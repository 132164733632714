import React, { useState, useEffect } from "react";

import { useTranslation } from "b2c/hooks/useTranslation";
import Text from "../Core/Text";
import Button from "../Core/Button";
import {
  UserAvatar,
  TypeAreaContent,
  TypeAreaControls,
  TypeAreaWrapper,
  TypeField,
  TypeFieldPlaceholder
} from "./styled";
import { FORUM_COMMENT_ACTIONS } from "../../../constants/forums";
import { buildImageUrlS3 } from "../../contexts/branded";
import { nameImages } from "../../constants/images";

const TypeArea = ({
  avatarURL,
  margin,
  mode,
  initialValue = "",
  onCancelClick,
  replyTargetName,
  onSubmit,
  loaded,
  ...props
}) => {
  const [isActive, setActive] = useState(false);
  const [isFocused, setFocused] = useState(false);
  const [value, setValue] = useState(initialValue);
  const [isFilled, setFilled] = useState(false);
  const avatar = avatarURL || buildImageUrlS3(nameImages.avatarEmpty);
  const { t } = useTranslation();

  useEffect(() => {
    value.length > 0 ? setFilled(true) : setFilled(false);
  }, [value]);

  useEffect(() => {
    isFilled || isFocused ? setActive(false) : setActive(true);
  }, [isFilled, isFocused]);

  const onFocusHandle = () => {
    setFocused(true);
  };
  const onBlurHandle = () => {
    setFocused(false);
  };

  const onChangeHandle = e => {
    setValue(e.target.value);
  };

  const clearValue = () => {
    setValue("");
  };

  const clickCancelHandle = () => {
    clearValue();
    onCancelClick && onCancelClick();
  };

  const handleSubmit = () => {
    onSubmit(value);
    clickCancelHandle();
  };

  const renderTypeAreaLabel = () => {
    switch (mode) {
      case FORUM_COMMENT_ACTIONS.REPLY:
        return (
          <Text fontWeight="400" color="black500" margin="5px 0 8px">
            {`${t("global.comments.reply_to")} `} <span style={{ fontWeight: "500" }}>{replyTargetName}</span>
          </Text>
        );
      case FORUM_COMMENT_ACTIONS.EDIT:
        return (
          <Text fontWeight="500" color="black500" margin="16px 0 8px">
            {`${t("global.comments.edit")} `}
          </Text>
        );
      default:
        return (
          <Text fontWeight="500" color="black500" margin="16px 0 8px">
            {`${t("global.comments.add")} `}
          </Text>
        );
    }
  };

  const submitButtonText = () => {
    if (mode === FORUM_COMMENT_ACTIONS.EDIT) {
      return t("global.comments.update");
    }

    return t("global.comments.post");
  };

  return (
    <TypeAreaWrapper styled={{ margin, ...props.style }}>
      {renderTypeAreaLabel()}

      <TypeAreaContent>
        <UserAvatar src={avatar} />
        <TypeField
          onChange={onChangeHandle}
          onFocus={onFocusHandle}
          onBlur={onBlurHandle}
          value={value}
          isFilled={isFilled}
          loaded={loaded}
        />
        <TypeFieldPlaceholder $isActive={isActive} $loaded={loaded}>
          {t("global.comments.placeholder")}
        </TypeFieldPlaceholder>
      </TypeAreaContent>
      <TypeAreaControls $isFilled={isFilled}>
        <Button noBackground margin="0 20px 0 0" theme="black200" onClick={clickCancelHandle}>
          {t("global.cancel")}
        </Button>
        <Button onClick={handleSubmit} color="primaryButtonLabelColor">
          {submitButtonText()}
        </Button>
      </TypeAreaControls>
    </TypeAreaWrapper>
  );
};

export default TypeArea;
