import React from "react";
import { Calendar } from "@styled-icons/bootstrap";
import { Globe } from "@styled-icons/boxicons-regular";
import format from "date-fns/format";

import { useTranslation } from "b2c/hooks/useTranslation";
import FuzuConfirmed from "images/fuzu-confirmed.png";
import { Image, Text } from "../Core";

const ConfirmedStep = ({ selectedDate, currentCountry, selectedHour }) => {
  const country = currentCountry.name === "Africa" ? "Kenya" : currentCountry.name;
  const { t } = useTranslation();

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <Image src={FuzuConfirmed} height="64px" style={{ display: "inline-block" }} />
        <Text fontWeight={500} fontSize={18} color="black300" width="inherit">
          {t("employers.contact_us_modal.confirmed_title")}
        </Text>
        <Text fontWeight={400} fontSize={14} color="black300" width="inherit" argin="5px 0 0 0">
          {selectedDate
            ? t("employers.contact_us_modal.confirmed_description_1")
            : t("employers.contact_us_modal.confirmed_description_2")}
        </Text>
      </div>
      {selectedDate && selectedHour && (
        <div
          style={{
            margin: "22px 0 0 0",
            padding: "18px 0 0 0",
            borderTop: "1px solid #8080805c"
          }}
        >
          <Text fontWeight={600} fontSize={14} color="black500" width="inherit" margin="0 0 12px 0px">
            <Calendar width={20} />
            <span style={{ marginLeft: "7px" }}>
              {`${selectedHour},  ${format(new Date(selectedDate), "EEEE, MMMM d")}`}
            </span>
          </Text>
          <Text fontWeight={600} fontSize={14} color="black500" width="inherit">
            <Globe width={20} />
            <span style={{ marginLeft: "7px" }}>
              {country} {t("employers.contact_us_modal.time")}
            </span>
          </Text>
        </div>
      )}
    </>
  );
};

export default ConfirmedStep;
