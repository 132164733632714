import React, { useContext } from "react";
import { Envelope, Timer } from "@styled-icons/boxicons-solid";
import ThemeContext from "../../../../contexts/theme";
import { calculateDaysLeft, getTimeSince } from "../../../../../utils/helpers";
import { StyledPositionDetails } from "./styled";
import { Badge, Text } from "../../../../components/Core";
import { useTranslation } from "../../../../hooks/useTranslation";

const PositionDetails = ({ campaignEndDate, messagesCount = 0, loaded, isExpiredJob }) => {
  const theme = useContext(ThemeContext);
  const days = Math.round((campaignEndDate - Date.now() / 1000) / (3600 * 24));
  const leftExpired = getTimeSince(campaignEndDate * 1000);
  const left = calculateDaysLeft(campaignEndDate);
  const daysLeft = Math.ceil((campaignEndDate - Date.now() / 1000) / (3600 * 24));
  const expired = daysLeft < 0;
  const { t } = useTranslation();
  const expiredDateText = !expired && daysLeft === 0 ? t("jobs.content.closing_today") : left;

  return (
    <StyledPositionDetails>
      {messagesCount > 0 ? (
        <>
          <Text fontSize={12} inline loaded={loaded} margin="0 6px 0 0">
            Messages
          </Text>
          <Envelope width="26px" />
        </>
      ) : (
        !expired && (
          <>
            <Text fontSize={12} inline loaded={loaded} margin="0 6px 0 0">
              {isExpiredJob ? `${t("global.closed")} ${expiredDateText}` : expiredDateText}
            </Text>
            {!isExpiredJob && <Timer width="26px" fill={days > 3 ? theme.success200 : theme.accent300} />}
          </>
        )
      )}
      {expired && (
        <>
          <Text fontSize={12} inline loaded={loaded} margin="0 6px 0 0">
            {isExpiredJob ? `${t("global.closed")} ${leftExpired}` : leftExpired}
          </Text>
          {!isExpiredJob && <Timer width="26px" fill={days > 3 ? theme.success200 : theme.accent300} />}
        </>
      )}
      <Badge fontSize={10}>{messagesCount}</Badge>
    </StyledPositionDetails>
  );
};

export default PositionDetails;
