import styled from "styled-components";

export const MainRow = styled.div`
  display: flex;

  img,
  svg {
    min-width: 100px;
  }
`;
