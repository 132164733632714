import styled from "styled-components";

export const NavWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100px;
  background: #fff;
  width: 100%;
  position: relative;
  @media screen and (max-width: 767px) {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 11;
  }
`;

export const NavQuestionCounter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 1;
  @media screen and (max-width: 550px) {
    position: static;
    transform: none;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
  }
`;
