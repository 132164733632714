import React from "react";
import { useTranslation } from "b2c/hooks/useTranslation";

import student from "images/b2c/onboarding/onboarding-seniority-student.png";
import manager from "images/b2c/onboarding/onboarding-seiority-manager.png";
import { Card, Checkbox, Image, Label, Text } from "../Core";

const list = [
  { id: 1, image: manager, title: "companies.contact_us_modal.description_1" },
  { id: 0, image: student, title: "companies.contact_us_modal.description_2" }
];

const OptionSelectionStep = ({ selected, setSelected }) => {
  const { t } = useTranslation();

  const handleSelect = event => {
    const { value } = event.target;
    const findJob = list.find(item => item.id === +value);

    setSelected(findJob);
  };

  return (
    <>
      <Text fontWeight={400} fontSize={14} color="black300" margin="24px 0 24px 0">
        {t("companies.contact_us_modal.sub-tile_1")}
      </Text>
      <Card theme="grey100" style={{ borderRadius: "15px" }}>
        <Card.Content>
          {list.map(({ id, title, image }) => {
            return (
              <Checkbox
                key={id}
                id={id}
                name="job"
                value={id}
                borderedView
                margin="6px 0"
                onChange={handleSelect}
                checked={selected?.id === id}
                markColor="white"
                checkedColor="secondary200"
                checkedBgColor="secondary100"
                labelProps={{
                  style: {
                    flexDirection: "row-reverse",
                    justifyContent: "space-between",
                    borderColor: selected?.id === id ? "#4690FF" : "transparent",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.08)",
                    padding: "8px"
                  }
                }}
                checkboxMarkProps={{
                  style: {
                    borderRadius: "50%"
                  }
                }}
                style={{
                  outline: "none"
                }}
                label={
                  <Label
                    theme="transparent"
                    Icon={
                      <Image
                        src={image}
                        borderRadius="100%"
                        style={{
                          maxWidth: "48px",
                          maxHeight: "48px",
                          marginRight: "8px"
                        }}
                      />
                    }
                    fontSize="14px"
                    lineHeight="21px"
                    fontWeight={600}
                    margin="0"
                    padding="0 15px 0 0"
                  >
                    <span>{t(title)}</span>
                  </Label>
                }
              />
            );
          })}
        </Card.Content>
      </Card>
    </>
  );
};

export default OptionSelectionStep;
