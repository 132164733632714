import React, { useRef, useEffect } from "react";
import parse from "html-react-parser";
import { useHistory } from "react-router-dom";
import { Comment, Like, Share } from "@styled-icons/boxicons-solid";
import cn from "classnames";

import { thousandShortcut } from "utils/helpers";
import { Title, Text, Label, Button, Divider, Video } from "b2c/components/Core";
import { useTranslation } from "b2c/hooks/useTranslation";
import ArticleImage from "../ArticleImage";
import AuthorInfo from "../../../../components/AuthorInfo";
import AuthorFullInfo from "../AuthorFullInfo";
import Comments from "../../../../components/Comments";
import ShareButton from "../../../../components/ShareButton";
import withLoading from "../../../../components/withLoading";
import SocialBottomControls from "../SocialBottomControls";
import useStore from "../../../../contexts/store";
import Promotion from "../Promotion";
import Placeholder from "../Placeholder";
import { GuestHidden, GuestVisible, AuthorizationButton } from "../../../../components/WithAuth";
import { getSearchParams } from "../../../../../utils/getSearchParams";
import { useIsBarona } from "../../../../hooks";
import { languageFormat } from "../../../../constants/main";

const Article = ({
  loading,
  loaded,
  id,
  body = "",
  categories = [],
  comments = [],
  comments_count: commentsCount = 0,
  created_at: createdAt = "",
  normal_image: normalImage,
  headline,
  liked_by_user: likedByUser = false,
  likes = 0,
  synopsis = "",
  views = 0,
  written_by: writtenBy = {},
  promotion_type: promotionType,
  likeArticle,
  removeLike,
  addComment,
  updateComment,
  removeComment
}) => {
  const commentSection = useRef();
  const history = useHistory();
  const likeHandler = () => (!likedByUser ? likeArticle(id) : removeLike(id));
  const { features } = useStore("Premium");
  const scrollToComments = () => commentSection.current?.scrollIntoView({ behavior: "smooth" });
  const queryCommentId = getSearchParams(location.search).get("comment_id");
  const isBarona = useIsBarona();
  const { global_brand } = useStore("initialState");
  const globalBrandName = global_brand.name.toLowerCase();
  const { t, language } = useTranslation();
  const languageFormatType = languageFormat[language];

  useEffect(() => {
    if (queryCommentId) {
      scrollToComments();
    }
  }, []);

  const options = {
    replace: domNode => {
      if (domNode.name === "p" && domNode.children && domNode.children[0].name === "iframe") {
        return (
          <Video
            embed
            src={domNode.children[0].attribs.src}
            autoPlay
            allow={domNode.children[0].attribs.allow}
            allowFullscreen
          />
        );
      }
    }
  };

  const handleAuthorPosts = () => {
    if (writtenBy) {
      history.push(`/forum/authors/${writtenBy.slug}`);
    }
  };

  return (
    <>
      <article role="article" className={cn("article", `article_${globalBrandName}`)}>
        <Text margin="0 0 12px" loaded={loaded}>
          <Label>{t("forum.label")}</Label>
        </Text>
        <Title margin="0 0 16px">{headline}</Title>
        <Title level="5" color="black300" lineHeight="180%" loaded={loaded}>
          {parse(synopsis.replace("&nbsp;", " "))}
        </Title>
        <div className="article__info">
          <AuthorInfo
            author={writtenBy ? writtenBy.name : t("forum.no_author")}
            avatarImg={writtenBy ? writtenBy.image_thumb : null}
            views={thousandShortcut(views)}
            date={
              createdAt
                ? new Date(createdAt).toLocaleString(languageFormatType, {
                    day: "numeric",
                    month: "short",
                    year: "numeric"
                  })
                : ""
            }
            linkable={Boolean(writtenBy)}
            loaded={loaded}
            loading={loading}
            onClick={handleAuthorPosts}
          />
          <div className="article__actions">
            <GuestVisible>
              {isBarona ? (
                <Button.Link
                  className="article__icon_barona"
                  to="/login"
                  theme="grey200"
                  type="join"
                  color="black500"
                  icon={<Like width="18px" />}
                  margin="0 8px 0 0"
                >
                  {likes || " "}
                </Button.Link>
              ) : (
                <AuthorizationButton
                  theme="grey200"
                  type="join"
                  color="black500"
                  icon={<Like width={18} />}
                  margin="0 8px 0 0"
                >
                  {likes || " "}
                </AuthorizationButton>
              )}
            </GuestVisible>
            <GuestHidden>
              <Button
                className={`article__icon_${globalBrandName}`}
                theme={likedByUser ? "secondary100" : "grey200"}
                color={likedByUser ? "secondary200" : "black500"}
                icon={<Like width={18} />}
                margin="0 8px 0 0"
                onClick={likeHandler}
              >
                {likes || " "}
              </Button>
            </GuestHidden>
            <GuestVisible>
              {isBarona ? (
                <Button.Link
                  className="article__icon_barona"
                  to="/login"
                  type="join"
                  icon={<Comment width={18} />}
                  theme="grey200"
                  margin="0 8px 0 0"
                >
                  {commentsCount || " "}
                </Button.Link>
              ) : (
                <AuthorizationButton type="join" icon={<Comment width={18} />} theme="grey200" margin="0 8px 0 0">
                  {commentsCount || " "}
                </AuthorizationButton>
              )}
            </GuestVisible>
            <GuestHidden>
              <Button
                className={`article__icon_${globalBrandName}`}
                icon={<Comment width={18} />}
                theme="grey200"
                margin="0 8px 0 0"
                onClick={scrollToComments}
              >
                {commentsCount}
              </Button>
            </GuestHidden>
            <ShareButton
              prefix={t("global.share.article")}
              name={headline}
              Component={props => (
                <Button icon={<Share width={18} style={{ transform: "rotateY(180deg)" }} />} theme="grey200" {...props}>
                  {/* {shares_count} */}
                </Button>
              )}
            />
          </div>
        </div>

        <ArticleImage src={normalImage} loading={loading} loaded={loaded} />

        <Divider margin="40px 0 20px" />

        <div className={cn("article__body", `article__body_${globalBrandName}`)}>
          {parse(body.replace("&nbsp;", " "), options)}
        </div>

        <div className={cn("article__tags", `article__tags_${globalBrandName}`)}>
          {categories.map(category => (
            <Button.Link
              key={category}
              to={`/forum/${category}`}
              theme="secondary300"
              fontSize="14px"
              padding="8px 16px"
              margin="0 12px 12px 0 "
              noBackground
              bordered
              height="36px"
            >
              {category}
            </Button.Link>
          ))}
        </div>
      </article>

      {!features?.instant_feedback_global?.activated && (
        <Promotion type={promotionType} style={{ margin: "0 0 24px" }} />
      )}

      <AuthorFullInfo
        name={writtenBy ? writtenBy.name : t("forum.no_author")}
        avatarImg={writtenBy ? writtenBy.image_thumb : null}
        description={writtenBy ? writtenBy.description : t("forum.no_description")}
        loaded={loaded}
        loading={loading}
        linkable={Boolean(writtenBy)}
        onClick={handleAuthorPosts}
      />

      <Divider margin="24px 0 0" />

      <SocialBottomControls liked={likedByUser} likes={likes} likeHander={likeHandler} />

      <div ref={commentSection}>
        <Comments
          margin="0 0 60px"
          loaded={loaded}
          id={id}
          comments={comments}
          comments_count={commentsCount}
          addComment={addComment}
          updateComment={updateComment}
          removeComment={removeComment}
          addReply={addComment}
        />
      </div>
    </>
  );
};

export default withLoading(Article, Placeholder);
