import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Row, Col, ScreenClassContext } from "react-grid-system";
import { useParams } from "react-router-dom";

import { useDocumentTitle } from "utils/hooks";
import heroCard from "images/trophyman.jpg";
import heroCardSm from "images/hero-card-sm.png";
import useStore from "../../contexts/store";
import { Card, Image } from "../../components/Core";
import Layout from "../../components/Layout";
import HeroFirstStep from "./components/HeroFirstStep";
import HeroSecondStep from "./components/HeroSecondStep";
import HeroThirdStep from "./components/HeroThirdStep";
import HeroForthStep from "./components/HeroForthStep";
import { CardWrapper } from "./styled";
import { getCompanyName } from "../../contexts/branded";

const HeroCard = () => {
  const { toggleNavigation } = useStore("User");
  const screen = useContext(ScreenClassContext);
  const isSm = ["xs", "sm"].includes(screen);
  const { token } = useParams();

  useEffect(() => {
    toggleNavigation({ desktop: false, mobile: false });
  }, []);
  useDocumentTitle(`Celebrate your career hero | ${getCompanyName()}`);

  const [step, setStep] = useState(1);
  const [generatedToken, setGeneratedToken] = useState(null);
  const [formState, setFormState] = useState({
    email: "",
    name: "",
    careerName: "",
    thank: ""
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  if (token) {
    return (
      <Layout.Section style={{ height: "0" }} backgroundColor="white">
        <CardWrapper style={{ padding: "90px 0" }}>
          <HeroForthStep token={token} isSm={isSm} setStep={setStep} />
        </CardWrapper>
      </Layout.Section>
    );
  }

  const steps = (
    <>
      {step === 1 && <HeroFirstStep setStep={setStep} isSm={isSm} />}
      {step === 2 && <HeroSecondStep isSm={isSm} formState={formState} setFormState={setFormState} setStep={setStep} />}
      {step === 3 && (
        <HeroThirdStep
          {...formState}
          isSm={isSm}
          setGeneratedToken={setGeneratedToken}
          generatedToken={generatedToken}
          setStep={setStep}
        />
      )}
    </>
  );

  return (
    <Layout.Section style={{ height: "0" }}>
      {isSm ? (
        <div style={{ margin: "60px 0" }}>
          {step === 1 && <Image borderRadius="0" src={heroCardSm} width="100%" />}
          {steps}
        </div>
      ) : (
        <CardWrapper style={{ padding: "90px 0" }}>
          <Card shadow style={{ height: "715px" }}>
            <Row>
              {!isSm && (
                <Col md={6}>
                  <Image style={{ maxWidth: "101.2%" }} borderRadius="0" src={heroCard} width="101.2%" />
                </Col>
              )}
              <Col md={6}>{steps}</Col>
            </Row>
          </Card>
        </CardWrapper>
      )}
    </Layout.Section>
  );
};

export default observer(HeroCard);
