import React from "react";
import { Row, Col } from "react-grid-system";

import FilterSelect from "b2c/components/FilterSelect";
import { useTranslation } from "b2c/hooks/useTranslation";

const CourseFilters = ({ searchFilters, appliedFilters, applyFilter }) => {
  const handleCategoryBuilder = category => {
    return `/learn/search/${category.slug}`;
  };
  const { t } = useTranslation();

  return (
    <Row style={{ padding: "12px 0 0" }}>
      <Col lg={2}>
        <FilterSelect
          placeholder={t("learn.search.category_placeholder")}
          category="category"
          appliedCategory="category_id"
          baseProperty="id"
          sortItemsBy="name"
          items={searchFilters?.categories}
          selected={appliedFilters?.category_id}
          applyFilter={applyFilter}
          nonResetting
          isLearnCategory
          categoryLearnUrlBuilder={handleCategoryBuilder}
        />
      </Col>
      <Col lg={2}>
        <FilterSelect
          multi
          placeholder={t("learn.search.level_placeholder")}
          category="level"
          appliedCategory="skill_level_ids"
          baseProperty="id"
          sortItemsBy="name"
          items={searchFilters?.skill_levels}
          selected={appliedFilters?.skill_level_ids}
          applyFilter={applyFilter}
        />
      </Col>
      <Col lg={2}>
        <FilterSelect
          placeholder={t("learn.search.organizer_placeholder")}
          category="organizer"
          appliedCategory="organizer_id"
          baseProperty="id"
          sortItemsBy="name"
          items={searchFilters?.organizers}
          selected={appliedFilters?.organizer_id}
          applyFilter={applyFilter}
        />
      </Col>
      {/* <Col lg={2}>
                <FilterSelect
                    category="language"
                    appliedCategory="language"
                    applyFilter={applyFilter}
                />
            </Col>
            <Col lg={2}>
                <FilterSelect
                    category="rating"
                    appliedCategory="rating"
                    applyFilter={applyFilter}
                />
            </Col> */}
    </Row>
  );
};

export default CourseFilters;
