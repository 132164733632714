import React, { useState, useMemo, useContext, useLayoutEffect, useEffect } from "react";
import { observer } from "mobx-react";
import { Map } from "@styled-icons/boxicons-solid";
import { toJS } from "mobx";
import { MdClear } from "react-icons/md";

import { highlightField, onEnterPress } from "utils/helpers";
import { useTranslation } from "b2c/hooks/useTranslation";
import ThemeContext from "../../contexts/theme";
// HiddenButton
import { FilterWrapper, LocationItem, CountryItem, EmptySearch, LocationMenuContainer } from "./styled";
import { Input, Icon } from "../Core";
import { useGetCurrentCountry, useLanguageAndCountryUrl } from "../../hooks";
import useStore from "../../contexts/store";
import { buildPath } from "../../../utils/hooks";

const LocationFilter = ({
  loading = true,
  countryIds: countryId,
  townIds: townId,
  applyFilter,
  countries,
  towns: townsList
  // test and remove [sc-5670]
  // getPageInfo,
  // appliedFilters
  // countryTownRef test and remove [sc-2103]
}) => {
  const theme = useContext(ThemeContext);
  const [focused, setFocused] = useState(false);
  const [applied, setApplied] = useState(false);
  const [field, setField] = useState("");
  const currentCountry = useGetCurrentCountry();
  const getCountryId = useMemo(() => {
    return parseInt(currentCountry.id && currentCountry.id !== "global" ? currentCountry.id : countryId);
  }, [currentCountry.id, countryId]);
  const { jobSearchUrlBuilder } = useStore("JobSearch");
  const { user } = useStore("User");
  const isGuestUser = !user?.id;
  const { t } = useTranslation();
  const languageAndLanguage = useLanguageAndCountryUrl();

  const getDefaultList = useMemo(() => {
    const priorityCountiesIds = toJS(countries) ? toJS(countries).map(item => item.id) : [];
    const sortedTownsByPriorityCountries = toJS(townsList)
      ? toJS(townsList).sort((a, b) => {
          return (
            priorityCountiesIds.findIndex(item => item === a.fuzu_country_id) -
            priorityCountiesIds.findIndex(item => item === b.fuzu_country_id)
          );
        })
      : [];

    if (countries) {
      if (getCountryId) {
        const findCountry = countries?.find(item => item.id === getCountryId);
        if (findCountry) {
          return [...sortedTownsByPriorityCountries, findCountry];
        }

        return sortedTownsByPriorityCountries;
      }

      return [...countries, ...sortedTownsByPriorityCountries];
    }

    return [];
  }, [getCountryId, countries, townsList]);

  const featuredCountriesAndTowns = getDefaultList.filter(item => item.featured || item.fuzu_country_featured);

  const filtered = useMemo(() => {
    if (isGuestUser && getCountryId) {
      return getDefaultList.filter(country => country.name.toLowerCase().includes(field?.toLowerCase()));
    }

    const findFiltered = getDefaultList.filter(country => country.name.toLowerCase().includes(field?.toLowerCase()));
    if (getCountryId) {
      const findCountry = countries?.find(item => item.id === getCountryId);
      const findTowns = findCountry ? getDefaultList?.filter(item => item.fuzu_country_id === findCountry.id) : [];

      if (findCountry) {
        return [...findFiltered, ...findTowns];
      }
    }

    return findFiltered;
  }, [field]);

  const changeLocationField = ({ target: { value } }) => {
    setField(value);
  };

  const handleCountryApply = country => {
    applyFilter({ country_id: country?.id, town_id: null, languageAndLanguage });
    setFocused(false);
    setField(country?.name);
    setApplied(true);
  };

  const handleTownApply = (town, isCallPageInfo) => {
    if (currentCountry.id && currentCountry.id === "global" && isCallPageInfo) {
      // getPageInfo({ path: buildUrlTown(town), query: appliedFilters });
      applyFilter({
        town_id: town.id,
        languageAndLanguage
      });
    } else {
      applyFilter({
        country_id: countryId,
        town_id: town.id,
        languageAndLanguage
      });
    }

    setFocused(false);
    setField(town.name);
    setApplied(true);
  };

  const onKeyDown = e => {
    if (field && field.length > 0 && focused) {
      onEnterPress(e, field, () => {
        e.preventDefault();
        e.stopPropagation();
        if (filtered?.[0]?.country_code) {
          handleCountryApply(filtered[0]);
        } else {
          handleTownApply(filtered[0], true);
        }
      });
    }
  };

  /*
  const handleSubmit = e => {
    if (field && field.length > 0 && filtered?.length && field !== filtered[0].name) {
      e.preventDefault();
      e.stopPropagation();
      if (filtered[0].country_code) {
        handleCountryApply(filtered[0]);
      } else {
        handleTownApply(filtered[0]);
      }
    }
  };
*/

  useEffect(() => {
    if (applied && !field && (countryId || townId)) {
      setApplied(false);

      if (townId && !countryId) {
        // const findTown = townsList.find(item => String(item.id) === String(townId[0] || townId));
        applyFilter({ country_id: countryId, town_id: null, languageAndLanguage });
        if (focused) {
          setFocused(false);
        }
      } else if (townId) {
        applyFilter({ country_id: countryId, town_id: null, languageAndLanguage });
      } else {
        applyFilter({
          country_id: currentCountry.id && currentCountry.id === "global" ? "" : countryId,
          town_id: null,
          languageAndLanguage
        });
        if (focused) {
          setFocused(false);
        }
      }
    }
  }, [field]);

  useLayoutEffect(() => {
    if (!applied) {
      const location =
        (townId && getDefaultList.find(({ id }) => Number(townId) === Number(id))) ||
        (countryId &&
          getDefaultList.find(
            ({ id }) => String(countryId) === String(id) && currentCountry.id && currentCountry.id === "global"
          ));

      if (location) {
        setField(location.name);
        setApplied(true);
      }
    } else if (!countryId && !townId) {
      setApplied(false);
      setField("");
    }
  }, [getDefaultList?.length, countryId, townId]);

  const buildUrlTown = record => {
    const result = jobSearchUrlBuilder.buildForTown(
      record,
      true,
      currentCountry.id && currentCountry.id !== "global",
      languageAndLanguage
    );

    return buildPath(result.path, result.query);
  };

  const renderCountryTown = countryTown => {
    const highlightedCountry = () => highlightField(countryTown.name, field);

    if (countryTown.country_code && currentCountry.id && currentCountry.id !== "global") {
      return null;
    }

    if (countryTown.country_code && currentCountry.id && currentCountry.id === "global") {
      return (
        <CountryItem
          theme={theme}
          onMouseDown={e => e.preventDefault()}
          onClick={() => {
            handleCountryApply(countryTown);
          }}
        >
          Jobs in {highlightedCountry()}
        </CountryItem>
      );
    }

    return (
      <LocationItem
        theme={theme}
        onMouseDown={e => e.preventDefault()}
        onClick={e => {
          e.preventDefault();
          handleTownApply(countryTown);
        }}
        to={buildUrlTown(countryTown)}
      >
        {`${t("jobs.search.jobs_in")} `} {highlightedCountry()}
        <span className="location-item__country">{` ${countryTown?.fuzu_country_name}`}</span>
      </LocationItem>
    );
  };

  return (
    <FilterWrapper theme={theme}>
      <Input
        data-cy="location-filter"
        id="location-filter"
        disabled={loading}
        placeholder={getCountryId ? t("jobs.search.location_placeholder_1") : t("jobs.search.location_placeholder_2")}
        type="search"
        role="search"
        Icon={<Icon as={Map} width="24px" height="24px" fill={applied ? "black500" : "black100"} />}
        value={field}
        onFocus={() => {
          setFocused(true);
        }}
        onBlur={() => setFocused(false)}
        onChange={changeLocationField}
        inputHeight="40px"
        margin={0}
        borderWidth="2px"
        borderColor={applied ? "secondary200" : undefined}
        backgroundColor={applied ? "secondary100" : undefined}
        style={{
          padding: "0 10px 0 40px"
        }}
        autoComplete="off"
        onKeyDown={onKeyDown}
      />
      {field.length > 0 && (
        <span
          role="button"
          tabIndex={-1}
          className="close-icon"
          onClick={() => {
            setField("");
          }}
        >
          <MdClear />
        </span>
      )}

      {focused && field.length === 0 && (
        <div className="location-menu">
          {getDefaultList.map(countryTown => (
            <React.Fragment key={`${countryTown.name}-default`}>{renderCountryTown(countryTown)}</React.Fragment>
          ))}
        </div>
      )}

      {focused && field.length > 0 && (
        <div className="location-menu">
          {filtered.map(countryTown => (
            <React.Fragment key={`${countryTown.name}-filtered`}>{renderCountryTown(countryTown)}</React.Fragment>
          ))}
        </div>
      )}

      {!focused && (
        <LocationMenuContainer>
          <div className="location-menu">
            {featuredCountriesAndTowns.map(countryTown => (
              <React.Fragment key={`${countryTown.name}-hidden`}>{renderCountryTown(countryTown)}</React.Fragment>
            ))}
          </div>
        </LocationMenuContainer>
      )}
      {focused && field.length > 0 && filtered.length === 0 ? (
        <div className="location-menu">
          <EmptySearch>{t("jobs.search.location_empty")}</EmptySearch>
        </div>
      ) : null}
      {/*
      <HiddenButton ref={countryTownRef} onClick={handleSubmit} />
      */}
    </FilterWrapper>
  );
};

export default observer(LocationFilter);
