import styled from "styled-components";

export const CropperContainer = styled.div`
  min-width: 300px;
  display: flex;
  flex-direction: column;

  > .edit-area {
    position: relative;
    width: 100%;
    height: 300px;
    border-radius: 5px;
    margin-bottom: 16px;
    overflow: hidden;
    min-width: 300px;
  }

  @media screen and (min-width: 768px) {
    min-width: 500px;
  }
`;
