import React, { useContext } from "react";
import { Row } from "react-grid-system";
import { Link } from "react-router-dom";

import ThemeContext from "../../contexts/theme";
import Button from "../Core/Button";
import { StyledLabel } from "./styled";

const MenuItem = ({
  pathname,
  iconStyle = {},
  path,
  hideActivityCenter,
  title,
  icon: IconElement,
  iconSolid: IconSolid,
  isButton,
  onClick
}) => {
  const Theme = useContext(ThemeContext);
  const Component = isButton ? Button : Link;
  const handleClick = () => {
    hideActivityCenter();

    if (onClick) {
      onClick();
    }
  };

  return (
    <Row>
      <Component
        to={path}
        style={{ width: "100%", display: "block", padding: "0", textAlign: "left" }}
        onClick={handleClick}
        theme="transparent"
      >
        <Button.Iconed
          className="nav-icon"
          theme="transparent"
          style={{ margin: "0", display: "inline-block", ...iconStyle }}
        >
          {pathname === path ? <IconSolid width={24} /> : <IconElement width={24} fill={Theme.black350} />}
        </Button.Iconed>
        <StyledLabel
          color={pathname === path ? "black" : Theme.black350}
          style={{ fontWeight: pathname === path ? "600" : "500" }}
        >
          {title}
        </StyledLabel>
      </Component>
    </Row>
  );
};

export default MenuItem;
