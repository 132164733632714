import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";

import { Card, Checkbox, Label } from "../../../Core";

const SuperPower = ({ list }) => {
  const [selected, setSelected] = useState([]);
  useEffect(() => {
    if (list) {
      const onboardingForm = JSON.parse(localStorage.getItem("onboardingForm"));
      const superPower = onboardingForm?.superPower;
      if (superPower) {
        setSelected(superPower.map(id => +id));
      }
    }
  }, [list]);

  const handleSelect = event => {
    const { value } = event.target;

    setSelected(prev => (prev.includes(+value) ? prev.filter(item => item !== +value) : prev.concat(+value)));
  };

  return (
    <Card theme="grey100">
      <Card.Content>
        {list.map(({ id, name }) => (
          <Checkbox
            key={id}
            id={id}
            name="superPower"
            value={id}
            borderedView
            margin="6px 0"
            onChange={handleSelect}
            checked={selected.includes(id)}
            markColor="white"
            checkedColor="secondary200"
            checkedBgColor="secondary100"
            style={{
              outline: "none"
            }}
            labelProps={{
              style: {
                flexDirection: "row-reverse",
                justifyContent: "space-between",
                borderColor: selected.includes(id) ? "#4690FF" : "transparent",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.08)",
                padding: "8px 12px",
                minHeight: "40px"
              }
            }}
            checkboxMarkProps={{
              style: {
                marginRight: "0"
              }
            }}
            label={
              <Label
                theme="transparent"
                fontSize="14px"
                lineHeight="21px"
                fontWeight={600}
                margin="0"
                padding="0 15px 0 0"
              >
                {name}
              </Label>
            }
          />
        ))}
      </Card.Content>
    </Card>
  );
};

export default observer(SuperPower);
