import styled from "styled-components";

export const StyledStars = styled.div`
  ${({ textColor }) => `
      span {
        color: ${textColor};
        white-space: nowrap;
      }
  `}
`;
