import React, { useEffect, useContext, useRef } from "react";

import { ScreenClassContext, Row, Col } from "react-grid-system";

import ThemeContext from "../../contexts/theme";

import { StepContainer, StepItem } from "./styled";

const StepProgress = ({
  width = "auto",
  margin = "45px 20px 90px",
  alwaysDisplay = false,
  steps = [],
  mobileSteps,
  currentStep = 1,
  setStep
}) => {
  const stepRef = useRef();
  const theme = useContext(ThemeContext);
  const screen = useContext(ScreenClassContext);
  const isMobile = ["xs", "sm"].includes(screen);

  useEffect(() => {
    stepRef.current?.focus();
  }, [currentStep]);

  const progressSteps = mobileSteps && isMobile ? mobileSteps : steps;

  return (
    <StepContainer
      $width={width}
      $margin={margin}
      ref={stepRef}
      role="progressbar"
      tabIndex="0"
      aria-valuemin="1"
      aria-valuemax={steps?.length}
      aria-valuenow={currentStep}
      aria-valuetext={`Step ${currentStep} of ${steps?.length}: ${steps[currentStep - 1]}`}
    >
      <Row nowrap>
        {progressSteps.map((step, index) => (
          <React.Fragment key={step}>
            <Col
              component={StepItem}
              theme={theme}
              alwaysDisplay={alwaysDisplay || !!mobileSteps}
              isActive={currentStep >= index + 1}
              isCurrent={currentStep == index + 1}
              isInteractive={!!setStep && currentStep >= index + 1}
              {...(!!setStep && currentStep >= index + 1 ? { onClick: () => setStep(index + 1) } : {})}
              {...(currentStep > index + 1
                ? { "data-step-complete": "" }
                : currentStep == index + 1
                  ? { "data-step-current": "" }
                  : { "data-step-incomplete": "" })}
            >
              <div>
                {index + 1}. <span>{step}</span>
              </div>
            </Col>
          </React.Fragment>
        ))}
      </Row>
    </StepContainer>
  );
};

export default StepProgress;
