import React from "react";

import { Link } from "react-router-dom";

import { formatError } from "utils/helpers";
import { Input, Hint, Text } from "../../Core";

const Credentials = ({ email, errors }) => {
  return (
    <>
      <Input
        type="email"
        name="email"
        placeholder="example@domain.com"
        label="Enter your email address"
        defaultValue={email}
        disabled
        errorMessage={formatError(errors?.email)}
      />
      <Input
        type="password"
        name="password"
        placeholder="Minimum 8 characters"
        label="Create a password"
        errorMessage={formatError(errors?.password)}
      />
      <Hint>
        By clicking “Continue” and creating a Fuzu account you agree to Fuzu’s{" "}
        <Text as={Link} to="/privacy-policy" className="link underlined" fontWeight={600} color="secondary200">
          Privacy Policy
        </Text>{" "}
        and{" "}
        <Text as={Link} to="/terms-and-conditions" className="link underlined" fontWeight={600} color="secondary200">
          Service Terms
        </Text>
        .
      </Hint>
    </>
  );
};

export default Credentials;
