import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
  padding: 12px 24px;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  border-top: 1px solid #eee;
  z-index: 1;
  background: #fff;

  @media screen and (min-width: 768px) {
    padding: 24px;
  }
`;

export const Content = styled.div`
  max-width: 480px;
  margin: 0 auto;
  display: flex;
  align-content: center;
  align-items: center;
  flex-direction: column-reverse;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

export const LoginContent = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 16px;

  @media screen and (min-width: 768px) {
    margin-top: 0;
  }
`;

export const Description = styled.span`
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
`;

export const Login = styled(Link)`
  background: transparent;
  padding: 0;
  color: #408bfc;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  display: block;
  margin-right: auto;
  margin-left: 4px;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column-reverse;
  gap: 16px;
  width: 100%;

  @media screen and (min-width: 768px) {
    justify-content: flex-end;
    flex-direction: row;
    width: auto;
    margin-left: auto;
  }
`;

const actionLink = `
  padding: 12px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 8px;
  cursor: pointer;
  text-decoration: none;
  width: 100%;
`;

export const Back = styled(Link)`
  ${actionLink};
  border: 1px solid #c5c5c5;
  background: #fff;

  @media screen and (min-width: 768px) {
    width: auto;
  }
`;

export const Next = styled.button`
  ${actionLink};
  background: ${({ $background }) => $background};
  color: ${({ $color }) => $color};

  @media screen and (min-width: 768px) {
    width: auto;
  }

  ${({ $disabled }) =>
    $disabled
      ? `
     opacity: 0.5;
     pointer-events: none;
      `
      : ""}}
`;
