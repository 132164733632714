import styled from "styled-components";

export const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 375px;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;
