import React from "react";

import { ArrowForward } from "@styled-icons/evaicons-solid";

import { useTranslation } from "b2c/hooks/useTranslation";
import JobItem from "../JobItem";

import { StyledRoot, StyledCounterSection, StyledMoreSection, StyledMoreLink, StyledCounter } from "./styled";

const NotificationJobs = ({ jobs }) => {
  const { t } = useTranslation();

  return (
    <StyledRoot>
      <StyledCounterSection>
        <StyledCounter>{jobs.length}</StyledCounter>
        {` ${t("notifications.new_recommendations_job")}`}
      </StyledCounterSection>

      {jobs.map((job, index) => (
        <JobItem key={index} {...job} />
      ))}

      <StyledMoreSection>
        <StyledMoreLink to="/job">
          {`${t("notifications.more_jobs")} `} <ArrowForward width={20} color="inherit" />
        </StyledMoreLink>
      </StyledMoreSection>
    </StyledRoot>
  );
};

export default NotificationJobs;
