const ReactDOM = require("react-dom");
const React = require("react");
const App = require("../../b2c/App").default;

window.hydrateApplication = (props, domRootElement) => {
  window.hydrated = true;
  const hydrationProps = { ...props, ssr: false, hydration: true };
  const rootElement = ReactDOM.createRoot(domRootElement, { hydrate: true });
  rootElement.render(<App {...hydrationProps} />);
};
