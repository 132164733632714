import React from "react";

import { Label } from "b2c/components/Core";
import { useTranslation } from "b2c/hooks/useTranslation";

const ActionRequired = ({ isShow }) => {
  if (!isShow) {
    return null;
  }

  const { t } = useTranslation();

  return (
    <Label
      rect
      theme="accent400"
      color="white"
      height="21px"
      margin="0 0 8px 0"
      style={{ borderRadius: "10px", letterSpacing: "1px" }}
      fontSize="10px"
      fontWeight="600"
    >
      {t("jobs.content.action_required")}
    </Label>
  );
};

export default ActionRequired;
