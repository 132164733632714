import React from "react";
import { Row, Col } from "react-grid-system";
import { Link } from "react-router-dom";
import { Edit, Trash, CopyAlt } from "@styled-icons/boxicons-regular";

import { useTranslation } from "b2c/hooks/useTranslation";
import { Card, Label, Alert, Text, Button } from "../../../../components/Core";
import { ActionsViewHandler } from "../../../../components/DescriptionSection";

const ProfileItem = ({
  id,
  alertMessage = "",
  title = "",
  info,
  date,
  additional,
  text = "",
  skills = [],
  onDelete,
  onAsk,
  onAddAttachment,
  onEdit,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Card padding shadow wideOnMobile {...props}>
      <Card.Content padding={16}>
        {alertMessage && (
          <Alert onClick={onEdit} margin="0 0 16px 0">
            {alertMessage}
          </Alert>
        )}
        <Row align="center">
          <Col>
            <Text fontSize="16px" fontWeight={700}>
              {title}
            </Text>
          </Col>
          <Col xs="content">
            <ActionsViewHandler>
              {onEdit && (
                <Button
                  noBackground
                  type="button"
                  height="30px"
                  theme="black500"
                  fontSize="14px"
                  icon={<Edit width={18} />}
                  onClick={() => onEdit(id)}
                >
                  {t("global.edit")}
                </Button>
              )}
              {onDelete && (
                <Button
                  noBackground
                  type="button"
                  height="30px"
                  theme="black500"
                  fontSize="14px"
                  icon={<Trash width={18} />}
                  onClick={onDelete}
                >
                  {t("global.delete")}
                </Button>
              )}
              {onAsk && (
                <Button
                  noBackground
                  type="button"
                  height="30px"
                  theme="black500"
                  fontSize="14px"
                  icon={<CopyAlt width={18} />}
                  onClick={onAsk}
                >
                  {t("wizard.ask_reference_button")}
                </Button>
              )}
              {onAddAttachment && (
                <Button
                  noBackground
                  type="button"
                  height="30px"
                  theme="black500"
                  fontSize="14px"
                  icon={<Link width={18} />}
                  onAddAttachment={onAddAttachment}
                >
                  {t("wizard.add_attachments")}
                </Button>
              )}
            </ActionsViewHandler>
          </Col>
        </Row>
        {info && (
          <Text margin="0 0 0 19px" fontWeight={700} color="black200">
            {info}
          </Text>
        )}
        {date && <Text margin="0 0 0 19px">{date}</Text>}
        {additional && <Text margin="0 0 0 19px">{additional} </Text>}
        {text && (
          <Text lineHeight="21px" margin="10px 0 0">
            {text}
          </Text>
        )}
        {skills.length > 0 && (
          <Row style={{ marginTop: 8 }}>
            <Col>
              {skills.map((item, i) => (
                <Label
                  key={`uid_${id}_${i}`}
                  theme="black400"
                  rect
                  noBackground
                  bordered
                  padding="8px 14px"
                  margin="5px 10px 5px 0"
                  style={{
                    borderRadius: "5px",
                    borderColor: "#9E9E9E"
                  }}
                >
                  {item.name}
                </Label>
              ))}
            </Col>
          </Row>
        )}
      </Card.Content>
    </Card>
  );
};

export default ProfileItem;
