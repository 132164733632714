import React from "react";

import petronelaImage from "images/b2c/barona/petronela.png";
import mariaImage from "images/b2c/barona/maria.png";
import clementImage from "images/b2c/barona/clement.png";
import { useTranslation } from "b2c/hooks/useTranslation";
import { Content, Title, List, Team, Image, Name, Languages, Email, Phone } from "./styled";

const teamList = [
  {
    image: petronelaImage,
    name: "Petronela Kusa",
    languages: "contact.team.language_1",
    email: "petronela.kusa@barona.fi",
    phone: "+421 948 294 304"
  },
  {
    image: mariaImage,
    name: "Maria Duca Palarie",
    languages: "contact.team.language_2",
    email: "maria.duca@barona.fi",
    phone: "+358 50 337 1969"
  },
  {
    image: clementImage,
    name: "Clement Poumeyrol",
    languages: "contact.team.language_3",
    email: "clement.poumeyrol@barona.fi",
    phone: "+358 50 326 2528"
  }
];

const ContactTeam = () => {
  const { t } = useTranslation();

  const drawTeamList = teamList.map(item => {
    return (
      <Team key={item.name}>
        <Image>
          <img src={item.image} alt="" />
        </Image>
        <Name>{item.name}</Name>
        <Languages>{t(item.languages)}</Languages>
        <Email href={`mailto:${item.email}`}>{item.email}</Email>
        <Phone href={`tel:${item.phone}`}>{item.phone}</Phone>
      </Team>
    );
  });

  return (
    <Content>
      <Title>{t("contact.team.title")}</Title>

      <List>{drawTeamList}</List>
    </Content>
  );
};

export default ContactTeam;
