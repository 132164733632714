import React, { useContext } from "react";
import { ScreenClassContext } from "react-grid-system";
import { useTranslation } from "b2c/hooks/useTranslation";
import parse from "html-react-parser";

import { Card, Title, Text, Image } from "../../../../components/Core";
import Layout from "../../../../components/Layout";
import CoursesSection from "../../../../components/FeedSections/CoursesSection";
import CourseReview from "../CourseReview";
import { buildImageUrlS3 } from "../../../../contexts/branded";
import { nameImages } from "../../../../constants/images";

const CourseCompleted = ({ courseName = "", courseSlug, push, clearModule }) => {
  const screen = useContext(ScreenClassContext);
  const isSm = ["xs", "sm"].includes(screen);
  const { t } = useTranslation();

  return (
    <Layout.Content size="md" style={{ display: "flex", flexDirection: "column" }}>
      <Title scales level={4} fontWeight={600} margin="40px 0 24px">
        {t("learn.module.course")}: {courseName}
      </Title>

      <Card>
        <Card.Content padding="24px">
          <Image
            src={buildImageUrlS3(nameImages.courseSuccess)}
            style={{ margin: "auto", display: "block", width: "225px" }}
          />

          <Title level={4} width="100%" textAlign="center" fontWeight={500} margin="20px 0">
            {t("learn.module.congratulations")}!
          </Title>
          <Text width="100%" textAlign="center" lineHeight="160%">
            {parse(t("learn.module.success_message", { courseName }))}
          </Text>
        </Card.Content>
      </Card>

      <Card style={{ margin: "24px 0" }}>
        <Card.Content padding={isSm ? "24px 12px" : "24px 30%"}>
          <CourseReview onReviewSubmitted={() => push("/learn")} />
        </Card.Content>
      </Card>

      <CoursesSection
        filter="similar"
        title="Top skills for you to learn next"
        count_limit={3}
        slug={courseSlug}
        noFooter
        onClickCapture={clearModule}
      />
    </Layout.Content>
  );
};

export default CourseCompleted;
