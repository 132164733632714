import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react";
import { Row, Col, ScreenClassContext } from "react-grid-system";
import { useLocation, useHistory } from "react-router-dom";

import fuzuLogo from "images/new_fuzu_logo_black.png";
import ncpwdLogo from "images/b2c/logo_large.png";
import demoLogo from "images/b2c/demo.png";
import ListMarkerGreen from "images/b2c/icons/listMarkerGreen.svg";
import welcomeImage from "images/b2c/onboarding/PP_phones.png";
import careerPortalBackground from "images/b2c/clouds.jpg";
import ncpwdImage from "images/b2c/registre_image.jpg";
import jkuatLogo from "images/b2c/jkuatLogo.png";
import contributeImage from "images/b2c/contribute_portrait.jpg";
import baronaLine from "images/b2c/brush-line.png";
import mntLogo from "images/b2c/badges/mtn-black-logo.png";
import BaronaLogo from "images/barona-logo.svg";
import kepsaLogo from "images/kepsa-logo.png";
import mntImage from "images/b2c/mnt/mnt-image.png";
import { Title, Text, Image, Label } from "b2c/components/Core";
import { useTranslation } from "b2c/hooks/useTranslation";
import BrandedContext, {
  WhitelabelledHidden,
  WhitelabelledVisible,
  BrandHidden,
  BrandVisible,
  isCustomJkuat,
  isKepsa
} from "../../../../contexts/branded";
import {
  AuthLeftContainer,
  AuthLeftContent,
  Content,
  AuthWelcomeImage,
  AuthInfo,
  AuthTitle,
  AuthText,
  AuthTextKepsa,
  LogoLink,
  BaronaTitle,
  LogoLinkKepsa,
  AuthTitleKepsa
} from "./styled";
import useStore from "../../../../contexts/store";
import { useStandaloneMode } from "../../../../contexts/standalone";
import MobileHeading from "../MobileHeading";
import PoweredByFuzu from "../PoweredByFuzu";
import { brandName } from "../../../../constants/brand";
import { theme } from "../../../../contexts/theme";
import { useIsBarona } from "../../../../hooks";

const LeftSide = () => {
  const screen = useContext(ScreenClassContext);
  const isSm = ["xs", "sm"].includes(screen);
  const isKepsaBrand = isKepsa();

  const isStandalone = useStandaloneMode();

  return (
    <>
      <BrandHidden brand={["ncpwd", brandName.jkuat, "demo"]}>
        <WhitelabelledHidden>{isStandalone ? <MobileHeading /> : <DefaultContent isSm={isSm} />}</WhitelabelledHidden>

        <WhitelabelledVisible>
          {isKepsaBrand ? <DefaultContentKepsa isSm={isSm} /> : <CareerPortalContent isSm={isSm} />}
        </WhitelabelledVisible>
      </BrandHidden>
      <BrandVisible brand={["ncpwd", brandName.jkuat, "demo"]}>
        <CustomPlatformContent isSm={isSm} />
      </BrandVisible>
    </>
  );
};

const DefaultContent = observer(({ isSm }) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const isMtn = /mtn-pulse/.test(pathname);
  const { isNewRegistrationFlow, disableNewRegistrationFlow } = useStore("User");
  const isStandalone = useStandaloneMode();
  const isBarona = useIsBarona();
  const isKepsaBrand = isKepsa();
  const { t } = useTranslation();

  const { Logo, featureList, imageProps, listProps, beforeList } = (() => {
    if (isKepsaBrand) {
      return {
        Logo: (
          <LogoLinkKepsa to="/">
            <img src={kepsaLogo} alt="Kepsa Logo" />
          </LogoLinkKepsa>
        ),
        beforeList: (
          <Content>
            <AuthTitle>Welcome to KEPSA Ajira!</AuthTitle>
            <AuthText>
              We create and commercialize quality solutions and products through research, consulting, training and
              innovation to transform individuals and institutions in Africa and beyond
            </AuthText>
          </Content>
        ),
        listProps: {},
        imageProps: {},
        featureList: []
      };
    }
    if (isBarona) {
      return {
        Logo: (
          <LogoLink to="/">
            <BaronaLogo />
          </LogoLink>
        ),
        beforeList: (
          <>
            <BaronaTitle>{t("login.title")}</BaronaTitle>
            <BaronaTitle>BARONA!</BaronaTitle>

            <Image
              src={baronaLine}
              margin={isSm ? "12px 0 12px 0" : ""}
              width={isSm ? "166px" : "292px"}
              padding="0"
              borderRadius="0"
              display="block"
            />
          </>
        ),
        listProps: {},
        imageProps: {},
        featureList: []
      };
    }
    if (isMtn) {
      return {
        Logo: (
          <Image
            src={mntLogo}
            width={isSm ? 170 : 300}
            padding="0"
            borderRadius="0"
            margin={isSm ? "0 auto 12px 0" : "0 auto 50px"}
            display="block"
          />
        ),
        featureList: [
          "400 Fuzu points to use on Premium features",
          "Hundreds of job opportunities",
          "Personalised career guidance, courses and articles"
        ],
        imageProps: { src: mntImage, margin: "auto auto -40px", width: 395, smWidth: 240 },
        listProps: {
          lineHeight: isSm ? "18px" : "30px",
          margin: isSm ? 0 : "0 0 15px",
          fontSize: isSm ? 14 : 20,
          fontWeight: 400
        },
        beforeList: (
          <Text fontSize={isSm ? 14 : 20} margin="0 0 8px" style={{ maxWidth: 570 }}>
            <b>Hello, Pulsers!</b> Sign up to Fuzu, Africa’s fastest growing career community, and unlock access to:
          </Text>
        )
      };
    }

    return {
      Logo: (
        <Image
          src={fuzuLogo}
          onClick={() => history.push("/")}
          width={isSm ? 100 : 240}
          padding="0"
          borderRadius="0"
          style={{ cursor: "pointer" }}
          margin={isStandalone ? "0 auto 0 0" : isSm ? "0 auto 30px 0" : "0 auto 50px"}
          display="block"
        />
      ),
      featureList: [
        t("login.list.receive_personalized_text"),
        t("login.list.get_feedback_text"),
        t("login.list.plan_your_text"),
        t("login.list.access_hundreds_text")
      ],
      listProps: { lineHeight: isSm ? "130%" : "30px", margin: "0 0 15px", fontSize: isSm ? 16 : 20, fontWeight: 600 },
      imageProps: { src: welcomeImage }
    };
  })();

  useEffect(() => {
    if (isMtn && isNewRegistrationFlow) {
      disableNewRegistrationFlow();
    }
  }, [isMtn, isNewRegistrationFlow]);

  return (
    <AuthLeftContainer
      $backGroundImage={
        isBarona ? theme.primary : `radial-gradient(105.74% 105.74% at 50% 100%, ${theme.primary} 44.47%, #F3A900 100%)`
      }
      isHideHeight={isStandalone || isBarona}
    >
      <AuthLeftContent>
        {Logo}
        {beforeList}
        {!isStandalone && (
          <div className="list-vertical">
            {featureList.map(text => (
              <Label
                key={text}
                Icon={<ListMarkerGreen width={isSm ? 20 : 24} style={{ marginRight: "16px" }} />}
                fill="transparent"
                noBackground
                color="black500"
                padding="0"
                {...listProps}
              >
                {text}
              </Label>
            ))}
          </div>
        )}
        <AuthWelcomeImage {...imageProps} />
      </AuthLeftContent>
    </AuthLeftContainer>
  );
});

const DefaultContentKepsa = observer(({ isSm }) => {
  return (
    <AuthLeftContainer $backGroundImage="linear-gradient(0deg, #F3A600 -106.2%, #FFF09F 57.72%)">
      <AuthLeftContent>
        <Content>
          <LogoLinkKepsa to="/">
            <img src={kepsaLogo} alt="Kepsa Logo" />
          </LogoLinkKepsa>
          <AuthTitleKepsa>
            Welcome to <br />
            KEPSA Ajira!
          </AuthTitleKepsa>
          <AuthTextKepsa>
            We create and commercialize quality solutions and products through research, consulting, training and
            innovation to transform individuals and institutions in Africa and beyond
          </AuthTextKepsa>

          {!isSm ? (
            <Row gutterWidth={8} style={{ marginTop: "auto" }}>
              <Col width="content" style={{ display: "flex" }}>
                <Text inline>Powered by</Text>
              </Col>
              <Col width="content">
                <Image src={fuzuLogo} width={68} borderRadius="0" padding="0" />
              </Col>
            </Row>
          ) : null}
        </Content>
      </AuthLeftContent>
    </AuthLeftContainer>
  );
});

const CareerPortalContent = ({ isSm }) => {
  const { name, employer } = useContext(BrandedContext);

  return (
    <AuthLeftContainer $backGroundImage={`url(${careerPortalBackground})`}>
      <AuthLeftContent>
        <Image
          src={employer?.logo}
          width={isSm ? 150 : 300}
          height={isSm ? 70 : 140}
          padding={isSm ? "5px" : "15px"}
          margin="0 auto"
          borderRadius="0"
          display="block"
          backgroundColor="white"
        />
        <Title
          margin={isSm ? "30px 0 auto" : "70px 0 auto"}
          fontWeight={700}
          textAlign="center"
          style={{ fontSize: isSm ? 28 : 48 }}
        >
          Welcome to {name} jobs!
        </Title>
        <Row gutterWidth={8} style={{ marginTop: 16 }}>
          <Col width="content" style={{ display: "flex" }}>
            <Text inline>Powered by</Text>
          </Col>
          <Col width="content">
            <Image src={fuzuLogo} width={68} borderRadius="0" padding="0" />
          </Col>
        </Row>
      </AuthLeftContent>
    </AuthLeftContainer>
  );
};

const CustomPlatformContent = () => {
  const { slug } = useContext(BrandedContext);
  const isNCPWD = slug === "ncpwd";
  const customJkuat = isCustomJkuat();

  const renderBackground = () => {
    if (isNCPWD) {
      return `url(${ncpwdImage})`;
    }

    if (customJkuat) {
      return "linear-gradient(180deg, #E1F6E0 35.22%, #FDF394 100%)";
    }

    return `url(${contributeImage})`;
  };

  const renderImage = () => {
    if (isNCPWD) {
      return ncpwdLogo;
    }

    if (customJkuat) {
      return jkuatLogo;
    }

    return demoLogo;
  };

  return (
    <AuthLeftContainer isJKUAT={customJkuat} $backGroundImage={renderBackground()}>
      <AuthLeftContent isJKUAT={customJkuat}>
        <a href={window.location.origin} className="logo-link">
          <Image
            src={renderImage()}
            width="112px"
            height="112px"
            alt="Logo"
            padding="8px"
            margin="0 auto"
            borderRadius="8px"
            display="block"
            backgroundColor="white"
          />
        </a>
        {customJkuat && (
          <>
            <AuthInfo>
              <AuthTitle>
                Welcome to <span>JKUAT Enterprises jobs!</span>
              </AuthTitle>
              <AuthText>
                We create and commercialize quality solutions and products through research, consulting, training and
                innovation to transform individuals and institutions in Africa and beyond
              </AuthText>
            </AuthInfo>
            <BrandVisible brand={brandName.jkuat}>
              <PoweredByFuzu isHideMobile />
            </BrandVisible>
          </>
        )}
      </AuthLeftContent>
    </AuthLeftContainer>
  );
};

export default LeftSide;
