import React, { useState, useContext, useRef, useMemo } from "react";
import { toJS } from "mobx";
import { Reset, Search, X } from "@styled-icons/boxicons-regular";
import { Row, Col, Visible } from "react-grid-system";
import { useTranslation } from "b2c/hooks/useTranslation";

import SearchRow from "b2c/components/SearchRow";
import { Title, Button, Icon } from "../Core";
import SearchFilters from "../SearchFilters";
import Layout from "../Layout";
import ThemeContext from "../../contexts/theme";
import { StyledFilters } from "./styled";
import { useLanguageAndCountryUrl } from "../../hooks";

const SearchTools = ({
  state,
  slug,
  applyFilter,
  appliedFilters,
  searchFilters,
  applicationFilters,
  resetFilters,
  appliedCount,
  isMobile,
  getPageInfo,
  handleApplyFilter,
  selectedAppliedFilters
}) => {
  const jobType = !/settings|applications/.test(slug);
  const theme = useContext(ThemeContext);
  const [enabled, enableFilters] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const countryTownRef = useRef();
  const { t } = useTranslation();
  const languageAndLanguage = useLanguageAndCountryUrl();

  const batchReset = () => {
    resetFilters(languageAndLanguage);
    setSearchTerm("");
  };

  const handleSearchAndHideFilters = () => {
    const townId =
      appliedFilters.town_id && Array.isArray(appliedFilters.town_id)
        ? appliedFilters.town_id[0]
        : appliedFilters.town_id;

    const updateAppliedFilters = {
      ...appliedFilters,
      country_id: !languageAndLanguage ? appliedFilters.country_id : null,
      town_id: townId || null
    };
    handleApplyFilter({
      ...updateAppliedFilters,
      term: searchTerm,
      languageAndLanguage
    });

    enableFilters(false);

    if (countryTownRef.current) {
      countryTownRef.current.click();
    }
  };

  const isShowClearFilterButton = useMemo(() => {
    const { job_id, country_id, ...rest } = selectedAppliedFilters;
    const fieldValues = Object.values(rest).filter(item => Boolean(item));

    return appliedCount && fieldValues.length && Object.values(rest).length;
  }, [
    selectedAppliedFilters.job_id,
    selectedAppliedFilters.country_id,
    appliedCount,
    Object.values(selectedAppliedFilters).length
  ]);

  return (
    <>
      <Visible sm xs>
        <Layout.Section backgroundColor="white" padding="12px 20px">
          <Row nowrap>
            <Col>
              <Button
                width="100%"
                onClick={() => enableFilters(true)}
                icon={appliedCount > 0 ? null : <Icon as={Search} width={20} fill="primaryButtonLabelColor" />}
                color="primaryButtonLabelColor"
              >
                {appliedCount > 0 ? t("jobs.search.edit_search_button") : t("jobs.search.click_to_search_button")}
              </Button>
            </Col>
            {isShowClearFilterButton ? (
              <Col>
                <Button width="100%" onClick={batchReset} theme="grey200" icon={<Icon as={Reset} width={20} />}>
                  {t("global.clear_filters_button")}
                </Button>
              </Col>
            ) : null}
          </Row>
        </Layout.Section>
      </Visible>

      {(!isMobile || enabled) && (
        <StyledFilters $backgroundColor={theme.white}>
          <Layout.Content size="xl">
            <Visible sm xs>
              <Row justify="between" style={{ marginBottom: 20 }}>
                <Col style={{ display: "flex" }}>
                  <Title level={5} fontWeight={600}>
                    {t("jobs.search.search_mobile_title")}
                  </Title>
                </Col>
                <Col width="content">
                  <Button.Iconed noBackground onClick={() => enableFilters(false)}>
                    <Icon as={X} width={24} />
                  </Button.Iconed>
                </Col>
              </Row>
            </Visible>

            {jobType && (
              <SearchRow
                loading={state.loading}
                countryIds={toJS(appliedFilters.country_id)}
                townIds={toJS(appliedFilters.town_id)}
                term={appliedFilters.term}
                applyFilter={applyFilter}
                resetFilters={batchReset}
                appliedCount={appliedCount}
                searchTermCallback={setSearchTerm}
                searchFilters={searchFilters}
                countryTownRef={countryTownRef}
                buttonText={t("jobs.search.show_jobs_button")}
                placeholder={t("jobs.search.show_jobs_button")}
                getPageInfo={getPageInfo}
                appliedFilters={toJS(appliedFilters)}
                handleSearchAndHideFilters={handleSearchAndHideFilters}
                isShowClearFilterButton={isShowClearFilterButton}
              />
            )}

            <SearchFilters
              jobType={jobType}
              applyFilter={applyFilter}
              searchFilters={searchFilters}
              applicationFilters={applicationFilters}
              appliedFilters={appliedFilters}
              showJobsInText
            />

            <Visible sm xs>
              <Col lg={2}>
                <Button
                  color="primaryButtonLabelColor"
                  margin="8px 0 0"
                  width="100%"
                  onClick={handleSearchAndHideFilters}
                >
                  {t("jobs.search.show_jobs_button")}
                </Button>
              </Col>

              {isShowClearFilterButton ? (
                <Col lg={2}>
                  <Button
                    width="100%"
                    onClick={batchReset}
                    theme="grey200"
                    margin="16px 0 0"
                    icon={<Icon as={Reset} width={20} />}
                  >
                    {t("global.clear_filters_button")}
                  </Button>
                </Col>
              ) : null}
            </Visible>
          </Layout.Content>
        </StyledFilters>
      )}
    </>
  );
};

export default SearchTools;
