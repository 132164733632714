import styled from "styled-components";

export const Container = styled.div`
  .content {
    padding: 0 16px;
  }

  @media screen and (min-width: 768px) {
    .content {
      padding: 0 20px;
    }

    .content_sm {
      padding-top: 20px;
    }
  }
`;
