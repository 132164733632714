import React, { useState, useLayoutEffect, useEffect, useContext } from "react";
import { observer } from "mobx-react";

import { useLocation } from "react-router-dom";
import { ModalContext } from "contexts/modal";
import GuestModeContext from "../../contexts/guest";
import BrandedModeContext, {
  isCustomJkuat,
  isCustomPlatform,
  isWhitelabelled,
  matchBrand
} from "../../contexts/branded";
import useStore from "../../contexts/store";

import ModalContent from "./ModalContent";

let promptInterval;

const GuestPromptResolver = ({ active, children }) => {
  const { pathname, search } = useLocation();
  const { toggle, content } = useContext(ModalContext);
  const [routeChangeCount, setRouteChangeCount] = useState(1);

  const handleReset = toggleCb => {
    toggleCb();
    setRouteChangeCount(1);
  };

  useEffect(() => {
    if (routeChangeCount >= 5) {
      window?.clearTimeout(promptInterval);
      if (active) toggle(<ModalContent onClose={() => handleReset(toggle)} />, "");
    } else setRouteChangeCount(routeChangeCount + 1);
  }, [pathname, search]);

  useEffect(() => {
    if (!content)
      promptInterval = setTimeout(() => {
        if (active) toggle(<ModalContent onClose={() => handleReset(toggle)} />, "");
      }, 60000);

    return () => {
      window?.clearTimeout(promptInterval);
    };
  }, [active, content]);

  return children;
};

const GuestModeManager = ({ children }) => {
  const {
    User: { user }
  } = useStore();
  const { base_domain } = useStore("initialState");
  const { setGuestMode } = useContext(GuestModeContext);
  const { slug } = useContext(BrandedModeContext);
  const customJkuat = isCustomJkuat();
  const customPlatform = isCustomPlatform();
  const whitelabelled = isWhitelabelled();

  useLayoutEffect(() => {
    const { protocol, port } = window.location;
    const isWhiteLabeledOrJkuat = customJkuat ? false : !whitelabelled;
    setGuestMode(!user?.id && isWhiteLabeledOrJkuat);

    if (!!user?.id && !matchBrand(user.brand, slug) && customPlatform && !customJkuat) {
      window.location.replace(`${protocol}//${user.brand?.slug}.${base_domain}:${port}`);
    }
  }, [user?.id]);

  return user && user.id ? children : <GuestPromptResolver active={false}>{children}</GuestPromptResolver>;
};

export default observer(GuestModeManager);
