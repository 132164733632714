import React from "react";

import Highlight from "../../Highlight";
import ArticleCarousel from "./ArticleCarousel";
import Single from "./Single";

const Heading = ({ type, loading, ...rest }) => {
  const InnerContent = () => {
    switch (type) {
      default:
      case "onboarding":
        return <Single {...rest} />;
      case "carousel":
        return <ArticleCarousel {...rest} />;
    }
  };

  return (
    <Highlight highlightId="carousel" desktopOnly>
      <InnerContent />
    </Highlight>
  );
};

export default Heading;
