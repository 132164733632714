import React from "react";
import parse, { domToReact } from "html-react-parser";

import { Text, Card, CollapsibleCard, List, Image } from "../Core";
import { buildImageUrlS3 } from "../../contexts/branded";
import { nameImages } from "../../constants/images";

const InfoSection = ({ data }) => {
  const parseOptions = {
    trim: true,
    replace: ({ name, type, children, parent, data: dataItem }) => {
      switch (name) {
        case "p":
          return <Text lineHeight="140%">{domToReact(children, parseOptions)}</Text>;
        case "ul":
          return <List style={{ marginLeft: 20 }}>{domToReact(children, parseOptions)}</List>;
        case "li":
          return <List.Item>{domToReact(children, parseOptions)}</List.Item>;
        case "br":
          return <br />;

        default:
          return type === "text" && !children && !parent ? (
            <Text lineHeight="140%" style={{ display: "inline" }}>
              {dataItem}
            </Text>
          ) : (
            children
          );
      }
    }
  };

  return data.map(({ id, title, text, sections }, i) => (
    <CollapsibleCard
      key={`section_uid_${id || i}`}
      style={{ marginBottom: 20 }}
      shadow
      headingContent={
        <>
          <Image
            src={buildImageUrlS3(nameImages.faq)}
            style={{ maxWidth: "100%", display: "block", textAlign: "center", margin: "auto 15px auto 0" }}
          />

          <Text fontSize={16} fontWeight={600}>
            {title}
          </Text>
        </>
      }
    >
      {sections
        ? sections.map((item, index) => (
            <Card key={`faq_uid_${item.id}`} theme="grey100" bordered style={{ marginTop: index === 0 ? 0 : 16 }}>
              <Card.Content>
                <Text fontSize={16} fontWeight={600} margin="0 0 4px">
                  {item.title}
                </Text>
                <div>{parse(item.text, parseOptions)}</div>
              </Card.Content>
            </Card>
          ))
        : parse(text, parseOptions)}
    </CollapsibleCard>
  ));
};

export default InfoSection;
