import React from "react";

import fuzuLogoBlack from "images/new_fuzu_logo_black.png";
import { Image } from "b2c/components/Core";
import { Text, Flex } from "./styled";

const PoweredByFuzu = ({ isHideMobile, margin }) => {
  return (
    <Flex isHideMobile={isHideMobile} margin={margin}>
      <Text>Powered by</Text>
      <Image src={fuzuLogoBlack} />
    </Flex>
  );
};

export default PoweredByFuzu;
