import React from "react";
import styled from "styled-components";

const Impersonate = ({ impersonateUser }) => {
  const { email } = impersonateUser;
  return (
    <StyledContainer>
      <p>
        You are impersonating a user ({email}).
        <a href="/support/users/cancel_impersonate">Cancel impersonation</a>
      </p>
    </StyledContainer>
  );
};

const StyledContainer = styled.header`
  p {
    background-color: #da332f;
    text-align: center;
  }
  a {
    margin-left: 10px;
  }
`;

export default Impersonate;
