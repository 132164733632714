import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { ScreenClassContext } from "react-grid-system";
import Skilled from "images/b2c/onboarding/skilled.svg";
import Expert from "images/b2c/onboarding/expert.svg";
import { jobTypes } from "b2c/constants/onboarding";
import { useTranslation } from "b2c/hooks/useTranslation";
import { Checkbox } from "../../../../components/Core";
import Content from "../Content";
import { Container, Item, Title, Description, ImageContainer } from "./styled";
import Navigation from "../Navigation";
import Steps from "../Steps";

const jobTypesList = [
  {
    id: "skilled",
    Image: Skilled,
    title: "onboarding.interests.list.title_1",
    text: "onboarding.interests.list.description_1",
    dataCy: "skilled"
  },
  {
    id: "expert",
    Image: Expert,
    title: "onboarding.interests.list.title_2",
    text: "onboarding.interests.list.description_2",
    dataCy: "expert"
  }
];

const JobType = () => {
  const screen = useContext(ScreenClassContext);
  const isSm = /xs|sm/.test(screen);
  const [selected, setSelected] = useState({});
  const history = useHistory();
  const { t } = useTranslation();

  const handleSelect = event => {
    const selectedType = jobTypesList.find(({ id }) => id === event.target.value);
    setSelected(selectedType);
  };

  const handleSubmit = () => {
    const prevOnboardingForm = JSON.parse(sessionStorage.getItem("onboardingFlow"));

    const onboardingForm = {
      ...prevOnboardingForm,
      jobType: selected?.id
    };

    sessionStorage.setItem("onboardingFlow", JSON.stringify(onboardingForm));

    if (jobTypes.skilled === selected.id) {
      history.push("/onboarding/pick-job");
    } else {
      history.push("/onboarding/job-titles");
    }
  };

  useEffect(() => {
    const onboardingFlow = JSON.parse(sessionStorage.getItem("onboardingFlow"));
    if (onboardingFlow?.jobType && !selected.id) {
      setSelected(jobTypesList.find(({ id }) => id === onboardingFlow.jobType));
    }
  }, []);

  return (
    <Container data-cy="job-type">
      <Steps width="120px" />

      <Content title={t("onboarding.interests.title")} description={t("onboarding.interests.description")}>
        {jobTypesList.map(({ id, text, Image, title, dataCy }) => (
          <div key={id}>
            <Checkbox
              id={id}
              name="interest"
              value={id}
              borderedView
              width="100%"
              margin="6px 0"
              onChange={handleSelect}
              checked={selected.id === id}
              markColor="white"
              checkedColor="secondary200"
              checkedBgColor="secondary100"
              $markMargin={isSm ? "auto 0" : "0"}
              style={{
                outline: "none"
              }}
              labelProps={{
                style: {
                  flexDirection: "row-reverse",
                  justifyContent: "space-between",
                  borderColor: selected?.id === id ? "#4690FF" : "#C5C5C5",
                  marginBottom: "16px",
                  padding: "15px 16px",
                  borderRadius: "8px",
                  alignItems: "center"
                }
              }}
              checkboxMarkProps={{
                style: {
                  borderRadius: "50%"
                }
              }}
              label={
                <Item data-cy={dataCy}>
                  <ImageContainer>
                    <Image />
                  </ImageContainer>

                  <div>
                    <Title>{t(title)}</Title>

                    <Description>{t(text)}</Description>
                  </div>
                </Item>
              }
            />
          </div>
        ))}
      </Content>

      <Navigation backUrl="/onboarding/country" isDisabled={!selected?.id} handleSubmit={handleSubmit} />
    </Container>
  );
};

export default JobType;
