import React from "react";
import getFuzu from "images/getFuzu.png";

import { Image } from "b2c/components/Core";
import { ArrowToBottom } from "@styled-icons/boxicons-regular";

import {
  Container,
  Content,
  Heading,
  Title,
  Steps,
  StepsItem,
  StepsCycle,
  ImageContent,
  Info,
  InfoItem,
  ArrowContent
} from "./styled";

import Availability from "../Availability";

const GetApp = ({ getAppRef }) => {
  return (
    <Container ref={getAppRef}>
      <Content>
        <Heading>
          <Title>Get the app for free on your mobile phone</Title>
          <Availability isGetApp />
        </Heading>
        <Steps>
          <StepsItem>
            <StepsCycle />
            Open your Mobile Browser and login to Fuzu.com
          </StepsItem>
          <StepsItem>
            <StepsCycle />
            Browse a page for 30 seconds or more
          </StepsItem>
          <StepsItem>
            <StepsCycle />
            Push notification will appear on the top. Click Install to download the App and save it on Home Screen.
          </StepsItem>
        </Steps>
        <ImageContent>
          <Image src={getFuzu} />
        </ImageContent>
        <Info>
          <InfoItem>
            <ArrowContent>
              <ArrowToBottom width="24px" height="24px" />
            </ArrowContent>
            That didn’t work?
            <ArrowContent>
              <ArrowToBottom />
            </ArrowContent>
          </InfoItem>
        </Info>
      </Content>
    </Container>
  );
};

export default GetApp;
