import React, { useContext } from "react";

import { Close, ArrowBack } from "@styled-icons/evaicons-solid";

import ThemeContext from "b2c/contexts/theme";
import Button from "b2c/components/Core/Button";

import { StyledHeader, StyledHeaderContent, StyledBody, StyledBodyContent } from "./styled";

const NotificationsCenterLayout = ({ title, withBackwardButton = false, onBackward, onClose, children }) => {
  const Theme = useContext(ThemeContext);

  return (
    <>
      <StyledHeader>
        <StyledHeaderContent
          style={{
            paddingLeft: withBackwardButton ? "22px" : "14px"
          }}
        >
          {withBackwardButton && (
            <Button.Iconed
              width={24}
              height={24}
              theme="transparent"
              onClick={onBackward}
              style={{
                color: Theme.black350
              }}
            >
              <ArrowBack width={24} />
            </Button.Iconed>
          )}

          {title}
        </StyledHeaderContent>

        <Button.Iconed
          theme="transparent"
          onClick={onClose}
          style={{
            color: Theme.grey700
          }}
        >
          <Close width={24} />
        </Button.Iconed>
      </StyledHeader>

      <StyledBody>
        <StyledBodyContent>{children}</StyledBodyContent>
      </StyledBody>
    </>
  );
};

export default NotificationsCenterLayout;
