import React, { useState, useEffect } from "react";
import { Row, Col } from "react-grid-system";

import { RadioButton, Card, Text, Image, Alert } from "../../../../components/Core";
import { LoadingContent } from "../../../../components/Core/Spinner";

const BankTransfer = ({ disableTransactions, options, onMount, onSubmit, Actions = () => null, ...rest }) => {
  const [provider, setProvider] = useState({});

  useEffect(() => {
    if (!options) onMount();
  }, []);

  const disableSubmit = !provider.slug;

  return (
    <>
      <Card bordered>
        <Card.Content>
          <LoadingContent loading={!options} size="50px" margin="48px auto">
            {options?.length > 0 ? (
              options?.map(option => (
                <RadioButton
                  id={option.slug}
                  key={option.slug}
                  boxedView
                  Label={
                    <Row>
                      <Col width="content">
                        <Image src={option.logo_url} width={50} />
                      </Col>
                      <Col style={{ display: "flex" }}>
                        <Text fontSize={16} fontWeight={500}>
                          {option.name}
                        </Text>
                      </Col>
                    </Row>
                  }
                  checked={provider.slug == option.slug}
                  onChange={() => setProvider(option)}
                />
              ))
            ) : (
              <Alert type="neutral">Bank transfer is not supported for your country</Alert>
            )}
          </LoadingContent>
        </Card.Content>
      </Card>

      <Actions disabled={disableSubmit} onSubmit={() => onSubmit(provider, { bank_code: provider.slug })} {...rest} />
    </>
  );
};

export default BankTransfer;
