import React, { useState } from "react";

import { onEnterPress } from "utils/helpers";
import { validateEmail, validateLogin } from "utils/validation";
import { Input, Button, Text } from "b2c/components/Core";
import { ButtonBack } from "./styled";

const NcpwdCheck = ({
  state: { loading, processing, message },
  ncpwd_id,
  email,
  checkEmail,
  ncpwdLogin,
  setEmail,
  setStep
}) => {
  const [formState, setFormState] = useState({
    ncpwd_id,
    email,
    ...(email ? { password: "" } : {})
  });

  const inputDisabled = !!email;

  const [validationState, setValidation] = useState({
    email: { error: false },
    password: { error: false }
  });

  const handleSubmission = e => {
    e.preventDefault();

    if (formState?.hasOwnProperty("password")) {
      const { valid, validation } = validateLogin(formState);

      return valid
        ? ncpwdLogin(formState).then(() => {
            setStep(3);
          })
        : setValidation(validation);
    }
    const emailValid = validateEmail(formState?.email);

    if (emailValid.test) {
      checkEmail(formState?.email).then(exists => {
        setEmail(formState?.email);
        if (!exists) setStep(1);
        else setFormState(prev => ({ ...prev, password: "" }));
      });
    } else setValidation({ ...validationState, email: emailValid });
  };

  return (
    <form onSubmit={handleSubmission} onKeyDown={e => onEnterPress(e, 1, () => handleSubmission(e))} noValidate>
      <Input
        label="Your email address"
        placeholder="example@domain.com"
        type="email"
        autoComplete="email"
        fieldRequired
        value={formState?.email}
        disabled={inputDisabled}
        onChange={({ target: { value } }) => setFormState(prev => ({ ...prev, email: value }))}
        errorMessage={validationState?.email?.error}
      />

      {formState?.hasOwnProperty("password") && (
        <>
          <Text margin="0 0 8px">
            We found a Fuzu account with this email. Please enter your Fuzu account password to proceed.
          </Text>
          <Input
            label="Your Fuzu account password"
            placeholder="Enter your password"
            type="password"
            fieldRequired
            onChange={({ target: { value } }) => setFormState(prev => ({ ...prev, password: value }))}
            errorMessage={validationState.password.error}
          />
        </>
      )}

      {message && (
        <Text color="accent300" margin="4px 0 0">
          {message}
        </Text>
      )}

      <Button
        type="submit"
        disabled={!formState?.email || loading}
        processing={processing}
        fontSize="14px"
        width="100%"
        height="48px"
        margin="20px 0"
      >
        Continue
      </Button>
      <ButtonBack type="button" onClick={() => setStep(0)}>
        Back
      </ButtonBack>
    </form>
  );
};

export default NcpwdCheck;
