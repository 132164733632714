import React from "react";
import { RightArrowAlt } from "@styled-icons/boxicons-regular";
import parse from "html-react-parser";

import b2bOfferPeopleImage from "images/b2c/landing-page/b2b-offer-people.png";
import { useTranslation } from "b2c/hooks/useTranslation";
import Decoration from "images/b2c/landing-page/b2b-offer-decor.svg";
import {
  B2bOfferSectionContainer,
  B2bOfferSectionContent,
  LeftSide,
  Title,
  Description,
  Actions,
  Hire,
  HireRemote,
  RightSide,
  B2bOfferPeople,
  DecorationContent
} from "./styled";
import { pathWithCountry } from "../../../../utils/routes";
import { useGetCurrentCountry } from "../../../../hooks";
import Icon from "../../../../components/Core/Icon";

const B2bOfferSection = () => {
  const country = useGetCurrentCountry();
  const { t } = useTranslation();

  const handleScrollTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <B2bOfferSectionContainer>
      <B2bOfferSectionContent>
        <LeftSide>
          <Title>{parse(t("landing_fuzu.employer_offers.title"))}</Title>
          <Description>{t("landing_fuzu.employer_offers.description")} </Description>
          <Actions>
            <Hire to={pathWithCountry(country, "/employers")} onClick={handleScrollTop}>
              {t("landing_fuzu.employer_offers.hire_in_africa_button")}
              <Icon as={RightArrowAlt} width={16} fill="grey750" margin="0 0 0 6px" />
            </Hire>

            <HireRemote href="https://global.fuzu.com/">
              {t("landing_fuzu.employer_offers.hire_remote_talent")}
              <Icon as={RightArrowAlt} width={16} fill="body" margin="0 0 0 6px" />
            </HireRemote>
          </Actions>
        </LeftSide>
        <RightSide>
          <DecorationContent>
            <Decoration />
          </DecorationContent>
          <B2bOfferPeople src={b2bOfferPeopleImage} />
        </RightSide>
      </B2bOfferSectionContent>
    </B2bOfferSectionContainer>
  );
};

export default B2bOfferSection;
