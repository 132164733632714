import React, { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react";
import { Row, Col, Hidden, Visible, useScreenClass } from "react-grid-system";

import { showDownloaded } from "utils/helpers";
import Layout from "b2c/components/Layout";
import { useTranslation } from "b2c/hooks/useTranslation";
import { Button, Title } from "b2c/components/Core";
import Preview from "../Preview";
import BuilderOptions from "../BuilderOptions";
import PlainModalContent from "../PlainModalContent";
import PurchaseModalContent from "../PurchaseModalContent";
import useStore from "../../../../contexts/store";
import { useModal } from "../../../../../utils/hooks";
import { StepWrapper } from "./styled";

const CustomizeStep = ({ CloseButton, update }) => {
  const {
    state: { loading, processing },
    options,
    templates,
    applied,
    getOptions,
    applyOptions,
    currentTemplate: { id, name, product },
    cvUrl,
    initialPath,
    getTemplates
  } = useStore("CvBuilder");
  const isSm = /xs|sm/.test(useScreenClass());
  const parentRef = useRef();
  const { t } = useTranslation();

  const openPlainModal = useModal(
    <PlainModalContent
      downloadCv={() => {
        showDownloaded(cvUrl, name);
      }}
      selectPremium={() => {
        applyOptions({ template_id: 1 });
      }}
    />,
    ""
  );
  const openPurchaseModal = useModal(<PurchaseModalContent product={product || {}} from={initialPath} />, "");
  const [optionsView, setOptionsView] = useState(false);

  useEffect(() => {
    getTemplates();
  }, []);

  useEffect(() => {
    if (update) getOptions(applied.template_id || 1);
  }, [update]);

  const GetCvButton = props => {
    return (
      <Button processing={loading || processing} onClick={getCv} {...props}>
        {cvUrl || loading || processing
          ? t("cv_creator.flow.download_cv")
          : `${t("cv_creator.flow.get_cv")} (${product?.currency} ${product?.discounted_price})`}
      </Button>
    );
  };

  const getCv = () => {
    if (id == 4) openPlainModal();
    else if (cvUrl) showDownloaded(cvUrl, name);
    else openPurchaseModal();
  };

  return (
    <>
      <CloseButton alternative={!isSm} />
      <StepWrapper ref={parentRef} column={isSm}>
        <Hidden sm xs>
          <Layout.Section style={{ flex: "0 0 360px", maxHeight: "100vh", overflow: "auto" }}>
            <Layout.Content tag="div" size="sm">
              <Title level={3} fontWeight={700} margin="24px 0">
                2. {t("cv_creator.flow.customize_and_preview")}
              </Title>
            </Layout.Content>
            <BuilderOptions
              processing={processing}
              options={options}
              templates={templates}
              applied={applied}
              applyOptions={applyOptions}
            />
          </Layout.Section>
          <Layout.Section
            backgroundColor="black400"
            style={{ flex: "1 0 auto", height: "100%", position: "relative", maxWidth: "calc(100% - 360px)" }}
          >
            <Preview GetCvButton={GetCvButton} />
          </Layout.Section>
        </Hidden>
        <Visible xs sm>
          {optionsView && (
            <BuilderOptions
              processing={processing}
              options={options}
              templates={templates}
              applied={applied}
              applyOptions={applyOptions}
              close={() => {
                setOptionsView(false);
              }}
            />
          )}
          <Layout.Section style={{ width: "100%", height: "10vh", display: "flex" }}>
            <Layout.Content tag="div" size="sm" style={{ height: "100%", display: "flex" }}>
              <Title level={4} fontWeight={600} margin="auto">
                2. {t("cv_creator.flow.customize_and_preview")}
              </Title>
            </Layout.Content>
          </Layout.Section>
          <Layout.Section
            backgroundColor="black400"
            padding="0 0 32px"
            style={{ flex: "1 1 80%", height: "100%", position: "relative" }}
          >
            <Preview />
          </Layout.Section>
          <Layout.Section padding="16px 0" style={{ width: "100%", flex: "1 0 auto" }}>
            <Layout.Content tag="div" size="sm" style={{ width: "100%" }}>
              <Row>
                <Col xs={6}>
                  <Button
                    width="100%"
                    onClick={() => {
                      setOptionsView(true);
                    }}
                  >
                    {t("cv_creator.flow.customize")}
                  </Button>
                </Col>
                <Col xs={6}>
                  <GetCvButton width="100%" />
                </Col>
              </Row>
            </Layout.Content>
          </Layout.Section>
        </Visible>
      </StepWrapper>
    </>
  );
};

export default observer(CustomizeStep);
