import React, { useContext, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row, ScreenClassContext, Visible } from "react-grid-system";

import { onEnterPress } from "utils/helpers";
import phone_email from "images/b2c/phone_email.png";
import { Button, Image, Input, Text, Suggestion } from "b2c/components/Core";
import { GuestVisible } from "../../../../components/WithAuth";

const SuggestionComponent = ({ jobTitle, companyName, ...rest }) => {
  const [email, setEmail] = useState("");
  const cta = useRef();
  const screen = useContext(ScreenClassContext);
  const isSm = ["xs", "sm", "md"].includes(screen);

  const handleEmailChange = ({ target: { value } }) => {
    setEmail(value);
  };

  const handleEnterPress = e => {
    onEnterPress(e, email, () => cta.current.click());
  };

  const handleSubmit = () => {
    if (email) {
      const onboardingForm = {
        email
      };
      localStorage.setItem("onboardingForm", JSON.stringify(onboardingForm));
    }
  };

  return (
    <GuestVisible>
      <Suggestion style={{ borderRadius: 6 }} {...rest}>
        <Row>
          <Visible xxl xl lg>
            <Col width="content">
              <Image display="block" src={phone_email} width={200} margin="28px 24px 0" />
            </Col>
          </Visible>
          <Col
            lg={7}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly"
            }}
          >
            <Text fontSize={isSm ? 18 : 24} margin={isSm ? "12px 0" : "20px 0 0"} textAlign={isSm ? "center" : "left"}>
              Don’t miss your chance to work at <span style={{ fontWeight: "bold" }}>{companyName}</span>. Enter your
              email to start your application now
            </Text>
            <Row>
              <Col lg={7}>
                <Input
                  onKeyDown={handleEnterPress}
                  value={email}
                  inputHeight="42px"
                  placeholder="Email address"
                  onChange={handleEmailChange}
                />
              </Col>
              <Col lg={4}>
                <Button
                  as={Link}
                  ref={cta}
                  theme="accent300"
                  width="100%"
                  margin={isSm ? "0 auto 12px" : "0"}
                  to="/onboarding"
                  onClick={handleSubmit}
                >
                  Start application
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Suggestion>
    </GuestVisible>
  );
};

export default SuggestionComponent;
