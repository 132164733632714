import styled from "styled-components";

export const Label = styled.p`
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
`;

export const Title = styled.h4`
  color: #333;
  font-size: 20px;
  font-weight: 600;
  line-height: 130%;
`;
