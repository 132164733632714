import styled from "styled-components";
import { theme } from "../../../../contexts/theme";

export const AuthorFullInfoWrapper = styled.div`
  display: flex;
  ${props => (props.loaded ? "animation: loaded 0.5s forwards;" : "")}
  ${props => props.styled}
  ${props => (props.linkable ? "cursor: pointer;" : "")}
`;

export const Avatar = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  object-fit: cover;
  width: 56px;
  height: 56px;
  margin-right: 15px;
`;

export const AvatarPlaceholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  object-fit: cover;
  width: 56px;
  height: 56px;
  margin-right: 15px;
  background-color: ${theme.primary100};
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InfoRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    .text-divider {
      display: none;
    }
  }
`;
