import styled from "styled-components";
import { Card } from "../../Core";

export const HeroCard = styled(Card)`
  ${({ background }) => `
        background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${background});
        background-size: cover;
        cursor: pointer;
    `}
`;

export const HeadingWrapper = styled.div`
  overflow: hidden;
  z-index: 1;
  display: block;
  background-color: #fff;
  border-radius: 8px;
  padding: 0;
  position: relative;
  margin: 0 auto;
  text-decoration: none;
  outline: none;
  @media screen and (max-width: 767px) {
    border-radius: 0;
  }
`;

export const MainImage = styled.div`
  max-width: 100%;
  height: 100%;
  min-height: 320px;
  position: relative;
  img {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

export const HeadingContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 16px;
`;

export const HeadingMobileContent = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 15px;
  width: 100%;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
`;
