import React from "react";
import parse from "html-react-parser";

import { Text } from "b2c/components/Core";
import { theme } from "../../../../contexts/theme";
import { AuthorFullInfoWrapper, Avatar, AvatarPlaceholder, DetailsContainer, InfoRow } from "./styled";
import { buildImageUrlS3 } from "../../../../contexts/branded";
import { nameImages } from "../../../../constants/images";
import { useTranslation } from "../../../../hooks/useTranslation";

const AuthorFullInfo = ({
  name = "",
  margin = "0 0",
  avatarImg,
  description = "",
  linkable = false,
  loading = false,
  loaded,
  onClick
}) => {
  const avatar = avatarImg || buildImageUrlS3(nameImages.avatarEmpty);
  const { t } = useTranslation();

  return !loading ? (
    <AuthorFullInfoWrapper styled={{ margin }} loaded={loaded} linkable={linkable} onClick={onClick}>
      <Avatar src={avatar} />
      <DetailsContainer>
        <Text
          styled={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            margin: "0",
            fontSize: "14px",
            color: theme.black200
          }}
        >
          {t("forum.written_by")}
        </Text>

        <Text fontWeight={500} fontSize={14} margin="0" color="black500">
          {name}
        </Text>

        <Text fontSize={14} margin="12px 0 0" color="black300">
          {description ? <span>{parse(description)}</span> : <i>{t("forum.author_empty_description")}</i>}
        </Text>
      </DetailsContainer>
    </AuthorFullInfoWrapper>
  ) : (
    <AuthorFullInfoWrapper styled={{ margin }} loaded={loaded}>
      <AvatarPlaceholder />
      <DetailsContainer>
        <InfoRow>
          <Text fontWeight={400} fontSize={14} margin="0" color="black500" loading />
          <span className="blog-post__text-divider" />
          <Text fontWeight={400} fontSize={14} margin="0" color="black300" loading />
        </InfoRow>

        <Text fontWeight={500} fontSize={14} color="black500" margin="4px 0 0" loading />
      </DetailsContainer>
    </AuthorFullInfoWrapper>
  );
};

export default AuthorFullInfo;
