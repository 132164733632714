import React, { useContext, forwardRef } from "react";

import brandImgPlaceholder from "images/b2c/logo-placeholder.png";
import ThemeContext from "../../contexts/theme";
import Layout from "../Layout";
import { Title } from "../Core";
import LinkWithFullUrl from "../../../components/LinkWithFullUrl";
import { BrandsWrapper, BrandList, BrandItem } from "./styled";

const BrandsSection = (
  { title, companies = [], actionButton, background = "white", contentSize, padding = "32px 0", style },
  ref
) => {
  const Theme = useContext(ThemeContext);

  return (
    <BrandsWrapper padding={padding} background={background} ref={ref} style={style}>
      <Layout.Content tag="div" size={contentSize}>
        {title && (
          <Title level="2" fontWeight={600} scales margin="0 auto 32px" textAlign="center">
            {title}
          </Title>
        )}
        <BrandList>
          {companies?.map(({ id, logo, name, isExternal, href, path }, i) => (
            <BrandItem
              as={isExternal ? "a" : LinkWithFullUrl}
              key={id}
              className="lp-brand-itm"
              $borderColor={Theme.grey200}
              $borderActiveColor={Theme.primary}
              $hideIfOddOnMobile={companies?.length - 1 === i && companies?.length % 2 == 1}
              {...(isExternal
                ? {
                    href: href || `https://www.fuzu.com${path}`,
                    target: "_blank"
                  }
                : { to: path })}
            >
              <div className="lp-brand-itm__content">
                <img
                  className="lp-brand-itm__image"
                  src={logo || brandImgPlaceholder}
                  alt={name}
                  width="218"
                  height="108"
                  loading="lazy"
                />
              </div>
            </BrandItem>
          ))}
        </BrandList>
        {actionButton && <div style={{ textAlign: "center" }}>{actionButton}</div>}
      </Layout.Content>
    </BrandsWrapper>
  );
};

export default forwardRef(BrandsSection);
