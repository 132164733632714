import React, { useState, useContext, useEffect } from "react";
import { observer } from "mobx-react";
import parse from "html-react-parser";

import Layout from "b2c/components/Layout";
import { useTranslation } from "b2c/hooks/useTranslation";
import { Text, Carousel, Image, Ribbon, Spinner } from "b2c/components/Core";
import { Hidden, useScreenClass } from "react-grid-system";
import ThemeContext from "../../../../contexts/theme";
import useStore from "../../../../contexts/store";
import { PreviewWrapper, ProcessingMask } from "./styled";

const Preview = ({ GetCvButton = () => null }) => {
  const {
    state: { processing },
    pages
  } = useStore("CvBuilder");
  const isSm = /xs|sm/.test(useScreenClass());
  const theme = useContext(ThemeContext);
  const { t } = useTranslation();

  const [currentPage, setPage] = useState(0);
  const [imagesLoaded, setImagesLoaded] = useState([false]);

  useEffect(() => {
    if (pages && pages.length > 0) setImagesLoaded(pages.map(() => false));
  }, [pages]);

  const onImageLoaded = index => {
    setImagesLoaded(prev => {
      const tmp = [...prev];
      tmp[index] = true;
      return tmp;
    });
  };

  const previewReady = !processing && imagesLoaded.every(el => el == true);

  return (
    <>
      {!previewReady && (
        <ProcessingMask maskColor={theme.black400}>
          <Spinner
            size="100px"
            margin="auto"
            messageProps={{ color: "white", fontWeight: 600, fontSize: 18 }}
            message={t("cv_creator.flow.build_cv_loading")}
          />
        </ProcessingMask>
      )}
      <Layout.Content tag="div" size="sm" style={{ display: "flex", height: "100%" }}>
        <PreviewWrapper>
          <Carousel
            settings={{
              className: "wide-blue-buttons",
              centerPadding: isSm ? "12px" : "40px",
              adaptiveHeight: false,
              centerMode: true,
              initialSlide: currentPage,
              beforeChange: (_, nextIndex) => {
                setPage(nextIndex);
                window.scrollTo(0, 0);
              }
            }}
          >
            {pages?.map((page, index) => (
              <Carousel.Slide key={`preview-page-${index + 1}`} height="100%" padding={0}>
                {pages.length > 0 && (
                  <Text
                    color="white"
                    textAlign="center"
                    fontSize={isSm ? 14 : 16}
                    margin={isSm ? "8px auto" : "16px auto"}
                  >
                    {`${t("cv_creator.flow.page")} `} {currentPage + 1} / {pages.length}
                    <Hidden sm xs>
                      {parse(t("cv_creator.flow.page_description"))}
                    </Hidden>
                  </Text>
                )}
                <div className="image-wrapper">
                  <Ribbon size={240} theme="preview">
                    {t("cv_creator.flow.preview")}
                  </Ribbon>
                  <Image
                    src={page}
                    backgroundColor={theme.white}
                    width="auto"
                    height="calc(100vh - 240px)"
                    style={{ pointerEvents: "none" }}
                    onLoad={() => {
                      onImageLoaded(index);
                    }}
                  />
                </div>
              </Carousel.Slide>
            ))}
          </Carousel>
          <Hidden sm xs>
            {previewReady && <GetCvButton margin="16px auto 0" width="220px" height="56px" />}
          </Hidden>
        </PreviewWrapper>
      </Layout.Content>
    </>
  );
};

export default observer(Preview);
