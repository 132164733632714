import React from "react";
import { Link, useHistory } from "react-router-dom";

import categoryPlaceholder from "images/b2c/category_placeholder.jpg";
import { getCategoryBackground } from "utils/industryImageMap";
import { stringify } from "utils/qsUtil";
import { useTranslation } from "b2c/hooks/useTranslation";
import { Card, Text, Title } from "../../../../components/Core";
import withLoading from "../../../../components/withLoading";
import CategoryItemPlaceholder from "../CategoryItemPlaceholder";

const CategoryItem = ({ id, name, slug, skill_type, count, ...rest }) => {
  const background = getCategoryBackground(slug);
  const history = useHistory();
  const basePath = `${history.location.pathname}/${slug}?${stringify({
    page: 1,
    per_page: 30,
    filters: { category_id: id }
  })}`;
  const { t } = useTranslation();

  return (
    <Card theme="white" clickable shadow {...rest} as={Link} to={basePath}>
      <Card.Header padding="12px 16px 0" height={96} backgroundImage={background || categoryPlaceholder} />

      <Card.Content padding="7px 16px 15px" height={64}>
        <Title level={6} height={40} fontWeight={500} width="100%" maxLength={60} truncatedLine={2} title={name} loaded>
          {name}
        </Title>

        {count && (
          <Text color="black300">
            {count} {t("learn.helper_text")}
          </Text>
        )}
      </Card.Content>
    </Card>
  );
};

export default withLoading(CategoryItem, CategoryItemPlaceholder);
