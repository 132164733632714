import React, { Component, useEffect } from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import useStore from "./store";

class ErrorBoundaries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI
    return { hasError: true };
  }

  componentDidCatch() {
    // The error is logged automatically by New Relic; no need to log here
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

const UserProvider = ({ children, initUser, ...props }) => {
  const { user, updateUser } = useStore("User");

  useEffect(() => {
    if (initUser && !user) updateUser(initUser);
  }, [initUser, user, updateUser]); // Ensure all dependencies are listed

  return process.env.NODE_ENV !== "development" ? (
    <ErrorBoundaries user={toJS(user)} {...props}>
      {children}
    </ErrorBoundaries>
  ) : (
    children
  );
};

export default observer(UserProvider);
