import styled from "styled-components";
import { theme } from "../../contexts/theme";

export const UserAvatar = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  object-fit: cover;
  width: 42px;
  height: 42px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 16px;
  pointer-events: none;
`;

export const TypeAreaWrapper = styled.div`
  ${props => props.styled}
`;

export const TypeAreaContent = styled.div`
  display: flex;
  position: relative;
`;

export const TypeField = styled.textarea`
  border-radius: 5px;
  padding: 12px 16px 12px 70px;
  width: 100%;
  resize: none;
  color: ${theme.black500};
  border: 1px solid ${props => (props.isFilled ? theme.black500 : theme.grey300)};
  background-color: ${props => (props.isFilled ? theme.white : theme.grey100)};
  min-height: 66px;
  outline: none;
  transition: 0.2s ease;
  &:hover {
    border-color: ${theme.black500};
    background-color: ${props => (props.isFilled ? theme.white : theme.grey100)};
  }
  &:focus {
    border-color: ${theme.black500};
    background-color: #fff;
  }
`;

export const TypeFieldPlaceholder = styled.div`
  position: absolute;
  left: 70px;
  top: ${props => (props.$isActive ? "12px" : "0")};
  color: ${theme.black200};
  transition: ${props => (props.$loaded ? "0.2s" : "0s")} ease;
  pointer-events: none;
  opacity: ${props => (props.$isActive ? "1" : "0")};
`;

export const TypeAreaControls = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  overflow: hidden;
  height: ${props => (props.$isFilled ? "70px" : "0")};
  opacity: ${props => (props.$isFilled ? "1" : "0")};
  transition: 0.3s ease;
`;
