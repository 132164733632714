import styled from "styled-components";

export const BlurredImage = styled.div`
  background-image: url(${({ bgImage }) => bgImage});
  background-position: center bottom;
  background-size: cover;
  position: absolute;
  left: -5%;
  top: -5%;
  width: 110%;
  height: 110%;
  filter: blur(10px) brightness(0.5);
`;
