import styled from "styled-components";

export const Content = styled.div`
  padding: 32px;
  border-top: 1px solid #eee;
`;

export const Title = styled.h4`
  color: #333;
  font-size: 20px;
  font-weight: 600;
  line-height: 130%;
  margin: 0 0 4px 0;
`;

export const Label = styled.p`
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  display: block;
  margin: 0 0 24px 0;

  @media screen and (min-width: 768px) {
    width: 60%;
  }
`;

export const EducationItem = styled.div`
  padding: 24px;
  border-radius: 8px;
  border: 1px solid #eee;
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const EducationFlex = styled.div`
  @media screen and (min-width: 768px) {
    display: flex;
    justify-content: space-between;
  }
`;

export const EducationInfo = styled.div`
  margin-bottom: 24px;

  @media screen and (min-width: 768px) {
    margin-bottom: 0;
  }
`;

export const Error = styled.div`
  border-radius: 8px;
  background: #ffeae5;
  padding: 14px;
  display: flex;
  align-items: center;
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  gap: 8px;
  margin-bottom: 16px;

  svg {
    width: 20px;
    height: 20px;
    fill: #e71d36;
    flex: none;
  }
`;

export const EducationTitle = styled.h6`
  color: #333;
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 4px 0;
`;

export const EducationDescription = styled.p`
  color: #333;
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 4px 0;
`;

export const EducationYear = styled.p`
  color: #666;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
`;

export const EducationFileUploader = styled.div`
  max-width: 460px;
  width: 100%;
  flex: none;

  .b2c-card {
    width: 100%;
  }

  .card-content {
    padding: 0;
  }

  .upload-file__types {
    margin: 0 0 4px 0;
  }

  .upload-file__types_below {
    margin: 16px 0 0 0;
  }

  @media screen and (min-width: 768px) {
    .upload-file__content {
      display: flex;
      gap: 16px;
    }

    .upload-file__content > div > .b2c-label {
      min-height: 40px;
    }

    .upload-file__content > a,
    .upload-file__content > div {
      max-width: ${({ $isBig }) => ($isBig ? "320px" : "286px")};
      width: 100%;
    }

    .upload-file__content .b2c-label {
      // padding: 13px 15px;
    }

    .upload-file__upload-button {
      margin-top: 0;
      max-width: 125px;
      height: 40px;
    }

    a {
      width: 100%;
    }

    .b2c-label {
      padding: 10px 15px;
    }
  }
`;

export const EmptyContent = styled.div`
  padding: 24px 0;
  max-width: 300px;
  margin: 0 auto;
  text-align: center;

  .add-button svg {
    width: 20px;
  }
`;

export const EmptyText = styled.p`
  color: #333;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 130%;
  display: block;
  margin: 8px 0 32px;
`;
