import ShareExperience from "./ShareExperience";
import UploadCv from "./UploadCv";
import CountrySelect from "./CountrySelect";
import JobInterest from "./JobInterest/index";
import JobInterestDetail from "./JobInterestDetail";
import SuperPower from "./SuperPower";
import JobType from "./JobType/index";
import Details from "./Details";
import Email from "./Email";
import Credentials from "./Credentials";
import CreateAccount from "./CreateAccount";
import MostInterests from "./MostInterests";
import SeniorityLevel from "./SeniorityLevel";
import EmailConfirmation from "./EmailConfirmation";

export default new Map([
  ["country", CountrySelect],
  ["interests", JobType],
  ["mostInterested", MostInterests],
  ["jobInterestDetail", JobInterestDetail],
  ["interested_job", JobInterest],
  ["share_exp", ShareExperience],
  ["upload_cv", UploadCv],
  ["jobInterest", JobInterest],
  ["details", Details],
  ["credentials", CreateAccount],
  ["validate", Email],
  ["validateEmail", Credentials],
  ["superPower", SuperPower],
  ["seniorityLevel", SeniorityLevel],
  ["emailConfirmation", EmailConfirmation]
]);
