import styled from "styled-components";
import promoBG from "images/b2c/service/promoBG.png";

export const PromoSectionWrapper = styled.section`
  padding: 20px 0 60px;
  background-image: url(${promoBG});
  background-size: cover;
  background-position: center center;
  @media screen and (max-width: 767px) {
    text-align: center;
    padding: 20px 0;
  }
`;
