import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { ScreenClassContext } from "react-grid-system";
import cn from "classnames";
import { RemoveRedEye } from "@styled-icons/material";
import { Comment } from "@styled-icons/boxicons-solid";

import { capitalize, thousandShortcut } from "utils/helpers";
import postCover from "images/b2c/post_cover.png";
import withLoading from "../withLoading";
import { Title, Text, Card, Label } from "../Core";
import { languageFormat } from "../../constants/main";
import { useTranslation } from "../../hooks/useTranslation";

const Post = ({
  loaded,
  type = "",
  horizontal,
  verticalXL,
  headline = "Post Title",
  created_at: createdAt,
  views = "Post Views Counter",
  comments_count: commentsCount = 0,
  normal_image: normalImage,
  full_image: fullImage,
  style,
  path,
  iArticleSectionList,
  marginFooter,
  paddingContent
}) => {
  const { language } = useTranslation();
  const languageFormatType = languageFormat[language];

  const wrapperClassNames = cn("blog-post", {
    "blog-post_horizontal": horizontal,
    "blog-post_verticalxl": verticalXL,
    "blog-post_list": iArticleSectionList
  });

  const formatDate = date => {
    return new Date(date).toLocaleString(languageFormatType, {
      day: "numeric",
      month: "short",
      year: "numeric"
    });
  };

  const screen = useContext(ScreenClassContext);
  const isSm = /xs|sm/.test(screen);

  const getTitleLevel = () => {
    return verticalXL ? "3" : "6";
  };

  return (
    <Card
      as={Link}
      to={{
        pathname: `${path}`,
        state: { type: capitalize(type.split("_")?.[0]) }
      }}
      className={wrapperClassNames}
      clickable
      shadow
      style={{
        border: "1px solid #E1E1E1",
        boxShadow: "none",
        height: "calc(100% - 1px)",
        ...style
      }}
    >
      <Card.Header
        className="blog-post__image"
        padding={iArticleSectionList ? "0px 0px 60%" : "0"}
        height={iArticleSectionList ? "auto" : isSm ? "100px" : "160px"}
        backgroundImage={verticalXL ? fullImage || normalImage : normalImage || fullImage}
        placeholder={postCover}
      />

      <Card.Content className="blog-post__info" padding={paddingContent || 8}>
        <Text
          loaded={loaded}
          fontWeight={600}
          fontSize={14}
          truncated
          margin="0"
          color="secondary200"
          title={capitalize(type)}
        >
          {capitalize(type.replace(/_/g, " "))}
        </Text>
        {headline && (
          <Title
            level={getTitleLevel()}
            margin={horizontal ? "4px 0 20px" : "4px 0"}
            fontWeight={500}
            height={verticalXL ? 56 : 38}
            truncatedLine={2}
            loaded={loaded}
            title={headline}
          >
            {headline}
          </Title>
        )}

        <Card.Footer className="blog-post__footer" padding={0} style={{ margin: marginFooter }}>
          {createdAt && (
            <Text loaded={loaded} fontWeight={400} fontSize={14} margin="0" color="black400">
              {formatDate(createdAt)}
            </Text>
          )}
          {(+views || +commentsCount) && (
            <>
              <div className="blog-post__actions" style={{ margin: "0 5px 0 auto" }}>
                <Label theme="black300" noBackground fontSize={14} margin="0" padding="0" Icon={<Comment width={18} />}>
                  {+commentsCount}
                </Label>
              </div>
              <Label
                theme="black300"
                noBackground
                fontSize={14}
                margin="0"
                padding="0"
                Icon={<RemoveRedEye width={18} />}
              >
                {thousandShortcut(+views)}
              </Label>
            </>
          )}
        </Card.Footer>
      </Card.Content>
    </Card>
  );
};

Post.Placeholder = ({ horizontal, verticalXL }) => {
  const wrapperClassNames = cn("blog-post blog-post_placeholder", {
    "blog-post_horizontal": horizontal,
    "blog-post_verticalxl": verticalXL
  });

  const getTitleLevel = () => {
    return verticalXL ? "3" : "6";
  };

  return (
    <Card className={wrapperClassNames} style={{ border: "none" }}>
      <Card.Header className="blog-post__image" padding={horizontal ? "0" : "0 0 60%"} />
      <Card.Content className="blog-post__info" padding={8}>
        {!verticalXL && !horizontal && (
          <Text loading fontWeight={400} fontSize={14} margin="0 0 4px 0" color="black500" />
        )}

        <Title
          level={getTitleLevel()}
          margin={verticalXL ? "4px 0 36px" : horizontal ? "4px 0 43px" : "4px 0 17px"}
          fontWeight={500}
          loading
        />

        <div className="blog-post__post-info">
          <Text loading fontWeight={400} fontSize={14} margin="0" color="black400" />
          <Text inline margin="0 6px" fontSize={18} color="grey200" aria-hidden="true">
            •
          </Text>
          <Text loading fontWeight={400} fontSize={14} margin="0" color="black400" />
        </div>
        <Card.Footer className="blog-post__footer" padding={0}>
          <Text
            loading
            fontWeight={500}
            fontSize={14}
            margin="0"
            color="black300"
            style={{ display: "flex", alignItems: "center", lineHeight: 1 }}
          />
        </Card.Footer>
      </Card.Content>
    </Card>
  );
};

export default withLoading(Post, Post.Placeholder);
