import { observer } from "mobx-react";
import useServiceWorker from "b2c/hooks/useServiceWorker";

const ServiceWorkerManager = () => {
  useServiceWorker();

  return null;
};

export default observer(ServiceWorkerManager);
