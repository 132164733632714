import React, { useState, useMemo } from "react";
import { Row, Col } from "react-grid-system";
import { useTranslation } from "b2c/hooks/useTranslation";

import Skillset from "b2c/components/Skillset";
import { getCompanyName, isWhitelabelledOrJkuat } from "b2c/contexts/branded";
import PersonalInformation from "b2c/components/PersonalInformation";
import { Title, Text, Button, Spinner } from "../../../../components/Core";
import WorkExperienceStep from "../WorkExperienceStep";
import EducationStep from "../EducationStep";
import LanguageStep from "../LanguageStep";
import ProfileVisibility from "../../../ProfileScreen/components/ProfileVisibility";
import Updating from "../Updating";
import { StepWrapper } from "./styled";
import useStore from "../../../../contexts/store";
import { useDocumentTitle } from "../../../../../utils/hooks";

const WizardStep = ({
  processing,
  currentStep,
  setStep,
  certificates,
  educations,
  languages,
  skills,
  work_experiences
}) => {
  const [progressDisabled, disableProgress] = useState(false);
  const [stepState, setState] = useState({ editing: false });
  const {
    Profile: { clearCurrentWorkExperience }
  } = useStore("User");
  const whitelabelledOrJkuat = isWhitelabelledOrJkuat();
  const { t } = useTranslation();

  useDocumentTitle(getCompanyName());

  const generateStepInfo = step => {
    switch (step) {
      default:
        return {
          title: t("wizard.uploading_profile.title"),
          Component: Updating
        };
      case 1:
        return {
          title: t("wizard.personal_info.title"),
          description: t("wizard.personal_info.description", { name: getCompanyName() }),
          Component: PersonalInformation
        };
      case 2:
        return {
          title: t("wizard.education.title"),
          description: t("wizard.education.description"),
          list: educations,
          add: t("wizard.education.add_button"),
          Component: EducationStep
        };
      case 3:
        return {
          title: t("wizard.work_experience.title"),
          description: t("wizard.work_experience.description"),
          list: work_experiences,
          add: t("wizard.work_experience.add_button"),
          Component: WorkExperienceStep
        };
      case 4:
        return {
          title: t("wizard.skills.title"),
          description: t("wizard.skills.description"),
          Component: props => <Skillset inProfileWizard {...props} />
        };
      case 5:
        return {
          title: t("wizard.languages.title"),
          description: t("wizard.languages.description"),
          list: languages,
          add: t("wizard.languages.add_button"),
          Component: LanguageStep
        };
      case 6:
        return {
          title: t("wizard.profile_visibility.title"),
          description: t("wizard.profile_visibility.description"),
          Component: props => <ProfileVisibility isWizard {...props} />
        };
    }
  };

  const {
    title,
    description,
    list,
    add,
    Component = () => null
  } = useMemo(
    () => generateStepInfo(currentStep),
    [currentStep, certificates, educations, languages, skills, work_experiences]
  );

  const backHandler = () => {
    if (stepState.editing) {
      setState({ editing: false });
      // Quick hack to work exp step back button issues
      clearCurrentWorkExperience();
    } else {
      setStep(prev => prev - 1);
    }
  };

  const resolveHandler = () => {
    if (stepState.editing) {
      setState({ editing: false });
    } else {
      setStep(prev => prev + 1);
    }
  };

  const resolveButtonText = stepState.editing
    ? t("global.save_button")
    : currentStep === (whitelabelledOrJkuat ? 5 : 6)
      ? t("global.finish_button")
      : t("wizard.next_step_button");

  return (
    <StepWrapper>
      <div className="step-title">
        <Row justify="between">
          <Col>
            <Title level={5} fontWeight="600" margin="0 0 6px">
              {title}
            </Title>
            <Text>{description}</Text>
          </Col>
          {add && !stepState.editing && (
            <Button
              color="primaryButtonLabelColor"
              className="add-button"
              onClick={() => setState({ editing: true, id: null })}
            >
              {add}
            </Button>
          )}
        </Row>
      </div>

      <Component
        list={list}
        hiddenTitle
        stepState={stepState}
        setStepState={setState}
        progressDisabled={progressDisabled}
        disableProgress={disableProgress}
        FormActions={props => (
          <StepNavigation
            processing={processing}
            currentStep={currentStep}
            setStep={setStep}
            buttonText={resolveButtonText}
            backHandler={backHandler}
            {...props}
          />
        )}
        resolveAction={resolveHandler}
        inProfileWizard
      />
    </StepWrapper>
  );
};

const StepNavigation = ({ processing, currentStep, backHandler, disableSubmit, buttonText = "Next step" }) => {
  return (
    <Row style={{ margin: 0, width: "100%" }}>
      {currentStep > 1 && (
        <Button type="button" theme="white" margin="auto 0 0 0" width="46px" onClick={backHandler} aria-label="Go back">
          {"<"}
        </Button>
      )}
      <Button
        type="submit"
        processing={processing}
        margin="15px 0 0 auto"
        style={{ minWidth: "150px" }}
        disabled={processing || disableSubmit}
        color="primaryButtonLabelColor"
        dataCy="save-languages"
      >
        {processing ? (
          <Spinner size="25px" margin="auto 25px" primaryFill="secondary200" secondaryFill="secondary100" />
        ) : (
          buttonText
        )}
      </Button>
    </Row>
  );
};

export default WizardStep;
