import styled from "styled-components";

export const AuthWrapper = styled.div`
  display: flex;
  overflow: hidden;
  height: 100vh;
  @media screen and (max-width: 767px) {
    height: ${({ isStandalone }) => (isStandalone ? "100vh" : "auto")};
    flex-direction: column;
  }
`;

export const AuthRightContainer = styled.div`
  background-color: #fff;
  display: flex;
  align-items: center;
  width: 50%;
  flex: 0 0 50%;
  min-width: 0px;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  padding: 40px 20px;
  position: relative;

  @media screen and (max-width: 767px) {
    background-color: #fff;
    width: 100%;
    padding: ${({ isStandalone }) => (isStandalone ? "0 20px 16px 20px" : "16px 20px")};
    flex: ${({ isStandalone }) => (isStandalone ? "1 0 75%" : "1 0 100%")};
    justify-content: normal;
  }
`;

export const AuthRightContent = styled.div`
  max-width: 430px;
  width: 100%;
  margin: auto;
  // position: relative;

  @media screen and (max-width: 767px) {
    > h3 {
      margin-top: 16px;
    }
  }
`;
