import React, { useEffect, useState, useRef } from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";

import FlagSlovakia from "images/b2c/onboarding/flags/slovakia.svg";
import FlagPoland from "images/b2c/onboarding/flags/poland.svg";
import FlagRomania from "images/b2c/onboarding/flags/romania.svg";
import FlagNepal from "images/b2c/onboarding/flags/nepal.svg";
import FlagIndia from "images/b2c/onboarding/flags/india.svg";
import FlagKenya from "images/b2c/onboarding/flags/kenya.svg";
import FlagNigeria from "images/b2c/onboarding/flags/nigeria.svg";
import FlagUganda from "images/b2c/onboarding/flags/uganda.svg";
import { useTranslation } from "b2c/hooks/useTranslation";
import Content from "../Content";
import Steps from "../Steps";
import useStore from "../../../../contexts/store";
import {
  Container,
  SearchWrapper,
  SearchInputIcon,
  SearchInput,
  Label,
  CountryContent,
  AllCountryContent,
  Name,
  NotFound
} from "./styled";
import { Checkbox } from "../../../../components/Core";
import Navigation from "../Navigation";

const flags = new Map([
  ["kenya", <FlagKenya />],
  ["nigeria", <FlagNigeria />],
  ["uganda", <FlagUganda />],
  ["slovakia", <FlagSlovakia />],
  ["poland", <FlagPoland />],
  ["romania", <FlagRomania />],
  ["nepal", <FlagNepal />],
  ["india", <FlagIndia />]
]);

const Country = () => {
  const { getCountries, allCountries = [] } = useStore("Common");
  const { countries: defaultCountries, default_country: defaultCountry } = useStore("initialState");
  const [selected, setSelected] = useState({});
  const [allCountriesList, setAllCountriesList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const history = useHistory();
  const { t } = useTranslation();
  const elementRef = useRef();

  const handleSelectDefaultCountries = event => {
    setSelected(defaultCountries.find(({ id }) => id === Number(event.target.value)));
  };

  const handleSearchCountry = event => {
    const { value } = event.target;
    setSearchValue(value);

    const findCountries = value
      ? [...defaultCountries, ...allCountries].filter(item => item.name.toLowerCase().includes(value.toLowerCase()))
      : allCountries;

    setAllCountriesList(findCountries);
  };

  const handleSelectAllCountries = (event, isSearch) => {
    const countryList = isSearch ? [...allCountries, ...defaultCountries] : allCountries;
    setSelected(countryList.find(({ id }) => id === Number(event.target.value)));
  };

  const handleSubmit = () => {
    const prevOnboardingForm = JSON.parse(sessionStorage.getItem("onboardingFlow"));

    const onboardingFlow = {
      ...prevOnboardingForm,
      countryId: selected?.id
    };

    sessionStorage.setItem("onboardingFlow", JSON.stringify(onboardingFlow));
    history.push("/onboarding/job-type");
  };

  useEffect(() => {
    getCountries(true);
  }, [defaultCountry]);

  useEffect(() => {
    if (allCountries.length && !allCountriesList.length) {
      setAllCountriesList(allCountries);
    }
  }, [allCountries.length]);

  useEffect(() => {
    const onboardingFlow = JSON.parse(sessionStorage.getItem("onboardingFlow"));
    if (allCountriesList.length && onboardingFlow?.countryId && !selected?.id) {
      const findIndex = [...defaultCountries, ...allCountriesList].findIndex(
        ({ id }) => Number(id) === Number(onboardingFlow.countryId)
      );

      if (findIndex) {
        setSelected(allCountries.find(({ id }) => Number(id) === Number(onboardingFlow.countryId)));
      }
    }
  }, [allCountriesList.length, selected?.id]);

  useEffect(() => {
    if (elementRef.current) {
      elementRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [elementRef.current, allCountriesList?.length]);

  const onboardingFlow = JSON.parse(sessionStorage.getItem("onboardingFlow"));

  const renderCountries = (countriesList, handleSelect, isShowFlag) => {
    return (
      <Container>
        {countriesList.map(({ id, slug, name }) => (
          <div key={slug} ref={String(id) === String(onboardingFlow?.countryId) ? elementRef : null}>
            <Checkbox
              id={slug}
              name="country"
              value={id}
              borderedView
              margin="0"
              onChange={event => handleSelect(event, searchValue.length)}
              checked={selected?.id === id}
              markColor="white"
              checkedColor="secondary200"
              checkedBgColor="secondary100"
              style={{
                outline: "none"
              }}
              labelProps={{
                style: {
                  flexDirection: "row-reverse",
                  justifyContent: "space-between",
                  borderColor: selected?.id === id ? "#4690FF" : "#C5C5C5",
                  marginBottom: "12px",
                  padding: "6px 16px",
                  borderRadius: "8px"
                }
              }}
              checkboxMarkProps={{
                style: {
                  borderRadius: "50%",
                  marginRight: "0"
                }
              }}
              label={
                <Name>
                  {isShowFlag && flags.get(slug)}
                  {name}
                </Name>
              }
            />
          </div>
        ))}
      </Container>
    );
  };

  return (
    <>
      <Steps width="60px" />

      <Content title={t("onboarding.country.title")} description={t("onboarding.country.description")}>
        <SearchWrapper>
          <SearchInput
            placeholder={t("onboarding.country.placeholder")}
            onChange={handleSearchCountry}
            value={searchValue}
          />
          <SearchInputIcon />
        </SearchWrapper>

        {!searchValue.length && (
          <>
            <Label>{t("onboarding.country.popular")}</Label>
            <CountryContent>{renderCountries(defaultCountries, handleSelectDefaultCountries, true)}</CountryContent>
            <Label>{t("onboarding.country.all_countries")}</Label>
          </>
        )}

        {allCountriesList.length ? (
          <AllCountryContent>{renderCountries(allCountriesList, handleSelectAllCountries, false)}</AllCountryContent>
        ) : (
          <>{searchValue.length ? <NotFound>{t("onboarding.country.not_found")}</NotFound> : null}</>
        )}
      </Content>

      <Navigation isDisabled={!selected?.id} handleSubmit={handleSubmit} />
    </>
  );
};

export default observer(Country);
