import styled, { css } from "styled-components";

export const StyledStepsCount = styled.div`
  color: #fff;
  background: #468ffd;
  position: absolute;
  padding: 4px 10px;
  border-radius: 12px;
  top: 30px;
  right: 24px;
  font-size: 12px;
`;

export const StyledModalWrapper = styled.div`
  position: relative;
  overflow-y: hidden;
  padding: ${({ $isPadding }) => ($isPadding ? "50px 0" : "0")};
  @media screen and (min-width: 501px) {
    width: 500px;
  }

  @media screen and (min-width: 768px) {
    width: 540px;
    padding: 0;
  }
`;

export const StyledTitle = styled.div`
  position: relative;
  border-bottom: 1px solid #80808063;
  padding: 64px 0 10px 0;
  @media screen and (min-width: 768px) {
    padding: 24px;
  }
`;

export const StyledContent = styled.div`
  padding: 10px;
`;

export const StyledFooter = styled.div`
  padding: 16px 24px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #80808063;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

export const StyledPrisingSection = styled.div`
  display: inline-block;
  vertical-align: top;
  margin: 31px;
`;

export const StyledPrisingSectionWrapper = styled.div`
  background: #f0f0f0;
  border-radius: 5px;
  height: 124px;
  margin-bottom: 24px;
`;

export const StyledCalendarWrapper = styled.div`
  display: inline-block;
  @media screen and (min-width: 501px) {
    margin-right: 45px;
  }

  ${() => css`
    .date_picker {
      border-radius: 5px;
      color: #303030;
      display: inline-block;
      max-width: 294px;
      width: 294px;
    }
    .date_picker-month {
      height: 311px;
    }
    .date_picker-month_header {
      margin-bottom: 20px;
      position: relative;
    }
    .date_picker-month_header_wrapper {
      text-align: center;
      margin: 0 auto 25px;
    }
    .date_picker-month_header_title {
      font-size: 14px;
      font-weight: 500;
      position: relative;
      left: 7px;
      display: inline-block;
    }
    .date_picker-header_button {
      cursor: pointer;
      background: #e1e1e1;
      border-radius: 50%;
      border: 1px solid #e4e7ea;
      transition:
        border 0.1s ease-in,
        color 0.1s ease-in;
      position: relative;
      height: 24px;
      width: 24px;
    }
    .date_picker-header_button:focus {
      outline: 0;
    }
    .date_picker-header_button.is-disabled {
      cursor: default;
      background: #fff;
    }
    .date_picker-header_button.is-prev {
      left: 240px;
      cursor: pointer;
      position: absolute;
      display: inline-block;
    }
    .date_picker-header_button.is-next {
      cursor: pointer;
      display: inline-block;
      position: absolute;
      left: 272px;
    }
    .date_picker-header_button.is-next:before,
    .date_picker-header_button.is-prev:before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: fill 0.1s ease-in;
      color: #303030;
    }
    .date_picker-header_button.is-disabled .date_picker-header_button.is-next,
    .date_picker-header_button.is-disabled .date_picker-header_button.is-prev {
      color: #acb3bb;
    }
    .date_picker-calendar_wrapper.is-shortcut_hover_outrange .date_picker-header_button.is-next,
    .date_picker-calendar_wrapper.is-shortcut_hover_outrange .date_picker-header_button.is-prev {
      border-color: #4a90e2;
      background: #e7f5ff;
    }

    .date_picker-days_of_week {
      font-size: 13px;
      display: flex;
      text-align: center;
      margin-bottom: 25px;
    }
    .date_picker-days_of_week_day {
      flex-basis: 15%;
    }
    .date_picker-week {
      display: flex;
      margin-bottom: 8px;
    }
    .date_picker-week:last-of-type {
      margin-bottom: 0;
    }
    .date_picker-week-day {
      height: 38px;
      width: 38px;
      vertical-align: middle;
      font-size: 12px;
      user-select: none;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-basis: 15%;
      background: #fff;
      position: relative;
      margin: 0;
      padding: 0;
      border: none;
      outline: 0;
    }

    .date_picker-week-day.is-prev_month,
    .date_picker-week-day.is-next_month {
      visibility: hidden;
    }
    .date_picker-week-day.is-today {
      font-weight: 700;
    }
    .date_picker-week-day.is-selected {
      background: #e3effc;
      color: #404040;
      position: relative;
      z-index: 1;
    }

    .date_picker-week-day.is-selected::before {
      background: #4694e9;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: -1;
      color: #fff;
    }
    .date_picker-calendar_wrapper.is-shortcut_hovered .date_picker-week-day.is-selected::before {
      background: #4a90e2;
    }
    .date_picker-week-day.is-selected:hover::before {
      content: "";
      box-shadow: inset 0 0 1px 0 #3863a0;
      background: #e3effc;
      color: #404040;
    }
    .date_picker-week-day.is-selected.is-start_selection.date_picker-week-day.is-selected:hover::before,
    .date_picker-week-day.is-selected.is-end_selection.date_picker-week-day.is-selected:hover::before {
      background: #3863a0;
    }
    .date_picker-week-day.is-selected.is-start_selection,
    .date_picker-week-day.is-selected.is-end_selection {
      background: #e3effc;
      color: #fff;
    }
    .date_picker-week-day.is-selected.is-start_selection::before,
    .date_picker-week-day.is-selected.is-end_selection::before {
      content: "";
    }

    .date_picker-week-day.is-selectable {
      cursor: pointer;
      position: relative;
      background: #ecf4ff;
      color: #296acc;
      margin: 0 2px;
      border-radius: 4px;
    }
    .date_picker-week-day.is-selectable:before {
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
    }
    .date_picker-week-day.is-selectable:hover:not(.is-selected) {
      z-index: 1;
      box-shadow: inset 0 0 1px 0 #3863a0;
    }
    .date_picker-week-day.is-not_selectable {
      color: #e4e7ea;
    }
    .date_picker-notice {
      display: none;
    }
  `}
`;

export const BaronaLogoContainer = styled.div`
  margin-bottom: 40px;

  svg {
    width: 180px;
    height: 40px;
  }

  path {
    fill: #18181b;
  }
`;
