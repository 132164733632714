export default [
  {
    type: "daily",
    isUnread: true,
    payload: {
      counter: 3
    }
  },
  {
    type: "superMatch",
    isUnread: true,
    payload: {
      counter: 3
    }
  },
  {
    type: "jobExpiring",
    payload: {
      counter: 3
    }
  },
  {
    type: "unfinishedJobExpiring",
    payload: {
      counter: 3
    }
  },
  {
    type: "applicationViewed",
    payload: {
      counter: 3
    }
  },
  {
    type: "applicationViewed",
    payload: {
      counter: 3
    }
  }
];
