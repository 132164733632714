import React from "react";
import { MdAndroid } from "react-icons/md";

import { AvailabilityStyled } from "./styled";

const Availability = ({ isGetApp }) => {
  return (
    <AvailabilityStyled isGetApp={isGetApp}>
      <MdAndroid fill="#DADADA" /> Currently available only on Android devices
    </AvailabilityStyled>
  );
};

export default Availability;
