import styled from "styled-components";

export const HowItWorksWrapper = styled.section`
  padding: ${({ $padding }) => $padding};
  ${({ style }) => style}
  @media screen and (max-width: 767px) {
    padding: 30px 0;

    .how-slide:not(:last-of-type) {
      border-bottom: 1px solid #e0e0e0;
    }
  }
`;

export const HowItWorksTextContainer = styled.div`
  margin: 0 auto;
  max-width: 440px;

  @media screen and (max-width: 767px) {
    padding: 16px 0;
    width: 100%;
  }
`;
