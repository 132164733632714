import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

const RootStyle = css`
  position: relative;
  display: flex;
  width: 100%;
  padding: 12px 14px;
  cursor: pointer;

  & + & {
    border-top: 1px solid #e1e1e1;
  }

  ${({ $styled }) => $styled};

  ${props =>
    props.isUnread &&
    css`
      background-color: #f3f8ff;

      &::after {
        content: "";
        top: 25px;
        right: 18px;
        position: absolute;
        width: 8px;
        height: 8px;
        background: #4690ff;
        border-radius: 50%;
      }
    `};
`;

const LinkStyle = css`
  color: #292929;
  text-decoration: none;

  &:visited {
    color: #292929;
  }

  ${RootStyle}
`;

export const StyledRoot = styled.div`
  ${RootStyle}
`;

export const StyledRootLink = styled(Link)`
  ${LinkStyle}
`;
export const StyledRootExternalLink = styled.a`
  ${LinkStyle}
`;

export const StyledTitle = styled.strong`
  font-weight: 700;
`;

export const StyledContent = styled.div`
  width: 208px;
  padding-top: 4px;
  padding-left: 12px;

  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 14px;
`;

export const StyledIcon = styled.div`
  width: 34px;
  height: 34px;
  flex-shrink: 0;
  position: relative;

  background: transparent;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  &::after {
    content: "";
    top: 1px;
    left: 1px;
    z-index: 0;
    position: absolute;

    width: 30px;
    height: 30px;

    border-radius: 2px;
    background: #f5f5f5;
  }
`;

export const StyledImage = styled.img`
  max-width: 100%;
  flex-shrink: 0;
  border-radius: 2px;
  position: relative;
  z-index: 1;
`;
