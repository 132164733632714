import styled from "styled-components";
import { Link } from "react-router-dom";

export const StyledRoot = styled.div`
  width: 100%;
  height: 100%;
  background-color: #f3f3f3;
`;

export const StyledCounterSection = styled.div`
  width: 100%;
  height: 37px;
  padding: 10px 16px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  color: #0b0a0a;
`;

export const StyledCounter = styled.span`
  font-weight: 700;
`;

export const StyledMoreSection = styled.div`
  padding: 16px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledMoreLink = styled(Link)`
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 32px;
  color: #296acc;
`;
