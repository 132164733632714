export const mainTabs = [
  {
    name: "learn.tabs.tab_1",
    tab: ""
  },
  {
    name: "learn.tabs.tab_2",
    tab: "search"
  }
];

export const authorizedTabs = [
  {
    name: "learn.tabs.tab_3",
    tab: "saved"
  },
  {
    name: "learn.tabs.tab_4",
    tab: "in-progress"
  },
  {
    name: "learn.tabs.tab_5",
    tab: "completed"
  }
];

export const tabNames = {
  search: "search"
};
