import React, { useContext } from "react";

import { brandName } from "b2c/constants/brand";
import phone_email from "images/b2c/phone_email.png";
import phone_course from "images/b2c/phone_course.png";
import ncpwd_logo from "images/b2c/logo_white.png";
import contribute_logo from "images/b2c/contribute_logo.png";
import ncpwd_image from "images/b2c/registre_image.jpg";
import contribute_image from "images/b2c/contribute_portrait.jpg";
import clouds from "images/b2c/clouds.jpg";
import fuzuLogoBlack from "images/new_fuzu_logo_black.png";
import { BrandSideWrapper } from "./styled";
import { Text, Title, Image } from "../Core";
import BrandedContext, {
  BrandVisible,
  BrandHidden,
  WhitelabelledVisible,
  isWhitelabelled
} from "../../contexts/branded";

const BrandSide = ({ type, isonboarding }) => {
  const { slug, employer, name } = useContext(BrandedContext);
  const whitelabelled = isWhitelabelled();

  const { logo, backgroundImage } = (() => {
    switch (slug) {
      default:
        return whitelabelled ? { logo: employer?.logo, backgroundImage: clouds } : {};
      case "www":
        return {};
      case "ncpwd":
        return { logo: ncpwd_logo, backgroundImage: ncpwd_image };
      case brandName.jkuat:
        return { logo: ncpwd_logo, backgroundImage: ncpwd_image };
      case "demo":
        return { logo: contribute_logo, backgroundImage: contribute_image };
    }
  })();

  const { phoneImage, title, subtitle } = (() => {
    switch (type) {
      default:
        return {
          phoneImage: phone_email,
          title: "Personalised, validated job recommendations",
          subtitle: "+ dozens of other benefits"
        };
      case "auto":
        return {
          phoneImage: phone_email,
          title: "What are you waiting for? Sign up and we’ll help you find your dream job.",
          subtitle: ""
        };
      case "learn":
        return {
          phoneImage: phone_course,
          title: "Insights and inspiration from a community of more than a Million users",
          subtitle: ""
        };
    }
  })();

  const OnboardingVersionHidden = ({ children }) => (isonboarding ? null : children);

  return (
    <BrandSideWrapper backgroundImage={backgroundImage} isonboarding={isonboarding}>
      <BrandHidden brand="www">
        <Image margin="16px auto" src={logo} />
      </BrandHidden>
      <BrandHidden brand={["ncpwd", "contribute"]}>
        <WhitelabelledVisible>
          <OnboardingVersionHidden>
            <Title margin="auto" textAlign="center" level={3} fontWeight={600}>
              Welcome to {name} jobs!
            </Title>
            <Text inline lineHeight={1.5} fontWeight={600} margin="auto auto 48px">
              Powered by
              <Image src={fuzuLogoBlack} margin="0 0 0 6px" width={64} />
            </Text>
          </OnboardingVersionHidden>
        </WhitelabelledVisible>
      </BrandHidden>
      <BrandVisible brand="www">
        <OnboardingVersionHidden>
          <Text margin="24px auto 12px" fontSize={16} fontWeight={500} color="accent300">
            For logged in users <u>only</u>
          </Text>
          <Title margin="0px auto" textAlign="center" level={3} fontWeight={700}>
            {title}
          </Title>
          {subtitle && (
            <Text fontSize={16} margin="12px auto 0px">
              {subtitle}
            </Text>
          )}
        </OnboardingVersionHidden>
        <Image src={phoneImage} margin="auto auto 0" />
      </BrandVisible>
    </BrandSideWrapper>
  );
};

export default BrandSide;
