import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { Row, Col } from "react-grid-system";

import useStore from "b2c/contexts/store";
import { isWhitelabelledOrJkuat } from "b2c/contexts/branded";
import { Card, Text, SwitchInput } from "../Core";

/* Remove this component after deploy 16.02.2024 */
const VisibilitySettings = () => {
  const {
    Profile: { profile: { personal_info: { talent_pool_visibility } = {} } = {} },
    Settings: { toggleVisibility }
  } = useStore("User");
  const whitelabelledOrJkuat = isWhitelabelledOrJkuat();

  const [disabled, disableToggle] = useState(false);
  const [pool, setPoolVisibility] = useState(talent_pool_visibility);

  useEffect(() => {
    setPoolVisibility(talent_pool_visibility);
  }, [talent_pool_visibility]);

  const handleVisibilityToggle = () => {
    disableToggle(true);
    toggleVisibility().then(() => {
      setPoolVisibility(!pool);
      disableToggle(false);
    });
  };

  return (
    <>
      {!whitelabelledOrJkuat ? (
        <Card
          shadow
          wideOnMobile
          style={{
            margin: "0 0 15px"
          }}
        >
          <Card.Content padding="15px 20px">
            <Row>
              <Col>
                <Text fontSize={12} fontWeight={700} margin="0 0 8px">
                  Let Employers Find You
                </Text>
                <Text margin="0">
                  {pool ? "Public" : "Private"}: Your profile is {pool ? "" : "not "}visible to headhunters. Hiring
                  managers will be able to view your profile and reach out about potential opportunities.{" "}
                </Text>
              </Col>
              <Col xs="content" style={{ display: "flex" }}>
                <SwitchInput
                  aria-label={`Let Employers Find You: ${pool ? "Public" : "Private"}: Your profile is ${
                    pool ? "" : "not "
                  }visible to headhunters`}
                  disabled={disabled}
                  margin="auto"
                  id="talent_pool_visibility"
                  checked={pool}
                  onChange={handleVisibilityToggle}
                />
              </Col>
            </Row>
          </Card.Content>
        </Card>
      ) : null}
    </>
  );
};

export default observer(VisibilitySettings);
