import styled from "styled-components";

export const Container = styled.div`
  margin-bottom: 124px;
`;

export const SearchWrapper = styled.div`
  position: relative;
  margin-bottom: 24px;
`;

export const SearchInput = styled.input`
  border-radius: 8px;
  border: 1px solid #c5c5c5;
  background: #fff;
  padding: 17px 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #212121;
  display: block;
  width: 100%;

  &:placeholder {
    color: #a4a4a4;
  }
`;

export const Label = styled.span`
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  display: block;
  margin-bottom: 8px;
`;

export const Description = styled.span`
  color: #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-bottom: 24px;

  a {
    color: #408bfc;
  }
`;

export const Or = styled.div`
  position: relative;
  margin-top: 24px;

  span {
    color: #666;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    display: block;
    margin: 0 auto;
    padding: 0 16px;
    background: #fff;
    position: relative;
    z-index: 1;
    width: 50px;
  }

  &:after {
    content: "";
    height: 1px;
    background: #eee;
    position: absolute;
    display: block;
    width: 100%;
    top: 50%;
    margin-top: -0.5px;
  }
`;
