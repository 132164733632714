import React, { useContext, useState } from "react";
import { ScreenClassContext } from "react-grid-system";
import { useRouteMatch } from "react-router-dom";
import { X } from "@styled-icons/boxicons-regular";

import NotificationContext from "contexts/notification";
import CountryNotification from "b2c/components/CountryNotification";
import useStore from "../../contexts/store";
import { isCustomJkuat, isWhitelabelled } from "../../contexts/branded";
import { Notification, Button } from "../Core";
import Layout from "../Layout";

const NotificationBanner = () => {
  const screen = useContext(ScreenClassContext);
  const [showCountryBanner, setShowCountryBanner] = useState(true);
  const isSm = /xs|sm/.test(screen);
  const { content, color, onClose, setNotification } = useContext(NotificationContext);
  const { default_country, ssr } = useStore("initialState");
  const { path } = useRouteMatch();
  const customJkuat = isCustomJkuat();
  const whitelabelled = isWhitelabelled();
  const { global_brand } = useStore("initialState");
  const isBarona = global_brand?.name.includes("Barona");

  if (isBarona) {
    return null;
  }

  const isShowCountryBanner =
    path === "/" &&
    !(default_country && path.includes(default_country?.slug)) &&
    !whitelabelled &&
    !customJkuat &&
    !React.isValidElement(content) &&
    showCountryBanner;

  const handleClose = () => {
    if (onClose) onClose();
    setNotification(null);
  };

  if (isShowCountryBanner) {
    return (
      <Notification textOnly theme="black300">
        <Layout.Content size="xl" tag="div" style={{ display: "flex" }}>
          <CountryNotification ssr={ssr} />
          <Button.Iconed
            theme="transparent"
            onClick={() => setShowCountryBanner(false)}
            margin={isSm ? 0 : "auto 0"}
            aria-label="Close notification"
          >
            <X width={26} fill="#fff" />
          </Button.Iconed>
        </Layout.Content>
      </Notification>
    );
  }

  return (
    content && (
      <>
        <Notification textOnly theme={color}>
          <Layout.Content size="xl" tag="div" style={{ display: "flex" }}>
            {React.isValidElement(content) ? React.cloneElement(content, { close: handleClose }) : content}
            <Button.Iconed
              theme={color}
              onClick={handleClose}
              margin={isSm ? 0 : "auto 0"}
              aria-label="Close notification"
            >
              <X width={26} />
            </Button.Iconed>
          </Layout.Content>
        </Notification>
      </>
    )
  );
};

export default NotificationBanner;
