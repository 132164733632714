import styled from "styled-components";

export const NavProgressWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;
`;

export const NavProgressStep = styled.div`
  flex: 1 0 auto;
  height: ${({ $isActive }) => ($isActive ? "6px" : "4px")};
  background-color: ${({ $isActive, activeColor, backgroundColor }) => ($isActive ? activeColor : backgroundColor)};
  transition: 0.2s ease;
  &:not(:last-child) {
    margin-right: 4px;
  }
`;
