import React, { useMemo, useEffect } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { Spinner } from "b2c/components/Core";
import useStore from "../../../../contexts/store";
import ArticlesSectionList from "../ArticleSectionList";

const AuthorPosts = observer(({ count_limit }) => {
  const { authorId } = useParams();
  const {
    state: { loading },
    author,
    getAuthor
  } = useStore("Author");

  useEffect(() => {
    getAuthor(authorId);
  }, [authorId]);

  useEffect(() => {
    if (window.scrollY) {
      window.scroll(0, 0);
    }
  }, [authorId]);

  const title = useMemo(() => {
    return `Articles from ${author.name}`;
  }, [author]);

  if (loading) return <Spinner size="50px" margin="auto" />;

  return (
    <ArticlesSectionList
      id="main"
      title={title}
      author_id={author.id}
      filter="author"
      count_limit={count_limit}
      pagination
    />
  );
});

export default AuthorPosts;
