import styled from "styled-components";

export const TabWrapper = styled.nav(props => props.$styled);

export const TabContent = styled.div`
  ${props => props.$styled}
`;

export const TabScrollContent = styled.div`
  display: flex;
  width: 100%;
  white-space: nowrap;
  overflow: auto;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  &:after {
    content: "";
    width: 0;
    min-height: 50px;
    position: sticky;
    right: 0;
    background-color: ${({ $theme }) => $theme};
    display: inline-flex;
    flex: 0 0 0;
    margin-left: auto;
    box-shadow: 0px 0 10px 15px ${({ $theme }) => $theme};
  }
`;

export const TabItem = styled.div.attrs()`
  ${({ selected, $color, $altcolor, $focusColor, $theme }) => `
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    min-height: 50px;
    padding: 0 calc(10px + 0.5%);
    color: ${selected ? $altcolor : $color};
    font-weight: 500;
    transition: .2s ease, font-weight 0s;
    user-select: none;
    background-color: ${$theme};
    box-shadow: ${selected ? `inset 0 -2px 0px 0px currentColor, 0 0 10px 5px ${$theme};` : "none"};

    position: ${selected ? "sticky" : "static"};
    left: 0;
    right: 0;
    z-index: ${selected ? "1" : "0"};


    &:hover {
        color: ${$altcolor};
    }

    &:focus {
        background-color: ${selected ? $theme : $focusColor};
        outline: none;
        z-index: 3;
    }
  `}
  ${({ $styled }) => $styled}
`;

export const TabPanel = styled.div(props => props.$styled);
