import { Crown, Medal, Star, User } from "@styled-icons/boxicons-solid";
import { Link } from "react-router-dom";
import React from "react";

import { useTranslation } from "b2c/hooks/useTranslation";
import { Icon, Label, Text } from "../Core";

const StrengthLabel = ({ fill, prompt, title, hideActivityCenter }) => {
  const icon = (() => {
    switch (prompt) {
      default:
      case "Beginner":
        return User;
      case "Intermediate":
        return Star;
      case "Advanced":
        return Medal;
      case "All star":
        return Crown;
    }
  })();
  const { t } = useTranslation();

  const handleClick = () => {
    if (hideActivityCenter) {
      hideActivityCenter();
    }
  };

  return (
    <Text as={Link} to="/profile" onClick={handleClick}>
      <Label
        Icon={<Icon as={icon} width={18} fill={fill} />}
        theme="transparent"
        color={fill}
        fill={fill}
        margin="0"
        display="inline-flex"
        rect
        fontSize={14}
      >
        {t(title)}
      </Label>
    </Text>
  );
};

export default StrengthLabel;
