import React from "react";
import { WhatIsFuzuComponent, WhatIsFuzuContent, HowItWorkContent } from "./styled";
import HowItWorksSection from "../../../../components/HowItWorksSection";

const WhatIsFuzu = ({ country }) => {
  return (
    <WhatIsFuzuComponent>
      <WhatIsFuzuContent>
        <HowItWorkContent>
          <HowItWorksSection country={country} padding="" isShowFullContent />
        </HowItWorkContent>
      </WhatIsFuzuContent>
    </WhatIsFuzuComponent>
  );
};

export default WhatIsFuzu;
