export const aboutFuzu = [
  "Through a friend or professional acquaintance",
  "Through a colleague at work",
  "Fuzu business development team",
  "Social media (LinkedIn, Facebook, Instagram, etc.)",
  "Internet search (Google, Bing, etc.)",
  "Email marketing"
];

export const hours = [
  "9:00 AM",
  "9:30 AM",
  "10:00 AM",
  "10:30 AM",
  "11:00 AM",
  "11:30 AM",
  "12:00 AM",
  "12:30 PM",
  "1:00 PM",
  "1:30 PM",
  "2:00 PM",
  "2:30 PM",
  "3:00 PM",
  "3:30 PM",
  "4:00 PM",
  "4:30 PM",
  "5:00 PM",
  "6:30 PM"
];

export const hoursMapper = {
  "9:00 AM": "09:00-09:30am",
  "9:30 AM": "09:30-10:00am",
  "10:00 AM": "10:00-10:30am",
  "10:30 AM": "10:30-11:00am",
  "11:00 AM": "11:00-11:30am",
  "11:30 AM": "11:30-12:00am",
  "12:00 AM": "12:00-12:30am",
  "12:30 PM": "12:30-01:00pm",
  "1:00 PM": "01:00-01:30pm",
  "1:30 PM": "01:30-02:00pm",
  "2:00 PM": "02:00-02:30pm",
  "2:30 PM": "02:30-03:00pm",
  "3:00 PM": "03:00-03:30pm",
  "3:30 PM": "03:30-04:00pm",
  "4:00 PM": "04:00-04:30pm",
  "4:30 PM": "04:30-05:00pm",
  "5:00 PM": "05:00-05:30pm",
  "6:30 PM": "05:30-06:00pm"
};

export const stepsInfo = {
  optionSelectionStep: {
    title: "companies.contact_us_modal.title_1",
    arrow: false,
    step: 1,
    cancel: true,
    button: "companies.contact_us_modal.continue"
  },
  calendarStep: {
    // title: "Book a call with Fuzu",
    title: "employers.contact_us_modal.calendar_title",
    arrow: true,
    step: 3,
    cancel: true
  },
  formStep: {
    title: {
      fuzu: "employers.contact_us_modal.form_step_title",
      barona: "companies.contact_us_modal.title_3"
    },
    arrow: true,
    step: 2,
    cancel: true,
    button: "companies.contact_us_modal.continue"
  },
  jobSeekerStep: {
    title: "employers.contact_us_modal.job_seeker_title",
    arrow: false,
    step: false,
    cancel: false,
    button: "employers.contact_us_modal.job_seeker_button"
  },
  confirmedStep: {
    title: "employers.contact_us_modal.calendar_title",
    arrow: false,
    step: false,
    cancel: false,
    button: "global.done"
  },
  baronaConfirmedStep: {
    title: "companies.contact_us_modal.title_4",
    arrow: false,
    step: false,
    cancel: false,
    button: "companies.contact_us_modal.button_4"
  },
  baronaJobSeekerStep: {
    title: "companies.contact_us_modal.title_2",
    arrow: false,
    step: false,
    cancel: false,
    button: "companies.contact_us_modal.button_2"
  },
  lastStep: {
    fuzu: "3",
    barona: "2"
  },
  jobSeekerText: {
    fuzu: "employers.contact_us_modal.job_seeker_description",
    barona: "companies.contact_us_modal.sub-title_2"
  },
  formState: {
    fuzu: "To set up your Fuzu account and to select the best solution for your needs, we’ll schedule a short call with the Fuzu experts",
    barona:
      "To set up your partnership with us and find the best solution for your needs, we’ll schedule a short call with Barona experts."
  }
};

export const steps = {
  optionSelectionStep: "optionSelectionStep",
  formStep: "formStep",
  jobSeekerStep: "jobSeekerStep",
  calendarStep: "calendarStep",
  confirmedStep: "confirmedStep",
  baronaConfirmedStep: "baronaConfirmedStep",
  baronaJobSeekerStep: "baronaJobSeekerStep"
};

export const productTypes = {
  "Free Forever": 0,
  Starter: 1,
  "Starter Gold": 2,
  Basic: 3,
  Growth: 4,
  Premium: 5,
  "Junior professional": 6,
  "Mid-level": 7,
  "Team lead": 8,
  "Tech hires": 9,
  "Executive search": 10
};
