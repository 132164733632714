import styled from "styled-components";

export const TitleIconHolder = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 auto;
`;

export const ActionRow = styled.div`
  display: flex;
  width: 300px;

  > * {
    flex-basis: 150px;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;
