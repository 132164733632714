import styled from "styled-components";
import { Button } from "../../components/Core";

export const Form = styled.form`
  width: 420px;
  margin: 10px auto;
`;

export const StyledButton = styled(Button)`
  border: none;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
  margin: 0;
`;

export const Label = styled.label`
  color: #292929;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0 0 4px 0;
`;

export const Email = styled.span`
  border-radius: 5px;
  border: 1px solid #eee;
  background: #e1e1e1;
  display: block;
  margin: 0 0 24px 0;
  padding: 11px 12px;
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  width: 100%;
`;

export const B2bInviteHeaderWrapper = styled.div`
  padding: 20px 0;
  text-align: center;
`;

export const Content = styled.div`
  max-width: 470px;
  margin: 0 auto;
`;
