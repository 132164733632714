import React from "react";
import { Row, Col } from "react-grid-system";

import { useTranslation } from "b2c/hooks/useTranslation";
import FilterSelect from "../FilterSelect";
import { isBaronaBrand } from "../../contexts/branded";

const ApplicationFilters = ({ applicationFilters, appliedFilters, applyFilter }) => {
  const isBarona = isBaronaBrand();
  const { t } = useTranslation();

  return (
    <Row>
      <Col lg={2}>
        <FilterSelect
          placeholder={t("jobs.search.application_placeholder")}
          category="application_status"
          appliedCategory="application_status"
          sortItemsBy="name"
          items={applicationFilters.application_statuses}
          selected={appliedFilters.application_status}
          applyFilter={applyFilter}
        />
      </Col>

      {!isBarona && (
        <Col lg={2}>
          <FilterSelect
            placeholder={t("jobs.search.job_type_placeholder")}
            category="job_type"
            appliedCategory="job_type"
            sortItemsBy="name"
            items={applicationFilters.job_types}
            selected={appliedFilters.job_type}
            applyFilter={applyFilter}
          />
        </Col>
      )}

      <Col lg={2}>
        <FilterSelect
          placeholder={t("jobs.search.employer_status_placeholder")}
          category="employer_review_status"
          appliedCategory="employer_review_status"
          sortItemsBy="name"
          items={applicationFilters.employer_review_statuses}
          selected={appliedFilters.employer_review_status}
          applyFilter={applyFilter}
        />
      </Col>
    </Row>
  );
};

export default ApplicationFilters;
