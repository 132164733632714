import React from "react";

import withLoading from "b2c/components/withLoading";
import Layout from "b2c/components/Layout";
import { Card, Spinner } from "b2c/components/Core";
import ForumCore from "../ForumCore";

const ForumWithLoading = withLoading(ForumCore, () => (
  <Layout.Content style={{ marginTop: 30, marginBottom: 30 }} id="main">
    <Card wideOnMobile shadow>
      <Spinner size="75px" margin="248px auto" />
    </Card>
  </Layout.Content>
));

export default ForumWithLoading;
