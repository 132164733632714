import styled, { css } from "styled-components";
import circleBG_dark from "images/b2c/circleBG_dark.png";
import circleBG_white from "images/b2c/circleBG_white.png";

export const DescriptionHeader = styled.div`
  ${({ premium, backgroundImage }) => css`
    width: 100%;
    min-height: 280px;
    background: ${premium
      ? `center bottom repeat url("${circleBG_dark}")`
      : `linear-gradient(rgb(20 20 30 / 80%),rgb(20 20 30 / 80%)),center / cover no-repeat url("${backgroundImage}")`};
    padding: 42px 0 15px;
    display: flex;
    align-items: flex-end;
    @media screen and (max-width: 767px) {
      padding-bottom: 100px;
    }
  `}
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  @media screen and (max-width: 767px) {
    flex-direction: ${({ $reverseOnMobile }) => ($reverseOnMobile ? "column-reverse" : "column")};
  }
`;

export const ContentSide = styled.div`
  ${({ padding = "0" }) => css`
    width: 100%;
    max-width: 835px;
    padding: ${padding};
  `}
`;

export const ContentBox = styled.div`
  background: ${({ premium }) => (premium ? `center top repeat url("${circleBG_white}")` : "transparent")};
`;

export const ContentGuide = styled.div`
  path {
    fill: ${({ fill }) => fill};
  }
`;
