import React, { useState, useContext } from "react";
import { FacebookShareButton, WhatsappShareButton, LinkedinShareButton } from "react-share";
import { FacebookSquare, LinkedinSquare, Whatsapp } from "@styled-icons/boxicons-logos";
import { Row, Col, Hidden, ScreenClassContext } from "react-grid-system";

import { Title, Text, Card, Button, Input } from "../../../../components/Core";

const InviteUsers = ({ processing, count, refer_code, sendInvite }) => {
  const [email, setEmail] = useState("");
  const screen = useContext(ScreenClassContext);
  const isSm = ["xs", "sm"].includes(screen);
  const shareUrl = `https://www.fuzu.com/signup?refer_code=${refer_code}`;

  return (
    <>
      <Card style={{ marginBottom: "25px", ...(isSm ? { borderRadius: 0 } : "") }}>
        <Card.Content padding="30px 16px">
          <Title width="100%" textAlign="center" level="5" fontWeight={600} margin="0 0 20px">
            How you can invite friends
          </Title>
          <div>
            <Text width="100%" fontSize={16} fontWeight={600} margin="0 0 15px">
              Share the invitation on social media:
            </Text>

            <Row>
              <Col lg={2} sm={4} xs={4}>
                <FacebookShareButton style={{ width: "100%" }} url={shareUrl}>
                  <Button
                    as="div"
                    height="48px"
                    fontSize="14px"
                    color="white"
                    width="100%"
                    style={{ backgroundColor: "#3E5893" }}
                  >
                    <FacebookSquare width="24px" />
                    <Hidden xs>Facebook</Hidden>
                  </Button>
                </FacebookShareButton>
              </Col>
              <Col lg={2} sm={4} xs={4}>
                <WhatsappShareButton style={{ width: "100%" }} url={shareUrl}>
                  <Button
                    as="div"
                    height="48px"
                    fontSize="14px"
                    color="white"
                    width="100%"
                    style={{ backgroundColor: "#128C7E" }}
                  >
                    <Whatsapp width="24px" />
                    <Hidden xs>Whatsapp</Hidden>
                  </Button>
                </WhatsappShareButton>
              </Col>
              <Col lg={2} sm={4} xs={4}>
                <LinkedinShareButton style={{ width: "100%" }} url={shareUrl}>
                  <Button
                    as="div"
                    height="48px"
                    fontSize="14px"
                    color="white"
                    width="100%"
                    style={{ backgroundColor: "#327BB8" }}
                  >
                    <LinkedinSquare width="24px" />
                    <Hidden xs>LinkedIn</Hidden>
                  </Button>
                </LinkedinShareButton>
              </Col>
            </Row>
          </div>
        </Card.Content>

        <Card.Separator margin="0" />

        <Card.Content padding="30px 16px">
          <Row align="end">
            <Col sm="content">
              <Input
                type="email"
                value={email}
                label="Send personal invitation via Email:"
                margin={isSm ? "0 0 10px" : "0"}
                width={isSm ? "100%" : "410px"}
                onChange={e => setEmail(e.target?.value)}
              />
            </Col>
            <Col sm="content">
              <Button
                processing={processing}
                disabled={!email || !refer_code}
                height="48px"
                width={isSm ? "100%" : "180px"}
                onClick={() => sendInvite(email)}
              >
                Send
              </Button>
            </Col>
            <Col xs={12}>
              <Text inline>My successful invitations: </Text>
              <Text inline color="success200" fontWeight={700} margin="0 0 0 5px">
                {count}
              </Text>
            </Col>
          </Row>
        </Card.Content>
      </Card>
    </>
  );
};

export default InviteUsers;
