import React from "react";
import { ArrowForward } from "@styled-icons/evaicons-solid";
import { useHistory } from "react-router-dom";

import { useTranslation } from "b2c/hooks/useTranslation";
import LogoPwa from "images/b2c/fuzu-logo-border.svg";
import { AppBannerStyled, Content, Title, Button, Text, Flex, LogoContent, ArrowContent } from "./styled";
import { useIsBarona } from "../../../../hooks";

const AppBanner = () => {
  const history = useHistory();
  const isBarona = useIsBarona();

  if (isBarona) {
    return null;
  }

  const { t } = useTranslation();

  const handleClick = () => {
    history.push("/fuzu-app");
  };

  return (
    <AppBannerStyled>
      <Content onClick={handleClick} target={-1} role="button">
        <Flex>
          <LogoContent>
            <LogoPwa />
          </LogoContent>
          <div>
            <Title>{t("fuzu_app.title")}</Title>
            <Text>{t("fuzu_app.text")}</Text>
            <Button>
              {t("fuzu_app.learn_more_button")} <ArrowForward width={20} color="#292929" />
            </Button>
          </div>
          <ArrowContent>
            <ArrowForward width={20} color="#FFFFFF" />
          </ArrowContent>
        </Flex>
      </Content>
    </AppBannerStyled>
  );
};

export default AppBanner;
