import styled from "styled-components";

export const BaronaLogoContainer = styled.div`
  svg {
    width: ${({ isSmallSize }) => (isSmallSize ? "94px" : "110px")};
    height: ${({ isSmallSize }) => (isSmallSize ? "20px" : "24px")};
  }

  path {
    fill: ${({ color }) => color || "#18181b"};
  }

  @media screen and (min-width: 768px) {
    svg {
      width: 110px;
      height: 24px;
    }
  }
`;
