import React, { useEffect } from "react";
import { observer } from "mobx-react";

import { useNotification, usePrev } from "utils/hooks";
import useStore from "../../contexts/store";
import { Text } from "../Core";

const NetworkObserver = ({ children }) => {
  const { connected, processingTooLong, updateConnection } = useStore("AppState");
  const prevProcessing = usePrev(processingTooLong);
  const prevConnected = usePrev(connected);

  useEffect(() => {
    window.addEventListener("online", updateNetworkConnection);
    window.addEventListener("offline", updateNetworkConnection);

    return () => {
      window.removeEventListener("online", updateNetworkConnection);
      window.removeEventListener("offline", updateNetworkConnection);
    };
  }, []);

  const notifyTooLong = useNotification(<TakesTooLong />, "accent300");
  const notifyConnectionLost = useNotification(<ConnectionLost />, "accent300");
  const disableNotification = useNotification(null);

  useEffect(() => {
    if (processingTooLong) {
      notifyTooLong();
    } else if (prevProcessing && prevProcessing !== processingTooLong) {
      disableNotification();
    }
  }, [processingTooLong]);

  useEffect(() => {
    if (!connected) {
      notifyConnectionLost();
    } else if (prevConnected && prevConnected !== connected) {
      disableNotification();
    }
  }, [connected]);

  const updateNetworkConnection = () => {
    updateConnection(window.navigator.onLine);
  };

  return children;
};

const TakesTooLong = () => {
  return (
    <Text color="white" margin="10px auto">
      You seem to have a slow internet connection – please wait patiently!
    </Text>
  );
};

const ConnectionLost = () => {
  return (
    <Text color="white" margin="10px auto">
      No internet connection – please check your connection.
    </Text>
  );
};

export default observer(NetworkObserver);
