import React, { useState, useRef, useEffect } from "react";
import { useScreenClass } from "react-grid-system";
import { ErrorCircle } from "@styled-icons/boxicons-solid";

import { Card, Input, Title, Button } from "../Core";
import DragAndDrop from "../DragAndDrop";
import FileLabel from "../FileLabel";
import { Type, Error } from "./styled";

const FileUploader = ({
  title,
  name,
  url,
  processing,
  buttonText = "Choose file",
  onUpload,
  emptyComponent,
  cardProps = {},
  accept,
  autoFocus,
  additionalContent = null,
  inputName,
  isRemoveIcon,
  deleteEducationCertificate,
  secondButtonText,
  showError,
  error,
  showTypesBelow,
  dataCy,
  ...rest
}) => {
  const ref = useRef();
  const [fileName, setName] = useState(name);
  const [uploaded, setUploaded] = useState(false);

  useEffect(() => {
    if (name) {
      setName(name);
    }
  }, [name]);

  useEffect(() => {
    if (autoFocus && ref.current) ref.current.focus();
  }, [ref.current]);

  const handleUpload = e => {
    const file = e.target.files[0];
    if (file) {
      setName(file.name);
      setUploaded(true);

      if (onUpload) {
        onUpload(file);
      }
    }
  };

  const renderTitle = () => {
    if (uploaded && secondButtonText) {
      return secondButtonText;
    }

    return buttonText;
  };

  return (
    <Card {...cardProps}>
      <Card.Content padding="15px 20px">
        {title && (
          <Title level="6" fontWeight={700} margin="0 0 8px">
            {title}
          </Title>
        )}

        {!showTypesBelow && <Type className="upload-file__types">PDF, DOC, DOCX, RTF or TXT</Type>}

        {error?.isShow && (
          <Error>
            <ErrorCircle />
            {error.text}
          </Error>
        )}

        <div className="upload-file__content">
          <FileLabel
            isRemoveIcon={isRemoveIcon}
            url={url}
            fileName={fileName}
            emptyComponent={emptyComponent}
            uploaded={uploaded}
            deleteEducationCertificate={deleteEducationCertificate}
          />

          <Input.File
            onChange={e => handleUpload(e)}
            accept={accept}
            errorMessage={error?.isShow ? "" : rest.errorMessage}
            dataCy={dataCy}
            Component={props => (
              <Button
                className="upload-file__upload-button"
                type="button"
                fontSize="14px"
                margin="15px auto 0"
                theme={url || uploaded ? "grey200" : "primary"}
                style={{ width: "100%", maxWidth: "440px" }}
                processing={processing}
                ref={ref}
                color={!(url || uploaded) ? "white" : "primaryButtonLabelColor"}
                {...props}
              >
                {renderTitle()}
              </Button>
            )}
            name={inputName}
            {...rest}
          />
          {additionalContent}
        </div>

        {showTypesBelow && (
          <Type className="upload-file__types upload-file__types_below">PDF, DOC, DOCX, RTF or TXT</Type>
        )}
      </Card.Content>
    </Card>
  );
};

FileUploader.DragAndDrop = props => {
  const isSm = /xs|sm/.test(useScreenClass());

  return (
    <DragAndDrop dataCy="upload-file" {...props}>
      {isSm ? "Click to choose file" : "Drag and drop your file here"}
    </DragAndDrop>
  );
};

export default FileUploader;
