import React, { useContext } from "react";
import { Col, Row, ScreenClassContext } from "react-grid-system";

import { Button } from "b2c/components/Core";
import { useTranslation } from "b2c/hooks/useTranslation";
import useStore from "../../../../contexts/store";
import { useModal } from "../../../../../utils/hooks";
import ExitModal from "../ExitModal";

const AuthorizedActions = ({
  selected,
  selectedIndex,
  fromUrl,
  search,
  expired,
  isFastApply,
  isAssigmentRequested
}) => {
  const screen = useContext(ScreenClassContext);
  const { track } = useStore("Tracking");
  const isMobile = /sm|xs/.test(screen);
  const cancelApplication = useModal(<ExitModal job_id={selected.id} app_id={selected.application?.id} />, "");

  const { t } = useTranslation();

  const renderApplyButtonText = () => {
    if (!expired || selected.active_fast_apply_application) {
      if (selected.application) {
        if (selected.application.submitted) {
          if (isAssigmentRequested) {
            return t("jobs.content.actions.start_assignment");
          }
          return t("jobs.content.actions.view_details");
        }
        return t("jobs.content.actions.continue");
      }

      if (isFastApply) {
        return t("jobs.content.actions.fast_apply");
      }

      return t("jobs.content.actions.apply");
    }
    return t("jobs.content.actions.see_other_jobs");
  };

  const {
    JobSearch: {
      params: { appliedFilters, page, per_page }
    }
  } = useStore();

  const trackApply = () => {
    const canApply = !expired && !selected.application;
    // NOTE Atte: Additional safeguards for preventing crashes - might be redundant
    const trackingPropsDefined =
      appliedFilters && page && per_page && typeof selectedIndex == "number" && selectedIndex > -1;

    if (canApply && trackingPropsDefined) {
      const { sort_by = "recommended", ...filters } = appliedFilters;
      track("job_search_apply", { sort_by, filters, ranking: (page - 1) * per_page + selectedIndex + 1 });
    }
  };

  return (
    <Row justify={isMobile ? "between" : "start"}>
      {(!expired || selected.active_fast_apply_application) && (
        <Col lg="content" xs={selected.application ? 6 : 12}>
          <Button.Link
            theme="primary"
            height={isMobile ? 36 : 42}
            width={isMobile ? "100%" : "auto"}
            style={{ minWidth: 120 }}
            color="primaryButtonLabelColor"
            to={{
              pathname: `/job${!expired || isAssigmentRequested ? `s/${selected.id}/application` : ""}`,
              state: { from: fromUrl, search }
            }}
            onClick={trackApply}
          >
            {renderApplyButtonText()}
          </Button.Link>
        </Col>
      )}
      {selected.application && !expired && (
        <Col lg="content" xs={6}>
          <Button
            theme="grey100"
            onClick={() => cancelApplication()}
            height={isMobile ? 36 : 42}
            width={isMobile ? "100%" : "auto"}
          >
            {t("global.cancel")}
          </Button>
        </Col>
      )}
    </Row>
  );
};

export default AuthorizedActions;
