import React from "react";

import Company from "b2c/components/Company";
import { Card, Title } from "b2c/components/Core";
import { useTranslation } from "b2c/hooks/useTranslation";

const CompanyContent = ({ name, jobs, articles, handleSaving, ...details }) => {
  const { t } = useTranslation();

  return (
    <Card.Content className="entity-page_company_content">
      <Title level={3} h1ForGuests fontWeight={600} margin="12px 0" fontSize="20px">{`${name} ${t(
        "company.job_vacancies"
      )}`}</Title>
      <Company.Content {...details} />
    </Card.Content>
  );
};
export default CompanyContent;
