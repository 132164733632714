import React from "react";
import { Button, Text } from "../../../../components/Core";
import ModalContent from "../ModalContents";

const PlainModalContent = ({ downloadCv, selectPremium, close }) => {
  const handleDownload = () => {
    downloadCv();
    close();
  };

  const handlePremium = () => {
    selectPremium();
    close();
  };

  return (
    <ModalContent
      title="Are you sure you don’t want to stand out?"
      Actions={[
        <Button theme="grey200" onClick={handleDownload}>
          Download Free CV
        </Button>,
        <Button theme="primary" onClick={handlePremium}>
          Get Premium CV
        </Button>
      ]}
    >
      <Text textAlign="center" margin="0 auto 36px">
        You are about to download the free Fuzu CV file.
        <br />
        According to our research, applicants with the Premium CV
        <br />
        have a higher chance of getting noticed by employers.
      </Text>
    </ModalContent>
  );
};

export default PlainModalContent;
