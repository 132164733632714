import React from "react";
import { useLocation } from "react-router-dom";

import { isCustomJkuat, isWhitelabelled, isKepsa } from "b2c/contexts/branded";
import DefaultFooter from "../DefaultFooter";
import WhitelabelledFooter from "../WhitelabelledFooter";

const Footer = props => {
  const { pathname } = useLocation();
  const customJkuat = isCustomJkuat();
  const isKepsaBrand = isKepsa();
  const whitelabelled = isWhitelabelled();

  const isHeroCardPage = pathname.indexOf("/career-hero") !== -1;
  if (isHeroCardPage) return null;

  return whitelabelled || customJkuat ? (
    <WhitelabelledFooter isBrandFooterInformation={customJkuat || isKepsaBrand} />
  ) : (
    <DefaultFooter {...props} />
  );
};

export default Footer;
