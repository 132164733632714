import { observer } from "mobx-react";
import React, { useContext, useMemo } from "react";
import { ScreenClassContext } from "react-grid-system";

import { useStandaloneMode } from "b2c/contexts/standalone";
import { ARTICLES_FALLBACK, ARTICLES_FUZU_APP } from "b2c/constants/home";
import FuzuAndroidAppPromo from "b2c/components/FuzuAndroidAppPromo";
import useStore from "b2c/contexts/store";
import Layout from "b2c/components/Layout";
import { Carousel } from "b2c/components/Core";
import HeroItem from "./HeroItem";
import { useIsBarona } from "../../../hooks";

const ArticleCarousel = observer(() => {
  const {
    hero: { items, count_limit: countLimit = 1 },
    highlightedRegions
  } = useStore("HomeLayout");

  const isBarona = useIsBarona();
  const screen = useContext(ScreenClassContext);
  const isSm = ["xs", "sm"].includes(screen);
  const isStandalone = useStandaloneMode();

  const headerArticles = useMemo(() => {
    const articlesList = items.default
      ? items.default.slice(0, highlightedRegions.length > 0 ? 1 : +countLimit)
      : ARTICLES_FALLBACK;

    return isStandalone || isBarona ? articlesList : [...ARTICLES_FUZU_APP, ...articlesList];
  }, [items.onboarding_category, items.default, countLimit, highlightedRegions, isBarona]);

  return (
    <Layout.Section margin={isSm ? "0 0 15px" : "0 0 50px"}>
      <Carousel
        settings={{
          className: "wide-blue-buttons dots-bottom slick-stretched-on-mobile slick-home-heading",
          fade: false,
          dots: isSm,
          initialSlide: 0,
          slidesToShow: 3,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 8000,
          adaptiveHeight: false,
          responsive: [
            {
              breakpoint: 767,
              settings: {
                arrows: false,
                slidesToShow: 1,
                fade: false
              }
            }
          ]
        }}
      >
        {headerArticles.map(
          ({ title, background_image: backgroundImage, front_image, article_stats = {}, id, path, type }) => {
            if (type === "customFuzuApp" && !isStandalone) {
              return (
                <Carousel.Slide key={type} padding="0 8px">
                  <FuzuAndroidAppPromo isSmallText={isSm} />
                </Carousel.Slide>
              );
            }
            return (
              <Carousel.Slide key={`article_slide_${id}`} padding="0 8px">
                <HeroItem
                  isSm={isSm}
                  title={title}
                  background_image={backgroundImage}
                  front_image={front_image}
                  disabled={highlightedRegions.length > 0}
                  font_color="white"
                  id={id}
                  path={path}
                  {...article_stats}
                />
              </Carousel.Slide>
            );
          }
        )}
      </Carousel>
    </Layout.Section>
  );
});

export default ArticleCarousel;
