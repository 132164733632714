import React, { useState } from "react";

import flagKenya from "@images/flag-kenya.jpg";
import flagNigeria from "@images/flag-nigeria.jpg";
import flagUganda from "@images/flag-uganda.jpg";
import useStore from "../../../contexts/store";
import { Card, Checkbox, Image, Label } from "../../Core";

const flags = new Map([
  ["nigeria", flagNigeria],
  ["kenya", flagKenya],
  ["uganda", flagUganda]
]);

const Country = () => {
  const { countries } = useStore("initialState");
  const [selected, setSelected] = useState([]);

  const handleSelect = e => {
    const value = +e.target.value;
    setSelected(prev => (prev.includes(value) ? prev.filter(item => item != value) : prev.concat(value)));
  };

  return (
    <Card theme="grey100">
      <Card.Content>
        {countries.map(({ id, slug, name }) => (
          <Checkbox
            key={slug}
            id={slug}
            name="country"
            value={id}
            borderedView
            margin="6px 0"
            onChange={handleSelect}
            checked={selected.includes(id)}
            checkedColor="white"
            checkedBgColor="white"
            markColor="black500"
            label={
              <Label
                theme="transparent"
                Icon={<Image src={flags.get(slug)} height={28} margin="0 8px 0 0" />}
                fontSize={14}
                fontWeight={600}
                margin="0"
              >
                {name}
              </Label>
            }
          />
        ))}
      </Card.Content>
    </Card>
  );
};

export default Country;
