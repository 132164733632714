import React from "react";
import { Col, Row } from "react-grid-system";
import { useTranslation } from "b2c/hooks/useTranslation";

import { buildImageUrlS3, getCompanyName } from "b2c/contexts/branded";
import Layout from "../../../../components/Layout";
import { BlurredImage } from "../../styled";
import { Image, Text, Title } from "../../../../components/Core";
import { nameImages } from "../../../../constants/images";

const Welcome = () => {
  const backgroundImage = buildImageUrlS3(nameImages.learnMain);
  const { t } = useTranslation();
  const companyName = getCompanyName();

  return (
    <Layout.Section
      backgroundColor="white"
      padding="20px 0"
      margin="0 0 10px"
      style={{
        position: "relative",
        zIndex: 1,
        overflow: "hidden"
      }}
    >
      <BlurredImage bgImage={backgroundImage} />
      <Layout.Content tag="div" size="xl">
        <Row align="center">
          <Col md={6}>
            <Title scales fontWeight={500} margin="0 0 40px" color="white">
              {t("learn.title", { name: companyName })}
            </Title>
            <Text fontSize={18} margin="0 0 15px" color="white">
              {getCompanyName()} {` ${t("learn.description_1")}`}
            </Text>
            <Text fontSize={18} margin="0 0 15px" color="white">
              {t("learn.description_2")}
            </Text>
            <Text fontSize={18} margin="0 0 15px" color="white">
              {t("learn.description_3")}
            </Text>
          </Col>
          <Col md={6}>
            <Image src={backgroundImage} margin="auto" display="flex" />
          </Col>
        </Row>
      </Layout.Content>
    </Layout.Section>
  );
};

export default Welcome;
