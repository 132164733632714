import styled from "styled-components";

export const PostsWrapper = styled.div`
  overflow: hidden;
  margin: -20px -20px 0;
  padding: 20px;

  @media screen and (max-width: 576px) {
    margin: 0;
    padding: 0 0 20px 0;
    .card-header,
    .card-footer {
      border-radius: 0;
    }
  }
`;
