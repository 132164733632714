import styled from "styled-components";
import { Search } from "@styled-icons/boxicons-regular";
import { Link } from "react-router-dom";

export const Container = styled.div`
  margin-bottom: 124px;
`;

export const SearchWrapper = styled.div`
  position: relative;
  margin-bottom: 24px;
`;

export const SearchInput = styled.input`
  border-radius: 8px;
  border: 1px solid #c5c5c5;
  background: #fff;
  padding: 17px 16px 17px 40px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #212121;
  display: block;
  width: 100%;

  &:placeholder {
    color: #a4a4a4;
  }
`;

export const SearchInputIcon = styled(Search)`
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  left: 16px;
  top: 50%;
  fill: #a4a4a4;
  margin-top: -8px;
`;

export const Label = styled.span`
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  display: block;
  margin-bottom: 8px;
`;

export const Skip = styled(Link)`
  color: #333;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #c5c5c5;
  width: 100%;
  display: block;
  text-decoration: none;
`;

export const InlineMenu = styled.div`
  ${({ theme }) => `
    border: 1px solid ${theme.grey200};
    background-color: ${theme.white};
    border-radius: 5px;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    max-width: 480px;
    top: -20px;
`}
`;

export const Item = styled.div`
  ${({ theme }) => `
    padding: 10px 12px;
    cursor: pointer;
    color: #000;
    min-width: 250px;
    display: flex;
    white-space: pre;
    font-weight: 500;

    span {
        background-color: ${theme.primary100};
    }

    &.selected {
        font-weight: 500;
        color: ${theme.black500};
    }

    &:hover {
        background-color: ${theme.primary100};
    }
`}
`;

export const Relative = styled.div`
  position: relative;
`;
