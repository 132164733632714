import React from "react";
import { useTranslation } from "b2c/hooks/useTranslation";

import { Notification, Text } from "../../../../components/Core";

const Tip = ({ type = "internal", employer = "employer" }) => {
  const { t } = useTranslation();

  const { title, subtitle } = (() =>
    type === "internal"
      ? {
          title: `${t("application.tip.title_1")} ${employer}`,
          subtitle: t("application.tip.description_1")
        }
      : {
          title: t("application.tip.title_1", { name: employer }),
          subtitle: t("application.tip.description_2")
        })();

  return (
    <Notification>
      <Text width="100%" fontSize="16px" fontWeight="600">
        {title}
      </Text>
      <Text width="100%" fontSize="14px" fontWeight="400">
        {subtitle}
      </Text>
    </Notification>
  );
};

export default Tip;
