import React, { forwardRef } from "react";

import { useScreenClass } from "react-grid-system";
import circleBG_dark from "images/b2c/circleBG_dark.png";
import { Card } from "../Core";

const PremiumBox = forwardRef(({ height = 150, smHeight, children, ...rest }, ref) => {
  const isSm = /xs|sm/.test(useScreenClass());

  return (
    <Card ref={ref} theme="black400" backgroundImage={circleBG_dark} backgroundSize="auto" {...rest}>
      <Card.Content padding="15px" height={isSm && smHeight ? smHeight : height}>
        {children}
      </Card.Content>
    </Card>
  );
});

export default PremiumBox;
