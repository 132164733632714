import React, { useEffect, useRef } from "react";
import { observer } from "mobx-react";
import { useScreenClass, Hidden, Visible, Row, Col } from "react-grid-system";

import { useTranslation } from "b2c/hooks/useTranslation";
import Layout from "b2c/components/Layout";
import { imageMap } from "b2c/constants/cvBuilder";
import { Title, Button, List, Carousel } from "b2c/components/Core";
import LoadingScreen from "b2c/pages/LoadingScreen";
import useStore from "../../../../contexts/store";
import { StepWrapper } from "./styled";
import TemplateSlide from "../TemplateSlide";

const TemplateSelect = ({ CloseButton, setStep }) => {
  const {
    CvBuilder: {
      state: { loading },
      templates,
      applyOptions,
      getTemplates
    },
    Premium: {
      features: { premium_cv_creator = {} }
    }
  } = useStore();
  const isSm = /md|xs|sm/.test(useScreenClass());
  const parentRef = useRef();
  const { t } = useTranslation();

  useEffect(() => {
    getTemplates();
  }, []);

  const applyTemplate = template_id => {
    applyOptions({ template_id });
    setStep(3);
  };

  return (
    <>
      <CloseButton />
      <StepWrapper column ref={parentRef}>
        <Layout.Section
          padding={isSm ? "0" : "32px 0"}
          style={{ width: "100%", ...(isSm ? { height: "10vh", display: "flex" } : {}) }}
        >
          <Layout.Content tag="div" size="xl" {...(isSm ? { style: { height: "100%", display: "flex" } } : {})}>
            <Title level={isSm ? 4 : 3} fontWeight={isSm ? 600 : 700} margin={isSm ? "auto" : "0"}>
              1. {t("cv_creator.flow.select_design")}
            </Title>
          </Layout.Content>
        </Layout.Section>
        {loading ? (
          <LoadingScreen />
        ) : (
          <>
            <Hidden md sm xs>
              <Layout.Section backgroundColor="black400" padding="32px 0" style={{ flex: "1 1 auto" }}>
                <Layout.Content tag="div" size="xl" style={{ height: "100%" }}>
                  <Row style={{ height: "100%" }}>
                    {templates.map(({ id, name, slug, product }) => (
                      <Col key={name} lg={3} style={{ display: "flex", flexFlow: "column" }}>
                        <TemplateSlide
                          popular={id == 1}
                          image={imageMap[slug]}
                          currency={product?.currency}
                          price={product?.discounted_price}
                          premium={!!product}
                          purchased={premium_cv_creator?.activated}
                          onClick={() => applyTemplate(id)}
                        />
                      </Col>
                    ))}
                  </Row>
                </Layout.Content>
              </Layout.Section>
              <Layout.Section padding="32px 0 64px" style={{ width: "100%" }}>
                <Layout.Content tag="div" size="xl">
                  <Row>
                    {templates.map(({ id, name, description }) => (
                      <Col key={name} lg={3} style={{ display: "flex", height: 240, flexFlow: "column" }}>
                        <Title level={4} fontWeight={700} margin="0 0 12px">
                          {name}
                        </Title>
                        <List listType="">
                          {description.split("\n").map((item, index) => (
                            <List.Item key={`${name}-feature-${index + 1}`}>{item}</List.Item>
                          ))}
                        </List>
                        <Button width="100%" margin="auto 0 0" onClick={() => applyTemplate(id)}>
                          {t("cv_creator.flow.select_cv_button", { name })}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </Layout.Content>
              </Layout.Section>
            </Hidden>
            <Visible md sm xs>
              <Carousel
                className="wide-blue-buttons builder"
                settings={{
                  dots: false,
                  adaptiveHeight: false,
                  beforeChange: () => {
                    window.scrollTo(0, 0);
                  }
                }}
              >
                {templates.map(({ id, name, description, slug, product }, i) => (
                  <Carousel.Slide
                    key={name}
                    styled={{
                      display: "flex",
                      flexDirection: "column",
                      height: "100%"
                    }}
                  >
                    <Layout.Section backgroundColor="black400" padding="32px 0" style={{ overflow: "hidden" }}>
                      <Layout.Content tag="div" size="sm" style={{ height: "100%" }}>
                        <TemplateSlide
                          popular={id == 1}
                          image={imageMap[slug]}
                          currency={product?.currency}
                          price={product?.discounted_price}
                          premium={!!product}
                          purchased={premium_cv_creator?.activated}
                          onClick={() => applyTemplate(id)}
                        />
                      </Layout.Content>
                    </Layout.Section>
                    <Layout.Section padding="16px 0" style={{ width: "100%", flex: "1 0 auto" }}>
                      <Layout.Content
                        tag="div"
                        size="sm"
                        style={{ height: "100%", display: "flex", flexFlow: "column" }}
                      >
                        <Title level={5} fontWeight={700} margin="0 auto 12px">
                          Design {i + 1}/{templates.length} - {name}
                        </Title>
                        <List listType="">
                          {description.split("\n").map((item, index) => (
                            <List.Item key={`${name}-feature-${index + 1}`}>{item}</List.Item>
                          ))}
                        </List>
                        <Button width="100%" margin="auto 0 0" onClick={() => applyTemplate(id)}>
                          {t("cv_creator.flow.select_cv_button", { name })}
                        </Button>
                      </Layout.Content>
                    </Layout.Section>
                  </Carousel.Slide>
                ))}
              </Carousel>
            </Visible>
          </>
        )}
      </StepWrapper>
    </>
  );
};

export default observer(TemplateSelect);
