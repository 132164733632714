import React from "react";
import { useTranslation } from "b2c/hooks/useTranslation";

import { getCompanyName, isBaronaBrand } from "b2c/contexts/branded";
import { Input, Hint, Text } from "../../../Core";

const CreateAccount = ({ error }) => {
  const isBarona = isBaronaBrand();
  const { t } = useTranslation();

  return (
    <>
      <Input
        type="name"
        name="name"
        label={t("onboarding.credentials.list.label_1")}
        errorMessage={error?.name}
        data-cy="onboarding-first-name"
      />
      <Input
        type="password"
        name="password"
        placeholder={t("onboarding.credentials.list.placeholder_2")}
        label={t("onboarding.credentials.list.label_2")}
        errorMessage={error?.password}
      />
      <Hint>{t("onboarding.credentials.list.hint")}</Hint>

      <Text>
        {t("onboarding.credentials.list.agreement", { name: getCompanyName() })}
        <Text
          as="a"
          href={isBarona ? "https://policies.barona.fi/" : "/privacy-policy"}
          className="link underlined"
          fontWeight="600"
          color="secondary200"
          rel="noopener noreferrer"
          target="_blank"
        >
          {t("onboarding.credentials.list.privacy")}
        </Text>{" "}
        {`${t("onboarding.credentials.list.and")} `}
        <Text
          as="a"
          href="/terms-and-conditions"
          className="link underlined"
          fontWeight="600"
          color="secondary200"
          rel="noopener noreferrer"
          target="_blank"
        >
          {t("onboarding.credentials.list.terms")}
        </Text>
      </Text>
    </>
  );
};

export default CreateAccount;
