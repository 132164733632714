import React from "react";
import { Row, Col } from "react-grid-system";
import { observer } from "mobx-react";

import FuzuPointMainIco from "images/fuzuPointMainIco.svg";
import FuzuPointsInfoIcon from "images/FuzuPointsInfoIcon.svg";
import { Card, Title, Text, Spinner } from "b2c/components/Core";
import { getCompanyName } from "b2c/contexts/branded";
import { PromotionCountryVisible } from "b2c/components/WithAuth";
import FaqItem from "b2c/components/FaqItem";
import PromoSection from "b2c/components/PromoSection";
import Chatline from "../../../../components/Chatline";
import { FeatureHidden } from "../../../../hocs";
import useStore from "../../../../contexts/store";
import ProductItem from "../ProductItem";

const FuzuPointsInfo = ({ points }) => {
  const { user } = useStore("User");
  const isActiveProduct = ["NG", "KE"].includes(user?.product_country?.country_code);

  return (
    <>
      <Row>
        <Col sm={4} style={{ margin: "0 0 20px" }}>
          <Card wideOnMobile shadow style={{ height: "100%" }}>
            <Card.Content padding="30px 15px" style={{ alignItems: "center" }}>
              <Title level="5" textAlign="center" width="100%" fontWeight={600} margin="0 0 25px">
                My {getCompanyName()} Points
              </Title>
              <FuzuPointMainIco />

              {points || Number(points) === 0 ? (
                <Text
                  textAlign="center"
                  fontSize={48}
                  fontWeight={700}
                  lineHeight="1"
                  color="success300"
                  margin="25px 0 0"
                >
                  {points}
                </Text>
              ) : (
                <Spinner size="48px" margin="25px 0 0" />
              )}
            </Card.Content>
          </Card>
        </Col>
        <Col sm={8} style={{ margin: "0 0 20px" }}>
          <Card wideOnMobile shadow style={{ height: "100%" }}>
            <Card.Content padding="30px 15px" style={{ alignItems: "center" }}>
              <Title level="5" textAlign="center" width="100%" fontWeight={600} margin="0 0 25px">
                You`ll get Fuzu Points when your friend joins Fuzu
              </Title>
              <FuzuPointsInfoIcon />
              <Text textAlign="center" margin="25px 0 0">
                Fuzu Points is Fuzu’s own reward program. You get Fuzu Points for being active on the platform and for
                being committed to constantly growing and pushing yourself for success. You can use them to get
                discounts from Fuzu Premium products.
              </Text>
            </Card.Content>
          </Card>
        </Col>
      </Row>
      <Text fontSize={20} fontWeight={500} margin="20px 0">
        Use Fuzu Points to get a discount on Fuzu Premium products
      </Text>

      {products.map(product => (
        <FeatureHidden feature={product.hiddenIfFeatureActivated} key={product.title}>
          <PromotionCountryVisible
            key={product.path}
            countryCodes={product.slug === "career-coaching" && isActiveProduct ? ["KE", "UG"] : []}
          >
            <ProductItem {...product} />
          </PromotionCountryVisible>
        </FeatureHidden>
      ))}

      <Card wideOnMobile shadow style={{ margin: "0 0 20px" }}>
        <Card.Content padding="20px 15px">
          <Text fontSize={20} fontWeight={500}>
            Frequently Asked Questions
          </Text>
          <FaqItem
            title="How to earn Fuzu Points?"
            text={`You just need to be active on the platform.
                        We reward you for inviting your friends to the platform,
                        for applying for jobs, for taking courses and much more.
                        We have marked the activities that provide points with a Fuzu Points sign`}
          />
          <FaqItem
            title="How to spend Fuzu Points?"
            text={`Whenever you purchase Fuzu Premium products like
                        Career Coaching or Instant Feedback, you can use Fuzu Points
                        to get a discount. With some services, the whole service can be
                        paid with Fuzu Points, while with others there is a limit to the discount.
                        Please follow the instructions carefully!`}
          />
        </Card.Content>
      </Card>
      <PromoSection type="invite_friends" />
      <Chatline />
    </>
  );
};

const products = [
  {
    title: "Career Coaching",
    slug: "career-coaching",
    path: "/premium/career-coaching",
    description: "Fuzu’s experienced career coaches help you through all the steps in your career.",
    hiddenIfFeatureActivated: null
  },
  /* hide for this ticket https://app.shortcut.com/fuzu/story/4129/remove-instant-feedback-remove-all-promo-elements-advertising-if-from-b2c-app */
  /*
  {
    title: "Instant Feedback",
    path: "/premium/instant-feedback",
    description:
      "Instant Feedback gives you guidance you deserve: You will receive detailed feedback on your Fuzu profile and you see how your profile compares with those of the top candidates.",
    hiddenIfFeatureActivated: "instant_feedback_global"
  },
  */
  {
    title: "CV Creator",
    path: "/cv-creator",
    description:
      "Turn your Fuzu Profile into a professional looking CV file. Fuzu has multiple beautiful CV templates which you can customise to impress the employers!",
    hiddenIfFeatureActivated: null
  }
];

export default observer(FuzuPointsInfo);
