import styled from "styled-components";
import { Col } from "react-grid-system";

export const MessageContent = styled.div`
  position: relative;
`;

export const Indent = styled.div`
  width: 100%;
  height: 50px;
`;

export const SocialContent = styled(Col)`
  order: 4;

  @media screen and (min-width: 992px) {
    order: 0;
  }
`;
