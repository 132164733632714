import React from "react";
import { Link } from "react-router-dom";

const MessagesLink = ({ children }) => {
  return (
    <Link to="/messages" className="user-side--link">
      {children}
    </Link>
  );
};

export default MessagesLink;
