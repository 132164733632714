import React, { useContext } from "react";
import { MdStar, MdStarBorder, MdStarHalf } from "react-icons/md";
import cn from "classnames";

import { useTranslation } from "b2c/hooks/useTranslation";
import { StyledStars } from "./styled";
import ThemeContext from "../../contexts/theme";
import useStore from "../../contexts/store";

const StarRating = ({ hideNumbers = false, hideCount, hideStars = false, rating, count, textColor = "black500" }) => {
  const theme = useContext(ThemeContext);
  const { global_brand } = useStore("initialState");
  const globalBrandName = global_brand.name.toLowerCase();
  const { t } = useTranslation();

  return (
    <StyledStars className={cn("b2c-stars", `b2c-stars_${globalBrandName}`)} textColor={theme[textColor]}>
      {!hideStars && (
        <>
          {[...new Array(5).keys()].map(i =>
            i + 1 <= rating ? (
              <MdStar key={i} />
            ) : rating - i >= 0.375 ? (
              rating - i >= 0.625 ? (
                <MdStar key={i} />
              ) : (
                <MdStarHalf key={i} />
              )
            ) : (
              <MdStarBorder key={i} />
            )
          )}
        </>
      )}

      {!hideNumbers && !isNaN(rating) && <span className="rating">{rating.toFixed(1)}</span>}

      {!hideCount && (
        <span className="count">
          ({count && !isNaN(count) && count > 0 ? `${count} ${t("global.rating")}` : t("global.no_rating")})
        </span>
      )}
    </StyledStars>
  );
};

export default StarRating;
