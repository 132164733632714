import React, { useLayoutEffect, useState } from "react";
import { Col, Hidden, Row } from "react-grid-system";
import { Reset } from "@styled-icons/boxicons-regular";
import { useTranslation } from "b2c/hooks/useTranslation";
import { onEnterPress } from "../../../utils/helpers";
import { Button, Icon, SearchInput } from "../Core";
import LocationFilter from "../LocationFilter";

const SearchRow = ({
  loading,
  countryIds,
  townIds,
  term = "",
  applyFilter,
  resetFilters,
  placeholder = "Search by job title",
  showLocation = true,
  buttonText = "Show jobs",
  searchTermCallback,
  searchFilters,
  countryTownRef,
  getPageInfo,
  appliedFilters,
  handleSearchAndHideFilters,
  isShowClearFilterButton
}) => {
  const { t } = useTranslation();

  const [searchTerm, setSearchTerm] = useState(term);

  useLayoutEffect(() => {
    setSearchTerm(term);
  }, [term]);

  const handleSearchInput = ({ target: { value } }) => {
    setSearchTerm(value);
    if (searchTermCallback) searchTermCallback(value);
    if (!value && term) applyFilter({ term: "" });
  };

  const handleSearch = () => {
    if (handleSearchAndHideFilters) {
      handleSearchAndHideFilters();
    } else {
      applyFilter({ term: searchTerm });
    }

    if (countryTownRef?.current) {
      countryTownRef.current.click();
    }
  };

  const onKeyDown = e => {
    onEnterPress(e, searchTerm, handleSearch);
  };

  return (
    <Row onKeyDown={e => onKeyDown(e)}>
      <Col lg={showLocation ? 4 : 8}>
        <SearchInput
          disabled={loading}
          value={searchTerm}
          width="100%"
          onChange={handleSearchInput}
          borderWidth="2px"
          placeholder={placeholder}
          borderColor={term ? "secondary200" : undefined}
          backgroundColor={term ? "secondary100" : undefined}
          iconColor={term ? "black500" : undefined}
        />
      </Col>

      {showLocation && (
        <Col lg={4}>
          <LocationFilter
            loading={loading}
            countryIds={countryIds}
            townIds={townIds}
            applyFilter={applyFilter}
            countries={searchFilters.countries}
            towns={searchFilters.towns}
            countryTownRef={countryTownRef}
            getPageInfo={getPageInfo}
            appliedFilters={appliedFilters}
          />
        </Col>
      )}

      <Hidden sm xs>
        <Col lg={2}>
          <Button color="primaryButtonLabelColor" width="100%" onClick={handleSearch}>
            {buttonText}
          </Button>
        </Col>

        {isShowClearFilterButton ? (
          <Col lg={2}>
            <Button
              width="100%"
              onClick={resetFilters}
              noBackground
              theme="black200"
              icon={<Icon as={Reset} width={20} fill="black200" />}
            >
              {t("global.clear_filters_button")}
            </Button>
          </Col>
        ) : null}
      </Hidden>
    </Row>
  );
};

export default SearchRow;
