import React from "react";
import { Row, Col } from "react-grid-system";

import { Image } from "b2c/components/Core";
import { FeatureStyled, Title, Text, LeftSide, RightSide, Content, ImageContent } from "./styled";

const Feature = ({
  title,
  text,
  isReverse,
  image,
  imageWidth,
  imageHeight,
  imageWidthTablet,
  imageHeightTablet,
  smartNotificationRef
}) => {
  const reverse = isReverse ? "row-reverse" : "reverse";
  const backgroundColor = isReverse ? "#F3F3F3" : "#FFFFFF";

  return (
    <FeatureStyled backgroundColor={backgroundColor} ref={smartNotificationRef}>
      <Content>
        <Row direction={reverse} align="center">
          <Col xs={12} xl={7}>
            <LeftSide isReverse={isReverse}>
              <Title>{title}</Title>
              <Text>{text}</Text>
            </LeftSide>
          </Col>
          <Col xs={12} xl={5}>
            <RightSide isReverse={isReverse}>
              <ImageContent
                imageWidth={imageWidth}
                imageHeight={imageHeight}
                imageWidthTablet={imageWidthTablet}
                imageHeightTablet={imageHeightTablet}
              >
                <Image src={image} />
              </ImageContent>
            </RightSide>
          </Col>
        </Row>
      </Content>
    </FeatureStyled>
  );
};

export default Feature;
