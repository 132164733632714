import styled from "styled-components";

export const Title = styled.h4`
  color: #333;
  font-size: 20px;
  font-weight: 600;
  line-height: 130%;
`;

export const Label = styled.p`
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  max-width: 824px;
`;

export const Content = styled.div`
  margin: 12px 0;
  border-top: 1px solid #eee;
  padding: 28px 16px 8px;
`;

export const Flex = styled.div`
  margin-top: 24px;

  @media screen and (min-width: 768px) {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 24px;
  }
`;

export const Error = styled.div`
  border-radius: 8px;
  background: #ffeae5;
  padding: 14px;
  display: flex;
  align-items: center;
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  gap: 8px;
  margin-bottom: 24px;

  svg {
    width: 20px;
    height: 20px;
    fill: #e71d36;
    flex: none;
  }
`;

export const ProfessionItem = styled.div`
  margin: 0 auto 24px auto;
  max-width: 540px;
  width: 100%;
  flex: none;
  border-radius: 8px;
  border: 1px solid #eee;
  background: #fff;
  padding: 16px;

  @media screen and (min-width: 768px) {
    margin: 0;
    padding: 24px;
  }
`;

export const ProfessionTitle = styled.h5`
  color: #333;
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 16px 0;

  @media screen and (min-width: 768px) {
    margin: 0 0 24px 0;
  }
`;

export const ProfessionDescription = styled.p`
  color: #333;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  display: block;
  margin: 0 0 8px 0;
`;

export const ProfessionFlex = styled.div`
  .dropdown-content {
    width: 100%;
  }

  @media screen and (min-width: 768px) {
    display: flex;
    gap: 16px;
  }
`;

export const EducationDescription = styled.p`
  color: #333;
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 4px 0;
`;

export const EducationYear = styled.p`
  color: #666;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
`;

export const EducationFileUploader = styled.div`
  width: 100%;
  flex: none;
  .b2c-card {
    width: 100%;
  }
  .card-content {
    padding: 0;
  }

  .upload-file__types {
    margin: 0 0 4px 0;
  }

  .upload-file__types_below {
    margin: 16px 0 0 0;
  }

  @media screen and (min-width: 768px) {
    .upload-file__content {
      display: flex;
      gap: 16px;
    }
    .upload-file__content > div > .b2c-label {
      min-height: 40px;
    }
    .upload-file__content > a,
    .upload-file__content > div {
      max-width: ${({ isBig }) => (isBig ? "370px" : "356px")};
      width: 100%;
    }
    .upload-file__content .b2c-label {
      // padding: 13px 15px;
    }
    .upload-file__upload-button {
      margin-top: 0;
      max-width: 125px;
      height: 40px;
    }
    a {
      width: 100%;
    }
    .b2c-label {
      padding: 10px 15px;
    }
  }
`;
