import React, { useContext } from "react";
import { ScreenClassContext } from "react-grid-system";

import ThemeContext from "../../../../contexts/theme";
import Layout from "../../../../components/Layout";
import { Text } from "../../../../components/Core";
import { TimelineWrapper, Timeline, TimelineRow, TimelineLeft, TimelineContent } from "./styled";

const TimelineSection = ({ data }) => {
  const screen = useContext(ScreenClassContext);
  const theme = useContext(ThemeContext);
  const isSm = ["xs", "sm"].includes(screen);

  return (
    <TimelineWrapper>
      <Layout.Content size="xl" tag="div">
        <Timeline>
          {data.map(({ id, date, text }) => (
            <TimelineRow key={id}>
              <TimelineLeft>
                <Text fontSize={isSm ? 28 : 32} fontWeight={600} lineHeight={1}>
                  {date}
                </Text>
              </TimelineLeft>
              <TimelineContent bulletTheme={theme.primary300}>
                <Text fontSize={isSm ? 14 : 20}>{text}</Text>
              </TimelineContent>
            </TimelineRow>
          ))}
        </Timeline>
      </Layout.Content>
    </TimelineWrapper>
  );
};

export default TimelineSection;
