import styled from "styled-components";

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  &:not(:first-of-type) {
    margin-top: 25px;
    @media screen and (max-width: 767px) {
      margin-top: 20px;
    }
  }
  &:not(:last-of-type) {
    margin-bottom: 25px;
    @media screen and (max-width: 767px) {
      margin-bottom: 20px;
    }
  }
`;
export const Expanding = styled.div`
  overflow: hidden;
  margin: ${({ $mobileMargin }) => $mobileMargin || "-20px"};
  height: ${({ $height, $narrowCarousel }) => ($height ? `${$height + ($narrowCarousel ? 20 : 40)}px` : "auto")};
  transition: height 0.4s ease;
`;
export const HeaderRow = styled.div`
  display: flex;
  width: ${({ width }) => width};
  justify-content: space-between;
  margin-bottom: 18px;
  align-items: center;

  @media screen and (max-width: 767px) {
    width: 100%;
    margin-bottom: 0;
  }
`;
export const FooterRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  @media screen and (max-width: 576px) {
    > .b2c-button {
      width: 100%;
    }
  }
`;

export const CarouselWrapper = styled.div`
  ${({ slidePadding }) => `

  .slick-slide {
    padding: ${slidePadding || "20px 10px"};

    @media screen and (max-width: 767px) {
      padding: ${slidePadding || "5px 15px"};
    }
  }

`}
`;
