import React from "react";

import useStore from "b2c/contexts/store";
import { useTheme } from "b2c/contexts/theme";

import { Badge, Button } from "b2c/components/Core";
import { useNotificationsCenterNav } from "b2c/components/NotificationsCenter";

import NotificationsIcon from "images/b2c/icons/notifications.svg";
import cn from "classnames";

const NotificationsButton = () => {
  const {
    Notifications: { counter }
  } = useStore("User");
  const { open } = useNotificationsCenterNav();
  const { global_brand } = useStore("initialState");
  const globalBrandName = global_brand.name.toLowerCase();

  const Theme = useTheme();

  const handleClick = () => open();

  return (
    <Button.Iconed
      className={cn("nav-icon", `nav-icon_${globalBrandName}`)}
      onClick={handleClick}
      theme="transparent"
      tabIndex={-1}
      aria-label="Notifications"
    >
      <NotificationsIcon width={32} fill={Theme.black350} />
      {!isNaN(counter) && Boolean(Number(counter)) && (
        <Badge top={0} right={0} style={{ border: "2px solid #FFFFFF" }}>
          {counter}
        </Badge>
      )}
    </Button.Iconed>
  );
};

export default NotificationsButton;
