import styled from "styled-components";
import { theme } from "../../../../contexts/theme";

export const CountryContainer = styled.div`
  height: 48px;
  width: 320px;
  margin-bottom: 20px;
  position: relative;

  .select__indicator-separator,
  .select__indicator {
    display: none;
  }

  .select__control {
    border-color: #e8eaef !important;
  }

  .select__control--is-focused {
    outline: ${theme.primary300} 1px solid !important;
  }

  .select__input {
    opacity: 1 !important;
  }

  @media screen and (min-width: 768px) {
    width: 480px;
  }
`;

export const Content = styled.div`
  border-radius: 8px;
  border: 1px solid #eee;
  background: #fff;
  margin-bottom: 24px;
  padding: 24px;
`;

export const Title = styled.h2`
  color: #333;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
`;

export const Description = styled.p`
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 160%;
  display: block;
  margin-bottom: 24px;
`;

export const Heading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const EditButton = styled.button`
  background: none;
  border: none;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 4px;
  color: #333;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const SelectedList = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
`;

export const SelectedItem = styled.button`
  border-radius: 8px;
  border: 1px solid #408bfc;
  background: #f3f8ff;
  padding: 7px 12px;
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;

  &:hover {
    border: 1px solid #ff2727;
    background: #fdf4f4;

    svg {
      fill: #ff2727;
    }
  }

  svg {
    width: 24px;
    height: 24px;
    fill: #408bfc;
  }
`;

export const SelectedDefaultItem = styled.span`
  border-radius: 8px;
  border: 1px solid #c5c5c5;
  background: #fff;
  padding: 7px 12px;
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
`;

export const EmptyState = styled.div`
  border-radius: 8px;
  border: 1px solid #e1e1e1;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 23px 12px;

  p {
    color: #666;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;

export const EmptyStateIcon = styled.span`
  border-radius: 4px;
  border: 1px solid #f5f5f5;
  background: #f5f5f5;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: #8f8f8f;
    width: 20px;
    height: 20px;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 24px;
`;

export const Save = styled.button`
  background: ${theme.primary};
  border-radius: 8px;
  display: block;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  padding: 8px 24px;
  cursor: pointer;
`;

export const Cancel = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 11px 12px;
  border-radius: 8px;
  background: #eee;
  color: #333;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  cursor: pointer;
  width: 100px;
`;
