import styled from "styled-components";

export const Container = styled.div`
  background-image: url(${({ backgroundMobile }) => backgroundMobile});
  height: 624px;
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (min-width: 1008px) {
    background-image: url(${({ backgroundDesktop }) => backgroundDesktop});
    display: flex;
  }
`;

export const Content = styled.div`
  padding: 100px 16px 32px 16px;
  margin: 0 auto;
  max-width: 1000px;

  @media screen and (min-width: 1008px) {
    margin-top: auto;
    padding-top: 0;
    display: flex;
  }

  @media screen and (min-width: 1048px) {
    margin-top: auto;
    padding: 0 0 64px 0;
  }
`;

export const AsideLeft = styled.div`
  max-width: 720px;

  @media screen and (min-width: 1008px) {
    margin-top: auto;
  }
`;

export const AsideRight = styled.div`
  @media screen and (min-width: 1008px) {
    margin-top: auto;
  }
`;

export const Title = styled.h2`
  color: #fff;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  margin: 0 0 16px 0;

  @media screen and (min-width: 1008px) {
    font-size: 48px;
  }
`;

export const Description = styled.p`
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  display: block;
  margin: 0 0 8px 0;

  @media screen and (min-width: 1008px) {
    font-size: 20px;
  }
`;

export const Actions = styled.div`
  width: calc(100% - 26px);
  margin: 40px 0 0 0;

  @media screen and (min-width: 1008px) {
    display: flex;
    gap: 24px;
    align-items: center;
    margin: 40px 0 0 0;
  }
`;

export const ContactUs = styled.button`
  width: 300px;
  border-radius: 5px;
  background: #2496f5;
  padding: 12px;
  text-align: center;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.2px;
  margin: 0 0 24px;
  display: block;
  flex: none;
  outline: none;
  cursor: pointer;

  @media screen and (min-width: 1008px) {
    margin: 0;
    width: 140px;

    &:hover {
      opacity: 0.8;
    }
  }
`;

export const LearnMore = styled.button`
  width: 300px;
  border-radius: 5px;
  background: #18181b;
  padding: 12px;
  text-align: center;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.2px;
  margin: 0 0 24px;
  display: block;
  outline: none;
  cursor: pointer;

  @media screen and (min-width: 1008px) {
    margin: 0;
    width: 300px;
    flex: none;

    &:hover {
      opacity: 0.8;
    }
  }
`;

export const VideoLink = styled.a`
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  gap: 8px;
  cursor: pointer;
  text-decoration: none;
`;

export const VideoPlay = styled.span`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #2496f5;

  svg {
    width: 12px;
    height: 12px;
    fill: #fff;
  }
`;
