import styled from "styled-components";

export const TestAndSurveyHeader = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  background-color: #fff;
  border-bottom: 1px solid #e1e1e1;
`;

export const TestAndSurveyHeaderAction = styled.div`
  position: absolute;
  top: 0;
  right: 20px;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const TestAndSurveySubHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  height: 60px;
  padding: 0 20px;
`;
