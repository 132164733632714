import React from "react";

import { useTranslation } from "b2c/hooks/useTranslation";
import Layout from "../../components/Layout";
import Spinner from "../../components/Core/Spinner";
import { loadingStyles } from "./styled";

const LoadingScreen = () => {
  const { t } = useTranslation();

  return (
    <Layout.Content size="xl" style={loadingStyles}>
      <Spinner size="75px" margin="auto" message={t("global.loading")} />
    </Layout.Content>
  );
};

export default LoadingScreen;
