import React, { useContext } from "react";
import { observer } from "mobx-react";

import { Link } from "react-router-dom";
import styled from "styled-components";
import ProfileAvatar from "components/ProfileAvatar";
import useStore from "../../contexts/store";
import ThemeContext from "../../contexts/theme";

const AvatarProgress = observer(({ width = 80, to = "/profile", isHideMobile, ...props }) => {
  const {
    user: { first_name = "", full_name = "", avatar, profile_completeness_percentage = 0 } = {},
    profileStrength: { prompt }
  } = useStore("User");

  const Theme = useContext(ThemeContext);
  const dash = Math.round(width * 3.2);
  const offset = dash * (1 - profile_completeness_percentage / 100);
  const { base, filled } = (() => {
    switch (prompt) {
      default:
      case "Beginner":
        return {
          base: "accent200",
          filled: "accent300"
        };
      case "Intermediate":
        return {
          base: "primary100",
          filled: "primary300"
        };
      case "Advanced":
      case "All star":
        return {
          base: "success100",
          filled: "success200"
        };
    }
  })();

  return (
    <StyledAvatarWrapper {...props}>
      <Link
        to={to}
        className={`avatar-progress ${isHideMobile ? "avatar-progress_hide-mobile" : ""}`}
        title="Go To Profile"
      >
        <Svg
          styled={{
            width,
            height: width
          }}
          className="progress"
        >
          <StyledCircle
            styled={{ transform: "rotate(90deg)" }}
            stroke={Theme[base]}
            strokeWidth={Math.round(width / 20)}
            fill="transparent"
            strokeDasharray={dash}
            r={width / 2}
            cx={width / 2}
            cy={width / 2}
          />
          <StyledCircle
            styled={{ transform: "rotate(90deg)" }}
            stroke={Theme[filled]}
            strokeWidth={Math.round(width / 20)}
            fill="transparent"
            strokeDasharray={dash}
            strokeDashoffset={offset}
            r={width / 2}
            cx={width / 2}
            cy={width / 2}
          />
        </Svg>
        <StyledAvatar
          styled={{
            top: 0,
            left: 0,
            width,
            height: width,
            padding: Math.round(width / 12)
          }}
          name={full_name || first_name}
          avatar={avatar}
        />
      </Link>
    </StyledAvatarWrapper>
  );
});

const StyledCircle = styled.circle`
  transform-origin: center;
  ${item => item.styled}
`;
const StyledAvatar = styled(ProfileAvatar)(item => item.styled);
const Svg = styled.svg(item => item.styled);
const StyledAvatarWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export default AvatarProgress;
