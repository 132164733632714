import React, { useState, useLayoutEffect } from "react";

import TableGenerator from "b2c/components/TableGenerator";
import SortingSelect from "b2c/components/SortingSelect";
import { sortingOptions } from "b2c/constants/points";
import { StyledControls } from "./styled";

const TransactionsTable = ({ loading, type, transactions, getTransactions }) => {
  const [sortBy, setSortBy] = useState("newest");

  useLayoutEffect(() => {
    getTransactions(sortBy);
  }, [type]);

  const handleSorting = value => {
    setSortBy(value);
    getTransactions(value);
  };

  return (
    <>
      <StyledControls>
        <SortingSelect currentValue={sortBy} options={sortingOptions} onSelect={handleSorting} />
      </StyledControls>
      <TableGenerator
        loading={loading}
        tab={type}
        items={
          transactions && transactions.length > 0
            ? transactions.map(item => ({ ...item, type }))
            : [{ description: "No transactions" }]
        }
      />
    </>
  );
};

export default TransactionsTable;
