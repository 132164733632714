/* eslint-disable */
import { createContext } from "react";

// remove
const branding = {
  faq: [
    {
      id: 1,
      title: "About Fuzu",
      sections: [
        {
          id: 1,
          title: "What are the benefits of Fuzu?",
          text:
            "Fuzu is your one stop shop for planning and reaching your career related goals. The Fuzu service is designed to help career builders to accelerate their growth through personalised learning, career guidance and accurate job recommendations."
        },
        {
          id: 2,
          title: "Is Fuzu a paid-for service?",
          text:
            "Using Fuzu is free of charge, outside of the data bundles needed to access the service. You can create your online CV, apply for jobs, read articles and access learning content on Fuzu absolutely free."
        },
        {
          id: 3,
          title: "What do I need to do to start using Fuzu?",
          text:
            "There are no special requirements for joining Fuzu. Anyone above 18 years who wants to apply for jobs, learn new skills, stay up-to-date with industry news or who wants to plan ahead their career may register on Fuzu. Registration is fast and easy. However, to find relevant jobs and courses or to increase chances of finding employment, we encourage you to complete your Fuzu profile."
        },
        {
          id: 4,
          title: "Can I use Fuzu even if I am a student and don’t have work experience?",
          text:
            'Yes, you can. We have internship positions on the platform as well. You should also check the <a class="link-faq-list" target="_blank" rel="noopener noreferrer" href="https://www.fuzu.com/learn">learning library</a> that is designed to help you out in finding your first job opportunity. You can also read <a class="link-faq-list" target="_blank" rel="noopener noreferrer" href="https://www.fuzu.com/forum">articles</a> on your industry to prepare you for the career ahead.'  
        }
      ]
    },
    {
      id: 2,
      title: "Jobs on Fuzu",
      sections: [
        {
          id: 1,
          title: "What kind of jobs are there on Fuzu",
          text:
            "Fuzu is open for jobs from all industries and seniority levels. We recommend that you check for new job content regularly. We have Fuzu Exclusive jobs that have been posted by our employer partners  as well as jobs we have aggregated from other sources. It is important to note that the aggregated jobs take you to an external website to post the application or provide you with instructions for sending in your application package. We make sure that the jobs on the platform are free from scams. Under no circumstances should you ever pay for applying for a job!"
        },
        {
          id: 2,
          title: "Am I allowed to apply for jobs in a country where I am not living at the moment?",
          text:
            "Yes, you can. However, it is good to keep in mind that employers have the ultimate say on whether they are willing to deal with work permits and relocation costs. So make sure you check the requirements before submitting an application."
        },
        {
          id: 3,
          title: "Are the jobs on Fuzu different from other job sites?",
          text:
            "Companies who post jobs on Fuzu go through a validation process by the Fuzu team. They may also post the same jobs across multiple job sites. However, many employers use Fuzu as their primary recruitment channel. Keep your Fuzu profile updated as employers are searching the talent database and the stronger your profile, the better chances you have for landing your next job opportunity."
        },
        {
          id: 4,
          title: "Are jobs on Fuzu genuine?",
          text:
            "All jobs on Fuzu are verified by the Fuzu team. You may not necessarily find the jobs on the employers’ website as some employers prefer applications to come from external sources like Fuzu. If you do encounter anything suspicious, please contact us."
        },
        {
          id: 5,
          title: "Why are you duplicating job postings?",
          text:
            "For some of the jobs on Fuzu, the employer is continuously looking for fresh talent and therefore there are multiple openings for the same position. In some cases, the employer is hiring for the same role in multiple  locations."
        },
        {
          id: 6,
          title: "Why don’t I get job recommendations from Fuzu?",
          text:
            'Make sure to check your spam folder and to mark Fuzu emails as safe. You can also check the email settings on the platform under <a class="link-faq-list" target="_blank" rel="noopener noreferrer" href="https://www.fuzu.com/job/settings">Job Search Settings.</a>'  
        }
      ]
    },
    {
      id: 3,
      title: "Password and account",
      sections: [
        {
          id: 1,
          title: "How do I reset my password?",
          text:
            "Open your browser and go to www.fuzu.com/login. Click 'Forgot your password?' and follow the instructions to reset it. The system will send you a recovery email with a link to reset your password."
        },
        {
          id: 2,
          title: "I am trying to log in but it says my email address is already taken?",
          text:
            "Most likely you are on the registration page, please go to the login page and click on this link www.fuzu.com/login.Type in your username and password. If you have forgotten your password, you can reset it by clicking on 'Forgot your password?'."
        },
        {
          id: 3,
          title: "I don’t need Fuzu anymore. How do I delete my account?",
          text:
            "We will be sad to see you leave and recommend that you keep your account, as we are constantly updating the content on Fuzu to better serve our community’s needs. If you nevertheless wish to delete your Fuzu account, click on your image on the home page (header bar-top right) and select Account Settings. You will find the Delete account option from there."
        }
      ]
    },
    {
      id: 4,
      title: "Managing and improving my Profile (CV)",
      sections: [
        {
          id: 1,
          title: "How do I get my CV to full completion?",
          text:
            "The stronger your Fuzu profile is, the better your chances are for finding interesting jobs, courses and articles on the platform. To push your Fuzu profile completion rate up, you need to fill in all the sections."
        },
        {
          id: 2,
          title: "Why do I need to add skills and how do I do it?",
          text:
            "Your skills are very important as they show the employer what you can do. You can add skills to your profile under the Skill set section or by connecting individual skills to each of your work experience items on the Fuzu profile page. In total you can add a maximum of  50 skills to your profile."
        },
        {
          id: 3,
          title: "Why do I need to create a profile and why does it take time?",
          text:
            "The profile creation is a process you need to complete only once. We also provide you with a tool to automatically analyse and build your profile based on an existing CV. A great Fuzu profile is needed to receive great recommendations and to get the best out of the Fuzu experience. You can also be headhunted by employers looking for talent on Fuzu."
        },
        {
          id: 4,
          title: "How do I Write a CV?",
          text:
            'We have great insights on how to build a great CV. Kindly check out the articles we have <a class="link-faq-list" target="_blank" rel="noopener noreferrer" href="https://www.fuzu.com/forum/article/20146">listed under the Forum section</a> as well as the free of charge courses we host on the platform. You also have the option of purchasing the <a class="link-faq-list" target="_blank" rel="noopener noreferrer" href="https://www.fuzu.com/cv-creator">CV Creator,</a> which provides you with a great looking CV file generated from your Fuzu profile.'  
        }
      ]
    },
    {
      id: 5,
      title: "Applying for jobs",
      sections: [
        {
          id: 1,
          title: "How is the application process like on Fuzu?",
          text:
            "We have two kinds of job postings on the platform, that's Fuzu exclusive and external roles. For Fuzu exclusive roles one would have to complete all the application steps we've provided in order to submit the application successfully. As for external roles, step 1 of the application process on the platform is meant to give you a chance to download and review your Fuzu CV or original CV(self-prepared) before submitting it to the employer. Once you have the CV of your choice kindly scroll down to step 2 where we've provided either the employer's email address, physical address, or a link to their website for you to submit the application successfully."
        },
        {
          id: 2,
          title: "How can I access my incomplete job application?",
          text:
            "You can save and finish your application any time before the application end date. To access your incomplete applications, log in to your profile, go to the Jobs page and click on the Applications tab or check out the My Updates section on My Fuzu page. Just remember that the employers may close the application process early and that early applicants always have the best chances of progressing to the next phase."
        },
        {
          id: 3,
          title: "How do I get notified that my application has been submitted?",
          text:
            "For jobs that are submitted on Fuzu, your application is submitted as you click the Submit button. You will also get a confirmation email. For jobs that are applied outside of the Fuzu platform, it is your responsibility to make sure that you complete the application either on an external website or by sending the application via email. Please read the instructions carefully!"
        },
        {
          id: 4,
          title: "What next after I get shortlisted for a position?",
          text:
            "The employer will get back to you on the next steps. Sometimes the recruitment process takes a while (even up to 2 months) and this communication might not be immediate. Being shortlisted is also not yet a confirmation for being invited for interviews. Sometimes employers select several candidates to the shortlist and then invite only a smaller number of candidates for the interviews."
        },
        {
          id: 5,
          title: "Why do I have to answer a long personality test when I apply for a job?",
          text:
            "Employers want to find people that match their corporate culture and therefore ask candidates to complete personality assessments. When it comes to personality, there are no right or wrong answers. After all, we are who we are! The Fuzu personality test is based on the Big 5 framework, which is a proven and popular psychometric framework for assessing people's character in a professional environment."
        },
        {
          id: 6,
          title: "Can I get my  test results for the Fuzu aptitude tests?",
          text:
            "No, however, employers use the questions that candidates failed on the aptitude test, to better understand your fit for their role.\n"
        },
        {
          id: 7,
          title: "How do I cancel my application and reapply again?",
          text:
            "If you realise that you missed a key thing in your application and you already submitted it, you can always cancel your application and apply afresh. To do so, access the Jobs page and then enter the Applications tab. Find your application and click Cancel. Before canceling make sure that the job is still open for applications!"
        },
        {
          id: 8,
          title: "How do I write a Cover letter?",
          text:
            'For some great insights on <a class="link-faq-list" target="_blank" rel="noopener noreferrer" href="https://www.fuzu.com/forum/article/5593">how to write cover letters,</a> please access the Forum page and search for articles discussing how to write a perfect cover letter.'  
        }
      ]
    },
    {
      id: 6,
      title: "Premium products and payments",
      sections: [
        {
          id: 1,
          title: "What is Instant Feedback?",
          text:
            "Instant Feedback is a premium feature that allows you to compare yourself to other top candidates by experience, skills, personality and education, providing you with valuable advice on how to improve your chances in the future. You can purchase Instant Feedback for a specific job or for an unlimited number of jobs for 30 days."
        },
        {
          id: 2,
          title: "Where do I get Instant Feedback?",
          text:
            "To purchase Instant Feedback, go to a job description and click “Get Instant Feedback”. Then just follow the steps to purchase the product option you prefer. Once you have activated Instant Feedback, go to the job description and click “View Instant Feedback”."
        },
        {
          id: 3,
          title: "Why am I not getting Feedback despite many applications sent?",
          text:
            'We understand the frustration of not getting feedback for your applications. Sometimes the recruitment process takes a long time and due to the high number of applicants, the employer is not able to provide personalised feedback to each and every candidate. It is also important to note that for applications that you submitted externally (by completing them on an external website or by sending the application via email or traditional mail), the employer will contact you directly, not via the Fuzu platform. To improve your chances we recommend that you make sure your profile is up to date. You are also welcome to book a session with one of the <a class="link-faq-list" target="_blank" rel="noopener noreferrer" href="https://www.fuzu.com/premium/career-coaching">Fuzu Coaches</a> to understand better what prevents you from finding the job of your dreams.'  
        },
        {
          id: 4,
          title: "I purchased Instant feedback but I have not gotten feedback for the role I applied to.",
          text:
            "Instant feedback gives you feedback on how you match a particular role, recommendations on enhancing your application and access to top candidates' profiles versus you. However, it does not give you specific feedback regarding your application from the employer, usually you get contacted if you made it to the shortlist which takes about two weeks after the job is closed."
        },
        {
          id: 5,
          title: "Where can I find the Fuzu CV Creator I purchased?",
          text:
            "Login to your account and go to your Fuzu profile by clicking your photo and you will find the button for downloading your CV."
        },
        {
          id: 6,
          title: "What if my Fuzu CV Creator is missing details?",
          text:
            'Your Fuzu Cv Creator is created from the information available on your Fuzu profile. First update your profile, then refresh your  CV Creator page to download the updated document. If the CV still fails to update, please <a class="link-faq-list" target="_blank" rel="noopener noreferrer" href="https://www.fuzu.com/contact">contact us.</a>'  
        },
        {
          id: 7,
          title: "What are Fuzu Points?",
          text:
            "Fuzu Points is Fuzu’s own reward program. You get Fuzu Points for being active on the platform and for being committed to constantly growing and pushing yourself for success. You can use them to get discounts from Fuzu Premium products. You can access your Fuzu Points page by clicking the coin icon on the header (on laptop/desktop) or your profile picture (on mobile)."
        }
      ]
    }
  ],
  terms: [
    {
      title: "What is FUZU?",
      text:
        "<p>FUZU is your online career coach and employment service with a social twist. With FUZU, users can dream about the future, plan their careers ahead, learn new skills and competencies and to find jobs that match with their unique talent and profile. For employers, FUZU allows them to attract talent, find candidates that match with their requirements, conduct headhunting and continuously keep their staff skills up to date with the job market demands.</p>"
    },
    {
      title: "What are these Terms about?",
      text: `<p>These FUZU Service Terms together with the Privacy Policy and information that may be provided within the Service (collectively "Terms") govern Your (later on referred to as "You", "Your" or "End User") use of the service, site, content and software (collectively the "Service"). By registering for or using the Service or any portion of it You accept the Terms.
            <br/><br/>
            The Terms constitute an agreement between You and FUZU Ltd (corporate ID 2546225-2) with its address at Lapinlahdenkatu 16, 00180 Helsinki, Finland including its affiliates and suppliers (collectively "FUZU"), defining Your ("End User") and FUZU’s rights and responsibilities with respect to the Service.
            <br/><br/>
            You may use the Service only in compliance with these Terms and all applicable local, state, national, and international laws, rules and regulations.</p>`
    },
    {
      title: "Who can use the Service?",
      text:
        "You can register for the Service as an individual user (Individual) under the Job Seeker-section of the Service or as a company, organization, government, or other legal entity user (collectively Organization) under the Employer section of the Service.<br/>To use the Service, You must be at least eighteen (18) years of age. If You are under eighteen (18) years of age, or at least eighteen (18) years of age but a minor where You live, You must have Your parent, legal guardian or legally competent representative of the Organization you represent accept Your registration on Your behalf and approve Your use of the Service. Anyone completing the registration must be legally competent.<br/>If you are accepting these Terms and using the Service on behalf of an Organization, you represent and warrant that you are authorized to do so."
    },
    {
      title: "Registering or Terminating the use of the Service",
      text: `To use a Service You may need to register and create a FUZU account with username and a password. You may need to provide us with certain personal information or information about the Organization you represent. FUZU may verify Your email address before account can be used or FUZU may use SMS based verification service to confirm Your identity. In case of Organizations, Fuzu may additionally validate the company identity (Company ID, Tax ID and alike) with the help of local authorities.
            <br/>
            The creation of a FUZU Account will require data transmission. Data transmission costs may apply and are borne by the End User.
            <br/>
            You agree to provide truthful and complete information when You register for the Service and to keep that information updated to Your best ability. Truthful information helps You to take full benefit of the Service. You must take due care to protect Your username and password against misuse by others and promptly notify FUZU about any misuse. You, and Your parent or legal guardian, if You are a minor, are personally responsible for any use of the Service.
            <br/>
            You may terminate Your registration if You no longer wish to use the Service by contacting Fuzu user support or by sending an email to  support@fuzu.com Termination means that You will no longer have access to the Service. FUZU may terminate Your registration or restrict Your access to certain parts of the Service if FUZU reasonably believes that You have breached the Trms or with prior notice if You have not used the service over the past six (6) months.
            <br/>
            Except as set forth in the Privacy Policy, FUZU is not responsible for any removal or loss of the information or content You have submitted to the Service. When information or content is removed from the Service by either You or FUZU, traces or copies may still remain elsewhere.`
    },
    {
      title: "Who owns and what?",
      text: `FUZU grants You a non-exclusive, non-transferable license, revocable at any time at FUZU’s sole discretion, to access and use the Service strictly in accordance with the Terms. Use of the Service does not grant You any intellectual property rights in or to any information or content in the Service.
            <br/>
            As part of the Service, FUZU may provide You with content developed by FUZU or third parties that is owned by FUZU and accessed within the Service ("FUZU Content") or provide access to third party content ("External Content") provided by Organizations using the Service or third party platforms such as YouTube. Content covering both FUZU Content and External Content is referred to as "Content". FUZU grants You a non-exclusive and perpetual license to use FUZU Content for the purpose it is intended, unless otherwise defined in the applicable Terms or the purchase order. Some FUZU Content may have geographical restrictions. You are bound by any restrictions applicable to specific FUZU Content You obtain through the Service. Any license acquired to third-party FUZU Content is a binding agreement between You and the third-party FUZU Content provider. You have only the rights to the FUZU Content which are expressly granted here. With regards to External Content FUZU claims no ownership of content that is accessed through FUZU, but operated from a third party platform.
            <br/>
            As part of the Service, FUZU may provide You with a mobile application or client developed by FUZU ("App"). Your use of App may be subject to separate terms and conditions that You must accept before using the App. If there are no separate terms and conditions applicable to such App, the following terms apply: FUZU grants to You a limited, non-exclusive, non-transferable right to install and use the App on Your computer and/or mobile device. You may not distribute, transfer the right to use, modify, translate, reproduce, resell, sublicense, rent, lease, reverse engineer, or otherwise attempt to discover the source code of or make derivative works of the App. For open source licensed software, applicable open source license terms apply.
            <br/>
            You, as Individual or representative of an Organization, may be able to submit information or other types of digital content, such as photos, logos, other forms of trademarks, video, audio or text ("Data") relevant to You or Your Organization to the Service. FUZU does not claim ownership in Your Data, unless agreed upon through separate agreement. Your submission of Data does not transfer ownership of rights of the Data to FUZU. FUZU is only transmitting the Data and is not responsible for editorial control over it. By submitting Data to the Service You grant FUZU a world-wide, non-exclusive, sub-licensable, assignable, fully paid-up, royalty-free, perpetual and irrevocable license to use, copy, publicly perform, display, distribute in any media and modify the Data to incorporate the Data into other works, and to grant similar sublicenses to the extent necessary for FUZU to provide the Service. You may be able to adjust this grant in the privacy and other settings of the Service.
            <br/>
            You are solely responsible for taking backup copies of the data You store on the Service, including Content You upload. If the Service is discontinued or canceled, FUZU may permanently delete Your data. FUZU has no obligation to return data to You after the Service is discontinued or canceled.`
    },
    {
      title: "What are the do's and don'ts?",
      text: `<p>You agree to:</p>
            <ul>
            <li>Comply with applicable laws, the Terms and good manners;</li>
            <li>As Individual, use the Service only for Your personal, career development related purposes;</li>
            <li>As Organization, use the Service to identify, develop and foster professional talent or to promote your Organization;</li>
            <li>As Individual, keep only one user account, and keep its information up to date and truthful;</li>
            <li>As Organization, ensure that the account information is up to date and truthful;</li>
            <li>As Individual, not send job applications or conduct tests on behalf of somebody else or otherwise use somebody else’s user account or identity on FUZU Service;</li>
            <li>As Organization, not publish campaigns, news updates or other content that is meant to mislead or scam Individual users or other Organizations;</li>
            <li>Not submit unlawful, offensive, inaccurate, misleading, abusive, pornographic, harassing, libelous or otherwise inappropriate Data;</li>
            <li>Obtain any consents, permission or licenses that may be legally required for You to submit any Data;</li>
            <li>Respect the privacy of others;</li>
            <li>Not distribute or post spam, unreasonably large files, chain letters, pyramid schemes, viruses; or</li>
            <li>Not use any other technologies or initiate other activities that may harm the Service, or the interest or property of the Service users;</li>
            <li>Not to use any automated systems or means to access, acquire, copy or monitor any part of the service.</li>
            </ul>
            <br/>
            <p><b>FUZU may, but has no obligation to:</b></p>
            <ul>
            <li>Monitor or moderate any Content or Data;</li>
            <li>Remove any Data from the Service;</li>
            <li>Restrict access to any part of the Service at any time in its sole discretion; and</li>
            <li>Block Users from accessing the Service in case violation of these Terms.</li>
            </ul>
        `
    },
    {
      title: "What about accessing and using the Content?",
      text: `<p>Before downloading or accessing any Content, please check whether the content which You wish to access is restricted by age or marked as potentially ‘offensive’ or ‘explicit’. FUZU shall not be responsible for any claims or offense caused or suffered by You accessing such Content.</p>
            <br/>
            <p><b>You agree:</b></p>
            <ul>
            <li>To use the Content only for Your personal, non-commercial purposes;</li>
            <li>To use the Content in accordance with the restrictions set out in the applicable laws, additional terms, guidelines and policies that apply to the specific content;</li>
            <li>Not to make copies, give, sell, resell, loan, rent, offer, broadcast, send, distribute, transfer, communicate to the public, reproduce, modify, display, perform, commercially exploit or make the Content available unless otherwise authorized in the applicable Terms and to advise FUZU promptly of any such unauthorized use;</li>
            <li>Not to remove, circumvent, reverse engineer, decrypt, or otherwise alter or interfere with any applicable usage rules or attempt to circumvent digital rights management or copy protection features associated with the Content or any other technologies used to control the access to or use of the Content or its identifying information;</li>
            <li>Not to use any automated systems or means, except for those provided by us, for the selection or downloading of the Content;</li>
            <li>Not to give out Your password or otherwise allow other people to access the Content. The restrictions on copying that apply to applicable media also apply to the Content accessed as part of this Service.</li>
            </ul>
            <br/>
            <p>The Content is owned and/or controlled by FUZU and/or its respective licensors and is protected by intellectual property laws. The third party Content provider is solely responsible for any Content it provides, any warranties to the extent that such warranties have not been disclaimed and for any claims You may have relating to that Content or Your use of that Content. However, FUZU may enforce the third party Content license terms against You as a third party beneficiary of those terms. The third party Content providers are third-party beneficiaries under these Terms and may enforce the provisions that directly concern the Content in which they have rights. FUZU may be acting as an agent for third party Content provider in providing the Content to You. FUZU is not a party to the transaction between You and the third party Content provider for such Content.</p>
        `
    },
    {
      title: "Allegations of Copyright Infringement",
      text: `<p>You may notify FUZU of copyright infringement on the Service by providing notice (a) by email with "Copyright Notification" in the subject line to info@fuzu.com, (b) by a document titled "Copyright Notification" mailed to FUZU Oy, Lapinlahdenkatu 16, 00180 Helsinki, Finland or (c) via the online form, if available. Your notice must:</p>
            <br/>
            <ul>
            <li>identify the original copyrighted work You claim has been infringed;</li>
            <li>identify the content on the Service that You claim is infringing the copyrighted work. Please provide enough detail for FUZU to locate the allegedly infringing content on the Service;</li>
            <li>provide Your contact information, including Your full name, mailing address, telephone number, and email address, if available;</li>
            <li>provide a statement that You have a good faith belief that the use of the content in the manner complained of is not authorized by the copyright owner, its agent, or the law;</li>
            <li>5. provide this statement: "I swear, under penalty of perjury, that the information in this notification and complaint is truthful and that I am the rightful owner of the copyright, or am authorized to act on behalf of the copyright owner of an exclusive right that is infringed."; and</li>
            <li>provide Your signature, as applicable.</li>
            </ul>`
    },
    {
      title: "How does Fuzu inform End-Users about the changes?",
      text:
        "FUZU may post notices within the Service. FUZU may also send You notices about new or changing Services to the email address or telephone number You have provided to us. You are deemed to have received such notices at the latest within seven (7) days from FUZU sending or posting those. Your continued use of the Services constitutes Your receipt of all notices regardless of delivery method."
    },
    {
      title: "What fees am I subject to?",
      text: `Your use of the Service may be or may become subject to charges. These charges may cover parts or the entire Service.
            <br/>
            Any fees charged by FUZU will be announced separately in connection with the Service.
            <br/>
            Use of the Service involves transmission of data through Your service provider’s network. Prices listed within the Service DO NOT include possible data transmission, text message, voice or other service provision charges by Your network service provider.
            <br/>
            FUZU assumes no responsibility for the payment of any charges of Your service providers.`
    },
    {
      title: "What are the payment terms?",
      text: `"Order" shall mean the selection of payable Content or Service and/or subscription to Content or Service offered by FUZU and available in the Service and submission of payment method, as well as submitting the order by selecting the "buy", "ok", "I accept", "confirm", "pay" or other similar confirmation of acceptance in the order flow or providing other indication of acceptance terms that are presented to You in the order flow.
            <br/>
            To place an Order within the Service, You must have reached the age of legal competence according to the applicable legislation. If You have not reached the age of legal competence, You may place Orders only with the prior consent of Your parent, legal guardian or the Organization you represent.
            <br/>
            You agree that all Orders shall be legally valid and binding. All Orders are subject to acceptance by FUZU.
            <br/>
            You may pay by credit or debit card, network service provider billing, mobile money, or other payment methods if available.
            <br/>
            Your credit or debit card must have a billing address in the country where the Content or Service is offered by the Service. FUZU will charge Your credit card or debit Your bank account within a reasonable time after You have made Your Order. All credit card payments are subject to validation checks and authorisation by the card issuer.
            <br/>
            If You choose network service provider billing, charges will appear on Your mobile phone bill or be deducted from Your prepaid account if it is a prepaid account. Some network service providers may subject Your usage of the Service to additional terms and conditions including placing limits on the amount of charges possible with network service provider billing. Charges in excess of network service provider limits or account balance may be rejected.
            <br/>
            You agree to pay the charges related to Your Order, to ensure that the instrument of payment is valid at the time of the Order, that You are the rightful holder of the instrument and that the instrument is used within its credit limits.
            <br/>
            The Content or Service that is delivered is licensed to You. You agree to use such Content or Service solely as permitted in these Terms and in any additional terms that You may be presented in the order flow.
            <br/>
            The Service may offer subscriptions. You authorise the Service to place a periodical charge during the period of the subscription. The Service may also offer a trial period. If Your Order involves a trial period (also known as try-and-buy), You may be charged when the trial period expires, unless You cancel in accordance with the subscription/trial terms.
            <br/>
            The prices in the Service may change from time to time. Prices include applicable taxes in effect at the time of Your transaction, unless otherwise stated, and need to be paid in full to FUZU. Depending on local tax laws and regulations, You may be required to pay additional taxes, such as the withholding tax. There may be instances where You incur additional charges from Your bank or credit card provider based on currency conversion rates used and/or additional fees assessed. FUZU assumes no responsibility for the payment of bank or any other third party service fees, charges or taxes.`
    },
    {
      title: "What about service availability and technical requirements?",
      text: `The availability of Content and the Service may vary and is subject to FUZU’s sole discretion. FUZU expressly disclaims any representation or warranty that any particular Content or Service will be available. The Service may not be available in all countries and may be provided only in selected languages. The Service, operations and some features may also be dependent on the network, compatibility of the devices used and the content formats supported.
            <br/>
            To access the Service, You may need to download a specific piece of App developed by FUZU or by another party.
            <br/>
            FUZU may, in its sole discretion, change, correct or discontinue the Service in whole or in part. The Service may not be available during maintenance breaks and other times. To ensure You have the latest FUZU device software and applications, Your device may automatically check for the availability of App updates from FUZU. If any are detected You will be prompted to approve the installation. You may disable automatic checking for updates through settings in Your device. You may also install App update through available software update channels. If FUZU considers a App update to be important or critical You may not continue using the previous version of the App. FUZU may prevent Your use of the previous version of the App or Service until You install the update.
            <br/>
            FUZU may disable any Content, Service or App contained in Your Service account for any reason and remove any Content or App and/or disable copies of any application on Your device in order to protect the Service, application providers, wireless carriers over whose network You access the service or any other affected or potentially affected parties.
            <br/>
            A particular service may be a pre-release version, for example a beta release, and may not work in the way a final version works. FUZU may significantly change any version of Service or App or decide not to release a final version.`
    },
    {
      title: "Can I cancel my Order?",
      text: `You agree to the electronic delivery of Content or Service being initiated concurrently with the placement of Your Order. You will not be able to cancel Your Order once it has been processed. The nature of the Content is such that it cannot be returned.
            <br/>
            In the event that after Your Order You discover and promptly inform FUZU within 48 hours that (a) the Content or Service You have ordered is faulty; (b) the Content or Service FUZU delivers to You does not match the description of the Content or Service You ordered via the Service; or (c) technical problems delayed or prevented delivery of Your Content or Service or accidental multiple orders caused by such technical problems, Your sole and exclusive remedy with respect to such Content or Service will be either replacement of such Content or Service, or refund of the price paid for such Content or Service, as determined solely by FUZU. Otherwise, no refunds are available. If You encounter any of the above issues, please contact FUZU.`
    },
    {
      title: "What happens with my social and location data?",
      text:
        "You may use features in the Service to share Your location, status, content, Data or information about You as an Individual or representative of an Organization or to interact with other users, sites and services. By using these features You agree that FUZU may use and provide that information to other services and persons with whom You choose to interact or share this information with. Users of these services and persons, such as Your contacts or people or Organizations You Follow, may see or get to know Your location, status and/or personal information. In using these features You agree not to share information, Content or Data or to link to any service or site that: (a) contains content or other material that is illegal or inappropriate; or (b) exploits intellectual property rights without authorisation or encourages users to piracy. Any interaction does not involve FUZU and is solely between You and the other user(s)."
    },
    {
      title: "How to provide feedback to FUZU?",
      text:
        'By submitting any ideas, feedback and/or proposals ("Feedback") to FUZU through the Service or other means, You acknowledge and agree that: (1) FUZU may have similar development ideas to the Feedback; (2) Your Feedback does not contain confidential or proprietary information of You or any third party; (3) FUZU is not under any obligation of confidentiality with respect to the Feedback; (4) FUZU may freely use, distribute, exploit and further develop and modify Feedback for any purpose; and (5) You are not entitled to compensation of any kind from FUZU.'  
    },
    {
      title: "Links to third party sites and content",
      text: `FUZU may include access to sites and services on the Internet or preloaded clients that enable You to interact with sites and services that are owned or operated by third parties and that are not part of the Service. You must review and agree to the terms and conditions of these sites or services before using these sites or services.
            <br/>
            FUZU has no control over the third party content, sites or services and assumes no responsibility for services provided or material created or published on these third-party sites or services. A link to a third-party site does not imply that FUZU endorses the site or the products or services referenced in the site.
            <br/>
            In addition, You and other users may create content and links to content within the Service that has not otherwise been submitted to the Service. FUZU is not responsible for this type of content or links.`
    },
    {
      title: "FUZU and advertisements?",
      text:
        "Service may include advertisements. Advertisements may be targeted to the content or information stored on the Service, queries made through the Service, or other information."
    },
    {
      title: "How does Fuzu take care of Your privacy?",
      text:
        "The Privacy Policy and any additional privacy information made available to You govern the use of data related to You as an Individual user or as a representative of an Organization."
    },
    {
      title: "Indemnification",
      text:
        "You agree to defend and indemnify FUZU from and against all third party claims and all liabilities, assessments, losses, costs or damages resulting from or arising out of (i) Your breach of the Terms, (ii) Your infringement or violation of any intellectual property rights, other rights or privacy of a third party, or (iii) misuse of the Service by a third party where the misuse was made possible by Your failure to take reasonable measures to protect Your username and password against misuse."
    },
    {
      title: "Limitation of liability",
      text: `The Service is provided on "AS IS" and "AS AVAILABLE" basis. FUZU does not warrant that the Service will be uninterrupted or error or virus-free. No warranty of any kind, either express or implied, including but not limited to warranties of title, non-infringement, merchantability, or fitness for a particular purpose, is made in relation to the availability, accuracy, reliability, information or content of the Service. You expressly agree and acknowledge that the use of the Service is at Your sole risk and that You may be exposed to content from various sources.
            <br/>
            Except for liability for death or personal injury caused by its gross negligence or intentional misconduct, FUZU is not liable for any direct damages caused by Your use or inability to use the Service. In no case will FUZU be liable for any indirect, incidental, punitive or consequential damages resulting from Your use or inability to use the Service.`
    },
    {
      title: "How long are these Terms valid?",
      text:
        "The Terms neither exclude nor limit any of Your mandatory rights in Your country of residence that cannot by law be waived. If a provision of the Terms is found to be invalid, the remaining provisions will not be affected and the invalid provision will be replaced with a valid provision that comes closest to the result and purpose of the Terms. In the event one or more provisions of these Terms are not relevant to Your use of the Service, it shall not impact the validity or enforceability of any other provision of the Terms or the Terms as a whole. If there is any conflict between these FUZU Service Terms and the Privacy Policy, the provisions of these FUZU Service Terms prevail. The provisions of the Terms that are intended to survive termination of Your registration remain valid after termination."
    },
    {
      title: "Choice of law for these Terms",
      text: "The Terms are governed by the laws of Finland without regard to its conflicts of law provisions."
    },
    {
      title: "Making changes to the Terms",
      text: `FUZU may modify the Terms at any time without prior notice. If the Terms are changed in a material, adverse way, FUZU will provide a separate notice advising of the change.
            <br/>
            You are responsible for regularly reviewing the Terms. Your continued use of the Service constitutes Your consent to any changes and modification.`
    },
    {
      title: "Intellectual property",
      text:
        "The Service, Content and App are protected under international copyright laws. FUZU claims copyrights in its Service, Content, and App to the maximum extent of the law. Subject to the Terms, FUZU retains all right, title and interest in the Service, its Content, the App and in all other FUZU products, software and other properties provided to You or used by You through the Service."
    },
    {
      title: "Transferring rights and obligations",
      text:
        "FUZU may transfer its rights and obligations under these Terms to its corporate parent, its subsidiaries, or to any company under common control with FUZU. Additionally, FUZU may assign its rights and obligations under these Terms to a third party in connection with a merger, acquisition, sale of assets, by operation of law or otherwise."
    }
  ],
  privacyPolicyData: [
    {
      title: "What information do we collect?",
      text: `
        <p><b>Your profile and activities:</b></p>
        <p>We collect the content and other types of personal data you (“User”) provide when you use the Service. This includes personal data provided while registering to FUZU, building of your personal profile, sharing of updates, applying for jobs, taking courses and different types of tests and communicating with other users. This can include information in or about the content you provide, such as the location or the date the data was created. We also collect information about how you use our Service, such as the types of content you view or engage with or the frequency and duration of your activities. We may, in accordance with the applicable law, record your communication with our customer care or with other such contact points.</p>
        <br />
        <p><b>Your connections:</b></p>
        <p>We collect information about the other users and groups you are connected to and how you interact with them, such as the people you communicate with the most or the groups you like to share updates with.</p>
        <br />
        <p><b>Your payment information:</b></p>
        <p>When you purchase FUZU paid-for Premium features we collect information about the purchase or transaction. If credit or debit card is used for the transaction, we will only store the card data with your permission. General data we collect and store include billing and contact details, such as your phone number.</p>
        <br />
        <p><b>Your devices:</b></p>
        <p>To continuously improve the Service we collect information about the computers, phones, or other devices where you install or access our Services, depending on the permissions you’ve granted.</p>
        `
    },
    {
      title: "Why Do We Process Personal Data?",
      text: `
        <p>FUZU may process your personal data for the following purposes. One or more purposes may apply simultaneously.</p>
        <br />
        
        <p><b>Providing products and services</b></p>
        <p>We may use your personal data to provide you with our Services and content, to process your requests or as otherwise may be necessary to perform the contract between you and FUZU, to ensure the functionality and security of our Services and content, to identify you as well as to prevent and investigate fraud and other misuses.</p>
        <br />
        
        <p><b>Accounts</b></p>
        <p>Requires an account to help you manage your personal information, content and preferences.</p>
        <br />
        
        <p><b>Developing and managing services and content</b></p>
        <p>We may use your personal data to develop and manage our Services, software, content, customer care, sales and marketing. We may combine personal data collected in connection with your use of a particular Service or content with other personal data we may have about you, unless such personal data was collected for a different purpose.</p>
        <br />
        
        <p><b>Communicating with you</b></p>
        <p>We may use your personal data to communicate with you, for example to inform you that our Services have changed or to send you critical alerts and other such notices relating to our products and/or Services and to contact you for customer care related purposes.</p>
        <br />
        
        <p><b>Marketing, advertising and making recommendations</b></p>
        <p>We may contact you to inform you of new services, content or promotions we may offer and to conduct market research when we have your consent or it is otherwise allowed. We may use your personal data to personalise our offering and to provide you with more relevant ads and services, for example, recommend jobs or training content. This may include displaying FUZU and third-party content and ads.</p>
        <br />
       
        <p><b>Promote safety and security</b></p>
        <p>We use the information we have to help verify accounts and activity, and to promote safety and security on and off of our Services, such as by investigating suspicious activity or violations of our terms or policies. We work hard to protect your account using specialists, automated systems, and advanced technology such as encryption.</p>        
      `
    },
    {
      title: "Do we share personal data?",
      text: `
        <p>Users use our Services for their own personal development or for interacting with other Users. Your personal data is shared in the following ways:</p>
        <br />
        
        <p><b>Users you share and communicate with:</b></p>
        <p>You will be able to determine what information other Users see of your personal data (profile, contact information and other data) when you take part in public activities on the Service (e.g. post a comment to an article). By default, only User photo, first name and summary profile will be visible to other users. The Service includes various ways for networking with other Users, where you can decide what information is visible and what not.</p>
        <br />
        
        <p><b>Applying for a job:</b></p>
        <p>When you apply for a job you consent to sharing of your profile and personal data, including application data and test results, with the employer.</p>  
        <br />
                
        <p><b>Headhunting:</b></p>
        <p>You may also decide to share your profile with the employers for headhunting purposes, who can then view your personal data including your user profile, test results and contact information and to communicate with you on or off the Service. Visibility to headhunters can be managed under user settings.</p>  
        <br />
        
        <p><b>Direct communication with other users:</b></p>
        <p>Your private communication with other Users, both individuals and employers, FUZU customer support or team remain private to you and the other party.</p>  
        <br />
        
        <p><b>Learning and career development:</b></p>
        <p>All activities related to learning and career development conducted on the Service, including taking courses and tests are by default private and cannot be seen by other users. You may choose to take a skill test to verify and accredit a skill, which can then be published on your profile or shared proactively with other users, including employers. This does not apply to tests taken in connection with job applications, which are by default visible to employers.</p>  
        <br />
        
        <p><b>User updates</b></p>
        <p>Your updates and postings on FUZU are by default visible to all FUZU Users, unless targeted for a specific group of users.</p>
        <p>Please consider carefully before disclosing any personal data or other information that might be accessible to other users.</p>  
        <br />
      `
    },
    {
      title: "Sharing within FUZU companies or third parties:",
      text: `
        <p>We may share your personal data with FUZU companies or third parties who process personal data for FUZU for the purposes described in this Policy. This may include for example billing through your network service provider or otherwise, delivery of your purchases, providing services including customer service, managing and analysing consumer data, credit checks, conducting research and managing marketing and other such campaigns. When you purchase a FUZU service from us with a network service provider plan, we may need to exchange information with your network service provider to provide you with such service.</p>
        <br />
        
        <p>Sharing of information with third parties includes entities providing advertising, measurement and analytics services.</p>
        <br />
        
        <p>We want our advertising to be as relevant and interesting as the other information you find on our Services. With this in mind, we use all of the information we have about you to show you relevant ads. We do not share information that personally identifies you (personally identifiable information is information like name or email address that can by itself be used to contact you or identifies who you are) with advertising, measurement or analytics partners unless you give us permission. We may provide these partners with information about the reach and effectiveness of their advertising without providing information that personally identifies you, or if we have aggregated the information so that it does not personally identify you. For example, we may tell an advertiser how its ads performed, or how many people viewed their ads or installed an app after seeing an ad, or provide non-personally identifying demographic information (such as 18-year-old female, in Nairobi, who likes software engineering) to these partners to help them understand their audience or customers, but only after the advertiser has agreed to abide by our advertiser guidelines.</p>
        <br />
        
        <p>These authorised third parties are not permitted to use your personal data for any other purposes. We require them to act consistently with this Policy and to use appropriate security measures to protect your personal data.</p>  
        <br />
        
        <p><b>International transfers of personal data</b></p>
        <p>Our products and Services may be provided using resources and servers located in various countries around the world. Therefore, your personal data may be transferred across international borders outside the country where you use our Services, including to countries outside the European Economic Area (EEA) that do not have laws providing specific protection for personal data or that have different legal rules on data protection, for example, the United States of America. In such cases we ensure that there is a legal basis for such a transfer and that adequate protection for your personal data is provided as required by applicable law, for example, by using standard agreements approved by relevant authorities (where necessary) and by requiring the use of other appropriate technical and organisational information security measures.</p>        
        <br />
        
        <p><b>Mandatory disclosures</b></p>
        <p>We may be obligated by mandatory law to disclose your personal data to certain authorities or other third parties, for example, to law enforcement agencies in the countries where we or third parties acting on our behalf operate. We may also disclose and otherwise process your personal data in accordance with applicable law to defend FUZU’s legitimate interests, for example, in civil or criminal legal proceedings.</p>
        <br>
        <p>New owner: If the ownership or control of all or part of our Services or their assets changes, we may transfer your information to the new owner.</p>
`
    },
    {
      title: "How do we address the privacy of children?",
      text: `
        <p>FUZU Service has been designed for adult Users of eighteen (18) years and above. FUZU does not knowingly collect information of children without the consent of their parents or guardians.</p>
        <br />      
`
    },
    {
      title: "How do we address data quality?",
      text: `
        <p>We take reasonable steps to keep the personal data we possess accurate and to delete incorrect or unnecessary personal data.</p>
        <br />
        <p>We encourage you to access your personal data through your account from time to time to ensure that it is up to date.</p> 
      `
    },
    {
      title: "What steps are taken to safeguard personal data?",
      text: `
        <p>Privacy and security are key considerations in the creation and delivery of our Services. We take appropriate steps to address online security, physical security, risk of data loss and other such risks taking into consideration the risk represented by the processing and the nature of the data being protected. Also, we limit access to our databases containing personal data to authorised persons having a justified need to access such information.</p>
      `
    },
    {
      title: "How do we use cookies, pixels and web beacons?",
      text: `
        <p>FUZU uses cookies, pixels, web beacons and other similar technologies to operate and improve our websites and offering. We also use cookies for personalisation and to display ads. Some FUZU websites use third party advertising technologies, such as DoubleClick, to serve ads.</p>
        <br />
        <p>Our domains may include third party elements that set cookies on behalf of a third party, for example relating to third party social network.</p>
      `
    },
    {
      title: "What are your rights?",
      text: `
        <p>You have a right to know what personal data we hold about you. You have a right to have incomplete, incorrect, unnecessary or outdated personal data deleted or updated.</p>
        <br />
        <p>You may exercise your rights by contacting us or by managing your account and choices through available profile management tools on your device and our Services. In some cases, especially if you wish us to delete or stop processing your personal data, this will also mean that we may not be able to continue to provide the Services to you.</p>
        <br />
        <p>If you choose to close your account on our Services, your personal data will generally stop being visible to others on our Services within 48 hours. We generally delete closed account information within 30 days of account closure.</p>       
      `
    },
    {
      title: "Who is the controller of your personal data?",
      text: `
        <p>FUZU Ltd (corporate ID 2546225-2), Lapinlahdenkatu 16, 00180 Helsinki, Finland is the controller of your personal data.</p>
        <br />
        <p>In addition, the FUZU affiliate providing the product or service may be a controller of your personal data. You may find the identity of the controller and its contact details by reviewing the terms and conditions of such a product or service or by using contact information provided in the applicable FUZU websites.</p>
        <br />
        <p>In matters pertaining to FUZU’s privacy practices you may also contact us at the address above or our email  <a class="link-faq-list" href="mailto:support@fuzu.com">support@fuzu.com</a>.</p>
      `
    },
    {
      title: "Changes to this Privacy Policy",
      text: `
        <p>FUZU may from time to time change this Policy or change, modify or withdraw access to this site at any time with or without notice. However, if this Policy is changed in a material, adverse way, FUZU will post a notice advising of such change at the beginning of this Policy and on this site’s home page for 30 days. We recommend that you re-visit this Policy from time to time to learn of any such changes to this Policy.</p>
      `
    }
  ]
};

const BrandingContext = createContext(branding);

export default BrandingContext;
