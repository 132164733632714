import React from "react";
import { Row, Col } from "react-grid-system";

import { List, Text, Title } from "b2c/components/Core";
import { useTranslation } from "b2c/hooks/useTranslation";
import { supports } from "b2c/constants/pricing";
import { EliteSupportSection, EliteSupportContent, EliteCircle } from "./styled";

export const EliteSupport = ({ isSm }) => {
  const { t } = useTranslation();

  return (
    <EliteSupportSection>
      <EliteSupportContent>
        <Title
          loaded
          level="2"
          style={{
            fontWeight: "600",
            lineHeight: "130%",
            color: "#292929",
            display: "block",
            margin: "0 auto 8px"
          }}
        >
          {t("pricing.elite_details.support.title")}
        </Title>
        <Text
          style={{
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "140%",
            color: "#292929",
            display: "block",
            margin: "0 auto 50px",
            textAlign: "center"
          }}
        >
          {t("pricing.elite_details.support.description")}
        </Text>
        <Row>
          {supports.map(({ number, title, list }) => (
            <Col md={4} key={title}>
              <div style={{ display: "flex", marginBottom: "24px" }}>
                <EliteCircle>{number}</EliteCircle>
                <Text
                  style={{
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#292929"
                  }}
                >
                  {t(title)}
                </Text>
              </div>
              <Row style={{ marginBottom: isSm ? " 40px" : "10px", marginTop: !isSm ? "40px" : "10px" }}>
                <List>
                  {list.map(item => (
                    <List.Item key={item} margin="0 0 10px" lineHeight="20px" bulletColor="black500">
                      <Text fontSize="14px" color="black300">
                        {t(item)}
                      </Text>
                    </List.Item>
                  ))}
                </List>
              </Row>
            </Col>
          ))}
        </Row>
      </EliteSupportContent>
    </EliteSupportSection>
  );
};
