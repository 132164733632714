import React from "react";
import { Row, Col } from "react-grid-system";

import { Title, Text, Spinner, Checkbox } from "b2c/components/Core";

const InterestsStep = ({ interest_areas = [], selected = [], onSelect }) => {
  return (
    <form noValidate>
      <Title level="3" fontWeight={500} margin="0 0 12px">
        Great, next we would love to understand your professional interests!
      </Title>
      <Text id="label" lineHeight="140%" margin="0 0 40px" color="black300">
        You can select one or more areas. Please note that your recommendations are based on these choices!
      </Text>
      <Row aria-labelledby="label">
        {interest_areas.length <= 1 ? (
          <Col>
            <Spinner size="50px" />
          </Col>
        ) : (
          interest_areas.map(({ title, id }) => (
            <Col sm={6} xs={12} key={id}>
              <Checkbox
                label={
                  <Text color="black400" lineHeight={1.95}>
                    {title}
                  </Text>
                }
                id={id}
                checked={selected.includes(id)}
                onChange={({ target: { checked } }) =>
                  onSelect(checked ? selected.concat(id) : selected.filter(i => i !== id))
                }
                boxedView
              />
            </Col>
          ))
        )}
      </Row>
    </form>
  );
};

export default InterestsStep;
