import React, { useContext } from "react";
import { Row, Col, ScreenClassContext } from "react-grid-system";

import coachAvatar_nabil from "images/b2c/careerCoaching/coach_1.jpg";
import coachAvatar_catherine from "images/b2c/careerCoaching/coach_catherine.jpg";
import coachAvatar_ernest from "images/b2c/careerCoaching/ernest.jpg";
import coachAvatar_annel from "images/b2c/careerCoaching/annel.jpg";
import coachAvatar_lillian from "images/b2c/careerCoaching/lilian.jpg";
import circleBG_white from "images/b2c/circleBG_white.png";
import { useTranslation } from "b2c/hooks/useTranslation";
import { CountryContainer } from "b2c/components/WithAuth";
import StarRating from "b2c/components/StarRating";
import { Title, Text, Card, Image, Carousel } from "b2c/components/Core";
import Layout from "b2c/components/Layout";

const CareerCoachingCarousel = () => {
  const screen = useContext(ScreenClassContext);
  const isSm = ["xs", "sm"].includes(screen);
  const { t } = useTranslation();

  return (
    <>
      <Layout.Section style={{ background: `url(${circleBG_white})` }} padding="30px 0 50px">
        <CountryContainer KE={{ coaches: coachesKE }} UG={{ coaches: coachesUG }}>
          {({ coaches }, country) => (
            <Layout.Content tag="div">
              <Title
                loaded
                level="2"
                fontWeight={600}
                margin="0 auto 25px"
                textAlign="center"
                style={{ fontSize: isSm ? 20 : 36 }}
              >
                {t("career_coaching.coaches.title")} {country?.name}
              </Title>
              <Carousel
                className="wide-blue-buttons full-height"
                settings={{
                  slidesToShow: coaches.length,
                  fade: false,
                  dots: false,
                  rows: 1,
                  adaptiveHeight: false,
                  responsive: [
                    {
                      breakpoint: 767,
                      settings: {
                        slidesToShow: 1
                      }
                    }
                  ]
                }}
              >
                {coaches.map(({ id, ...coach }) => (
                  <Carousel.Slide key={id} padding="20px" height="100%">
                    <CoachCard {...coach} />
                  </Carousel.Slide>
                ))}
              </Carousel>
            </Layout.Content>
          )}
        </CountryContainer>
      </Layout.Section>
    </>
  );
};

const CoachCard = ({ avatar, name, role, description, score, sessions }) => {
  const { t } = useTranslation();

  return (
    <Card shadow style={{ height: "100%" }}>
      <Card.Content padding="16px" height="100%">
        <Row align="center">
          <Col xs="content">
            <Image width={100} height={100} objectFit="cover" src={avatar} borderRadius="50%" />
          </Col>
          <Col>
            <Text fontSize={16} fontWeight={700} lineHeight="24px">
              {t(name)}
            </Text>
            <Text color="black300" lineHeight="18px">
              {t(role)}
            </Text>
          </Col>
        </Row>
        <Text color="black200" margin="10px 0 0">
          {t(description)}
        </Text>
      </Card.Content>
      <Card.Footer padding="16px" display="block">
        <Row justify="between">
          {score && (
            <Col lg="content" sm={12}>
              <StarRating rating={score} hideCount />
            </Col>
          )}
          {sessions && (
            <Col lg="content" sm={12}>
              <Text textAlign="end">
                {sessions}
                {t("career_coaching.coachescoaching_sessions")}
              </Text>
            </Col>
          )}
        </Row>
      </Card.Footer>
    </Card>
  );
};

const coachesKE = [
  {
    id: 1,
    avatar: coachAvatar_nabil,
    name: "career_coaching.coaches.list_kenya.nabeel_name",
    role: "career_coaching.coaches.list_kenya.nabeel_role",
    description: "career_coaching.coaches.list_kenya.nabeel_description",
    score: 4.7,
    sessions: 50
  },
  {
    id: 2,
    avatar: coachAvatar_catherine,
    name: "career_coaching.coaches.list_kenya.catherine_name",
    role: "career_coaching.coaches.list_kenya.catherine_role",
    description: "career_coaching.coaches.list_kenya.catherine_description",
    score: 4.3,
    sessions: 40
  },
  {
    id: 3,
    avatar: coachAvatar_ernest,
    name: "career_coaching.coaches.list_kenya.ernest_name",
    role: "career_coaching.coaches.list_kenya.ernest_role",
    description: "career_coaching.coaches.list_kenya.ernest_description",
    score: 4.4,
    sessions: 15
  }
];

const coachesUG = [
  {
    id: 1,
    avatar: coachAvatar_annel,
    name: "career_coaching.coaches.list_uganda.annelies_name",
    role: "career_coaching.coaches.list_uganda.annelies_role",
    description: "career_coaching.coaches.list_uganda.annelies_description"
  },
  {
    id: 2,
    avatar: coachAvatar_lillian,
    name: "career_coaching.coaches.list_uganda.lilian_name",
    role: "career_coaching.coaches.list_uganda.lilian_role",
    description: "career_coaching.coaches.list_uganda.lilian_description"
  }
];
export default CareerCoachingCarousel;
