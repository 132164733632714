import styled from "styled-components";

export const BaronaEmpty = styled.div`
  width: 300px;
  margin: 36px auto;
  display: flex;
  justify-content: center;

  svg {
    width: 300px;
    height: 300px;
  }
`;
