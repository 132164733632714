import React from "react";

import Courses from "./CoursesSection";
import Jobs from "./JobsSection";
import Articles from "./ArticleSection";
import Unfinished from "./UnfinishedSection";
import Companies from "./CompaniesSection";
import UsersSection from "./UsersSection";
import Heading from "./Heading";

export const FeedSection = ({ type, ...rest }) => {
  switch (type) {
    default:
      return null;
    case "jobs":
      return <Jobs {...rest} />;
    case "courses":
      return <Courses {...rest} />;
    case "articles":
      return <Articles {...rest} />;
    case "unfinished_content":
      return <Unfinished {...rest} />;
    case "companies":
      return <Companies {...rest} />;
    case "users":
      return <UsersSection {...rest} />;
  }
};

export default { FeedSection, Heading };
