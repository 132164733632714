import React from "react";
import { Row, Col } from "react-grid-system";
import { QuoteAltLeft } from "@styled-icons/boxicons-solid/QuoteAltLeft";
import { Title, Text, Card, Image, Carousel, Icon } from "../../../../components/Core";
import { LoadingContent } from "../../../../components/Core/Spinner";
import { FeedbackSliderWrapper } from "./styled";

const FeedbackSlider = ({ reviews = [], loading }) => {
  return (
    <>
      <Title level={3} fontWeight={600} margin="35px 0 0">
        What students say about this course
      </Title>
      <LoadingContent loading={loading} size="50px">
        <FeedbackSliderWrapper>
          <Carousel
            settings={{
              adaptiveHeight: true,
              slidesToShow: 2,
              fade: false,
              dots: false,
              responsive: [
                {
                  breakpoint: 767,
                  settings: {
                    slidesToShow: 1,
                    arrows: false
                  }
                }
              ]
            }}
          >
            {reviews.map(({ user, comment }, i) => (
              <Carousel.Slide padding="20px" key={`feedback_uid_${i}`}>
                <Card shadow>
                  <Card.Content padding="20px 16px" height={250}>
                    <Icon
                      as={QuoteAltLeft}
                      width={60}
                      fill="primary300"
                      padding="16px"
                      backgroundColor="white"
                      additionalCss={`
                        border-radius: 50%;
                        filter: drop-shadow(0px 4px 4px rgba(0,0,0,0.05));
                      `}
                    />
                    <Text margin="12px 0 auto" fontSize={16} lineHeight="24px" maxLength={100}>
                      {comment}
                    </Text>
                    <Row align="center">
                      <Col xs="content">
                        <Image width={42} src={user.avatar} borderRadius="50%" />
                      </Col>
                      <Col>
                        <Text fontSize={16} fontWeight={700} lineHeight="24px">
                          {user.first_name} {user.last_name}
                        </Text>
                      </Col>
                    </Row>
                  </Card.Content>
                </Card>
              </Carousel.Slide>
            ))}
          </Carousel>
        </FeedbackSliderWrapper>
      </LoadingContent>
    </>
  );
};

export default FeedbackSlider;
