import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";

import { useTranslation } from "b2c/hooks/useTranslation";
import { buildOnboardingParams } from "b2c/utils/helpers";
import { useHistory } from "react-router-dom";
import parse from "html-react-parser";
import Content from "../Content";
import Navigation from "../Navigation";
import { Label, SearchInput, SearchWrapper, Container } from "./styled";
import useStore from "../../../../contexts/store";
import { useQueryParams } from "../../../../../utils/hooks";
import Steps from "../Steps";
import { jobTypes } from "../../../../constants/onboarding";
import { Description } from "../YourEmail/styled";

const YourEmail = () => {
  const [nameValue, setNameValue] = useState("");
  const { signUpDetails } = useStore("Onboarding");
  const { candidate, refer_code: referCode } = useQueryParams();
  const { t } = useTranslation();
  const history = useHistory();

  const handleChange = event => {
    setNameValue(event.target.value);
  };

  const handleSubmit = () => {
    const prevOnboardingForm = JSON.parse(sessionStorage.getItem("onboardingFlow"));
    const applicationId = prevOnboardingForm.applicationId
      ? { applicationId: Number(prevOnboardingForm.applicationId) }
      : {};

    const paramsValues = {
      ...prevOnboardingForm,
      name: nameValue,
      ...applicationId
    };

    const params = buildOnboardingParams(paramsValues);

    signUpDetails(params, referCode, candidate).then(res => {
      if (res) {
        sessionStorage.removeItem("onboardingFlow");

        if (prevOnboardingForm.returnTo) {
          history.push(prevOnboardingForm.returnTo);
        }
      }
    });
  };

  const backUrl = useMemo(() => {
    const prevOnboardingForm = JSON.parse(sessionStorage.getItem("onboardingFlow"));

    if (prevOnboardingForm?.jobType === jobTypes.skilled) {
      return "/onboarding/pick-job";
    }

    return "/onboarding/seniority-level";
  }, []);

  useEffect(() => {
    const onboardingFlowStorage = JSON.parse(sessionStorage.getItem("onboardingFlow"));
    const name = onboardingFlowStorage?.name;

    if (name && !nameValue) {
      setNameValue(name);
    }
  }, []);

  useEffect(() => {
    const promptInterval = setTimeout(() => {
      const prevOnboardingForm = JSON.parse(sessionStorage.getItem("onboardingFlow"));

      const onboardingForm = {
        ...prevOnboardingForm,
        name: nameValue
      };

      sessionStorage.setItem("onboardingFlow", JSON.stringify(onboardingForm));
    }, 1500);

    return () => {
      window.clearTimeout(promptInterval);
    };
  }, [nameValue]);

  return (
    <>
      <Steps width="100%" />

      <Container data-cy="name-and-password">
        <Content title={t("onboarding.yourName.title")} description={t("onboarding.yourName.description")}>
          <Label>{t("onboarding.yourName.label")}</Label>

          <SearchWrapper>
            <SearchInput
              placeholder={t("onboarding.yourName.placeholder")}
              type="text"
              onChange={handleChange}
              value={nameValue}
              data-cy="first-name"
            />
          </SearchWrapper>

          <Description>{parse(t("onboarding.yourName.agreement"))}</Description>
        </Content>
      </Container>
      <Navigation backUrl={backUrl} isFinish isDisabled={!nameValue} handleSubmit={handleSubmit} />
    </>
  );
};

export default observer(YourEmail);
