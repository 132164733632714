import React, { useLayoutEffect, useContext, useEffect, lazy, Suspense, useMemo } from "react";
import { observer } from "mobx-react";
import { Redirect, Route, useHistory } from "react-router-dom";
import { Hidden } from "react-grid-system";
import { Helmet } from "react-helmet";

import Arrows from "images/b2c/landing-page/arrows.svg";
import HireEmployers from "b2c/components/HireEmployers";
import { useTranslation } from "b2c/hooks/useTranslation";
import GuestModeContext from "../../contexts/guest";
import LandingContext from "../../contexts/landing";
import useStore from "../../contexts/store";
import { withGuestVersion } from "../../components/WithAuth";
import { Categories, Icon } from "../../components/Core";
import JobSearchSection from "./components/JobSearchSection";
import LandingBarona from "./components/LandingBarona";
import Hero from "./components/Hero";
import { useGetCurrentCountry, useIsBarona, useLanguageAndCountryUrl, useShowToastMessage } from "../../hooks";
import { getCompanyName, withCustomPlatformHidden } from "../../contexts/branded";
import { townPath, pathWithCountry } from "../../utils/routes";
import { LandingPageWrapper } from "./styled";

const HowItWorksSection = lazy(() => import("../../components/HowItWorksSection"));
const B2bOfferSection = lazy(() => import("./components/B2bOfferSection"));
const FreeCvPromo = lazy(() => import("./components/FreeCvPromo"));
const Testimonials = lazy(() => import("./components/Testimonials"));
const SignupPrompt = lazy(() => import("../../components/SignupPrompt"));

const LandingScreen = ({ isMultiLanguageBrand }) => {
  const { getGuestLandingPage, guestLayouts } = useStore("Landing");
  const isBarona = useIsBarona();
  const { t } = useTranslation();
  const languageAndLanguageUrl = useLanguageAndCountryUrl();

  const {
    layout: { common, general }
  } = useStore("initialState");

  const commonLayouts = guestLayouts.common ? guestLayouts.common : common;
  const generalLayouts = guestLayouts.common ? guestLayouts.general : general;

  const normalizeLayoutCommon = useMemo(() => {
    if (Array.isArray(commonLayouts) && commonLayouts.length > 0) {
      return commonLayouts.reduce((acc, current) => {
        acc[current.type] = current;
        return acc;
      }, {});
    }
    return {};
  }, [commonLayouts]);

  useEffect(() => {
    if (generalLayouts?.page_type !== "guest_landing_page") {
      getGuestLandingPage();
    }
  }, []);

  const {
    hiring_now_for_jobs: hiringNowForJobs = {},
    platform_stats: platformStats = {},
    jobs_search_panel: jobsSearchPanel = {},
    platform_reviews: platformReviews = {},
    popular_searches: popularSearches = {},
    professions_with_jobs: professionsWithJobs = {},
    industries_with_jobs: industriesWithJobs = {},
    towns_with_jobs: townsWithJobs = {},
    login_section: loginSection = {},
    seo_categories: seoCategories,
    static_platform_details: staticPlatformDetails = {},
    talent_pool_intro: talentPoolIntro = {},
    barona_platform_stats: baronaPlatformStats = {},
    barona_static_platform_details: baronaStaticPlatformDetails = {},
    barona_talent_pool_intro: baronaTalentPoolIntro = {}
  } = normalizeLayoutCommon || guestLayouts;

  const country = useGetCurrentCountry();
  const isNigeria = country?.slug === "nigeria";
  const history = useHistory();
  const { setGuestMode } = useContext(GuestModeContext);
  const { setVersion } = useContext(LandingContext);

  useLayoutEffect(() => {
    setVersion("b2c");
    setGuestMode(false);
    return () => {
      setGuestMode(true);
    };
  }, []);

  const handleClickCategory = path => {
    history.push(path);
  };

  useShowToastMessage();

  if (isMultiLanguageBrand) {
    return <Redirect to={`${languageAndLanguageUrl}/job`} />;
  }

  return (
    <>
      {((document.title && document?.title?.includes("Login")) || document?.title === "Fuzu") && (
        <Helmet>
          <title>{getCompanyName()}</title>
        </Helmet>
      )}
      {isBarona ? (
        <LandingBarona
          jobsSearchPanel={jobsSearchPanel}
          platformReviews={platformReviews}
          popularSearches={popularSearches}
          industriesWithJobs={industriesWithJobs}
          townsWithJobs={townsWithJobs}
          talentPoolIntro={talentPoolIntro}
          baronaPlatformStats={baronaPlatformStats}
          baronaStaticPlatformDetails={baronaStaticPlatformDetails}
          baronaTalentPoolIntro={baronaTalentPoolIntro}
        />
      ) : (
        <LandingPageWrapper data-cy="landing">
          <Hero country={country} jobsCount={platformStats?.context?.jobs_count} />

          <JobSearchSection
            country={country}
            towns={jobsSearchPanel?.context?.geonames}
            filters={
              { categories: jobsSearchPanel?.context?.categories, industries: jobsSearchPanel?.context?.industries } ||
              {}
            }
            handleClickCategory={handleClickCategory}
            topCategories={popularSearches?.context?.top_categories}
          />

          <Hidden xs sm>
            <Icon
              as={Arrows}
              width={88}
              style={{
                position: "absolute",
                right: "50%",
                transform: "translateX(50%) translateY(-50%)"
              }}
            />
          </Hidden>

          {hiringNowForJobs?.id && <HireEmployers isLanding companies={hiringNowForJobs?.context?.companies} />}

          <Suspense fallback={<div />}>
            <Route exact path="/nigeria">
              <FreeCvPromo style={{ contentVisibility: "auto" }} />
            </Route>

            {staticPlatformDetails?.id && <HowItWorksSection country={country} style={{ contentVisibility: "auto" }} />}

            {loginSection?.id && <SignupPrompt style={{ contentVisibility: "auto" }} />}

            {platformReviews?.id && <Testimonials style={{ contentVisibility: "auto" }} />}
            {talentPoolIntro && <B2bOfferSection style={{ contentVisibility: "auto" }} />}
          </Suspense>

          <Categories
            title={
              isNigeria
                ? t("landing_fuzu.categories.search_jobs_by_profession")
                : `${t("landing_fuzu.categories.explore_jobs")}${
                    country?.name ? ` ${t("landing_fuzu.categories.explore_in")} ${country?.name}` : ""
                  } ${t("landing_fuzu.categories.by_profession")}`
            }
            style={{ contentVisibility: "auto" }}
          >
            {professionsWithJobs?.context?.categories?.map(({ name, slug }) => (
              <Categories.Item
                key={slug}
                to={pathWithCountry(country, `/job/${slug}`)}
                style={{ contentVisibility: "auto" }}
                onClick={() => handleClickCategory(pathWithCountry(country, `/job/${slug}`))}
              >
                {`${name} ${t("landing.industry.helper_text")}`}
              </Categories.Item>
            ))}
          </Categories>

          <Categories
            title={
              isNigeria
                ? t("landing_fuzu.categories.search_jobs_by_location")
                : `${t("landing_fuzu.categories.explore_jobs")}${
                    country?.name ? ` ${t("landing_fuzu.categories.explore_in")} ${country?.name}` : ""
                  } ${t("landing_fuzu.categories.by_location")}`
            }
            style={{ contentVisibility: "auto" }}
          >
            {townsWithJobs?.context?.towns?.slice(0, 20).map(town => (
              <Categories.Item
                key={town.slug}
                to={townPath(town)}
                style={{ contentVisibility: "auto" }}
                onClick={() => handleClickCategory(townPath(town))}
              >
                {`${t("landing.location.helper_text")} ${town.name}`}
                {country ? "" : `, ${town.fuzu_country_name}`}
              </Categories.Item>
            ))}
          </Categories>

          <Categories
            title={
              isNigeria
                ? t("landing_fuzu.categories.search_jobs_by_title")
                : `${t("landing_fuzu.categories.explore_jobs")}${
                    country?.name ? ` ${t("landing_fuzu.categories.explore_in")} ${country?.name}` : ""
                  } ${t("landing_fuzu.categories.by_job_title")}`
            }
            style={{ contentVisibility: "auto" }}
          >
            {seoCategories?.context?.seo_categories?.map(({ name, slug }) => (
              <Categories.Item
                key={slug}
                to={pathWithCountry(country, `/job/${slug}`)}
                style={{ contentVisibility: "auto" }}
                onClick={() => handleClickCategory(pathWithCountry(country, `/job/${slug}`))}
              >
                {`${name} ${t("landing.industry.helper_text")}`}
              </Categories.Item>
            ))}
          </Categories>
        </LandingPageWrapper>
      )}
    </>
  );
};

export default withCustomPlatformHidden(withGuestVersion(() => <Redirect to="/home" />, observer(LandingScreen)));
