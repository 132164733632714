import styled from "styled-components";

import { Image, Text } from "../../../../components/Core";

export const TestAndSurveyContent = styled.div`
  flex: 1 0 0;
  padding: 60px 0;
  overflow: auto;
`;

export const TestAndSurveyHeader = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  background-color: #fff;
  border-bottom: 1px solid #e1e1e1;
`;

export const TestAndSurveyHeaderAction = styled.div`
  position: absolute;
  top: 0;
  right: 20px;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const TestAndSurveySubHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  height: 60px;
  padding: 0 20px;
`;

// Navigation
export const NavWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100px;
  background: #fff;
  width: 100%;
  position: relative;
  @media screen and (max-width: 767px) {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 11;
  }
`;
export const NavQuestionCounter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 1;
  @media screen and (max-width: 550px) {
    position: static;
    transform: none;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
  }
`;

// nav progress
export const NavProgressWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;
`;

export const NavProgressStep = styled.div`
  flex: 1 0 auto;
  height: ${({ isActive }) => (isActive ? "6px" : "4px")};
  background-color: ${({ isActive, activeColor, backgroundColor }) => (isActive ? activeColor : backgroundColor)};
  transition: 0.2s ease;
  &:not(:last-child) {
    margin-right: 4px;
  }
`;

export const IntroductionContents = styled.div`
  @media screen and (max-width: 767px) {
    margin: 0 -20px;
  }

  .b2c-card {
    @media screen and (max-width: 767px) {
      width: 100%;
    }

    .card-header {
      height: 320px;

      @media screen and (max-width: 767px) {
        height: 140px;
      }
    }

    p:not(:last-child) {
      margin-bottom: 12px;
    }
  }
`;

// Timer

export const TimerWrapper = styled.div`
  display: flex;
  align-items: center;
  min-width: 150px;
`;

export const TimerTextWrapper = styled.div`
  position: absolute;
  left: 100%;
  margin-left: 10px;
  white-space: nowrap;
`;

export const QuestionsWrapper = styled.div`
  &:focus {
    outline: none;
  }
`;

export const CorrectCircleIcon = styled(Image)`
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
`;

export const AnswerOption = styled(Text)`
  display: block;
  padding: 0 10px;
  width: calc(100% - 50px);
  flex: none;
`;

export const AnswerOptionWrapper = styled.div`
  padding: 6px;
  border: ${({ isSelectedAndIncorrect, isSelectedAndCorrect }) =>
    isSelectedAndIncorrect ? "1px solid #C30000" : isSelectedAndCorrect ? "1px solid #018747" : "1px solid #e0e0e0"};
  border-radius: 5px;
  margin: 3px 0;
  position: relative;
  background-color: ${({ isSelectedAndIncorrect, isSelectedAndCorrect }) =>
    isSelectedAndIncorrect ? "#FDE8EB" : isSelectedAndCorrect ? "#E1F6E0" : "#fff"};
  display: flex;
  align-items: center;
`;

export const ResultTitle = styled.div`
  display: inline-block;
  position: absolute;
  top: 10px;
  left: 65px;
  font-size: 18px;
`;

export const ResultTitleWrapper = styled.div`
  position: relative;
  margin-bottom: 7px;
`;

export const ResultIconWrapper = styled.div`
  padding: 5px;
  height: 32px;
  border: 1px solid #dedede;
  border-radius: 15px;
  display: inline-block;
  background: ${({ isCorrect }) => (isCorrect ? "#0aa677" : "#E71D36")};
  width: 168px;
  color: white;
  position: relative;
`;

export const ResultIcon = styled(Image)`
  position: absolute;
  width: 17px;
  top: 8px;
  left: 10px;
`;

export const AnswerResultTitle = styled.div`
  position: absolute;
  top: 4px;
  left: 40px;
`;

export const Questions = styled.div`
  border: 1px solid #e0e0e0;
  padding: 16px;
  margin: 21px 0;
  border-radius: 5px;
`;

export const IncorrectMark = styled.div`
  width: 24px;
  height: 24px;
  border: 1px solid #9e9e9e;
  background: #f3f3f3;
  border-radius: 3px;
`;
