import React, { useState, useEffect, useContext, useMemo } from "react";
import { Row, Col, ScreenClassContext } from "react-grid-system";
import { observer } from "mobx-react";

import { capitalize } from "utils/helpers";
import { WhitelabelledHidden } from "b2c/contexts/branded";
import { useTranslation } from "b2c/hooks/useTranslation";
import FileUploader from "../../../../components/FileUploader";
import { Card, Button, Text, Spinner } from "../../../../components/Core";
import PromoSection from "../../../../components/PromoSection";
import { Title, Label } from "./styled";
import Referral from "../../../../components/Referral";
import ProfessionalCertificates from "../ProfessionalCertificates";
import Educations from "../Educations";
import useStore from "../../../../contexts/store";
import { isBaronaBrand } from "../../../../contexts/branded";

const Attachments = ({
  id,
  processing,
  loading = false,
  substeps = [],
  attachments: { uploaded_cv, education_certificates, professional_certificates } = {},
  validation_errors = {},
  getAttachments,
  updateAttachments,
  getAttachmentsEducationItems,
  getAttachmentsProfessionalCertificates,
  educationsItems,
  professionalCertificates,
  deleteEducationCertificate,
  back,
  handleInitializeApplication,
  applicationAttachmentsErrors
}) => {
  const {
    Profile: {
      profile: { references }
    }
  } = useStore("User");
  const { t } = useTranslation();

  const screen = useContext(ScreenClassContext);
  const isSm = ["xs", "sm"].includes(screen);
  const [attachments, setAttachments] = useState({
    uploaded_cv,
    education_certificates: [],
    professional_certificates: []
  });
  const isBarona = isBaronaBrand();

  useEffect(() => {
    getAttachments(id);
    getAttachmentsEducationItems(id);
    getAttachmentsProfessionalCertificates(id);
  }, []);

  const handleBack = () => {
    back();
    if (handleInitializeApplication) {
      handleInitializeApplication();
    }
  };

  useEffect(() => {
    setAttachments({
      uploaded_cv: attachments.uploaded_cv || uploaded_cv,
      education_certificates: [],
      professional_certificates
    });
  }, [uploaded_cv, education_certificates]);

  const handleAttachment = (name, file) => {
    setAttachments(prev => ({ ...prev, [name]: file }));
  };

  const formatError = error => (error ? capitalize(error.join(", ")) : null);

  const requiresCv = substeps.some(el => el === "upload_cv");
  const requiresReferrals = substeps.some(el => el === "upload_reference");
  const requiresEducationCertificates = substeps.some(el => el === "upload_education_certificates");
  const requiresCert = substeps.some(el => el === "upload_academic_certificate");
  const requiresProfessionCertificates = substeps.some(el => el === "upload_professional_certificate");

  const isAllowCertificates = useMemo(() => {
    const defaultNotFilledCertificatesIds = professionalCertificates
      .filter(item => !item.certificate_item)
      .map(item => item.id);

    const filledAttachmentsCertificatesIds = attachments.professional_certificates
      ?.filter(item => Boolean(item.attachment) || Boolean(item.certificate))
      .map(item => item.certificate_id);

    const combinedFilledAndNotFilledValues =
      defaultNotFilledCertificatesIds?.length && filledAttachmentsCertificatesIds?.length
        ? Array.from(new Set([...defaultNotFilledCertificatesIds, ...filledAttachmentsCertificatesIds]))
        : [];

    const isFilledCertificates =
      combinedFilledAndNotFilledValues?.length &&
      filledAttachmentsCertificatesIds?.length === combinedFilledAndNotFilledValues?.length
        ? filledAttachmentsCertificatesIds?.every(value => combinedFilledAndNotFilledValues.includes(value))
        : false;

    return defaultNotFilledCertificatesIds?.length === 0 ? true : isFilledCertificates;
  }, [professionalCertificates, attachments.professional_certificates]);

  const getNotDuplicates = data => {
    return data ? data.filter((item, index) => data.indexOf(item) === index) : [];
  };

  const isAllowEducations = useMemo(() => {
    const defaultNotFilledEducationsIds = educationsItems
      .filter(item => !item.academic_certificate)
      .map(item => item.id);

    const filledAttachmentsEducationsIds = getNotDuplicates(
      attachments.education_certificates?.filter(item => Boolean(item.certificate)).map(item => item.education_item_id)
    );

    const combinedFilledAndNotFilledValues =
      defaultNotFilledEducationsIds?.length && filledAttachmentsEducationsIds?.length
        ? Array.from(new Set([...defaultNotFilledEducationsIds, ...filledAttachmentsEducationsIds]))
        : [];

    const isFilledCertificates =
      combinedFilledAndNotFilledValues?.length &&
      filledAttachmentsEducationsIds?.length === combinedFilledAndNotFilledValues?.length
        ? filledAttachmentsEducationsIds?.every(value => combinedFilledAndNotFilledValues.includes(value))
        : false;

    return defaultNotFilledEducationsIds?.length === 0 ? true : isFilledCertificates;
  }, [educationsItems, attachments.education_certificates]);

  const allowProgress =
    (requiresCv ? !!attachments.uploaded_cv : true) &&
    (requiresEducationCertificates || requiresCert ? isAllowEducations : true) &&
    (requiresReferrals ? !!attachments.reference_items || !!references?.length : true) &&
    (requiresProfessionCertificates ? isAllowCertificates : true);

  return (
    <div data-cy="upload_academic_certificate">
      <Card bordered wideOnMobile>
        {requiresCv && (
          <Card.Header>
            <div>
              <Title>{t("application.attachments.cv.title")}</Title>
              <Label>{t("application.attachments.cv.description")}</Label>
            </div>
          </Card.Header>
        )}

        {(requiresCv || loading) && (
          <>
            <Card.Footer>
              {loading ? (
                <Spinner size="75px" margin="24px auto" />
              ) : (
                <Row justify="flex-start" style={{ margin: "16px 0", width: "100%" }}>
                  {requiresCv && (
                    <Col sm={4}>
                      <FileUploader
                        data-cy="upload_cv"
                        title={t("application.attachments.cv.file_title")}
                        cardProps={{ bordered: true, borderColor: "grey200" }}
                        name={
                          attachments?.uploaded_cv?.name ||
                          uploaded_cv?.original_filename ||
                          t("application.attachments.cv.file_name")
                        }
                        url={uploaded_cv?.url}
                        accept=".pdf, .doc, .docx, .rtf, .txt"
                        buttonText={
                          uploaded_cv?.url ? t("wizard.upload.upload_cv_button") : t("wizard.upload.choose_file")
                        }
                        onUpload={file => handleAttachment("uploaded_cv", file)}
                        error={{
                          isShow: Boolean(applicationAttachmentsErrors.uploaded_cv),
                          text: applicationAttachmentsErrors.uploaded_cv
                        }}
                      />
                      {validation_errors.attachments &&
                        validation_errors.attachments.uploaded_cv &&
                        !applicationAttachmentsErrors?.uploaded_cv && (
                          <Text
                            color="accent300"
                            fontSize="14px"
                            margin="16px auto"
                            fontWeight={500}
                            loaded={!!validation_errors.attachments.uploaded_cv}
                          >
                            {formatError(validation_errors.attachments.uploaded_cv)}
                            _______
                          </Text>
                        )}
                    </Col>
                  )}
                </Row>
              )}
            </Card.Footer>
            {validation_errors.attachments && Array.isArray(validation_errors.attachments) && (
              <Text
                color="accent300"
                fontSize="14px"
                margin="0 auto 16px"
                fontWeight={500}
                loaded={!!validation_errors.attachments}
              >
                {formatError(validation_errors.attachments)}
              </Text>
            )}
          </>
        )}

        {(requiresEducationCertificates || requiresCert) && (
          <Educations
            educations={educationsItems}
            setAttachments={setAttachments}
            deleteEducationCertificate={deleteEducationCertificate}
            requiresCert={requiresCert}
            id={id}
            educationCertificatesErrors={applicationAttachmentsErrors.education_certificates}
          />
        )}

        {validation_errors.attachments && validation_errors.attachments.academic_certificate && (
          <Text
            color="accent300"
            fontSize="14px"
            margin="16px auto"
            fontWeight={500}
            loaded={!!validation_errors.attachments.academic_certificate}
          >
            {formatError(validation_errors.attachments.academic_certificate)}
          </Text>
        )}

        {requiresProfessionCertificates && (
          <ProfessionalCertificates
            professionalCertificates={professionalCertificates}
            setAttachments={setAttachments}
            attachments={attachments}
            professionalCertificatesErrors={applicationAttachmentsErrors.professional_certificates}
          />
        )}

        {requiresReferrals && <Referral />}

        {!loading && uploaded_cv && !isBarona && (
          <WhitelabelledHidden>
            <Card.Content>
              <PromoSection.PremiumCv />
            </Card.Content>
          </WhitelabelledHidden>
        )}
      </Card>

      <Row justify={isSm ? "center" : "flex-end"} style={{ padding: "24px 0" }}>
        <Col lg={2} xs={6}>
          <Button theme="grey200" width="100%" onClick={handleBack}>
            {t("global.back")}
          </Button>
        </Col>
        <Col lg={2} xs={6}>
          <Button
            color="primaryButtonLabelColor"
            processing={processing}
            width="100%"
            disabled={loading || !allowProgress}
            onClick={() => {
              updateAttachments(attachments).then(res => {
                if (res) {
                  handleBack();
                }
              });
            }}
          >
            {t("global.save_button")}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default observer(Attachments);
