import styled from "styled-components";

export const Title = styled.h4`
  color: #333;
  font-size: 20px;
  font-weight: 600;
  line-height: 130%;
`;

export const Label = styled.p`
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  max-width: 824px;
`;

export const ReferralContent = styled.div`
  margin: 12px 0 0;
  border-top: 1px solid #eee;
  padding: 28px 16px 8px;
`;

export const AddMoreContent = styled.div`
  margin: 24px 0 0;
  border-top: 1px solid #eee;
  padding: 24px 0px;
`;

export const ReferralButton = styled.button`
  border-radius: 5px;
  background: ${({ theme }) => theme.primary};
  padding: 12px;
  gap: 10px;
  display: block;
  max-width: 144px;
  margin-top: 24px;
  color: ${({ theme }) => theme.primaryButtonLabelColor};
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
  cursor: pointer;

  svg {
    width: 20px;
  }
`;

export const FormContent = styled.div`
  margin-top: 24px;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #c5c5c5;
`;

export const GreyButton = styled.button`
  display: flex;
  height: 40px;
  padding: 0px 20px;
  justify-content: center;
  align-items: center;
  width: 160px;
  color: #333;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
  border-radius: 5px;
  background: #eee;
  cursor: pointer;

  svg {
    width: 18px;
  }
`;

export const ReferralsContent = styled.div`
  margin-top: 24px;
`;
