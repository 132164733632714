import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import domtoimage from "dom-to-image";

import { Button, Card, Text } from "b2c/components/Core";
import careerCard from "images/career-hero-wide-sm.png";
import { LoadingContent } from "b2c/components/Core/Spinner";
import ShareButton from "b2c/components/ShareButton";
import useStore from "../../../../contexts/store";

const HeroThirdStep = ({ name, careerName, thank, email, setStep, setGeneratedToken, isSm }) => {
  const { addCareerHeroInformation } = useStore("CareerHero");
  const [imageUrl, setImageUrl] = useState(null);
  const [shareUrl, setShareUrl] = useState(null);
  const [imageLoad, setImageLoad] = useState(false);
  const cardRef = useRef(null);
  useEffect(() => {
    if (!imageUrl) {
      domtoimage
        .toJpeg(cardRef.current)
        .then(url => {
          setImageUrl(url);
          setImageLoad(true);
          return addCareerHeroInformation({
            career_hero: {
              email,
              name,
              message: thank,
              image: url
            }
          });
        })
        .then(data => {
          setShareUrl(`${location.origin}/nigeria/career-hero/${data?.career_hero?.token}`);
          setGeneratedToken(data?.career_hero?.token);
          setImageLoad(false);
        })
        .catch(error => {
          console.error("oops, something went wrong!", error);
        });
    }
  }, []);

  const handlePictureDownload = () => {
    if (imageUrl) {
      const a = document.createElement("a");
      a.href = imageUrl;
      a.download = "Career-hero-card.jpeg";
      a.click();
    }
  };

  const getNamePadding = length => {
    if (length > 95) return "3";
    if (length > 90) return "8";
    if (length > 80) return "15";
    return "30";
  };

  const getFontSize = length => {
    if (length > 250) return 17;
    if (length > 200) return 20;
    if (length > 160) return 22;
    if (length > 120) return 24;
    if (length > 80) return 26;

    return 28;
  };

  const getLineHeight = length => {
    if (length > 250) return "21px";
    if (length > 200) return "23px";
    if (length > 160) return "25px";
    if (length > 120) return "27px";
    if (length > 80) return "29px";
    return "31px";
  };

  return (
    <Card>
      <div style={{ margin: `${isSm ? "30" : "103"}px auto 50px auto`, textAlign: isSm ? "initial" : "center" }}>
        <Text
          fontSize={16}
          fontWeight={600}
          lineHeight="24px"
          width="100%"
          margin="0 0 15px 0"
          textAlign="center"
          style={{
            fontFamily: "Poppins",
            fontStyle: "normal",
            lineHeight: "24px",
            textAlign: "center"
          }}
        >
          Your thank you card
        </Text>

        <div
          ref={cardRef}
          style={{
            backgroundImage: `url(${careerCard})`,
            width: "468px",
            height: "263px",
            display: "inline-block",
            position: "relative"
          }}
        >
          <div>
            <Text
              fontSize={22}
              fontWeight={600}
              style={{
                position: "absolute",
                top: "20px",
                textAlign: "start",
                left: "20px",
                fontFamily: "Poppins"
              }}
            >
              Thank you {careerName}
            </Text>
            <Text
              fontSize={getFontSize(thank?.length)}
              lineHeight={getLineHeight(thank?.length)}
              width="100%"
              textAlign="center"
              style={{
                overflowWrap: "break-word",
                fontFamily: "Poppins",
                fontStyle: "normal",
                textAlign: "start",
                padding: "64px 20px 5px 20px"
              }}
            >
              {thank}
            </Text>
          </div>

          <div>
            <Text
              style={{
                padding: `${getNamePadding(thank?.length)}px 15px 0 0`,
                fontFamily: "Poppins",
                position: "absolute",
                bottom: "18px",
                left: "20px"
              }}
            >
              {name}
            </Text>
          </div>
        </div>

        <div style={{ textAlign: "center", marginTop: isSm ? "40px" : "60px" }}>
          <LoadingContent loading={imageLoad} size="40px">
            {shareUrl && (
              <ShareButton
                path={shareUrl}
                handlePictureDownload={handlePictureDownload}
                prefix="your card"
                Component={props => (
                  <Button
                    type="button"
                    fontSize="14px"
                    margin="margin: 101px 0 31px 0"
                    width={isSm ? "300px" : "468px"}
                    {...props}
                  >
                    Click to share
                  </Button>
                )}
              />
            )}
          </LoadingContent>
        </div>

        <div role="button" tabIndex={-1} onClick={() => setStep(2)} style={{ textAlign: "center", marginTop: "31px" }}>
          <Link as="a" to="/nigeria/career-hero">
            Go back to edit text
          </Link>
        </div>

        <div style={{ textAlign: "center", marginTop: isSm ? "10px" : "20px" }}>
          <Link to="/nigeria" as="a">
            Go to Fuzu
          </Link>
        </div>
      </div>
    </Card>
  );
};

export default HeroThirdStep;
