import React, { useContext } from "react";
import { Check } from "@styled-icons/boxicons-regular";
import { observer } from "mobx-react";
import { ScreenClassContext } from "react-grid-system";
import { OpenInNew } from "@styled-icons/material";
import parse from "html-react-parser";
import { useTranslation } from "b2c/hooks/useTranslation";

import { buildImageUrlS3, getCompanyName, isKepsa } from "b2c/contexts/branded";
import { Success, JobPropose, SuccessContent, TextContent, ButtonContent, DidYouKnow, SlideContent } from "./styled";
import { isWhitelabelled, WhitelabelledHidden } from "../../../../contexts/branded";
import { Text, Image, Title, Button } from "../../../../components/Core";
import Layout from "../../../../components/Layout";
import JobsSection from "../../../../components/FeedSections/JobsSection";
import useStore from "../../../../contexts/store";
import ThemeContext from "../../../../contexts/theme";
import { nameImages } from "../../../../constants/images";

const SuccessComponent = ({ detailInfo, isFastApply, isAssigmentRequested, employer }) => {
  const screen = useContext(ScreenClassContext);
  const { removeApplication } = useStore("Application");
  const isSm = /xs|sm/.test(screen);
  const whitelabelled = isWhitelabelled();
  const isKepsaBrand = isKepsa();

  const { base_domain } = useStore("initialState");
  const { protocol } = window.location;
  const theme = useContext(ThemeContext);
  const { t } = useTranslation();

  const handleRemoveApplication = () => {
    removeApplication(detailInfo?.id);
  };

  const renderTitle = () => {
    if (isFastApply) {
      if (isAssigmentRequested) {
        return t("application.success.title_1");
      }

      return t("application.success.title_2");
    }

    return t("application.success.title_3");
  };

  return (
    <SuccessContent>
      <Success>
        <JobPropose>
          <Image
            src={detailInfo?.company_logo || ""}
            style={{ width: "28px", height: "28px", marginRight: "14px", display: "block" }}
          />
          <div>
            <Text style={{ fontWeight: "600", fontSize: "14px", lineHeight: "140%", color: "#292929" }}>
              {detailInfo?.title}
            </Text>
            <Text style={{ fontWeight: "400", fontSize: "14px", lineHeight: "140%", color: "#3E3E3E" }}>
              {`${detailInfo?.company_name || ""} ${t("application.success.location_in")} ${
                detailInfo?.location || ""
              }`}
            </Text>
          </div>
        </JobPropose>

        <Image
          src={buildImageUrlS3(nameImages.applicationSuccess)}
          style={{ maxWidth: "100%", display: "block", textAlign: "center", margin: "0 auto 30px" }}
        />

        <Title
          style={{
            fontWeight: "600",
            fontSize: "24px",
            lineHeight: "150%",
            color: "#292929",
            marginBottom: "10px",
            textAlign: "center"
          }}
        >
          {renderTitle()}
        </Title>
        <TextContent>
          <Check width="24px" height="24px" fill="#25CB7B" />

          <Text
            style={{
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "130%",
              color: "#3E3E3E",
              display: "block",
              marginLeft: "12px"
            }}
          >
            {parse(t("application.success.description_1", { name: detailInfo?.company_name || "" }))}
          </Text>
        </TextContent>

        <TextContent>
          <Check width="24px" height="24px" fill="#25CB7B" />

          <Text
            style={{
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "130%",
              color: "#3E3E3E",
              display: "block",
              marginLeft: "12px"
            }}
          >
            {isFastApply ? t("application.success.description_2") : t("application.success.description_3")}
          </Text>
        </TextContent>

        <TextContent>
          <Check width="24px" height="24px" fill="#25CB7B" />

          <Text
            style={{
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "130%",
              color: "#3E3E3E",
              display: "block",
              marginLeft: "12px"
            }}
          >
            {parse(t("application.success.description_4", { name: getCompanyName() }))}
          </Text>
        </TextContent>
        <ButtonContent>
          <Button
            as="a"
            target={!isKepsaBrand && whitelabelled ? "_blank" : ""}
            href={!isKepsaBrand && whitelabelled ? `${protocol}//${base_domain}/job/applications` : "/job/applications"}
            onClick={handleRemoveApplication}
            theme="grey400"
            width={isSm ? "180px" : "300px"}
            maxWidth="350px"
            style={{
              flex: "none",
              fontWeight: 600,
              display: "block",
              marginRight: "12px",
              color: "#3E3E3E",
              textAlign: "center",
              padding: "10px"
            }}
            margin="0 12px 0 0"
          >
            {t("application.success.view_application")}
            {whitelabelled && <OpenInNew style={{ width: "16px", height: "16px", marginLeft: "10px" }} />}
          </Button>
          <Button.Link
            to={!isKepsaBrand && whitelabelled ? "/" : "/job"}
            theme="primary"
            onClick={handleRemoveApplication}
            width={isSm ? "170px" : "300px"}
            maxWidth="350px"
            style={{
              flex: "none",
              fontWeight: 600,
              display: "block",
              color: theme.primaryButtonLabelColor,
              textAlign: "center",
              padding: "10px"
            }}
          >
            {whitelabelled
              ? t("application.success.back_to_career_page", { name: employer.name })
              : t("application.success.back_to_job_search")}
          </Button.Link>
        </ButtonContent>
      </Success>
      <WhitelabelledHidden>
        <SlideContent>
          <Layout.Section backgroundColor="white">
            <Layout.Content size="xl" tag="div">
              <JobsSection
                filter="similar_to_job"
                title={t("application.success.similar_jobs")}
                count_limit={4}
                job_id={detailInfo?.id}
                titleProps={{ margin: "0" }}
                noFooter
              />
            </Layout.Content>
          </Layout.Section>
          <DidYouKnow>
            <span style={{ fontSize: "24px" }}>🔥</span>
            <Text style={{ fontSize: "16px", lineHeight: "140%", color: "#292929", marginLeft: "8px" }}>
              {parse(t("application.success.did_you_know"))}
            </Text>
          </DidYouKnow>
        </SlideContent>
      </WhitelabelledHidden>
    </SuccessContent>
  );
};

export default observer(SuccessComponent);
