import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "b2c/hooks/useTranslation";
import parse from "html-react-parser";
import styled from "styled-components";

import { capitalize } from "utils/helpers";
import ThemeContext from "../../contexts/theme";
import SearchInput from "../Core/SearchInput";
import Text from "../Core/Text";

const SkillSearch = ({ loading = true, list = [], selectedList = [], handleSearch, handleSelect }) => {
  const theme = useContext(ThemeContext);
  const [focused, setFocused] = useState(false);
  const [field, setField] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    if (field.length >= 3) handleSearch(field);
  }, [field]);

  const highlightField = name => {
    const highlighted =
      name.toLowerCase().indexOf(field) === 0
        ? name.replace(capitalize(field), `<span>${capitalize(field)}</span>`)
        : name.replace(field.toLowerCase(), `<span>${field.toLowerCase()}</span>`);

    return parse(highlighted);
  };

  return (
    <FilterWrapper theme={theme}>
      <SearchInput
        disabled={loading}
        placeholder={t("wizard.skills.search_placeholder")}
        value={field}
        onFocus={({ target }) => {
          target.select();
          setFocused(true);
        }}
        onChange={({ target: { value } }) => setField(value)}
        margin="0 0 15px 0"
        autoComplete="new-password"
        style={{
          padding: "0 10px 0 40px",
          borderRadius: "5px"
        }}
      />
      {field.length >= 3 && focused && (
        <div className="skill-menu">
          {list
            .slice()
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map(skill => (
              <SkillItem
                key={skill.name}
                theme={theme}
                onClick={() => {
                  handleSelect(skill);
                  setFocused(false);
                }}
              >
                <Text fontWeight={selectedList.some(({ id }) => id == skill.id) ? 600 : 400}>
                  {highlightField(skill.name)}
                </Text>
              </SkillItem>
            ))}
        </div>
      )}
    </FilterWrapper>
  );
};

const FilterWrapper = styled.div`
  ${({ theme }) => `
    width: 100%;
    position: relative;
    input:hover {
        background-color: ${theme.grey100};
    }
    .skill-menu {
        position: absolute;
        z-index: 2;
        border: 1px solid ${theme.grey200};
        background-color: ${theme.white};
        border-radius: 5px;
        font-size: 14px;
        overflow: hidden;
        max-height: 236px;
        overflow-y: auto;
        height: fit-content;
        display: flex;
        flex-direction: column;
        @media screen and (max-width: 767px) {
            margin: 8px 0 0;
            width: 100%;
        }
    }
`}
`;

const SkillItem = styled.div`
  ${({ theme }) => `
    padding: 10px 12px;
    cursor: pointer;
    color: ${theme.black300};
    min-width: 250px;
    span {
        background-color: ${theme.primary100};
    }
    &.selected {
        font-weight: 500;
        color: ${theme.black500};
    }
    &:hover {
        background-color: ${theme.primary100};
    }
`}
`;

export default SkillSearch;
