import React from "react";

import { Hidden } from "react-grid-system";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Check } from "@styled-icons/boxicons-regular";

import cvBuilderImage from "images/b2c/promo/cv-builder.png";
import { showDownloaded } from "utils/helpers";
import useStore from "../../contexts/store";
import { useImpressionTrack } from "../../hooks";
import { Card, Button, Image, Title, Text, Label } from "../Core";
import { useTranslation } from "../../hooks/useTranslation";
import { languageFormat } from "../../constants/main";

const PremiumCvPromo = ({
  title = "promo.premium_cv.title",
  children = "promo.premium_cv.description",
  forceDownload = false,
  redirectTo,
  onClick
}) => {
  const {
    Premium: { features: { premium_cv_creator } = {} },
    CvBuilder: {
      state: { processing },
      loadCv
    }
  } = useStore();
  const { track } = useStore("Tracking");
  const {
    push,
    location: { pathname }
  } = useHistory();
  const { path } = useRouteMatch();
  const { targetRef } = useImpressionTrack({ type: "premium_cv_creator" });
  const { t, language } = useTranslation();
  const languageFormatType = languageFormat[language];

  const handleCvDownload = () => {
    track("promo_clicked", { match_path: path, type: "premium_cv_creator" });
    if (premium_cv_creator.activated) {
      loadCv().then(url => {
        if (forceDownload) showDownloaded(url);
        else push({ pathname: "/cv-creator", state: { step: 3, from: redirectTo || pathname } });
      });
    } else push({ pathname: "/cv-creator", state: { from: redirectTo || pathname } });
    if (onClick) onClick();
  };

  return (
    <Card
      theme="grey900"
      ref={targetRef}
      wideOnMobile
      style={{ flexDirection: "row", justifyContent: "space-between", overflow: "hidden", minHeight: 180 }}
    >
      <Card.Content padding="24px 32px">
        <Title level={5} color="white" fontWeight={600} margin="0 0 4px">
          {t(title)}
        </Title>
        <Text color="white" margin="auto 0">
          {t(children)}
        </Text>
        <Button
          processing={processing || !premium_cv_creator}
          theme="secondary200"
          color="white"
          margin="4px 0 0"
          width="280px"
          onClick={handleCvDownload}
        >
          {t("promo.premium_cv.button")}
        </Button>
        {premium_cv_creator?.valid_to && (
          <Label
            Icon={<Check width={18} />}
            fontSize={14}
            fontWeight={500}
            noBackground
            theme="success200"
            padding={0}
            margin="8px 0 0"
          >
            {t("promo.premium_cv.label")}{" "}
            {new Date(premium_cv_creator?.valid_to).toLocaleString(languageFormatType, {
              day: "numeric",
              month: "short",
              year: "numeric"
            })}
          </Label>
        )}
      </Card.Content>
      <Hidden sm xs>
        <Image src={cvBuilderImage} height="150px" margin="auto 16px -8px" />
      </Hidden>
    </Card>
  );
};

export default PremiumCvPromo;
