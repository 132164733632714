import styled from "styled-components";

export const MobileAction = styled.div`
  @media screen and (max-width: 767px) {
    margin-top: 15px;
    > * {
      margin: 0;
    }
  }
  ${({ $styled }) => $styled}
`;
