require("../utils/defaults");
require("../utils/ssrHelpers");

if (!window.isContentPrerendered) {
  const componentRequireContext = require.context("b2c", true);
  const ReactRailsUJS = require("react_ujs");

  ReactRailsUJS.useContext(componentRequireContext);

  if (module.hot && !process.env.RAILS_ENV === "test") {
    module.hot.accept(["b2c/App"]);
  }
}
