import React, { useEffect, useLayoutEffect } from "react";
import { observer } from "mobx-react";

import useStore from "../../../../contexts/store";
import Layout from "../../../../components/Layout";
import Item from "../../../../components/FeedSections/CoursesSection/CoursesItem";
import { Text } from "../../../../components/Core";
import Section from "../../../../components/Section";
import { useDocumentTitle } from "../../../../../utils/hooks";
import { getCompanyName } from "../../../../contexts/branded";
import { useTranslation } from "../../../../hooks/useTranslation";

const LearningTab = ({ slug }) => {
  const {
    state: { loading },
    courses,
    getStatusCourses
  } = useStore("Learning");
  const { handleSaving } = useStore("Saved");
  const { t } = useTranslation();

  useLayoutEffect(() => {
    getStatusCourses(slug);
  }, [slug]);

  useEffect(() => {
    if (window.scrollY) {
      window.scroll(0, 0);
    }
  }, []);
  useDocumentTitle(getCompanyName());

  return (
    <Layout.Content size="xl" style={{ margin: "15px auto", minHeight: 980 }}>
      <Section expanded columns={4}>
        {(courses || [...new Array(8).fill({ loading: true })]).map((item, i) => (
          <Item
            key={`item-${i + 1}`}
            loading={loading}
            {...item}
            saveItem={saved => handleSaving(item.id, "course", saved)}
          />
        ))}
      </Section>
      {courses?.length === 0 && (
        <Text fontSize={18} fontWeight={600}>
          {t("learn.no_slug_courses", { slug })}
        </Text>
      )}
    </Layout.Content>
  );
};

export default observer(LearningTab);
