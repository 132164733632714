import React, { useContext } from "react";
import { Col, Row, ScreenClassContext } from "react-grid-system";
import { Redirect } from "react-router-dom";

import { useTranslation } from "b2c/hooks/useTranslation";
import { useScrollHandler } from "utils/hooks";
import promoImage from "images/b2c/service/promo_image.png";
import blockquoteImg from "images/b2c/service/visionImage2.png";
import BlockquoteSection from "b2c/components/BlockquoteSection";
import { withTrackedRoute } from "../../hocs";
import Layout from "../../components/Layout";
import { Image, Text, Title } from "../../components/Core";
import PromoSection from "./components/PromoSection";
import SliderSection from "./components/SliderSection";
import Sections from "../../components/FeedSections";
import { isBaronaBrand } from "../../contexts/branded";

const CareerScreen = () => {
  const screen = useContext(ScreenClassContext);
  const isSm = ["xs", "sm"].includes(screen);
  const isBarona = isBaronaBrand();
  const { t } = useTranslation();

  const { scrollHandler, targetRef } = useScrollHandler();

  if (isBarona) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <PromoSection onClick={scrollHandler} />

      <Layout.Section backgroundColor="white" padding="20px 0">
        <Layout.Content tag="div" size="xl">
          <Row align="center">
            <Col sm={6}>
              <Text fontSize={28} fontWeight={600} margin="0 0 15px">
                {t("careers.title")}
              </Text>
              <Text fontSize={20}>{t("careers.description")}</Text>
            </Col>
            <Col sm={6}>
              <Image src={promoImage} />
            </Col>
          </Row>
        </Layout.Content>
      </Layout.Section>

      <BlockquoteSection
        text={t("careers.vision.text")}
        author={t("careers.vision.author")}
        role={t("careers.vision.role")}
        img={blockquoteImg}
      />

      <Layout.Content tag="div" ref={targetRef} size="xl" style={{ padding: "20px" }}>
        <Title
          level="2"
          width="100%"
          textAlign="center"
          fontWeight={600}
          style={{ fontSize: isSm ? 20 : 40 }}
          margin="0 0 20px"
        >
          {t("careers.careers_at_fuzu")}
        </Title>

        <Sections.FeedSection type="jobs" filter="fuzu" count_limit={100} noHeader noFooter />
      </Layout.Content>

      <SliderSection />
    </>
  );
};

export default withTrackedRoute(CareerScreen);
