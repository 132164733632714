import styled from "styled-components";
import HomeScreenImageCycles from "images/app-home-screen-cycles.png";

export const HomeScreenContent = styled.div`
  position: relative;
  margin: 0 auto 15px;
  text-align: center;

  &:before {
    content: "";
    width: 330px;
    height: 310px;
    display: block;
    background-image: url(${HomeScreenImageCycles});
    position: absolute;
    left: 50%;
    margin-left: -165px;
    top: 70px;
    background-size: cover;
  }

  @media screen and (min-width: 1200px) {
    height: auto;
    margin: 0 0 0 auto;

    &:before {
      width: 380px;
      height: 360px;
      position: absolute;
      margin-left: -190px;
      top: 60px;
    }
  }

  @media screen and (min-width: 1440px) {
    &:before {
      width: 420px;
    }
  }
`;

export const image = {
  position: "relative",
  zIndex: "1"
};
