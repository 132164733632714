import React, { useContext, useState } from "react";

import { Row, Col, ScreenClassContext } from "react-grid-system";

import { Card, Checkbox, Image, Text } from "../../Core";

const Interests = ({ list = [] }) => {
  const screen = useContext(ScreenClassContext);
  const isSm = /xs|sm/.test(screen);
  const [selected, setSelected] = useState([]);

  const handleSelect = e => {
    const value = +e.target.value;
    setSelected(prev => (prev.includes(value) ? prev.filter(item => item != value) : prev.concat(value)));
  };

  return (
    <Card theme="grey100">
      <Card.Content>
        <Row>
          {list.map(({ id, name, image_url }) => (
            <Col key={id} lg={6} sm={12} style={{ display: "flex" }}>
              <Checkbox
                id={id}
                name="interest"
                value={id}
                borderedView
                width="100%"
                margin="6px 0"
                onChange={handleSelect}
                checked={selected.includes(id)}
                checkedColor="white"
                checkedBgColor="white"
                markColor="black500"
                $markMargin={isSm ? "auto 0" : "0"}
                labelProps={{ style: { padding: 8 } }}
                style={{
                  outline: "none"
                }}
                label={
                  <Row nowrap={isSm} style={{ margin: isSm ? 0 : "0 0 0 -24px" }}>
                    <Col width={isSm ? "auto" : "100%"} style={{ display: "flex" }}>
                      <Image
                        src={image_url}
                        borderRadius="100%"
                        width={isSm ? 48 : 100}
                        height={isSm ? 48 : 100}
                        margin={isSm ? "auto" : "24px auto 12px"}
                      />
                    </Col>
                    <Col width="100%" style={{ display: "flex" }}>
                      <Text margin={isSm ? "auto" : "0 auto"} textAlign="center" fontWeight={600}>
                        {name}
                      </Text>
                    </Col>
                  </Row>
                }
              />
            </Col>
          ))}
        </Row>
      </Card.Content>
    </Card>
  );
};

export default Interests;
