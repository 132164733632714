import styled from "styled-components";

export const StyledInfoList = styled.div`
  margin-bottom: 15px;

  :not(:last-child) {
    margin-bottom: 24px;
  }

  .infolist-col {
    @media screen and (max-width: 576px) {
      display: flex;
      margin-bottom: 5px;
      p {
        flex: 0 0 50%;
        margin: 0;
      }
    }
    &:not(:last-child) {
      @media screen and (min-width: 576px) {
        border-right: 1px solid ${({ theme }) => theme.grey200};
      }
    }
  }
`;
