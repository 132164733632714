import styled from "styled-components";

export const StyledButton = styled.div`
  ${({ theme, active }) => `
        display: flex;
        flex-direction: column;
        padding: 12px 0;
        height: 100px;
        align-items: center;
        justify-content: space-around;
        background-color: ${active ? theme.secondary100 : theme.white};
        border-radius: 5px;
        border: 1px solid ${active ? theme.secondary200 : theme.grey300};
        text-align: center;
        font-size: 14px;
        color: ${active ? theme.secondary300 : theme.black500};
        cursor: pointer;
        user-select: none;
        transition: .2s ease;

        &:hover {
            border-color: ${theme.secondary200};
            color: ${theme.secondary300};
        }

        &:focus {
            border-color: ${theme.secondary200};
            outline: none;
        }

        @media screen and (max-width: 767px) {
            font-size: 12px;
        }
    `}
`;

export const PaymentMethodWrapper = styled.div`
  margin-top: 40px;

  > .method-group {
    display: flex;
    margin: 0 0 16px;
    width: 100%;

    ${StyledButton} {
      &:not(:last-child) {
        margin-right: 6px;
      }
      width: 100%;
    }
  }

  .method-actions {
    margin-top: 16px;
  }
`;

export const TitleIconHolder = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 auto;
`;
