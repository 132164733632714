import styled from "styled-components";

export const PromoSectionWrapper = styled.section`
  background-color: ${({ backgroundColor }) => backgroundColor || "#fff"};
  padding-top: ${({ paddingTop }) => paddingTop || "50px"};
  @media screen and (max-width: 767px) {
    text-align: center;
    padding: 20px 0 0;
    margin: 0 -20px;
  }
`;

export const Icon = styled.span`
  height: 24px;
  path {
    fill: ${({ iconColor }) => iconColor || "#000"};
  }
`;
