import React from "react";
import { useTranslation } from "b2c/hooks/useTranslation";

import BaronaLogo from "images/barona-logo.svg";
import { Text } from "../Core";
import { BaronaLogoContainer } from "./styled";

const ConfirmedStep = () => {
  const { t } = useTranslation();

  return (
    <div style={{ textAlign: "center", margin: "40px 0" }}>
      <BaronaLogoContainer>
        <BaronaLogo />
      </BaronaLogoContainer>
      <Text fontWeight="500" fontSize="18px" style={{ color: "#292929" }} width="inherit">
        {t("companies.contact_us_modal.success_title")}
      </Text>
      <Text fontWeight="400" fontSize="14" style={{ color: "#3E3E3E" }} width="inherit">
        {t("companies.contact_us_modal.success_description")}
      </Text>
    </div>
  );
};

export default ConfirmedStep;
