import styled from "styled-components";

export const Content = styled.div`
  max-width: 680px;
  width: 100%;
  margin: 56px auto 120px auto;
  padding: 0 16px;

  @media screen and (min-width: 768px) {
    padding: 0;
  }
`;

export const Title = styled.h2`
  color: #212121;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  margin-bottom: 8px;
`;

export const Description = styled.p`
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  display: block;
  margin-bottom: 24px;
`;

export const Divider = styled.span`
  display: block;
  height: 1px;
  width: 100%;
  background-color: #c5c5c5;
  margin: 24px 0 32px 0;
`;

export const Input = styled.input`
  padding: 11px 12px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #cdcdcd;
  color: #212121;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  display: block;
  width: 100%;

  &::placeholder {
    color: #8f8f8f;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
  }
`;

export const TextArea = styled.textarea`
  padding: 10px 12px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #cdcdcd;
  color: #212121;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  height: 144px;
  display: block;
  width: 100%;
  resize: none;

  &::placeholder {
    color: #8f8f8f;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
  }
`;

export const Label = styled.span`
  color: #292929;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
`;

export const Tile = styled.div`
  border-radius: 5px;
  border: 1px solid #c5c5c5;
  background: #fff;
  padding: 15px 016px;
  margin-bottom: 16px;

  & > div {
    margin: 0;
    outline: none;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 10px;
  right: 20px;
  display: flex;
  align-items: center;
`;

export const Heading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  height: 60px;
  padding: 0 20px;
  color: #212121;
  font-size: 16px;
  font-weight: 600;
  line-height: 140%;
`;

export const NavWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100px;
  background: #fff;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 11;
`;

export const Error = styled.span`
  color: #c30000;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  display: block;
  margin: 8px 0 0 0;
`;
