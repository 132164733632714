import React from "react";
import { useTranslation } from "b2c/hooks/useTranslation";

import atea from "images/b2c/barona/atea-logo.png";
import firstbeat from "images/b2c/barona/firstbeat-logo.png";
import santa from "images/b2c/barona/santa-claus-logo.png";
import skoda from "images/b2c/barona/skoda-logo.png";
import { Container, Content, Title, Companies, CompaniesItem } from "./styled";

const Customers = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Content>
        <Title>{t("companies.join")}</Title>
        <Companies>
          <CompaniesItem>
            <img src={skoda} alt="skoda" />
          </CompaniesItem>
          <CompaniesItem>
            <img src={atea} alt="atea" />
          </CompaniesItem>
          <CompaniesItem>
            <img src={santa} alt="santa" />
          </CompaniesItem>
          <CompaniesItem>
            <img src={firstbeat} alt="firstbeat" />
          </CompaniesItem>
        </Companies>
      </Content>
    </Container>
  );
};

export default Customers;
