import React, { useRef, useEffect, useMemo, useContext } from "react";
import { observer } from "mobx-react";
import { Switch, Route, Redirect, Link, useLocation } from "react-router-dom";

import { OnRouteChange, useQueryParams } from "utils/hooks";
import Login from "./components/Login";
import Registration from "./components/Registration";
import PasswordReset from "./components/PasswordReset";
import PasswordUpdate from "./components/PasswordUpdate";
import LeftSide from "./components/LeftSide";
import SocialAuthorization from "../../components/SocialAuthorization";
// TODO remove
// import OnboardingSurvey from "../OnboardingSurvey";
// import OnboardingFlow from "../../components/OnboardingFlow";
import Onboarding from "../Onboarding";
import { Text } from "../../components/Core";
import { AuthWrapper, AuthRightContainer, AuthRightContent } from "./styled";
import BrandedContext, {
  isCustomPlatform,
  isCustomJkuat,
  isKepsa,
  isWhitelabelledOrJkuat,
  isWhitelabelled
} from "../../contexts/branded";
import { useNotification } from "../../../utils/hooks";
import useStore from "../../contexts/store";
import ServerSide from "../../contexts/ssr";
import { useStandaloneMode } from "../../contexts/standalone";
import { useIsBarona } from "../../hooks";

const AuthRoutes = ({ countryPaths }) => {
  const {
    state,
    user,
    Authorization: {
      validation_errors,
      userLogin,
      userRegister,
      userDetails,
      countries,
      getCountries,
      ncpwdValidateUser,
      userResetPassword,
      userUpdatePassword,
      checkEmail,
      ncpwdLogin,
      resetValidationErrors
    },
    resetUser,
    updateUserStateMessage
  } = useStore("User");
  const { feature_toggles: { new_registration_flow } = {} } = useStore("initialState");
  const { isNewRegistrationFlow } = useStore("User");

  const { state: locationState = {} } = useLocation();
  const { refer_code: referCode, return_to } = useQueryParams();
  const customPlatform = isCustomPlatform();
  const whitelabelledOrJkuat = isWhitelabelledOrJkuat();
  const customJkuat = isCustomJkuat();
  const isKepsaBrand = isKepsa();
  const { ssr } = useContext(ServerSide);
  const { slug } = useContext(BrandedContext);
  const fromUrl = useRef();
  const redirectUrl = whitelabelledOrJkuat ? "/" : "/home";

  const notifyMnt = useNotification(
    <Text margin="auto" color="white" fontWeight={500}>
      You just received 400 Fuzu points for registering to Fuzu through MTN Pulse. Read more about the points and how to
      use them on{" "}
      <Link className="link underlined inherit" to="/points">
        Fuzu points page
      </Link>
      .
    </Text>,
    "success300"
  );

  useEffect(() => {
    if (locationState?.from || return_to) {
      fromUrl.current = return_to || locationState?.from;
    }
  }, [locationState?.from, return_to]);

  const initialSignupStep = useMemo(() => {
    if (customPlatform && slug === "ncpwd" && (!user || !user.registration_completed)) return 0;
    if (customPlatform && slug === "ncpwd" && (!user || !user.registration_completed)) return 0;
    if (customJkuat && (!user || !user.registration_completed)) return 1;
    if (!user || user.ncpwd_data || slug === "demo") return 1;
    if (!user.registration_completed) return 2;
    return 3;
  }, [user]);

  const resetAuthorizationState = () => {
    resetValidationErrors();
    updateUserStateMessage();
  };

  const registrationProps = {
    state,
    first_name: user?.first_name || "",
    email: user?.email || locationState?.email,
    country: user?.country,
    getCountries,
    initialStep: initialSignupStep,
    validation_errors,
    countries,
    userRegister,
    userDetails,
    resetUser,
    ncpwdValidateUser,
    checkEmail,
    ncpwdLogin,
    resetValidationErrors,
    fromUrl: fromUrl?.current,
    notifyMnt
  };
  const isStandalone = useStandaloneMode();
  const whitelabelled = isWhitelabelled();
  const isBarona = useIsBarona();

  const { LinkedInPopUp } = ssr ? () => null : require("react-linkedin-login-oauth2");

  return (
    <Route
      path={countryPaths
        ?.map(({ slug: countryLanguagePath }) => [
          "/login",
          `/${countryLanguagePath}/login`,
          "/signin",
          "/reset",
          "/signup",
          `/${countryLanguagePath}/signup`,
          "/onboarding",
          "/onboarding",
          "/onboarding/:id",
          "/pro/auth/linkedin/callback",
          "/pro/password/edit"
        ])
        .flat()}
    >
      {/*
      path={[
        "/login",
        "/signin",
        "/reset",
        "/signup",
        "/onboarding",
        "/onboarding",
        "/onboarding/:id",
        "/pro/auth/linkedin/callback",
        "/pro/password/edit"
      ]}
      */}
      {!user?.onboarding_steps_completed ? (
        <Switch>
          {/* path={["/login", "/reset", "/signup", "/pro/auth/linkedin/callback", "/pro/password/edit"]} */}
          <Route
            path={countryPaths
              ?.map(({ slug: countryLanguagePath }) => [
                "/login",
                `/${countryLanguagePath}/login`,
                "/reset",
                "/signup",
                `/${countryLanguagePath}/signup`,
                "/pro/auth/linkedin/callback",
                "/pro/password/edit"
              ])
              .flat()}
          >
            <AuthWrapper isStandalone={isStandalone}>
              <LeftSide />
              {new_registration_flow && (
                <Route
                  exact
                  // path={["/signup"]}
                  path={countryPaths
                    ?.map(({ slug: countryLanguagePath }) => ["/signup", `/${countryLanguagePath}/signup`])
                    .flat()}
                  component={() => {
                    localStorage.removeItem("onboardingForm");
                    localStorage.setItem("onboardingCurrentStep", "1");
                    localStorage.removeItem("referCode");
                    if (referCode) {
                      localStorage.setItem("referCode", referCode);
                    }
                    // return <Redirect to="/onboarding" />;
                  }}
                />
              )}
              <AuthRightContainer isStandalone={isStandalone}>
                <AuthRightContent>
                  <OnRouteChange callback={resetAuthorizationState}>
                    <Switch>
                      <Route
                        exact
                        // path={["/login", "/login/:slug"]}
                        path={countryPaths
                          ?.map(({ slug: countryLanguagePath }) => [
                            "/login",
                            `/${countryLanguagePath}/login`,
                            "/login/:slug",
                            `/${countryLanguagePath}/login/:slug`
                          ])
                          .flat()}
                        component={props => {
                          if (
                            user &&
                            !user.onboarding_steps_completed &&
                            (customJkuat || isKepsaBrand) &&
                            fromUrl.current
                          ) {
                            setTimeout(() => {
                              window.location.reload();
                            }, 100);
                            return <Redirect to={fromUrl.current} />;
                          }

                          if (user && !user.onboarding_steps_completed && (customJkuat || isKepsaBrand)) {
                            setTimeout(() => {
                              window.location.reload();
                            }, 10);

                            return <Redirect to="/home" />;
                          }
                          if (
                            user &&
                            !user.onboarding_steps_completed &&
                            !customJkuat &&
                            whitelabelledOrJkuat &&
                            !isKepsaBrand
                          ) {
                            return <Redirect to="/signup" />;
                          }

                          if (
                            user &&
                            !user.onboarding_steps_completed &&
                            !customJkuat &&
                            !isKepsaBrand &&
                            !whitelabelledOrJkuat
                          ) {
                            return <Redirect to="/onboarding" />;
                          }
                          return (
                            <Login
                              state={state}
                              isCustomPlatform={customPlatform}
                              userLogin={userLogin}
                              notifyMnt={notifyMnt}
                              {...props}
                            />
                          );
                        }}
                      />
                      <Route exact path="/reset">
                        <PasswordReset state={state} userResetPassword={userResetPassword} />
                      </Route>
                      <Route exact path="/pro/password/edit">
                        <PasswordUpdate
                          state={state}
                          validation_errors={validation_errors}
                          userUpdatePassword={userUpdatePassword}
                        />
                      </Route>
                      <Route exact path={["/signup/:slug"]}>
                        <Registration {...registrationProps} />
                      </Route>
                      {!new_registration_flow && (
                        <Route exact path={["/signup"]}>
                          <Registration {...registrationProps} />
                        </Route>
                      )}
                    </Switch>
                    {!isBarona && (
                      <>
                        <Switch>
                          <Route
                            exact
                            path={["/login", "/signup"]}
                            component={() => (
                              <SocialAuthorization
                                isStandalone={isStandalone}
                                label={isStandalone ? "login.or_continue_with" : "login.or_login_with_social"}
                              />
                            )}
                          />
                          <Route
                            exact
                            path={["/login/:slug", "/signup/:slug"]}
                            component={() => (
                              <SocialAuthorization label="login.or_login_with_social" onSuccess={notifyMnt} />
                            )}
                          />
                        </Switch>
                      </>
                    )}

                    <Route exact path="/pro/auth/linkedin/callback" component={LinkedInPopUp} />
                  </OnRouteChange>
                </AuthRightContent>
              </AuthRightContainer>
            </AuthWrapper>
          </Route>
          {isNewRegistrationFlow ? (
            <Route path={["/onboarding", "/onboarding/:id", "/signup"]} component={Onboarding} />
          ) : (
            <Route
              exact
              path={["/onboarding", "/onboarding/:id"]}
              component={() =>
                user && !user.onboarding_steps_completed && (!whitelabelled || isKepsaBrand) && !customJkuat ? (
                  <Onboarding />
                ) : (
                  /*  
                <OnboardingSurvey
                    isCustomPlatform={customPlatform}
                    fromUrl={fromUrl.current || redirectUrl}
                    setRedirectUrl={url => {
                      fromUrl.current = url;
                    }}
                    {...props}
                  />
                  */
                  <Redirect to={`/signup${fromUrl.current ? `?return_to=${fromUrl.current}` : ""}`} />
                )
              }
            />
          )}
        </Switch>
      ) : (
        <Redirect to={fromUrl.current || redirectUrl} />
      )}
    </Route>
  );
};

export default observer(AuthRoutes);
