import React, { useLayoutEffect } from "react";
import { observer } from "mobx-react";

import { getCompanyName } from "b2c/contexts/branded";
import { Text } from "../../../../components/Core";
import FileUploader from "../../../../components/FileUploader";
import PromoSection from "../../../../components/PromoSection";
import useStore from "../../../../contexts/store";
import { StyledModalContent } from "./styled";
import { useTranslation } from "../../../../hooks/useTranslation";

const GenerateCvModalContent = ({ close }) => {
  const {
    Profile: {
      state: { processing },
      profile: { uploaded_cv } = {},
      getGeneralInfo,
      uploadCv
    }
  } = useStore("User");
  const { t } = useTranslation();

  useLayoutEffect(() => {
    if (!uploaded_cv) getGeneralInfo();
  }, []);

  return (
    <StyledModalContent>
      <FileUploader
        processing={processing}
        cardProps={{ shadow: true, wideOnMobile: true, style: { margin: "0 0 15px" } }}
        title={uploaded_cv?.url ? t("profile.cv.my_cv_title") : t("profile.cv.cv_document_title")}
        name={uploaded_cv?.original_filename || t("profile.cv.upload_button")}
        accept=".pdf, .doc, .docx"
        url={uploaded_cv?.url}
        buttonText={t("wizard.upload.choose_file")}
        onUpload={uploadCv}
        emptyComponent={
          <Text margin="0">{t("jobs.instructions.cv_modal.empty_text", { name: getCompanyName() })}</Text>
        }
        autoFocus
      />
      <PromoSection.PremiumCv onClick={close} />
    </StyledModalContent>
  );
};

export default observer(GenerateCvModalContent);
