import React, { useContext } from "react";
import { observer } from "mobx-react";

import { Row, Col, Hidden } from "react-grid-system";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";

import { brandName } from "b2c/constants/brand";
import { useTranslation } from "b2c/hooks/useTranslation";
import { FacebookSquare, Google, LinkedinSquare } from "@styled-icons/boxicons-logos";
import { Link, useHistory, useParams } from "react-router-dom";
import { Text, Button } from "b2c/components/Core";
import { BrandHidden, WhitelabelledHidden } from "../../contexts/branded";
import useStore from "../../contexts/store";
import ServerSide from "../../contexts/ssr";
import { Label } from "./styled";

const SocialAuthorization = ({
  label = "login.or_login_with_social",
  modal = false,
  close,
  onSuccess,
  isStandalone
}) => {
  const { ssr } = useContext(ServerSide);
  const { slug: channel } = useParams();
  const history = useHistory();

  const {
    Authorization: { responseOAuth, responseOAuthError }
  } = useStore("User");
  const { buildOnboardingFlowBody, confirmEmail } = useStore("Onboarding");
  const { t } = useTranslation();

  const handleSuccess = success => {
    if (success && onSuccess) {
      onSuccess();
    }

    if (success && close) {
      close();
    }
  };

  const handleOAuth = (provider, code) => {
    const { user, referCode } = buildOnboardingFlowBody();
    const context = {
      source: modal ? "login_prompt" : "login_page",
      channel,
      details: user,
      referCode
    };

    responseOAuth(provider, code, context)
      .then(data => {
        if (data.state === "registered") {
          confirmEmail({ user: { email: data.user.email } }).then(res => {
            handleSuccess(res?.data?.user?.registration_completed);
          });

          return;
        }

        handleSuccess(data?.user?.registration_completed);
      })
      .catch(error => {
        const data = error?.response?.data;
        if (data?.state === "redirect_to_onboarding") history.push("/onboarding");
      });
  };

  const { LinkedIn } = ssr ? () => null : require("react-linkedin-login-oauth2");

  return (
    <BrandHidden brand={["ncpwd", brandName.jkuat, "demo"]}>
      <WhitelabelledHidden>
        {!isStandalone && (
          <>
            {!modal && <div className="page-separator" style={{ margin: "12px 0 30px" }} />}

            <Text lineHeight="16px" margin="0 0 16px" fontWeight={600}>
              {t(label)}
            </Text>
          </>
        )}

        {isStandalone && (
          <Label>
            <span>{t(label)}</span>
          </Label>
        )}

        <Row nowrap>
          <Col>
            <FacebookLogin
              appId={process.env.FB_APP_ID}
              fields="first_name, last_name, email"
              callback={() => handleOAuth("facebook")}
              onFailure={responseOAuthError}
              redirectUri={decodeURIComponent(window.location.href)}
              disableMobileRedirect
              cookie
              render={({ onClick }) => (
                <Button
                  height="48px"
                  width="100%"
                  color="white"
                  onClick={onClick}
                  icon={<FacebookSquare width="24px" />}
                  style={{ backgroundColor: "#3E5893" }}
                >
                  {!modal && <Hidden xs>Facebook</Hidden>}
                </Button>
              )}
            />
          </Col>
          <Col>
            <GoogleLogin
              clientId={process.env.GOOGLE_CLIENT_ID}
              onSuccess={({ accessToken }) => handleOAuth("google", accessToken)}
              onFailure={responseOAuthError}
              scope="email profile"
              cookiePolicy="single_host_origin"
              responseType="token"
              accessType="online"
              render={({ onClick }) => (
                <Button
                  height="48px"
                  width="100%"
                  color="white"
                  icon={<Google width="24px" />}
                  style={{ backgroundColor: "#DA5140" }}
                  onClick={onClick}
                >
                  {!modal && <Hidden xs>Google</Hidden>}
                </Button>
              )}
            />
          </Col>
          <Col>
            <LinkedIn
              clientId={process.env.LINKEDIN_CLIENT_ID}
              redirectUri={`${window.location.origin}/pro/auth/linkedin/callback`}
              onSuccess={({ code }) => handleOAuth("linkedin", code)}
              onFailure={responseOAuthError}
              scope="r_emailaddress r_liteprofile"
              renderElement={({ onClick }) => (
                <Button
                  height="48px"
                  width="100%"
                  color="white"
                  icon={<LinkedinSquare width="24px" />}
                  style={{ backgroundColor: "#327BB8" }}
                  onClick={onClick}
                >
                  {!modal && <Hidden xs>LinkedIn</Hidden>}
                </Button>
              )}
            />
          </Col>
        </Row>

        {isStandalone && (
          <Text lineHeight="140%" margin="32px auto" color="grey700">
            Not a Fuzu member yet?{" "}
            <Link
              className="link"
              onClick={() => {
                window.location.href = "/signup";
              }}
              color="#408BFC"
            >
              {t("login.create_account_button")}
            </Link>
          </Text>
        )}
      </WhitelabelledHidden>
    </BrandHidden>
  );
};

export default observer(SocialAuthorization);
