import React, { useContext, useState } from "react";
import { EyeSlashFill, EyeFill } from "@styled-icons/bootstrap";

import { inputTypes } from "b2c/constants/main";
import { useTranslation } from "b2c/hooks/useTranslation";
import { validateEmail } from "../../../../../utils/validation";
import {
  Tile,
  TileTitle,
  TileDescription,
  TileButton,
  TileInput,
  TileInputContainer,
  TileContent,
  Actions,
  ActionsCancel,
  ActionsSubmit,
  TileInputPasswordButton,
  InputError
} from "../../styled";
import { Label, InputDescription } from "./styled";
import ThemeContext from "../../../../contexts/theme";

const UpdateEmailSection = ({
  processing,
  userEmail,
  updateEmail,
  customAuthenticationUrl,
  validation_errors = {
    email: []
  }
}) => {
  const formatError = error => (error ? error.join(", ") : "");
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);
  const [isShowForm, setIsShowForm] = useState(false);
  const [passwordType, setPasswordType] = useState(inputTypes.password);
  const [formValues, setFormValues] = useState({
    email: "",
    password: ""
  });
  const { test: emailError } = validateEmail(formValues.email);

  const handleShowForm = () => {
    setIsShowForm(true);
  };

  const handleHideForm = () => {
    setIsShowForm(false);
  };

  const handleChangeForm = event => {
    setFormValues(prev => {
      return {
        ...prev,
        [event.target.name]: event.target.value
      };
    });
  };

  const handleChangePasswordType = () => {
    setPasswordType(prev => (prev === inputTypes.password ? inputTypes.text : inputTypes.password));
  };

  const handleSubmitEmail = () => {
    updateEmail({ email: formValues.email, password: formValues.password }).then(result => {
      if (result) {
        handleHideForm();
        setFormValues({
          email: "",
          password: ""
        });
      }
    });
  };

  return (
    <Tile>
      <TileContent>
        <TileTitle>{t("settings.email.title")}</TileTitle>
        <TileDescription>
          {t("settings.email.your_email")}:<span>{userEmail}</span>
        </TileDescription>
        {isShowForm ? (
          <>
            <Label>{t("settings.email.new_email_label")}</Label>
            <InputDescription>{t("settings.email.new_email_description")}</InputDescription>
            <TileInput
              name="email"
              value={formValues.email}
              placeholder={userEmail}
              type="email"
              onChange={handleChangeForm}
              $isError={formatError(validation_errors.email)}
            />

            <Label>{t("settings.email.password_label")}</Label>
            <InputDescription>{t("settings.email.password_description")}</InputDescription>
            <TileInputContainer>
              <TileInputPasswordButton onClick={handleChangePasswordType}>
                {passwordType === inputTypes.password ? <EyeSlashFill /> : <EyeFill />}
              </TileInputPasswordButton>

              <TileInput
                $marginBottom="0"
                $isError={validation_errors.password}
                name="password"
                value={formValues.password}
                type={passwordType}
                onChange={handleChangeForm}
              />
            </TileInputContainer>
            {validation_errors.password && <InputError>{t("settings.email.password_error")}</InputError>}

            <Actions>
              <ActionsCancel onClick={handleHideForm}>{t("global.cancel")}</ActionsCancel>
              <ActionsSubmit
                disabled={!emailError || !formValues.password || processing}
                $background={theme.primaryButtonBackground}
                $color={theme.primaryButtonLabelColor}
                onClick={handleSubmitEmail}
              >
                {t("settings.email.submit_button")}
              </ActionsSubmit>
            </Actions>
          </>
        ) : (
          <TileButton $disabled={customAuthenticationUrl} onClick={handleShowForm}>
            {t("settings.email.change_my_email_button")}
          </TileButton>
        )}
      </TileContent>
    </Tile>
  );
};

export default UpdateEmailSection;
