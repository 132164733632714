import React, { useEffect, useState, useContext } from "react";
import { observer } from "mobx-react";
import { Row, Col, ScreenClassContext } from "react-grid-system";
import { useLocation } from "react-router-dom";
import { PlusCircle, Trash } from "@styled-icons/boxicons-regular";
import { toJS } from "mobx";

import { useTranslation } from "b2c/hooks/useTranslation";
import SvgContent from "b2c/components/SvgContent";
import MainInformationIco from "images/b2c/profilePage/MainInformationIco.svg";
import { capitalize } from "utils/helpers";
import useStore from "../../contexts/store";
import { Card, Text, Input, Badge, Button, Dropdown } from "../Core";
import AvatarUploader from "../AvatarUploader";
import SubPageSection from "../SubPageSection";
import ThemeContext from "../../contexts/theme";
import { languageFormat } from "../../constants/main";
import SelectSearch from "../../pages/JobSearch/components/SelectSearch";
import { CountryContainer, DropdownContent } from "./styled";

const replaceAll = require("string.prototype.replaceall");

const PersonalInformation = props => {
  const {
    Profile: {
      state: { loading, processing },
      profile: {
        personal_info: {
          first_name,
          last_name,
          date_of_birth,
          avatar,
          avatar_uploaded,
          phone = {},
          country = {},
          city = "",
          city_id = "",
          gender = "",
          social_links,
          geoname
        } = {}
      } = {},
      personal_options: {
        phone_codes: { common = [], priorities = [] } = {},
        genders = [],
        social_link_types = []
        // countries = []
      } = {},
      validation_errors,
      initializePersonalInfo,
      deleteUserAvatar,
      updatePersonalInfo
    }
  } = useStore("User");
  const { getCommonLocations, getCommonCities, commonCountries, commonCities } = useStore("JobSearch");

  const location = useLocation();
  const { inProfileWizard } = props;
  const Theme = useContext(ThemeContext);
  const screen = useContext(ScreenClassContext);
  const isXs = screen === "xs";
  const isSm = ["xs", "sm", "md"].includes(screen);
  const [socialItems, setSocialItems] = useState([]);
  const [selectedCode, setCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState();
  const [selectedDate, setDate] = useState();
  const [photo, setPhoto] = useState();
  const isWizardPage = location.pathname === "/wizard";
  const isScrollToError = isSm && isWizardPage;
  const { global_brand } = useStore("initialState");
  const { t, language } = useTranslation();
  const languageFormatType = languageFormat[language];
  const [countrySearchValue, setCountrySearchValue] = useState("");
  const [activeCountry, setActiveCountry] = useState("");
  const [citySearchValue, setCitySearchValue] = useState("");
  const [activeCity, setActiveCity] = useState("");

  useEffect(() => {
    initializePersonalInfo(true);
  }, []);

  useEffect(() => {
    if (!activeCity && geoname?.name) {
      setActiveCity({ name: geoname.name, id: geoname.id });
      setCitySearchValue(geoname.name);
    }

    if (!activeCity && !geoname?.name && city_id && city_id) {
      setActiveCity({ name: city, id: city_id });
      setCitySearchValue(city);
    }

    if (!activeCountry && toJS(country)?.id) {
      setActiveCountry({ id: toJS(country).id, name: toJS(country).name });
      setCountrySearchValue(toJS(country).name);
    }
  }, [geoname?.name, country?.name]);

  useEffect(() => {
    setCode(phone?.code);
    setPhoneNumber(phone?.number);
  }, [phone]);

  useEffect(() => {
    setDate(formatDate(date_of_birth));
  }, [date_of_birth]);

  useEffect(() => {
    if (social_links && social_links.length > 0) setSocialItems(social_links);
  }, [social_links]);

  const handleSocialLinkChange = (e, index, action) => {
    e.persist();
    const newSocial = Object.assign([], socialItems, {
      [index]: { ...socialItems[index], url: e.target.value }
    });
    setSocialItems(newSocial);
    action(e, newSocial);
  };

  const removeSocialLink = id => {
    setSocialItems(prev => prev.filter(item => item.id != id));
  };

  const handleDateChange = (e, value) => {
    setDate(prev => ({ ...prev, [e.target.name]: value || e.target.value }));
  };

  const formatDate = date =>
    date
      ? {
          year: +date.substring(0, date.indexOf("-", 0)),
          month: +date.substring(date.indexOf("-", 0) + 1, date.indexOf("-", 5)),
          day: +date.substring(date.length, date.indexOf("-", 7) + 1)
        }
      : {};

  const formatError = error => (error ? capitalize(error.join(", ")) : null);

  const getMonth = value => (months.some(m => m.value == value) ? months.find(m => m.value == value).name : "");

  const months = Array.from({ length: 12 }, (_, i) => ({
    name: new Date(null, i + 1, null).toLocaleString(languageFormatType, { month: "long" }),
    value: i + 1
  }));

  const codes = priorities.concat(common);

  const defaultCode = (() => {
    const codeByPhone = codes.find(item => item.phone_code == phone.code);
    const codeByCountry = codes.find(item => item?.name == country?.name);
    const item = codeByPhone || codeByCountry;
    if (!selectedCode && item) {
      setCode(item.phone_code);
    }
    return item ? `${item.name} (${item.phone_code})` : null;
  })();

  const dynamicFields = {
    date: selectedDate && Object.keys(selectedDate)?.length ? selectedDate : null,
    phone: {
      code: selectedCode || phone.code,
      number: phoneNumber
    },
    social_links: socialItems
  };

  const initialFields = {
    gender,
    country_id: country?.id,
    first_name,
    last_name,
    city,
    city_id: geoname?.id || city_id
  };

  const personalFields = ["gender", "date"];
  const requiredFields = ["gender", "date", "phone", "first_name", "last_name", "country_id"].filter(field => {
    return false || !personalFields.includes(field);
  });

  useEffect(() => {
    if (window.scrollY) {
      window.scroll(0, 0);
    }
  }, []);

  const handleCountrySearch = value => {
    setCountrySearchValue(value);

    if (!value && toJS(activeCountry)?.name) {
      setActiveCountry(null);
    }
  };

  const handleChangeLocation = value => {
    setActiveCountry(value);
  };

  const handleCitySearch = value => {
    setCitySearchValue(value);

    if (!value && activeCity) {
      setActiveCity(null);
    }
  };

  const onRemoveCity = () => {
    setActiveCity(null);
    setCitySearchValue("");
  };

  const onRemoveCountry = () => {
    setActiveCountry(null);
    setCountrySearchValue("");
  };

  const handleChangeCity = value => {
    setActiveCity(value);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (citySearchValue.length >= 2) {
        getCommonCities(activeCountry.id, citySearchValue);
      }
    }, 800);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [citySearchValue]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (countrySearchValue.length >= 2) {
        getCommonLocations(countrySearchValue);
      }
    }, 800);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [countrySearchValue]);

  return (
    <SubPageSection
      title={t("wizard.personal_info.title")}
      sectionIcon={
        <SvgContent>
          <MainInformationIco />
        </SvgContent>
      }
      submitAction={form => updatePersonalInfo(form, photo, isScrollToError)}
      loading={loading || !selectedDate}
      processing={processing}
      dynamicFields={dynamicFields}
      initialFields={initialFields}
      validation={validation_errors}
      requiredFields={requiredFields}
      {...props}
    >
      {handleChange => (
        <Row>
          {!inProfileWizard && (
            <Col sm={3}>
              <AvatarUploader
                bordered
                imageUrl={avatar}
                avatarUploaded={avatar_uploaded}
                updatePhoto={p => setPhoto(p)}
                deletePhoto={deleteUserAvatar}
              />
            </Col>
          )}

          <Col sm={inProfileWizard ? 12 : 9}>
            <Row>
              <Col sm={6}>
                <Input
                  label={t("wizard.personal_info.first_name_label")}
                  fieldRequired
                  name="first_name"
                  placeholder={t("wizard.personal_info.first_name_placeholder")}
                  defaultValue={first_name || ""}
                  onChange={handleChange}
                  errorMessage={formatError(validation_errors.first_name)}
                />
              </Col>
              <Col sm={6}>
                <Input
                  label={t("wizard.personal_info.last_name_label")}
                  fieldRequired
                  name="last_name"
                  placeholder={t("wizard.personal_info.last_name_placeholder")}
                  defaultValue={last_name || ""}
                  onChange={handleChange}
                  errorMessage={formatError(validation_errors.last_name)}
                />
              </Col>
            </Row>

            <Row>
              <Col sm={6}>
                <Dropdown
                  label={`${t("wizard.personal_info.gender_label")}${global_brand.require_personal_info ? "*" : ""}`}
                  id="gender"
                  name="gender"
                  placeholder={t("wizard.personal_info.gender_placeholder")}
                  triggerDataCy="gender"
                  defaultValue={capitalize(replaceAll(gender || "", "_", " "))}
                  errorMessage={formatError(validation_errors.gender)}
                >
                  <Dropdown.Menu disableSorting>
                    {genders.map(genderItem => (
                      <Dropdown.Item key={genderItem} onClick={() => handleChange(null, genderItem, "gender")}>
                        {capitalize(replaceAll(genderItem || "", "_", " "))}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              <Col sm={6}>
                <Row gutterWidth={10} align="end" style={{ marginBottom: isXs ? 20 : 0 }}>
                  <Col xs={4}>
                    <Dropdown
                      label={`${t("wizard.personal_info.day_label")}${global_brand.require_personal_info ? "*" : ""}`}
                      id="day"
                      name="day"
                      placeholder={t("wizard.personal_info.day_placeholder")}
                      triggerDataCy="day-of-birth"
                      defaultValue={selectedDate?.day}
                    >
                      <Dropdown.Menu disableSearch>
                        {[...Array(31)]
                          .map((_, i) => i + 1)
                          .map(i => (
                            <Dropdown.Item
                              key={i}
                              onClick={() => handleDateChange({ target: { value: i, name: "day" } })}
                            >
                              {i}
                            </Dropdown.Item>
                          ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                  <Col xs={4}>
                    <Dropdown
                      id="month"
                      name="month"
                      triggerDataCy="month-of-birth"
                      placeholder={t("wizard.personal_info.month_placeholder")}
                      defaultValue={getMonth(selectedDate?.month)}
                    >
                      <Dropdown.Menu disableSorting>
                        {months.map(({ name, value }) => (
                          <Dropdown.Item
                            key={name}
                            onClick={() => handleDateChange({ target: { value, name: "month" } })}
                          >
                            {name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                  <Col xs={4}>
                    <Dropdown
                      id="year"
                      name="year"
                      triggerDataCy="year-of-birth"
                      placeholder={t("wizard.personal_info.year_placeholder")}
                      defaultValue={selectedDate?.year}
                    >
                      <Dropdown.Menu disableSorting disableSearch>
                        {[...Array(92)]
                          .map((_, i) => (new Date().getFullYear() - i).toString())
                          .map(value => (
                            <Dropdown.Item
                              key={value}
                              onClick={() => handleDateChange({ target: { value, name: "year" } })}
                            >
                              {value}
                            </Dropdown.Item>
                          ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                </Row>
                {validation_errors.date_of_birth && (
                  <Text color="accent300" fontSize="12px" margin="-16px 0 0" loaded={!!validation_errors.date_of_birth}>
                    {formatError(validation_errors.date_of_birth)}
                  </Text>
                )}
              </Col>
            </Row>
            <Card.Separator margin="15px 0 30px" />
            <Row align="end">
              <Col sm={6}>
                <DropdownContent>
                  <div className="dropdown-label">
                    <Text fontSize="inherit" fontWeight="500">
                      {t("wizard.personal_info.country_label")}*
                    </Text>
                    <Text fontSize="12px">{t("wizard.personal_info.country_description")}</Text>
                  </div>
                </DropdownContent>

                <CountryContainer>
                  <SelectSearch
                    onRemove={onRemoveCountry}
                    className={formatError(validation_errors.country_id) ? "select__control_error" : ""}
                    value={activeCountry}
                    defaultValue="Select Country"
                    classNameInput="select__input_country_cy"
                    options={commonCountries}
                    noOptionsMessage={() => null}
                    onInputChange={(newValue, actions) => {
                      if (actions?.action === "input-change") {
                        handleCountrySearch(newValue);
                      }

                      if (!actions) {
                        handleCountrySearch("");
                      }
                    }}
                    onSelect={value => {
                      handleChangeLocation(value);

                      if (value?.name) {
                        handleCountrySearch(value.name);
                      }

                      handleChange({ target: { value: value?.id, name: "country_id" } });
                    }}
                    inputValue={countrySearchValue}
                    isSearchable
                  />
                </CountryContainer>

                {formatError(validation_errors.country_id) && (
                  <Text color="accent400" margin="4px 0 0" fontSize={12}>
                    {formatError(validation_errors.country_id)}
                  </Text>
                )}
              </Col>
              <Col sm={6}>
                <DropdownContent>
                  <div className="dropdown-label">
                    <Text fontSize="inherit" fontWeight="500">
                      {t("wizard.personal_info.city_label")}*
                    </Text>
                  </div>
                </DropdownContent>

                <CountryContainer>
                  <SelectSearch
                    onRemove={onRemoveCity}
                    className={formatError(validation_errors.city) ? "select__control_error" : ""}
                    value={activeCity || city || ""}
                    defaultValue="City"
                    classNameInput="select__input_city_cy"
                    options={commonCities}
                    onInputChange={(newValue, actions) => {
                      if (actions?.action === "input-change") {
                        handleCitySearch(newValue);
                      }

                      if (!actions) {
                        handleCitySearch("");
                      }
                    }}
                    onSelect={value => {
                      handleChangeCity(value);

                      if (value?.name) {
                        handleCitySearch(value.name);
                      }

                      handleChange(null, value?.name, "city");
                      handleChange(null, value?.id, "city_id");
                    }}
                    inputValue={citySearchValue}
                    noOptionsMessage={() => null}
                    isSearchable
                  />
                </CountryContainer>

                {formatError(validation_errors.city) && (
                  <Text color="accent400" margin="4px 0 0" fontSize={12}>
                    {formatError(validation_errors.city)}
                  </Text>
                )}

                {/*
                <TownInput
                  label={t("wizard.personal_info.city_label")}
                  placeholder={t("wizard.personal_info.city_placeholder")}
                  name="city"
                  defaultValue={city || ""}
                  fieldRequired
                  onChange={(value, key) => {
                    handleChange(null, value, key);
                  }}
                  errorMessage={formatError(validation_errors.city)}
                />
                */}
              </Col>
            </Row>
            <Row align="end">
              <Col sm={6}>
                <Dropdown
                  id="code"
                  name="code"
                  triggerDataCy="phone-code"
                  label={
                    <>
                      <Text fontSize="inherit" fontWeight="500">
                        {t("wizard.personal_info.phone_code_label")}*
                      </Text>
                      <Text fontSize="12px">{t("wizard.personal_info.phone_code_description")}</Text>
                    </>
                  }
                  placeholder={t("wizard.personal_info.phone_code_placeholder")}
                  defaultValue={defaultCode}
                  errorMessage={formatError(validation_errors.phone?.code || [])}
                >
                  <Dropdown.Menu disableSorting>
                    {priorities.concat(common).map(({ name, code, phone_code }) => (
                      <Dropdown.Item key={`${code}:${phone_code}`} onClick={() => setCode(phone_code)}>
                        {`${name} (${phone_code})`}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              <Col sm={6}>
                <Input
                  dataCy="phoneNumber"
                  label={
                    <>
                      <Text fontSize="inherit" fontWeight="500">
                        {t("wizard.personal_info.phone_number_label")}*
                      </Text>
                      <Text fontSize="12px">{t("wizard.personal_info.phone_number_description")}</Text>
                    </>
                  }
                  name="phone"
                  type="tel"
                  disabled={!selectedCode && !defaultCode}
                  placeholder={t("wizard.personal_info.phone_number_placeholder")}
                  defaultValue={phone.number}
                  onChange={e => setPhoneNumber(e.target.value)}
                  errorMessage={formatError(validation_errors.phone?.number || [])}
                  triggerDataCy="country"
                />
              </Col>
            </Row>
            {!inProfileWizard &&
              (socialItems.length > 0 ? socialItems : [{ id: "new_1" }]).map((item, i) => (
                <React.Fragment key={i}>
                  <Card.Separator margin="15px 0 30px" />
                  <Row align="end" key={`${(item && item.url) || "empty"}_${i}`}>
                    <Col sm={5}>
                      <Dropdown
                        id="social_link_type"
                        name="social_link_type"
                        label={
                          i === 0 ? (
                            <>
                              <Text fontSize="inherit" fontWeight="500" width="100%">
                                {t("wizard.personal_info.social_links_label")}
                              </Text>
                              <Text fontSize="12px" margin="0 15px 0 0">
                                {t("wizard.personal_info.social_links_description")}
                              </Text>
                            </>
                          ) : null
                        }
                        placeholder={t("wizard.personal_info.social_links_placeholder")}
                        defaultValue={item?.social_type}
                        errorMessage={formatError(validation_errors.social_links?.[i]?.social_type || [])}
                      >
                        <Dropdown.Menu disableSorting>
                          {social_link_types.map(type => (
                            <Dropdown.Item
                              key={type}
                              onClick={() =>
                                setSocialItems(prev =>
                                  Object.assign([], prev, { [i]: { ...prev[i], social_type: type } })
                                )
                              }
                            >
                              {typeof type === "string" && capitalize(replaceAll(type || "", "_", " "))}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                      {i === 0 && socialItems.length < 4 && (
                        <Badge theme="transparent" fontSize={24}>
                          <Button.Iconed
                            type="button"
                            padding={0}
                            theme="transparent"
                            onClick={() =>
                              socialItems.length < 4 &&
                              setSocialItems(prev => prev.concat({ id: `new_${socialItems.length + 1}` }))
                            }
                            aria-label={t("wizard.personal_info.add_social_link_label")}
                            title={t("wizard.personal_info.add_social_link_label")}
                          >
                            <PlusCircle width={20} fill={Theme.secondary300} />
                          </Button.Iconed>
                        </Badge>
                      )}
                    </Col>
                    <Col sm={6} xs={10}>
                      <Input
                        name="social_links"
                        defaultValue={socialItems[i]?.url}
                        disabled={!socialItems[i]?.social_type}
                        onBlur={e => {
                          handleSocialLinkChange(e, i, handleChange);
                        }}
                        title={t("wizard.personal_info.add_social_link_placeholder")}
                        errorMessage={formatError(validation_errors.social_links?.[i]?.url || [])}
                      />
                    </Col>
                    <Col sm={1} xs={1}>
                      <Button
                        type="button"
                        noBackground
                        height="22px"
                        theme="black500"
                        fontSize="14px"
                        icon={<Trash width={18} />}
                        padding="0"
                        margin="14px auto 32px"
                        title={t("wizard.personal_info.remove_social_link")}
                        onClick={() => removeSocialLink(item.id)}
                      />
                    </Col>
                  </Row>
                </React.Fragment>
              ))}
          </Col>
        </Row>
      )}
    </SubPageSection>
  );
};

export default observer(PersonalInformation);
