import React, { useState } from "react";
import { useHistory } from "react-router";

import { showDownloaded } from "utils/helpers";
import Success from "images/b2c/fuzuIcons/paymentSuccess.svg";
import useStore from "../../../../contexts/store";
import { Icon, List, Card, Button, Text } from "../../../../components/Core";

const PaymentSuccess = ({ name, slug, message, from, position_id }) => {
  const [generating, setGenerating] = useState(false);
  const { cvUrl, loadCv } = useStore("CvBuilder");
  const { push } = useHistory();

  const CvPromise = () =>
    new Promise(resolve => {
      if (!cvUrl) {
        setGenerating(true);
        loadCv()
          .then(url => {
            setGenerating(false);
            if (url) showDownloaded(url, "cv.pdf");
          })
          .then(resolve);
      } else {
        showDownloaded(cvUrl, "cv.pdf");
        resolve();
      }
    });

  const generateCv = () => {
    CvPromise().then(() => {
      push(from);
    });
  };

  const { Content, Actions } = (() => {
    switch (slug) {
      default:
        return {
          Content: () => null,
          Actions: () => (
            <Button.Link margin="20px auto 0" padding="0 25px" to={from}>
              Back to previous page
            </Button.Link>
          )
        };
      case "global-application-booster-30-days":
        return {
          Content: () => (
            <>
              <Text fontSize={20} fontWeight={600} margin="5px 0">
                How to use Instant Feedback
              </Text>

              <List listType="decimal">
                <List.Item>
                  Make sure you have started an application for a job – you can get Instant Feedback only for
                  applications you have started.
                </List.Item>
                <List.Item>
                  Click View Instant Feedback button on the job you’ve started an application for (You’ll find your
                  applications on My Updates section and in Applications tab under Jobs).
                </List.Item>
                <List.Item>
                  Review the report on how you compare to other candidates (constantly updating) and check out the tips.{" "}
                </List.Item>
                <List.Item>
                  Access to the top candidate profiles becomes available when the campaign has ended. Remember to visit
                  the tool to see latest data.
                </List.Item>
              </List>
            </>
          ),
          Actions: () => (
            <Button.Link margin="20px auto 0" padding="0 25px" to={from}>
              Back to previous page
            </Button.Link>
          )
        };
      case "position-specific-application-booster":
        return {
          Content: () => (
            <>
              <Text fontSize={20} fontWeight={600} margin="5px 0">
                How to use Instant Feedback
              </Text>

              <List listType="decimal">
                <List.Item>
                  Go to the job application you purchased Instant Feedback for and click View Instant Feedback button.
                </List.Item>
                <List.Item>
                  Review the report on how you compare to other candidates (constantly updating) and check out the tips.{" "}
                </List.Item>
                <List.Item>
                  Access to the top candidate profiles becomes available when the campaign has ended. Remember to visit
                  the tool to see latest data!
                </List.Item>
              </List>
            </>
          ),
          Actions: () => (
            <Button.Link
              margin="20px auto 0"
              padding="0 25px"
              to={{
                pathname: `/job?filters[job_id]=${position_id}`,
                state: { purchased: true, slug, position_id }
              }}
            >
              Go to position to view Instant Feedback
            </Button.Link>
          )
        };
      case "permanent-cv-printing":
      case "premium-cv-creator":
      case "cv-printing":
        return {
          Content: () => null,
          Actions: () => (
            <>
              <Button width={320} margin="20px auto 8px" processing={generating} onClick={generateCv}>
                Download Fuzu Premium CV as PDF
              </Button>
              <Button.Link width={320} theme="grey200" margin="0 auto" padding="0 25px" to={from}>
                Back to previous page
              </Button.Link>
            </>
          )
        };
    }
  })();

  return (
    <Card shadow style={{ margin: "30px 0" }}>
      <Card.Content padding="50px 16px">
        <Icon as={Success} width={346} margin="0 auto" />
        <Text
          fontSize={24}
          fontWeight={600}
          textAlign="center"
          width="100%"
          margin="24px auto"
          style={{ maxWidth: "500px" }}
        >
          Thank you for purchasing {name}!
        </Text>

        {message && (
          <Text fontSize={16} margin="5px 0">
            {message}
          </Text>
        )}

        <Content />

        <Actions />
      </Card.Content>
    </Card>
  );
};

export default PaymentSuccess;
