import React, { useState, useEffect, useContext, useRef } from "react";
import { observer } from "mobx-react";

import fuzuLogo from "images/new_fuzu_logo_black.png";
import { withTrackedRoute } from "../../hocs";
import BrandedContext, { getCompanyName, isWhitelabelled } from "../../contexts/branded";
import Layout from "../../components/Layout";
import { Image } from "../../components/Core";
import useStore from "../../contexts/store";
import { withAuthorizationRedirect } from "../../components/WithAuth";
import ProfileWizard from "./components/ProfileWizard";
import WizardIntro from "./components/WizardIntro";
import WizardOutro from "./components/WizardOutro";
import UploadStep from "./components/UploadStep";
import { TestAndSurveyHeader, WizardWrapper } from "./styled";
import BaronaLogo from "../../components/BaronaLogo";
import { useDocumentTitle } from "../../../utils/hooks";

const WizardScreen = ({ location, match }) => {
  const {
    User: {
      Profile: {
        state: { loading, processing },
        profile: { uploaded_cv },
        uploadCv,
        initializeWizard
      }
    },
    Application: { initializeApplication },
    Tracking: { track }
  } = useStore();
  const [intro, setIntro] = useState(false);
  const [upload, setUpload] = useState(false);
  const [wizard, setWizard] = useState(false);
  const stepCompletedState = useRef("intro");
  const { employer } = useContext(BrandedContext);
  const { slug } = match.params;
  const whitelabelled = isWhitelabelled();

  useDocumentTitle(getCompanyName());

  useEffect(() => {
    initializeWizard();
    track("cv_wizard_started");
    if (slug) initializeApplication(slug);
  }, [slug]);

  const setCompleted = key => {
    if (key === "intro") {
      stepCompletedState.current = "upload";
      setIntro(true);
    } else if (key === "upload") {
      stepCompletedState.current = "wizard";
      setUpload(true);
    } else if (key === "wizard") {
      stepCompletedState.current = "";
      setWizard(true);
    }
  };

  const RenderWizardChildren = () => {
    if (!intro || stepCompletedState.current === "intro") {
      return <WizardIntro loading={loading} onComplete={() => setCompleted("intro")} />;
    }

    if (!upload || stepCompletedState.current === "upload") {
      return (
        <UploadStep
          processing={processing}
          uploaded_cv={uploaded_cv}
          uploadCv={uploadCv}
          onComplete={() => setCompleted("upload")}
        />
      );
    }
    if (!wizard || stepCompletedState.current === "wizard") {
      return <ProfileWizard params={match.params} track={track} onComplete={() => setCompleted("wizard")} />;
    }

    return <WizardOutro location={location} />;
  };

  return (
    <div className="profile-wizard">
      <TestAndSurveyHeader isBranded={whitelabelled}>
        <Layout.Content size="xl" tag="div" style={{ position: "relative", justifyContent: "center", display: "flex" }}>
          <BaronaLogo>
            <Image className="brand-logo" src={employer?.logo || fuzuLogo} alt="logo" borderRadius="0" />
          </BaronaLogo>
        </Layout.Content>
      </TestAndSurveyHeader>

      <WizardWrapper isPassed={!intro || !upload || wizard}>
        <Layout.Content size={!intro || !upload || wizard ? "md" : "xl"} id="main">
          {RenderWizardChildren()}
        </Layout.Content>
      </WizardWrapper>
    </div>
  );
};

export default withTrackedRoute(withAuthorizationRedirect(observer(WizardScreen)));
