import styled from "styled-components";

export const Content = styled.div`
  circle {
    fill: ${({ fill }) => fill};
  }
  ellipse {
    fill: ${({ fill }) => fill};
  }
`;
