import React from "react";

import PremiumBanner from "../../../../components/PremiumBanner";

const Promotion = ({ type, ...rest }) => {
  switch (type) {
    default:
      return null;
    /* hide for this ticket https://app.shortcut.com/fuzu/story/4129/remove-instant-feedback-remove-all-promo-elements-advertising-if-from-b2c-app */
    /* case "instant_feedback":
      return <PremiumBanner.InstantFeedback {...rest} />;
    */

    case "career_coaching":
      return <PremiumBanner.CareerCoaching {...rest} />;
    case "premium_cv":
      return <PremiumBanner.Cv {...rest} />;
    case "b2b_landing_page":
      return <PremiumBanner.EmployerLandingPromo type="blog" {...rest} />;
  }
};

export default Promotion;
