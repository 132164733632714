export const FORUM_COMMENT_ACTIONS = {
  REPLY: "reply",
  EDIT: "edit",
  DELETE: "delete"
};

export const FORUM_COMMENT_STATUSES = {
  ACCEPTED: "accepted",
  ON_HOLD: "on_hold",
  REJECTED: "rejected",
  DELETED: "deleted"
};
