import React, { useContext } from "react";

import { Route } from "react-router-dom";
import ServerSide from "../../b2c/contexts/ssr";

const getMaxIndex = (index, string) => {
  const slashIndex = string.substring(index, string.length).indexOf("/", 1);
  if (slashIndex < 0) return string.length;
  return slashIndex;
};

const RouteExclude = ({ path = [], exclude = [], children, ...rest }) => {
  const { ssr } = useContext(ServerSide);
  const modifiedExcludeRoutes = exclude.map(route => {
    let modified = route;
    for (let i = 0; i < (route.match(/:/g) || []).length; i++) {
      const index = modified.indexOf(":", 1);
      const param = modified.substring(index, index + getMaxIndex(index, modified) + 1);
      modified = modified.replace(param, "");
    }

    return modified;
  });

  const modifyPath = pathName => {
    let modified = pathName;
    for (let i = 0; i < (modified.match(/[0-9]/g) || []).length; i++) {
      const index = modified.indexOf(modified.match(/[0-9]/), 1);
      const param = modified.substring(index, index + getMaxIndex(index, modified) + 1);
      modified = modified.replace(param, "");
    }

    return modified;
  };

  return ssr ? (
    <Route path={path} {...rest}>
      {children}
    </Route>
  ) : (
    <Route
      path={path}
      component={({ location: { pathname } }) => {
        const modifiedPathname = modifyPath(pathname);
        return !modifiedExcludeRoutes.some(route => modifiedPathname.includes(route)) ? children : null;
      }}
      {...rest}
    />
  );
};

export default RouteExclude;
