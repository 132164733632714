import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { useScreenClass } from "react-grid-system";
import { ChevronDown, X } from "@styled-icons/boxicons-regular";

import { Dropdown, Text, Icon, SelectInput } from "../Core";
import ThemeContext from "../../contexts/theme";
import { buildPath } from "../../../utils/hooks";
import { useTranslation } from "../../hooks/useTranslation";

const FilterSelect = ({
  category = "",
  appliedCategory,
  sortItemsBy = "name",
  baseProperty = "id",
  selected,
  items = [],
  applyFilter,
  multi = false,
  nonResetting,
  urlBuilder,
  isLearnCategory,
  categoryLearnUrlBuilder,
  placeholder,
  showJobsInText,
  isSeniority,
  isJobFilter,
  dataCyTrigger
}) => {
  const screenClass = useScreenClass();
  const isSm = /xs|sm/.test(screenClass);
  const [currentSelected, setSelected] = useState();
  const theme = useContext(ThemeContext);
  const { t } = useTranslation();

  const buildUrl = record => {
    if (isLearnCategory) {
      return categoryLearnUrlBuilder(record);
    }

    if (!urlBuilder) return;
    const result = urlBuilder(record);
    if (!result) return;
    return buildPath(result.path, result.query);
  };

  useEffect(() => {
    setSelected(items.find(item => String(item[baseProperty]) === String(selected)));
  }, [selected, items]);

  const handleSelect = value => {
    applyFilter(
      {
        [appliedCategory || `${category}_ids`]:
          selected === value || value === "reset" ? undefined : multi ? [value] : value
      },
      isJobFilter
    );
  };

  const resetSelected = force => {
    if (currentSelected && (!nonResetting || force)) handleSelect();
  };

  return isSm ? (
    items.length > 0 && (
      <SelectInput
        placeholder={placeholder}
        value={currentSelected?.[baseProperty] || placeholder}
        onChange={e => handleSelect(e.target.value)}
      >
        {currentSelected && <option value="reset">Reset filter</option>}
        {[...items]
          .sort((a, b) => a[sortItemsBy] > b[sortItemsBy])
          .map(item => (
            <option key={item.id} value={item[baseProperty]}>
              {showJobsInText && !isSeniority && `${t("jobs.content.jobs_in")} `}
              {item.name}
              {showJobsInText && isSeniority && ` ${t("jobs.content.jobs")}`}
            </option>
          ))}
      </SelectInput>
    )
  ) : (
    <Dropdown
      id={category}
      name={category}
      controlled
      placeholder={placeholder}
      closeOnSelect
      defaultValue={currentSelected?.name}
      trigger={
        <FilterTrigger
          placeholder={placeholder}
          data-cy={dataCyTrigger || ""}
          defaultName={currentSelected?.name}
          theme={theme}
          category={category}
          handleReset={resetSelected}
          nonResetting={nonResetting}
        />
      }
      menuStyle="width: 240px;"
      wrapperStyle="margin-bottom: 0;"
    >
      {({ setState }) => (
        <Dropdown.Menu disabled={items.length === 0}>
          {[...items]
            .sort((a, b) => a[sortItemsBy] > b[sortItemsBy])
            .map(item => (
              <Dropdown.Item
                key={item.id}
                value={item[sortItemsBy]}
                highlightSelected={false}
                href={buildUrl(item)}
                onClick={() => {
                  handleSelect(item[baseProperty]);
                  setState(false);
                }}
              >
                {item.name}
                {showJobsInText && ` ${t("jobs.content.jobs")}`}
              </Dropdown.Item>
            ))}
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
};

const FilterTrigger = ({ defaultName, category, placeholder, isOpen, handleReset, nonResetting, ...rest }) => {
  const applied = !isOpen && defaultName;
  const handleClickReset = () => {
    handleReset();
  };

  const handelForceReset = e => {
    e.preventDefault();
    e.stopPropagation();
    if (applied && nonResetting) handleReset(true);
  };

  return (
    <StyledTrigger
      $backgroundColor={applied ? "secondary100" : "white"}
      $borderColor={applied ? "secondary200" : "grey200"}
      onClickCapture={handleClickReset}
      {...rest}
    >
      <Text truncated>{defaultName || placeholder}</Text>
      <Icon as={applied ? X : ChevronDown} width={20} onClick={handelForceReset} />
    </StyledTrigger>
  );
};

const StyledTrigger = styled.div`
  ${({ theme, $backgroundColor, $borderColor }) => `
        position: relative;
        background: ${theme[$backgroundColor]};
        border: 2px solid ${theme[$borderColor]};
        border-radius: 5px;
        padding: 8px 32px 8px 12px;
        cursor: pointer;

        @media screen and (max-width: 767px) {
           margin-bottom: 8px;
           padding: 10px 32px 10px 12px;
        }

        > svg {
            position: absolute;
            right: 8px;
            top: calc(50% - 10px);
        }

        &:focus {
            outline: none;
            border-color: ${theme.secondary200}
        }
`}
`;

export default FilterSelect;
