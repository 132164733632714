import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, ScreenClassContext, Hidden, Visible } from "react-grid-system";
import { Search, MapPin } from "@styled-icons/boxicons-regular";
import { useTranslation } from "b2c/hooks/useTranslation";

import Layout from "b2c/components/Layout";
import { Title, Button, Card, Icon, Dropdown, Divider, Tag, Categories } from "b2c/components/Core";
import { SelectCountry } from "b2c/components/SelectCountry";
import { searchCountries, searchNigeriaCities, searchKenyaCities, searchUgandaCities } from "b2c/constants/landing";
import { countryKeys } from "b2c/constants/main";
import LinkWithFullUrl from "../../../../../components/LinkWithFullUrl";
import { pathWithCountry } from "../../../../utils/routes";
import { Content } from "./styled";
import ThemeContext from "../../../../contexts/theme";

const JobSearchSection = ({ filters, towns = [], country, handleClickCategory, topCategories, isBarona }) => {
  const { categories = [], industries = [] } = filters;
  const screen = useContext(ScreenClassContext);
  const isSm = /xs|sm/.test(screen);
  const { push } = useHistory();
  const topCategoriesSorted = topCategories?.sort((a, b) => b.count - a.count).slice(0, 6);
  const theme = useContext(ThemeContext);
  const { t } = useTranslation();

  const handleClick = slug => {
    push(pathWithCountry(country, `/job/${slug}`));
  };

  const handleClickCountry = path => {
    push(path);
  };

  const getCurrentSearch = () => {
    const currentCountryName = country.name.toLowerCase();

    if (currentCountryName === countryKeys.kenya.toLowerCase()) {
      return searchKenyaCities;
    }

    if (currentCountryName === countryKeys.nigeria.toLowerCase()) {
      return searchNigeriaCities;
    }

    if (currentCountryName === countryKeys.uganda.toLowerCase()) {
      return searchUgandaCities;
    }

    return searchCountries;
  };

  return (
    <div style={{ backgroundColor: theme.guestLandingBackgroundPrimary, padding: isSm ? "32px 0" : "64px 0" }}>
      <Layout.Content tag="div" size="lg">
        <Content>
          <Title
            level="2"
            width="100%"
            textAlign="center"
            fontWeight="600"
            style={{ fontSize: isSm ? "20px" : "32px" }}
            color="primaryButtonLabelColor"
          >
            {isBarona ? (
              <>{t("landing.search.title")}</>
            ) : (
              <>
                {t("landing.search.search_jobs_in")}{" "}
                {country?.slug ? ` ${country?.name}` : ` ${t("landing.search.africa")}`}
              </>
            )}
          </Title>
          {!isBarona && <SelectCountry country={country} />}
          <Card shadow style={{ margin: "24px 0 0 0" }}>
            <Card.Content>
              <Row>
                <Col
                  {...(isSm ? { xs: 12 } : {})}
                  style={{ display: "flex", flexDirection: isSm ? "column" : "row", marginBottom: isSm ? "20px" : "0" }}
                >
                  <Dropdown
                    defaultValue={t("landing.search.filter_by_1")}
                    wrapperStyle={{
                      width: isSm ? "100%" : "240px",
                      height: "50px",
                      marginBottom: "0"
                    }}
                    $triggerStyle={{
                      height: "50px",
                      fontWeight: "600",
                      fontSize: "18px",
                      lineHeight: "140%",
                      color: "rgb(112, 115, 120)",
                      border: "none"
                    }}
                  >
                    <Dropdown.Menu disableSorting>
                      {categories.map(({ id, name, slug }) => (
                        <Dropdown.Item
                          key={id}
                          value={name}
                          style={{ textTransform: "capitalize", padding: "0" }}
                          onClick={() => handleClick(slug)}
                        >
                          <Button
                            as={LinkWithFullUrl}
                            to={pathWithCountry(country, `/job/${slug}`)}
                            width="100%"
                            height="100%"
                            theme="transparent"
                            margin="0"
                            style={{ justifyContent: "flex-start", padding: "10px" }}
                          >
                            {name}
                          </Button>
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                  <Hidden xs sm>
                    <Divider.Vertical margin="auto" height="16px" />
                  </Hidden>
                  <Visible xs sm>
                    <Divider />
                  </Visible>
                </Col>
                <Col
                  {...(isSm ? { xs: 12 } : {})}
                  style={{ display: "flex", flexDirection: isSm ? "column" : "row", marginBottom: isSm ? "20px" : "0" }}
                >
                  <Dropdown
                    defaultValue={t("landing.search.filter_by_2")}
                    wrapperStyle={{
                      width: isSm ? "100%" : "240px",
                      height: "50px",
                      marginBottom: "0"
                    }}
                    $triggerStyle={{
                      height: "50px",
                      fontWeight: "600",
                      fontSize: "18px",
                      lineHeight: "140%",
                      color: "rgb(112, 115, 120)",
                      border: "none"
                    }}
                  >
                    <Dropdown.Menu disableSorting>
                      {industries.map(({ id, name, slug }) => (
                        <Dropdown.Item
                          key={id}
                          value={name}
                          style={{ textTransform: "capitalize", padding: "0" }}
                          onClick={() => handleClick(slug)}
                        >
                          <Button
                            as={LinkWithFullUrl}
                            to={pathWithCountry(country, `/job/${slug}`)}
                            height="100%"
                            theme="transparent"
                            margin="0"
                            style={{ justifyContent: "flex-start", padding: "10px" }}
                            padding="10px"
                          >
                            {name}
                          </Button>
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                  <Hidden xs sm>
                    <Divider.Vertical margin="auto" height="16px" />
                  </Hidden>
                  <Visible xs sm>
                    <Divider />
                  </Visible>
                </Col>
                <Col
                  {...(isSm ? { xs: 12 } : {})}
                  style={{ display: "flex", flexDirection: isSm ? "column" : "row", marginBottom: isSm ? "20px" : "0" }}
                >
                  <Dropdown
                    defaultValue={t("landing.search.filter_by_3")}
                    wrapperStyle={{
                      width: isSm ? "100%" : "240px",
                      height: "50px",
                      marginBottom: "0"
                    }}
                    $triggerStyle={{
                      height: "50px",
                      fontWeight: "600",
                      fontSize: "18px",
                      lineHeight: "140%",
                      color: "rgb(112, 115, 120)",
                      border: "none"
                    }}
                  >
                    <Dropdown.Menu disableSorting>
                      {towns.map(({ name, path }) => (
                        <Dropdown.Item
                          key={path}
                          value={name}
                          style={{ textTransform: "capitalize", padding: "0" }}
                          onClick={() => handleClickCountry(path)}
                        >
                          <Button
                            as={LinkWithFullUrl}
                            to={path}
                            width="100%"
                            height="100%"
                            theme="transparent"
                            margin="0"
                            style={{ justifyContent: "flex-start", padding: "10px" }}
                          >
                            {name}
                          </Button>
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                  <Hidden xs sm>
                    <Divider.Vertical margin="auto" height="16px" />
                  </Hidden>
                  <Visible xs sm>
                    <Divider />
                  </Visible>
                </Col>
                <Col {...(isSm ? { xs: 12 } : {})} style={{ display: "flex", flexDirection: isSm ? "column" : "row" }}>
                  <Button
                    as="a"
                    href={pathWithCountry(country, "/job")}
                    width="100%"
                    height="100%"
                    theme="primaryButtonBackground"
                    color="primaryButtonLabelColor"
                    margin="0"
                    style={{
                      justifyContent: "flex-start",
                      width: isSm ? "100%" : "180px",
                      display: "block",
                      textAlign: "center",
                      padding: "13px 10px",
                      fontWeight: "600",
                      flex: "none"
                    }}
                  >
                    {t("landing.search.button")}
                  </Button>
                </Col>
              </Row>
            </Card.Content>
          </Card>

          <Title
            color="primaryButtonLabelColor"
            level={3}
            scales
            fontWeight={600}
            margin={isSm ? "32px auto 16px" : "48px auto 24px"}
          >
            {t("landing.search.additional_search")}
          </Title>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "center"
            }}
          >
            {topCategoriesSorted?.map(topCategory => (
              <Categories.Item
                as={LinkWithFullUrl}
                to={pathWithCountry(country, `/job/${topCategory.slug}`)}
                key={topCategory.slug}
                name={`${topCategory.name} jobs`}
                removable={false}
                width="auto"
                borderColor="transparent"
                margin="0 12px 12px 0"
                Icon={() => <Icon as={Search} height="100%" fill="primary300" />}
                flexDirection="row-reverse"
                shadow
                color="black500"
                onClick={() => handleClickCategory(pathWithCountry(country, `/job/${topCategory.slug}`))}
              />
            ))}

            {!isBarona && (
              <div
                style={{
                  flex: "none",
                  textAlign: "center",
                  width: "100%"
                }}
              >
                {getCurrentSearch().map(search => (
                  <Tag
                    as={LinkWithFullUrl}
                    to={search.slug}
                    key={search.slug}
                    name={t(search.name)}
                    removable={false}
                    width="auto"
                    borderColor="transparent"
                    margin="0 12px 12px 0"
                    Icon={() => <Icon as={MapPin} height="100%" fill="primary300" />}
                    flexDirection="row-reverse"
                    shadow
                    color="black500"
                  />
                ))}
              </div>
            )}
          </div>
        </Content>
      </Layout.Content>
    </div>
  );
};

export default JobSearchSection;
