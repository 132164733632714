import styled from "styled-components";
import { theme } from "b2c/contexts/theme";

export const Modal = styled.div`
  max-width: 640px;
  width: 100%;
`;

export const Content = styled.div`
  margin: -40px -20px 0;
`;

export const Logo = styled.div`
  margin-bottom: 16px;
  padding-left: 32px;

  img {
    width: 98px;
    height: 29px;
  }
`;

export const Description = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #666666;
  display: block;
  padding: 40px 16px;
  border-bottom: 1px solid #c5c5c5;
  border-top: 1px solid #c5c5c5;

  @media screen and (min-width: 768px) {
    padding: 64px 32px;
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin: 24px 32px 0 0;
`;

export const GoToGlobal = styled.a`
  background: ${theme.primary};
  border-radius: 8px;
  display: block;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  padding: 9px 24px;
  cursor: pointer;
`;

export const Close = styled.button`
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #408bfc;
  padding: 9px 24px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
`;
