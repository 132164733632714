import React from "react";

import lassiBarona from "images/b2c/landing-page/lassiBarona.png";
import { useTranslation } from "b2c/hooks/useTranslation";
import { Container, Content, ImageContent, Text, Name } from "./styled";

const BaronaCeo = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Content>
        <ImageContent>
          <img src={lassiBarona} alt="ceo barona" />
        </ImageContent>
        <div>
          <Text>“{t("about.ceo_description")}”</Text>
          <Name>Lassi Määttä, {` ${t("about.ceo_position")}`}</Name>
        </div>
      </Content>
    </Container>
  );
};

export default BaronaCeo;
