import styled from "styled-components";

export const Container = styled.div`
  background-color: #fff;
  padding: 32px 16px;

  @media screen and (min-width: 1008px) {
    padding: 64px 0;
  }
`;

export const Content = styled.div`
  max-width: 1000px;
  margin: 0 auto;
`;

export const Title = styled.h3`
  color: #292929;
  text-align: center;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  margin-bottom: 16px;

  @media screen and (min-width: 1008px) {
    font-size: 32px;
    margin-bottom: 64px;
  }
`;

export const Offer = styled.div`
  @media screen and (min-width: 1008px) {
    display: flex;
    gap: 64px;
    align-items: center;
  }
`;

export const OfferLeftSide = styled.div`
  max-width: 460px;
  margin: 0 auto;

  @media screen and (min-width: 1008px) {
    margin: 0 24px;
  }
`;

export const OfferRightSide = styled.div`
  max-width: 476px;
  margin: 40px auto;

  @media screen and (min-width: 1008px) {
    margin: 0;
  }
`;

export const OfferTitle = styled.h5`
  color: #292929;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  margin: 0 0 8px 0;

  @media screen and (min-width: 1008px) {
    font-size: 32px;
    font-weight: 600;
    line-height: 140%;
    margin: 0 0 8px 0;
  }
`;
export const OfferLabel = styled.span`
  color: #292929;
  font-size: 16px;
  font-weight: 700;
  line-height: 140%;
  display: block;
  margin: 0 0 24px 0;
`;

export const OfferDescription = styled.p`
  color: #292929;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  display: block;
  margin: 0 0 8px 0;
`;

export const OfferButton = styled.button`
  border-radius: 5px;
  background: #18181b;
  padding: 12px 24px;
  max-width: 360px;
  margin-top: 16px;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.2px;
  cursor: pointer;

  @media screen and (min-width: 1008px) {
    &:hover {
      opacity: 0.8;
    }
  }
`;

export const OfferImageContent = styled.div`
  width: 328px;
  height: 268px;
  margin-bottom: 32px;

  img {
    max-width: 100%;
  }

  @media screen and (min-width: 1008px) {
    width: 460px;
    height: 374px;
    margin: 0;
  }
`;
