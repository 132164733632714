import React from "react";

import { withTrackedRoute } from "../../hocs";
import TestAndSurvey from "./components/TestAndSurvey";
import { withAuthorizationRedirect } from "../../components/WithAuth";

const TestAndSurveyScreen = ({ ...routerProps }) => {
  return <TestAndSurvey {...routerProps} />;
};

export default withTrackedRoute(withAuthorizationRedirect(TestAndSurveyScreen));
