import styled from "styled-components";

export const PromoBuildProfileContainer = styled.div`
  margin: 20px 0;

  @media screen and (min-width: 768px) {
    display: flex;
    align-items: center;
  }
`;

export const TextHideMobile = styled.span`
  display: none;

  @media screen and (min-width: 768px) {
    display: inline-block;
  }
`;

export const Strong = styled.span`
  @media screen and (min-width: 768px) {
    font-weight: 700;
    display: inline-block;
  }
`;
