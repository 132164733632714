import React, { useEffect, useLayoutEffect } from "react";
import { observer } from "mobx-react";

import { withRoute } from "utils/hocs/withRoute";
import { useTranslation } from "b2c/hooks/useTranslation";
import { Spinner, Categories } from "../../../../components/Core";
import Layout from "../../../../components/Layout";
import Section from "../../../../components/FeedSections";
import PromoSection from "../../../../components/PromoSection";
import LearningActivity from "../LearningActivity";
import useStore from "../../../../contexts/store";
import { useDocumentTitle } from "../../../../../utils/hooks";
import Chatline from "../../../../components/Chatline";
import Welcome from "../Welcome";
import { FeatureHidden } from "../../../../hocs";
import { GuestVisible } from "../../../../components/WithAuth";
import { getCompanyName } from "../../../../contexts/branded";
import ScrollToTopOnMount from "../ScrollToTopOnMount";
import { useLanguageAndCountryUrl } from "../../../../hooks";

const Home = () => {
  const {
    User: { user },
    Learning: {
      state: { loading },
      activity = {},
      categories = [],
      getCategories,
      sortedSections,
      initializeLearning,
      getLearningActivity
    }
  } = useStore();
  const { t } = useTranslation();
  const languageAndLanguage = useLanguageAndCountryUrl();

  useLayoutEffect(() => {
    getCategories();

    if (user?.id) {
      initializeLearning();
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useDocumentTitle(`${t("learn.meta_title")} | ${getCompanyName()}`);

  const LearnSection = ({ type, promotion_type: promotionType, ...rest }) => {
    switch (type) {
      default:
        return null;
      case "my_learning_activity":
        return user?.id ? <LearningActivity getLearningActivity={getLearningActivity} {...activity} /> : null;
      case "courses":
        return <Section.FeedSection type="courses" narrowCarousel {...rest} pathList="/search" />;
      case "promotions":
        if (promotionType === "instant_feedback") {
          return (
            <FeatureHidden feature="instant_feedback_global" fallback={null}>
              <PromoSection type={promotionType} />
            </FeatureHidden>
          );
        }
    }
  };

  return (
    <>
      <ScrollToTopOnMount />
      {sortedSections.some(({ type }) => type === "welcome_to_learning") && !loading && <Welcome />}
      <Layout.Content size="xl" style={{ margin: "24px auto" }}>
        {!loading ? (
          sortedSections.map(section =>
            section.promotion_type === "customer_support_chatline" ? (
              <Chatline key={section.position} />
            ) : (
              <LearnSection key={section.position} {...section} />
            )
          )
        ) : (
          <Spinner size="72px" margin="240px auto" />
        )}
        <GuestVisible>
          <Categories title={t("learn.category_title")} style={{ padding: "0", marginLeft: "0" }}>
            {categories.map(({ name, slug }) => (
              <Categories.Item to={`${languageAndLanguage}/learn/search/${slug}`} key={slug}>
                {name}
              </Categories.Item>
            ))}
          </Categories>
        </GuestVisible>
      </Layout.Content>
    </>
  );
};

export default withRoute(observer(Home));
