import React from "react";

import { useTranslation } from "b2c/hooks/useTranslation";
import { Title } from "b2c/components/Core";
import { EliteDeliverablesSection, EliteDeliverablesContent } from "./styled";
import EliteDeliverablesPlan from "../EliteDeliverablesPlan";

export const EliteDeliverables = () => {
  const { t } = useTranslation();

  return (
    <EliteDeliverablesSection>
      <EliteDeliverablesContent>
        <Title
          level="3"
          style={{
            fontWeight: "600",
            lineHeight: "130%",
            color: "#292929",
            display: "block",
            margin: "0 auto 54px"
          }}
        >
          {t("pricing.elite_details.delivers.title")}
        </Title>
        <EliteDeliverablesPlan />
      </EliteDeliverablesContent>
    </EliteDeliverablesSection>
  );
};
