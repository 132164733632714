import styled from "styled-components";

export const BlockquoteSectionWrapper = styled.section`
  background-color: white;
  padding-bottom: 90px;
  @media screen and (max-width: 767px) {
    background-color: #f4f4f4;
    padding-bottom: 0;
  }
`;

export const BlockquoteContainer = styled.div`
  display: flex;
  padding: 140px 0 40px;
  position: relative;
  z-index: 1;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    padding: 20px 0;
  }
`;

export const BlockquoteImage = styled.div`
  display: flex;
  align-items: center;
  max-width: 490px;
  margin-right: 60px;
  @media screen and (max-width: 767px) {
    margin: 0 auto;
  }
`;

export const BlockquoteContent = styled.div`
  max-width: 660px;
  padding-top: 30px;
  @media screen and (max-width: 767px) {
    padding-top: 20px;
  }
`;

export const QuotationBG = styled.div`
  background: #f4f4f4;
  border-radius: 50px 0px 0px 0px;
  position: absolute;
  left: 9%;
  right: 0;
  top: 70px;
  bottom: 0;
  z-index: -1;
`;
