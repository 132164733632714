import React from "react";
import { Close } from "@styled-icons/evaicons-solid";
import { Link } from "react-router-dom";

import { getCompanyName } from "b2c/contexts/branded";
import { Text, Title } from "b2c/components/Core";
import { BrandHidden, BrandVisible, isCustomJkuat } from "../../../../contexts/branded";
import { Flex, Label, LinkClose, Login } from "./styled";

const RegistrationHeadContent = ({ slug, step, employer, routeSlug, resetUser, returnTo }) => {
  const customJkuat = isCustomJkuat();

  const handleClose = () => {
    if (returnTo) {
      return returnTo;
    }

    return window.location.origin;
  };

  return (
    <>
      <Title level="4" fontWeight={600} margin="0 0 30px">
        <BrandHidden brand="www">
          <LinkClose href={handleClose()} isHide={customJkuat}>
            <Close />
          </LinkClose>
        </BrandHidden>
        Register
        <BrandVisible brand="www"> to {getCompanyName()}</BrandVisible>
        <BrandHidden brand="www"> to {employer?.name}</BrandHidden>
        {step !== 1 && step !== 4 && (
          <BrandHidden brand="www">
            <Flex>
              <Label>Already have an account?</Label>
              <Login to="/login">Log in</Login>
            </Flex>
          </BrandHidden>
        )}
      </Title>

      <Text lineHeight="140%" margin="0 0 24px" color="black300">
        {step === 1 && slug !== "demo" && (
          <>
            <BrandHidden brand="www">
              {employer?.name} is powered by {getCompanyName()}.{" "}
            </BrandHidden>
            <BrandHidden brand={["ncpwd", "demo"]}>
              <div>
                Already have a {getCompanyName()} account?{" "}
                <Link
                  className="link link_blue"
                  to={`/login${routeSlug ? `/${routeSlug}` : ""}${returnTo ? `?return_to=${returnTo}` : ""}`}
                  onClick={() => resetUser()}
                >
                  Log in
                </Link>
              </div>
            </BrandHidden>
          </>
        )}
      </Text>
      <div className="page-separator" style={{ margin: "0 0 24px" }} />
    </>
  );
};

export default RegistrationHeadContent;
