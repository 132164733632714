import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useLocation } from "react-router-dom";
import { Col, Row } from "react-grid-system";
import { useTranslation } from "b2c/hooks/useTranslation";

import SvgContent from "b2c/components/SvgContent";
import CertificatesIco from "images/b2c/profilePage/CertificatesIco.svg";
import { capitalize } from "utils/helpers";
import { Dropdown, Text } from "b2c/components/Core";
import SubPageSection from "b2c/components/SubPageSection";
import FileUploader from "b2c/components/FileUploader";
import useStore from "../../../../contexts/store";
import { languageFormat } from "../../../../constants/main";

const Certificates = props => {
  const {
    Profile: {
      state: { loading, processing },
      certificate_info: { certificates = [], certificate_items } = {},
      validation_errors,
      initializeCertificates,
      updateCertificates
    }
  } = useStore("User");
  const [certificateItems, setCertificateItems] = useState([]);
  const [certificateDates, setDates] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [attachmentUrls, setAttachmentUrls] = useState([]);
  const { state } = useLocation();
  const { t, language } = useTranslation();
  const languageFormatType = languageFormat[language];

  const itemId = state ? state.item_id : "";

  useEffect(() => {
    initializeCertificates();
  }, []);

  useEffect(() => {
    if (certificate_items?.length > 0) {
      const selectedCertificate = certificate_items.filter(item => item.id === itemId);
      setCertificateItems(selectedCertificate);
      setDates((selectedCertificate || []).map(item => formatDate(item.issued_at)));
      setFileNames((selectedCertificate || []).map(item => item.attachment?.name || "Attachment"));
      setAttachmentUrls((selectedCertificate || []).map(item => item.attachment?.url));
    }
    if (certificate_items?.length === 0 || !itemId) {
      addCertificate();
    }
  }, [certificate_items, itemId]);

  const addCertificate = () => {
    setDates([
      {
        year: null,
        month: null
      }
    ]);
    setFileNames("");
    setCertificateItems([
      {
        certificate_id: null,
        attachment: null
      }
    ]);
  };

  const handleCertificatesChange = (name, id, index) => {
    const newList = [...certificateItems];
    newList[index] = {
      ...newList[index],
      [name]: id
    };

    setCertificateItems(newList);
  };

  const handleAttachment = (file, index) => {
    const newList = [...certificateItems];
    newList[index] = {
      ...newList[index],
      attachment: file
    };

    const newNames = [...fileNames];
    newNames[index] = file?.name;

    setFileNames(newNames);
    setCertificateItems(newList);
  };

  const handleDateChange = (name, id, index) => {
    const newList = [...certificateDates];
    newList[index] = {
      ...newList[index],
      [name]: id
    };

    setDates(newList);
  };

  const formatDate = date =>
    date
      ? {
          year: +date.substring(0, date.indexOf("-", 0)),
          month: +date.substring(date.indexOf("-", 0) + 1, date.indexOf("-", 5)),
          day: +date.substring(date.length, date.indexOf("-", 7) + 1)
        }
      : {};

  const months = Array.from({ length: 12 }, (_, i) => ({
    name: new Date(null, i + 1, null).toLocaleString(languageFormatType, {
      month: "long"
    }),
    id: i + 1
  }));

  const formatError = (error = []) => (error ? capitalize(error.join(", ")) : null);

  const getMonth = value => (months.some(m => m?.id == value) ? months.find(m => m?.id == value)?.name : "");

  const modifyDate = (item, index) => {
    const pad = d => (d ? (d < 10 ? `0${d.toString()}` : d.toString()) : null);

    if (certificateDates[index].month) {
      const modifiedMonth = months.some(m => m.id == certificateDates[index].month)
        ? certificateDates[index].month
        : months.find(m => certificateDates[index].month == m?.name)?.id;
      return {
        ...item,
        issued_at: `${certificateDates[index].year}-${pad(modifiedMonth)}-01`
      };
    }

    return {
      ...item,
      issued_at: ""
    };
  };

  useEffect(() => {
    if (window.scrollY) {
      window.scroll(0, 0);
    }
  }, []);

  return (
    <SubPageSection
      title={t("profile.certificates.title")}
      // title={`${itemId ? "Edit" : "Add"} certificate`}
      sectionIcon={
        <SvgContent>
          <CertificatesIco />
        </SvgContent>
      }
      loading={loading}
      processing={processing}
      submitAction={updateCertificates}
      disableSubmit={certificateItems.some(item => !item.certificate_id || !item.attachment)}
      dynamicFields={{
        certificates: certificateItems.map((item, index) => modifyDate(item, index))
      }}
      validation={validation_errors}
      {...props}
    >
      {() => (
        <>
          {(certificateItems || []).map((item, index) => (
            <Row key={`certificate-${index + 1}`} style={{ marginBottom: 14 }}>
              <Col sm={8} lg={4}>
                <Dropdown
                  label={t("profile.certificates.name_label")}
                  placeholder={t("profile.certificates.name_placeholder")}
                  id="certificate_id"
                  name="certificate_id"
                  defaultValue={item?.name}
                  errorMessage={formatError(validation_errors?.certificates?.[index]?.certificate_id)}
                >
                  <Dropdown.Menu disableSorting>
                    {certificates.map(({ id, name }) => (
                      <Dropdown.Item key={id} onClick={() => handleCertificatesChange("certificate_id", id, index)}>
                        {name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                <Row gutterWidth={10} align="end">
                  <Col xs={6}>
                    <Dropdown
                      label={t("profile.certificates.month_label")}
                      placeholder={t("profile.certificates.month_placeholder")}
                      id="month"
                      name="month"
                      defaultValue={getMonth(certificateDates[index].month)}
                    >
                      <Dropdown.Menu disableSorting>
                        {months.map(({ id, name }) => (
                          <Dropdown.Item key={id} onClick={() => handleDateChange("month", id, index)}>
                            {name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                  <Col xs={6}>
                    <Dropdown
                      id="year"
                      name="year"
                      placeholder={t("profile.certificates.year_placeholder")}
                      defaultValue={certificateDates[index].year}
                    >
                      <Dropdown.Menu disableSorting disableSearch>
                        {[...Array(92)]
                          .map((_, i) => new Date().getFullYear() - i)
                          .map(year => (
                            <Dropdown.Item key={year} onClick={() => handleDateChange("year", year, index)}>
                              {year}
                            </Dropdown.Item>
                          ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                  {validation_errors?.certificates?.[index]?.issued_at && (
                    <Text
                      color="accent300"
                      fontSize="12px"
                      margin="4px 0 0"
                      loaded={!!validation_errors?.certificates?.[index]?.issued_at}
                    >
                      {formatError(validation_errors?.certificates?.[index]?.issued_at)}
                    </Text>
                  )}
                </Row>
              </Col>

              <Col sm={4} xs={11}>
                <FileUploader
                  title={t("profile.certificates.file_title")}
                  cardProps={{ shadow: true, margin: "0 0 15px" }}
                  name={fileNames[index]}
                  url={attachmentUrls[index]}
                  accept=".pdf, .doc, .docx, .txt, .rtx"
                  buttonText={
                    !attachmentUrls[index] ? t("profile.certificates.choose_file") : t("profile.certificates.replace")
                  }
                  onUpload={file => handleAttachment(file, index)}
                  additionalContent={
                    validation_errors?.certificates?.[index]?.attachment && (
                      <Text color="accent300" fontSize="12px" margin="8px auto 0" loaded>
                        {formatError(validation_errors?.certificates?.[index]?.attachment)}
                      </Text>
                    )
                  }
                />
              </Col>
            </Row>
          ))}
        </>
      )}
    </SubPageSection>
  );
};

export default observer(Certificates);
