import React from "react";
import { Plus } from "@styled-icons/boxicons-regular";
import { ErrorCircle } from "@styled-icons/boxicons-solid";

import { useTranslation } from "b2c/hooks/useTranslation";
import {
  Content,
  Title,
  Label,
  EducationItem,
  EducationFlex,
  EducationInfo,
  EducationTitle,
  EducationDescription,
  EducationYear,
  EducationFileUploader,
  EmptyContent,
  EmptyText,
  Error
} from "./styled";
import FileUploader from "../../../../components/FileUploader";
import { Button, Image } from "../../../../components/Core";
import { buildImageUrlS3 } from "../../../../contexts/branded";
import { nameImages } from "../../../../constants/images";
import { languageFormat } from "../../../../constants/main";

const Educations = ({
  educations,
  setAttachments,
  deleteEducationCertificate,
  requiresCert,
  educationCertificatesErrors,
  id: jobId
}) => {
  const { t, language } = useTranslation();
  const languageFormatType = languageFormat[language];

  const convertDate = string => {
    return string
      ? new Date(string).toLocaleString(languageFormatType, { month: "short", year: "numeric" })
      : "Present";
  };

  const handleAttachment = (file, id) => {
    setAttachments(prev => {
      return {
        ...prev,
        education_certificates: prev.education_certificates
          ? [...prev.education_certificates, { education_item_id: id, certificate: file }]
          : [{ education_item_id: id, certificate: file }]
      };
    });
  };

  const educationsList = educations?.map((item, index) => {
    const educationItemError =
      Boolean(educationCertificatesErrors) && Boolean(educationCertificatesErrors?.[item.id])
        ? educationCertificatesErrors[item.id]
        : {};

    return (
      <EducationItem key={`${item.degree_name}${item.id}`}>
        {educationItemError?.certificate?.[0] ? (
          <Error>
            <ErrorCircle />
            {educationItemError?.certificate[0]}
          </Error>
        ) : null}

        <EducationFlex>
          <EducationInfo>
            <EducationTitle>{item.degree_name}</EducationTitle>
            <EducationDescription>{item.school_name}</EducationDescription>
            <EducationYear>{`${convertDate(item.start_date)} - ${convertDate(item.end_date)}`}</EducationYear>
          </EducationInfo>
          <EducationFileUploader $isBig={!item.academic_certificate?.original_filename}>
            <FileUploader
              name={item.academic_certificate?.original_filename || t("application.attachments.educations.file_name")}
              accept=".pdf, .doc, .docx, .rtf, .txt"
              url={item.academic_certificate?.url}
              buttonText={
                item.academic_certificate?.url ? t("wizard.upload.update_file") : t("wizard.upload.choose_file")
              }
              secondButtonText="Update file"
              onUpload={file => handleAttachment(file, item.id, index)}
              isRemoveIcon
              deleteEducationCertificate={() => deleteEducationCertificate(item.academic_certificate?.id)}
              showTypesBelow
            />
          </EducationFileUploader>
        </EducationFlex>
      </EducationItem>
    );
  });
  return (
    <Content>
      <Title>
        {requiresCert
          ? t("application.attachments.educations.title_1")
          : t("application.attachments.educations.title_2")}
      </Title>
      <Label>
        {requiresCert
          ? t("application.attachments.educations.description_1")
          : t("application.attachments.educations.description_2")}
      </Label>

      {educations?.length ? (
        <>{educationsList}</>
      ) : (
        <EmptyContent>
          <Image src={buildImageUrlS3(nameImages.educationEmpty)} style={{ margin: "auto", display: "block" }} />
          <EmptyText>{t("application.attachments.educations.empty_text")}</EmptyText>
          <Button.Link
            className="add-button"
            color="primaryButtonLabelColor"
            to={{
              pathname: "/profile",
              state: {
                from: `/jobs/${jobId}/application`,
                noiseless: true
              }
            }}
          >
            <Plus />
            {t("application.attachments.educations.add_button")}
          </Button.Link>
        </EmptyContent>
      )}
    </Content>
  );
};

export default Educations;
