import React, { forwardRef, useContext } from "react";
import { ScreenClassContext } from "react-grid-system";
import { observer } from "mobx-react";

import { useTranslation } from "b2c/hooks/useTranslation";
import { onEnterPress } from "utils/helpers";
import ActionRequired from "b2c/components/ActionRequired";
import { Card, Label, Text, Title } from "b2c/components/Core";
import FastApply from "b2c/components/FastApply";
import { applicationTypes } from "../../../../constants/jobSearch";
import PositionDetails from "../PositionDetails";
import useStore from "../../../../contexts/store";

const ViewItem = forwardRef(
  (
    {
      id,
      slug,
      path,
      company_name: companyCame = "Company",
      title = "Position Title",
      campaign_end_date: campaignEndDate = 0,
      location = "",
      loaded,
      selected = false,
      application,
      brand_tag: brandTag,
      onClick,
      expired,
      application_type,
      ...props
    },
    ref
  ) => {
    const screenClass = useContext(ScreenClassContext);
    const isMobile = ["xs", "sm"].includes(screenClass);
    const isFastApply = application_type === applicationTypes.applicationTypeFastApply;
    const isAssigmentRequested = application?.fast_apply_status === applicationTypes.fastApplyAssignmentsRequested;
    const isAssigmentCompleted = application?.fast_apply_status === applicationTypes.fastApplyAssignmentsCompeted;
    const { isBaronaState: isBarona } = useStore("Onboarding");
    const { t } = useTranslation();

    return (
      <Card
        as="a"
        href={path}
        target="_blank"
        ref={ref}
        theme={selected ? "secondary100" : isMobile ? "white" : "grey100"}
        bordered
        borderColor={selected ? "secondary200" : "transparent"}
        borderWidth="2px"
        clickable
        onClick={onClick}
        onKeyDown={e => onEnterPress(e, 1, onClick)}
        {...props}
      >
        <Card.Header padding="8px 16px 0">
          <Text truncated={!!application} loaded={loaded} style={{ flex: "1 0 0" }}>
            {companyCame}
          </Text>

          <FastApply t={t} isShow={isFastApply && !isAssigmentRequested && !isAssigmentCompleted} isHideDescription />

          <ActionRequired isShow={isAssigmentRequested} />

          {application && (
            <Label
              rect
              theme={application.submitted ? "success200" : "primary100"}
              color={application.submitted ? "white" : "black500"}
              height={24}
              margin="0 0 8px 0"
            >
              {application.submitted
                ? isFastApply && !isAssigmentCompleted
                  ? ""
                  : t("jobs.content.submit_status.applied")
                : t("jobs.content.unfinished")}
            </Label>
          )}
          {brandTag && !isBarona && (
            <Label rect noBackground bordered theme="black200" height={24} margin="0 0 0 8px">
              {`${t("jobs.content.only_on")} `} {brandTag}
            </Label>
          )}
          {/* expired job */}
          {expired && (
            <Label
              rect
              noBackground
              bordered
              theme="accent300"
              height={24}
              margin="0 0 0 8px"
              style={{ textTransform: "uppercase" }}
            >
              {t("jobs.content.closed")}
            </Label>
          )}
        </Card.Header>

        <Card.Content>
          {/* expired job */}
          <Title
            loaded={loaded}
            level={2}
            fontSize="16px"
            lineHeight="120%"
            fontWeight={500}
            margin="0 0 auto"
            color={expired ? "accent300" : "black"}
          >
            {title}
          </Title>
          <Text fontSize={12} loaded={loaded}>
            {location}
          </Text>
        </Card.Content>

        <Card.Footer separator="small" padding="0 16px 8px">
          <PositionDetails
            isExpiredJob={expired}
            messagesCount={application && application.messages_count ? application.messages_count : 0}
            campaignEndDate={campaignEndDate}
            loaded={loaded}
          />
        </Card.Footer>
      </Card>
    );
  }
);

export default observer(ViewItem);
