import { observer } from "mobx-react";
import { Show } from "@styled-icons/boxicons-regular";
import React, { useContext } from "react";
import { Col, Row } from "react-grid-system";

import SvgContent from "b2c/components/SvgContent";
import { ModalContext } from "contexts/modal";
import JobModalContent from "b2c/components/JobModalContent";
import WorkExperienceIco from "images/b2c/profilePage/WorkExperienceIco.svg";
import FastApply from "b2c/components/FastApply";
import { getTimeSince } from "utils/helpers";
import { useTranslation } from "b2c/hooks/useTranslation";
import useStore from "../../../../contexts/store";
import ThemeContext from "../../../../contexts/theme";
import { Button, Card, Text, Title } from "../../../../components/Core";
import InstantFeedbackBanner from "../../../../components/InstantFeedbackBanner";
import { StyledInfoList } from "./styled";
import { languageFormat } from "../../../../constants/main";

export const Details = ({
  loading,
  id,
  title,
  company_name: companyName,
  location,
  campaign_end_date: campaignEndDate = 0,
  instant_feedback: instantFeedback = {},
  application,
  isAssigmentRequested,
  isFastApply,
  ...rest
}) => {
  const { getPosition } = useStore("JobSearch");
  const theme = useContext(ThemeContext);
  const { toggle } = useContext(ModalContext);
  const { t, language } = useTranslation();
  const languageFormatType = languageFormat[language];

  const endDate = campaignEndDate
    ? new Date(campaignEndDate * 1000).toLocaleString(languageFormatType, {
        day: "numeric",
        month: "short",
        year: "numeric"
      })
    : "Today";
  const remainingTime = getTimeSince(campaignEndDate * 1000);
  const daysLeft = Math.ceil((campaignEndDate - Date.now() / 1000) / (3600 * 24));
  const expired = daysLeft < 0;

  const leftAssigment = getTimeSince(application?.complete_due_date).replace("remaining", "to complete");
  const expiredDateText = !expired && daysLeft === 0 ? t("jobs.content.closing_today") : remainingTime;

  const handleModalContent = () => {
    getPosition(id);
    toggle(<JobModalContent />, t("application.job_description"));
  };

  return (
    <Card wideOnMobile shadow style={{ margin: "20px 0 15px" }} {...rest}>
      <Card.Header style={{ display: "block" }}>
        <Row justify="between" style={{ width: "100%" }}>
          <Col width="auto">
            <div style={{ display: "flex" }}>
              <SvgContent style={{ flex: "0 0 auto", margin: "0 10px 0 0" }}>
                <WorkExperienceIco />
              </SvgContent>
              <Title
                level={4}
                fontWeight={700}
                style={{ display: "inline-flex", alignItems: "center" }}
                loading={loading}
                truncatedLine={1}
              >
                {title}
              </Title>
            </div>
            <FastApply t={t} isShow={isFastApply} margin="12px 0" isAssigmentRequested={isAssigmentRequested} />
          </Col>

          <Col sm="content">
            <Button theme="transparent" color="secondary300" onClick={handleModalContent} padding={0}>
              <Show width={20} style={{ marginRight: 4 }} />
              {t("application.show_job_description")}
            </Button>
          </Col>
        </Row>
      </Card.Header>

      <Card.Separator margin="10px 0 0" />

      <Card.Content padding="15px 15px 0">
        <StyledInfoList theme={theme}>
          <Row>
            <Col className="infolist-col" sm={4}>
              <Text fontWeight={700} margin="0 0 2px">
                {t("application.company")}
              </Text>
              <Text loading={loading} color="black400">
                {companyName}
              </Text>
            </Col>
            <Col className="infolist-col" sm={4}>
              <Text fontWeight={700} margin="0 0 2px">
                {t("application.location")}
              </Text>
              <Text loading={loading} color="black400">
                {location}
              </Text>
            </Col>
            <Col className="infolist-col" sm={4}>
              {isAssigmentRequested ? (
                <>
                  <Text fontWeight={700} margin="0 0 2px">
                    {t("application.assignment_deadline")}
                  </Text>
                  <Text loading={loading} color="accent400">
                    {leftAssigment}
                  </Text>
                </>
              ) : (
                <>
                  <Text fontWeight={700} margin="0 0 2px">
                    {t("application.application_date")}
                  </Text>
                  <Text loading={loading} color="black400">
                    {endDate} ({expiredDateText})
                  </Text>
                </>
              )}
            </Col>
          </Row>
        </StyledInfoList>

        {!loading && instantFeedback.activated && <InstantFeedbackBanner position_id={id} {...instantFeedback} />}
      </Card.Content>
    </Card>
  );
};

export default observer(Details);
