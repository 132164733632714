import styled from "styled-components";
import unicafMobile from "images/b2c/promo/unicafMobile.png";
import unicafBigMobile from "images/b2c/promo/unicafBigMobile.png";
import unicafMiddleDesktop from "images/b2c/promo/unicafMiddleDesktop.png";
import unicafDesktop from "images/b2c/promo/unicafDesktop.png";

export const PromoImage = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
  max-width: 100%;
  max-height: 560px;
  width: 100%;
  ${({ $styled }) => $styled}
`;

export const PromoUniCaf = styled.section`
  max-width: 1360px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
`;

export const PromoUniCafBackground = styled.a`
  background-image: url(${unicafMobile});
  width: 300px;
  height: 50px;
  margin: 0 auto;
  display: block;

  @media screen and (min-width: 460px) {
    background-image: url(${unicafBigMobile});
    width: 320px;
  }

  @media screen and (min-width: 900px) {
    background-image: url(${unicafMiddleDesktop});
    width: 888px;
    height: 60px;
  }

  @media screen and (min-width: 1024px) {
    background-image: url(${unicafDesktop});
    width: 970px;
    height: 90px;
  }
`;
