import { useEffect } from "react";
import { observer } from "mobx-react";

import { useLocation } from "react-router-dom";
import { usePrev } from "utils/hooks";
import { getPathRoot, getLastUrlLevel } from "utils/helpers";

import useStore from "../../contexts/store";

const UserStatusManager = ({ routes = [] }) => {
  const { user, getUserStatus } = useStore("User");
  const { pathname } = useLocation();
  const pathRoot = getPathRoot(pathname);
  const previousRoot = usePrev(pathRoot);
  const lastLevel = getLastUrlLevel(pathname);

  const matches =
    routes.some(route => route == lastLevel) ||
    (routes.some(route => pathname.includes(route)) && pathRoot != previousRoot);

  useEffect(() => {
    return () => {
      if (matches && user?.id) getUserStatus();
    };
  }, [pathname]);

  return null;
};

export default observer(UserStatusManager);
