import React, { useContext } from "react";
import { observer } from "mobx-react";

import { useTranslation } from "b2c/hooks/useTranslation";
import { getCompanyName } from "b2c/contexts/branded";
import { Title, Button } from "b2c/components/Core";
import { SpeciesJobsContent, SpeciesJobsFooter, FuzuYear, SpeciesJobsCountries } from "./styled";
import { GuestVisible } from "../../../../components/WithAuth";
import LinkWithFullUrl from "../../../../../components/LinkWithFullUrl";
import { globalAfrica } from "../../../../constants/landing";
import CountryContext from "../../../../contexts/country";
import useStore from "../../../../contexts/store";
import { useLanguageAndCountryUrl } from "../../../../hooks";

const SpeciesJobs = ({ searchFilters }) => {
  const towns = searchFilters?.towns || [];
  const employers = searchFilters?.employers || [];
  const year = new Date().getFullYear();
  const { country = globalAfrica } = useContext(CountryContext);
  const { global_brand } = useStore("initialState");
  const isBarona = global_brand?.name?.includes("Barona");
  const { t } = useTranslation();
  const languageAndLanguage = useLanguageAndCountryUrl();

  const countries = [
    {
      name: "jobs.content.africa",
      slug: "global"
    },
    {
      name: "jobs.content.kenya",
      slug: "kenya"
    },
    {
      name: "jobs.content.nigeria",
      slug: "nigeria"
    },
    {
      name: "jobs.content.uganda",
      slug: "uganda"
    }
  ];

  const selectCountry = () => {
    const constructHref = slug => {
      let href = "";
      if (slug === "global") {
        href = "/job";
      } else {
        href = `/${slug}/job`;
      }

      return href;
    };

    const countriesList = countries
      ?.filter(item => item?.slug !== country?.slug)
      .filter(item => {
        if (country?.id === "global" || !country) {
          return item.slug !== "global";
        }

        return item;
      })
      .map(({ name, slug }) => (
        <Button.Link
          as={LinkWithFullUrl}
          to={constructHref(slug)}
          key={name}
          style={{
            fontWeight: "500",
            fontSize: "12px",
            lineHeight: "18px",
            color: "#408BFC"
          }}
          theme="transparent"
          height="20px"
        >
          {t(name)}
        </Button.Link>
      ));
    return <>{countriesList}</>;
  };

  const employersList = employers.map(({ name, path }, index) => (
    <Button.Link
      key={name}
      to={path}
      theme="transparent"
      style={{
        fontWeight: "600",
        fontSize: "12px",
        lineHeight: "18px",
        padding: "0 5px 0 0",
        color: "#0E50B5"
      }}
      height="18px"
    >
      {name}
      {`${index + 1 !== employers.length ? "," : ""} `}
    </Button.Link>
  ));

  const townsList = towns.map(({ name, path }, index) => (
    <Button.Link
      key={name}
      as={LinkWithFullUrl}
      to={path}
      theme="transparent"
      style={{
        fontWeight: "600",
        fontSize: "12px",
        lineHeight: "18px",
        padding: "0 5px 0 0",
        color: "#408BFC"
      }}
      height="18px"
    >
      {`${t("jobs.search.jobs_in")} ${name}`}
      {`${index + 1 !== towns.length ? "," : ""} `}
    </Button.Link>
  ));

  return (
    <GuestVisible>
      <SpeciesJobsContent>
        <div style={{ marginBottom: "20px" }}>
          <Title
            style={{
              fontWeight: "600",
              fontSize: "12px",
              lineHeight: "18px",
              color: "#212121"
            }}
            level={2}
          >
            {t("jobs.content.top_cities")}
          </Title>
          {townsList}
        </div>
        <div>
          <Title
            style={{
              fontWeight: "600",
              fontSize: "12px",
              lineHeight: "18px",
              color: "#212121"
            }}
            level="2"
          >
            {t("jobs.content.companies_hiring_now")}
          </Title>
          {employersList}
        </div>

        {!isBarona && (
          <>
            <Title
              style={{
                fontWeight: "600",
                fontSize: "12px",
                lineHeight: "18px",
                color: "#212121",
                margin: "24px 0 8px 0"
              }}
              level="2"
            >
              {t("jobs.content.country_region")}
            </Title>
            <SpeciesJobsCountries>{selectCountry()}</SpeciesJobsCountries>
          </>
        )}

        <Title
          style={{
            fontWeight: "600",
            fontSize: "12px",
            lineHeight: "18px",
            color: "#212121",
            margin: "24px 0 8px 0"
          }}
          level="2"
        >
          {getCompanyName()}
        </Title>
        <SpeciesJobsFooter>
          <Button.Link
            style={{
              fontWeight: "500",
              fontSize: "12px",
              lineHeight: "18px",
              color: "#408BFC",
              paddingLeft: 0
            }}
            theme="transparent"
            to={`${languageAndLanguage}/about`}
            height="20px"
          >
            {t("jobs.content.about")} {getCompanyName()}
          </Button.Link>
          {!isBarona && (
            <Button.Link
              style={{
                fontWeight: "500",
                fontSize: "12px",
                lineHeight: "18px",
                color: "#408BFC",
                borderLeft: "1px solid #ccc",
                borderRight: "1px solid #ccc"
              }}
              theme="transparent"
              to="/careers"
              height="20px"
            >
              {t("jobs.content.careers_at_fuzu")}
            </Button.Link>
          )}

          <Button.Link
            style={{
              fontWeight: "500",
              fontSize: "12px",
              lineHeight: "18px",
              color: "#408BFC"
            }}
            theme="transparent"
            to={`${languageAndLanguage}/contact`}
            height="20px"
          >
            {t("jobs.content.contact_us")}
          </Button.Link>
        </SpeciesJobsFooter>
        <FuzuYear>
          © {getCompanyName()}
          {!isBarona && " Ltd"} {` ${year}`}
        </FuzuYear>
      </SpeciesJobsContent>
    </GuestVisible>
  );
};

export default observer(SpeciesJobs);
