import styled from "styled-components";

export const StyledRoot = styled.div`
  top: 0;
  left: 0;
  z-index: 101;
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  &:focus {
    border: none;
    outline: none;
  }
  ${({ $styled }) => $styled}
`;

export const StyledContent = styled.div`
  width: 304px;
  height: 100%;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
`;

export const StyledOverlay = styled.div`
  width: 100%;
  height: 100%;
  flex-grow: 1;
  background: #1c1e2085;
`;
