import React from "react";

import { useTranslation } from "b2c/hooks/useTranslation";
import NotificationItem from "../NotificationItem";
import NotificationsCenterLayout from "../NotificationsCenterLayout";

const NotificationsList = ({ notifications, onBackward, onClose }) => {
  const { t } = useTranslation();

  return (
    <NotificationsCenterLayout title={t("notifications.title")} onBackward={onBackward} onClose={onClose}>
      {notifications.list.map(notificationId => (
        <NotificationItem key={notificationId} {...notifications.entities[notificationId]} />
      ))}
    </NotificationsCenterLayout>
  );
};

export default NotificationsList;
