import React from "react";

import { useTranslation } from "b2c/hooks/useTranslation";
import { Button, Text } from "b2c/components/Core";
import ModalContent from "../ModalContents";

const LeaveModalContent = ({ from, close }) => {
  const { t } = useTranslation();

  return (
    <ModalContent
      title={t("cv_creator.leave.title")}
      Actions={[
        <Button.Link to={from || "/home"} theme="grey200" onClick={close}>
          {t("cv_creator.leave.button_1")}
        </Button.Link>,
        <Button theme="primary" onClick={close}>
          {t("cv_creator.leave.button_2")}
        </Button>
      ]}
    >
      <Text textAlign="center" margin="0 auto 36px">
        {t("cv_creator.leave.description")}
      </Text>
    </ModalContent>
  );
};

export default LeaveModalContent;
