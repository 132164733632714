import { useEffect } from "react";

const Mounted = ({ onSectionMount, children }) => {
  useEffect(() => {
    onSectionMount();
  }, []);

  return children || null;
};

export default Mounted;
