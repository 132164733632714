import React from "react";
import { Row, Col, Visible, Hidden } from "react-grid-system";

import industryImageMap from "utils/industryImageMap";
import { capitalize, getTimeSpanString } from "utils/helpers";
import logo_placeholder from "images/b2c/logo-placeholder.png";
import FuzuPointsIco from "images/fuzuPointsIco.svg";
import { Text, Image, Label, Spinner } from "../Core";
import SaveButton from "../SaveButton";
import ShareButton from "../ShareButton";
import { MainRow } from "./styled";

// Job Row Type
const jobRow = (
  {
    id,
    company_logo,
    company_name,
    title,
    location,
    job_type,
    industry,
    views_count = 0,
    campaign_start_date,
    requirements = {},
    saved,
    slug
  } = {},
  handleSaving,
  savedContent,
  t
) => {
  const dateSince = new Date(new Date() - new Date(campaign_start_date * 1000)).getTime();
  const { Icon } = industryImageMap(industry);
  const Employer = (
    <MainRow>
      <Hidden sm xs>
        {company_logo ? (
          <Image
            padding={8}
            className="logo"
            objectFit="contain"
            width={100}
            src={company_logo}
            alt={company_name}
            height={60}
            placeholder={logo_placeholder}
            margin="0 20px 0 0"
          />
        ) : (
          <Icon className="industry-icon logo" width={100} height={60} style={{ margin: "0 20px 0 0" }} />
        )}
        <Text inline fontWeight={500} margin="auto 0" fontSize={14}>
          {company_name}
        </Text>
      </Hidden>
      <Visible sm xs>
        <Text fontWeight={400} margin="5px 0 0" fontSize={14}>
          {company_name}
        </Text>
      </Visible>
    </MainRow>
  );

  const Title = (
    <>
      <Hidden sm xs>
        <Text fontWeight={500} margin="auto 0" fontSize={14}>
          {title}
        </Text>
      </Hidden>
      <Visible sm xs>
        <Text fontWeight={700} margin="5px 0 0" fontSize={16}>
          {title}
        </Text>
      </Visible>
    </>
  );

  const Location = (
    <>
      <Hidden sm xs>
        <Text fontWeight={500} margin="auto 0" fontSize={14}>
          {location}
        </Text>
      </Hidden>
      <Visible sm xs>
        <Text fontWeight={400} margin="0" fontSize={14}>
          {location}
        </Text>
      </Visible>
    </>
  );

  const JobType = (
    <Hidden sm xs>
      <Text fontWeight={400} margin="auto 0" fontSize={14}>
        {job_type ? capitalize(job_type.replace("_", "-")) : ""}
      </Text>
    </Hidden>
  );

  const Applicants = (
    <Hidden sm xs>
      <Text fontWeight={400} margin="auto 0" fontSize={14}>
        {views_count}
      </Text>
    </Hidden>
  );

  const RequiredExperience = (
    <Hidden sm xs>
      <Text fontWeight={400} margin="auto 0" fontSize={14}>
        {requirements.work_experience}
      </Text>
    </Hidden>
  );

  const Published = (
    <>
      <Hidden sm xs>
        <Text fontWeight={400} margin="auto 0" fontSize={14}>
          {getTimeSpanString(dateSince)}
        </Text>
      </Hidden>
      <Visible sm xs>
        {getTimeSpanString(dateSince)}
        <Text inline margin="0 6px" fontSize={18} color="grey200" aria-hidden="true">
          •
        </Text>
        {views_count || 0} views
      </Visible>
    </>
  );

  const Save = (
    <Row nowrap gutterWidth={5} justify="end">
      <Col width="content">
        <SaveButton
          saved={saved}
          handleSaving={isSaved => handleSaving(id, "job", isSaved)}
          title="Save Job"
          titleSaved="Remove Job From Saved"
        />
      </Col>
      <Col width="content">
        <ShareButton path={`/jobs/${slug}`} prefix={t("global.share.position")} name={title} />
      </Col>
    </Row>
  );

  return {
    to: `/job?filters[job_id]=${id}`,
    item: [Employer, Title, Location, RequiredExperience, JobType, Applicants, Published].concat(
      savedContent ? [] : Save
    )
  };
};

// Course Row Type
const courseRow = (
  { id, name, category, participants_count = 0, modules_count = 0, likes = 0, saved } = {},
  handleSaving,
  savedContent,
  t
) => {
  const Title = (
    <Text fontWeight={500} margin="auto 0" fontSize={14}>
      {name}
    </Text>
  );

  const Save = (
    <Row nowrap gutterWidth={5} justify="end">
      <Col width="content">
        <SaveButton
          saved={saved}
          handleSaving={isSaved => handleSaving(id, "course", isSaved)}
          title="Save Course"
          titleSaved="Remove Course From Saved"
        />
      </Col>
      <Col width="content">
        <ShareButton path={`/learn/${id}`} prefix={t("global.share.course")} name={name} />
      </Col>
    </Row>
  );

  const Modules = (
    <>
      <Hidden sm xs>
        {modules_count}
      </Hidden>
      <Visible sm xs>
        Modules <b>{modules_count}</b>
        <Text inline margin="0 6px" fontSize={18} color="grey200" aria-hidden="true">
          •
        </Text>
        Participants <b>{participants_count}</b>
        <Text inline margin="0 6px" fontSize={18} color="grey200" aria-hidden="true">
          •
        </Text>
        Likes <b>{likes}</b>
      </Visible>
    </>
  );
  const Participants = (
    <>
      <Hidden sm xs>
        {participants_count}
      </Hidden>
    </>
  );
  const Likes = (
    <>
      <Hidden sm xs>
        {likes}
      </Hidden>
    </>
  );

  return {
    to: `/learn/${id}`,
    item: [Title, category, Modules, Participants, Likes].concat(savedContent ? [] : Save)
  };
};

// Article Row Type
const articleRow = (
  {
    id,
    headline,
    created_at = "",
    thumbnail_image,
    views = 0,
    likes = 0,
    comments_count = 0,
    written_by = {},
    saved,
    path
  } = {},
  handleSaving,
  savedContent,
  t
) => {
  const published = new Date(created_at).toDateString();

  const Headline = (
    <MainRow>
      <Image
        className="logo"
        width={100}
        src={thumbnail_image}
        alt={headline}
        height={60}
        placeholder={logo_placeholder}
      />
      <Text inline fontWeight={500} margin="auto 24px" fontSize={14}>
        {headline}
      </Text>
    </MainRow>
  );

  const Pulished = (
    <Text fontWeight={500} margin="auto 0" fontSize={14}>
      {published}
    </Text>
  );

  const Save = (
    <Row nowrap gutterWidth={5} justify="end">
      <Col width="content">
        <SaveButton
          saved={saved}
          handleSaving={isSaved => handleSaving(id, "article", isSaved)}
          title="Save Article"
          titleSaved="Remove Article From Saved"
        />
      </Col>
      <Col width="content">
        <ShareButton path={path} prefix={t("global.share.article")} name={headline} />
      </Col>
    </Row>
  );

  const Views = (
    <>
      <Hidden sm xs>
        {views}
      </Hidden>
      <Visible sm xs>
        Views <b>{views}</b>
        <Text inline margin="0 6px" fontSize={18} color="grey200" aria-hidden="true">
          •
        </Text>
        Likes <b>{likes}</b>
        <Text inline margin="0 6px" fontSize={18} color="grey200" aria-hidden="true">
          •
        </Text>
        Comments <b>{comments_count}</b>
      </Visible>
    </>
  );
  const Likes = (
    <>
      <Hidden sm xs>
        {likes}
      </Hidden>
    </>
  );
  const CommentsCount = (
    <>
      <Hidden sm xs>
        {comments_count}
      </Hidden>
    </>
  );

  return {
    to: path,
    item: [Headline, Pulished, (written_by && written_by.name) || "Not specified", Views, Likes, CommentsCount].concat(
      savedContent ? [] : Save
    )
  };
};

// Company Row Type
const companyRow = ({ name, logo, jobs_count = 0, path } = {}, t) => {
  const Headline = (
    <MainRow>
      <Image className="logo" width={100} src={logo} alt={name} height={60} placeholder={logo_placeholder} />
      <Text inline fontWeight={500} margin="auto 24px" fontSize={14}>
        {name}
      </Text>
    </MainRow>
  );

  const JobsCount = (
    <>
      <Hidden sm xs>
        {jobs_count}
      </Hidden>
      <Visible sm xs>
        <Text margin="5px 0 0">
          Job count <b>{jobs_count}</b>
        </Text>
      </Visible>
    </>
  );

  const Share = (
    <Row nowrap gutterWidth={5} justify="end">
      <Col width="content">
        <ShareButton path={path} prefix={t("global.share.course")} name={name} />
      </Col>
    </Row>
  );

  return {
    to: path,
    item: [Headline, JobsCount, Share]
  };
};

const inviteRow = ({ referee_email, amount = 20, created_at, languageFormatType } = {}) => {
  const Headline = (
    <>
      <Visible sm xs>
        <Text fontWeight={600} margin="0 0 5px" fontSize={14}>
          Referee
        </Text>
      </Visible>
      <Text fontWeight={400} margin="auto 0" fontSize={14}>
        {referee_email}
      </Text>
    </>
  );

  const Points = (
    <>
      <Visible sm xs>
        <Text fontWeight={600} margin="15px 0 5px" fontSize={14}>
          Received Points
        </Text>
      </Visible>
      {amount > 0 && (
        <Label
          Icon={<FuzuPointsIco />}
          fontSize={16}
          fontWeight={600}
          color="success200"
          noBackground
          margin="0"
          padding="0"
        >
          + {amount} Points
        </Label>
      )}
    </>
  );

  const CreatedAt = (
    <>
      <Visible sm xs>
        <Text fontWeight={600} margin="15px 0 5px" fontSize={14}>
          Date
        </Text>
      </Visible>
      {created_at && (
        <Text fontWeight={400} margin="auto 0" fontSize={14}>
          {new Date(created_at).toLocaleString(languageFormatType, { month: "long", day: "numeric", year: "numeric" })}
        </Text>
      )}
    </>
  );

  return {
    to: "",
    item: [Headline, Points, CreatedAt]
  };
};

// Loading Content Row Type
const LoadingRows = (headings = []) => {
  return Array(5).fill({
    item: headings.map((h, i) => (i == 0 ? <Spinner key={i} size="42px" /> : <Text key={i} margin="8px 0" loading />))
  });
};

export { jobRow, courseRow, articleRow, companyRow, inviteRow, LoadingRows };
