import React, { useEffect } from "react";
import { observer } from "mobx-react";

import useStore from "../../../../contexts/store";
import { LoadingContent } from "../../../../components/Core/Spinner";
import { Image, Text } from "../../../../components/Core";

const HeroForthStep = observer(({ token }) => {
  const { loadItemByToken, record } = useStore("CareerHero");

  useEffect(() => {
    loadItemByToken(token);
  }, []);

  return (
    <div style={{ textAlign: "center" }}>
      <LoadingContent loading={!record?.image_url} size="40px">
        {record?.image_url && <Image style={{ margin: "40px auto" }} width="570px" src={record?.image_url} />}
      </LoadingContent>
      <Text
        fontSize={16}
        fontWeight={600}
        width="100%"
        textAlign="center"
        style={{
          fontFamily: "Poppins",
          fontStyle: "normal",
          textAlign: "center",
          fontWeight: "normal"
        }}
      >
        Want to create a similar thank you card? Celebrate Your
      </Text>
      <Text
        fontSize={16}
        fontWeight={600}
        width="100%"
        textAlign="center"
        style={{
          fontFamily: "Poppins",
          fontStyle: "normal",
          textAlign: "center",
          fontWeight: "normal"
        }}
      >
        career hero at <a href="https://www.fuzu.com/nigeria/career-hero">www.fuzu.com/nigeria/career-hero</a>
      </Text>
    </div>
  );
});

export default HeroForthStep;
