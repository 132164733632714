import React from "react";
import howItWorks1 from "images/b2c/landing-page/howItWorksBaronaAbout1.png";
import howItWorks2 from "images/b2c/landing-page/howItWorksBaronaAbout2.png";

import { useTranslation } from "b2c/hooks/useTranslation";
import { Container, Content, Item, ImageContent, Title, Text } from "./styled";

const BaronaHowItWorks = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Content>
        <Item>
          <ImageContent>
            <img src={howItWorks1} alt="how it works barona" />
          </ImageContent>
          <div>
            <Title>{t("about.how_it_works.title_1")}</Title>
            <Text>{t("about.how_it_works.description_1_1")}</Text>
            <Text>{t("about.how_it_works.description_1_2")}</Text>
          </div>
        </Item>
        <Item>
          <ImageContent>
            <img src={howItWorks2} alt="how it works barona" />
          </ImageContent>
          <div>
            <Title>{t("about.how_it_works.title_2")}</Title>
            <Text>{t("about.how_it_works.description_2_1")}</Text>
            <Text>{t("about.how_it_works.description_2_3")}</Text>
            <Text>{t("about.how_it_works.description_2_3")}</Text>
          </div>
        </Item>
      </Content>
    </Container>
  );
};

export default BaronaHowItWorks;
