import styled from "styled-components";

export const Modal = styled.div`
  width: 340px;

  @media screen and (min-width: 768px) {
    width: 570px;
  }
`;

export const Description = styled.p`
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  display: block;
  margin: 0 0 24px 0;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
`;

export const Cancel = styled.button`
  border-radius: 8px;
  background: #eee;
  color: #333;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;
  cursor: pointer;
`;

export const HideProfile = styled.button`
  border-radius: 8px;
  background: #eb3737;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 168px;
  cursor: pointer;
`;
