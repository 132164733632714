import React, { useContext } from "react";
import { ScreenClassContext } from "react-grid-system";

import { Card, Button, Text, Title, Image } from "../../Core";
import withLoading from "../../withLoading";

const UserItem = ({ loaded, slug, avatar, current_job, current_employer }) => {
  const screen = useContext(ScreenClassContext);
  const isSm = /xs|sm/.test(screen);

  return (
    <Card
      theme="white"
      clickable
      shadow
      style={{
        height: "100%",
        border: "1px solid #E1E1E1",
        boxShadow: "none"
      }}
    >
      <Card.Header height={isSm ? "70px" : "100px"}>
        <Image
          backgroundColor="white"
          src={avatar}
          alt={slug}
          width={90}
          height={90}
          borderRadius="50%"
          margin="auto"
          objectFit="contain"
        />
      </Card.Header>

      <Card.Content style={{ flex: "1 0 auto" }} padding={isSm ? "5px 16px" : "8px 16px"}>
        <Title
          level={5}
          textAlign="center"
          fontWeight={600}
          margin="0 auto 5px"
          loaded={loaded}
          title={slug}
          height={22}
        >
          {slug}
        </Title>
        {current_job && (
          <Text color="black300" textAlign="center" margin="0 auto" loaded={loaded}>
            {current_job} at {current_employer}
          </Text>
        )}
      </Card.Content>

      <Card.Footer padding="20px 16px 25px">
        <Button margin="auto" height={32} padding="0 25px" fontSize={14} fontWeight={600}>
          View profile
        </Button>
      </Card.Footer>
    </Card>
  );
};

UserItem.Placeholder = () => {
  return (
    <Card theme="white" shadow>
      <Card.Header height={100} />

      <Card.Content>
        <Title loading level={5} fontSize={20} margin="0 auto 6px" />
        <Text loading margin="0 auto" />
      </Card.Content>

      <Card.Footer padding="20px 16px 25px">
        <Text loading margin="7px auto 6px" />
      </Card.Footer>
    </Card>
  );
};

export default withLoading(UserItem, UserItem.Placeholder);
