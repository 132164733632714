import React, { useState, useContext } from "react";
import { Row, Col, ScreenClassContext } from "react-grid-system";
import { useTranslation } from "b2c/hooks/useTranslation";
import parse from "html-react-parser";

import { Card, Textarea, Button, Text, Tipbox, List } from "b2c/components/Core";

const CoverLetter = ({ processing, cover_letter = "", back, handleUpdate }) => {
  const screen = useContext(ScreenClassContext);
  const isSm = ["xs", "sm"].includes(screen);
  const [letter, setLetter] = useState(cover_letter || "");
  const [error, setError] = useState();
  const { t } = useTranslation();

  const wordCount = letter.length > 0 ? letter.trim().split(/\s+/).length : 0;

  const validateCoverLetter = () =>
    new Promise((resolve, reject) => {
      if (wordCount >= 50 && wordCount <= 300) resolve();
      else reject(t("application.cover_letter.error"));
    });

  const handleSubmit = () => {
    validateCoverLetter()
      .then(() => handleUpdate(letter))
      .catch(errors => setError(errors))
      .then(res => {
        if (res) back();
      });
  };

  const handlePasteAction = e => {
    e.preventDefault();
    const input = e.target;
    const formatedText = (e.clipboardData || window.clipboardData).getData("text/plain").replace(/\s+/g, " ");

    const isSuccess = document.execCommand("insertText", false, formatedText);

    if (!isSuccess && typeof input.setRangeText === "function") {
      const start = input.selectionStart;
      input.setRangeText(formatedText);

      input.selectionStart = input.selectionEnd = start + formatedText.length;
    }
  };

  return (
    <>
      <Card bordered wideOnMobile>
        <Card.Header>
          <Text textAlign="center" fontSize={16} margin="auto">
            {parse(t("application.cover_letter.description"))}
          </Text>
        </Card.Header>

        <Card.Content>
          <Tipbox
            margin="8px 0"
            title={t("application.cover_letter.tip.title")}
            text={t("application.cover_letter.tip.sub-title")}
            expandContent={
              <List style={{ margin: "10px 15px" }}>
                <List.Item bulletColor="secondary300">{t("application.cover_letter.tip.description_1")}</List.Item>
                <List.Item bulletColor="secondary300">{t("application.cover_letter.tip.description_2")}</List.Item>
                <List.Item bulletColor="secondary300">{t("application.cover_letter.tip.description_3")}</List.Item>
                <List.Item bulletColor="secondary300">{t("application.cover_letter.tip.description_4")}</List.Item>
              </List>
            }
          />
        </Card.Content>

        <Card.Footer style={{ display: "block" }}>
          <Textarea
            data-cy="cover-letter-textarea"
            disabled={processing}
            value={letter}
            onFocus={() => setError("")}
            width="100%"
            inputHeight="165px"
            resize="vertical"
            onChange={e => setLetter(e.target.value)}
            errorMessage={error}
            onPaste={handlePasteAction}
          />
          <Text fontSize={12}>
            <b>{t("application.cover_letter.word_count")}: </b>
            {wordCount}
          </Text>
        </Card.Footer>
      </Card>

      <Row justify={isSm ? "center" : "start"} style={{ padding: "24px 0" }}>
        <Col lg={2} xs={6}>
          <Button theme="grey200" width="100%" onClick={back}>
            {t("global.back")}
          </Button>
        </Col>
        <Col lg={2} xs={6}>
          <Button
            color="primaryButtonLabelColor"
            processing={processing}
            width="100%"
            disabled={!letter}
            onClick={handleSubmit}
          >
            {t("global.save_button")}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default CoverLetter;
