import styled from "styled-components";

export const FloatingSummary = styled.div`
  position: sticky;
  width: 100%;
  max-width: 320px;
  top: 70px;
  left: 0;
  font-size: 14px;
  margin: ${({ $static }) => ($static ? "0" : "-135px")} 0 0 auto;
  @media screen and (max-width: 767px) {
    position: relative;
    margin: ${({ $static }) => ($static ? "0" : "-100px")} auto 0;
    top: 0;
    max-width: none;
  }
`;
