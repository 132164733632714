import styled from "styled-components";

export const ModalContentWrapper = styled.div`
  width: 768px;
  max-width: 100%;

  @media screen and (max-width: 767px) {
    margin: auto;
  }
`;
