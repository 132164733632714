import React, { useEffect, useMemo } from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx";

import { useDocumentTitle, useQueryParams } from "utils/hooks";
import Navigation from "b2c/components/TabNavigation";
import { useIsBarona } from "b2c/hooks";
import { useTranslation } from "b2c/hooks/useTranslation";
import { withTrackedRoute } from "../../hocs";
import Layout from "../../components/Layout";
import JobViewer from "./components/JobViewer";
import SearchSettings from "./components/SearchSettings";
import { GuestHidden } from "../../components/WithAuth";
import { getCompanyName, isCustomJkuat, withBrandedRedirect } from "../../contexts/branded";
import AuthorizedSubroute from "./components/AuthorizedSubroute";
import useStore from "../../contexts/store";
import { useShowToastMessage } from "../../hooks";

const JobSearch = ({
  location: { pathname, state: locationState },
  match: {
    params: { slug = "" }
  },
  history
}) => {
  const {
    JobSearch: {
      state,
      h1_title,
      meta,
      initializeJobSearch,
      clearPageInfo,
      results,
      settings,
      totalCount,
      activeCount,
      searchFilters = {},
      applicationFilters = {},
      params: { appliedFilters = {}, per_page = 10 },
      appliedPageInfo,
      applyFilter,
      addIdParameter,
      setPage,
      resetFilters,
      appliedCount,
      settingSections,
      updateSettings,
      navigationMethod,
      setNavigationMethod,
      unassignHistory,
      positionNotFound,
      trackJobView,
      jobSearchUrlBuilder,
      setDefaultCountryId,
      defaultCountryId,
      getTownJobsByTown,
      setIsSelectedCurrentCountry,
      getCommonLocations,
      commonCountries,
      getGlobalLocations,
      globalLocations
    },
    Saved: { handleSaving },
    User: { user = {} },
    Application: { cancelApplication },
    initialState: { location }
  } = useStore();
  const query = useQueryParams();
  const customJkuat = isCustomJkuat();
  const isBarona = useIsBarona();
  const { t } = useTranslation();

  const updatedRoutes = useMemo(() => {
    const routes = [
      { route: "", title: t("jobs.tabs.jobs"), selected: !/saved|applications|settings/.test(slug) },
      { route: "saved", title: t("jobs.tabs.saved") },
      { route: "applications", title: t("jobs.tabs.applications") },
      { route: "settings", title: t("jobs.tabs.settings") }
    ];

    return customJkuat ? routes.filter(item => item.route !== "settings") : routes;
  }, [customJkuat]);

  const page = parseInt(appliedPageInfo?.query?.page || location?.query_params?.page || 1);

  useEffect(() => {
    initializeJobSearch(
      pathname,
      query,
      history,
      slug,
      user?.ml_recommedations_active,
      locationState?.job_id,
      !!user?.id,
      locationState?.disable
    );
  }, [pathname]);

  useEffect(() => {
    getCommonLocations();
    return () => {
      unassignHistory();
      clearPageInfo();
    };
  }, []);

  useShowToastMessage();

  useDocumentTitle(getCompanyName());

  return (
    <BrandedAuthorizedSubroute path={["/saved", "/applications", "/settings"]}>
      <JobSearchTabs slug={slug} routes={updatedRoutes} navigationMethod={navigationMethod} />
      {slug !== "settings" ? (
        <JobViewer
          state={state}
          slug={slug}
          title={h1_title || "Search jobs"}
          authorized={!!user?.id}
          jobId={appliedFilters.job_id}
          list={results}
          totalCount={totalCount}
          activeCount={activeCount}
          pageParams={{ page, per_page }}
          setPage={setPage}
          handleSaving={handleSaving}
          push={history.push}
          location={history.location}
          addIdParameter={addIdParameter}
          cancelApplication={cancelApplication}
          applyFilter={applyFilter}
          appliedFilters={appliedFilters}
          searchFilters={searchFilters}
          applicationFilters={applicationFilters}
          resetFilters={resetFilters}
          appliedCount={appliedCount}
          currentSorting={appliedFilters.sort_by}
          setNavigationMethod={setNavigationMethod}
          positionNotFound={positionNotFound}
          trackJobView={trackJobView}
          meta={meta}
          isBarona={isBarona}
          jobSearchUrlBuilder={jobSearchUrlBuilder}
          setDefaultCountryId={setDefaultCountryId}
          defaultCountryId={defaultCountryId}
          getPageInfo={getTownJobsByTown}
          setIsSelectedCurrentCountry={setIsSelectedCurrentCountry}
        />
      ) : (
        <GuestHidden>
          <Layout.Content
            size="xl"
            id="main"
            style={{
              display: "flex",
              flexDirection: "column",
              paddingTop: 16,
              paddingBottom: 72
            }}
            data-hide-footer-below
          >
            <SearchSettings
              loading={state.loading}
              sections={settingSections}
              settings={toJS(settings)}
              updateSettings={updateSettings}
              commonCountries={toJS(commonCountries)}
              globalLocations={toJS(globalLocations)}
              getGlobalLocations={getGlobalLocations}
            />
          </Layout.Content>
        </GuestHidden>
      )}
    </BrandedAuthorizedSubroute>
  );
};

const JobSearchTabs = ({ slug, routes, navigationMethod }) => {
  return (
    <GuestHidden>
      <Navigation innerLayoutSize="xl" margin="0" style={{ borderBottom: "2px solid #f3f3f3" }}>
        {routes.map(({ route, title }, index) => (
          <Navigation.Tab
            key={`job-route-${index}`}
            selected={slug === route}
            to={`/job${route ? `/${route}` : ""}`}
            onClick={navigationMethod}
            style={{ color: slug === route ? "#408BFC" : "#3E3E3E" }}
            $isNewSelectedColor
          >
            {title}
          </Navigation.Tab>
        ))}
      </Navigation>
    </GuestHidden>
  );
};

const BrandedAuthorizedSubroute = withBrandedRedirect(AuthorizedSubroute);

export default withTrackedRoute(observer(JobSearch));
