import React from "react";

import { B2bOfferSectionContainer, Title, LinkTo } from "./styled";

const BaronaTalentPoolIntro = ({ title, buttonText, linkTo }) => {
  return (
    <B2bOfferSectionContainer>
      <Title>{title}</Title>
      <LinkTo to={linkTo}>{buttonText}</LinkTo>
    </B2bOfferSectionContainer>
  );
};

export default BaronaTalentPoolIntro;
