import React from "react";
import { Row, Col } from "react-grid-system";

import { Title, Text, Spinner, RadioButton } from "b2c/components/Core";

const SeniorityStep = ({ seniority_levels = [], selected, onSelect }) => {
  return (
    <form noValidate>
      <Title id="label" level="3" fontWeight={500} margin="0 0 12px">
        Where are you with your career today?
      </Title>
      <Text lineHeight="140%" margin="0 0 40px" color="black300">
        This will help us create the perfect experience for you.
      </Text>

      <Row role="radiogroup" aria-labelledby="label">
        {seniority_levels.length <= 1 ? (
          <Col>
            <Spinner size="50px" />
          </Col>
        ) : (
          seniority_levels.map(({ title, description, id }) => (
            <Col sm={6} xs={12} key={id}>
              <RadioButton
                name="exp"
                value={id}
                id={id}
                checked={id === selected}
                boxedView
                Label={
                  <>
                    <Text fontSize={16} fontWeight={500}>
                      {title}
                    </Text>
                    <Text lineHeight="140%" color="black100">
                      {description}
                    </Text>
                  </>
                }
                onChange={() => onSelect(id)}
              />
            </Col>
          ))
        )}
      </Row>
    </form>
  );
};

export default SeniorityStep;
