import { Link } from "react-router-dom";
import React from "react";
import { features } from "b2c/constants/cvBuilder";
import ModalContent from "../ModalContents";
import { Button, List, Text } from "../../../../components/Core";

const PurchaseModalContent = ({
  product: { currency, discounted_price, price, slug, fuzu_points_needed_for_discount },
  from,
  close
}) => {
  return (
    <ModalContent
      title={`Purchase CV file - ${currency} ${discounted_price}`}
      Actions={[
        <Button theme="grey200" onClick={close}>
          Cancel
        </Button>,
        <Button.Link
          theme="primary"
          to={{ pathname: `/product/${slug}`, state: { discounted: true, from } }}
          onClick={close}
        >
          Purchase now
        </Button.Link>
      ]}
    >
      <List style={{ padding: "0 32px", margin: 16 }}>
        {features.map(f => (
          <List.Item key={f.split(0, 3)}>{f}</List.Item>
        ))}
      </List>
      {fuzu_points_needed_for_discount > 0 && (
        <Text fontSize={12} lineHeight="18px" textAlign="center" color="secondary300" margin="0 auto 24px">
          You will use <b>{fuzu_points_needed_for_discount} Fuzu Points.</b>
          <br />
          Normal price without Fuzu Points:{" "}
          <Text
            as={Link}
            fontWeight={600}
            fontSize={12}
            color="secondary300"
            className="link underlined"
            to={{ pathname: `/product/${slug}`, state: { discounted: false, from } }}
            onClick={close}
          >
            Pay {currency} {price}
          </Text>
        </Text>
      )}
    </ModalContent>
  );
};

export default PurchaseModalContent;
