import styled, { css } from "styled-components";

export const StepContainer = styled.div`
  ${({ $width, $margin }) => css`
    display: flex;
    width: ${$width};
    max-width: 100%;
    margin: ${$margin};
    overflow: hidden;
    &:focus {
      outline: none;
    }

    > div {
      width: calc(100% + 10px);
    }

    @media screen and (max-width: 767px) {
      margin: 16px 0 25px;
    }
  `}
`;

export const StepItem = styled.div`
  ${({ isActive, isCurrent, alwaysDisplay, isInteractive, theme }) => css`
    position: relative;
    font-size: 14px;
    padding-bottom: 18px;
    white-space: nowrap;
    width: 100%;
    text-align: center;
    color: ${isActive ? theme.black500 : theme.black200};
    font-weight: ${isActive ? "600" : "500"};
    transition: 0.2s ease;
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    ${isInteractive ? "cursor: pointer;" : ""}

    @media screen and (max-width: 767px) {
      ${isCurrent
        ? css`
            display: flex;
            padding-left: 8px;
          `
        : ""};
      /* flex: ${isActive ? "1 0 auto" : "0 0 0"}; */
    }
    &:before {
      content: "";
      display: flex;
      height: 6px;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      background: linear-gradient(
        to right,
        ${theme.primary300},
        ${theme.primary300} 50%,
        ${theme.grey200} 50%,
        ${theme.grey200} 100%
      );
      background-size: 205% 100%;
      background-position: ${isActive ? "0%" : "100%"} 50%;
      transition: 0.4s ease;
    }
    &:not(:last-child) {
      &:after {
        content: ">";
        color: ${theme.grey300};
        margin-left: auto;
        ${isCurrent ? "padding-left: 8px;" : ""}
      }
    }
    &:first-child {
      text-align: left;
    }
    &:last-child {
      text-align: right;
      overflow: hidden;
    }
    span {
      text-overflow: ellipsis;
      overflow: hidden;
      text-indent: 4px;

      @media screen and (max-width: 767px) {
        display: ${isCurrent || alwaysDisplay ? "inline" : "none"};
      }
    }
  `}
`;
