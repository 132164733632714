import React, { useEffect, useLayoutEffect } from "react";
import { observer } from "mobx-react";

import { withTrackedRoute } from "../../hocs";
import CourseDescription from "./components/CourseDescription";
import ModuleContent from "./components/ModuleContent";
import CourseCompleted from "./components/CourseCompleted";
import { Suggestion } from "../../components/Core";
import useStore from "../../contexts/store";
import { useDocumentTitle } from "../../../utils/hooks";
import { getCompanyName } from "../../contexts/branded";

const CourseScreen = ({
  match: {
    params: { id }
  },
  history: { push },
  location: { state }
}) => {
  const {
    Learning: {
      CourseStore: {
        state: { loading, processing },
        course,
        module,
        getCourseDetails,
        startCourse,
        cancelCourse,
        getCourseModule,
        completeModule,
        postponeModule,
        addComment,
        updateComment,
        removeComment,
        likeCourse,
        removeCourseLike,
        clearModule,
        startAffiliate,
        cancelAffiliate,
        finishAffiliate
      }
    },
    Saved: { handleSaving }
  } = useStore();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [module?.id]);

  useLayoutEffect(() => {
    if (!course || id != course.id) getCourseDetails(id);
  }, [id]);

  useDocumentTitle(`${course?.name} | ${getCompanyName()}`);

  const retakeCourse = (promise, action) => {
    return () => {
      return promise(course?.id).then(success => {
        if (success) action(course?.id);
      });
    };
  };

  const withRedirect = promise => {
    return () =>
      promise().then(success => {
        if (success) push(state?.from || "/learn");
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Suggestion.Banner />

      {module ? (
        <ModuleContent
          processing={processing}
          courseTitle={course.name}
          courseId={course.id}
          modules={course.modules.map(({ name }) => name)}
          completeModule={completeModule}
          postponeModule={postponeModule}
          clearModule={clearModule}
          getCourseModule={getCourseModule}
          {...module}
        />
      ) : course && course.completed ? (
        <CourseCompleted courseName={course.name} courseSlug={course.slug} push={push} clearModule={clearModule} />
      ) : (
        <CourseDescription
          loading={loading}
          processing={processing}
          handleSaving={handleSaving}
          likeCourse={likeCourse}
          removeCourseLike={removeCourseLike}
          startCourse={course?.premium ? startAffiliate : startCourse}
          finishAffiliate={withRedirect(finishAffiliate)}
          cancelCourse={withRedirect(course?.premium ? cancelAffiliate : cancelCourse)}
          retakeCourse={retakeCourse(
            course?.premium ? cancelAffiliate : cancelCourse,
            course?.premium ? startAffiliate : startCourse
          )}
          getCourseModule={getCourseModule}
          addComment={addComment}
          updateComment={updateComment}
          removeComment={removeComment}
          {...course}
        />
      )}
    </>
  );
};

export default withTrackedRoute(observer(CourseScreen));
