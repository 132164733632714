import React, { useContext, forwardRef } from "react";
import { ScreenClassContext } from "react-grid-system";

import { Title, Text, Button } from "b2c/components/Core";
import Layout from "b2c/components/Layout";
import { useTranslation } from "b2c/hooks/useTranslation";
import LandingContext from "../../../../contexts/landing";
import NewPricingPlans from "../NewPricingPlans";
import { PricingWrapper, TitleWrapper, PricingList, InfoContent, ContactUs } from "./styled";

const PricingSection = ({ targetRef, openContactUsModal }) => {
  const screen = useContext(ScreenClassContext);
  const isSm = ["xs", "sm"].includes(screen);
  const { baronaPricing } = useContext(LandingContext);
  const { t } = useTranslation();

  return (
    <Layout.Section
      style={{
        backgroundColor: "#F3F3F3"
      }}
    >
      <PricingWrapper ref={targetRef} id="country-selector">
        <InfoContent>
          <TitleWrapper>
            <Title
              loaded
              level="2"
              fontWeight="600"
              margin="0 auto 8px"
              textAlign="center"
              style={{ fontSize: isSm ? 28 : 32, display: "inline-block", width: "70%", color: "#292929" }}
            >
              {t("companies.pricing.title")}
            </Title>
          </TitleWrapper>
          <Text
            fontSize={isSm ? 14 : 16}
            lineHeight="24px"
            width="100%"
            textAlign="center"
            style={{ padding: isSm ? "0 10px" : "0", color: "#292929" }}
            margin="0"
          >
            {t("companies.pricing.description")}
          </Text>
        </InfoContent>
        <PricingList>
          {baronaPricing?.pricingPlans?.map(plan => {
            return (
              <NewPricingPlans
                key={plan.type}
                description={plan.descr}
                {...plan}
                isSm={isSm}
                buttonText={plan.buttonText}
                openContactUsModal={() => openContactUsModal(plan.selectedProductType)}
              />
            );
          })}
        </PricingList>
        <ContactUs>
          <Text
            fontSize={16}
            fontWeight={600}
            textAlign={isSm ? "center" : "start"}
            margin={`20px 0 20px ${isSm ? "0" : "10"}px`}
          >
            <span>{`${t("companies.pricing.still_have_questions_title")} `}</span>
            <span style={{ fontWeight: isSm ? "300" : "600", marginLeft: "5px" }}>
              {t("companies.pricing.still_have_questions_description")}
            </span>
          </Text>
          <Button
            theme="black0"
            color="white"
            fontSize={14}
            margin="0 13px 0 0"
            width={isSm ? 320 : 160}
            onClick={openContactUsModal}
          >
            {t("companies.contact")}
          </Button>
        </ContactUs>
      </PricingWrapper>
    </Layout.Section>
  );
};

export default forwardRef(PricingSection);
