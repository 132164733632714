import globalProvide1 from "images/b2c/icons/global-provide-1.svg";
import globalProvide2 from "images/b2c/icons/global-provide-2.svg";
import globalProvide3 from "images/b2c/icons/global-provide-3.svg";
import globalProvide4 from "images/b2c/icons/global-provide-4.svg";
import globalProvide5 from "images/b2c/icons/global-provide-5.svg";
import globalProvide6 from "images/b2c/icons/global-provide-6.svg";
import globalProvide7 from "images/b2c/icons/global-provide-7.svg";
import testimonial1 from "images/b2c/global-testimonial-1.png";
import testimonial2 from "images/b2c/global-testimonial-2.png";

export const providesData = [
  {
    Image: globalProvide1,
    title: "Competitive rates",
    text: "Cost of talent is 30-70% lower than that of inhouse or remote talent in Europe"
  },
  {
    Image: globalProvide2,
    title: "Validated talent",
    text: "Talent is validated through tests and expert interviews to ensure right skills and culture fit"
  },
  {
    Image: globalProvide3,
    title: "Experts in what we do",
    text: "Fuzu has successfully matched over 150000 professionals to 2000+ businesses"
  },
  {
    Image: globalProvide4,
    title: "Fast delivery",
    text: "After initial scoping of your needs, Fuzu takes between 5-20 days to find the right match"
  },
  {
    Image: globalProvide5,
    title: "Relentless focus on value",
    text: "Fuzu supports and monitors the onboarding and on-the-job performance to ensure value"
  },
  {
    Image: globalProvide6,
    title: "Matching time zone",
    text: "English-speaking talent, working in a time zone +/- 2h from any location in Europe"
  },
  {
    Image: globalProvide7,
    title: "Flexibility",
    text: "If you are not satisfied, you can change your team at any point"
  }
];

export const hiringData = [
  {
    title: "Scope",
    text: "Talk with our expert to scope your needs"
  },
  {
    title: "Match",
    text: "We find the talent with the right skills and culture fit"
  },
  {
    title: "Review",
    text: "You review and choose the perfect candidate"
  },
  {
    title: "Onboard",
    text: "We facilitate the onboarding to ensure success"
  }
];

export const fitData = [
  { text: "You operate in English and work with international markets" },
  { text: "You want to reduce your talent and operating costs " },
  { text: "You need a partner who can help you scale fast" },
  { text: "You need a service provider who understands your culture" }
];

export const whatIsuzuData = [
  {
    title: "We are a European company",
    text: "Fuzu is an EU based limited liability company with our headquarters based in Helsinki, Finland."
  },
  {
    title: "A multinational team of professionals",
    text: "Fuzu has a multinational team with experience working with Nokia, Microsoft, Accenture, Bain & Co., IBM and United Nations."
  },
  {
    title: "We helped more than 15M+ users and 2000+ employers",
    text: "Over the past few years, our platform helped find the right talent, land a job or learn a new skill for 15M + users and 2000+ employers."
  },
  {
    title: "An AI-powered solution",
    text: "Our AI-powered talent development and employment solution attracts and validates talent using automated smart testing and expert reviews."
  }
];

export const servicesTabs = { remote: "remove", outSourced: "outSourced" };

export const remoteFeatures = [
  {
    text: "No upfront recruitment fee"
  },
  {
    text: "Contracts from 3 months and up"
  },
  {
    text: "Pick talent from pre-validated talent pool or let us know what you need"
  },
  {
    text: "We take care of  the payroll and contracting as the Employer of Record"
  },
  {
    text: "We monitor performance and support you throughout engagement"
  }
];

export const outSourcedFeatures = [
  {
    text: "No upfront recruitment fee"
  },
  {
    text: "Contracts from 3 months and up"
  },
  {
    text: "We take care of  the payroll and contracting as the Employer of Record"
  }
];

export const outSourcedRoles = [
  {
    title: "Data labeling team",
    text: "Build a labeling and annotation team, responsible for labeling user-generated content. Deliver high-quality training data sets for Machine Learning programs or other data labeling needs while following a pre-defined set of instructions."
  },
  {
    title: "Customer success team",
    text: "Build an autonomous team and management structure to provide a high level of customer care. Resolve routine customer queries quickly and efficiently and align processes with client SLA criteria."
  }
];

export const removeRoles = [
  {
    label: "Sales and Marketing",
    title: "Lead Generator",
    text: "Identifies and assesses leads by segmenting and evaluating them based on your own criteria. Feeds high-quality leads to your sales team."
  },
  {
    label: "Sales and Marketing",
    title: "Remote Sales Specialist",
    text: "Converts leads to sales through cold calling or remote meetings Provides reliable data on sales funnel performance."
  },
  {
    label: "Sales and Marketing",
    title: "Market Researcher",
    text: "Supports business growth by conducting market research. Receives insights using online tools, consumer outreach, surveys and data analysis."
  },
  {
    label: "Sales and Marketing",
    title: "Community manager",
    text: "Helps increase customer engagement by facilitating and catalyzing discussions, or by creating content that resonates with your audience."
  },
  {
    label: "Customer Success",
    title: "Customer success specialist",
    text: "Provides customers with care & support via online and phone-based channels. Solves customer queries quickly and efficiently."
  },
  {
    label: "Digital Content and Data",
    title: "Digital content specialist",
    text: "Creates copy and visuals for print or digital media, like magazines, ads or e-commerce websites."
  },
  {
    label: "Digital Content and Data",
    title: "Data labeler",
    text: "Provides high-quality training data-sets for Machine Learning projects by labeling and annotating data."
  },
  {
    label: "Admin and finance",
    title: "Virtual Assistant",
    text: "Helps with administrative tasks like answering emails, scheduling meetings and making travel arrangements."
  },
  {
    label: "Admin and finance",
    title: "Financial specialist",
    text: "Helps manage the organization’s finances. Prepares financial statements, analyzes financial data and generates insights."
  },
  {
    label: "Tech",
    title: "Front-end developer",
    text: "Converts UI designs and logic into a functioning user experience, excluding the backend logic."
  },
  {
    label: "Tech",
    title: "Back-end developer",
    text: "Builds the underlying backend logic based on functional specification and data architecture."
  },
  {
    label: "Tech",
    title: "QA engineer",
    text: "Identifies, logs and prioritizes bugs and sets structures and processes for manual and automated testing."
  }
];

export const suggestsRoles = [
  {
    title: "Sales and Marketing",
    text: "Remote sales specialists, Lead generators, Market researchers, Community managers",
    name: "salesAndMarketing"
  },
  {
    title: "Customer Success",
    text: "Customer success specialists",
    name: "customerSuccess"
  },
  {
    title: "Digital content and data",
    text: "Digital content specialists, Data labelers",
    name: "digitalContent"
  },
  {
    title: "Admin and finance",
    text: "Virtual assistants, financial specialists",
    name: "adminFinance"
  },
  {
    title: "Tech",
    text: "Front-end and Back-end developers, QA engineers",
    name: "tech"
  }
];

export const formInit = {
  email: "",
  name: "",
  phoneCode: "",
  phone: "",
  company: "",
  salesAndMarketing: false,
  customerSuccess: false,
  digitalContent: false,
  adminFinance: false,
  tech: false,
  country: "",
  about: "",
  count: ""
};

export const defaultValidation = {
  email: { error: false },
  name: { error: false },
  country: { error: false },
  count: { error: false }
};

export const testimonials = [
  {
    title: "Save time for business development",
    text: "’Vinrian (Fuzu outstaffed expert) has saved us more than 20 hours a week, that we can dedicate to Business Development. That time is priceless!’",
    name: "Jinal Shah",
    position: "CEO, Flygkonsult 747, Sweden",
    image: testimonial1
  },
  {
    title: "Best talent available in the market",
    text: "’GIZ has been partnering with Fuzu for 4 years and counting. We take value in the deep database of potential talent available. The staff is always available to ensure that we receive the best solutions in the market. We couldn’t be more satisfied with our partnership with Fuzu’",
    name: "George Rugu",
    position: "Head of HR, GIZ",
    image: testimonial2
  }
];
