import styled from "styled-components";
import { theme } from "../../../../contexts/theme";

export const Container = styled.div`
  background-color: #fff;
`;

export const Content = styled.div`
  padding: 20px 20px 0 20px;
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;

  @media screen and (min-width: 1200px) {
    padding: 64px 20px 0;
  }

  @media screen and (min-width: 1440px) {
    padding: 64px 50px 0;
  }
`;

export const Heading = styled.div`
  @media screen and (min-width: 768px) {
    max-width: 360px;
    margin: 0 auto;
  }
`;

export const Title = styled.h2`
  font-weight: 600;
  font-size: 24px;
  line-height: 130%;
  color: #292929;
  text-align: center;
  margin-bottom: 16px;

  @media screen and (min-width: 1200px) {
    font-size: 32px;
  }
`;

export const Steps = styled.div`
  max-width: 440px;
  margin: 0 auto 40px;
`;

export const StepsItem = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #656565;
  display: block;
  margin-bottom: 20px;
  position: relative;
  text-align: center;

  &:before {
    content: "";
    display: block;
    width: 2px;
    height: 16px;
    background: ${theme.primary};
    border-radius: 3px;
    position: absolute;
    left: 50%;
    margin-left: -1px;
    bottom: -19px;
  }

  &:last-child {
    margin-bottom: 0;

    span {
      background-color: ${theme.primary};
    }

    &:before {
      display: none;
    }
  }

  @media screen and (min-width: 1200px) {
    margin-bottom: 14px;
    display: flex;
    align-items: center;
    text-align: left;
    font-size: 16px;

    &:before {
      left: 8px;
      bottom: -20px;
    }
    &:first-child {
      margin-bottom: 25px;
    }
  }
`;

export const StepsCycle = styled.span`
  display: block;
  width: 16px;
  height: 16px;
  background: ${theme.primary50};
  border: 2px solid ${theme.primary};
  margin-right: 16px;
  display: none;
  border-radius: 50%;
  flex: none;

  @media screen and (min-width: 1200px) {
    display: block;
  }
`;

export const ImageContent = styled.div`
  width: 320px;
  height: 174;
  margin: 0 auto 20px;

  @media screen and (min-width: 768px) {
    width: 346px;
    height: 184px;
  }

  img {
    max-width: 100%;
  }
`;

export const Info = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
  margin-top: 30px;

  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #c5c5c5;
    left: 0;
    top: 50%;
    margin-top: -0.5px;
  }

  @media screen and (min-width: 768px) {
    margin-top: 50px;
  }
`;

export const InfoItem = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #656565;
  background-color: #fff;
  padding: 0 24px;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 264px;
  margin: 0 auto;
`;

export const ArrowContent = styled.div`
  width: 24px;
  height: 24px;
  background: #e0edff;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    fill: #408bfc;
    width: 16px;
    height: 16px;
  }
`;
