import React from "react";
import { Col, Hidden, Row, Visible } from "react-grid-system";
import { Link, useHistory } from "react-router-dom";
import { ChevronDownSquare, ChevronUpSquare, CopyAlt, Edit, Plus, Trash } from "@styled-icons/boxicons-regular";
import { Coin } from "@styled-icons/boxicons-solid";

import { useTranslation } from "b2c/hooks/useTranslation";
import Badge1Ico from "images/b2c/badges/badge1.svg";
import Gear from "images/b2c/profilePage/we_gear.svg";
import { Alert, Button, Card, Dropdown, Icon, Label, Spinner, Text, Tipbox, Title } from "../Core";
import { AchievementItem, AchievementItemContent, AchievementItemIcon, TitleIconHolder } from "./styled";
import { useModal } from "../../../utils/hooks";
import DeleteConfirmation from "../ModalContents/DeleteConfirmation";

const DescriptionSection = ({
  title,
  icon,
  potentialPoints,
  data = [],
  dataSkills = [],
  dataAchievements = [],
  tipTitle,
  tipText,
  actionUrl,
  isEmpty = true,
  multiple = false,
  loading,
  testScope
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  const onEdit = url => {
    return id => {
      history.push({ ...url, state: { ...url.state, item_id: id } });
    };
  };

  return (
    <Card
      bordered
      style={{ margin: "0 0 24px", borderColor: "#eee" }}
      testScope={testScope}
      Button={
        actionUrl &&
        (isEmpty || multiple) &&
        !loading && (
          <Button.Link
            to={actionUrl}
            icon={<Plus size={20} />}
            fontSize="14px"
            margin="0"
            height="40px"
            theme="primary"
            ariaLabel={`Add ${title}`}
            color="primaryButtonLabelColor"
          >
            {t("global.add_button")}
          </Button.Link>
        )
      }
    >
      <Card.Header
        padding="12px 15px"
        style={{
          alignItems: "center",
          minHeight: "64px"
        }}
      >
        <TitleIconHolder>{icon}</TitleIconHolder>
        <Title level={5} margin="0 0 0 15px" style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
          {title}
          {potentialPoints && (
            <Label
              rect
              Icon={<Coin width={16} />}
              fill="black500"
              color="black500"
              height={27}
              fontSize={14}
              margin="auto 6px"
              theme="primary"
            >
              +{potentialPoints} Fuzu points
            </Label>
          )}
        </Title>
      </Card.Header>
      <Card.Separator margin="0" />
      <Card.Content padding={!isEmpty || loading ? "15px" : 0}>
        {loading ? (
          <Spinner size="42px" margin="auto" />
        ) : !isEmpty ? (
          <>
            {data.length > 0 && (
              <StandardContent
                data={data}
                actionUrl={actionUrl}
                onEdit={onEdit(actionUrl)}
                loading={loading}
                title={title}
              />
            )}
            {dataSkills.length > 0 && <SkillsContent data={dataSkills} actionUrl={actionUrl} loading={loading} />}
            {dataAchievements.length > 0 && (
              <AchievementsContent data={dataAchievements} actionUrl={actionUrl} loading={loading} title={title} />
            )}
          </>
        ) : (
          <Card.Footer padding="0 15px 15px">
            <Tipbox title={tipTitle} text={tipText} margin="15px 0 0" />
          </Card.Footer>
        )}
      </Card.Content>
    </Card>
  );
};

const StandardContent = ({ data, actionUrl, onEdit, title: sectionTitle }) => {
  const { t } = useTranslation();
  const openDeleteConfirmation = useModal(<DeleteConfirmation title={sectionTitle} />, "");

  const handleOpenDeleteConfirmation = (handleDelete, name) => {
    openDeleteConfirmation({ handleDelete, name });
  };

  return data.map(
    (
      {
        id,
        title = "",
        info,
        date,
        additional,
        text = "",
        skills = [],
        onDelete,
        onAsk,
        onAddAttachment,
        alertMessage
      },
      i
    ) => (
      <div key={`uid_${i}`}>
        <>
          <Row align="center" style={{ marginTop: i < 1 ? "0" : "15px" }}>
            <Col>
              <Text fontSize="16px" fontWeight="600" color="grey750" margin="0 0 8px 0">
                {title}
              </Text>
            </Col>
            <Col xs="content">
              <ActionsViewHandler>
                {actionUrl && (
                  <Button.Link
                    to={{ ...actionUrl, state: { ...actionUrl.state, item_id: id } }}
                    noBackground
                    height="140%"
                    color="black0"
                    fontSize="14px"
                    fontWeight="400"
                    icon={<Edit width={18} />}
                  >
                    {t("global.edit")}
                  </Button.Link>
                )}
                {onDelete && (
                  <Button
                    noBackground
                    height="140%"
                    color="black0"
                    fontSize="14px"
                    fontWeight="400"
                    icon={<Trash width={18} />}
                    onClick={() => handleOpenDeleteConfirmation(onDelete, title)}
                  >
                    {t("global.delete")}
                  </Button>
                )}
                {onAsk && (
                  <Button
                    noBackground
                    height="140%"
                    color="black0"
                    fontSize="14px"
                    fontWeight="400"
                    icon={<CopyAlt width={18} />}
                    onClick={onAsk}
                  >
                    {t("wizard.ask_reference_button")}
                  </Button>
                )}
                {onAddAttachment && (
                  <Button
                    noBackground
                    height="140%"
                    color="black0"
                    fontSize="14px"
                    fontWeight="400"
                    icon={<Link width={18} />}
                    onAddAttachment={onAddAttachment}
                  >
                    {t("wizard.add_attachments")}
                  </Button>
                )}
              </ActionsViewHandler>
            </Col>
          </Row>
          {info && (
            <Text fontWeight="400" color="black0" fontSize="14px" margin="0 0 8px 0">
              {info}
            </Text>
          )}
          {date && <Text>{date}</Text>}
          {additional && <Text>{additional} </Text>}
          {text && (
            <Text color="grey650" margin="0" style={{ whiteSpace: "pre-wrap" }} fontSize="14px">
              {text}
            </Text>
          )}
          {skills.length > 0 && (
            <div className="labels-list">
              {skills.map((item, index) => (
                <Label
                  key={`uid_${id}_${index}`}
                  theme="black400"
                  rect
                  noBackground
                  bordered
                  padding="8px 14px"
                  margin="5px 10px 5px 0"
                  style={{
                    borderRadius: "5px",
                    borderColor: "#9E9E9E"
                  }}
                >
                  {item.name}
                </Label>
              ))}
            </div>
          )}
        </>
        {alertMessage && (
          <Alert clickable onClick={() => onEdit(id)} margin="8px 0 0">
            {alertMessage}
          </Alert>
        )}
      </div>
    )
  );
};

const SkillsContent = ({ data, actionUrl }) => {
  const { t } = useTranslation();

  return (
    <>
      <Row align="center" style={{ marginBottom: "15px" }}>
        <Col>
          <Text fontSize="16px" fontWeight={700}>
            {t("profile.skills.sub-title")}
          </Text>
        </Col>
        <Col xs="content">
          <ActionsViewHandler>
            {actionUrl && (
              <Button.Link
                to={actionUrl}
                noBackground
                height="22px"
                theme="black500"
                fontSize="14px"
                icon={<Edit width={18} />}
              >
                {t("global.edit")}
              </Button.Link>
            )}
          </ActionsViewHandler>
        </Col>
      </Row>
      <div className="labels-list">
        {data.map((item, i) => (
          <Label
            key={`uid_${i}`}
            theme="black400"
            rect
            noBackground
            bordered
            height={30}
            padding="8px 14px"
            margin="5px 10px 5px 0"
            style={{
              borderRadius: "5px",
              borderColor: "#9E9E9E"
            }}
            Icon={item.work_experience_related && <Gear width={14} height={14} style={{ marginLeft: "-4px" }} />}
          >
            {item.name}
          </Label>
        ))}
      </div>
    </>
  );
};

const AchievementsContent = ({ data, actionUrl, title: sectionTitle }) => {
  const { t } = useTranslation();
  const openDeleteConfirmation = useModal(<DeleteConfirmation title={sectionTitle} />, "");

  const handleOpenDeleteConfirmation = (handleDelete, name) => {
    openDeleteConfirmation({ handleDelete, name });
  };

  return (
    <>
      <Text fontSize="16px" fontWeight={700} margin="0 0 15px">
        {t("profile.certificates.sub-title")}
      </Text>

      {data.map(({ title, info, date, onDelete, id }, i) => (
        <Row align="center" key={`uid_${i}`}>
          <Col>
            <AchievementItem>
              <AchievementItemIcon>
                <Badge1Ico />
              </AchievementItemIcon>
              <AchievementItemContent>
                <Text fontSize="16px" fontWeight={500}>
                  {title}
                </Text>
                <Text>{info}</Text>
                <Text>{date}</Text>
              </AchievementItemContent>
            </AchievementItem>
          </Col>
          <Col xs="content">
            <ActionsViewHandler>
              {title && actionUrl && (
                <Button.Link
                  to={{ ...actionUrl, state: { ...actionUrl.state, item_id: id } }}
                  noBackground
                  height="22px"
                  theme="black500"
                  fontSize="14px"
                  icon={<Edit width={18} />}
                >
                  {t("global.edit")}
                </Button.Link>
              )}
              {onDelete && (
                <Button
                  noBackground
                  height="22px"
                  theme="black500"
                  fontSize="14px"
                  icon={<Trash width={18} />}
                  onClick={() => handleOpenDeleteConfirmation(onDelete, title)}
                >
                  {t("global.delete")}
                </Button>
              )}
            </ActionsViewHandler>
          </Col>
        </Row>
      ))}
    </>
  );
};

export const ActionsViewHandler = ({ children }) => {
  const Trigger = ({ isOpen, ...rest }) => (
    <Button.Iconed noBackground theme="black500" fontSize="14px" type="button" {...rest}>
      <Icon as={isOpen ? ChevronUpSquare : ChevronDownSquare} width={24} />
    </Button.Iconed>
  );

  return (
    <>
      <Hidden sm xs>
        {children}
      </Hidden>
      <Visible sm xs>
        <Dropdown trigger={<Trigger />}>
          <Dropdown.Menu>
            {React.Children.map(
              children,
              child =>
                child &&
                React.cloneElement(child, {
                  height: "42px",
                  width: "100%"
                })
            )}
          </Dropdown.Menu>
        </Dropdown>
      </Visible>
    </>
  );
};

export default DescriptionSection;
