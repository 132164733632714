import styled from "styled-components";

export const Content = styled.div`
  background-color: #fff;
  padding: 32px 0;

  @media screen and (min-width: 1008px) {
    padding: 64px 0;
  }
`;

export const Title = styled.h2`
  color: #292929;
  text-align: center;
  font-size: 28px;
  font-weight: 600;
  line-height: 54px;
  margin: 0 0 32px 0;

  @media screen and (min-width: 1008px) {
    font-size: 36px;
    font-weight: 600;
    line-height: 54px;
    margin-bottom: 64px;
  }
`;

export const List = styled.div`
  max-width: 1280px;
  margin: 0 auto;

  @media screen and (min-width: 1008px) {
    display: flex;
    gap: 40px;
    align-items: center;
    flex-wrap: wrap;
  }
`;

export const Team = styled.div`
  max-width: 280px;
  width: 100%;
  flex: none;
  text-align: center;
  margin: 0 auto 24px auto;

  @media screen and (min-width: 1008px) {
    width: 280px;
  }
`;

export const Image = styled.div`
  width: 208px;
  height: 208px;
  display: block;
  overflow: hidden;
  border-radius: 50%;
  margin: 0 auto 16px;

  img {
    max-width: 100%;
  }
`;

export const Name = styled.h6`
  color: #292929;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  line-height: 140%;
  display: block;
  margin: 0 0 2px 0;
`;

export const Languages = styled.p`
  color: #666666;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  display: block;
  margin: 0 0 16px 0;
`;

export const Email = styled.a`
  color: #292929;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  display: block;
  cursor: pointer;
  margin-bottom: 8px;
  text-decoration: none;
`;

export const Phone = styled.a`
  color: #292929;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  display: block;
  cursor: pointer;
  text-decoration: none;
`;
