import React from "react";
import { Text } from "../../../../components/Core";

const NotFoundNotification = () => (
  <Text color="white" margin="9px auto">
    Unfortunately, the job you tried to access has expired. Here is a list of similar jobs.
  </Text>
);

export default NotFoundNotification;
