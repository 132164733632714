export const ARTICLES_FALLBACK = [
  {
    title: "Loading",
    background_image: "none",
    front_image: null,
    id: 0
  }
];

export const ARTICLES_FUZU_APP = [
  {
    id: "custom-fuzu-app",
    type: "customFuzuApp"
  }
];

export const customFuzuApp = "customFuzuApp";
