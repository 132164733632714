import React, { useState, useRef } from "react";
import { Visible } from "react-grid-system";
import { Link, useHistory } from "react-router-dom";
import { Search } from "@styled-icons/boxicons-regular";
import { Close } from "@styled-icons/evaicons-solid";
import cn from "classnames";

import { SearchInputWrapper, SearchInputIcon } from "./styled";
import { Button, SearchInput } from "../Core";
import useStore from "../../contexts/store";

const HeaderSearchInput = ({
  Theme,
  searchTerm,
  setSearchTerm,
  handleUniversalSearch,
  onEnterPress,
  pathname,
  className
}) => {
  const [showSearchInput, setShowSearchInput] = useState(false);
  const { push } = useHistory();
  const { setTerm } = useStore("UniversalSearch");
  const ref = useRef();

  const handleSearch = e => {
    if (/forum/.test(pathname)) {
      onEnterPress(e, searchTerm, () => push(`/search/articles?term=${searchTerm}`));
    } else if (/learn/.test(pathname)) {
      onEnterPress(e, searchTerm, () => push(`/search/courses?term=${searchTerm}`));
    } else if (
      /search\/articles/.test(pathname) ||
      /search\/courses/.test(pathname) ||
      /search\/companies/.test(pathname)
    ) {
      onEnterPress(e, searchTerm, () => setTerm(searchTerm));
    } else {
      onEnterPress(e, searchTerm, handleUniversalSearch);
    }
  };

  return (
    <>
      <Visible md sm xs>
        <Link to="/search">
          <Button.Iconed className="nav-icon" tabIndex={-1} theme="transparent" style={{ marginTop: "8px" }}>
            <Search width={32} fill={Theme.black350} />
          </Button.Iconed>
        </Link>
      </Visible>
      <Visible xxl xl lg>
        <SearchInputWrapper
          style={{
            margin: "5px 10px 0 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "33px"
          }}
        >
          {!showSearchInput && (
            <Button.Iconed
              className={cn("nav-icon", className)}
              tabIndex={-1}
              theme="transparent"
              onClick={() => {
                setShowSearchInput(true);
              }}
              style={{ margin: "0" }}
            >
              <SearchInputIcon
                className="form-input-search__icon"
                styled={{
                  width: "30px",
                  color: Theme.black350
                }}
              />
            </Button.Iconed>
          )}
          {showSearchInput && (
            <>
              <SearchInput
                aria-label="Search"
                width={300}
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                onKeyDown={handleSearch}
                type="text"
                ref={ref}
                isFocus
              />

              <Button.Iconed
                className="close-icon"
                tabIndex={-1}
                theme="transparent"
                onClick={() => {
                  setShowSearchInput(false);
                  setSearchTerm("");
                }}
              >
                <Close
                  styled={{
                    width: "26px",
                    color: Theme.black100
                  }}
                />
              </Button.Iconed>
            </>
          )}
        </SearchInputWrapper>
      </Visible>
    </>
  );
};

export default HeaderSearchInput;
