import React, { useEffect, useLayoutEffect } from "react";
import { observer } from "mobx-react";
import { Redirect, useLocation, useRouteMatch } from "react-router-dom";
import { useScreenClass } from "react-grid-system";

import { withRoute } from "utils/hocs/withRoute";
import useStore from "../contexts/store";

export const withTrackedRoute = Component => {
  const TrackedComponent = withRoute(
    observer(props => {
      const { trackRouteChange } = useStore("Tracking");
      const { user } = useStore("User");
      const {
        location: { pathname },
        match: { params, path },
        history: { listen }
      } = props;

      useEffect(() => {
        return listen((location, action) => {
          if (location?.pathname !== pathname && action === "PUSH" && user?.id)
            trackRouteChange({
              url: window?.location?.href,
              match_path: path,
              params
            });
        });
      }, []);

      return <Component {...props} />;
    })
  );

  return TrackedComponent;
};

export const withTracking = (Component, event, params) => {
  const TrackedComponent = observer(props => {
    const { track } = useStore("Tracking");
    const { path } = useRouteMatch();

    useLayoutEffect(() => {
      track(event, { match_path: path, ...params });
    }, []);

    return <Component {...props} />;
  });

  return TrackedComponent;
};

export const Responsive = (DesktopComponent, MobileComponent) => props => {
  const isMobile = /sm|xs/.test(useScreenClass());

  return React.createElement(isMobile ? MobileComponent : DesktopComponent, props);
};

export const FeatureHidden = ({ feature, children, fallback = null }) => {
  const { features } = useStore("Premium");

  return features?.[feature]?.activated ? fallback : children;
};

export const withWizardRedirect = Component => {
  return observer(props => {
    const { user } = useStore("User");
    const { pathname, state } = useLocation();

    return !isNaN(user?.profile_completeness_percentage) && user?.profile_completeness_percentage <= 15 ? (
      <Redirect
        to={{
          pathname: "/wizard",
          state: { from: state?.from || pathname }
        }}
      />
    ) : (
      <Component {...props} />
    );
  });
};
