import React, { useContext, useState, useEffect } from "react";
import { Redirect, useParams } from "react-router-dom";
import { ScreenClassContext } from "react-grid-system";

import { useModal } from "utils/hooks";
import { global } from "b2c/constants/pricing";
import LandingContext from "../../contexts/landing";
import CountryContext from "../../contexts/country";
import ElitePlan from "./components/ElitePlan";
import Plan from "./components/Plan";
import PlanSm from "./components/PlanSm";
import ContactSection from "./components/ContactSection";
import ContactUsModal from "../../components/ContactUsModal";
import { isBaronaBrand } from "../../contexts/branded";

const PricingPlan = () => {
  const { type } = useParams();
  const { country = global } = useContext(CountryContext);
  const [activePlan, setActivePlan] = useState(0);
  const screen = useContext(ScreenClassContext);
  const isSm = ["xs", "sm"].includes(screen);
  const { pricing, setVersion } = useContext(LandingContext);
  const openContactUsModal = useModal(<ContactUsModal />, "", null, false);
  const isBarona = isBaronaBrand();

  if (isBarona) {
    return <Redirect to="/companies" />;
  }

  const pricingPlan = pricing?.pricingPlans.find(plan => type === plan.type) || {};
  const { details = {} } = pricingPlan;
  const plan = details[country.country_code];

  useEffect(() => {
    setVersion("b2b");

    if (window.scrollY) {
      window.scroll(0, 0);
    }
  }, []);

  const price = details[country.country_code]?.pricings[activePlan];
  const homePath = country.slug && country.slug !== "global" ? `/${country.slug}` : "";
  const backURL = `${homePath}/employers`;
  const props = {
    backURL,
    setActivePlan,
    openContactUsModal: () => openContactUsModal({ aggressive: true }),
    details,
    country,
    type,
    price
  };

  return (
    price && (
      <div>
        {type === "elite" ? (
          <ElitePlan isSm={isSm} plan={plan} {...props} />
        ) : isSm ? (
          <PlanSm {...props} />
        ) : (
          <Plan {...props} />
        )}
        <ContactSection isSm={isSm} openContactUsModal={openContactUsModal} />
      </div>
    )
  );
};

export default PricingPlan;
