import React, { useState, useCallback } from "react";
import Cropper from "react-easy-crop";
import InputRange from "react-input-range";

import Text from "../Core/Text";
import Button from "../Core/Button";
import { CropperContainer } from "./styled";

const ImageEditor = ({ source, setImage, close }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1.25);
  const [croppedArea, setCroppedArea] = useState(null);

  const onCropComplete = useCallback((_, area) => {
    setCroppedArea(area);
  }, []);

  const applyCropping = useCallback(async () => {
    const image = await createImage(source);
    const canvas = document.createElement("canvas");
    const canvasContext = canvas.getContext("2d");

    const maxSize = Math.max(image.width, image.height);
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

    canvas.width = safeArea;
    canvas.height = safeArea;

    canvasContext.translate(safeArea / 2, safeArea / 2);
    canvasContext.rotate((Math.PI * rotation) / 180);
    canvasContext.translate(-safeArea / 2, -safeArea / 2);

    canvasContext.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5);

    const data = canvasContext.getImageData(0, 0, safeArea, safeArea);

    canvas.width = croppedArea.width;
    canvas.height = croppedArea.height;

    canvasContext.putImageData(
      data,
      Math.round(0 - safeArea / 2 + image.width * 0.5 - croppedArea.x),
      Math.round(0 - safeArea / 2 + image.height * 0.5 - croppedArea.y)
    );

    return new Promise(resolve => {
      canvas.toBlob(
        file =>
          resolve({
            url: canvas.toDataURL(),
            file: new File([file], "avatar.png", { type: "image/png" })
          }),
        "image/jpeg"
      );
    });
  }, [rotation, croppedArea]);

  const saveImage = () => {
    applyCropping()
      .then(result => {
        setImage(result);
      })
      .then(() => {
        close();
      });
  };

  return (
    <CropperContainer>
      <div className="edit-area">
        <Cropper
          image={source}
          crop={crop}
          rotation={rotation}
          zoom={zoom}
          aspect={1}
          onCropChange={setCrop}
          onRotationChange={setRotation}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </div>
      <Text>Zoom</Text>
      <InputRange
        minValue={100}
        maxValue={300}
        value={zoom * 100}
        onChange={value => setZoom(value / 100)}
        classNames={rangeClasses}
      />
      <Text>Rotation</Text>
      <InputRange
        minValue={-90}
        maxValue={90}
        value={rotation}
        onChange={value => setRotation(value)}
        classNames={rangeClasses}
      />
      <Button color="primaryButtonLabelColor" width="100%" type="button" onClick={saveImage} margin="16px 0 0">
        Apply changes
      </Button>
    </CropperContainer>
  );
};

const rangeClasses = {
  activeTrack: "fz-input-range__track fz-input-range__track--active",
  disabledInputRange: "fz-input-range fz-input-range--disabled",
  inputRange: "fz-input-range",
  labelContainer: "fz-input-range__label-container",
  maxLabel: "fz-input-range__label fz-input-range__label--max",
  minLabel: "fz-input-range__label fz-input-range__label--min",
  slider: "fz-input-range__slider",
  sliderContainer: "fz-input-range__slider-container",
  track: "fz-input-range__track fz-input-range__track--background",
  valueLabel: "fz-input-range__label fz-input-range__label--value"
};

const createImage = url =>
  new Promise(resolve => {
    const image = new Image();
    image.onload = () => resolve(image);
    image.src = url;
  });

export default ImageEditor;
