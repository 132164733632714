import React, { useState, useContext } from "react";
import parse from "html-react-parser";
import { Row, Col, ScreenClassContext } from "react-grid-system";
import { LeftArrowAlt, LinkExternal, PlayCircle, Check } from "@styled-icons/boxicons-regular";
import { useTranslation } from "b2c/hooks/useTranslation";

import { ModalContext } from "contexts/modal";
import { useScrollHandler } from "utils/hooks";
import { TimeFive } from "@styled-icons/boxicons-solid";
import Layout from "../../../../components/Layout";
import { Title, Text, Label, Card, Divider, List, Button, CollapsibleCard, Video } from "../../../../components/Core";
import StepProgress from "../../../../components/StepProgress";
import Summary from "../Summary";
import { ModuleQuizModalContent } from "../../../../components/ModalContents";
import { ContentWrapper, ContentSide, StyledModuleContent } from "./styled";

const ModuleContent = ({
  processing,
  id,
  name = "",
  description = "",
  courseId,
  courseTitle = "",
  completed: finished,
  clearModule,
  modules = [],
  elements = [],
  activities,
  resources = [],
  duration_seconds = 0,
  completeModule,
  postponeModule,
  getCourseModule,
  previous_module_id,
  test_id
}) => {
  const screen = useContext(ScreenClassContext);
  const { toggle } = useContext(ModalContext);
  const isSm = ["xs", "sm"].includes(screen);
  const [completed, setCompleted] = useState([]);
  const [nextToExpand, setNextToExpand] = useState(null);
  const { scrollHandler, targetRef } = useScrollHandler();
  const moduleNumber = modules.indexOf(name) + 1;
  const videosCount = elements.filter(item => item.element_type === "video").length;
  const { t } = useTranslation();

  const markAsCompleted = (ids, nextId, setExpanded) => {
    setCompleted(prev => prev.concat(ids));
    setExpanded(false);
    setNextToExpand(nextId);
  };

  const handleForward = () => {
    if (test_id && !finished) {
      toggle(<ModuleQuizModalContent courseId={courseId} testId={test_id} close={() => toggle()} />, "");
    } else {
      completeModule();
    }
  };

  const progressNodes = [
    <Col lg={previous_module_id ? 4 : 6} md={previous_module_id ? 8 : 4}>
      <Row>
        <Col lg={6}>
          <Button
            processing={processing}
            margin="5px 0"
            width="auto"
            theme="grey200"
            onClick={() => postponeModule().then(clearModule)}
          >
            {t("learn.module.save_and_finish_later")}
          </Button>
        </Col>
        {previous_module_id && (
          <Col>
            <Button
              processing={processing}
              margin="5px 0"
              width="100%"
              theme="grey200"
              onClick={() => getCourseModule(previous_module_id)}
            >
              {t("learn.module.previous_module")}
            </Button>
          </Col>
        )}
      </Row>
    </Col>,
    <Col lg={2} md={4}>
      <Button
        processing={processing}
        margin="5px 0"
        width="100%"
        onClick={handleForward}
        color="primaryButtonLabelColor"
      >
        {moduleNumber < modules.length ? t("learn.module.next") : t("learn.module.finish")}
      </Button>
    </Col>
  ];

  return (
    <Layout.Content size="xl" style={{ padding: "20px 20px 40px", overflow: "hidden" }}>
      <Button
        onClick={clearModule}
        icon={<LeftArrowAlt width={20} />}
        fontSize={20}
        theme="black500"
        noBackground
        padding="0"
      >
        {t("learn.module.course")}: {courseTitle}
      </Button>

      <StepProgress steps={modules} currentStep={moduleNumber} margin="10px 0 20px" />
      <ContentWrapper>
        <ContentSide>
          <Title level="1" margin="0 0 30px">
            <b>
              {t("learn.module.module")} {moduleNumber}:
            </b>{" "}
            {name}
          </Title>

          <Text as="div" color="black300" fontSize={18} lineHeight="28px">
            {parse(description)}
          </Text>

          <Divider margin="25px 0 15px" />
          <Row style={{ marginBottom: 25 }}>
            <Col>
              <Label
                theme="black500"
                noBackground
                fontSize={14}
                margin="0 15px 20px 0"
                padding="0"
                Icon={<PlayCircle width={20} />}
              >
                {`${videosCount} ${t("learn.module.videos")}`}
              </Label>
              {duration_seconds ? (
                <Label
                  theme="black500"
                  noBackground
                  fontSize={14}
                  margin="0 15px 20px 0"
                  padding="0"
                  Icon={<TimeFive width={20} />}
                >
                  {`${Math.round(duration_seconds / 60)} ${t("learn.module.videos")}`}
                </Label>
              ) : null}
            </Col>
          </Row>

          {[...elements]
            .sort((a, b) => a.item_order - b.item_order)
            .map((item, i) => (
              <CollapsibleCard
                key={`${id}-contents-${i + 1}`}
                ref={item.id == nextToExpand ? targetRef : null}
                shadow
                openByDefault={i == 0}
                open={item.id == nextToExpand}
                collapseProps={{ onCollapseEnd: scrollHandler }}
                headingContent={
                  <Row align="center" style={{ flex: "1 0 0" }}>
                    <Col>
                      <Text fontSize={16} fontWeight={700} margin="3px 0">
                        {`${moduleNumber}.${i + 1}`} {item.title}
                      </Text>
                    </Col>

                    {completed.includes(item.id) && (
                      <Col sm="content">
                        <Label theme="success200" color="white" fill="white" Icon={<Check width={16} />} margin="3px 0">
                          {t("learn.module.completed")}
                        </Label>
                      </Col>
                    )}
                  </Row>
                }
                controlled
                wideOnMobile
                style={{ marginBottom: 24 }}
              >
                {(isExpanded, setExpanded) => (
                  <>
                    <StyledModuleContent>{parse(item.content)}</StyledModuleContent>

                    {item.element_type === "video" && item.url && (
                      <Video src={item.url} margin="0 0 16px" embed controls allowFullscreen />
                    )}

                    <Row justify="end">
                      <Col width="auto">
                        <Button
                          color="white"
                          theme="success200"
                          onClick={() => markAsCompleted(item.id, elements[i + 1]?.id, setExpanded)}
                        >
                          {t("learn.module.mark_as_completed")}
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}
              </CollapsibleCard>
            ))}
        </ContentSide>

        {(activities || resources?.length > 0) && (
          <Summary isStatic>
            {activities && (
              <>
                <Text fontWeight={600}>Activities</Text>
                {parse(activities)}
              </>
            )}
            {resources?.length > 0 && (
              <>
                <Text fontWeight={600} margin="15px 0 0">
                  {t("learn.module.resources")}
                </Text>
                <List>
                  {resources?.map((resource, i) => (
                    <List.Item bulletColor="black300" key={i}>
                      <a href={resource.attached_file?.attached_file?.url} className="link underlined">
                        {resource / description}
                        <LinkExternal width="16px" style={{ marginLeft: "5px" }} />
                      </a>
                    </List.Item>
                  ))}
                </List>
              </>
            )}
          </Summary>
        )}
      </ContentWrapper>

      <Card bordered wideOnMobile style={{ marginTop: 25, marginBottom: 50 }}>
        <Card.Content padding="10px 16px">
          <Row justify="between">{isSm ? progressNodes.reverse() : progressNodes}</Row>
        </Card.Content>
      </Card>
    </Layout.Content>
  );
};

export default ModuleContent;
