import styled from "styled-components";

export const BrandsWrapper = styled.section`
  padding: ${({ padding }) => padding};
  background: ${({ background }) => background};
  ${({ style }) => style}

  @media screen and (max-width: 767px) {
    padding: 40px 0;
  }
`;

export const BrandList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;
`;

export const BrandItem = styled.div`
  border-radius: 5px;
  width: 14%;
  background-color: #ffffff;
  animation: loaded 0.4s forwards;
  transition: 0.2s ease;
  margin: 1%;
  &:hover {
    border-color: ${({ $borderActiveColor }) => $borderActiveColor};
  }
  @media screen and (max-width: 767px) {
    flex: 0 0 46%;
    margin: 2%;
    display: ${({ $hideIfOddOnMobile }) => ($hideIfOddOnMobile ? "none" : "block")};
  }

  .lp-brand-itm__content {
    padding-bottom: 50%;
    position: relative;
  }
  .lp-brand-itm__image {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    width: 100%;
    height: 100%;
    object-fit: contain;
    padding: 12px;
    max-height: 100%;
    max-width: 100%;
  }
`;
