import React, { cloneElement, Children } from "react";

import { useModal } from "utils/hooks";
import { UnderDevelopmentContent } from "../ModalContents";

const UnderDevelopment = ({ children, disabled = false }) => {
  const openModal = useModal(<UnderDevelopmentContent />, "");

  const onClick = e => {
    e.preventDefault();
    e.stopPropagation();
    openModal();
  };

  return disabled ? children : cloneElement(Children.only(children), { onClick });
};

export default UnderDevelopment;
