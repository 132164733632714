import React, { useState } from "react";
import { observer } from "mobx-react";
import { Visible } from "react-grid-system";

import { showDownloaded } from "utils/helpers";
import { useModal } from "utils/hooks";
import FuzuPointsIco from "images/fuzuPointsIco.svg";
import { InstantFeedbackContent } from "../ModalContents";
import { Text, Label, Button } from "../Core";
import useStore from "../../contexts/store";

// Fuzu Points Row Type
const debitRow = ({
  description,
  amount,
  type = "debit",
  created_at,
  payment_transaction,
  languageFormatType
} = {}) => {
  const activity = payment_transaction?.product?.description || description;

  const Headline = (
    <>
      <Visible sm xs>
        <Text fontWeight={600} margin="0 0 5px" fontSize={14}>
          Activity
        </Text>
      </Visible>
      <Text fontWeight={400} margin="auto 0" fontSize={14}>
        {activity}
      </Text>
    </>
  );

  const Points = (
    <>
      <Visible sm xs>
        <Text fontWeight={600} margin="15px 0 5px" fontSize={14}>
          {type === "debit" ? "Received Points" : "Used Points"}
        </Text>
      </Visible>
      {amount ? (
        <Label
          Icon={<FuzuPointsIco />}
          fontSize={16}
          fontWeight={600}
          color={type === "debit" ? "success200" : "accent300"}
          noBackground
          margin="0"
          padding="0"
        >
          {type === "debit" ? "+" : ""}
          {amount} Points
        </Label>
      ) : null}
    </>
  );

  const CreatedAt = (
    <>
      <Visible sm xs>
        <Text fontWeight={600} margin="15px 0 5px" fontSize={14}>
          Date
        </Text>
      </Visible>
      {created_at && (
        <Text fontWeight={400} margin="auto 0" fontSize={14}>
          {new Date(created_at * 1000).toLocaleString(languageFormatType, {
            month: "long",
            day: "numeric",
            year: "numeric"
          })}
        </Text>
      )}
    </>
  );

  return {
    to: "",
    item: [Headline, Points, CreatedAt]
  };
};

const creditRow = ({
  description,
  amount,
  type = "debit",
  created_at,
  payment_transaction,
  languageFormatType
} = {}) => {
  const activity = payment_transaction?.product?.name || description;

  const Headline = (
    <>
      <Visible sm xs>
        <Text fontWeight={600} margin="0 0 5px" fontSize={14}>
          Activity
        </Text>
      </Visible>
      <Text fontWeight={400} margin="auto 0" fontSize={14}>
        {activity}
      </Text>
    </>
  );

  const Money = (
    <>
      <Visible sm xs>
        <Text fontWeight={600} margin="0 0 5px" fontSize={14}>
          Used money
        </Text>
      </Visible>
      <Text fontWeight={400} margin="auto 0" fontSize={14}>
        {payment_transaction?.currency} {payment_transaction?.amount}
      </Text>
    </>
  );

  const Points = (
    <>
      <Visible sm xs>
        <Text fontWeight={600} margin="15px 0 5px" fontSize={14}>
          {type === "debit" ? "Received Points" : "Used Points"}
        </Text>
      </Visible>
      {amount ? (
        <Label
          Icon={<FuzuPointsIco />}
          fontSize={16}
          fontWeight={600}
          color={type === "debit" ? "success200" : "accent300"}
          noBackground
          margin="0"
          padding="0"
        >
          {type === "debit" ? "+" : ""}
          {amount} Points
        </Label>
      ) : null}
    </>
  );

  const CreatedAt = (
    <>
      <Visible sm xs>
        <Text fontWeight={600} margin="15px 0 5px" fontSize={14}>
          Date
        </Text>
      </Visible>
      {created_at && (
        <Text fontWeight={400} margin="auto 0" fontSize={14}>
          {new Date(created_at * 1000).toLocaleString(languageFormatType, {
            month: "long",
            day: "numeric",
            year: "numeric"
          })}
        </Text>
      )}
    </>
  );

  const Premium = (
    <PremiumProductsAction position_id={payment_transaction?.position_id} slug={payment_transaction?.product?.slug} />
  );

  return {
    to: "",
    item: [Headline, Money, Points, CreatedAt, Premium]
  };
};

const PremiumProductsCore = ({ slug, position_id }) => {
  const {
    Profile: { getGeneratedCv }
  } = useStore("User");
  const [generating, setGenerating] = useState(false);
  const openModal = useModal(<InstantFeedbackContent position_id={position_id} />, "");

  const generateCv = () => {
    setGenerating(true);
    getGeneratedCv().then(url => {
      setGenerating(false);
      if (url) showDownloaded(url, "cv.pdf");
    });
  };

  return (() => {
    switch (slug) {
      default:
        return null;
      case "position-specific-application-booster":
        return (
          <Button width="100%" onClick={openModal}>
            View Instant Feedback
          </Button>
        );
      case "global-application-booster-30-days":
        return (
          <Button.Link width="100%" to="/job/applications">
            Go to applications
          </Button.Link>
        );
      case "permanent-cv-printing":
        return (
          <Button width="100%" processing={generating} onClick={generateCv}>
            Download PDF
          </Button>
        );
    }
  })();
};

const PremiumProductsAction = observer(PremiumProductsCore);

export { debitRow, creditRow };
