import React, { useContext } from "react";
import { ScreenClassContext } from "react-grid-system";

import { useTranslation } from "b2c/hooks/useTranslation";
import alvin from "images/2021-alvin.png";
import patricia from "images/neo-patricia.png";
import eetu from "images/neo-eetu.png";
import { Title, Text, Carousel, Card, Image } from "../../../../components/Core";
import Layout from "../../../../components/Layout";
import { SliderSectionWrapper } from "./styled";

const SliderSection = () => {
  const { t } = useTranslation();

  const data = [
    {
      imgsrc: alvin,
      title: t("careers.list.title_1"),
      name: "Alvin Katto",
      testimony: t("careers.list.description_1")
    },
    {
      imgsrc: patricia,
      title: t("careers.list.title_2"),
      name: "Patricia Awori",
      testimony: t("careers.list.description_2")
    },
    {
      imgsrc: eetu,
      title: t("careers.list.title_3"),
      name: "Eetu Mattila",
      testimony: t("careers.list.description_2")
    }
  ];
  const screen = useContext(ScreenClassContext);
  const isSm = ["xs", "sm"].includes(screen);

  return (
    <SliderSectionWrapper>
      <Layout.Content tag="div">
        <Title
          loaded
          level="2"
          fontWeight={600}
          margin="0 auto 25px"
          textAlign="center"
          style={{ fontSize: isSm ? 20 : 40 }}
        >
          {t("careers.we_are_loving_it")}
        </Title>
        <Carousel
          className="wide-blue-buttons"
          settings={{
            slidesToShow: 1,
            fade: true,
            dots: true,
            responsive: [
              {
                breakpoint: 767,
                settings: {
                  slidesToShow: 1
                }
              }
            ]
          }}
        >
          {data?.map((itm, i) => (
            <Carousel.Slide key={i}>
              <Card>
                <Card.Content style={{ alignItems: "center" }}>
                  <Image src={itm.imgsrc} width="180px" height="180px" borderRadius="50%" />
                  <Text fontSize={22} fontWeight={600} margin="25px 0 0">
                    {itm.name}
                  </Text>
                  <Text fontSize={16}>{itm.title}</Text>
                  <Text fontSize={18} margin="20px auto" textAlign="center" style={{ maxWidth: "600px" }}>
                    {itm.testimony}
                  </Text>
                </Card.Content>
              </Card>
            </Carousel.Slide>
          ))}
        </Carousel>
      </Layout.Content>
    </SliderSectionWrapper>
  );
};

export default SliderSection;
