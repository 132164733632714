import React, { useContext } from "react";

import { CheckCircle, Bus, Cog, UserVoice } from "@styled-icons/boxicons-solid";
import { Wc, AirportShuttle, AirlineSeatReclineExtra, Devices, Description, Warning } from "@styled-icons/material";
import { Accessibility, Laptop, TimeFive } from "@styled-icons/boxicons-regular";

import ThemeContext from "../../contexts/theme";
import { AccessibilityItem } from "./styled";

const iconMap = {
  di_policy: CheckCircle,
  accessibility: Accessibility,
  hazard_warnings: Warning,
  public_transport_accessible: Bus,
  manually_operated_machinery: Cog,
  inaccessible_software: Laptop,
  client_communication_required: UserVoice,
  accessible_washrooms: Wc,
  adapted_transport_facility: AirportShuttle,
  adapted_workspace: AirlineSeatReclineExtra,
  assistive_tech: Devices,
  accessible_documentation: Description,
  flexible_work: TimeFive
};

const Accessibilities = ({ accesibilities }) => {
  const theme = useContext(ThemeContext);
  const propsToRender = Object.keys(accesibilities)
    .filter(key => key !== "organization_has")
    .filter(key => accesibilities[key] && accesibilities[key].selected_option === "yes")
    .map(key => ({ name: key, description: accesibilities[key].description, Icon: iconMap[key] }))
    .concat(
      (accesibilities.organization_has &&
        accesibilities.organization_has.selected_option.map((item, index) => ({
          name: item,
          description: accesibilities.organization_has.description[index],
          Icon: iconMap[item]
        }))) ||
        []
    );

  return (
    <>
      {propsToRender.map(({ name, description, Icon = () => null }) => (
        <AccessibilityItem key={name} backgroundColor={theme.grey200}>
          <Icon />
          <p>{description}</p>
        </AccessibilityItem>
      ))}
    </>
  );
};

export default Accessibilities;
