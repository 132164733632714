import React, { useEffect, useContext, forwardRef, useCallback, useState } from "react";
import { observer } from "mobx-react";
import { Link, useLocation } from "react-router-dom";
import { Row } from "react-grid-system";
import {
  RightArrowAlt,
  Home,
  Briefcase,
  Chalkboard,
  Group,
  UserCircle,
  Chat,
  UserPlus,
  LogOut,
  Bell
} from "@styled-icons/boxicons-regular";
import { SettingsOutline, QuestionMarkCircleOutline } from "@styled-icons/evaicons-outline";
import {
  Settings as SettingsSolid,
  QuestionMarkCircle as QuestionMarkCircleSolid,
  Close,
  Bell as BellSolid
} from "@styled-icons/evaicons-solid";
import {
  Home as HomeSolid,
  Briefcase as BriefcaseSolid,
  Chalkboard as ChalkboardSolid,
  Group as GroupSolid,
  UserCircle as UserCircleSolid,
  Chat as ChatSolid,
  UserPlus as UserPlusSolid
} from "@styled-icons/boxicons-solid";

import { buildImageUrlS3, getCompanyName, isBaronaBrand } from "b2c/contexts/branded";
import FuzuIcon from "images/b2c/icons/fuzu-coin.svg";
import { useInitialFocus } from "utils/hooks";
import useStore from "../../contexts/store";
import Text from "../Core/Text";
import Button from "../Core/Button";
import Image from "../Core/Image";
import MenuItem from "../MenuItem";
import ThemeContext from "../../contexts/theme";
import { StyledActivityCenter, StyledLabel, StyledMenuHeader, StyledOverlay } from "./styled";
import { Label } from "../Core";
import Icon from "../Core/Icon";
import { useNotificationsSettingsNav } from "../NotificationsSettings";
import { nameImages } from "../../constants/images";

const ActivityCenter = forwardRef((props, ref) => {
  const {
    user: { full_name: firstName, avatar },
    toggleUserAccountMenu,
    userAccountMenu
  } = useStore("User");
  const [avatarImage, setAvatar] = useState(avatar);
  const Theme = useContext(ThemeContext);
  const { pathname } = useLocation();
  const { open } = useNotificationsSettingsNav();
  const isBarona = isBaronaBrand();

  const {
    User: {
      Notifications: { updateCounterBadge }
    }
  } = useStore();

  const OnPressEscape = useCallback(event => {
    if (event.keyCode === 27) {
      toggleUserAccountMenu();
    }
  }, []);

  const hideActivityCenter = () => {
    if (userAccountMenu) {
      toggleUserAccountMenu();
    }
  };

  useEffect(() => {
    setAvatar(avatar || buildImageUrlS3(nameImages.avatarEmpty));
  }, [avatar]);

  useEffect(() => {
    document.addEventListener("keydown", OnPressEscape);

    return () => {
      document.removeEventListener("keydown", OnPressEscape);
    };
  }, []);

  useInitialFocus(ref);

  return (
    <StyledActivityCenter
      onClick={event => event.stopPropagation()}
      aria-modal="true"
      role="dialog"
      ref={ref}
      style={{
        display: "flex",
        flexDirection: "column",
        paddingBottom: "20px"
      }}
    >
      <StyledMenuHeader theme={Theme}>
        <Link onClick={hideActivityCenter} to="/profile" className="link">
          <Label
            theme="transparent"
            Icon={<Image src={avatarImage} height="51px" width="51px" margin="20px 6px" borderRadius="100%" />}
            fontSize={14}
            fontWeight={600}
            margin="0"
          >
            <Text fontSize="14px" lineHeight="20px" fontWeight={600} margin="-17px 0 0 0">
              {firstName?.length > 22 ? `${firstName?.substr(0, 22)}...` : firstName}
            </Text>
          </Label>
        </Link>

        <Link
          onClick={hideActivityCenter}
          to="/profile"
          className="link"
          style={{
            fontSize: "14px",
            position: "absolute",
            top: "54px",
            left: "71px"
          }}
        >
          View profile
          <Icon as={RightArrowAlt} width={16} />
        </Link>
        <Button.Iconed
          theme="transparent"
          onClick={hideActivityCenter}
          style={{
            position: "absolute",
            top: "3px",
            color: Theme.black350,
            right: "5px"
          }}
        >
          <Close width={26} />
        </Button.Iconed>
      </StyledMenuHeader>

      <>
        <div style={{ padding: "0 20px", width: "75%" }}>
          <MenuItem
            icon={Home}
            iconSolid={HomeSolid}
            pathname={pathname}
            path="/home"
            title="Home"
            hideActivityCenter={hideActivityCenter}
          />
          <MenuItem
            icon={Briefcase}
            iconSolid={BriefcaseSolid}
            pathname={pathname}
            path="/job"
            title="Jobs"
            hideActivityCenter={hideActivityCenter}
          />
          <MenuItem
            icon={Chalkboard}
            iconSolid={ChalkboardSolid}
            pathname={pathname}
            path="/learn"
            title="Learn"
            hideActivityCenter={hideActivityCenter}
          />
          <MenuItem
            icon={Group}
            iconSolid={GroupSolid}
            pathname={pathname}
            path="/forum"
            title="Forum"
            hideActivityCenter={hideActivityCenter}
          />
        </div>
        <Row
          justify="center"
          style={{
            marginBottom: "10px",
            paddingBottom: "10px",
            borderBottom: `1px solid ${Theme.grey200}`
          }}
        />
        <div style={{ padding: "0 20px", width: "75%" }}>
          <MenuItem
            icon={UserCircle}
            iconSolid={UserCircleSolid}
            pathname={pathname}
            path="/profile"
            title="My Profile"
            hideActivityCenter={hideActivityCenter}
          />

          <MenuItem
            icon={Chat}
            iconSolid={ChatSolid}
            pathname={pathname}
            path="/messages"
            title="Messages"
            hideActivityCenter={hideActivityCenter}
          />

          {!isBarona && (
            <MenuItem
              iconStyle={{ position: "relative", top: "6px" }}
              icon={FuzuIcon}
              iconSolid={FuzuIcon}
              pathname={pathname}
              path="/points"
              title={`${getCompanyName()} Points`}
              hideActivityCenter={hideActivityCenter}
            />
          )}

          <MenuItem
            icon={UserPlus}
            iconSolid={UserPlusSolid}
            pathname={pathname}
            path="/invite"
            title="Invite Friends"
            hideActivityCenter={hideActivityCenter}
          />
        </div>

        <Row
          justify="center"
          style={{
            marginBottom: "10px",
            paddingBottom: "10px",
            borderBottom: `1px solid ${Theme.grey200}`
          }}
        />

        <div style={{ padding: "0 20px", width: "75%" }}>
          <MenuItem
            icon={SettingsOutline}
            iconSolid={SettingsSolid}
            pathname={pathname}
            path="/settings"
            title="Account Settings"
            hideActivityCenter={hideActivityCenter}
          />

          <MenuItem
            icon={Bell}
            iconSolid={BellSolid}
            pathname={pathname}
            title="Notification Settings"
            onClick={open}
            hideActivityCenter={hideActivityCenter}
            isButton
          />

          <MenuItem
            icon={QuestionMarkCircleOutline}
            iconSolid={QuestionMarkCircleSolid}
            pathname={pathname}
            path="/messages?channel=2"
            title="Contact Support"
            hideActivityCenter={hideActivityCenter}
          />
          <Row>
            <Text
              as="a"
              href="/logout"
              fontWeight={500}
              style={{ display: "block", width: "100%", outline: "none" }}
              onClick={() => updateCounterBadge()}
            >
              <Button.Iconed
                className="nav-icon"
                color={Theme.black350}
                theme="transparent"
                style={{ position: "relative", right: "2px", display: "inline-block" }}
              >
                <LogOut width={24} />
              </Button.Iconed>
              <StyledLabel color={Theme.black350}>Logout</StyledLabel>
            </Text>
          </Row>
        </div>
      </>
      <StyledOverlay onClick={hideActivityCenter} />
    </StyledActivityCenter>
  );
});

export default observer(ActivityCenter);
