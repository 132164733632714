import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Carousel, Image } from "b2c/components/Core";

import fuzuLogo from "images/new_fuzu_logo_black.png";
import { theme } from "../../contexts/theme";

import LandingSlide from "./components/LandingSlide";
import useStandaloneLandingPage from "./utils/useStandaloneLandingPage";

const HeadBlock = styled.div`
  text-align: center;
  margin-top: 30px;
`;

const LogoWrapper = styled.a`
  display: block;
`;

const CarouselWrapper = styled.div`
  margin-top: 16px;
`;

const ButtonsBlock = styled.div`
  padding: 0 16px;
  margin-top: 24px;
`;

const PrimaryButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 48px;

  background-color: ${theme.primary};
  border-radius: 5px;

  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;

  color: #000000;
`;

const SecondaryButton = styled(PrimaryButton)`
  margin-top: 16px;
  background-color: #f3f3f3;
`;

const StandaloneLandingPage = () => {
  const { slides } = useStandaloneLandingPage();

  return (
    <section>
      <HeadBlock>
        <LogoWrapper href="/">
          <Image
            src={fuzuLogo}
            alt="Fuzu main logo"
            borderRadius="0"
            height={30}
            width={102}
            loadingType="eager"
            srcSet={`${fuzuLogo} 300w`}
          />
        </LogoWrapper>
      </HeadBlock>

      <CarouselWrapper>
        <Carousel
          className="slick-yellow-dots dots-bottom slick-stretched-on-mobile"
          autoplay
          autoplaySpeed={5000}
          arrows={false}
        >
          {slides.map(({ title, image }, index) => (
            <Carousel.Slide key={index}>
              <LandingSlide title={title} image={image} />
            </Carousel.Slide>
          ))}
        </Carousel>
      </CarouselWrapper>

      <ButtonsBlock>
        <PrimaryButton to="/signup">Get Started!</PrimaryButton>

        <SecondaryButton to="/login">I already have an account</SecondaryButton>
      </ButtonsBlock>
    </section>
  );
};

export default StandaloneLandingPage;
