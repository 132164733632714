import styled from "styled-components";

export const EliteFAQSection = styled.section`
  padding: 64px 0;
  background-color: #f3f3f3;

  @media screen and (max-width: 1200px) {
    padding: 20px 30px;
  }
`;

export const EliteFAQContent = styled.div`
  max-width: 1160px;
  width: 100%;
  margin: 0 auto;
`;

export const FAQItemContent = styled.div`
  background-color: #ffffff;
  border: 1px solid #dedede;
  border-radius: 5px;
  padding: 17px 15px 15px;
  margin-bottom: 16px;
`;
