import styled from "styled-components";

export const Content = styled.div`
  padding: 24px;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 8px;
  margin-bottom: ${({ isWizard }) => (isWizard ? "0" : "24px")};
`;

export const Title = styled.h5`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin: 0 0 8px 0;
  color: #333333;
`;

export const Description = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin: 0 0 16px 0;
  color: #666666;
  max-width: ${({ maxWidth }) => maxWidth};

  span {
    color: #333333;
  }
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const VisibilityStatus = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #333;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;

  svg {
    width: 22px;
    height: 19px;
    fill: ${({ isVisibilityProfile }) => (isVisibilityProfile ? "#00B55E" : "#666666")};
  }
`;

export const ToggleVisibility = styled.button`
  height: 40px;
  padding: 12px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: ${({ background }) => background || "#eee"};
  color: ${({ color }) => color || "#333"};
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
  cursor: pointer;

  &:hover {
    filter: brightness(90%);
  }
`;

export const Info = styled.div`
  padding: 16px;
  border-radius: 8px;
  background: #f3f8ff;
`;

export const InfoHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

export const InfoTitle = styled.h6`
  color: #333;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  text-align: left;
`;

export const InfoArrow = styled.span`
  background: transparent;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;

  svg {
    width: 20px;
    fill: #333333;
  }
`;

export const InfoContent = styled.ul`
  margin-top: 6px;
  padding-left: 20px;

  li {
    color: #666;
    font-size: 14px;
    font-weight: 400;
    line-height: 160%;
  }
`;
