import styled from "styled-components";

export const MessageWrapper = styled.section`
  display: flex;
  padding: ${props => (props.threadMessage ? "20px 0 0" : "15px 0")};
`;

export const MessageDetails = styled.div`
  width: 100%;
`;

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const UserAvatar = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  object-fit: cover;
  width: ${props => (props.threadMessage ? "28px" : "36px")};
  height: ${props => (props.threadMessage ? "28px" : "36px")};
  margin-right: 15px;
`;

export const MessageActions = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;
