import styled from "styled-components";

export const Type = styled.div`
  color: #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-bottom: 24px;
`;

export const Error = styled.div`
  border-radius: 8px;
  background: #ffeae5;
  padding: 14px;
  display: flex;
  align-items: center;
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  gap: 8px;
  margin-bottom: 24px;

  svg {
    width: 20px;
    height: 20px;
    fill: #e71d36;
    flex: none;
  }
`;
