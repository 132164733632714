import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { Row, Col, Hidden, Visible, ScreenClassContext } from "react-grid-system";
import { Buildings, Globe, Phone, Edit, Check } from "@styled-icons/boxicons-regular";

import SvgContent from "b2c/components/SvgContent";
import { withRoute } from "utils/hocs/withRoute";
import MainInformationIco from "images/b2c/profilePage/MainInformationIco.svg";
import WorkExperienceIco from "images/b2c/profilePage/WorkExperienceIco.svg";
import EducationIco from "images/b2c/profilePage/EducationIco.svg";
import SkillsIco from "images/b2c/profilePage/SkillsIco.svg";
import { getCompanyName } from "b2c/contexts/branded";
import LanguageSkillsIco from "images/b2c/profilePage/LanguageSkillsIco.svg";
import CertificatesIco from "images/b2c/profilePage/CertificatesIco.svg";
import AssociationsIco from "images/b2c/profilePage/AssociationsIco.svg";
import HobbiesIco from "images/b2c/profilePage/HobbiesIco.svg";
import { PromotionCountryVisible } from "b2c/components/WithAuth";
import ReferencesIco from "images/b2c/profilePage/ReferencesIco.svg";
// import VisibilitySettings from "b2c/components/VisibilitySettings";
import FileUploader from "b2c/components/FileUploader";
import DescriptionSection from "b2c/components/DescriptionSection";
import AvatarProgress from "b2c/components/AvatarProgress";
import { Card, Text, Title, Button, Label, Divider } from "b2c/components/Core";
import { normalizeReferences } from "b2c/utils/normalizeData";
import { useIsBarona } from "b2c/hooks";
import { useTranslation } from "b2c/hooks/useTranslation";
import CareerCoachingBanner from "../CareerCoachingBanner";
import SocialLinks from "../SocialLinks";
import ProfileVisibility from "../ProfileVisibility";
import useStore from "../../../../contexts/store";
import { ProfileAvatar } from "./styled";
import { languageFormat } from "../../../../constants/main";

const MyCareer = ({ match: { url }, location: { state = {} } }) => {
  const {
    user: { first_name: userFirst, last_name: userLast, current_work, phone_number },
    Profile: {
      state: { loading, processing },
      editable,
      profile: {
        association_items,
        certificates,
        educations,
        hobbies,
        languages,
        references,
        skills,
        work_experiences,
        uploaded_cv,
        personal_info: { first_name, last_name, country, city, social_links, phone } = {},
        basic_info: { headline, career_intro } = {}
      } = {},
      removeWorkExperience,
      removeEducation,
      removeLanguage,
      removeCertificate,
      removeAssociation,
      removeHobby,
      removeReference,
      uploadCv
    }
  } = useStore("User");
  const { features: { premium_cv_creator } = {} } = useStore("Premium");
  const isBarona = useIsBarona();
  const screen = useContext(ScreenClassContext);
  const isSm = /xs|sm|md/.test(screen);
  const { t, language } = useTranslation();
  const languageFormatType = languageFormat[language];
  const convertDate = (string, isSendEmpty) => {
    const defaultResult = isSendEmpty ? "" : "Present";

    return string
      ? new Date(string).toLocaleString(languageFormatType, { month: "short", year: "numeric" })
      : defaultResult;
  };

  const sortByPriority = (itemA, itemB, priority) => (itemA[priority] ? -1 : itemA.end_date > itemB.end_date ? -1 : 1);

  const FlowHidden = ({ children }) => (state?.noiseless ? null : children);

  const normalizedReferenceData = normalizeReferences(references, removeReference);

  useEffect(() => {
    if (window.scrollY) {
      window.scroll(0, 0);
    }
  }, []);

  const Settings = (
    <>
      <FlowHidden>
        <FileUploader
          processing={processing}
          cardProps={{ bordered: true, style: { margin: "0 0 24px", borderColor: "#eee" } }}
          title={uploaded_cv?.url ? t("profile.cv.my_cv_title") : t("profile.cv.cv_document_title")}
          name={uploaded_cv?.original_filename || "Uploaded CV"}
          accept=".pdf, .doc, .docx"
          url={uploaded_cv?.url}
          buttonText={uploaded_cv?.url ? t("profile.cv.replace_button") : t("profile.cv.upload_button")}
          onUpload={uploadCv}
          emptyComponent={<Text margin="0">{t("profile.cv.description", { name: getCompanyName() })}</Text>}
          autoFocus={state.focus === "cv_upload"}
        />

        {!isBarona && (
          <Card bordered style={{ margin: "0 0 24px", borderColor: "#eee" }}>
            <Card.Content padding="15px 20px">
              {premium_cv_creator?.activated && <Label.Premium />}

              <Title level="6" fontWeight={700} margin="0 0 5px">
                {t("profile.cv_creator.title")}
              </Title>

              <Text>{t("profile.cv_creator.description")}</Text>

              <Button.Link
                fontSize="14px"
                margin="15px auto 0"
                width="100%"
                to={{ pathname: "/cv-creator", state: { from: "/profile" } }}
              >
                {t("profile.cv_creator.button")}
              </Button.Link>
              {premium_cv_creator?.valid_to && (
                <Label
                  Icon={<Check width={18} />}
                  fontSize={14}
                  fontWeight={500}
                  noBackground
                  theme="success300"
                  padding={0}
                  margin="8px auto 0"
                >
                  CV Creator activated until{" "}
                  {new Date(premium_cv_creator?.valid_to).toLocaleString(languageFormatType, {
                    day: "numeric",
                    month: "short",
                    year: "numeric"
                  })}
                </Label>
              )}
            </Card.Content>
          </Card>
        )}
      </FlowHidden>
      {/*
      <VisibilitySettings
        loading={loading}
        toggleVisibility={toggleVisibility}
        talent_pool_visibility={talent_pool_visibility}
      />
      */}
    </>
  );

  return (
    <Row>
      <Col md={8}>
        <Card
          bordred
          style={{ margin: "0 0 24px", borderColor: "#eee" }}
          Button={
            editable && (
              <Link to={`${url}/personal-information`} title="Edit Your Profile">
                <Button noBackground theme="black500" fontSize="14px" icon={<Edit width={18} />}>
                  {t("global.edit")}
                </Button>
              </Link>
            )
          }
        >
          <Card.Content padding="24px 16px">
            <Row>
              <Col lg={2}>
                <ProfileAvatar>
                  <AvatarProgress width={100} to={`${url}/personal-information`} title="Edit Your Profile" />
                </ProfileAvatar>
              </Col>
              <Col lg={10} xs={12}>
                <Title level={3} margin={isSm ? "16px 0" : "0 0 16px"} fontWeight={600} textAlign="center">
                  {`${first_name || userFirst} ${last_name || userLast || ""}`}
                </Title>
                <Row>
                  <Col lg={5}>
                    <Label
                      Icon={<Buildings width={18} />}
                      fontSize={14}
                      fontWeight={400}
                      noBackground
                      theme={country ? "black500" : "secondary300"}
                      margin="0 0 15px"
                      padding={0}
                    >
                      {country ? (
                        (city ? `${city}, ` : "") + country?.name
                      ) : (
                        <Link className="link" to={`${url}/personal-information`}>
                          {`${t("profile.personal_info.add_location")} `}[+]
                        </Link>
                      )}
                    </Label>
                    <Label
                      Icon={<Globe width={18} />}
                      fontSize={14}
                      fontWeight={400}
                      noBackground
                      theme={current_work ? "black500" : "secondary300"}
                      margin="0 0 15px"
                      padding={0}
                    >
                      {current_work ? (
                        `${current_work.employer} - ${current_work.job_title}`
                      ) : (
                        <Link className="link" to={`${url}/work-experience`}>
                          {`${t("profile.personal_info.add_work_experience")} `}[+]
                        </Link>
                      )}
                    </Label>
                    <Label
                      Icon={<Phone width={18} />}
                      fontSize={14}
                      fontWeight={400}
                      noBackground
                      theme={phone_number ? "black500" : "secondary300"}
                      padding={0}
                      margin={0}
                    >
                      {(phone && phone.number) || phone_number ? (
                        phone ? (
                          `${phone.code}${phone.number}`
                        ) : (
                          phone_number
                        )
                      ) : (
                        <Link className="link" to={`${url}/personal-information`}>
                          {`${t("profile.personal_info.add_phone_number")} `}[+]
                        </Link>
                      )}
                    </Label>
                  </Col>
                  <Hidden sm xs>
                    <Col width="content">
                      <Divider.Vertical margin="0 8px" />
                    </Col>
                  </Hidden>
                  <Visible md sm xs>
                    <Card.Separator margin="16px 0" />
                  </Visible>
                  <Col lg={5} xs={12}>
                    <SocialLinks url={url} links={social_links} loading={loading || !social_links} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card.Content>
        </Card>

        <Visible xs sm>
          {Settings}
        </Visible>
        <ProfileVisibility />
        <DescriptionSection
          loading={loading}
          title={t("profile.career_intro.title")}
          icon={
            <SvgContent>
              <MainInformationIco />
            </SvgContent>
          }
          data={[
            {
              title: headline,
              text: career_intro
            }
          ]}
          tipTitle={t("profile.career_intro.helper_title")}
          tipText={t("profile.career_intro.helper_text")}
          isEmpty={!headline && !career_intro}
          actionUrl={{ pathname: editable && `${url}/basic-information`, state }}
        />

        <PromotionCountryVisible countryCodes={["KE", "UG"]}>
          <FlowHidden>
            <CareerCoachingBanner
              disable={loading}
              height="auto"
              smHeight="auto"
              style={{ marginBottom: 20 }}
              wideOnMobile
            />
          </FlowHidden>
        </PromotionCountryVisible>

        <DescriptionSection
          loading={loading && !work_experiences}
          title={t("profile.work_experience.title")}
          icon={
            <SvgContent>
              <WorkExperienceIco />
            </SvgContent>
          }
          data={(work_experiences || [])
            .slice()
            .sort((a, b) => sortByPriority(a, b, "current_work"))
            .map(
              ({
                id,
                job_title,
                description,
                employer,
                start_date,
                end_date,
                skills: skillsItem,
                waits_user_review
              }) => ({
                id,
                title: job_title,
                info: employer,
                text: description,
                date: `${convertDate(start_date)} - ${convertDate(end_date)}`,
                skills: skillsItem,
                onDelete: () => removeWorkExperience(id),
                alertMessage: waits_user_review ? t("profile.work_experience.alert_message") : ""
              })
            )}
          isEmpty={work_experiences && work_experiences.length === 0}
          multiple
          tipTitle={t("profile.work_experience.helper_title")}
          tipText={t("profile.work_experience.helper_text")}
          actionUrl={{ pathname: editable && `${url}/work-experience`, state }}
        />

        <DescriptionSection
          loading={loading && !educations}
          title={t("profile.education.title")}
          icon={
            <SvgContent>
              <EducationIco />
            </SvgContent>
          }
          data={(educations || [])
            .slice()
            .sort((a, b) => sortByPriority(a, b, "current_education"))
            .map(({ id, school_name, degree_name, description, start_date, end_date, grade, waits_user_review }) => ({
              id,
              title: degree_name,
              info: school_name,
              additional: grade ? `${t("wizard.education.grade")}: ${grade}` : null,
              date: `${convertDate(start_date)} - ${convertDate(end_date)}`,
              text: description,
              onDelete: () => removeEducation(id),
              alertMessage: waits_user_review ? t("profile.education.alert_message") : ""
            }))}
          multiple
          isEmpty={educations && educations.length === 0}
          tipTitle={t("profile.education.helper_title")}
          tipText={t("profile.education.helper_text")}
          actionUrl={{ pathname: editable && `${url}/education`, state }}
        />

        <DescriptionSection
          loading={loading && !skills}
          title={t("profile.skills.title")}
          icon={
            <SvgContent>
              <SkillsIco />
            </SvgContent>
          }
          dataSkills={(skills || []).slice()}
          isEmpty={skills && skills.length === 0}
          multiple
          tipTitle={t("profile.skills.helper_title")}
          tipText={t("profile.skills.helper_text")}
          actionUrl={{ pathname: editable && `${url}/skills`, state }}
          testScope="skills"
        />

        <DescriptionSection
          loading={loading && !languages}
          title={t("profile.languages.title")}
          icon={
            <SvgContent>
              <LanguageSkillsIco />
            </SvgContent>
          }
          data={(languages || []).slice().map(({ id, name, spoken, written }) => ({
            title: name,
            info: `${t("wizard.languages.spoken")}: ${spoken}. ${t("wizard.languages.written")}: ${written}`,
            onDelete: () => removeLanguage(id),
            id
          }))}
          multiple
          isEmpty={languages && languages.length === 0}
          tipTitle={t("profile.languages.helper_title")}
          tipText={t("profile.languages.helper_text")}
          actionUrl={{ pathname: editable && `${url}/languages`, state }}
        />

        <DescriptionSection
          loading={loading && !certificates}
          title={t("profile.certificates.title")}
          icon={
            <SvgContent>
              <CertificatesIco />
            </SvgContent>
          }
          dataAchievements={(certificates || []).slice().map(({ id, name, description, issued_at }) => ({
            title: name,
            text: description,
            date: convertDate(issued_at, true),
            onDelete: () => removeCertificate(id),
            id
          }))}
          multiple
          tipShow
          isEmpty={certificates && certificates.length === 0}
          tipTitle={t("profile.certificates.helper_title")}
          tipText={t("profile.certificates.helper_text")}
          actionUrl={{ pathname: editable && `${url}/certificates`, state }}
        />

        <DescriptionSection
          loading={loading && !association_items}
          title={t("profile.associations.title")}
          icon={
            <SvgContent>
              <AssociationsIco />
            </SvgContent>
          }
          data={(association_items || []).slice().map(({ id, name, role }) => ({
            title: name,
            info: role,
            onDelete: () => removeAssociation(id)
          }))}
          multiple
          tipShow
          isEmpty={association_items && association_items.length === 0}
          tipTitle={t("profile.associations.helper_title")}
          tipText={t("profile.associations.helper_text")}
          actionUrl={{ pathname: editable && `${url}/associations`, state }}
        />

        <DescriptionSection
          loading={loading && !hobbies}
          title={t("profile.hobbies.title")}
          icon={
            <SvgContent>
              <HobbiesIco />
            </SvgContent>
          }
          data={(hobbies || []).slice().map(({ id, name }, index) => ({
            title: index === 0 ? t("profile.hobbies.sub-title") : "",
            text: name,
            onDelete: () => removeHobby(id)
          }))}
          multiple
          tipShow
          isEmpty={hobbies && hobbies.length === 0}
          tipTitle={t("profile.hobbies.helper_title")}
          tipText={t("profile.hobbies.helper_text")}
          actionUrl={{ pathname: editable && `${url}/hobbies`, state }}
        />

        <DescriptionSection
          loading={loading && !references}
          title={t("profile.references.title")}
          icon={
            <SvgContent>
              <ReferencesIco />
            </SvgContent>
          }
          data={normalizedReferenceData}
          multiple
          tipShow
          isEmpty={references && references.length === 0}
          tipTitle={t("profile.references.helper_title")}
          tipText={t("profile.references.helper_text", { name: getCompanyName() })}
          actionUrl={{ pathname: editable && `${url}/references`, state }}
        />
      </Col>
      <Hidden sm xs>
        <Col md={4}>{Settings}</Col>
      </Hidden>
    </Row>
  );
};

export default withRoute(observer(MyCareer));
