import React from "react";
import { Row, Col } from "react-grid-system";

import { Title } from "b2c/components/Core";

const ModalContent = ({ title, Actions = [], children }) => {
  return (
    <>
      <Title level={3} scales textAlign="center" fontWeight={600} margin="-12px auto 24px">
        {title}
      </Title>
      {children}
      <Row justify="between" gutterWidth={24}>
        {Actions.map((ButtonProp, index) => (
          <Col key={`action-${index}`}>{React.cloneElement(ButtonProp, { width: "100%", margin: "8px 0 0" })}</Col>
        ))}
      </Row>
    </>
  );
};

export default ModalContent;
