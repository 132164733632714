import React from "react";

import { Card, Spinner } from "../Core";

const LoadingStep = () => {
  return (
    <Card theme="grey100">
      <Card.Content height="30vh">
        <Spinner size="50%" margin="auto" secondaryFill="grey200" />
      </Card.Content>
    </Card>
  );
};

export default LoadingStep;
