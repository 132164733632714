import React, { useEffect } from "react";

import { useTranslation } from "b2c/hooks/useTranslation";
import { Card, Title, Text, Image } from "../../../../components/Core";
import CourseResult from "../CourseResult";
import { buildImageUrlS3 } from "../../../../contexts/branded";
import { nameImages } from "../../../../constants/images";

const TestCompleted = ({ id, getTestResult, testResults, setFinished, isJobPage }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (!isJobPage) {
      getTestResult(id);
    }
  }, [isJobPage]);

  useEffect(() => {
    return () => {
      setFinished();
    };
  }, []);

  return (
    <>
      <Card wideOnMobile>
        <Card.Content padding="48px 24px">
          <Image
            src={buildImageUrlS3(nameImages.courseSuccess)}
            style={{ margin: "auto", display: "block", width: "225px" }}
          />

          <Title level={4} width="100%" textAlign="center" fontWeight={500} margin="20px 0">
            {t("test_and_survey.success_title")}
          </Title>
          {testResults?.form_result.stats && (
            <Text width="100%" textAlign="center" lineHeight="160%">
              {t("test_and_survey.success_description", {
                count: testResults?.form_result?.stats?.correct_count,
                total: testResults?.form_result?.stats.total_count
              })}
            </Text>
          )}
        </Card.Content>
      </Card>
      {testResults && testResults.form_result && <CourseResult questions={testResults.form_result.questions} />}
    </>
  );
};

export default TestCompleted;
