import React from "react";
import { observer } from "mobx-react";

import { Button } from "../Core";
import useStore from "../../contexts/store";

const LinkSupportChannel = ({
  children,
  theme = "primary",
  height = 42,
  margin = "24px auto",
  padding = "0 15px",
  style,
  width,
  color,
  fontSize,
  isTargetBlank
}) => {
  const { fuzu_support_channel } = useStore("initialState");
  const { user } = useStore("User");

  if (isTargetBlank) {
    return (
      <Button
        href={`messages?channel=${fuzu_support_channel?.id}`}
        theme={theme}
        as="a"
        target="_blank"
        color={color}
        padding={padding}
        fontSize={fontSize}
        style={style}
        width={width}
        margin={margin}
      >
        {children}
      </Button>
    );
  }
  return (
    <Button.Link
      margin={margin}
      theme={theme}
      height={height}
      padding={padding}
      fontSize={fontSize}
      to={user ? `/messages?channel=${fuzu_support_channel?.id}` : "/login"}
      style={style}
      width={width}
      color={color}
    >
      {children}
    </Button.Link>
  );
};

export default observer(LinkSupportChannel);
