import React from "react";

import surveyPlaceholder from "images/b2c/survey_placeholder.jpg";
import { Card, Title } from "../Core";
import { LoadingContent } from "../Core/Spinner";
import { IntroductionContents } from "./styled";

const Introduction = ({ loading, name, image = surveyPlaceholder, actions, dataCy, children }) => {
  return (
    <IntroductionContents data-cy={dataCy || "introductionContents"}>
      <Card>
        <Card.Header backgroundImage={image} height={320} />

        <Card.Content padding="16px 12px">
          <LoadingContent loading={loading} size="75px" margin="48px auto">
            <Title level={2} scales fontWeight={600} margin="0 0 16px">
              {name}
            </Title>
            {children}
          </LoadingContent>
        </Card.Content>

        {actions && (
          <Card.Footer separator display="block" padding="16px">
            {actions}
          </Card.Footer>
        )}
      </Card>
    </IntroductionContents>
  );
};

export default Introduction;
