import React, { useContext } from "react";
import { ScreenClassContext } from "react-grid-system";

import { useTranslation } from "b2c/hooks/useTranslation";
import Layout from "../../../../components/Layout";
import { Title, Button } from "../../../../components/Core";
import { PromoSectionWrapper } from "./styled";

const PromoSection = props => {
  const screen = useContext(ScreenClassContext);
  const isSm = ["xs", "sm"].includes(screen);
  const { t } = useTranslation();

  return (
    <PromoSectionWrapper>
      <Layout.Content size="xl" tag="div">
        <Title width="100%" textAlign="center" color="white" style={{ fontSize: isSm ? 36 : 54 }}>
          {t("careers.join_team_title")}
        </Title>
        <Button margin="10px 0" width="320px" height="48px" {...props}>
          {t("careers.join_team_button")}
        </Button>
      </Layout.Content>
    </PromoSectionWrapper>
  );
};

export default PromoSection;
