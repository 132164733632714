import React from "react";
import { useTranslation } from "b2c/hooks/useTranslation";

import Layout from "b2c/components/Layout";
import SortingSelect from "b2c/components/SortingSelect";
import { Text, Title } from "b2c/components/Core";
import { useIsLoggedIn } from "../../../../../utils/hooks";
import { StatRow } from "./styled";
import { useLanguageAndCountryUrl } from "../../../../hooks";

const ViewTools = ({ title, slug = "jobs", totalCount = 0, currentSorting, sortingOptions, applyFilter }) => {
  const isLoggedIn = useIsLoggedIn();
  const { t } = useTranslation();
  const languageAndLanguage = useLanguageAndCountryUrl();

  return (
    <Layout.Content size="xl" tag="div" style={{ flex: "none" }}>
      {title && !isLoggedIn && (
        <Title loading={false} level={1} fontWeight={600} margin="16px 0 0 0" style={{ fontSize: "24px" }}>
          {title}
        </Title>
      )}

      <StatRow>
        <div className="job-stats">
          {slug !== "applications" && slug !== "saved" ? <Text inline>{t("jobs.content.found")}</Text> : null}
          <Text inline fontWeight={500} color={totalCount > 0 ? "secondary300" : "black200"} margin="0 6px">
            {totalCount}
          </Text>
          <Text inline>{slug === "applications" ? t("jobs.content.applications") : t("jobs.content.jobs")}</Text>
        </div>
        <SortingSelect
          baseProperty={slug === "applications" ? "id" : "value"}
          currentValue={currentSorting}
          options={sortingOptions}
          onSelect={sort_by => applyFilter({ sort_by, languageAndLanguage })}
        />
      </StatRow>
    </Layout.Content>
  );
};

export default ViewTools;
