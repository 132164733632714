import React from "react";

import failure from "images/fa-exclamation-circle-red-big.png";
import success from "images/b2c/step-success.png";
import { Button, Image, Text } from "../../../../components/Core";

const ResultContent = ({ error, message, from }) => (
  <>
    <Image margin="48px auto 16px" width={75} height={75} src={error ? failure : success} />
    <Text fontSize={16} margin="8px auto" fontWeight={500}>
      {message}
    </Text>
    {!error && (
      <Button.Link margin="0 auto 24px" theme="secondary300" to={from}>
        Back to previous page
      </Button.Link>
    )}
  </>
);

export default ResultContent;
