import React from "react";

import { navigateTo } from "utils/urlHelpers";
import LinkWithFullUrl from "../LinkWithFullUrl";

const HardReloadLink = props => {
  const navigate = e => {
    e.preventDefault();
    navigateTo(props.to);
  };
  return <LinkWithFullUrl {...props} onClick={navigate} />;
};

export default HardReloadLink;
