import React, { useContext, forwardRef } from "react";
import { Row, Col, ScreenClassContext } from "react-grid-system";

import { useTranslation } from "b2c/hooks/useTranslation";
import { Title, Text, Button } from "b2c/components/Core";
import Layout from "b2c/components/Layout";
import { globalAfrica } from "b2c/constants/landing";
import { SelectCountry } from "b2c/components/SelectCountry";
import LandingContext from "../../../../contexts/landing";
import CountryContext from "../../../../contexts/country";
import NewPricingPlans from "../NewPricingPlans";
import { PricingWrapper, TitleWrapper, PricingList } from "./styled";

const PricingSection = ({ openContactUsModal }, ref) => {
  const screen = useContext(ScreenClassContext);
  const isSm = ["xs", "sm"].includes(screen);
  const { t } = useTranslation();

  const { country = globalAfrica } = useContext(CountryContext);
  const { pricing } = useContext(LandingContext);

  return (
    <Layout.Section
      style={{
        backgroundImage: "linear-gradient(0deg, rgba(246, 252, 255, 0.0001) 0%, #F7F7F7 100%)"
      }}
    >
      <PricingWrapper ref={ref} id="country-selector">
        <TitleWrapper>
          <Title
            loaded
            level="2"
            fontWeight={600}
            margin="0 auto 10px"
            textAlign="center"
            style={{ fontSize: isSm ? 20 : 32, display: "inline-block", width: "70%" }}
          >
            {t("pricing.title", { country: country?.slug ? ` ${country.name}'s` : " Africa's" })}
          </Title>
        </TitleWrapper>

        <SelectCountry country={country} isEmployer />

        <Text
          fontSize={isSm ? 14 : 16}
          lineHeight="24px"
          width="100%"
          textAlign="center"
          style={{ padding: isSm ? "0 10px" : "0" }}
          margin="0 0 18px"
        >
          {t("pricing.description")}
        </Text>

        <PricingList>
          {pricing?.pricingPlans?.map(plan => {
            return (
              <NewPricingPlans
                key={plan.type}
                description={plan.prices[country?.country_code]?.descr || plan.descr}
                {...plan}
                isSm={isSm}
                countryCode={country?.country_code}
                countrySlug={country?.slug}
              />
            );
          })}
        </PricingList>

        <Row>
          <Col md={10}>
            <Text
              fontSize={16}
              fontWeight={600}
              textAlign={isSm ? "center" : "start"}
              margin={`20px 0 20px ${isSm ? "0" : "10"}px`}
            >
              <span>{t("pricing.still_have_questions_title")}</span>
              <span style={{ fontWeight: isSm ? "300" : "600", marginLeft: "5px" }}>
                {t("pricing.still_have_questions_description")}
              </span>
            </Text>
          </Col>
          <Col md={2}>
            <div style={{ textAlign: isSm ? "center" : "end" }}>
              <Button
                theme="black0"
                color="white"
                fontSize={14}
                margin="0 13px 0 0"
                width={isSm ? 320 : 160}
                onClick={() => openContactUsModal({ aggressive: true })}
              >
                {t("pricing.still_have_questions_button")}
              </Button>
            </div>
          </Col>
        </Row>
      </PricingWrapper>
    </Layout.Section>
  );
};

export default forwardRef(PricingSection);
