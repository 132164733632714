import React, { useState } from "react";
import { Card, Label, RadioButton } from "../../Core";

const jobInterests = ["CarDriver", "Cashier"];

const JobInterest = () => {
  const [selected, setSelected] = useState("");

  const handleSelect = e => {
    const { checked } = e.target;
    setSelected(checked);
  };

  return (
    <Card theme="grey100">
      <Card.Content>
        {jobInterests.map(type => (
          <RadioButton
            key={type}
            id={type}
            name="jobInterest"
            value={type}
            borderedView
            margin="6px 0"
            onChange={handleSelect}
            checked={selected}
            checkedColor="white"
            checkedBgColor="white"
            markColor="black500"
            label={
              <Label
                theme="transparent"
                // Icon={<Image src={flags.get(slug)} height={28} margin="0 8px 0 0" />}
                fontSize={14}
                fontWeight={600}
                margin="0"
              >
                {type}
              </Label>
            }
          />
        ))}
      </Card.Content>
    </Card>
  );
};

export default JobInterest;
