import React, { useRef, useState } from "react";
import parse from "html-react-parser";
import { Row, Col } from "react-grid-system";
import { Link } from "react-router-dom";

import { useTranslation } from "b2c/hooks/useTranslation";
import ListMarker from "images/b2c/icons/listMarker.png";
import AbsentIcon from "images/b2c/icons/absent.png";
import LeftIcon from "images/b2c/landing-page/left-arrow.png";
import laptopImage from "images/b2b/landing-page/laptopImage.png";
import { Button, Text, List, Label, Card, Title, Image } from "b2c/components/Core";
import Layout from "b2c/components/Layout";
import { theme } from "b2c/contexts/theme";
import { StyledMarker, StyledDescr, PricingWrapper, TitleWrapper, PricingList } from "./styled";
import Terms from "../Terms";

const Plan = ({ details, country, openContactUsModal, backURL }) => {
  const { isBigScreen, isSingleElement } = details[country?.country_code];
  const { t } = useTranslation();

  const scrollableRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleMouseDown = e => {
    setIsDragging(true);
    setStartX(e.clientX);
    setScrollLeft(scrollableRef.current.scrollLeft);
    scrollableRef.current.style.cursor = "grabbing";
  };

  const handleMouseMove = e => {
    if (!isDragging) return;
    scrollableRef.current.scrollLeft = scrollLeft + (startX - e.clientX);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    scrollableRef.current.style.cursor = "grab";
  };

  const onModalOpenHandler = pricing => {
    openContactUsModal();
    const pricingData = {
      title: pricing.title,
      value: pricing.prices.value,
      per: pricing.prices.per
    };
    localStorage.setItem("contactUsPricing", JSON.stringify(pricingData));
  };

  return (
    <Layout.Section style={{ backgroundColor: "white" }}>
      <PricingWrapper isBigScreen={isBigScreen}>
        <TitleWrapper>
          <Title
            loaded
            level="1"
            fontWeight={600}
            margin="0 auto 10px"
            textAlign="center"
            style={{ fontSize: 36, display: "inline-block" }}
          >
            {t(details.title)}
          </Title>
        </TitleWrapper>
        <Text fontSize={18} lineHeight="24px" width="100%" textAlign="center" margin="0 0 18px">
          {t(details.tagline)}
          <br />
        </Text>

        <PricingList
          ref={scrollableRef}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
        >
          <Card
            shadow
            bordered
            width="330px"
            style={{
              margin: "0 10px 0",
              flex: "0 0 335px",
              padding: 0,
              position: "relative"
            }}
          >
            <Card.Header style={{ flexDirection: "column", overflow: "hidden" }} padding="0">
              <Card bordered style={{ backgroundColor: "#f3f3f3", height: "447px", marginBottom: "45px" }}>
                <div style={{ margin: "20px" }}>
                  <Text fontSize="16px" fontWeight={700}>
                    {parse(t("pricing.compare_products"))}
                  </Text>
                </div>
                <Image
                  margin="110px 0 0 75px"
                  width={500}
                  height={260}
                  loadingType="eager"
                  src={laptopImage}
                  alt="Laptop Image"
                />
              </Card>
            </Card.Header>
            <Card.Content padding="10px 15px" style={{ flex: "1 0 auto" }}>
              <List>
                {details[country.country_code].descr.map((desc, i) => (
                  <React.Fragment key={i}>
                    <List.Item
                      lineHeight="20px"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center"
                      }}
                      height="72px"
                      bulletSize="0"
                    >
                      <StyledDescr>{t(desc)}</StyledDescr>
                    </List.Item>
                    <Card.Separator height={1} margin={0} />
                  </React.Fragment>
                ))}
              </List>
            </Card.Content>
            <Card.Separator height={1} margin="0" />
          </Card>
          <Link
            to={{
              pathname: backURL,
              query: { scroll: 1400 }
            }}
            style={{
              position: "absolute",
              top: "2%",
              zIndex: "1",
              cursor: "pointer"
            }}
          >
            <Image margin="15px" width={15} height={15} loadingType="eager" src={LeftIcon} alt="Left Icon" />
          </Link>

          {details[country.country_code].pricings.map((pricing, i) => {
            return (
              <Card
                key={i}
                shadow
                bordered
                borderWidth="1px"
                borderColor={pricing.popular ? "primary" : "#B9B9B9"}
                style={{
                  margin: "0 10px 0",
                  flex: isSingleElement ? "1 0 0" : "0 0 335px",
                  position: "relative",
                  backgroundColor: pricing.popular ? theme.primary50 : "white"
                }}
              >
                {pricing.popular && !pricing.isNotShowPopularText ? (
                  <Text margin="12px" style={{ position: "absolute" }}>
                    <Label theme="secondary200" color="white">
                      {pricing.tag ? t(pricing.tag) : t("pricing.popular")}
                    </Label>
                  </Text>
                ) : null}
                <Card.Header
                  style={{
                    flexDirection: "column",
                    height: "237px",
                    flexFlow: isSingleElement ? "column" : "row wrap",
                    alignItems: isSingleElement ? "flex-start  " : "flex-end"
                  }}
                  padding="40px 0 10px 20px"
                >
                  <Text fontSize={28} margin={0} fontWeight={600}>
                    {t(pricing.title)}
                  </Text>
                  <Text fontSize={14} margin={0} fontWeight={600} color="black300">
                    {parse(t(pricing.tagline))}
                  </Text>
                  <Row align="end" style={{ height: "50px" }}>
                    <Col>
                      {pricing.prices.discount && (
                        <Text
                          fontSize={16}
                          color="accent400"
                          fontWeight={600}
                          style={pricing.prices.removed ? { textDecorationLine: "line-through" } : {}}
                        >
                          {pricing.prices.discount}
                        </Text>
                      )}
                      {pricing.prices.helperText && (
                        <Text fontSize={16} color="accent300" fontWeight={600}>
                          {t(pricing.prices.helperText)}
                        </Text>
                      )}
                      <Row margin="0 0 0 5px">
                        <Text
                          fontSize={28}
                          margin="0 0 0 9px"
                          style={pricing.prices.isThroughPrice ? { textDecorationLine: "line-through" } : {}}
                        >
                          {t(pricing.prices.value)}
                        </Text>
                        <Text fontSize={12} margin="15px 0 0 0">
                          {t(pricing.prices.per)}
                        </Text>
                      </Row>
                    </Col>
                  </Row>
                </Card.Header>
                <Card.Content padding="0 15px" style={{ flex: "1 0 auto" }}>
                  <Button fontSize={18} width="100%" onClick={() => onModalOpenHandler(pricing)}>
                    {t("pricing.get_started")}
                  </Button>
                  <div className="list-vertical" style={{ marginBottom: "80px" }}>
                    <Terms terms={pricing.terms} />
                  </div>
                  <List style={{ padding: 0 }}>
                    {pricing.actives.map((active, index) => {
                      return typeof active === "string" ? (
                        <React.Fragment key={index}>
                          <Text
                            fontSize={14}
                            color="#292929"
                            style={{
                              fontSize: "14px",
                              color: "#292929",
                              textAlign: "center",
                              margin: "27px auto"
                            }}
                          >
                            {t(active)}
                          </Text>
                          <Card.Separator height={1} margin="0" theme={pricing.popular ? "primary" : "grey200"} />
                        </React.Fragment>
                      ) : (
                        <React.Fragment key={index}>
                          <StyledMarker>
                            <Image
                              width={20}
                              height={20}
                              style={{ marginTop: "25px" }}
                              src={active ? ListMarker : AbsentIcon}
                              alt="Circle"
                            />
                          </StyledMarker>
                          <Card.Separator height={1} margin="0" theme={pricing.popular ? "primary" : "grey200"} />
                        </React.Fragment>
                      );
                    })}
                  </List>
                </Card.Content>

                <Card.Footer style={{ flexDirection: "column" }} padding="20px 15px">
                  <Text fontSize={20} fontWeight={600}>
                    {t(pricing.title)}
                  </Text>
                  <Row>
                    <Text fontSize={18} margin="2px 0 0 9px">
                      {t(pricing.prices.value)}
                    </Text>
                    <Text fontSize={12} margin="7px 0 0 0">
                      {t(pricing.prices.per)}
                    </Text>
                  </Row>

                  <Button fontSize={18} width="100%" margin="5px 0 20px 0" onClick={openContactUsModal}>
                    {t("pricing.get_started")}
                  </Button>
                </Card.Footer>
              </Card>
            );
          })}
        </PricingList>
      </PricingWrapper>
    </Layout.Section>
  );
};

export default Plan;
