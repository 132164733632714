import slideImage1 from "./images/slide-1.png";
import slideImage2 from "./images/slide-2.png";
import slideImage3 from "./images/slide-3.png";

const slides = [
  {
    image: slideImage1,
    title: "All jobs, for all seniority <br /> levels – in one place"
  },
  {
    image: slideImage2,
    title: "Inspiring content <br /> every day"
  },
  {
    image: slideImage3,
    title: "Africa’s favorite <br /> career community"
  }
];

export default slides;
