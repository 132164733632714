import React, { useContext, useEffect } from "react";
import { ScreenClassContext } from "react-grid-system";

import { useDocumentTitle } from "utils/hooks";
import baronaMobile from "images/b2c/landing-page/aboutBaronaMobile.jpg";
import baronaDesctop from "images/b2c/landing-page/aboutBaronaDesktop.jpg";
import { useTranslation } from "b2c/hooks/useTranslation";
import BaronaMessage from "../BaronaMessage";
import BaronaIntroduction from "../BaronaIntroduction";
import BaronaCeo from "../BaronaCeo";
import BaronaHowItWorks from "../BaronaHowItWorks";
import BaronaContactUs from "../BaronaContactUs";
import BaronaHeading from "../BaronaHeading";

const AboutBarona = () => {
  const screen = useContext(ScreenClassContext);
  const isSm = ["xs", "sm"].includes(screen);
  const { t } = useTranslation();

  useEffect(() => {
    if (window.scrollY) {
      window.scroll(0, 0);
    }
  }, []);

  const backgroundImage = () => {
    if (isSm) {
      return baronaMobile;
    }

    return baronaDesctop;
  };

  useDocumentTitle(t("about.meta_title"));

  return (
    <>
      <BaronaHeading backgroundImage={backgroundImage()} />
      <BaronaMessage />
      <BaronaIntroduction />
      <BaronaCeo />
      <BaronaHowItWorks />
      <BaronaContactUs />
    </>
  );
};

export default AboutBarona;
