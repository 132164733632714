import styled from "styled-components";
import { Image } from "b2c/components/Core";
import { theme } from "../../../../contexts/theme";

export const Wrapper = styled.section`
  background: ${({ backgroundImage }) => (backgroundImage ? `url(${backgroundImage}) ` : "")} ${theme.primary};
  background-position: center 105%;
  background-size: 1232px auto;
  background-repeat: no-repeat;
  display: flex;
  align-items: flex-end;
  padding-top: 16px;

  @media screen and (max-width: 991px) {
    padding-bottom: 20px;
  }

  @media screen and (max-width: 767px) {
    padding-top: 0;
    padding-bottom: 0;
    height: 680px;
    align-items: center;
  }
`;

export const ImageContainer = styled(Image)`
  width: 100%;
  height: auto;
  margin: 0 0 24px;
`;

export const ImageContent = styled.div`
  width: calc(100% + 48px);
  margin-left: -24px;
`;
