import { Link } from "react-router-dom";
import React from "react";

import PulseLogo from "images/b2c/badges/pulse.png";
import { Text } from "../Core";

const MtnBanner = () => {
  return (
    <>
      <img src={PulseLogo} width={100} alt="pulse" style={{ margin: "auto 0 auto auto", maxHeight: "32px" }} />
      <Text color="white" fontSize={14} margin="12px auto 12px 12px">
        <b>MTN Pulsers!</b> Register now and get <b>400 Fuzu Points</b>. Get started{" "}
        <Text
          as={Link}
          className="link underlined"
          color="white"
          fontSize={14}
          fontWeight={700}
          to="/signup/mtn-pulse"
          target="_blank"
        >
          HERE
        </Text>
      </Text>
    </>
  );
};

export default MtnBanner;
