import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";

import agriculture from "@images/b2c/onboarding/jobInterest/agriculture.png";
import business from "@images/b2c/onboarding/jobInterest/business.png";
import customerService from "@images/b2c/onboarding/jobInterest/customerService.png";
import driver from "@images/b2c/onboarding/jobInterest/driver.png";
import engineering from "@images/b2c/onboarding/jobInterest/engineering.png";
import handyman from "@images/b2c/onboarding/jobInterest/handyman.png";
import health from "@images/b2c/onboarding/jobInterest/health.png";
import hotels from "@images/b2c/onboarding/jobInterest/hotels.png";
import human from "@images/b2c/onboarding/jobInterest/human.png";
import software from "@images/b2c/onboarding/jobInterest/software.png";
import legal from "@images/b2c/onboarding/jobInterest/legal.png";
import marketing from "@images/b2c/onboarding/jobInterest/marketing.png";
import security from "@images/b2c/onboarding/jobInterest/security.png";
import cashier from "@images/b2c/onboarding/jobInterest/cashier.png";
import housekeeper from "@images/b2c/onboarding/jobInterest/housekeeper.png";
import electrician from "@images/b2c/onboarding/jobInterest/electrician.png";
import nurse from "@images/b2c/onboarding/jobInterest/nurse.png";
import office from "@images/b2c/onboarding/jobInterest/office.png";
import receptionist from "@images/b2c/onboarding/jobInterest/receptionist.png";
import cook from "@images/b2c/onboarding/jobInterest/cook.png";
import cleaner from "@images/b2c/onboarding/jobInterest/cleaner.png";
import motoDriver from "@images/b2c/onboarding/jobInterest/moto-driver.png";
import fieldSalesAgent from "@images/b2c/onboarding/jobInterest/field-sales-agent.png";
import { Col, Row } from "react-grid-system";
import { Card, RadioButton, Image, Text } from "../../../Core";

const iconsMapper = {
  legal,
  human,
  agriculture,
  health,
  engineering,
  business,
  "customer-service": customerService,
  cashier,
  cook,
  cleaner,
  software,
  driver,
  electrician,
  "field-sales-agent": fieldSalesAgent,
  housekeeper,
  handyman,
  nurse,
  office,
  receptionist,
  security,
  hotels,
  "moto-driver": motoDriver
};

const MostInterests = ({ list }) => {
  const [selected, setSelected] = useState({});
  const handleSelect = event => {
    const selectedInterest = list.find(item => item?.id === +event.target.value);
    setSelected(selectedInterest);
  };

  useEffect(() => {
    if (list) {
      const onboardingForm = JSON.parse(localStorage.getItem("onboardingForm"));
      const selectedInterest = list.find(item => item?.id === +onboardingForm.mostInterest);
      if (selectedInterest) {
        setSelected(selectedInterest);
      }
    }
  }, [list]);

  return (
    <Card theme="grey100">
      <Card.Content>
        {list.map(({ id, title, logo_file }) => (
          <RadioButton
            key={id}
            id={+id}
            name="mostInterested"
            value={+id}
            borderedView
            margin="6px 0"
            onChange={handleSelect}
            checked={+selected?.id === +id}
            markColor="white"
            checkedColor="secondary200"
            checkedBgColor="secondary100"
            style={{ outline: "none" }}
            labelProps={{
              style: {
                outline: "none",
                flexDirection: "row-reverse",
                justifyContent: "space-between",
                borderColor: +selected?.id === +id ? "#4690FF" : "transparent",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.08)",
                padding: "8px"
              }
            }}
            Label={
              <Row>
                <Col width="content">
                  <Image src={iconsMapper[logo_file] || marketing} width={35} />
                </Col>
                <Col style={{ display: "flex", outline: "none" }}>
                  <Text fontSize={16} fontWeight={500}>
                    {title}
                  </Text>
                </Col>
              </Row>
            }
          />
        ))}
      </Card.Content>
    </Card>
  );
};

export default observer(MostInterests);
