import React from "react";
import { RightArrowAlt } from "@styled-icons/boxicons-regular";
import { useTranslation } from "b2c/hooks/useTranslation";

import { Container, Content, Title, GetInTouchButton, GetInTouchLink } from "./styled";
import { useLanguageAndCountryUrl } from "../../../../hooks";

const BaronaContactUs = ({ openContactUsModal }) => {
  const { t } = useTranslation();
  const languageAndLanguage = useLanguageAndCountryUrl();

  return (
    <Container>
      <Content>
        <Title>{t("global.contact_today")} </Title>
        {openContactUsModal ? (
          <GetInTouchButton onClick={openContactUsModal}>
            {t("global.get_in_touch")} <RightArrowAlt />
          </GetInTouchButton>
        ) : (
          <GetInTouchLink to={`${languageAndLanguage}/contact`}>
            {t("global.get_in_touch")} <RightArrowAlt />
          </GetInTouchLink>
        )}
      </Content>
    </Container>
  );
};

export default BaronaContactUs;
