import styled from "styled-components";

export const Head = styled.div`
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  padding-top: 0;
  height: 630px;
  align-items: center;
`;

export const HeadContent = styled.div`
  margin: auto auto 64px auto;
  max-width: 1000px;

  @media screen and (min-width: 1008px) {
    margin: auto;
    width: 100%;
  }
`;

export const LogoContainer = styled.div`
  margin-bottom: 32px;

  svg {
    width: 110px;
    height: 24px;
  }

  path {
    fill: #fff;
  }
`;

export const HeadTitle = styled.h2`
  color: #fff;
  text-align: center;
  font-family: Originals2;
  font-size: 60px;
  font-weight: 400;
  line-height: 50px;
  text-align: left;
  max-width: 720px;

  @media screen and (min-width: 1008px) {
    font-size: 90px;
    line-height: 90px;
    text-transform: uppercase;
  }
`;
