import styled from "styled-components";

import { X } from "@styled-icons/boxicons-regular";

export const BuilderWrapper = styled.div`
  background: ${({ backgroundColor }) => backgroundColor};
  width: 100%;
  min-height: 100vh;
  display: flex;
`;

export const CloseIcon = styled(X)`
  position: absolute;
  width: 24px;
  height: 24px;
  top: 24px;
  right: 24px;
  cursor: pointer;
  z-index: 11;
  @media screen and (max-width: 767px) {
    top: 12px;
    right: 12px;
  }

  ${({ alternative }) =>
    alternative
      ? `
        background: #fff;
        border-radius: 50%;
    `
      : ""}
`;
