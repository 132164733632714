import React, { useState, useContext, useEffect, useCallback } from "react";
import { observer } from "mobx-react";
import { useScreenClass } from "react-grid-system";
import styled from "styled-components";

import { highlightField, debounce } from "utils/helpers";
import { usePreventSubmission } from "utils/hooks";
import ThemeContext from "../../contexts/theme";
import useStore from "../../contexts/store";
import { Input, Spinner, Text } from "../Core";

const TownInput = ({ loading = false, defaultValue = "", onChange, ...inputProps }) => {
  const {
    state: { loading: processing },
    towns,
    getTowns
  } = useStore("Common");
  const theme = useContext(ThemeContext);
  const [focused, setFocused] = useState(false);
  const [field, setField] = useState("");
  const screen = useScreenClass();
  const isSm = /xs|sm/.test(screen);

  const getTownsDebounced = useCallback(debounce(getTowns, 750), []);

  usePreventSubmission(focused);

  useEffect(() => {
    setField(defaultValue);
  }, [defaultValue]);

  const handleInput = value => {
    setField(value);
    if (value.length > 2) getTownsDebounced(value);
    if (towns.some(item => item.name.toLowerCase() == value.toLowerCase()) && !isSm) {
      const found = towns.find(item => item.name.toLowerCase() == value.toLowerCase());
      setFocused(false);
      onChange(null, "city");
      onChange(found.id, "city_id");
    } else {
      if (!focused) setFocused(true);
      onChange(value, "city");
      onChange(null, "city_id");
    }
  };

  const handleTownSelect = ({ id, name }) => {
    setField(name);
    setFocused(false);
    onChange(null, "city");
    onChange(id, "city_id");
  };

  const handleAutoSelect = e => {
    if (e.keyCode == 13) {
      const found = towns[0];
      if (found) handleTownSelect(found);
    }
  };

  const isMenuVisible = focused && (isSm || field.length >= 3);

  return (
    <FilterWrapper>
      <Input
        disabled={loading}
        value={field}
        style={{ fontSize: "14px" }}
        onFocus={({ target }) => {
          target.select();
          setFocused(true);
        }}
        onBlur={() => {
          if (!isSm) setFocused(false);
        }}
        onChange={({ target: { value } }) => handleInput(value)}
        onKeyDown={handleAutoSelect}
        autoComplete="new-password"
        {...inputProps}
      />
      {processing && <ProcessingState size="24px" />}
      {isMenuVisible && (
        <div className="location-menu">
          {towns.map(town => (
            <React.Fragment key={town.name}>
              <LocationItem theme={theme} onMouseDown={e => e.preventDefault()} onClick={() => handleTownSelect(town)}>
                <Text fontWeight={500}>{highlightField(town.name, field)}</Text>
              </LocationItem>
            </React.Fragment>
          ))}
        </div>
      )}
    </FilterWrapper>
  );
};

const FilterWrapper = styled.div`
  width: 100%;
  position: relative;

  .location-menu {
    position: absolute;
    z-index: 9;
    border: 1px solid #e8eaef;
    background-color: #ffffff;
    border-radius: 5px;
    font-size: 14px;
    overflow: hidden;
    max-height: 236px;
    overflow-y: auto;
    height: fit-content;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 767px) {
      margin: 8px 0 0;
      width: 100%;
    }
  }
`;

export const ProcessingState = styled(Spinner)`
  ${({ bottom = 12 }) => `bottom: ${bottom}px;`}
  position: absolute;
  right: 0;
  transform: translate(-50%);
  z-index: 10;
`;

const LocationItem = styled.div`
  ${({ theme }) => `
    padding: 10px 12px;
    cursor: pointer;
    color: ${theme.black300};
    min-width: 250px;
    span {
        background-color: ${theme.primary100};
    }
    &.selected {
        font-weight: 500;
        color: ${theme.black500};
    }
    &:hover {
        background-color: ${theme.primary100};
    }
`}
`;

export default observer(TownInput);
