import React from "react";
import { observer } from "mobx-react";

import { Modal, Title, Description, Actions, Cancel, Submit } from "./styled";
import useStore from "../../../../contexts/store";
import { useTranslation } from "../../../../hooks/useTranslation";

const ExitModal = ({ job_id, app_id, close }) => {
  const {
    state: { processing },
    cancelApplication
  } = useStore("Application");
  const { t } = useTranslation();

  const handleCancel = () => {
    cancelApplication(job_id, app_id).then(() => {
      setTimeout(() => {
        close();
      }, 50);
    });
  };

  return (
    <Modal>
      <Title>{t("jobs.content.cancel_application.title")}</Title>
      <Description>{t("jobs.content.cancel_application.description")}</Description>
      <Actions>
        <Submit disabled={processing} onClick={handleCancel}>
          {t("jobs.content.cancel_application.submit_button")}
        </Submit>
        <Cancel onClick={close}> {t("jobs.content.cancel_application.cancel_button")}</Cancel>
      </Actions>
    </Modal>
  );
};

export default observer(ExitModal);
