import React from "react";
import { useTranslation } from "b2c/hooks/useTranslation";
import parse from "html-react-parser";

import { buildImageUrlS3, getCompanyName } from "b2c/contexts/branded";
import { Button, Card, Image, Text } from "../../../../components/Core";
import { nameImages } from "../../../../constants/images";

const EmptyContent = ({ from }) => {
  const { t } = useTranslation();

  return (
    <>
      <Text textAlign="center" margin="0 auto 16px" lineHeight="22px">
        {t("profile.personality.empty.description_1")}
      </Text>
      <Text textAlign="center" margin="0 auto 16px" lineHeight="22px">
        {t("profile.personality.empty.description_2")}
      </Text>
      <Card theme="grey200">
        <Card.Content>
          <Text textAlign="center" margin="auto" lineHeight="22px" fontWeight={500}>
            {parse(t("profile.personality.empty.description_3", { name: getCompanyName() }))}
          </Text>
        </Card.Content>
      </Card>
      <Image src={buildImageUrlS3(nameImages.personalityEmpty)} margin="36px auto" objectFit="contain" />

      <Button.Link
        to={{
          pathname: "/tests/4",
          state: { from }
        }}
        width={250}
        margin="0 auto 8px"
        color="primaryButtonLabelColor"
      >
        {t("profile.personality.empty.take_a_test")}
      </Button.Link>
    </>
  );
};

export default EmptyContent;
