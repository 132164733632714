import styled from "styled-components";
import { theme } from "../../../../contexts/theme";

export const Container = styled.div`
  background-color: #fff;
`;

export const Content = styled.div`
  padding: 20px 20px 30px;
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 20px;
  position: relative;

  &:after {
    content: "";
    height: 1px;
    width: calc(100% - 40px);
    left: 50%;
    transform: translateX(-50%);
    display: block;
    background-color: #c5c5c5;
    position: absolute;
    bottom: 0;
  }

  @media screen and (min-width: 1200px) {
    padding: 40px 20px 65px;
    margin-bottom: 60px;
  }

  @media screen and (min-width: 1440px) {
    padding: 40px 50px 65px;

    &:after {
      width: calc(100% - 100px);
    }
  }
`;

export const Title = styled.h2`
  font-weight: 600;
  font-size: 24px;
  line-height: 130%;
  color: #292929;
  margin-bottom: 24px;
  text-align: center;
  max-width: 320px;
  margin: 0 auto;

  span {
    color: #4690ff;
  }

  @media screen and (min-width: 768px) {
    font-size: 32px;
    max-width: 380px;
    margin: 0 auto 56px auto;
  }
`;

export const Steps = styled.div`
  @media screen and (min-width: 1200px) {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: start;
    gap: 40px;
  }
`;

export const StepsItem = styled.div`
  margin-bottom: 32px;

  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (min-width: 1200px) {
    margin-bottom: 0;
  }
`;

export const StepsTitle = styled.h3`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #292929;
  margin-bottom: 4px;
  text-align: center;

  @media screen and (min-width: 1200px) {
    font-size: 16px;
    margin-bottom: 0;
  }
`;

export const StepsText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #656565;
  text-align: center;
  display: block;
  margin-bottom: 24px;

  @media screen and (min-width: 1200px) {
    font-size: 16px;
  }
`;

export const StepsHead = styled.div`
  @media screen and (min-width: 1200px) {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
  }
`;

export const StepsCycle = styled.span`
  background: ${({ isLast }) => (isLast ? `${theme.primary}` : `${theme.primary50}`)};
  color: ${({ isLast }) => (isLast ? "#292929" : "#c19000")};
  width: 32px;
  height: 32px;
  border-radius: 32px;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
  margin: 0 auto 8px auto;

  @media screen and (min-width: 1200px) {
    margin: 0 8px 0 0;
  }
`;

export const ImageContent = styled.div`
  width: ${({ imageWidth }) => imageWidth};
  height: ${({ imageHeight }) => imageHeight};
  margin: 0 auto;

  @media screen and (min-width: 1200px) {
    width: ${({ imageWidthTablet }) => imageWidthTablet};
    height: ${({ imageHeightTablet }) => imageHeightTablet};
  }

  img {
    max-width: 100%;
  }
`;
