import React from "react";

import { Text } from "../Core";

const BannerContent = () => {
  return (
    <Text fontSize={13} margin="15px 20px">
      <b>Welcome to completely NEW Fuzu!</b> You’re now using the beta version of the new Fuzu. Share your feedback at{" "}
      <a href="mailto:support@fuzu.com?subject=My feedback about the NEW Fuzu" className="link underlined">
        support@fuzu.com
      </a>
      .
    </Text>
  );
};

export default BannerContent;
