import styled from "styled-components";

export const Container = styled.div`
  margin-bottom: 124px;
`;
export const Item = styled.div``;

export const Title = styled.h3`
  color: #292929;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 2px;
`;

export const Description = styled.p`
  color: #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-bottom: 0;
`;
