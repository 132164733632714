import styled from "styled-components";

export const Flex = styled.div`
  display: ${({ isHideMobile }) => (isHideMobile ? "none" : "flex")};
  margin-top: ${({ margin }) => margin || "40px"};
  align-items: center;
  gap: 4px;
  justify-content: center;
  padding: 24px 12px;

  img {
    max-width: 48px;
    max-height: 100%;
    width: 100%;
    display: block;
  }

  @media screen and (min-width: 768px) {
    justify-content: flex-start;
    padding: 0;
    display: ${({ isHideMobile }) => (isHideMobile ? "flex" : "none")};
    margin-top: auto;

    img {
      max-width: 80px;
    }
  }
`;

export const Text = styled.span`
  display: flex;
  align-items: center;
  color: #666;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
