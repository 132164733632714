import React, { useState, useMemo, useEffect, useContext } from "react";
import { observer } from "mobx-react";
import { useHistory, Link } from "react-router-dom";
import { ScreenClassContext, useScreenClass } from "react-grid-system";
import { GridAlt, RightArrowAlt } from "@styled-icons/boxicons-regular";

import ArticleIco from "images/b2c/emptyStates/emptyArticle.svg";
import { usePrev } from "utils/hooks";
import { useTranslation } from "b2c/hooks/useTranslation";
import Post from "../../Post";
import { Title, Text, Button, Card, Icon } from "../../Core";
import useStore from "../../../contexts/store";
import CarouselSection from "../../Section/CarouselSection";
import { useLanguageAndCountryUrl } from "../../../hooks";

const ArticlesSection = observer(
  ({
    title,
    filter,
    type,
    article_id,
    count_limit = 3,
    total_limit = 6,
    narrowCarousel,
    expandable = false,
    noHeader,
    noFooter,
    titleProps,
    isMinDefaultWidth,
    isHome,
    ...rest
  }) => {
    const isXs = useScreenClass() === "xs";
    const { articles, state, getArticles } = useStore("Articles");
    const { push } = useHistory();
    const [page, setPage] = useState(1);
    const loading = state[filter] ? state[filter].loading : true;
    const expanded = useState(false);
    const prevLoading = usePrev(loading);
    const loaded = useMemo(() => prevLoading, [loading, expanded]);
    const item_limit = expanded || (narrowCarousel && isXs) ? total_limit || count_limit : count_limit;
    const articleList = articles[filter] ? articles[filter].slice(0, item_limit) : [...new Array(item_limit).keys()];
    const screen = useContext(ScreenClassContext);
    const isSm = /xs|sm/.test(screen);
    const { t } = useTranslation();
    const languageAndLanguage = useLanguageAndCountryUrl();

    useEffect(() => {
      if (page > 1) {
        getArticles(filter, count_limit, page);
      }
      window.scrollTo(0, 0);
    }, [page]);

    useEffect(() => {
      if (articles[filter] && articles[filter].length === 0 && page > 1) {
        setPage(1);
        getArticles(filter, count_limit, 1);
      }
    }, [articles]);

    const onSelectMount = () => {
      if (!articles.length) {
        getArticles(filter, expandable ? total_limit : count_limit, 0, article_id, undefined, undefined, isHome);
      }
    };

    return (
      <CarouselSection
        expandable={expandable}
        expanded={expanded}
        onSectionMount={onSelectMount}
        columns={4}
        testScope="articles-section"
        narrowCarousel={narrowCarousel && isXs}
        slidePadding="15px 10px 0"
        isFullHeight
        isMinDefaultWidth={isMinDefaultWidth}
        slideMobileWidth="230px"
        style={{
          marginTop: isSm ? 0 : "25px"
        }}
        ShowMoreButton={
          <Card style={{ height: "100%" }} as={Link} to={`/forum/${filter}`}>
            <Card.Content
              style={{
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid #E1E1E1",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.08)",
                marginRight: "10px",
                borderRadius: "5px"
              }}
            >
              <Icon width="120px" as={ArticleIco} additionalCss="height: 110px" />
              <Text width="100%" textAlign="center" margin="10px 0" fontWeight={600} color="primaryButtonLabelColor">
                {t("forum.show_more_button")}
                <RightArrowAlt width={20} />
              </Text>
            </Card.Content>
          </Card>
        }
        ShowAll={
          <Button.Link
            to={`${languageAndLanguage}/forum/${filter === "similar_to_article" ? "" : filter}`}
            theme="transparent"
            style={{ color: "#296ACC", fontSize: "14px", fontWeight: 600 }}
          >
            <GridAlt width={17} style={{ stroke: "#296ACC", marginRight: "10px" }} />
            {t("global.see_all")}
          </Button.Link>
        }
        Header={
          !noHeader && (
            <Title scales level={3} className="title_sentence" fontWeight={600} color="#292929" {...titleProps}>
              {title}
            </Title>
          )
        }
        {...rest}
      >
        {articleList.map((item, index) => (
          <Post
            key={`article_${filter}_${index}${item.headline}`}
            {...item}
            type={type}
            onClick={() => push(item.path)}
            loading={loading}
            loaded={loaded}
          />
        ))}
      </CarouselSection>
    );
  }
);

export default ArticlesSection;
