import React, { useLayoutEffect } from "react";
import { Row, Col } from "react-grid-system";

import { pluralize } from "utils/helpers";
import Marks from "images/b2c/fuzuIcons/course_count.svg";
import { LoadingContent } from "../../../../components/Core/Spinner";
import { Card, Title, Text, Icon } from "../../../../components/Core";

const LearningActivity = ({ completed_count, getLearningActivity }) => {
  useLayoutEffect(() => {
    getLearningActivity();
  }, []);

  return (
    <Card shadow wideOnMobile style={{ marginBottom: 24 }}>
      <Card.Header padding="25px 20px 0">
        <Title level={2} fontWeight={500} scales>
          My learning activity
        </Title>
      </Card.Header>
      <Card.Content padding="25px 20px 0">
        <Row>
          <Col lg={6}>
            <Card theme="grey100" style={{ marginBottom: 20 }}>
              <LoadingContent loading={!completed_count && isNaN(completed_count)} size="64px" margin="24px 0">
                <Card.Header>
                  <Icon width={64} as={Marks} margin="0 24px 0 0 " />
                  <Text fontSize={28} fontWeight={700} color="primary300">
                    {pluralize(completed_count, "course")}
                  </Text>
                </Card.Header>
                <Card.Content padding="15px 20px">
                  <Text fontWeight={500}>Number of courses you have completed so far.</Text>
                </Card.Content>
              </LoadingContent>
            </Card>
          </Col>
        </Row>
      </Card.Content>
    </Card>
  );
};

export default LearningActivity;
