import React, { useEffect, useContext } from "react";
import { observer } from "mobx-react";
import { useParams, useHistory } from "react-router-dom";
import { ScreenClassContext } from "react-grid-system";

import { useTranslation } from "b2c/hooks/useTranslation";
import { useDocumentTitle, useQueryParams } from "utils/hooks";
import { capitalize } from "utils/helpers";
import { stringify } from "utils/qsUtil";
import Layout from "../../components/Layout";
import Navigation from "../../components/TabNavigation";
import { Title, Pagination } from "../../components/Core";
import TableGenerator from "../../components/TableGenerator";
import { withAuthorizationRedirect } from "../../components/WithAuth";
import BrandedContext, { getCompanyName } from "../../contexts/branded";
import SearchNavigation from "./components/SearchNavigation";
import useStore from "../../contexts/store";

const UniversalSearch = () => {
  const { handleSaving } = useStore("Saved");
  const {
    state: { loading },
    params: { term, page },
    results,
    setType,
    setTerm,
    setPage,
    initializeSearch
  } = useStore("UniversalSearch");

  const screen = useContext(ScreenClassContext);
  const isSm = ["xs", "sm", "md"].includes(screen);
  const { slug } = useContext(BrandedContext);
  const { tab: tabName = "jobs" } = useParams();
  const tab = tabName === "job" ? "jobs" : tabName;
  const queryParams = useQueryParams();

  const history = useHistory();
  const pathname = history.location.pathname.substring(0, history.location.pathname.indexOf("/", 1));

  const tableItems = results[tab] ? results[tab].items : [];
  const tableCount = results[tab] ? results[tab].total_count : 0;
  const { t } = useTranslation();
  const companyName = getCompanyName();

  useDocumentTitle(/saved/.test(pathname) ? `${companyName} - Saved` : `${companyName} - Search`);

  useEffect(() => {
    initializeSearch(queryParams, history);
  }, [history.location.pathname]);

  useEffect(() => {
    setPage(1);
  }, [tab]);

  const generatePath = path => {
    const qString = `?${stringify({ term: queryParams.term })}`;
    const qTerm = queryParams.term ? qString : "";

    return path ? `${pathname}/${path}${qTerm}` : `${pathname}${qTerm}`;
  };

  const handlePagination = pageNumber => {
    setType(tab);
    setPage(+pageNumber);
    window.scrollTo(0, 0);
  };

  const handleSearch = searchTerm => {
    setTerm(searchTerm);
  };

  const filteredCategories = slug === "ncpwd" ? ["articles", "courses"] : /saved/.test(pathname) ? ["companies"] : [];

  return (
    <>
      <Navigation
        NavRight={isSm ? <SearchNavigation term={term || ""} handleSearch={handleSearch} /> : null}
        innerLayoutSize="xl"
      >
        {Object.keys(results)
          .filter(key => !filteredCategories.includes(key))
          .map(item => (
            <Navigation.Tab
              key={item}
              selected={tab === item}
              count={results[item].total_count}
              to={generatePath(item)}
            >
              {capitalize(item)}
            </Navigation.Tab>
          ))}
      </Navigation>

      <Layout.Content size="xl" id="main" style={{ marginTop: 30, marginBottom: 30 }}>
        {term && (
          <Title margin="30px 0 25px 0" level={4}>
            {t("search.title_1")} <b>{term}</b>
          </Title>
        )}

        <TableGenerator
          loading={loading}
          tab={tab}
          items={tableItems}
          handleSaving={handleSaving}
          savedContent={/saved/.test(pathname)}
        />

        {results[tab] && results[tab].items.length > 0 && results[tab].total_count > 30 && (
          <Pagination
            page={+page}
            last={Math.ceil(tableCount / 30)}
            disabled={!results[tab]}
            padding="16px 0 24px"
            setPage={pageNumber => handlePagination(pageNumber)}
          />
        )}
      </Layout.Content>
    </>
  );
};

export default withAuthorizationRedirect(observer(UniversalSearch));
