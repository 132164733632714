import React from "react";

import SvgContent from "b2c/components/SvgContent";
import checkmarkIncorrect from "images/checkmark_incorrect.png";
import correctCircle from "images/correct_circle.png";
import incorrectCircle from "images/incorrect_circle.png";
import correct from "images/correct.png";
import checkmarkCorrect from "images/checkmark_correct.png";
import { useTranslation } from "b2c/hooks/useTranslation";
import { Card, Text, Image } from "../../../../components/Core";
import {
  CorrectCircleIcon,
  AnswerOption,
  AnswerOptionWrapper,
  ResultTitle,
  ResultTitleWrapper,
  ResultIcon,
  AnswerResultTitle,
  Questions,
  ResultIconWrapper,
  IncorrectMark
} from "./styled";
import EducationIco from "../../../../../../assets/images/b2c/profilePage/EducationIco.svg";

const CourseResult = ({ questions }) => {
  const { t } = useTranslation();

  const questionResults = {};
  const calculateQuestionResults = (answer_option, name, question) => {
    if (answer_option[name]) {
      questionResults[question.question.id][`${name}Id`] = answer_option.id;
    }
  };

  questions?.forEach(question => {
    questionResults[question.question.id] = {};

    question.answer_options.forEach(answer_option => {
      calculateQuestionResults(answer_option, "correct", question);
      calculateQuestionResults(answer_option, "selected", question);
    });
  });

  return (
    <Card wideOnMobile style={{ margin: "15px 0 0 0" }}>
      <Card.Content padding="24px">
        <ResultTitleWrapper>
          <SvgContent>
            <EducationIco />
          </SvgContent>
          <ResultTitle>{t("test_and_survey.result.your_results")}</ResultTitle>
        </ResultTitleWrapper>
        {questions.map(question => {
          const isCorrect =
            questionResults[question.question.id].selectedId === questionResults[question.question.id].correctId;
          return (
            <Questions key={question.question.id}>
              <ResultIconWrapper isCorrect={isCorrect}>
                <ResultIcon src={isCorrect ? checkmarkCorrect : checkmarkIncorrect} />
                <AnswerResultTitle>
                  {isCorrect ? t("test_and_survey.result.correct") : t("test_and_survey.result.incorrect")}
                </AnswerResultTitle>
              </ResultIconWrapper>
              <Text fontWeight={600} fontSize="18px" margin="20px 0">
                {question.question.description}
              </Text>
              <div>
                {question.answer_options.map(answer_option => {
                  const isSelected = questionResults[question.question.id].selectedId === answer_option.id;
                  const isSelectedAndIncorrect = !isCorrect && isSelected;
                  const isSelectedAndCorrect = isCorrect && isSelected;
                  let circleIcon = null;

                  if (isSelectedAndIncorrect) {
                    circleIcon = incorrectCircle;
                  } else if (isSelectedAndCorrect) {
                    circleIcon = correctCircle;
                  }

                  return (
                    <AnswerOptionWrapper
                      isSelectedAndIncorrect={isSelectedAndIncorrect}
                      isSelectedAndCorrect={isSelectedAndCorrect}
                    >
                      {questionResults[question.question.id].selectedId === answer_option.id ? (
                        <Image src={correct} width={24} />
                      ) : (
                        <IncorrectMark />
                      )}
                      <AnswerOption>{answer_option.description}</AnswerOption>
                      {circleIcon && <CorrectCircleIcon src={circleIcon} width={24} />}
                    </AnswerOptionWrapper>
                  );
                })}
              </div>
            </Questions>
          );
        })}
      </Card.Content>
    </Card>
  );
};

export default CourseResult;
