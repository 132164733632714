import React, { useContext } from "react";
import { Row, Col, ScreenClassContext, Visible, Hidden } from "react-grid-system";
import parse from "html-react-parser";
import styled from "styled-components";

import { useTranslation } from "b2c/hooks/useTranslation";
import circleBG_dark from "images/b2c/circleBG_dark.png";
import Layout from "b2c/components/Layout";
import { Title, Text, Button, Label } from "b2c/components/Core";

const CareerCoachingHero = ({ scrollHandler }) => {
  const screen = useContext(ScreenClassContext);
  const isSm = ["xs", "sm"].includes(screen);
  const { t } = useTranslation();

  return (
    <>
      <Layout.Section style={{ background: `center bottom repeat url(${circleBG_dark})` }} padding="30px 0 50px">
        <Layout.Content tag="div">
          <Row>
            <Col md={6} sm={12}>
              <Label.PremiumAlternative margin="0 0 20px" title="Premium" />
              <Visible xs sm>
                <StyledIframe allow="fullscreen" width="100%" src="https://www.youtube.com/embed/ZXKMwYqIDxM" />
              </Visible>

              <Title
                role="heading"
                level={2}
                fontSize={isSm ? 18 : 24}
                fontWeight={600}
                lineHeight={1.2}
                color="white"
                margin="0 0 20px"
              >
                {t("career_coaching.hero.title")}
              </Title>

              <Text fontSize={isSm ? 14 : 18} theme="black400" margin="0 0 42px" color="white">
                {parse(t("career_coaching.hero.description"))}
              </Text>
              <Button theme="additional200" width={isSm ? "100%" : "330px"} onClick={scrollHandler}>
                {t("career_coaching.hero.button")}
              </Button>
            </Col>
            <Hidden xs sm>
              <Col md={6} sm={12} style={{ display: "flex" }}>
                <StyledIframe allow="fullscreen" width="100%" src="https://www.youtube.com/embed/ZXKMwYqIDxM" />
              </Col>
            </Hidden>
          </Row>
        </Layout.Content>
      </Layout.Section>
    </>
  );
};

const StyledIframe = styled.iframe`
  border: none;
  border-radius: 5px;
  display: flex;
  height: 300px;
  margin: auto;

  @media screen and (max-width: 767px) {
    margin: auto auto 24px;
    height: auto;
  }
`;

export default CareerCoachingHero;
