import React, { useState, useEffect } from "react";
import { Heart } from "@styled-icons/boxicons-regular";
import { Heart as SolidHeart } from "@styled-icons/boxicons-solid";
import { useHistory } from "react-router-dom";

import Button from "../Core/Button";
import useStore from "../../contexts/store";
import { useModal } from "../../../utils/hooks";
import LoginModalContent from "../ModalContents/SignUpModalContent";

const SaveButton = ({ id, saved = false, handleSaving, title, titleSaved, isWhitelabelledOrCustom, ...rest }) => {
  const [isSaved, setSaved] = useState(saved);
  const { user } = useStore("User");
  const openLoginModal = useModal(<LoginModalContent type="auto" />, null, () => {}, 0);
  const { push } = useHistory();
  const { global_brand } = useStore("initialState");
  const isBarona = global_brand?.name?.includes("Barona");

  useEffect(() => {
    setSaved(saved);
  }, [saved, id]);

  const saveItem = e => {
    e.preventDefault();
    e.stopPropagation();
    setSaved(!isSaved);
    handleSaving(isSaved);
  };

  const openModal = e => {
    e.preventDefault();
    e.stopPropagation();

    if (isBarona) {
      push("/login");
    } else if (isWhitelabelledOrCustom) {
      push("/onboarding");
    } else {
      openLoginModal();
    }
  };

  return (
    <>
      {user?.id ? (
        <Button.Iconed
          className="save-button"
          theme={!isSaved ? "grey100" : "accent200"}
          type="button"
          onClick={saveItem}
          title={!isSaved ? title : titleSaved}
          {...rest}
        >
          {!isSaved ? <Heart width={24} /> : <SolidHeart color="#FF2727" width={24} />}
        </Button.Iconed>
      ) : (
        <Button.Iconed
          className="save-button"
          theme="grey100"
          type="button"
          title={!isSaved ? title : titleSaved}
          onClick={openModal}
          {...rest}
        >
          <Heart width={24} />
        </Button.Iconed>
      )}
    </>
  );
};

export default SaveButton;
