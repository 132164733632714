import styled from "styled-components";
import { Link } from "react-router-dom";

export const Login = styled(Link)`
  color: #408bfc;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  cursor: pointer;
  text-decoration: none;
`;

export const Flex = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 24px;
  margin-top: 16px;
`;

export const Label = styled.span`
  color: #666;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`;

export const LinkClose = styled.a`
  cursor: pointer;
  height: 32px;
  width: 32px;
  background: transparent;
  position: absolute;
  right: 100px;
  top: 20;
  display: ${({ isHide }) => (isHide ? "none" : "block")};

  svg {
    width: 24px;
    height: 24px;
    fill: #a4a4a4;
  }

  @media screen and (min-width: 640px) {
    display: block;
  }

  @media screen and (min-width: 768px) {
    top: 40px;
  }
`;
