import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "b2c/hooks/useTranslation";

import JobInput from "../../../JobInput";

const JobInterest = () => {
  const { t } = useTranslation();

  return (
    <JobInput
      inputProps={{
        label: t("onboarding.interested_job.label"),
        placeholder: t("onboarding.interested_job.placeholder"),
        style: { borderColor: "#B9B9B9" }
      }}
    />
  );
};

export default observer(JobInterest);
