import React from "react";
import { observer } from "mobx-react";
import { RightArrowAlt } from "@styled-icons/boxicons-regular";

import { getCompanyName } from "b2c/contexts/branded";
import { useTranslation } from "b2c/hooks/useTranslation";
import { Button, Text } from "../../../../components/Core";
import { PromoBuildProfileContainer, TextHideMobile, Strong } from "./styled";
import useStore from "../../../../contexts/store";

const PromoBuildProfile = () => {
  const { user } = useStore("User");

  if (user?.profile_completed) {
    return null;
  }

  const { t } = useTranslation();

  return (
    <PromoBuildProfileContainer>
      <Text fontSize="16px" lineHeight="140%" color="primaryButtonLabelColor">
        <Strong>{t("promo.want_better.title", { name: getCompanyName() })}</Strong>
        <TextHideMobile> {t("promo.want_better.description")}</TextHideMobile>
      </Text>

      <Button.Link
        fontSize="16px"
        lineHeight="140%"
        color="secondary200"
        to="/wizard"
        style={{ textDecoration: "underline" }}
        display="inline-block"
        theme="transparent"
        height="22px"
        padding="5px 6px"
      >
        {t("promo.want_better.button")}
        <RightArrowAlt width="20px" />
      </Button.Link>
    </PromoBuildProfileContainer>
  );
};

export default observer(PromoBuildProfile);
