import React from "react";
import { Row, Col } from "react-grid-system";
import parse from "html-react-parser";

import { getCompanyName } from "b2c/contexts/branded";
import { useTranslation } from "b2c/hooks/useTranslation";
import { Title, Card, Text, Button } from "../../../../components/Core";
import FileUploader from "../../../../components/FileUploader";
import { useDocumentTitle } from "../../../../../utils/hooks";

const UploadStep = ({ processing, uploaded_cv, uploadCv, onComplete }) => {
  const handleUpload = file => {
    uploadCv(file, false).then(onComplete);
  };
  useDocumentTitle(getCompanyName());

  const { t } = useTranslation();

  return (
    <Card shadow wideOnMobile>
      <Card.Header padding="16px">
        <Title scales fontWeight={600} margin="auto" textAlign="center">
          {t("wizard.upload.title")}
        </Title>
      </Card.Header>
      <Card.Content padding="16px">
        <Text margin="0 0 24px" fontSize={16}>
          {t("wizard.upload.description", { name: getCompanyName() })}
        </Text>
        <Row justify="center">
          <Col lg={6}>
            <FileUploader.DragAndDrop
              name={uploaded_cv?.original_filename}
              url={uploaded_cv?.url}
              accept=".pdf, .doc, .docx"
              buttonText={uploaded_cv?.url ? t("wizard.upload.upload_cv_button") : t("wizard.upload.choose_file")}
              onUpload={handleUpload}
            >
              {t("wizard.upload.placeholder")}
            </FileUploader.DragAndDrop>
          </Col>
        </Row>
        <Text margin="24px 0 0" fontSize={16}>
          {parse(t("wizard.upload.skip_description_1"))}{" "}
          <Text
            as="span"
            className="link underlined"
            fontSize={16}
            color="secondary300"
            fontWeight={600}
            onClick={onComplete}
          >
            {t("wizard.upload.skip_button")}
          </Text>{" "}
          {t("wizard.upload.skip_description_2")}
        </Text>
      </Card.Content>

      <Card.Footer display="block" separator padding="16px">
        <Row justify="end">
          <Col lg={3}>
            <Button width="100%" processing={processing} onClick={onComplete} color="primaryButtonLabelColor">
              {t("wizard.next_step_button")}
            </Button>
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
};

export default UploadStep;
