import React from "react";
import { Link } from "react-router-dom";

import { getTimeSpanString, capitalize } from "utils/helpers";
import ApplicationIcon from "images/b2c/icons/applicationIcon.svg";
import CourseIcon from "images/b2c/icons/courseIcon.svg";
import withLoading from "../../withLoading";
import { Card, Text, Title, ProgressBar } from "../../Core";
import { useTranslation } from "../../../hooks/useTranslation";

const entryType = {
  application: "application",
  course: "course"
};

const UnfinishedItem = ({
  id,
  entity_type,
  position_id,
  title = "",
  name = "",
  category = "",
  company_name = "",
  progress,
  campaign_end_date = ""
}) => {
  const since = campaign_end_date
    ? getTimeSpanString(Date.now() - new Date(campaign_end_date), "Closing ", false)
    : "Not specified";
  const relevancy = campaign_end_date ? (new Date(campaign_end_date).getTime() - Date.now()) / (1000 * 3600 * 24) : 0;
  const { t } = useTranslation();

  const itmIcon = {
    application: <ApplicationIcon style={{ display: "block" }} />,
    course: <CourseIcon style={{ display: "block" }} />
  };

  return (
    <Card
      as={Link}
      to={entity_type === entryType.application ? `/jobs/${position_id}/application` : `/learn/${id}`}
      shadow
      theme="white"
      Button={itmIcon[entity_type]}
      clickable
      style={{ backgroundColor: "#fff", boxShadow: "none", height: "100%" }}
    >
      <Card.Content padding="10px 16px">
        <Text loaded fontWeight={400} margin="0 0 2px 0" truncated width="90%">
          {category || company_name || capitalize(entity_type)}
        </Text>
        <Title loaded level={6} height={38} fontWeight={600} truncatedLine={2} margin="4px 0 0" width="90%">
          {title || name}
        </Title>
        {entity_type === entryType.course && (
          <>
            <ProgressBar
              currentValue={progress.completed_count}
              maxValue={progress.modules_count}
              style={{ margin: "7px 0 6px" }}
            />
            <Text margin="0">
              <b>
                {progress.completed_count}/{progress.modules_count}
              </b>{" "}
              {t("learn.modules_completed")}
            </Text>
          </>
        )}
      </Card.Content>

      {entity_type === entryType.application && (
        <Card.Footer separator="small">
          <Text loaded fontSize={13} fontWeight={500} color={relevancy > 7 ? "success200" : "accent300"}>
            {since}
            {relevancy > 1 ? ` ${t("learn.apply")}` : ""}
          </Text>
        </Card.Footer>
      )}
    </Card>
  );
};

UnfinishedItem.Placeholder = () => (
  <Card theme="white" shadow>
    <Card.Content>
      <Text loading fontWeight={400} margin="0" />
      <Title loading level={6} height={50} fontWeight={500} margin="4px 0 18px" />
    </Card.Content>

    <Card.Footer separator="small">
      <Text loading fontWeight={400} margin="0" />
    </Card.Footer>
  </Card>
);

export default withLoading(UnfinishedItem, UnfinishedItem.Placeholder);
