import React, { useMemo } from "react";

import { useTranslation } from "b2c/hooks/useTranslation";
import { jobRow, courseRow, articleRow, companyRow, inviteRow } from "../RowTypes";
import ResultTable from "../ResultTable";
import { debitRow, creditRow } from "../PaymentRows";
import { languageFormat } from "../../constants/main";

const TableGenerator = ({ tab, savedContent = false, items = [], handleSaving }) => {
  const { t, language } = useTranslation();
  const languageFormatType = languageFormat[language];

  const headings = (() => {
    switch (tab) {
      default:
        return [];
      case "jobs":
        return [
          t("search.employer"),
          t("search.title"),
          t("search.location"),
          t("search.experience"),
          t("search.type"),
          t("search.views"),
          t("search.published")
        ].concat(savedContent ? [] : "");
      case "courses":
        return [
          t("search.course"),
          t("search.category"),
          t("search.modules"),
          t("search.participants"),
          t("search.likes")
        ].concat(savedContent ? [] : "");
      case "articles":
        return [
          t("search.title"),
          t("search.published"),
          t("search.author"),
          t("search.views"),
          t("search.likes"),
          t("search.comments")
        ].concat(savedContent ? [] : "");
      case "companies":
        return [t("search.name"), t("search.job_count")].concat(savedContent ? [] : "");
      case "debit":
        return [t("search.activity"), t("search.received_points"), t("search.date")];
      case "credit":
        return [t("search.purchases"), t("search.used_money"), t("search.used_points"), t("search.date")];
      case "invites":
        return [t("search.friends_email"), t("search.received_fuzu_points"), t("search.date")];
    }
  })();

  const rows = useMemo(() => {
    switch (tab) {
      default:
        return [];
      case "jobs":
        return items.map(job => jobRow(job, handleSaving, savedContent, t));
      case "courses":
        return items.map(course => courseRow(course, handleSaving, savedContent, t));
      case "articles":
        return items.map(article => articleRow(article, handleSaving, savedContent, t));
      case "companies":
        return items.map(company => companyRow(company, t));
      case "invites":
        return items.map(data => inviteRow({ ...data, languageFormatType }));
      case "debit":
        return items.map(pointsData => debitRow({ ...pointsData, languageFormatType }));
      case "credit":
        return items.map(pointsData => creditRow({ ...pointsData, languageFormatType }));
    }
  }, [headings]);

  return <ResultTable headings={headings} rows={rows} />;
};

export default TableGenerator;
