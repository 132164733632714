import React, { useState, useEffect, useRef, useCallback } from "react";
import { observer } from "mobx-react";
import { useLocation } from "react-router-dom";
import { Row, Col } from "react-grid-system";

import SvgContent from "b2c/components/SvgContent";
import { capitalize } from "utils/helpers";
import EducationIco from "images/b2c/profilePage/EducationIco.svg";
import { useTranslation } from "b2c/hooks/useTranslation";
import useStore from "../../contexts/store";
import { Text, Input, Textarea, Checkbox, Dropdown } from "../Core";
import SubPageSection from "../SubPageSection";
import { languageFormat } from "../../constants/main";

const Education = ({ itemId, ...rest }) => {
  const {
    Profile: {
      state: { loading, processing },
      education_options: { education_levels = [] } = {},
      education_details: {
        school_name,
        degree_name,
        description,
        start_date,
        end_date,
        grade,
        education_level_id,
        current_education,
        waits_user_review
      } = {},
      validation_errors,
      initializeEducation,
      addEducation,
      updateEducation
    }
  } = useStore("User");
  const { state } = useLocation();
  const [startDate, setStartDate] = useState({});
  const [endDate, setEndDate] = useState({});
  const [isCurrent, setCurrent] = useState(false);
  const [eduSummary, setSummary] = useState("");
  const [eduGrade, setGrade] = useState("");
  const { t, language } = useTranslation();
  const languageFormatType = languageFormat[language];

  const gradeRef = useRef();
  const summaryRef = useRef();

  const item_id = state ? state.item_id : "";

  useEffect(() => {
    initializeEducation(itemId || item_id);
  }, [itemId, item_id]);

  useEffect(() => {
    setStartDate(formatDate(start_date));
  }, [start_date]);

  useEffect(() => {
    setEndDate(formatDate(end_date));
  }, [end_date]);

  useEffect(() => {
    setCurrent(current_education);
  }, [current_education]);

  useEffect(() => {
    setSummary(description);
  }, [description]);

  useEffect(() => {
    setGrade(grade);
  }, [grade]);

  const formatDate = date =>
    date
      ? {
          year: +date.substring(0, date.indexOf("-", 0)),
          month: +date.substring(date.indexOf("-", 0) + 1, date.indexOf("-", 5)),
          day: +date.substring(date.length, date.indexOf("-", 7) + 1)
        }
      : {};

  const formatError = useCallback(
    key => {
      return validation_errors[key]
        ? capitalize(validation_errors[key].join(", "))
        : waits_user_review && requiredFields.includes(key) && !initialFields[key]
          ? "Field is missing"
          : null;
    },
    [waits_user_review, validation_errors]
  );

  const dynamicFields = {
    start: startDate,
    end: endDate,
    current_education: isCurrent,
    description: eduSummary,
    grade: eduGrade
  };

  const initialFields = {
    education_level_id,
    school_name,
    degree_name
  };

  const requiredFields = ["school_name", "degree_name", "education_level_id"];

  // const disableSubmit = !startDate.year || (!endDate.year && !isCurrent);

  const months = Array.from({ length: 12 }, (_, i) => ({
    name: new Date(null, i + 1, null).toLocaleString(languageFormatType, { month: "long" }),
    id: i + 1
  }));

  const getMonth = value => (months.some(m => m.id == value) ? months.find(m => m.id == value).name : "");

  const getDefaultValue = (id, list) =>
    list && list.some(item => item.id == id) ? list.find(item => item.id == id).name : "";

  useEffect(() => {
    if (window.scrollY) {
      window.scroll(0, 0);
    }
  }, []);

  return (
    <SubPageSection
      title={t("wizard.education.sub-title")}
      sectionIcon={
        <SvgContent>
          <EducationIco />
        </SvgContent>
      }
      loading={loading}
      processing={processing}
      submitAction={item_id || itemId ? formData => updateEducation(item_id || itemId, formData) : addEducation}
      initialFields={initialFields}
      dynamicFields={dynamicFields}
      validation={validation_errors}
      confirmation={false}
      requiredFields={requiredFields}
      {...rest}
    >
      {handleChange => (
        <>
          <Row>
            <Col sm={4}>
              <Input
                label={t("wizard.education.school_name_label")}
                placeholder={t("wizard.education.school_name_placeholder")}
                name="school_name"
                fieldRequired
                defaultValue={school_name}
                onChange={handleChange}
                errorMessage={formatError("school_name")}
              />
            </Col>
            <Col sm={4}>
              <Input
                label={t("wizard.education.degree_name_label")}
                placeholder={t("wizard.education.degree_name_placeholder")}
                name="degree_name"
                fieldRequired
                defaultValue={degree_name}
                onChange={handleChange}
                errorMessage={formatError("degree_name")}
              />
            </Col>
            <Col sm={4}>
              <Dropdown
                label={`${t("wizard.education.education_level_label")}*`}
                placeholder={t("wizard.education.education_level_placeholder")}
                id="education_level_id"
                name="education_level_id"
                triggerDataCy="education-level"
                defaultValue={getDefaultValue(education_level_id, education_levels)}
                errorMessage={formatError("education_level_id")}
              >
                <Dropdown.Menu disableSorting>
                  {education_levels.map(({ name, id }) => (
                    <Dropdown.Item key={id} onClick={() => handleChange(null, id, "education_level_id")}>
                      {name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <Input
                label={t("wizard.education.grade_label")}
                placeholder={t("wizard.education.grade_placeholder")}
                name="grade"
                ref={gradeRef}
                value={eduGrade}
                onChange={e => setGrade(e.target.value)}
                errorMessage={formatError("grade")}
              />
            </Col>
            <Col sm={4}>
              <Row gutterWidth={10} align="end">
                <Col xs={6}>
                  <Dropdown
                    label={t("wizard.education.start_month_label")}
                    placeholder={t("wizard.education.start_month_placeholder")}
                    id="education_start_month"
                    name="education_start_month"
                    triggerDataCy="start-education-month"
                    defaultValue={getMonth(startDate.month)}
                  >
                    <Dropdown.Menu disableSorting triggerDataCy="start-education-month-menu">
                      {months.map(({ name, id }) => (
                        <Dropdown.Item
                          key={id}
                          onClick={() =>
                            setStartDate({
                              ...startDate,
                              month: id
                            })
                          }
                        >
                          {name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
                <Col xs={6}>
                  <Dropdown
                    placeholder={t("wizard.education.start_year_placeholder")}
                    id="education_start_year"
                    name="education_start_year"
                    triggerDataCy="start-education-year"
                    defaultValue={startDate.year}
                  >
                    <Dropdown.Menu disableSorting disableSearch triggerDataCy="start-education-year-menu">
                      {[...Array(92)]
                        .map((_, i) => new Date().getFullYear() - i)
                        .map(year => (
                          <Dropdown.Item
                            key={year}
                            onClick={() =>
                              setStartDate({
                                ...startDate,
                                year
                              })
                            }
                          >
                            {year}
                          </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </Row>
              {validation_errors.start_date && (
                <Text color="accent300" fontSize="12px" margin="4px 0 0" loaded={!!validation_errors.start_date}>
                  {formatError("start_date")}
                </Text>
              )}
            </Col>
            <Col sm={4}>
              <Row gutterWidth={10} align="end">
                <Col xs={6}>
                  <Dropdown
                    label={t("wizard.education.end_month_label")}
                    placeholder={t("wizard.education.end_month_placeholder")}
                    id="education_end_month"
                    name="education_end_month"
                    triggerDataCy="end-education-month"
                    disabled={isCurrent}
                    defaultValue={getMonth(endDate.month)}
                  >
                    <Dropdown.Menu disableSorting triggerDataCy="end-education-month-menu">
                      {months.map(({ name, id }) => (
                        <Dropdown.Item
                          key={id}
                          onClick={() =>
                            setEndDate({
                              ...endDate,
                              month: id
                            })
                          }
                        >
                          {name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
                <Col xs={6}>
                  <Dropdown
                    placeholder={t("wizard.education.end_year_placeholder")}
                    id="education_end_year"
                    name="education_end_year"
                    triggerDataCy="end-education-year"
                    disabled={isCurrent}
                    defaultValue={endDate.year}
                  >
                    <Dropdown.Menu disableSorting disableSearch triggerDataCy="end-education-year-menu">
                      {[...Array(92)]
                        .map((_, i) => new Date().getFullYear() - i)
                        .map(year => (
                          <Dropdown.Item
                            key={year}
                            onClick={() =>
                              setEndDate({
                                ...endDate,
                                year
                              })
                            }
                          >
                            {year}
                          </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
                <Checkbox
                  name="current_education"
                  margin="0 5px 15px"
                  label={<Text>{t("wizard.education.current_education_label")}</Text>}
                  checked={isCurrent}
                  onChange={() => setCurrent(!isCurrent)}
                  errorMessage={formatError("current_education")}
                />
              </Row>
              {validation_errors.start_date && (
                <Text color="accent300" fontSize="12px" margin="4px 0 0" loaded={!!validation_errors.end_date}>
                  {formatError("end_date")}
                </Text>
              )}
            </Col>
          </Row>
          <Textarea
            label={
              <>
                <Text fontSize="inherit" fontWeight="500">
                  {t("wizard.education.summary_label")}
                </Text>
                <Text fontSize="12px">{t("wizard.education.summary_description")}</Text>
              </>
            }
            name="description"
            ref={summaryRef}
            placeholder={t("wizard.education.summary_placeholder")}
            value={eduSummary}
            onChange={e => setSummary(e.target.value)}
            errorMessage={formatError("description")}
          />
        </>
      )}
    </SubPageSection>
  );
};

export default observer(Education);
