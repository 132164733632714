const ccErrors = [];

ccErrors[0] = "Unknown card type";
ccErrors[1] = "No card number provided";
ccErrors[2] = "Invalid format";
ccErrors[3] = "Credit card number is invalid";
ccErrors[4] = "Invalid number of digits";
ccErrors[5] = "Warning! This credit card number is associated with a scam attempt";

const validateCard = (cardnumber, cardname) => {
  const cards = [];

  cards[0] = { name: "Visa", length: "13,16", prefixes: "4", checkdigit: true };
  cards[1] = { name: "MasterCard", length: "16", prefixes: "51,52,53,54,55", checkdigit: true };
  cards[2] = { name: "AmEx", length: "15", prefixes: "34,37", checkdigit: true };
  cards[3] = { name: "JCB", length: "16", prefixes: "35", checkdigit: true };

  // Establish card type
  let cardType = -1;
  for (let i = 0; i < cards.length; i++) {
    // See if it is this card (ignoring the case of the string)
    if (cardname.toLowerCase() == cards[i].name.toLowerCase()) {
      cardType = i;
      break;
    }
  }

  // If card type not found, report an error
  if (cardType == -1) {
    return { valid: false, error: ccErrors[0] };
  }

  // Ensure that the user has provided a credit card number
  if (cardnumber.length === 0) {
    return { valid: false, error: ccErrors[1] };
  }

  // Now remove any spaces from the credit card number
  cardnumber = cardnumber.replace(/\s/g, "");

  // Check that the number is numeric
  const cardNo = cardnumber;
  const cardexp = /^[0-9]{13,19}$/;
  if (!cardexp.exec(cardNo)) {
    return { valid: false, error: ccErrors[2] };
  }

  // Now check the modulus 10 check digit - if required
  if (cards[cardType].checkdigit) {
    let checksum = 0; // running checksum total
    let j = 1; // takes value of 1 or 2

    // Process each digit one by one starting at the right
    let calc;
    for (let i = cardNo.length - 1; i >= 0; i--) {
      // Extract the next digit and multiply by 1 or 2 on alternative digits.
      calc = Number(cardNo.charAt(i)) * j;

      // If the result is in two digits add 1 to the checksum total
      if (calc > 9) {
        checksum += 1;
        calc -= 10;
      }

      // Add the units element to the checksum total
      checksum += calc;

      // Switch the value of j
      if (j == 1) {
        j = 2;
      } else {
        j = 1;
      }
    }

    // All done - if checksum is divisible by 10, it is a valid modulus 10.
    // If not, report an error.
    if (checksum % 10 != 0) {
      return { valid: false, error: ccErrors[3] };
    }
  }

  // Check it's not a spam number
  if (cardNo === "5490997771092064") {
    return { valid: false, error: ccErrors[5] };
  }

  // The following are the card-specific checks we undertake.
  let LengthValid = false;
  let PrefixValid = false;

  // We use these for holding the valid lengths and prefixes of a card type
  let prefix = [];
  let lengths = [];

  // Load an array with the valid prefixes for this card
  prefix = cards[cardType].prefixes.split(",");

  // Now see if any of them match what we have in the card number
  for (let i = 0; i < prefix.length; i++) {
    const exp = new RegExp(`^${prefix[i]}`);
    if (exp.test(cardNo)) PrefixValid = true;
  }

  // If it isn't a valid prefix there's no point at looking at the length
  if (!PrefixValid) {
    return { valid: false, error: ccErrors[3] };
  }

  // See if the length is valid for this card
  lengths = cards[cardType].length.split(",");
  for (let j = 0; j < lengths.length; j++) {
    if (cardNo.length === lengths[j]) LengthValid = true;
  }

  // See if all is OK by seeing if the length was valid. We only check the length if all else was
  // hunky dory.
  if (!LengthValid) {
    return { valid: false, error: ccErrors[4] };
  }

  // The credit card is in the required format.
  return { valid: true, error: "" };
};

export const formatCardNumber = value => {
  let val = value;
  let newval = "";
  val = val.replace(/\D|\s/g, "").slice(0, 19);
  for (let i = 0; i < val.length; i++) {
    if (i % 4 == 0 && i > 0) newval = newval.concat("-");
    newval = newval.concat(val[i]);
  }
  return newval;
};

export default validateCard;
