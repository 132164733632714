import React, { useContext } from "react";
import { Row, Col, ScreenClassContext, Visible, Hidden } from "react-grid-system";

import { useTranslation } from "b2c/hooks/useTranslation";
import whoIs from "images/b2c/careerCoaching/whoIs.png";
import Layout from "b2c/components/Layout";
import { Title, Text, Button, Image, Divider } from "b2c/components/Core";

const CareerCoachingHowItWorks = ({ scrollHandler }) => {
  const screen = useContext(ScreenClassContext);
  const isSm = ["xs", "sm"].includes(screen);
  const { t } = useTranslation();

  return (
    <>
      <Layout.Section backgroundColor="white" padding="30px 0 50px">
        <Layout.Content tag="div">
          <Row style={{ padding: isSm ? "0 0 16px" : "32px 0" }}>
            <Col md={7}>
              <Image src={whoIs} width={550} height={isSm ? 240 : 420} display="block" margin="20px auto" />
            </Col>
            <Col md={5} style={{ display: "flex", flexDirection: "column" }}>
              <Title loaded level="2" fontWeight={600} margin="0 0 25px" style={{ fontSize: isSm ? 20 : 36 }}>
                {t("career_coaching.questions.title")}
              </Title>
              {values.map(({ id, title, description }) => (
                <React.Fragment key={`value-${id}`}>
                  <Text margin="8px 0" fontSize={16} fontWeight={600}>
                    {t(title)}
                  </Text>
                  <Text margin="8px 0">{t(description)}</Text>
                </React.Fragment>
              ))}
            </Col>
          </Row>

          <Divider style={{ height: "1px" }} margin="40px auto" width={isSm ? "100%" : "80%"} />

          <Title
            loaded
            level="2"
            fontWeight={600}
            margin="0 auto 25px"
            textAlign="center"
            style={{ fontSize: isSm ? 20 : 36 }}
          >
            {t("career_coaching.how_it_works.title")}
          </Title>
          <Row>
            {instructions.map(({ id, title, description }) => (
              <Col md={4} key={id}>
                <Hidden xs sm>
                  <Counter count={id} size={24} />
                </Hidden>
                <Text
                  fontSize={20}
                  fontWeight={700}
                  margin="12px 0"
                  width="100%"
                  style={{
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  <Visible xs sm>
                    <Counter as="span" count={id} size={16} margin="0 8px 0 0" />
                  </Visible>
                  {t(title)}
                </Text>
                <Text fontSize={16} margin="0 0 20px">
                  {t(description)}
                </Text>
              </Col>
            ))}
          </Row>
          <Button
            theme="additional200"
            width={isSm ? "100%" : "330px"}
            margin="24px auto 0"
            style={{
              display: "flex"
            }}
            onClick={scrollHandler}
          >
            {t("career_coaching.view_coaching_packages")}
          </Button>
        </Layout.Content>
      </Layout.Section>
    </>
  );
};

const Counter = ({ count, size, ...rest }) => (
  <Text
    fontSize={size}
    fontWeight={700}
    color="additional200"
    backgroundColor="additional100"
    lineHeight={`${(size - 2) / 2}px`}
    style={{ padding: size, borderRadius: "100%" }}
    {...rest}
  >
    {count}
  </Text>
);

const values = [
  {
    id: 1,
    title: "career_coaching.questions.list.title_1",
    description: "career_coaching.questions.list.description_1"
  },
  {
    id: 2,
    title: "career_coaching.questions.list.title_2",
    description: "career_coaching.questions.list.description_2"
  },
  {
    id: 3,
    title: "career_coaching.questions.list.title_3",
    description: "career_coaching.questions.list.description_3"
  },
  {
    id: 4,
    title: "career_coaching.questions.list.title_4",
    description: "career_coaching.questions.list.description_4"
  }
];

const instructions = [
  {
    id: 1,
    title: "career_coaching.how_it_works.list.title_1",
    description: "career_coaching.how_it_works.list.description_1"
  },
  {
    id: 2,
    title: "career_coaching.how_it_works.list.title_2",
    description: "career_coaching.how_it_works.list.description_2"
  },
  {
    id: 3,
    title: "career_coaching.how_it_works.list.title_3",
    description: "career_coaching.how_it_works.list.description_3"
  }
];

export default CareerCoachingHowItWorks;
