import React from "react";

import fuzuLogo from "images/new_fuzu_logo_yellow.png";
import { withTrackedRoute } from "../../hocs";
import Layout from "../../components/Layout";
import ProductCheckout from "./components/ProductCheckout";
import { withAuthorizationRedirect } from "../../components/WithAuth";
import { StyledHeader } from "./styled";
import BaronaLogo from "../../components/BaronaLogo";

const PaymentScreen = ({ ...routeProps }) => {
  return (
    <>
      <StyledHeader styled={{ backgroundColor: "#212121" }}>
        <BaronaLogo>
          <img className="brand-logo" src={fuzuLogo} alt="logo" />
        </BaronaLogo>
      </StyledHeader>

      <Layout.Content size="sm" tag="div" style={{ padding: "24px 0" }}>
        <ProductCheckout {...routeProps} />
      </Layout.Content>
    </>
  );
};

export default withTrackedRoute(withAuthorizationRedirect(PaymentScreen));
