import styled from "styled-components";

export const Wrapper = styled.section`
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  padding-top: 0;
  height: 630px;
  align-items: center;
`;
