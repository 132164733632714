import React, { useState, useMemo, useEffect, useContext, useRef } from "react";
import { observer } from "mobx-react";
import { Hidden } from "react-grid-system";
import { KeyboardArrowLeft } from "@styled-icons/material/KeyboardArrowLeft";

import fuzu_logo from "images/new_fuzu_logo_black.png";
import ncpwd_logo from "images/b2c/logo_navbar.jpg";
import ncpwd_image from "images/b2c/logo_large.png";
import demo_image from "images/b2c/demo.png";
import assistantImage from "images/b2c/onboarding/onboarding-assistant.png";
import { Title, Spinner, Text, Button } from "b2c/components/Core";
import StepProgress from "b2c/components/StepProgress";
import ThemeContext from "../../contexts/theme";
import useStore from "../../contexts/store";
import {
  StyledLogo,
  StyledNcpwdLogo,
  OnboardingWrapper,
  OnboardingLeftContainer,
  OnboardingLeftContent,
  OnboardingRightContainer,
  OnboardingRightContent,
  OnboardingAssistantContent,
  OnboardingAssistantImage,
  OnboardingContent,
  OnboardingNav,
  Logout
} from "./styled";
import Seniority from "./components/Seniority";
import Career from "./components/Career";
import Interests from "./components/Interests";
import BrandedContext from "../../contexts/branded";

const Onboarding = ({ isCustomPlatform, fromUrl, setRedirectUrl }) => {
  const {
    state: { loading, message },
    user,
    Authorization: { seniority_levels, interest_areas, career_interests, fetchRegistrationSteps, updateUserSteps }
  } = useStore("User");
  const { brand } = useStore("initialState");
  const contentRef = useRef();
  const [step, setStep] = useState(0);
  const [stepAnswers, setStepAnswers] = useState({
    seniority_level_id: null,
    interest_area_ids: [],
    career_interest_id: null
  });
  const theme = useContext(ThemeContext);
  const { slug } = useContext(BrandedContext);

  useEffect(() => {
    fetchRegistrationSteps().then(() => {
      setStep(1);
    });
  }, []);

  useEffect(() => {
    if (isCustomPlatform && brand.onboarding_survey_id) {
      setRedirectUrl({ pathname: `/tests/${brand.onboarding_survey_id}`, state: { from: fromUrl, mandatory: true } });
    }
    contentRef?.current?.scrollTo(0, 0);
  }, [step]);

  const progressDisabled = useMemo(() => {
    switch (step) {
      default:
      case 1:
        return !stepAnswers.seniority_level_id;
      case 2:
        return stepAnswers.interest_area_ids.length === 0;
      case 3:
        return !stepAnswers.career_interest_id;
    }
  }, [step, stepAnswers]);

  const handleStepAnswer = (key, value) => {
    setStepAnswers(prev => ({ ...prev, [key]: value }));
  };

  const handleNextStep = () => {
    if (Number(step) === 3) {
      handleRegistrationCompleted();
      setStep(0);
    } else {
      setStep(prev => prev + 1);
    }
  };

  const handleBackwards = () => setStep(prev => prev - 1);

  const handleRegistrationCompleted = () => {
    updateUserSteps(stepAnswers);
  };

  const buildStyledLogo = () => {
    if (!isCustomPlatform) {
      return <StyledLogo src={fuzu_logo} alt="Fuzu logo" />;
    }

    if (slug === "ncpwd") {
      return <StyledNcpwdLogo src={ncpwd_logo} alt="NCPWD logo" />;
    }
  };

  return (
    <OnboardingWrapper>
      <OnboardingLeftContainer>
        <OnboardingLeftContent>
          {buildStyledLogo()}

          <Hidden sm xs>
            <OnboardingAssistantContent>
              <OnboardingAssistantImage
                src={isCustomPlatform ? (slug === "demo" ? demo_image : ncpwd_image) : assistantImage}
                alt={isCustomPlatform ? "NCPWD Logo" : "Women assistant illustration"}
              />

              <Title level="5" fontWeight={500} width="100%" textAlign="center" margin="25px 0 15px">
                Hey, {user?.first_name}
                <br />
                {!isCustomPlatform && "Welcome to Fuzu"}
              </Title>
              <Text textAlign="center">
                Just three quick steps to help us find opportunities for growth that match your level and interests!
              </Text>
            </OnboardingAssistantContent>
          </Hidden>
        </OnboardingLeftContent>
      </OnboardingLeftContainer>

      <OnboardingRightContainer>
        <OnboardingRightContent>
          {loading ? (
            <Spinner size="72px" margin="auto" />
          ) : (
            <>
              <StepProgress
                steps={["Seniority level", "Professional interests", "Career interests"]}
                width="auto"
                currentStep={step}
              />
              <OnboardingContent ref={contentRef}>
                {(() => {
                  switch (step) {
                    default:
                      return <Spinner size="72px" margin="248px auto" />;
                    case 1:
                      return (
                        <Seniority
                          seniority_levels={seniority_levels}
                          selected={stepAnswers.seniority_level_id}
                          onSelect={id => handleStepAnswer("seniority_level_id", id)}
                        />
                      );
                    case 2:
                      return (
                        <Interests
                          interest_areas={interest_areas}
                          selected={stepAnswers.interest_area_ids}
                          onSelect={arr => handleStepAnswer("interest_area_ids", arr)}
                        />
                      );
                    case 3:
                      return (
                        <Career
                          career_interests={career_interests}
                          selected={stepAnswers.career_interest_id}
                          onSelect={id => handleStepAnswer("career_interest_id", id)}
                        />
                      );
                  }
                })()}
              </OnboardingContent>
            </>
          )}
          <OnboardingNav backgroundColor={theme.white}>
            {step > 1 ? (
              <Button
                aria-label="Go back"
                width={48}
                height={48}
                color="black300"
                noBackground
                onClick={handleBackwards}
              >
                <KeyboardArrowLeft width={25} />
              </Button>
            ) : (
              <div />
            )}

            {message && (
              <Text color="accent300" loaded>
                {message}
              </Text>
            )}

            <Logout href="/logout">Log out</Logout>
            <Button
              height={48}
              width={200}
              aria-label="Next step"
              disabled={progressDisabled}
              onClick={handleNextStep}
              fontSize="14px"
            >
              {step === 3 ? "Finish" : "Next"}
            </Button>
          </OnboardingNav>
        </OnboardingRightContent>
      </OnboardingRightContainer>
    </OnboardingWrapper>
  );
};

export default observer(Onboarding);
