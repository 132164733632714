import modern from "images/b2c/cvBuilder/modern.png";
import classic from "images/b2c/cvBuilder/classic.png";
import international from "images/b2c/cvBuilder/international.png";
import plain from "images/b2c/cvBuilder/plain.png";

export const features = [
  "You will get 12 month access to Fuzu CV Creator",
  "All design and editing options available for 12 months",
  "Create and download CV as many times as you wish"
];

export const imageMap = {
  modern,
  classic,
  international,
  plain
};
