import React from "react";
import { observer } from "mobx-react";
import { useLocation } from "react-router-dom";

import NotFound from "b2c/pages/NotFound";
import useStore from "../../../../contexts/store";

const AuthorizedSubroute = ({ path, children }) => {
  const { user } = useStore("User");
  const { pathname } = useLocation();
  const matches = path.some(single => pathname.includes(single));

  return matches && !user?.id ? <NotFound /> : children;
};

export default observer(AuthorizedSubroute);
