import React, { useContext } from "react";
import { observer } from "mobx-react";
import { useHistory, useParams } from "react-router-dom";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";

import BrandGoogle from "images/b2c/brandGoogle.svg";
import BrandFacebook from "images/b2c/brandFacebook.svg";
import BrandLinkedin from "images/b2c/brandLinkedin.svg";

import { brandName } from "b2c/constants/brand";
import { BrandHidden, WhitelabelledHidden } from "../../../../contexts/branded";
import useStore from "../../../../contexts/store";
import ServerSide from "../../../../contexts/ssr";
import { Container, Item, IconContainer } from "./styled";

const SocialAuthorization = ({ modal = false, close, label, onSuccess }) => {
  const { ssr } = useContext(ServerSide);
  const { slug: channel } = useParams();
  const history = useHistory();

  const {
    Authorization: { responseOAuth, responseOAuthError }
  } = useStore("User");
  const { buildOnboardingFlowBody, confirmEmail } = useStore("Onboarding");

  const handleSuccess = success => {
    if (success && onSuccess) {
      onSuccess();
    }

    if (success && close) {
      close();
    }
  };

  const handleOAuth = (provider, code) => {
    const { user, referCode } = buildOnboardingFlowBody();
    const context = {
      source: modal ? "login_prompt" : "login_page",
      channel,
      details: user,
      referCode
    };

    responseOAuth(provider, code, context)
      .then(data => {
        if (data.state === "registered") {
          confirmEmail({ user: { email: data.user.email } }).then(res => {
            handleSuccess(res?.data?.user?.registration_completed);
          });

          return;
        }

        handleSuccess(data?.user?.registration_completed);
      })
      .catch(error => {
        const data = error?.response?.data;
        if (data?.state === "redirect_to_onboarding") history.push("/onboarding");
      });
  };

  const { LinkedIn } = ssr ? () => null : require("react-linkedin-login-oauth2");

  return (
    <BrandHidden brand={["ncpwd", brandName.jkuat, "demo"]}>
      <WhitelabelledHidden>
        <Container>
          <GoogleLogin
            clientId={process.env.GOOGLE_CLIENT_ID}
            onSuccess={({ accessToken }) => handleOAuth("google", accessToken)}
            onFailure={responseOAuthError}
            scope="email profile"
            cookiePolicy="single_host_origin"
            responseType="token"
            accessType="online"
            render={({ onClick }) => (
              <Item onClick={onClick}>
                <IconContainer>
                  <BrandGoogle />
                </IconContainer>
                <p>{label} Google</p>
              </Item>
            )}
          />
          <FacebookLogin
            appId={process.env.FB_APP_ID}
            fields="first_name, last_name, email"
            callback={() => handleOAuth("facebook")}
            onFailure={responseOAuthError}
            redirectUri={decodeURIComponent(window.location.href)}
            disableMobileRedirect
            cookie
            render={({ onClick }) => (
              <Item onClick={onClick}>
                <IconContainer>
                  <BrandFacebook />
                </IconContainer>
                <p>{label} Facebook</p>
              </Item>
            )}
          />

          <LinkedIn
            clientId={process.env.LINKEDIN_CLIENT_ID}
            redirectUri={`${window.location.origin}/pro/auth/linkedin/callback`}
            onSuccess={({ code }) => handleOAuth("linkedin", code)}
            onFailure={responseOAuthError}
            scope="r_emailaddress r_liteprofile"
            renderElement={({ onClick }) => (
              <Item onClick={onClick}>
                <IconContainer>
                  <BrandLinkedin />
                </IconContainer>
                <p>{label} LinkedIn</p>
              </Item>
            )}
          />
        </Container>
      </WhitelabelledHidden>
    </BrandHidden>
  );
};

export default observer(SocialAuthorization);
