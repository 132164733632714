import React from "react";
import { Row, Col } from "react-grid-system";
import { useTranslation } from "b2c/hooks/useTranslation";

import { buildImageUrlS3, getCompanyName } from "b2c/contexts/branded";
import { List, Button } from "../../../../components/Core";
import Introduction from "../../../../components/Introduction";
import { nameImages } from "../../../../constants/images";
import { useDocumentTitle } from "../../../../../utils/hooks";

const WizardIntro = ({ loading, onComplete }) => {
  const { t } = useTranslation();

  const bullets = [
    t("wizard.description_1", { name: getCompanyName() }),
    t("wizard.description_2", { name: getCompanyName() }),
    t("wizard.description_3", { name: getCompanyName() })
  ];

  useDocumentTitle(getCompanyName());

  return (
    <Introduction
      dataCy="wizard-content"
      loading={loading}
      image={buildImageUrlS3(nameImages.wizardIntro)}
      name={t("wizard.title", { name: getCompanyName() })}
      actions={
        <Row justify="end">
          <Col lg={3}>
            <Button color="primaryButtonLabelColor" width="100%" disabled={loading} onClick={onComplete}>
              {t("wizard.start")}
            </Button>
          </Col>
        </Row>
      }
    >
      <List>
        {bullets.map(bullet => (
          <List.Item key={bullet} fontSize={16}>
            {bullet}
          </List.Item>
        ))}
      </List>
    </Introduction>
  );
};

export default WizardIntro;
