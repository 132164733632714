import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Row, Col } from "react-grid-system";

import useStore from "../../../../contexts/store";
import { Card, Input, SelectInput, Text, Image, Alert, RadioButton } from "../../../../components/Core";
import { LoadingContent } from "../../../../components/Core/Spinner";

const MobileMethod = ({ disableTransactions, options, errors, onMount, onSubmit, Actions = () => null, ...rest }) => {
  const {
    Profile: {
      profile: { personal_info: { country, phone = {} } = {} } = {},
      personal_options: { phone_codes: { common = [], priorities = [] } = {} } = {},
      getPersonalInfo,
      getPersonalOptions
    }
  } = useStore("User");
  const [provider, setProvider] = useState({});
  const [phone_number, setPhoneNumber] = useState({ code: "+254", number: "" });
  const [validation, setValidation] = useState({});

  useEffect(() => {
    if (!phone.number || common.length === 0) getPersonalInfo().then(getPersonalOptions);
    if (!options) onMount();
  }, []);

  useEffect(() => {
    setValidation(errors || {});
  }, [errors]);

  useEffect(() => {
    const { code, number } = phone;
    if (code && number) setPhoneNumber({ code, number });
  }, [phone]);

  const handleInputs = e => {
    e.persist();
    setPhoneNumber(prev => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const disableSubmission = !phone_number.code || !phone_number.number || !provider.paymentname;

  return (
    <>
      <Card bordered>
        <Card.Content>
          <LoadingContent loading={common.length === 0 || !options} size="50px" margin="48px auto">
            <Row align="end">
              <Col sm={3}>
                <SelectInput
                  label={
                    <Text fontSize="inherit" fontWeight="500" margin="8px 0 0">
                      Phone Number
                    </Text>
                  }
                  name="code"
                  placeholder="Select Country Code"
                  options={priorities.concat(common).map(item => item.phone_code)}
                  defaultValue={phone_number.code || ""}
                  onChange={handleInputs}
                  errorMessage={validation.phone?.code}
                />
              </Col>
              <Col sm={6}>
                <Input
                  name="number"
                  type="tel"
                  placeholder="Your number"
                  value={phone_number.number}
                  onChange={handleInputs}
                  errorMessage={validation.phone?.number || validation.phone}
                />
              </Col>
            </Row>

            <Text fontWeight={500} margin="0 0 8px">
              Your service provider ({country?.name})
            </Text>

            {options?.length > 0 ? (
              options.map(option => (
                <RadioButton
                  id={option.paymentname}
                  key={option.paymentname}
                  boxedView
                  Label={
                    <Row>
                      <Col width="content">
                        <Image src={option.logo} width={68} />
                      </Col>
                      <Col style={{ display: "flex" }}>
                        <Text fontSize={16} fontWeight={500}>
                          {option.name}
                        </Text>
                      </Col>
                    </Row>
                  }
                  checked={provider.paymentname == option.paymentname}
                  onChange={() => setProvider(option)}
                />
              ))
            ) : (
              <Alert type="neutral">Mobile payment is not supported for your country</Alert>
            )}

            {provider.paymentname && (
              <Text margin="12px 0 24px">
                In the next step you will be asked to confirm {provider.paymentname} payment.
              </Text>
            )}
          </LoadingContent>
        </Card.Content>
      </Card>

      <Actions
        disabled={disableSubmission}
        onSubmit={() =>
          onSubmit(provider, { phone: phone_number, mno: provider.paymentname, mno_country: provider.country })
        }
        {...rest}
      />
    </>
  );
};

export default observer(MobileMethod);
