import styled from "styled-components";

export const Container = styled.div`
  background-color: #231f20;
  padding: 32px 16px;

  @media screen and (min-width: 1008px) {
    padding: 64px 16px;
  }

  @media screen and (min-width: 1440px) {
    padding: 64px 0;
  }
`;

export const Content = styled.div`
  max-width: 880px;
  width: 100%;

  @media screen and (min-width: 768px) {
    margin: 0 auto;
  }
`;

export const Title = styled.h3`
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 130%;
  margin: 0 0 16px 0;
  text-align: center;

  @media screen and (min-width: 768px) {
    font-size: 32px;
    font-weight: 600;
    line-height: 130%;
    margin-bottom: 40px;
  }
`;

export const Video = styled.div`
  width: 320px;
  height: 184px;
  margin: auto;

  iframe {
    height: 100%;
    width: 100%;
  }

  & > div {
    height: 100%;
    width: 100%;
  }

  & > div img {
    height: 100%;
    width: 100%;
  }

  @media screen and (min-width: 768px) {
    width: 800px;
    height: 495px;
  }
`;
