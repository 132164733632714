import styled from "styled-components";

export const LoginModal = styled.div`
  width: 500px;
  height: 742px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    width: auto;
    height: auto;
  }
`;
