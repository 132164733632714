import React, { useState } from "react";

import { defaultValidation } from "b2c/constants/heroCard";
import { Button, Card, Input, Text, Textarea } from "b2c/components/Core";
import { validateFormHeroCard } from "b2c/utils/helpers";
import useStore from "../../../../contexts/store";

const HeroSecondStep = ({ formState, setFormState, setStep, isSm }) => {
  const [validationState, setValidation] = useState(defaultValidation);
  const {
    Profile: { validation_errors }
  } = useStore("User");

  const handleSubmission = e => {
    e.preventDefault();
    setValidation(defaultValidation);

    const { valid, validation } = validateFormHeroCard(formState);
    setValidation(validation);
    if (valid) {
      setStep(3);
    }
  };

  return (
    <Card style={{ height: "715px" }}>
      <div
        style={{
          width: isSm ? "328px" : "310px",
          margin: isSm ? "16px auto" : "47px auto"
        }}
      >
        <form onSubmit={handleSubmission} noValidate>
          <Input
            key="name"
            label="Your name"
            type="text"
            fieldRequired
            value={formState?.name}
            errorMessage={validationState?.name?.error || validation_errors?.name}
            onChange={({ target: { value } }) => {
              if (value.length <= 40) {
                setFormState(prev => ({ ...prev, name: value }));
              }
            }}
          />
          <Input
            key="email"
            label="Your email address"
            type="email"
            autoComplete="email"
            fieldRequired
            value={formState?.email}
            onChange={({ target: { value } }) => setFormState(prev => ({ ...prev, email: value }))}
            errorMessage={validationState?.email?.error || validation_errors?.email}
          />
          <Input
            key="career_hero_name"
            label="Name of your career hero"
            type="text"
            fieldRequired
            value={formState?.careerName}
            errorMessage={validationState?.careerName?.error || validation_errors?.careerName}
            onChange={({ target: { value } }) => {
              if (value.length <= 25) {
                setFormState(prev => ({ ...prev, careerName: value }));
              }
            }}
          />
          <Textarea
            margin="0"
            inputHeight={154}
            key="thank"
            label={
              <>
                <Text fontSize="inherit" fontWeight="500">
                  Your thank you message*
                </Text>
                <Text fontSize="12px">Name of your hero and tell why you want to thank him/her.</Text>
              </>
            }
            errorMessage={validationState?.thank?.error || validation_errors?.thank}
            onChange={({ target: { value } }) => {
              if (value.length <= 300) {
                setFormState(prev => ({ ...prev, thank: value }));
              }
            }}
            value={formState?.thank}
          />
          <Text fontSize={12} margin="0 0 15px 0">
            <b>Characters count: </b>
            {formState?.thank?.length || 0} of 300
          </Text>

          <Button type="submit" fontSize="14px" margin="15px 0" width="100%" height="48px">
            View Thank You card
          </Button>

          <Text fontSize={12} fontWeight={400}>
            By creating and viewing your Thank You Card you
          </Text>
          <Text fontSize={12} fontWeight={400} style={{ width: "102%" }}>
            give Fuzu permission to use it in its communications.
          </Text>
        </form>
      </div>
    </Card>
  );
};

export default HeroSecondStep;
