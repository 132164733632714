import React, { useContext } from "react";
import styled from "styled-components";
import { useRouteMatch } from "react-router-dom";
import { Row, Col, ScreenClassContext } from "react-grid-system";

import promoUp from "images/b2c/promo/promo-up.png";
import promoDown from "images/b2c/promo/promo-down.png";
import promo1 from "images/b2c/promo/promo1.png";
import promo2 from "images/b2c/promo/promo2.png";
import promo3 from "images/b2c/promo/promo3.png";
import promo4 from "images/b2c/promo/promo4.png";
import promo5 from "images/b2c/promo/promo5.png";
import promo6 from "images/b2c/promo/promo6.png";
import promo7 from "images/b2c/promo/promo7.png";
import Layout from "../Layout";
import { Button, Image, Text } from "../Core";
import useStore from "../../contexts/store";

const EmployerLandingPromo = ({ type, linkTo }) => {
  const { track } = useStore("Tracking");
  const { path } = useRouteMatch();
  const screen = useContext(ScreenClassContext);
  const isSm = ["xs", "sm"].includes(screen);

  const trackPromoClick = () => {
    track("promo_clicked", { match_path: path, type: "employer_landing_page" });
  };
  if (type === "blog" && !isSm) {
    return <Blog trackPromoClick={trackPromoClick} />;
  }
  return <Standard trackPromoClick={trackPromoClick} linkTo={linkTo} />;
};

const Blog = ({ trackPromoClick }) => (
  <BlogWrapper>
    <Row>
      <Col md={7}>
        <Text fontWeight={500} style={{ fontSize: 28 }} margin="24px 0 0 32px" width="100%" textAlign="left">
          Hire top talent, 80% faster
        </Text>
        <Text style={{ fontSize: 16 }} margin="0 0 0 32px" width="100%" textAlign="left">
          Find the right candidate with ease and save time using Fuzu’s match-making technology!
        </Text>
        <Button.Link
          onClick={trackPromoClick}
          style={{ margin: "32px 0 0 32px" }}
          arrow
          theme="black400"
          width="fit-content"
          to="/employers"
          margin="auto"
        >
          Start hiring
        </Button.Link>
      </Col>
      <Col md={5}>
        {images.map(({ top, right, src }, i) => (
          <ImageWrapper key={i} style={{ top, right }}>
            <Image borderRadius="50%" width={54} height={54} src={src} alt="Promo" />
          </ImageWrapper>
        ))}
      </Col>
    </Row>
  </BlogWrapper>
);

const Standard = ({ trackPromoClick, linkTo }) => {
  const screen = useContext(ScreenClassContext);
  const isSm = ["xs", "sm"].includes(screen);
  return (
    <Layout.Section
      backgroundColor="white"
      margin={isSm ? "25px 0" : "25px 0 0 0"}
      padding={isSm ? "32px 0" : "32px 0 40px 0"}
      style={{ position: "relative" }}
    >
      <Layout.Content tag="div" style={{ maxWidth: "1400px" }}>
        <Row align={isSm ? "center" : "normal"}>
          <Col md={10}>
            <Text
              fontWeight={isSm ? 600 : 400}
              style={{ fontSize: isSm ? 20 : 32 }}
              margin="0 0 16px"
              width="100%"
              textAlign={isSm ? "center" : "left"}
            >
              Looking for talent?
            </Text>
            <Row>
              <Col
                width={isSm ? "100%" : "fit-content"}
                height={isSm ? "60px" : "auto"}
                style={{
                  display: "flex",
                  position: "relative",
                  height: "60px"
                }}
              >
                {(isSm ? companyImagesSm : companyImages).map(({ src, left }, i) => (
                  <ImageWrapper key={i} style={{ left }}>
                    <Image
                      style={{ boxShadow: "0px 0px 0px 3px #fff" }}
                      width={40}
                      height={40}
                      src={src}
                      maxWidth="initial"
                      borderRadius="50%"
                      alt="Looking for talent?"
                    />
                  </ImageWrapper>
                ))}
              </Col>
              <Col width={isSm ? "100%" : "fit-content"} style={{ display: "flex" }}>
                <Text
                  style={{ textAlign: isSm ? "center" : "left" }}
                  fontSize={isSm ? 14 : 16}
                  fontWeight={isSm ? 400 : 500}
                  margin={isSm ? "auto" : "10px 0 0 220px"}
                >
                  2.5 Million+ job seekers ready to apply
                </Text>
              </Col>
            </Row>
          </Col>
          <Col md={2} style={{ display: "flex", marginTop: 16 }}>
            <Button.Link
              onClick={trackPromoClick}
              arrow
              theme="black400"
              width="211px"
              to={linkTo || "/employers"}
              margin="auto"
            >
              Start hiring
            </Button.Link>
          </Col>
        </Row>
      </Layout.Content>
    </Layout.Section>
  );
};

const BlogWrapper = styled.div`
  width: 680px;
  height: 231px;
  background: white;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  margin: 15px auto;
`;

const ImageWrapper = styled.div`
  position: absolute;
`;

const images = [
  {
    right: "167px",
    top: "-6px",
    src: promoUp
  },
  {
    right: "101px",
    top: "-37px",
    src: promoUp
  },
  {
    right: "35px",
    top: "-6px",
    src: promoUp
  },
  {
    right: "167px",
    top: "56px",
    src: promo6
  },
  {
    right: "101px",
    top: "23px",
    src: promo1
  },
  {
    right: "35px",
    top: "56px",
    src: promo2
  },
  {
    right: "167px",
    top: "122px",
    src: promo5
  },
  {
    right: "101px",
    top: "89px",
    src: promo7
  },
  {
    right: "35px",
    top: "122px",
    src: promo3
  },
  {
    right: "167px",
    top: "184px",
    src: promoDown
  },
  {
    right: "101px",
    top: "155px",
    src: promo4
  },
  {
    right: "35px",
    top: "184px",
    src: promoDown
  },
  {
    right: "101px",
    top: "215px",
    src: promoDown
  }
];

const companyImages = [
  { src: promo1, left: "0px" },
  { src: promo6, left: "40px" },
  { src: promo2, left: "80px" },
  { src: promo7, left: "120px" },
  { src: promo5, left: "160px" },
  { src: promo3, left: "200px" }
];

const companyImagesSm = [
  { src: promo1, left: "calc(45% - 120px)" },
  { src: promo6, left: "calc(45% - 80px)" },
  { src: promo2, left: "calc(45% - 40px)" },
  { src: promo7, left: "45%" },
  { src: promo5, left: "calc(45% + 40px)" },
  { src: promo3, left: "calc(45% + 80px)" },
  { src: promo4, left: "calc(45% + 120px)" }
];

export default EmployerLandingPromo;
