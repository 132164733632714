import React, { useState, useMemo, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import { ScreenClassContext, useScreenClass } from "react-grid-system";

import { GridAlt, RightArrowAlt } from "@styled-icons/boxicons-regular";
import { usePrev } from "utils/hooks";
import { brandTypes } from "b2c/constants/brand";
import { useTranslation } from "b2c/hooks/useTranslation";
import Item from "./JobsItem";
import CarouselSection from "../../Section/CarouselSection";
import useStore from "../../../contexts/store";
import BrandedContext, { buildImageUrlS3 } from "../../../contexts/branded";
import { Title, Text, Button, Card, Image } from "../../Core";
import BannerBuildProfile from "../../BannerBuildProfile";
import { nameImages } from "../../../constants/images";

const JobsSection = observer(
  ({
    baseRoute = "job",
    employer_id,
    job_id,
    title,
    filter,
    count_limit = 4,
    total_limit = 8,
    expandable = false,
    noHeader = false,
    narrowCarousel,
    titleProps,
    brandSlug,
    ...rest
  }) => {
    const isXs = useScreenClass() === "xs";
    const { jobs, state, getJobs, getFuzuJobs } = useStore("Jobs");
    const { user } = useStore("User");
    const { handleSaving } = useStore("Saved");
    const loading = state[filter] ? state[filter]?.loading : true;
    const expanded = useState(false);
    const prevLoading = usePrev(loading);
    const loaded = useMemo(() => prevLoading, [loading, expanded]);
    const item_limit = expanded || (narrowCarousel && isXs) ? total_limit || count_limit : count_limit;
    const jobList = jobs[filter] ? jobs[filter].slice(0, item_limit) : [...new Array(item_limit).keys()];
    const screen = useContext(ScreenClassContext);
    const isSm = /xs|sm/.test(screen);
    const isShowFuzuBuildPrompt = filter === "recommended";
    const { t } = useTranslation();

    useEffect(() => {
      onSectionMount();
    }, [job_id]);

    const getListJobs = () => {
      if (
        user &&
        user.profile_completed !== true &&
        isShowFuzuBuildPrompt &&
        jobList.length &&
        typeof jobList[0] === "object"
      ) {
        const jobListLength = jobList.length;
        const copyJobsList = [...jobList];

        copyJobsList.splice(jobListLength <= 3 ? jobListLength : 3, 0, { type: "banner", filter: "banner" });
        return copyJobsList;
      }
      return jobList;
    };

    const getLinkSeeAll = () => {
      switch (filter) {
        case "saved":
          return "/job/saved";
        case "latest":
          return "/job?filters[sort_by]=latest";
        case "closing_soon":
          return "/job?filters[sort_by]=closing";
        case "recommended":
        case "industry":
        case "similar_to_applied":
          return "/job?filters[sort_by]=recommended";
        default:
          return "/job";
      }
    };

    // NOTE Atte 12.10.21: guest job url logic should be moved to backend – now no single source of truth
    const { brand_type } = useContext(BrandedContext);
    const generatePositionUrl = position =>
      user?.id && brand_type !== brandTypes.whitelabelled
        ? `/${baseRoute}${position?.id ? `?filters[job_id]=${position?.id}&` : "?"}${
            filter === "employability_program"
              ? `filters[employability_program_ids][0]=${employer_id}`
              : `filters[feed]=${filter}`
          }`
        : `${position?.country?.featured ? `/${position?.country?.slug}` : ""}/jobs/${position?.slug}`;

    const onSectionMount = () => {
      if (jobs.length) {
        return;
      }

      if (filter === "fuzu") {
        getFuzuJobs(count_limit);
      } else {
        getJobs(filter, expandable ? total_limit : count_limit, employer_id, job_id, brandSlug, isShowFuzuBuildPrompt);
      }
    };

    const emptyIcon = buildImageUrlS3(nameImages.jobsEmpty);

    return (
      <CarouselSection
        expandable={expandable}
        expanded={expanded}
        onSectionMount={onSectionMount}
        columns={4}
        testScope="jobs-section"
        narrowCarousel={narrowCarousel}
        slideMobileWidth="230px"
        style={{
          marginTop: isSm ? 0 : "25px"
        }}
        Header={
          !noHeader && (
            <Title scales level={3} fontWeight={600} color="#292929" {...titleProps} className="title_sentence">
              {title}
            </Title>
          )
        }
        ShowAll={
          <Button.Link
            to={getLinkSeeAll()}
            theme="transparent"
            style={{ color: "#296ACC", fontSize: "14px", fontWeight: 600 }}
          >
            <GridAlt width={17} style={{ stroke: "#296ACC", marginRight: "10px" }} />
            {t("global.see_all")}
          </Button.Link>
        }
        ShowMoreButton={
          <Card style={{ height: "100%" }} as={Link} to={getLinkSeeAll()}>
            <Card.Content
              style={{
                border: "1px solid #E1E1E1",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.08)",
                minHeight: "153px",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "10px",
                borderRadius: "5px"
              }}
            >
              <Image width="75px" src={emptyIcon} additionalCss="height: 57px" />
              <Text width="100%" textAlign="center" margin="10px 0" fontWeight={600}>
                {t("jobs.content.show_more_jobs")}
                <RightArrowAlt width={20} />
              </Text>
            </Card.Content>
          </Card>
        }
        {...rest}
      >
        {getListJobs().map((item, index) => (
          <React.Fragment key={index}>
            {item.filter === "banner" ? (
              <BannerBuildProfile key={`jobBanner_${title}_${filter}`} />
            ) : (
              <Item
                {...item}
                key={`top_job_${filter}_${index}_${title}_${item.title}`}
                baseRoute={baseRoute}
                saveItem={saved => handleSaving(item.id, "job", saved)}
                to={generatePositionUrl(item)}
                loading={loading}
                loaded={loaded}
                isShowFuzuBuildPrompt
              />
            )}
          </React.Fragment>
        ))}
      </CarouselSection>
    );
  }
);

export default JobsSection;
