import styled from "styled-components";

export const FeatureStyled = styled.div`
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

export const Content = styled.div`
  padding: 20px;
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;

  @media screen and (min-width: 1200px) {
    padding: 80px 50px;
  }
`;

export const Title = styled.h3`
  font-weight: 600;
  font-size: 24px;
  line-height: 130%;
  color: #292929;
  margin-bottom: 16px;

  @media screen and (min-width: 768px) {
    font-size: 32px;
  }
`;

export const Text = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #656565;
  display: block;
  margin: 0 auto 16px auto;

  @media screen and (min-width: 768px) {
    font-size: 16px;
    max-width: 490px;
  }
  @media screen and (min-width: 1200px) {
    margin: 0;
  }
`;

export const LeftSide = styled.div`
  text-align: center;

  @media screen and (min-width: 1200px) {
    text-align: left;
    margin-left: ${({ isReverse }) => (isReverse ? "75px" : "0")};
  }
`;

export const RightSide = styled.div`
  @media screen and (min-width: 1200px) {
    display: flex;
    justify-content: ${({ isReverse }) => (isReverse ? "flex-start" : "flex-end")};;
  Ї
`;

export const ImageContent = styled.div`
  width: ${({ imageWidth }) => imageWidth};
  height: ${({ imageHeight }) => imageHeight};
  margin: 0 auto;

  @media screen and (min-width: 768px) {
    width: ${({ imageWidthTablet }) => imageWidthTablet};
    height: ${({ imageHeightTablet }) => imageHeightTablet};
  }

  @media screen and (min-width: 1200px) {
    margin: 0;
  }

  img {
    max-width: 100%;
  }
`;
