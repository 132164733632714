import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import parse from "html-react-parser";
import { useTranslation } from "b2c/hooks/useTranslation";

import ExperienceFlow from "b2c/components/ExperienceFlow";
import { buildImageUrlS3, getCompanyName } from "b2c/contexts/branded";
import useStore from "../../../../contexts/store";
import { Card, Checkbox, Image, Text } from "../../../../components/Core";
import ProfileItem from "../ProfileItem";
import { EmptyContent, ItemList } from "./styled";
import { nameImages } from "../../../../constants/images";
import { languageFormat } from "../../../../constants/main";

const WorkExperienceStep = ({ list = [], stepState: { id, editing }, setStepState, FormActions, resolveAction }) => {
  const {
    Profile: { removeWorkExperience }
  } = useStore("User");
  const [submissionDisabled, disableSubmission] = useState(true);
  const { t, language } = useTranslation();
  const languageFormatType = languageFormat[language];

  useEffect(() => {
    disableSubmission(list.length === 0 || list.some(item => item.waits_user_review));
  }, [list]);

  const handleProgress = e => {
    e.preventDefault();
    resolveAction();
  };

  const convertDate = string =>
    string ? new Date(string).toLocaleString(languageFormatType, { month: "short", year: "numeric" }) : "Present";

  const sortByPriority = (itemA, itemB, priority) => (itemA[priority] ? -1 : itemA.end_date > itemB.end_date ? -1 : 1);

  return !editing ? (
    <form onSubmit={handleProgress}>
      {list.length > 0 ? (
        <ItemList>
          {list
            .slice()
            .sort((a, b) => sortByPriority(a, b, "current_work"))
            .map(({ id: ids, job_title, description, employer, start_date, end_date, waits_user_review, ...rest }) => (
              <ProfileItem
                key={ids}
                {...{
                  id: ids,
                  title: job_title,
                  info: employer,
                  text: description,
                  date: `${convertDate(start_date)} - ${convertDate(end_date)}`,
                  onEdit: () => setStepState({ editing: true, id: ids }),
                  onDelete: () => removeWorkExperience(ids),
                  alertMessage: waits_user_review && <>{t("profile.work_experience.alert_message")}</>,
                  ...rest
                }}
              />
            ))}
        </ItemList>
      ) : (
        <Card wideOnMobile>
          <EmptyContent>
            <Image width="150px" src={buildImageUrlS3(nameImages.jobsEmpty)} />
            <Text fontSize={16} textAlign="center" margin="24px auto" fontWeight={500}>
              {parse(t("wizard.work_experience.empty_text", { name: getCompanyName() }))}
            </Text>
            <Checkbox
              margin="0 auto"
              checked={!submissionDisabled}
              onChange={() => disableSubmission(!submissionDisabled)}
              label={t("wizard.work_experience.empty_label")}
            />
          </EmptyContent>
        </Card>
      )}

      <FormActions disableSubmit={submissionDisabled} />
    </form>
  ) : (
    <ExperienceFlow hiddenTitle itemId={id} resolveAction={resolveAction} FormActions={FormActions} inProfileWizard />
  );
};

export default observer(WorkExperienceStep);
