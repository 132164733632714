import styled from "styled-components";

export const LegacyComponent = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
`;

export const LegacyHeader = styled.div`
  background-color: #212121;
  padding: 22px;
  display: flex;
  justify-content: center;
`;

export const LegacyContent = styled.div`
  text-align: center;
  margin-top: 50px;
  padding: 0 40px;
  @media screen and (min-width: 768px) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 460px;
    margin-top: 0;
    padding: 0 20px;
  }
`;

export const BrowserList = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const BrowserItem = styled.a`
  text-decoration: none;
`;

export const BrowserImage = styled.img`
  height: 60px;
  width: 60px;
  margin-bottom: 15px;
  display: block;
  @media screen and (min-width: 768px) {
    height: 79px;
    width: 79px;
  }
`;
