import React from "react";

import { Checkbox, Image, Input, RadioButton } from "../../../../components/Core";
import Spinner from "../../../../components/Core/Spinner";

const AnswerOption = ({
  processing,
  id,
  type,
  text,
  full_image,
  medium_image,
  small_image,
  response,
  onChange,
  onMultiChange
}) => {
  const optionContent = (() => {
    const image = full_image || medium_image || small_image;
    return image ? <Image src={image} alt="Option image" /> : text;
  })();

  switch (type) {
    case "text":
      return (
        <Input
          placeholder="Your answer"
          type="text"
          margin="0 0 10px"
          onChange={({ target: { value } }) => onChange(value)}
          disabled={processing}
        />
      );
    case "option":
      return (
        <RadioButton
          borderedView
          id={id}
          value={id}
          disabled={processing}
          checked={+response === id}
          Label={optionContent}
          margin="0 0 10px"
          onChange={({ target: { value } }) => onChange(value)}
        />
      );
    case "checkbox":
      return (
        <Checkbox
          borderedView
          id={id}
          value={id}
          disabled={processing}
          checked={response && response.includes(id)}
          label={optionContent}
          margin="0 0 10px"
          onChange={onMultiChange}
        />
      );

    default:
      return <Spinner size="50px" margin="24px auto" />;
  }
};

export default AnswerOption;
