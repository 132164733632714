import React, { useState, useContext } from "react";

import { Link } from "react-router-dom";

import { validateEmail } from "utils/validation";
import { onEnterPress } from "utils/helpers";
import { Input, Button, Checkbox, Dropdown, Text } from "b2c/components/Core";
import BrandedContext from "../../../../contexts/branded";

const Confirmation = ({
  state: { loading, message },
  form,
  countries = [],
  validation_errors,
  submitForm,
  resetUser
}) => {
  const { slug } = useContext(BrandedContext);
  const [agreed, setAgreement] = useState(false);
  const [formState, setFormState] = useState(form);

  const [validationState, setValidation] = useState({
    email: { error: false },
    fuzu_country_id: { error: false }
  });

  const handleSubmission = e => {
    e.preventDefault();

    const valid_email = validateEmail(formState.email);
    const countrySelected = !!formState.fuzu_country_id;

    return valid_email.test && countrySelected
      ? submitForm(formState)
      : setValidation({ email: valid_email, fuzu_country_id: { error: "Please select country" } });
  };

  return (
    <>
      <form onSubmit={handleSubmission} onKeyDown={e => onEnterPress(e, 1, () => handleSubmission(e))} noValidate>
        <Input
          label="Confirm your email address"
          placeholder="example@domain.com"
          type="email"
          autoComplete="email"
          value={formState.email}
          onChange={({ target: { value } }) => setFormState(prev => ({ ...prev, email: value }))}
          errorMessage={validationState.email.error || validation_errors.email}
        />

        <Dropdown
          label="Choose country"
          defaultValue={
            countries.find(({ id }) => id == formState?.fuzu_country_id)?.name ||
            (slug === "ncpwd" && "Kenya") ||
            "Select an option"
          }
          wrapperStyle="margin-bottom: 16px;"
          errorMessage={validationState.fuzu_country_id.error || validation_errors.fuzu_country_id}
        >
          <Dropdown.Menu disableSorting>
            {countries.map(({ id, name }) => (
              <Dropdown.Item
                key={id}
                value={name}
                onClick={() => setFormState(prev => ({ ...prev, fuzu_country_id: id }))}
              >
                {name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>

        <Checkbox
          label={
            <Text color="black400">
              Make my Fuzu profile visible to employers so that they can find me and send me messages inside the Fuzu
              platform
            </Text>
          }
          id="test1"
          checked={formState.talent_pool_visibility}
          onChange={({ target: { checked } }) => setFormState(prev => ({ ...prev, talent_pool_visibility: checked }))}
        />
        <Checkbox
          describedBy="agreement-description"
          label={
            <Text id="agreement-description" color="black400">
              By creating an account you agree to the{" "}
              <Link className="link" to="/privacy-policy">
                Privacy Policy
              </Link>{" "}
              and{" "}
              <Link className="link" to="/terms-and-conditions">
                Service Terms
              </Link>
            </Text>
          }
          checked={agreed}
          onChange={({ target: { checked } }) => setAgreement(checked)}
        />
        {message && (
          <Text color="accent300" margin="4px 0 0" loaded>
            {message}
          </Text>
        )}

        <Button type="submit" disabled={loading || !agreed} fontSize="14px" margin="10px 0 0" width="100%">
          Continue
        </Button>
      </form>

      <Button.Link to="/signup" width="100%" theme="grey200" fontSize="14px" margin="12px 0 0" onClick={resetUser}>
        Go back
      </Button.Link>
    </>
  );
};

export default Confirmation;
