import heroImageB2C from "images/b2c/landing-page/heroImage.png";

import howItWorks1 from "images/b2c/landing-page/howItWorks1.png";
import howItWorks2 from "images/b2c/landing-page/howItWorks2.png";
import howItWorks3 from "images/b2c/landing-page/howItWorks3.png";

import howItWorksBarona1 from "images/b2c/landing-page/howItWorksBarona1.png";
import howItWorksBarona2 from "images/b2c/landing-page/howItWorksBarona2.png";
import howItWorksBarona3 from "images/b2c/landing-page/howItWorksBarona3.png";

import howItWorksB2B_1 from "images/b2b/landing-page/howItWorksB2B_1.png";
import howItWorksB2B_2 from "images/b2b/landing-page/howItWorksB2B_2.png";
import howItWorksB2B_3 from "images/b2b/landing-page/howItWorksB2B_3.png";
import howItWorksB2B_4 from "images/b2b/landing-page/howItWorksB2B_4.png";

import infoSlideImage1 from "images/b2b/landing-page/infoSlider/1.mp4";
import infoSlideImage2 from "images/b2b/landing-page/infoSlider/2.mp4";
import infoSlideImage3 from "images/b2b/landing-page/infoSlider/3.mp4";

import testimonial1 from "images/b2b/landing-page/testimonials/olaronke.jpeg";
import testimonial3 from "images/b2b/landing-page/testimonials/sarah.jpg";
import testimonial4 from "images/b2b/landing-page/testimonials/alastair.jpg";

import Clock from "images/b2c/landing-page/bullets/clock.svg";
import Comm from "images/b2c/landing-page/bullets/comm.svg";
import Cup from "images/b2c/landing-page/bullets/cup.svg";
import Glass from "images/b2c/landing-page/bullets/glass.svg";
import Graph from "images/b2c/landing-page/bullets/graph.svg";
import House from "images/b2c/landing-page/bullets/house.svg";
import Letter from "images/b2c/landing-page/bullets/letter.svg";
import Cool from "images/b2c/landing-page/bullets/cool.svg";
import Fire from "images/b2c/landing-page/bullets/fire.svg";
import Lamp from "images/b2c/landing-page/bullets/lamp.svg";
import Person from "images/b2c/landing-page/bullets/person.svg";
import Rocket from "images/b2c/landing-page/bullets/rocket.svg";
import Star from "images/b2c/landing-page/bullets/star.svg";

export const testimonialsFuzu = [];

export const testimonials = {
  fuzu: [
    {
      id: 1,
      text: "landing_fuzu.testimonials.sub-title_1",
      videoID: "H9S0jTYfen4",
      author: "landing_fuzu.testimonials.author_1"
    },
    {
      id: 3,
      text: "landing_fuzu.testimonials.sub-title_2",
      videoID: "09zWVDb0Pw4",
      author: "landing_fuzu.testimonials.author_2"
    }
  ],
  barona: [
    {
      id: 4,
      text: "landing.testimonials.sub-title_1",
      videoID: "lgaYWf8XMnI",
      author: "landing.testimonials.author_1"
    },
    {
      id: 5,
      text: "landing.testimonials.sub-title_2",
      videoID: "-tVfhCyYu54",
      author: "landing.testimonials.author_2"
    }
  ]
};

export const countries = [
  {
    name: "Jobs in Africa",
    slug: "global"
  },
  {
    name: "Jobs in Kenya",
    slug: "kenya"
  },
  {
    name: "Jobs in Nigeria",
    slug: "nigeria"
  },
  {
    name: "Jobs in Uganda",
    slug: "uganda"
  }
];

export const countriesSelection = {
  b2b: [
    {
      name: "pricing.hire_in_africa",
      slug: "global"
    },
    {
      name: "pricing.hire_in_kenya",
      slug: "kenya"
    },
    {
      name: "pricing.hire_in_nigeria",
      slug: "nigeria"
    },
    {
      name: "pricing.hire_in_uganda",
      slug: "uganda"
    }
  ],
  b2c: [
    {
      name: "landing.search.jobs_in_africa",
      slug: "global"
    },
    {
      name: "landing.search.jobs_in_kenya",
      slug: "kenya"
    },
    {
      name: "landing.search.jobs_in_nigeria",
      slug: "nigeria"
    },
    {
      name: "landing.search.jobs_in_uganda",
      slug: "uganda"
    }
  ]
};

export const globalAfrica = {
  id: "global",
  country_code: "global",
  name: "Africa"
};

export const searchCountries = [
  { slug: "/kenya/job", name: "landing.search.jobs_in_kenya" },
  { slug: "/nigeria/job", name: "landing.search.jobs_in_nigeria" },
  { slug: "/uganda/job", name: "landing.search.jobs_in_uganda" }
];

export const searchNigeriaCities = [
  { slug: "/nigeria/job/lagos", name: "landing.search.jobs_in_lagos" },
  { slug: "/nigeria/job/abuja", name: "landing.search.jobs_in_abuja" },
  { slug: "/nigeria/job/ibadan", name: "landing.search.jobs_in_ibadan" }
];

export const searchKenyaCities = [
  { slug: "/kenya/job/nairobi", name: "landing.search.jobs_in_nairobi" },
  { slug: "/kenya/job/mombasa", name: "landing.search.jobs_in_mombasa" },
  { slug: "/kenya/job/kisumu", name: "landing.search.jobs_in_kisumu" }
];

export const searchUgandaCities = [{ slug: "/uganda/job/kampala", name: "landing.search.jobs_in_kampala" }];

export const landing = {
  b2c: {
    heroSectionData: {
      title: "Fuzu prepares/you for success",
      text: "Join millions of users and get personalised job recommendations, career guidance and new skills",
      imageUrl: heroImageB2C
    },
    whatFuzuOffers: {
      slides: {
        fuzu: [
          {
            id: 1,
            title: "5,000+",
            subtitle: "landing_fuzu.offers.sub-title_1",
            items: [
              { icon: Glass, text: "landing_fuzu.offers.description_1_point_1" },
              { icon: Clock, text: "landing_fuzu.offers.description_1_point_2" },
              { icon: Letter, text: "landing_fuzu.offers.description_1_point_3" }
            ],
            action: { text: "landing_fuzu.offers.button_1", to: "/job" },
            imageUrl: howItWorks1,
            smImageHeight: 308,
            smImageWidth: 320,
            imageHeight: 486,
            imageWidth: 506,
            direction: "",
            alt: "landing_fuzu.offers.image_alt_1"
          },
          {
            id: 2,
            title: "3,000+",
            subtitle: "landing_fuzu.offers.sub-title_2",
            items: [
              { icon: Cup, text: "landing_fuzu.offers.description_2_point_1" },
              { icon: House, text: "landing_fuzu.offers.description_2_point_2" }
            ],
            action: { text: "landing_fuzu.offers.button_2", to: "/learn" },
            imageUrl: howItWorks3,
            smImageHeight: 265,
            smImageWidth: 320,
            imageHeight: 424,
            imageWidth: 513,
            direction: "reverse",
            alt: "landing_fuzu.offers.image_alt_2"
          },
          {
            id: 3,
            title: "landing_fuzu.offers.title_3",
            subtitle: "landing_fuzu.offers.sub-title_3",
            items: [
              { icon: Graph, text: "landing_fuzu.offers.description_3_point_1" },
              { icon: Comm, text: "landing_fuzu.offers.description_3_point_2" }
            ],
            action: { text: "landing_fuzu.offers.button_3", to: "/forum" },
            imageUrl: howItWorks2,
            smImageHeight: 308,
            smImageWidth: 320,
            imageHeight: 486,
            imageWidth: 506,
            direction: "",
            alt: "landing_fuzu.offers.image_alt_3"
          }
        ],
        barona: [
          {
            id: 1,
            title: "landing.offers.title_1",
            subtitle: "landing.offers.sub-title_1",
            items: [
              {
                text: "landing.offers.description_1_point_1"
              },
              {
                text: "landing.offers.description_1_point_2"
              }
            ],
            action: { text: "landing.offers.button_1", to: "/login" },
            imageUrl: howItWorksBarona1,
            smImageHeight: 308,
            smImageWidth: 320,
            imageHeight: 486,
            imageWidth: 506,
            direction: "",
            alt: "landing.offersimage_alt_1"
          },
          {
            id: 2,
            title: "landing.offers.title_2",
            subtitle: "",
            items: [
              {
                text: "landing.offers.description_2_point_1"
              },
              { text: "landing.offers.description_2_point_2" }
            ],
            action: {
              text: "landing.offers.button_2",
              to: "https://www.koutsit.fi/en/products/how-to-land-a-job-in-the-nordics",
              isAnchor: true
            },
            imageUrl: howItWorksBarona2,
            smImageHeight: 265,
            smImageWidth: 320,
            imageHeight: 424,
            imageWidth: 513,
            direction: "reverse",
            alt: "landing.offers.image_alt_2"
          },
          {
            id: 3,
            title: "landing.offers.title_3",
            subtitle: "landing.offers.title_3",
            items: [
              {
                text: "landing.offers.description_3_point_1"
              },
              {
                text: "landing.offers.description_3_point_2"
              }
            ],
            // action: { text: "Learn about Barona", to: "/about" },
            imageUrl: howItWorksBarona3,
            smImageHeight: 308,
            smImageWidth: 320,
            imageHeight: 486,
            imageWidth: 506,
            direction: "",
            alt: "landing.offers.image_alt_3"
          }
        ]
      },
      slidesNigeria: [
        {
          id: 1,
          title: "Access 5,000+ new job opportunities every month",
          subtitle: "",
          items: [
            {
              icon: Letter,
              text: "Get job alerts about matching internships or management roles, remote work or online jobs"
            },
            { icon: Clock, text: "Apply for open vacancies in minutes using online CV" },
            { icon: Rocket, text: "Read tips and articles about interview questions and answers" },
            { icon: Cool, text: "Receive feedback on your applications" }
          ],
          action: { text: "Get a job now", to: "/job" },
          imageUrl: howItWorks1,
          smImageHeight: 308,
          smImageWidth: 320,
          imageHeight: 486,
          imageWidth: 506,
          direction: "",
          alt: "access thousands of jobs and vacancies and benefit from cv templates and interview support"
        },
        {
          id: 2,
          title: "Build skills demanded by the job market to improve your chances",
          subtitle: "",
          items: [
            { icon: Lamp, text: "Access hundreds of free and paid online courses" },
            {
              icon: Cup,
              text: "Get promoted, become an entrepreneur or start freelancing with Fuzu Career Advisors"
            },
            {
              icon: Person,
              text: "Build a resume based on CV templates or let a professional help you out with CV writing"
            },
            { icon: House, text: "Get inspired by thought-leaders and how they reach goals and recruit talent" }
          ],
          action: { text: "Start learning", to: "/learn" },
          imageUrl: howItWorks3,
          smImageHeight: 265,
          smImageWidth: 320,
          imageHeight: 424,
          imageWidth: 513,
          direction: "reverse",
          alt: "access hundreds of online courses, high quality CV samples and career advice to support lifelong learning"
        },
        {
          id: 3,
          title: "Join Africa’s fastest growing career network",
          subtitle: "",
          items: [
            {
              icon: Star,
              text: "For business leaders, experts, public servants, NGO specialists, recent graduates and skilled professionals"
            },
            { icon: Comm, text: "Network and grow together with likeminded professionals" },
            { icon: Fire, text: "Get advice and be inspired by senior experts" },
            { icon: Glass, text: "Learn about local and international career opportunities" }
          ],
          action: { text: "Join the community", to: "/forum" },
          imageUrl: howItWorks2,
          smImageHeight: 308,
          smImageWidth: 320,
          imageHeight: 486,
          imageWidth: 506,
          direction: "",
          alt: "get support and be inspired by Africa’s fastest growing career network for anyone from entry level to executives"
        }
      ]
    }
  },
  b2b: {
    testimonialSliderData: {
      title: "",
      testimonials: [
        {
          id: 1,
          title: "employers.testimonials.title_1",
          text: "employers.testimonials.description_1",
          author: "employers.testimonials.author_1",
          imageUrl: testimonial1
        },
        {
          id: 2,
          title: "employers.testimonials.title_2",
          text: "employers.testimonials.description_2",
          author: "employers.testimonials.author_2",
          imageUrl: testimonial3
        },
        {
          id: 3,
          title: "employers.testimonials.title_3",
          text: "employers.testimonials.description_3",
          author: "employers.testimonials.author_3",
          imageUrl: testimonial4
        }
      ]
    },
    baronaPricing: {
      pricingPlans: [
        {
          selectedProductType: 0,
          type: "free",
          title: "companies.pricing.price.title_1",
          badge: "companies.pricing.price.title_1",
          tagline: "companies.pricing.price.sub-title_1",
          descr: [
            "companies.pricing.price.description_1_point_1",
            "companies.pricing.price.description_1_point_2",
            "companies.pricing.price.description_1_point_3",
            "companies.pricing.price.description_1_point_4",
            "companies.pricing.price.description_1_point_5"
          ],
          buttonText: "companies.pricing.price.button_1"
        },
        {
          selectedProductType: 1,
          type: "starter",
          title: "companies.pricing.price.title_2",
          badge: "companies.pricing.price.title_2",
          tagline: "companies.pricing.price.sub-title_2",
          descr: [
            "companies.pricing.price.description_2_point_1",
            "companies.pricing.price.description_2_point_2",
            "companies.pricing.price.description_2_point_3",
            "companies.pricing.price.description_2_point_4",
            "companies.pricing.price.description_2_point_5",
            "companies.pricing.price.description_2_point_6"
          ],
          buttonText: "companies.pricing.price.button_2"
        },
        {
          selectedProductType: 2,
          type: "pro",
          title: "companies.pricing.price.title_3",
          badge: "companies.pricing.price.title_3",
          tagline: "companies.pricing.price.sub-title_3",
          descr: [
            "companies.pricing.price.description_3_point_1",
            "companies.pricing.price.description_3_point_2",
            "companies.pricing.price.description_3_point_3",
            "companies.pricing.price.description_3_point_4",
            "companies.pricing.price.description_3_point_5"
          ],
          buttonText: "companies.pricing.price.button_3"
        }
      ]
    },
    pricing: {
      title: "Fuzu features and plans",
      subtitle:
        "Small business, growth company, large enterprise or NGO - we have a solution for you <b>Pay for 10 months and get 2 free with annual payment</b>",
      pricingPlans: [
        {
          type: "starter",
          title: "pricing.price.starter.title",
          badge: "pricing.price.starter.badge",
          tagline: "pricing.price.starter.sub-title",
          descr: [
            "pricing.price.starter.description_1",
            "pricing.price.starter.description_2",
            "pricing.price.starter.description_3",
            "pricing.price.starter.description_4",
            "pricing.price.starter.description_5"
          ],
          prices: {
            NG: {
              value: "30,000 NGN",
              per: "pricing.per_job_post"
            },
            KE: { value: "6,000 KES", per: "pricing.per_job_post" },
            UG: { value: "150,000 UGX", per: "pricing.per_job_post" },
            global: { value: "60 USD", per: "pricing.per_job_post" }
          },
          details: {
            title: "pricing.price.starter.details_title",
            tagline: "pricing.fuzu_top_features",
            NG: {
              descr: [
                "pricing.details.talent_pool_description",
                "pricing.details.fuzu_analyses_description",
                "pricing.details.fuzu_exclusive_description",
                "pricing.details.filters_to_help_description",
                "pricing.details.link_fuzu_description",
                "pricing.details.world_class_description",
                "pricing.details.automatically_share_description",
                "pricing.details.automated_candidate_description",
                "pricing.details.aptitude_tests_description",
                "pricing.details.skill_tests_description",
                "pricing.details.based_personality_description",
                "pricing.details.automated_headhunting_description",
                "pricing.details.drive_traffic_description",
                "pricing.details.advanced_dedicated_account",
                "pricing.details.employer_brand_description",
                "pricing.details.advanced_headhunting_description",
                "pricing.details.advanced_collaboration_description"
              ],
              pricings: [
                {
                  title: "pricing.price.starter.title",
                  name: "Starter",
                  popular: false,
                  tagline: "pricing.price.starter.tag_line",
                  prices: { value: "30,000 NGN", per: "pricing.job_post", discount: "", removed: true },
                  terms: [
                    {
                      name: "job",
                      label: {
                        bold: "pricing.1_active_job",
                        ordinary: "pricing.post"
                      }
                    },
                    {
                      name: "cv",
                      label: {
                        bold: "pricing.unlimited",
                        ordinary: "pricing.cv_views"
                      }
                    },
                    {
                      name: "contact",
                      label: {
                        bold: "pricing.20_contact_details",
                        ordinary: "pricing.month"
                      }
                    }
                  ],
                  actives: [
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false
                  ]
                },
                {
                  title: "pricing.starter_gold",
                  name: "Starter Gold",
                  popular: true,
                  tagline: "pricing.the_most_advanced",
                  prices: {
                    value: "60,000 NGN",
                    per: "pricing.job_post",
                    removed: false
                  },
                  terms: [
                    {
                      name: "job",
                      label: {
                        bold: "pricing.1_active_job",
                        ordinary: "pricing.post"
                      }
                    },
                    {
                      name: "cv",
                      label: {
                        bold: "pricing.unlimited",
                        ordinary: "pricing.cv_views"
                      }
                    },
                    {
                      name: "contact",
                      label: {
                        bold: "pricing.50_contact_details",
                        ordinary: "pricing.month"
                      }
                    }
                  ],
                  actives: [
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    false,
                    false,
                    false,
                    false,
                    false
                  ]
                },
                {
                  title: "Starter Gold Unlimited",
                  name: "Starter Gold Unlimited",
                  popular: true,
                  tag: "pricing.tag_new",
                  tagline: "pricing.starter_gold_unlimited_tagline",
                  prices: { value: "80,000 NGN", per: "pricing.job_post" },
                  terms: [
                    {
                      name: "job",
                      label: {
                        bold: "pricing.1_active_job",
                        ordinary: "pricing.post"
                      }
                    },
                    {
                      name: "cv",
                      label: {
                        bold: "pricing.unlimited",
                        ordinary: "pricing.cv_views"
                      }
                    },
                    {
                      name: "contact",
                      label: {
                        bold: "pricing.50_contact_details",
                        ordinary: "pricing.month"
                      }
                    }
                  ],
                  actives: [
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    false,
                    false,
                    false,
                    false
                  ]
                },
                {
                  title: "Starter Gold Best Match",
                  name: "Starter Gold Best Match",
                  popular: true,
                  tag: "pricing.tag_new",
                  tagline: "pricing.starter_gold_best_match_tagline",
                  prices: { value: "200,000 NGN", per: "pricing.per_role" },
                  terms: [
                    {
                      name: "job",
                      label: {
                        bold: "pricing.1_active_job",
                        ordinary: "pricing.post"
                      }
                    },
                    {
                      name: "cv",
                      label: {
                        bold: "pricing.unlimited",
                        ordinary: "pricing.cv_views"
                      }
                    },
                    {
                      name: "contact",
                      label: {
                        bold: "pricing.50_contact_details",
                        ordinary: "pricing.month"
                      }
                    }
                  ],
                  actives: [
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    false,
                    false,
                    false
                  ]
                }
              ]
            },
            KE: {
              descr: [
                "pricing.details.talent_pool_description",
                "pricing.details.fuzu_analyses_description",
                "pricing.details.fuzu_exclusive_description",
                "pricing.details.filters_to_help_description",
                "pricing.details.link_fuzu_description",
                "pricing.details.world_class_description",
                "pricing.details.automatically_share_description",
                "pricing.details.automated_candidate_description",
                "pricing.details.aptitude_tests_description",
                "pricing.details.skill_tests_description",
                "pricing.details.based_personality_description",
                "pricing.details.automated_headhunting_description",
                "pricing.details.drive_traffic_description",
                "pricing.details.advanced_dedicated_account",
                "pricing.details.employer_brand_description",
                "pricing.details.advanced_headhunting_description",
                "pricing.details.advanced_collaboration_description"
              ],
              pricings: [
                {
                  title: "pricing.price.starter.title",
                  name: "Starter",
                  popular: false,
                  tagline: "pricing.price.starter.tag_line",
                  prices: { value: "6,000 KES", per: "pricing.job_post", discount: "", removed: true },
                  terms: [
                    {
                      name: "job",
                      label: {
                        bold: "pricing.1_active_job",
                        ordinary: "pricing.post"
                      }
                    },
                    {
                      name: "cv",
                      label: {
                        bold: "pricing.unlimited",
                        ordinary: "pricing.cv_views"
                      }
                    },
                    {
                      name: "contact",
                      label: {
                        bold: "pricing.20_contact_details",
                        ordinary: "pricing.month"
                      }
                    }
                  ],
                  actives: [
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false
                  ]
                },
                {
                  title: "pricing.starter_gold",
                  name: "Starter Gold",
                  popular: true,
                  gap: "Popular",
                  tagline: "pricing.the_most_advanced",
                  prices: { value: "13,000 KES", per: "pricing.job_post" },
                  terms: [
                    {
                      name: "job",
                      label: {
                        bold: "pricing.1_active_job",
                        ordinary: "pricing.post"
                      }
                    },
                    {
                      name: "cv",
                      label: {
                        bold: "pricing.unlimited",
                        ordinary: "pricing.cv_views"
                      }
                    },
                    {
                      name: "contact",
                      label: {
                        bold: "pricing.50_contact_details",
                        ordinary: "pricing.month"
                      }
                    }
                  ],
                  actives: [
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    false,
                    false,
                    false,
                    false,
                    false
                  ]
                },
                {
                  title: "Starter Gold Unlimited",
                  name: "Starter Gold Unlimited",
                  popular: true,
                  tag: "pricing.tag_new",
                  tagline: "pricing.starter_gold_unlimited_tagline",
                  prices: { value: "15,000 KES", per: "pricing.job_post" },
                  terms: [
                    {
                      name: "job",
                      label: {
                        bold: "pricing.1_active_job",
                        ordinary: "pricing.post"
                      }
                    },
                    {
                      name: "cv",
                      label: {
                        bold: "pricing.unlimited",
                        ordinary: "pricing.cv_views"
                      }
                    },
                    {
                      name: "contact",
                      label: {
                        bold: "pricing.50_contact_details",
                        ordinary: "pricing.month"
                      }
                    }
                  ],
                  actives: [
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    false,
                    false,
                    false,
                    false
                  ]
                },
                {
                  title: "Starter Gold Best Match",
                  name: "Starter Gold Best Match",
                  popular: true,
                  tag: "pricing.tag_new",
                  tagline: "pricing.starter_gold_best_match_tagline",
                  prices: { value: "30,000 KES", per: "pricing.job_post" },
                  terms: [
                    {
                      name: "job",
                      label: {
                        bold: "pricing.1_active_job",
                        ordinary: "pricing.post"
                      }
                    },
                    {
                      name: "cv",
                      label: {
                        bold: "pricing.unlimited",
                        ordinary: "pricing.cv_views"
                      }
                    },
                    {
                      name: "contact",
                      label: {
                        bold: "pricing.50_contact_details",
                        ordinary: "pricing.month"
                      }
                    }
                  ],
                  actives: [
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    false,
                    false,
                    false
                  ]
                }
              ]
            },
            UG: {
              descr: [
                "pricing.details.talent_pool_description",
                "pricing.details.fuzu_analyses_description",
                "pricing.details.fuzu_exclusive_description",
                "pricing.details.filters_to_help_description",
                "pricing.details.link_fuzu_description",
                "pricing.details.world_class_description",
                "pricing.details.automatically_share_description",
                "pricing.details.automated_candidate_description",
                "pricing.details.aptitude_tests_description",
                "pricing.details.skill_tests_description",
                "pricing.details.based_personality_description",
                "pricing.details.automated_headhunting_description",
                "pricing.details.drive_traffic_description",
                "pricing.details.advanced_dedicated_account",
                "pricing.details.employer_brand_description",
                "pricing.details.advanced_headhunting_description",
                "pricing.details.advanced_collaboration_description"
              ],
              pricings: [
                {
                  title: "pricing.price.starter.title",
                  name: "Starter",
                  popular: false,
                  tagline: "pricing.price.starter.tag_line",
                  prices: { value: "150,000 UGX", per: "pricing.job_post", discount: "", removed: true },
                  terms: [
                    {
                      name: "job",
                      label: {
                        bold: "pricing.1_active_job",
                        ordinary: "pricing.post"
                      }
                    },
                    {
                      name: "cv",
                      label: {
                        bold: "pricing.unlimited",
                        ordinary: "pricing.cv_views"
                      }
                    },
                    {
                      name: "contact",
                      label: {
                        bold: "pricing.20_contact_details",
                        ordinary: "pricing.month"
                      }
                    }
                  ],
                  actives: [
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false
                  ]
                },
                {
                  title: "pricing.starter_gold",
                  name: "Starter Gold",
                  popular: true,
                  tagline: "pricing.the_most_advanced",
                  prices: {
                    value: "300,000 UGX",
                    per: "pricing.per_job_post",
                    discount: ""
                  },
                  terms: [
                    {
                      name: "job",
                      label: {
                        bold: "pricing.1_active_job",
                        ordinary: "pricing.post"
                      }
                    },
                    {
                      name: "cv",
                      label: {
                        bold: "pricing.unlimited",
                        ordinary: "pricing.cv_views"
                      }
                    },
                    {
                      name: "contact",
                      label: {
                        bold: "pricing.50_contact_details",
                        ordinary: "pricing.month"
                      }
                    }
                  ],
                  actives: [
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    false,
                    false,
                    false,
                    false,
                    false
                  ]
                },
                {
                  title: "Starter Gold Unlimited",
                  name: "Starter Gold Unlimited",
                  popular: true,
                  tag: "pricing.tag_new",
                  tagline: "pricing.starter_gold_unlimited_tagline",
                  prices: { value: "350,000 UGX", per: "pricing.job_post" },
                  terms: [
                    {
                      name: "job",
                      label: {
                        bold: "pricing.1_active_job",
                        ordinary: "pricing.post"
                      }
                    },
                    {
                      name: "cv",
                      label: {
                        bold: "pricing.unlimited",
                        ordinary: "pricing.cv_views"
                      }
                    },
                    {
                      name: "contact",
                      label: {
                        bold: "pricing.50_contact_details",
                        ordinary: "pricing.month"
                      }
                    }
                  ],
                  actives: [
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    false,
                    false,
                    false,
                    false
                  ]
                },
                {
                  title: "Starter Gold Best Match",
                  name: "Starter Gold Best Match",
                  popular: true,
                  tag: "pricing.tag_new",
                  tagline: "pricing.starter_gold_best_match_tagline",
                  prices: { value: "750,000 UGX", per: "pricing.job_post" },
                  terms: [
                    {
                      name: "job",
                      label: {
                        bold: "pricing.1_active_job",
                        ordinary: "pricing.post"
                      }
                    },
                    {
                      name: "cv",
                      label: {
                        bold: "pricing.unlimited",
                        ordinary: "pricing.cv_views"
                      }
                    },
                    {
                      name: "contact",
                      label: {
                        bold: "pricing.50_contact_details",
                        ordinary: "pricing.month"
                      }
                    }
                  ],
                  actives: [
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    false,
                    false,
                    false
                  ]
                }
              ]
            },
            global: {
              descr: [
                "pricing.details.talent_pool_description",
                "pricing.details.fuzu_analyses_description",
                "pricing.details.fuzu_exclusive_description",
                "pricing.details.filters_to_help_description",
                "pricing.details.link_fuzu_description",
                "pricing.details.world_class_description",
                "pricing.details.automatically_share_description",
                "pricing.details.automated_candidate_description",
                "pricing.details.aptitude_tests_description",
                "pricing.details.skill_tests_description",
                "pricing.details.based_personality_description",
                "pricing.details.automated_headhunting_description",
                "pricing.details.drive_traffic_description",
                "pricing.details.advanced_dedicated_account",
                "pricing.details.employer_brand_description",
                "pricing.details.advanced_headhunting_description",
                "pricing.details.advanced_collaboration_description"
              ],
              pricings: [
                {
                  title: "pricing.price.starter.title",
                  name: "Starter",
                  popular: false,
                  tagline: "pricing.price.starter.tag_line",
                  prices: { value: "60 USD", per: "pricing.job_post" },
                  terms: [
                    {
                      name: "job",
                      label: {
                        bold: "pricing.1_active_job",
                        ordinary: "pricing.post"
                      }
                    },
                    {
                      name: "cv",
                      label: {
                        bold: "pricing.unlimited",
                        ordinary: "pricing.cv_views"
                      }
                    },
                    {
                      name: "contact",
                      label: {
                        bold: "pricing.20_contact_details",
                        ordinary: "pricing.month"
                      }
                    }
                  ],
                  actives: [
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false
                  ]
                },
                {
                  title: "pricing.starter_gold",
                  name: "Starter Gold",
                  tag: "Popular",
                  popular: true,
                  tagline: "pricing.the_most_advanced",
                  prices: { value: "130 USD", per: "pricing.job_post" },
                  terms: [
                    {
                      name: "job",
                      label: {
                        bold: "pricing.1_active_job",
                        ordinary: "pricing.post"
                      }
                    },
                    {
                      name: "cv",
                      label: {
                        bold: "pricing.unlimited",
                        ordinary: "pricing.cv_views"
                      }
                    },
                    {
                      name: "contact",
                      label: {
                        bold: "pricing.50_contact_details",
                        ordinary: "pricing.month"
                      }
                    }
                  ],
                  actives: [
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    false,
                    false,
                    false,
                    false,
                    false
                  ]
                },
                {
                  title: "Starter Gold Unlimited",
                  name: "Starter Gold Unlimited",
                  popular: true,
                  tag: "pricing.tag_new",
                  tagline: "pricing.starter_gold_unlimited_tagline",
                  prices: { value: "150 USD", per: "pricing.job_post" },
                  terms: [
                    {
                      name: "job",
                      label: {
                        bold: "pricing.1_active_job",
                        ordinary: "pricing.post"
                      }
                    },
                    {
                      name: "cv",
                      label: {
                        bold: "pricing.unlimited",
                        ordinary: "pricing.cv_views"
                      }
                    },
                    {
                      name: "contact",
                      label: {
                        bold: "pricing.50_contact_details",
                        ordinary: "pricing.month"
                      }
                    }
                  ],
                  actives: [
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    false,
                    false,
                    false,
                    false
                  ]
                },
                {
                  title: "Starter Gold Best Match",
                  name: "Starter Gold Best Match",
                  popular: true,
                  tag: "pricing.tag_new",
                  tagline: "pricing.starter_gold_best_match_tagline",
                  prices: { value: "300 USD", per: "pricing.job_post" },
                  terms: [
                    {
                      name: "job",
                      label: {
                        bold: "pricing.1_active_job",
                        ordinary: "pricing.post"
                      }
                    },
                    {
                      name: "cv",
                      label: {
                        bold: "pricing.unlimited",
                        ordinary: "pricing.cv_views"
                      }
                    },
                    {
                      name: "contact",
                      label: {
                        bold: "pricing.50_contact_details",
                        ordinary: "pricing.month"
                      }
                    }
                  ],
                  actives: [
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    false,
                    false,
                    false
                  ]
                }
              ]
            }
          },
          terms: []
        },
        {
          type: "pro",
          title: "pricing.price.pro.title",
          badge: "pricing.price.pro.badge",
          tagline: "pricing.price.pro.sub-title",
          descr: [
            "pricing.price.pro.description_1",
            "pricing.price.pro.description_2",
            "pricing.price.pro.description_3",
            "pricing.price.pro.description_4",
            "pricing.price.pro.description_5"
          ],
          prices: {
            NG: {
              value: "110,000 NGN",
              per: "pricing.per_month"
            },
            KE: { value: "35,000 KES", per: "pricing.per_month" },
            UG: { value: "800,000 UGX", per: "pricing.per_month" },
            global: { value: "300 USD", per: "pricing.per_month" }
          },
          details: {
            title: "pricing.price.pro.details_title",
            tagline: "pricing.price.pro.tag_line",
            NG: {
              descr: [
                "pricing.details.talent_pool_description",
                "pricing.details.fuzu_analyses_description",
                "pricing.details.fuzu_exclusive_description",
                "pricing.details.filters_to_help_description",
                "pricing.details.link_fuzu_description",
                "pricing.details.world_class_description",
                "pricing.details.automatically_share_description",
                "pricing.details.automated_candidate_description",
                "pricing.details.aptitude_tests_description",
                "pricing.details.skill_tests_description",
                "pricing.details.based_personality_description",
                "pricing.details.automated_headhunting_description",
                "pricing.details.employer_brand_description",
                "pricing.details.advanced_headhunting_description",
                "pricing.details.drive_traffic_description",
                "pricing.details.hire_across_description",
                "pricing.details.multi_country_description",
                "pricing.details.help_with_job_description"
              ],
              pricings: [
                {
                  title: "pricing.growth",
                  name: "Growth",
                  popular: true,
                  tagline: "pricing.tech_solutions",
                  prices: {
                    value: "110,000 NGN",
                    per: "pricing.month",
                    removed: false
                  },
                  terms: [
                    {
                      name: "job",
                      label: {
                        bold: "pricing.5_active_job",
                        ordinary: "pricing.posts"
                      }
                    },
                    {
                      name: "cv",
                      label: {
                        bold: "pricing.unlimited",
                        ordinary: "pricing.cv_views"
                      }
                    },
                    {
                      name: "contact",
                      label: {
                        bold: "pricing.300_contact_details",
                        ordinary: "pricing.month"
                      }
                    }
                  ],
                  actives: [
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    false,
                    "pricing.at_an_additional_cost",
                    "pricing.at_an_additional_cost",
                    "pricing.at_an_additional_cost"
                  ]
                },
                {
                  title: "pricing.premium",
                  name: "Premium",
                  popular: true,
                  tag: "Best value",
                  tagline: "pricing.build_for_africa",
                  prices: {
                    value: "260,000 NGN",
                    per: "pricing.month",
                    discount: "",
                    removed: false
                  },
                  terms: [
                    {
                      name: "job",
                      label: {
                        bold: "pricing.unlimited",
                        ordinary: "pricing.active_job_posts"
                      }
                    },
                    {
                      name: "cv",
                      label: {
                        bold: "pricing.unlimited",
                        ordinary: "pricing.cv_views"
                      }
                    },
                    {
                      name: "contact",
                      label: {
                        bold: "pricing.1000_contact_details",
                        ordinary: "pricing.month"
                      }
                    }
                  ],
                  actives: [
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    false,
                    "pricing.at_an_additional_cost",
                    "pricing.at_an_additional_cost",
                    "pricing.at_an_additional_cost"
                  ]
                },
                {
                  title: "Premium Unlimited",
                  name: "Premium Unlimited",
                  popular: true,
                  isNotShowPopularText: true,
                  tagline: "pricing.premium_unlimited_tagline",
                  prices: {
                    value: "350,000 NGN",
                    per: "pricing.per_month",
                    discount: "",
                    removed: false
                  },
                  terms: [
                    {
                      name: "job",
                      label: {
                        bold: "pricing.unlimited",
                        ordinary: "pricing.active_job_posts"
                      }
                    },
                    {
                      name: "cv",
                      label: {
                        bold: "pricing.unlimited",
                        ordinary: "pricing.cv_views"
                      }
                    },
                    {
                      name: "contact",
                      label: {
                        bold: "pricing.1000_contact_details",
                        ordinary: "pricing.month"
                      }
                    }
                  ],
                  actives: [
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    "pricing.at_an_additional_cost",
                    "pricing.at_an_additional_cost",
                    "pricing.at_an_additional_cost"
                  ]
                }
              ]
            },
            KE: {
              descr: [
                "pricing.details.talent_pool_description",
                "pricing.details.fuzu_analyses_description",
                "pricing.details.fuzu_exclusive_description",
                "pricing.details.filters_to_help_description",
                "pricing.details.link_fuzu_description",
                "pricing.details.world_class_description",
                "pricing.details.automatically_share_description",
                "pricing.details.automated_candidate_description",
                "pricing.details.aptitude_tests_description",
                "pricing.details.skill_tests_description",
                "pricing.details.based_personality_description",
                "pricing.details.automated_headhunting_description",
                "pricing.details.employer_brand_description",
                "pricing.details.advanced_headhunting_description",
                "pricing.details.advanced_collaboration_description",
                "pricing.details.drive_traffic_description",
                "pricing.details.hire_across_description",
                "pricing.details.multi_country_description",
                "pricing.details.help_with_job_description"
              ],
              pricings: [
                {
                  title: "pricing.growth",
                  name: "Growth",
                  popular: true,
                  tagline: "pricing.tech_solutions",
                  prices: {
                    value: "35,000 KES",
                    per: "pricing.month",
                    discount: "",
                    helperText: "pricing.pay_for_9_months"
                  },
                  terms: [
                    {
                      name: "job",
                      label: {
                        bold: "pricing.5_active_job",
                        ordinary: "pricing.posts"
                      }
                    },
                    {
                      name: "cv",
                      label: {
                        bold: "pricing.unlimited",
                        ordinary: "pricing.cv_views"
                      }
                    },
                    {
                      name: "contact",
                      label: {
                        bold: "pricing.300_contact_details",
                        ordinary: "pricing.month"
                      }
                    }
                  ],
                  actives: [
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    false,
                    "pricing.at_an_additional_cost",
                    "pricing.at_an_additional_cost",
                    "pricing.at_an_additional_cost"
                  ]
                },
                {
                  title: "pricing.premium",
                  name: "Premium",
                  popular: true,
                  tag: "Best value",
                  tagline: "pricing.build_for_africa",
                  prices: {
                    value: "60,000 KES",
                    per: "pricing.month",
                    helperText: "pricing.pay_for_9_months",
                    discount: ""
                  },
                  terms: [
                    {
                      name: "job",
                      label: {
                        bold: "pricing.unlimited",
                        ordinary: "pricing.active_job_posts"
                      }
                    },
                    {
                      name: "cv",
                      label: {
                        bold: "pricing.unlimited",
                        ordinary: "pricing.cv_views"
                      }
                    },
                    {
                      name: "contact",
                      label: {
                        bold: "pricing.1000_contact_details",
                        ordinary: "pricing.month"
                      }
                    }
                  ],
                  actives: [
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    false,
                    "pricing.at_an_additional_cost",
                    "pricing.at_an_additional_cost",
                    "pricing.at_an_additional_cost"
                  ]
                },
                {
                  title: "Premium Unlimited",
                  name: "Premium Unlimited",
                  popular: true,
                  isNotShowPopularText: true,
                  tagline: "pricing.premium_unlimited_tagline",
                  prices: {
                    value: "70,000 KES",
                    per: "pricing.per_month",
                    discount: "",
                    removed: false,
                    helperText: "pricing.pay_for_9_months"
                  },
                  terms: [
                    {
                      name: "job",
                      label: {
                        bold: "pricing.unlimited",
                        ordinary: "pricing.active_job_posts"
                      }
                    },
                    {
                      name: "cv",
                      label: {
                        bold: "pricing.unlimited",
                        ordinary: "pricing.cv_views"
                      }
                    },
                    {
                      name: "contact",
                      label: {
                        bold: "pricing.1000_contact_details",
                        ordinary: "pricing.month"
                      }
                    }
                  ],
                  actives: [
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    "pricing.at_an_additional_cost",
                    "pricing.at_an_additional_cost",
                    "pricing.at_an_additional_cost"
                  ]
                }
              ]
            },
            UG: {
              descr: [
                "pricing.details.talent_pool_description",
                "pricing.details.fuzu_analyses_description",
                "pricing.details.fuzu_exclusive_description",
                "pricing.details.filters_to_help_description",
                "pricing.details.link_fuzu_description",
                "pricing.details.world_class_description",
                "pricing.details.automatically_share_description",
                "pricing.details.automated_candidate_description",
                "pricing.details.aptitude_tests_description",
                "pricing.details.skill_tests_description",
                "pricing.details.based_personality_description",
                "pricing.details.automated_headhunting_description",
                "pricing.details.employer_brand_description",
                "pricing.details.advanced_headhunting_description",
                "pricing.details.advanced_collaboration_description",
                "pricing.details.drive_traffic_description",
                "pricing.details.hire_across_description",
                "pricing.details.multi_country_description",
                "pricing.details.help_with_job_description"
              ],
              pricings: [
                {
                  title: "pricing.growth",
                  name: "Growth",
                  popular: true,
                  tagline: "pricing.tech_solutions",
                  prices: {
                    value: "800,000 UGX",
                    per: "pricing.per_month",
                    discount: "",
                    helperText: "pricing.pay_for_9_months_and_get_free_1"
                  },
                  terms: [
                    {
                      name: "job",
                      label: {
                        bold: "pricing.5_active_job",
                        ordinary: "pricing.posts"
                      }
                    },
                    {
                      name: "cv",
                      label: {
                        bold: "pricing.unlimited",
                        ordinary: "pricing.cv_views"
                      }
                    },
                    {
                      name: "contact",
                      label: {
                        bold: "pricing.300_contact_details",
                        ordinary: "pricing.month"
                      }
                    }
                  ],
                  actives: [
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    false,
                    "pricing.at_an_additional_cost",
                    "pricing.at_an_additional_cost",
                    "pricing.at_an_additional_cost"
                  ]
                },
                {
                  title: "pricing.premium",
                  name: "Premium",
                  popular: true,
                  tag: "Best value",
                  tagline: "pricing.build_for_africa",
                  prices: {
                    value: "1.25M UGX",
                    per: "pricing.per_month",
                    discount: "",
                    helperText: "pricing.contact_us_and_get_3_free"
                  },
                  terms: [
                    {
                      name: "job",
                      label: {
                        bold: "pricing.unlimited",
                        ordinary: "pricing.active_job_posts"
                      }
                    },
                    {
                      name: "cv",
                      label: {
                        bold: "pricing.unlimited",
                        ordinary: "pricing.cv_views"
                      }
                    },
                    {
                      name: "contact",
                      label: {
                        bold: "pricing.1000_contact_details",
                        ordinary: "pricing.month"
                      }
                    }
                  ],
                  actives: [
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    false,
                    "pricing.at_an_additional_cost",
                    "pricing.at_an_additional_cost",
                    "pricing.at_an_additional_cost"
                  ]
                },
                {
                  title: "Premium Unlimited",
                  name: "Premium Unlimited",
                  popular: true,
                  isNotShowPopularText: true,
                  tagline: "pricing.premium_unlimited_tagline",
                  prices: {
                    value: "1.4M UGX",
                    per: "pricing.per_month",
                    discount: "",
                    helperText: "pricing.contact_us_and_get_3_free",
                    removed: false
                  },
                  terms: [
                    {
                      name: "job",
                      label: {
                        bold: "pricing.unlimited",
                        ordinary: "pricing.active_job_posts"
                      }
                    },
                    {
                      name: "cv",
                      label: {
                        bold: "pricing.unlimited",
                        ordinary: "pricing.cv_views"
                      }
                    },
                    {
                      name: "contact",
                      label: {
                        bold: "pricing.1000_contact_details",
                        ordinary: "pricing.month"
                      }
                    }
                  ],
                  actives: [
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    "pricing.at_an_additional_cost",
                    "pricing.at_an_additional_cost",
                    "pricing.at_an_additional_cost"
                  ]
                }
              ]
            },
            global: {
              descr: [
                "pricing.details.talent_pool_description",
                "pricing.details.fuzu_analyses_description",
                "pricing.details.fuzu_exclusive_description",
                "pricing.details.filters_to_help_description",
                "pricing.details.link_fuzu_description",
                "pricing.details.world_class_description",
                "pricing.details.automatically_share_description",
                "pricing.details.automated_candidate_description",
                "pricing.details.aptitude_tests_description",
                "pricing.details.skill_tests_description",
                "pricing.details.based_personality_description",
                "pricing.details.automated_headhunting_description",
                "pricing.details.employer_brand_description",
                "pricing.details.advanced_headhunting_description",
                "pricing.details.advanced_collaboration_description",
                "pricing.details.drive_traffic_description",
                "pricing.details.hire_across_description",
                "pricing.details.multi_country_description",
                "pricing.details.help_with_job_description"
              ],
              pricings: [
                {
                  title: "pricing.growth",
                  name: "Growth",
                  popular: true,
                  tagline: "pricing.tech_solutions",
                  prices: {
                    value: "300 USD",
                    per: "pricing.month",
                    discount: "",
                    helperText: "pricing.pay_for_9_months"
                  },
                  terms: [
                    {
                      name: "job",
                      label: {
                        bold: "pricing.5_active_job",
                        ordinary: "pricing.posts"
                      }
                    },
                    {
                      name: "cv",
                      label: {
                        bold: "pricing.unlimited",
                        ordinary: "pricing.cv_views"
                      }
                    },
                    {
                      name: "contact",
                      label: {
                        bold: "pricing.300_contact_details",
                        ordinary: "pricing.month"
                      }
                    }
                  ],
                  actives: [
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    false,
                    "pricing.at_an_additional_cost",
                    "pricing.at_an_additional_cost",
                    "pricing.at_an_additional_cost"
                  ]
                },
                {
                  title: "pricing.premium",
                  name: "Premium",
                  popular: true,
                  tag: "Best value",
                  tagline: "pricing.build_for_africa",
                  prices: {
                    value: "500 USD",
                    per: "pricing.month",
                    discount: "",
                    helperText: "pricing.pay_for_9_months"
                  },
                  terms: [
                    {
                      name: "job",
                      label: {
                        bold: "pricing.unlimited",
                        ordinary: "pricing.active_job_posts"
                      }
                    },
                    {
                      name: "cv",
                      label: {
                        bold: "pricing.unlimited",
                        ordinary: "pricing.cv_views"
                      }
                    },
                    {
                      name: "contact",
                      label: {
                        bold: "pricing.1000_contact_details",
                        ordinary: "pricing.month"
                      }
                    }
                  ],
                  actives: [
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    false,
                    "pricing.at_an_additional_cost",
                    "pricing.at_an_additional_cost",
                    "pricing.at_an_additional_cost"
                  ]
                },
                {
                  title: "Premium Unlimited",
                  name: "Premium Unlimited",
                  popular: true,
                  isNotShowPopularText: true,
                  tagline: "pricing.premium_unlimited_tagline",
                  prices: {
                    value: "600 USD",
                    per: "pricing.per_month",
                    helperText: "pricing.pay_for_9_months",
                    discount: "",
                    removed: false
                  },
                  terms: [
                    {
                      name: "job",
                      label: {
                        bold: "pricing.unlimited",
                        ordinary: "pricing.active_job_posts"
                      }
                    },
                    {
                      name: "cv",
                      label: {
                        bold: "pricing.unlimited",
                        ordinary: "pricing.cv_views"
                      }
                    },
                    {
                      name: "contact",
                      label: {
                        bold: "pricing.1000_contact_details",
                        ordinary: "pricing.month"
                      }
                    }
                  ],
                  actives: [
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    "pricing.at_an_additional_cost",
                    "pricing.at_an_additional_cost",
                    "pricing.at_an_additional_cost"
                  ]
                }
              ]
            }
          },
          terms: []
        },
        {
          type: "elite",
          title: "pricing.price.elite.title",
          badge: "pricing.price.elite.badge",
          tagline: "pricing.price.elite.sub-title",
          descr: [
            "pricing.price.elite.description_1",
            "pricing.price.elite.description_2",
            "pricing.price.elite.description_3",
            "pricing.price.elite.description_4",
            "pricing.price.elite.description_5"
          ],
          prices: {
            NG: { value: "540,000 NGN", per: "pricing.per_hire" },
            KE: { value: "70,000 KES", per: "pricing.per_hire" },
            UG: { value: "1.6 million UGX", per: "pricing.per_hire" },
            global: { value: "600 USD", per: "pricing.per_hire" }
          },
          details: {
            NG: {
              descr: {
                title: "pricing.elite_details.title",
                tagline: "pricing.elite_details.tagline",
                disclaimer: "First deal discounts apply!",
                section: [
                  {
                    title: "pricing.elite_details.fastest_growing",
                    taglines: ["pricing.elite_details.instant_access", "pricing.elite_details.over_candidates"]
                  },
                  {
                    title: "pricing.elite_details.leading_recruitment",
                    taglines: [
                      "pricing.elite_details.embedded_psychometric",
                      "pricing.elite_details.seamless_collaboration"
                    ]
                  },
                  {
                    title: "pricing.elite_details.internationally_experienced",
                    taglines: ["pricing.elite_details.ground_experts", "pricing.elite_details.experience_recruiting"]
                  }
                ],
                eliteSection: {
                  title: "pricing.elite_details.elite",
                  tagline: "pricing.elite_details.section.tagline",
                  descr: [
                    "pricing.elite_details.section.vetted_and_interviewed",
                    "pricing.elite_details.section.visibility_at_each",
                    "pricing.elite_details.section.africa_leading",
                    "pricing.elite_details.section.all_the_power",
                    "pricing.elite_details.section.results_guaranteed"
                  ]
                },
                levelSection: [
                  {
                    title: "pricing.elite_details.level.hire_lead",
                    tagline: "pricing.elite_details.level.hire_lead_tagline_nigeria",
                    value: "75,000 NGN + Success Fee",
                    additional: "pricing.elite_details.commitment_10_percent",
                    buttonText: "pricing.elite_details.level.hire_lead_button"
                  },
                  {
                    title: "pricing.elite_details.level.tech_hires",
                    tagline: "pricing.elite_details.level.tech_hires_tagline_nigeria",
                    value: "75,000 NGN + Success Fee",
                    additional: "pricing.elite_details.commitment_10_percent",
                    buttonText: "pricing.elite_details.level.tech_hires_button"
                  },
                  {
                    title: "pricing.elite_details.level.executive_search",
                    tagline: "pricing.elite_details.level.executive_search_tagline_nigeria",
                    value: "100,000 NGN + Success Fee",
                    additional: "pricing.elite_details.commitment_13_percent",
                    buttonText: "pricing.elite_details.level.executive_search_button"
                  }
                ]
              },
              pricings: [
                { name: "pricing.elite_details.level.hire_junior_button", value: "169,900 NGN", additional: "" },
                { name: "pricing.elite_details.level.hire_mid_level_button", value: "269,900 NGN", additional: "" },
                {
                  name: "pricing.elite_details.level.hire_lead_button",
                  value: "pricing.elite_details.level.tech_hires_value_3"
                  // additional: "+NGN 59K"
                },
                {
                  name: "pricing.elite_details.level.executive_search_button",
                  value: "pricing.elite_details.level.executive_search_value_3"
                  // additional: "+NGN 99K"
                }
              ]
            },
            KE: {
              descr: {
                title: "pricing.elite_details.title",
                tagline: "pricing.elite_details.tagline",
                section: [
                  {
                    title: "pricing.elite_details.fastest_growing",
                    taglines: ["pricing.elite_details.instant_access", "pricing.elite_details.over_candidates"]
                  },
                  {
                    title: "pricing.elite_details.leading_recruitment",
                    taglines: [
                      "pricing.elite_details.embedded_psychometric",
                      "pricing.elite_details.seamless_collaboration"
                    ]
                  },
                  {
                    title: "pricing.elite_details.internationally_experienced",
                    taglines: ["pricing.elite_details.ground_experts", "pricing.elite_details.experience_recruiting"]
                  }
                ],
                eliteSection: {
                  title: "pricing.elite_details.elite",
                  tagline: "pricing.elite_details.section.tagline",
                  descr: [
                    "pricing.elite_details.section.vetted_and_interviewed",
                    "pricing.elite_details.section.visibility_at_each",
                    "pricing.elite_details.section.africa_leading",
                    "pricing.elite_details.section.all_the_power",
                    "pricing.elite_details.section.results_guaranteed"
                  ]
                },
                levelSection: [
                  {
                    title: "pricing.elite_details.level.junior_professional",
                    tagline: "pricing.elite_details.level.junior_professional_tagline_kenya",
                    value: "KES 70,000",
                    additional: "pricing.elite_details.service_fee",
                    buttonText: "pricing.elite_details.level.hire_junior_button"
                  },
                  {
                    title: "pricing.elite_details.level.hire_lead",
                    tagline: "pricing.elite_details.level.hire_lead_tagline_kenya",
                    value: "30,000 KES + Success Fee",
                    additional: "pricing.elite_details.community_fee_1_month",
                    buttonText: "pricing.elite_details.level.hire_lead_button"
                  },
                  {
                    title: "pricing.elite_details.level.tech_hires",
                    tagline: "pricing.elite_details.level.tech_hires_tagline_kenya",
                    value: "40,000 KES + Success Fee",
                    additional: "pricing.elite_details.community_fee_1_25_month",
                    buttonText: "pricing.elite_details.level.tech_hires_button"
                  },
                  {
                    title: "pricing.elite_details.level.executive_search",
                    tagline: "pricing.elite_details.level.executive_search_tagline_kenya",
                    value: "60,000 KES + Success Fee",
                    additional: "pricing.elite_details.community_fee_1_5_month",
                    buttonText: "pricing.elite_details.level.executive_search_button"
                  }
                ]
              },
              pricings: [
                { name: "pricing.elite_details.level.hire_junior_button", value: "39,900 KES", additional: "" },
                { name: "pricing.elite_details.level.hire_mid_level_button", value: "54,900 KES", additional: "" },
                {
                  name: "pricing.elite_details.level.hire_lead_button",
                  value: "pricing.elite_details.level.hire_lead_value"
                  // additional: "+KES 15K"
                },
                {
                  name: "pricing.elite_details.level.executive_search_button",
                  value: "pricing.elite_details.level.executive_search_value"
                  // additional: "+KES 20K"
                }
              ]
            },
            UG: {
              descr: {
                title: "pricing.elite_details.title",
                tagline: "pricing.elite_details.tagline",
                section: [
                  {
                    title: "pricing.elite_details.fastest_growing",
                    taglines: ["pricing.elite_details.instant_access", "pricing.elite_details.over_candidates"]
                  },
                  {
                    title: "pricing.elite_details.leading_recruitment",
                    taglines: [
                      "pricing.elite_details.embedded_psychometric",
                      "pricing.elite_details.seamless_collaboration"
                    ]
                  },
                  {
                    title: "pricing.elite_details.internationally_experienced",
                    taglines: ["pricing.elite_details.ground_experts", "pricing.elite_details.experience_recruiting"]
                  }
                ],
                eliteSection: {
                  title: "pricing.elite_details.elite",
                  tagline: "pricing.elite_details.section.tagline",
                  descr: [
                    "pricing.elite_details.section.vetted_and_interviewed",
                    "pricing.elite_details.section.visibility_at_each",
                    "pricing.elite_details.section.africa_leading",
                    "pricing.elite_details.section.all_the_power",
                    "pricing.elite_details.section.results_guaranteed"
                  ]
                },
                levelSection: [
                  {
                    title: "pricing.elite_details.level.junior_professional",
                    tagline: "pricing.elite_details.level.junior_professional_tagline_uganda",
                    value: "1.6 million UGX",
                    additional: "pricing.elite_details.service_fee",
                    buttonText: "pricing.elite_details.level.hire_junior_button"
                  },
                  {
                    title: "pricing.elite_details.level.hire_lead",
                    tagline: "pricing.elite_details.level.hire_lead_tagline_uganda",
                    value: "700,000 UGX + Success Fee",
                    additional: "pricing.elite_details.community_fee_1_month",
                    buttonText: "pricing.elite_details.level.hire_lead_button"
                  },
                  {
                    title: "pricing.elite_details.level.tech_hires",
                    tagline: "pricing.elite_details.level.tech_hires_tagline_uganda",
                    value: "800,000 UGX + Success Fee",
                    additional: "pricing.elite_details.community_fee_1_25_month",
                    buttonText: "pricing.elite_details.level.tech_hires_button"
                  },
                  {
                    title: "pricing.elite_details.level.executive_search",
                    tagline: "pricing.elite_details.level.executive_search_tagline_uganda",
                    value: "1 million UGX + Success Fee",
                    additional: "pricing.elite_details.community_fee_1_5_month",
                    buttonText: "pricing.elite_details.level.executive_search_button"
                  }
                ]
              },
              pricings: [
                { name: "pricing.elite_details.level.hire_junior_button", value: "1,200,000 UGX", additional: "" },
                { name: "pricing.elite_details.level.hire_mid_level_button", value: "1,850,000 UGX", additional: "" },
                {
                  name: "pricing.elite_details.level.hire_lead_button",
                  value: "pricing.elite_details.level.hire_lead_value"
                  // additional: "+ UGX 350K"
                },
                {
                  name: "pricing.elite_details.level.executive_search_button",
                  value: "pricing.elite_details.level.executive_search_value"
                  // additional: "+ UGX 500K"
                }
              ]
            },
            global: {
              descr: {
                title: "pricing.elite_details.title",
                tagline: "pricing.elite_details.tagline",
                section: [
                  {
                    title: "pricing.elite_details.fastest_growing",
                    taglines: ["pricing.elite_details.instant_access", "pricing.elite_details.over_candidates"]
                  },
                  {
                    title: "pricing.elite_details.leading_recruitment",
                    taglines: [
                      "pricing.elite_details.embedded_psychometric",
                      "pricing.elite_details.seamless_collaboration"
                    ]
                  },
                  {
                    title: "pricing.elite_details.internationally_experienced",
                    taglines: ["pricing.elite_details.ground_experts", "pricing.elite_details.experience_recruiting"]
                  }
                ],
                eliteSection: {
                  title: "pricing.elite_details.elite",
                  tagline: "pricing.elite_details.section.tagline",
                  descr: [
                    "pricing.elite_details.section.vetted_and_interviewed",
                    "pricing.elite_details.section.visibility_at_each",
                    "pricing.elite_details.section.africa_leading",
                    "pricing.elite_details.section.all_the_power",
                    "pricing.elite_details.section.results_guaranteed"
                  ]
                },
                levelSection: [
                  {
                    title: "pricing.elite_details.level.junior_professional",
                    tagline: "pricing.elite_details.level.junior_professional_tagline_global",
                    value: "600 USD",
                    additional: "pricing.elite_details.service_fee",
                    buttonText: "pricing.elite_details.level.hire_junior_button"
                  },
                  {
                    title: "pricing.elite_details.level.hire_lead",
                    tagline: "pricing.elite_details.level.hire_lead_tagline_global",
                    value: "250 USD + Success Fee",
                    additional: "pricing.elite_details.community_fee_1_month",
                    buttonText: "pricing.elite_details.level.hire_lead_button"
                  },
                  {
                    title: "pricing.elite_details.level.tech_hires",
                    tagline: "pricing.elite_details.level.tech_hires_tagline_global",
                    value: "350 USD + Success Fee",
                    additional: "pricing.elite_details.community_fee_1_25_month",
                    buttonText: "pricing.elite_details.level.tech_hires_button"
                  },
                  {
                    title: "pricing.elite_details.level.executive_search",
                    tagline: "pricing.elite_details.level.executive_search_tagline_global",
                    value: "500 USD + Success Fee",
                    additional: "pricing.elite_details.community_fee_1_5_month",
                    buttonText: "pricing.elite_details.level.executive_search_button"
                  }
                ]
              },
              pricings: [
                { name: "pricing.elite_details.level.hire_junior_button", value: "400 USD", additional: "" },
                { name: "pricing.elite_details.level.hire_mid_level_button", value: "550 USD", additional: "" },
                {
                  name: "pricing.elite_details.level.hire_lead_button",
                  value: "pricing.elite_details.level.hire_lead_value"
                  // additional: "+USD 150"
                },
                {
                  name: "pricing.elite_details.level.executive_search_button",
                  value: "pricing.elite_details.level.executive_search_value"
                  // additional: "+USD 200"
                }
              ]
            }
          },
          terms: []
        }
      ]
    },
    howItWorks: {
      title: "employers.how_it_works.title",
      slides: [
        {
          id: 1,
          title: "employers.how_it_works.sub-title_1",
          text: "employers.how_it_works.description_1",
          videoUrl: infoSlideImage1
        },
        {
          id: 2,
          title: "employers.how_it_works.sub-title_2",
          text: "employers.how_it_works.description_2",
          videoUrl: infoSlideImage3
        },
        {
          id: 3,
          title: "employers.how_it_works.sub-title_3",
          text: "employers.how_it_works.description_3",
          videoUrl: infoSlideImage2
        }
      ]
    },
    whatFuzuOffers: {
      title: "employers.offers.title",
      slides: {
        fuzu: [
          {
            id: 1,
            title: "employers.offers.sub-title_1",
            text: "employers.offers.description_1",
            imageUrl: howItWorksB2B_1,
            smImageHeight: 308,
            smImageWidth: 320,
            imageHeight: 486,
            imageWidth: 506,
            direction: ""
          },
          {
            id: 2,
            title: "employers.offers.sub-title_2",
            text: "employers.offers.description_2",
            imageUrl: howItWorksB2B_2,
            smImageHeight: 265,
            smImageWidth: 320,
            imageHeight: 424,
            imageWidth: 513,
            direction: "reverse"
          },
          {
            id: 3,
            title: "employers.offers.sub-title_3",
            text: "employers.offers.description_3",
            imageUrl: howItWorksB2B_3,
            smImageHeight: 308,
            smImageWidth: 320,
            imageHeight: 486,
            imageWidth: 506,
            direction: ""
          },
          {
            id: 4,
            title: "employers.offers.sub-title_4",
            text: "employers.offers.description_4",
            imageUrl: howItWorksB2B_4,
            smImageHeight: 296,
            smImageWidth: 320,
            imageHeight: 400,
            imageWidth: 430,
            direction: "reverse"
          }
        ]
      }
    }
  }
};
