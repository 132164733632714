import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "b2c/hooks/useTranslation";

import InfoSection from "b2c/components/InfoSection";
import { withTrackedRoute } from "../../hocs";
import Layout from "../../components/Layout";
import { Title, Text } from "../../components/Core";
import { useDocumentTitle } from "../../../utils/hooks";
import { getCompanyName } from "../../contexts/branded";
import { useIsBarona } from "../../hooks";

const TermsScreen = () => {
  const { t } = useTranslation();
  const isBarona = useIsBarona();

  const fuzuTerms = [
    {
      title: t("terms_fuzu.list.sub-title_1"),
      text: t("terms_fuzu.list.description_1")
    },
    {
      title: t("terms_fuzu.list.sub-title_2"),
      text: t("terms_fuzu.list.description_2")
    },
    {
      title: t("terms_fuzu.list.sub-title_3"),
      text: t("terms_fuzu.list.description_3")
    },
    {
      title: t("terms_fuzu.list.sub-title_4"),
      text: t("terms_fuzu.list.description_4")
    },
    {
      title: t("terms_fuzu.list.sub-title_5"),
      text: t("terms_fuzu.list.description_5")
    },
    {
      title: t("terms_fuzu.list.sub-title_6"),
      text: t("terms_fuzu.list.description_6")
    },
    {
      title: t("terms_fuzu.list.sub-title_7"),
      text: t("terms_fuzu.list.description_7")
    },
    {
      title: t("terms_fuzu.list.sub-title_8"),
      text: t("terms_fuzu.list.description_8")
    },
    {
      title: t("terms_fuzu.list.sub-title_9"),
      text: t("terms_fuzu.list.description_9")
    },
    {
      title: t("terms_fuzu.list.sub-title_10"),
      text: t("terms_fuzu.list.description_10")
    },
    {
      title: t("terms_fuzu.list.sub-title_11"),
      text: t("terms_fuzu.list.description_11")
    },
    {
      title: t("terms_fuzu.list.sub-title_12"),
      text: t("terms_fuzu.list.description_12")
    },
    {
      title: t("terms_fuzu.list.sub-title_13"),
      text: t("terms_fuzu.list.description_13")
    },
    {
      title: t("terms_fuzu.list.sub-title_14"),
      text: t("terms_fuzu.list.description_14")
    },
    {
      title: t("terms_fuzu.list.sub-title_15"),
      text: t("terms_fuzu.list.description_15")
    },
    {
      title: t("terms_fuzu.list.sub-title_16"),
      text: t("terms_fuzu.list.description_16")
    },
    {
      title: t("terms_fuzu.list.sub-title_17"),
      text: t("terms_fuzu.list.description_17")
    },
    {
      title: t("terms_fuzu.list.sub-title_18"),
      text: t("terms_fuzu.list.description_18")
    },
    {
      title: t("terms_fuzu.list.sub-title_19"),
      text: t("terms_fuzu.list.description_19")
    },
    {
      title: t("terms_fuzu.list.sub-title_20"),
      text: t("terms_fuzu.list.description_20")
    },
    {
      title: t("terms_fuzu.list.sub-title_21"),
      text: t("terms_fuzu.list.description_21")
    },
    {
      title: t("terms_fuzu.list.sub-title_22"),
      text: t("terms_fuzu.list.description_22")
    },
    {
      title: t("terms_fuzu.list.sub-title_23"),
      text: t("terms_fuzu.list.description_23")
    },
    {
      title: t("terms_fuzu.list.sub-title_24"),
      text: t("terms_fuzu.list.description_24")
    },
    {
      title: t("terms_fuzu.list.sub-title_25"),
      text: t("terms_fuzu.list.description_25")
    }
  ];

  const baronaTerms = [
    {
      title: `1. ${t("terms.list.sub-title_1")}`,
      text: t("terms.list.description_1")
    },
    {
      title: `2. ${t("terms.list.sub-title_2")}`,
      text: t("terms.list.description_2")
    },
    {
      title: `3. ${t("terms.list.sub-title_3")}`,
      text: t("terms.list.description_3")
    },
    {
      title: `4. ${t("terms.list.sub-title_4")}`,
      text: t("terms.list.description_4")
    },
    {
      title: `5. ${t("terms.list.sub-title_5")}`,
      text: t("terms.list.description_5")
    },
    {
      title: `6. ${t("terms.list.sub-title_6")}`,
      text: t("terms.list.description_6")
    },
    {
      title: `7. ${t("terms.list.sub-title_7")}`,
      text: t("terms.list.description_7")
    },
    {
      title: `8. ${t("terms.list.sub-title_8")}`,
      text: t("terms.list.description_8")
    },
    {
      title: `9. ${t("terms.list.sub-title_9")}`,
      text: t("terms.list.description_9")
    },
    {
      title: `10. ${t("terms.list.sub-title_10")}`,
      text: t("terms.list.description_10")
    },
    {
      title: `11. ${t("terms.list.sub-title_11")}`,
      text: t("terms.list.description_11")
    },
    {
      title: `12. ${t("terms.list.sub-title_12")}`,
      text: t("terms.list.description_12")
    }
  ];
  const termsList = isBarona ? baronaTerms : fuzuTerms;

  useEffect(() => {
    if (window.scrollY) {
      window.scroll(0, 0);
    }
  }, []);

  useDocumentTitle(`${t("terms.meta_title")} | ${getCompanyName()}`);

  return (
    <Layout.Content size="xl">
      <Title level="2" fontWeight="600" margin="30px 0 5px">
        {isBarona ? t("terms.barona_title") : <> {`Fuzu ${t("terms.title")}`}</>}
      </Title>
      <Text margin="0 0 24px">{isBarona ? t("terms.effective_date") : "Effective date 15th of February 2016"}</Text>
      <InfoSection data={termsList} />
    </Layout.Content>
  );
};

export default withTrackedRoute(observer(TermsScreen));
