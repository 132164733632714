import React from "react";

import { Container, Title, Description } from "./styled";

const Content = ({ title, description, children }) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Description>{description}</Description>

      {children}
    </Container>
  );
};

export default Content;
