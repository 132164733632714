import styled from "styled-components";

export const StyledFilters = styled.div`
  background-color: ${({ $backgroundColor = "#fff" }) => $backgroundColor};
  padding: 15px 0;

  @media screen and (max-width: 767px) {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 100;
    padding: 20px 0;
  }
`;
