import styled, { css } from "styled-components";

export const FlowWrapper = styled.div`
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;

  > .container {
    height: 100%;
    display: flex;
    overflow-y: auto;

    > .inner {
      margin: 90px auto;
    }

    @media screen and (max-width: 767px) {
      padding-top: 32px;

      > .inner {
        margin: 50px auto;
      }
    }
  }
`;

export const FlowNavBar = styled.div`
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  display: flex;

  @media screen and (max-width: 767px) {
    width: 100%;
    left: 0;
    transform: initial;
    position: relative;
  }
`;

export const FlowProgress = styled.div`
  ${({ fillColorOne, fillColorTwo, emptyColor, currentStep, stepCount }) => `
        height: 6px;
        position: absolute;
        bottom: -6px;
        width: 100%;
        display: flex;

        &::before {
            content: " ";
            width: ${(currentStep * 100) / stepCount}%;
            background: linear-gradient(90deg, ${fillColorOne} 0%, ${fillColorTwo} 100%);
            transition: width .3s ease-in-out;
        }

        &::after {
            content: " ";
            width: ${100 - (currentStep * 100) / stepCount}%;
            background: ${emptyColor};
            transition: width .3s ease-in-out;
        }
    `}
`;

export const StyledHeaderContainer = styled.header`
  ${({ background_color = "#fff", fontColor, fontColorActive, fillColor, borderBottom }) => css`
    background-color: ${background_color};
    border-bottom: ${borderBottom};

    .nav-item {
      display: inline-flex;
      align-items: center;
      color: ${fontColor};
      font-size: 14px;
      font-weight: 600;
      padding: 0 15px;
      height: 100%;
      &.is-active {
        color: ${fontColorActive};
        .nav-item__icon {
          display: block;
        }
        &.prompt {
          padding-top: 3px;
        }
      }
    }

    .nav-icon {
      outline: none;

      > svg {
        fill: ${fillColor};
      }
    }

    .close-icon {
      position: absolute;
      right: 5px;
      top: 50%;
      margin-top: -17px;
    }

    .nav-item__icon {
      display: inline-block;
      margin-right: 5px;
      display: none;
    }
  `}
`;
