import { useMemo } from "react";

export const refReducer = (state, { ref, id }) => {
  if (!state?.some(item => item?.id == id)) return state.concat({ ref, id });
  return state;
};

export const normalizeReferences = (references, removeReference) => {
  return useMemo(() => {
    if (!references || !references.length) return [];

    return references
      .slice()
      .map(
        ({
          id,
          name,
          title_and_organization,
          relation_to,
          email,
          phone_code: ref_phone_code,
          phone_number: ref_phone_number
        }) => {
          const title = `${name} • ${title_and_organization}`;
          const info = relation_to !== "Other" ? relation_to : "";

          let text = email;
          if (ref_phone_code && ref_phone_number) {
            text += `, ${ref_phone_code} ${ref_phone_number}`;
          }

          return { id, title, info, text, onDelete: () => removeReference(id) };
        }
      );
  }, [references]);
};
