import React, { useContext } from "react";
import { ScreenClassContext } from "react-grid-system";

import Messenger from "pages/Messenger";
import { withTrackedRoute } from "../../hocs";
import Layout from "../../components/Layout";
import { withAuthorizationRedirect } from "../../components/WithAuth";
import useStore from "../../contexts/store";
import withStore from "../../../b2b/hocs/withStore";

const MessagesScreen = ({ ...routeProps }) => {
  const { Messages, User, isBarona } = useStore();
  const screen = useContext(ScreenClassContext);
  const isSm = ["xs", "sm"].includes(screen);

  return (
    <Layout.Content
      size="xl"
      id="main"
      style={{
        display: "flex",
        flexDirection: "column",
        padding: `${isSm ? "0" : "20px 20px 0px"} `
      }}
      {...{ "data-hide-footer-below": true }}
    >
      <Messenger isBarona={isBarona} Messages={Messages} User={User} {...routeProps} />
    </Layout.Content>
  );
};

export default withStore(withTrackedRoute(withAuthorizationRedirect(MessagesScreen)));
