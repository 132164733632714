import React from "react";

import { useTranslation } from "b2c/hooks/useTranslation";
import Layout from "b2c/components/Layout";
import { Card, Image, List, Text } from "b2c/components/Core";
import { deliverablesList, deliverablesListNames } from "b2c/constants/pricing";
import ListMarker from "images/b2c/icons/listMarker.png";
import { theme } from "b2c/contexts/theme";
import AbsentIcon from "images/b2c/icons/absent.png";
import { PricingList, PricingWrapper, StyledDescr, StyledMarker } from "./styled";

const EliteDeliverablesPlan = () => {
  const { t } = useTranslation();

  return (
    <Layout.Section style={{ backgroundColor: "white" }}>
      <PricingWrapper>
        <PricingList>
          <Card
            shadow
            bordered
            style={{
              margin: "0 5px 0",
              flex: "0 0 245px",
              padding: 0,
              position: "relative"
            }}
          >
            <Card.Header style={{ backgroundColor: "#f3f3f3", height: "145px" }} padding="32px 0 0 20px">
              <Text fontSize="16px" fontWeight={700} margin="0">
                {t("pricing.elite_details.delivers.column-title_1")}
              </Text>
            </Card.Header>
            <Card.Content padding="0 15px" style={{ flex: "1 0 auto" }}>
              <List style={{ padding: 0 }}>
                {deliverablesListNames.map(desc => (
                  <React.Fragment key={desc}>
                    <List.Item
                      lineHeight="20px"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center"
                      }}
                      height="72px"
                      bulletSize="0"
                    >
                      <StyledDescr>{t(desc)}</StyledDescr>
                    </List.Item>
                    <Card.Separator height={1} margin={0} />
                  </React.Fragment>
                ))}
              </List>
            </Card.Content>
            <Card.Separator height={1} margin="0" />
          </Card>

          {deliverablesList.map((item, i) => {
            return (
              <Card
                key={i}
                shadow
                bordered
                borderWidth="1px"
                borderColor={item.popular ? "primary" : "#B9B9B9"}
                style={{
                  margin: "0 5px 0",
                  flex: "0 0 280px",
                  position: "relative",
                  backgroundColor: item.popular ? theme.primary50 : "white"
                }}
              >
                <Card.Header
                  style={{
                    height: "145px"
                  }}
                  padding="32px 0 0 20px"
                >
                  <Text fontSize={20} margin="0" fontWeight={600}>
                    {t(item.title)}
                  </Text>
                </Card.Header>
                <Card.Content padding="0 15px" style={{ flex: "1 0 auto" }}>
                  <List style={{ padding: 0 }}>
                    {item.actives.map((active, index) => {
                      return (
                        <React.Fragment key={index}>
                          <StyledMarker>
                            <Image
                              width={20}
                              height={20}
                              style={{ marginTop: "25px" }}
                              src={active ? ListMarker : AbsentIcon}
                              alt="Circle"
                            />
                          </StyledMarker>
                          <Card.Separator height={1} margin="0" theme={item.popular ? "primary" : "grey200"} />
                        </React.Fragment>
                      );
                    })}
                  </List>
                </Card.Content>
              </Card>
            );
          })}
        </PricingList>
      </PricingWrapper>
    </Layout.Section>
  );
};

export default EliteDeliverablesPlan;
