import React, { useState, useRef } from "react";
import { BadgeCheck } from "@styled-icons/boxicons-solid";

import DndIcon from "images/b2c/dndicon.svg";
import { Card, Input, Button, Text, Icon } from "../Core";
import FileLabel from "../FileLabel";
import { useNotification } from "../../../utils/hooks";

const DragAndDrop = ({
  title,
  name,
  url,
  accept,
  processing,
  buttonText = "Select file",
  onUpload,
  emptyComponent = <></>,
  cardProps = {},
  autoFocus,
  children = "Drag and drop your file here",
  inputName,
  dataCy,
  ...rest
}) => {
  const ref = useRef();
  const [isDraggedOver, setDraggedOver] = useState(false);
  const [fileName, setName] = useState(name);
  const [uploaded, setUploaded] = useState(uploaded);
  const notifyNotSupported = useNotification(
    <Text color="white" margin="auto">
      Drag and drop is not supported by your device
    </Text>,
    "accent300"
  );

  const onDragOver = e => {
    e.preventDefault();
    setDraggedOver(true);
  };

  const onDrop = e => {
    e.preventDefault();

    if (e.dataTransfer) {
      if (e.dataTransfer.items) {
        if (e.dataTransfer.items[0].kind === "file") {
          const file = e.dataTransfer.items[0].getAsFile();
          setFile(file);
        }
      } else {
        setFile(e.dataTransfer.files[0]);
      }
    } else {
      notifyNotSupported();
    }
  };

  const handleUpload = e => {
    const file = e.target.files[0];
    setFile(file);
    setUploaded(true);
  };

  const setFile = file => {
    if (file) {
      setName(file.name);
      if (onUpload) onUpload(file);
    }
  };

  return (
    <Card
      bordered
      borderColor={fileName || isDraggedOver ? "secondary200" : "grey200"}
      borderWidth="3px"
      borderStyle={fileName ? "solid" : "dashed"}
      onDragOver={onDragOver}
      onDragLeave={() => setDraggedOver(false)}
      onDrop={onDrop}
      {...cardProps}
    >
      <Card.Content height="160px" padding="15px 20px">
        {url || uploaded ? (
          <Icon as={BadgeCheck} width={60} fill="success300" margin="auto" />
        ) : (
          <>
            <Icon as={DndIcon} width={90} fill={isDraggedOver ? "secondary200" : "black100"} margin="auto" />
            <Text margin="0 auto" color={fileName || isDraggedOver ? "secondary200" : "black200"}>
              {fileName || children}
            </Text>
          </>
        )}
        <FileLabel url={url} fileName={fileName} emptyComponent={emptyComponent} uploaded={uploaded} />
      </Card.Content>

      <Card.Footer>
        <Input.File
          accept={accept}
          onChange={e => handleUpload(e)}
          dataCy={dataCy}
          Component={props => (
            <Button
              ref={ref}
              type="button"
              fontSize="14px"
              width="100%"
              theme="secondary300"
              processing={processing}
              {...props}
            >
              {buttonText}
            </Button>
          )}
          name={inputName}
          {...rest}
        />
      </Card.Footer>
    </Card>
  );
};

export default DragAndDrop;
