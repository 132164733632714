import React, { useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";

import { onEnterPress } from "../../../../../utils/helpers";
import { Button, SearchInput } from "../../../../components/Core";
import { SearchWrapper } from "./styled";

const SearchNavigation = ({ term = "", handleSearch }) => {
  const [searchTerm, setSearchTerm] = useState(term);

  useEffect(() => {
    setSearchTerm(term);
  }, [term]);

  const handleSearchInput = e => {
    setSearchTerm(e.target.value);
  };

  return (
    <SearchWrapper onKeyDown={e => onEnterPress(e, searchTerm, () => handleSearch(searchTerm))}>
      <Row>
        <Col>
          <SearchInput
            aria-label="Search anything"
            width="100%"
            value={searchTerm}
            onChange={handleSearchInput}
            placeholder="Search anything"
            autoFocus
          />
        </Col>
        <Col xs={4}>
          <Button height="40px" width="100%" onClick={() => handleSearch(searchTerm)}>
            Search
          </Button>
        </Col>
      </Row>
    </SearchWrapper>
  );
};

export default SearchNavigation;
