import React from "react";
import { useTranslation } from "b2c/hooks/useTranslation";

import whatOfferImg from "images/b2c/barona/whatOffer.png";
import {
  Container,
  Content,
  Title,
  Offer,
  OfferLeftSide,
  OfferRightSide,
  OfferButton,
  OfferTitle,
  OfferLabel,
  OfferDescription,
  OfferImageContent
} from "./styled";

const WhatOffer = ({ openContactUsModal }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Content>
        <Title>{t("companies.whatOffers.title")}</Title>
        <Offer>
          <OfferLeftSide>
            <OfferImageContent>
              <img src={whatOfferImg} alt="What offer" />
            </OfferImageContent>
          </OfferLeftSide>
          <OfferRightSide>
            <OfferTitle>{t("companies.whatOffers.sub_title_1")}</OfferTitle>
            <OfferLabel>{t("companies.whatOffers.sub_title_2")}</OfferLabel>
            <OfferDescription>{t("companies.whatOffers.description_1")}</OfferDescription>
            <OfferDescription>{t("companies.whatOffers.description_2")}</OfferDescription>
            <OfferButton onClick={openContactUsModal}> {t("companies.whatOffers.button")}</OfferButton>
          </OfferRightSide>
        </Offer>
      </Content>
    </Container>
  );
};

export default WhatOffer;
