import React from "react";

import ClipboardIcon from "images/b2c/application/clipboard.svg";
import { useTranslation } from "b2c/hooks/useTranslation";
import { Title, Text, Tipbox, Card, Notification } from "../../../../components/Core";
import { ApplicationStep } from "../../../../components/Core/InfoBar";
import StepActions from "../StepActions";

const Steps = ({ title = "application.application_steps", id, loading, steps, setStep, ...rest }) => {
  const sortedSteps = steps.slice().sort((a, b) => a.priority - b.priority);
  const { t } = useTranslation();

  const getStepStatus = index => {
    return sortedSteps[index] && !loading ? availabilityStatus(index) : "loading";
  };

  const availabilityStatus = index => {
    if (sortedSteps[index].completed) return "completed";
    return "relevant";
  };

  return (
    <>
      <Card wideOnMobile bordered style={{ marginBottom: 15 }} {...rest}>
        <Card.Header>
          <ClipboardIcon style={{ margin: "0 10px 0 0" }} />
          <Title level={6} fontWeight="700">
            {t(title) || t("application.questions_default")}
          </Title>
        </Card.Header>
        <Card.Content padding="15px 16px">
          <Tipbox title={t("application.tip_box")} margin="12px 0" />

          <div data-cy="application-steps">
            {(loading ? [...new Array(4).keys()] : sortedSteps).map((step, index) => (
              <ApplicationStep
                key={`app_step_${index + 1}`}
                title={step.title}
                number={index + 1}
                description={step.description}
                status={getStepStatus(index)}
                actions={
                  !loading ? (
                    <StepActions
                      id={id}
                      type={step.variety}
                      substeps={step.substeps}
                      stepStatus={getStepStatus(index)}
                      cta={step.cta}
                      setStep={() => setStep(step)}
                    />
                  ) : null
                }
              />
            ))}
          </div>
        </Card.Content>
      </Card>
      <Notification margin="0 0 15px">
        <Text width="100%" fontSize="16px" fontWeight="600">
          {t("application.complete_all_steps")}
        </Text>
      </Notification>
    </>
  );
};

export default Steps;
