import React from "react";

import { useTranslation } from "b2c/hooks/useTranslation";
import { countriesSelection } from "b2c/constants/landing";
import { Button } from "../Core";

export const SelectCountry = ({ country, isEmployer }) => {
  const part = isEmployer ? "b2b" : "b2c";
  const { t } = useTranslation();

  const constructHref = slug => {
    let href = "";
    if (slug === "global") {
      if (isEmployer) {
        href = "/employers";
      } else {
        href = "/";
      }
    } else if (isEmployer) {
      href = `/${slug}/employers`;
    } else {
      href = `/${slug}`;
    }

    return href;
  };
  const countriesList = countriesSelection[part]
    ?.filter(item => item?.slug !== country?.slug)
    .filter(item => {
      if (country?.id === "global" || !country) {
        return item.slug !== "global";
      }

      return item;
    })
    .map(({ name, slug }, index) => (
      <Button
        key={name}
        as="a"
        href={constructHref(slug)}
        theme="transparent"
        style={{
          fontSize: "12px",
          lineHeight: "24px",
          color: "#4690FF",
          margin: "0 5px",
          height: "auto",
          padding: "0",
          fontWeight: "600",
          display: "block"
        }}
        target="_self"
      >
        {t(name)} {`${index + 1 !== 3 ? " | " : ""}`}
      </Button>
    ));
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        margin: "5px auto 0",
        alignItems: "center"
      }}
    >
      {countriesList}
    </div>
  );
};
