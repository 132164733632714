import React, { lazy, Suspense } from "react";
import { observer } from "mobx-react";
import { Redirect, useHistory } from "react-router-dom";

import { useTranslation } from "b2c/hooks/useTranslation";
import { withGuestVersion } from "../../../../components/WithAuth";
import { Categories } from "../../../../components/Core";
import JobSearchSection from "../JobSearchSection";
import HeroBarona from "../HeroBarona";
import { useGetCurrentCountry } from "../../../../hooks";
import { withCustomPlatformHidden } from "../../../../contexts/branded";
import { townPath, pathWithCountry } from "../../../../utils/routes";
import { LandingPageWrapper } from "../../styled";

const HowItWorksSection = lazy(() => import("../../../../components/HowItWorksSection"));
const BaronaTalentPoolIntro = lazy(() => import("../BaronaTalentPoolIntro"));
const Testimonials = lazy(() => import("../Testimonials"));

const LandingScreen = ({
  jobsSearchPanel,
  platformReviews,
  popularSearches,
  industriesWithJobs,
  townsWithJobs,
  baronaPlatformStats,
  baronaStaticPlatformDetails,
  baronaTalentPoolIntro
}) => {
  const country = useGetCurrentCountry();
  const history = useHistory();
  const { t } = useTranslation();

  const handleClickCategory = path => {
    history.push(path);
  };

  return (
    <LandingPageWrapper data-cy="landing">
      <HeroBarona jobsCount={baronaPlatformStats?.context?.jobs_count} />

      <JobSearchSection
        isBarona
        country={country}
        towns={jobsSearchPanel?.context?.geonames}
        filters={
          { categories: jobsSearchPanel?.context?.categories, industries: jobsSearchPanel?.context?.industries } || {}
        }
        handleClickCategory={handleClickCategory}
        topCategories={popularSearches?.context?.top_categories}
      />

      <Suspense fallback={<div />}>
        {baronaStaticPlatformDetails?.id && (
          <HowItWorksSection isBarona country={country} style={{ contentVisibility: "auto" }} />
        )}

        {platformReviews?.id && <Testimonials style={{ contentVisibility: "auto" }} />}
        {baronaTalentPoolIntro?.id && (
          <BaronaTalentPoolIntro
            title={t("landing.join_us_banner.title")}
            buttonText={t("landing.join_us_banner.button")}
            linkTo="/onboarding"
            style={{ contentVisibility: "auto" }}
          />
        )}
      </Suspense>

      <Categories title={t("landing.industry.title")} style={{ contentVisibility: "auto" }}>
        {industriesWithJobs?.context?.industries?.map(({ name, slug }) => (
          <Categories.Item
            key={slug}
            to={pathWithCountry(country, `/job/${slug}`)}
            style={{ contentVisibility: "auto" }}
            onClick={() => handleClickCategory(pathWithCountry(country, `/job/${slug}`))}
          >
            {`${name} ${t("landing.industry.helper_text")}`}
          </Categories.Item>
        ))}
      </Categories>

      <Categories title={t("landing.location.title")} style={{ contentVisibility: "auto" }}>
        {townsWithJobs?.context?.towns?.slice(0, 20).map(town => (
          <Categories.Item
            key={town.slug}
            to={townPath(town)}
            style={{ contentVisibility: "auto" }}
            onClick={() => handleClickCategory(townPath(town))}
          >
            {`${t("landing.location.helper_text")} ${town.name}`}
            {country ? "" : `, ${town.fuzu_country_name}`}
          </Categories.Item>
        ))}
      </Categories>
    </LandingPageWrapper>
  );
};

export default withCustomPlatformHidden(withGuestVersion(() => <Redirect to="/home" />, observer(LandingScreen)));
