import styled from "styled-components";

export const TitleIconHolder = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 auto;
`;

export const AchievementItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

export const AchievementItemIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #eeeeee;
  margin-right: 15px;
  flex: 0 0 80px;
`;

export const AchievementItemContent = styled.div``;
