import React from "react";
import { toJS } from "mobx";
import { Row, Col } from "react-grid-system";

import { useQueryParams } from "utils/hooks";
import { useTranslation } from "b2c/hooks/useTranslation";
import FilterSelect from "../FilterSelect";
import useStore from "../../contexts/store";
import { useGetCurrentCountry, useLanguageAndCountryUrl } from "../../hooks";

const JobFilters = ({ searchFilters, appliedFilters, applyFilter, showJobsInText }) => {
  const { jobSearchUrlBuilder } = useStore("JobSearch");
  const { t } = useTranslation();
  const currentCountry = useGetCurrentCountry();
  const languageAndLanguage = useLanguageAndCountryUrl();

  const query = useQueryParams();
  const seniority = toJS(appliedFilters.seniority_ids);
  const activeSeniority =
    seniority && typeof seniority === "object"
      ? toJS(appliedFilters.seniority_ids)[0]
      : toJS(appliedFilters.seniority_ids);

  const seniorities = searchFilters.seniorities?.map(item => {
    return {
      slug: item.id,
      ...item
    };
  });

  const countrySelected = !languageAndLanguage
    ? currentCountry.id && currentCountry.id !== "global"
      ? currentCountry
      : ""
    : "";

  return (
    <Row style={{ padding: "12px 0 0" }}>
      <Col lg={2}>
        <FilterSelect
          placeholder={t("jobs.search.published_placeholder")}
          category="published"
          appliedCategory="published"
          baseProperty="value"
          dataCyTrigger="published"
          sortItemsBy="name"
          items={searchFilters.published}
          selected={appliedFilters.published}
          applyFilter={applyFilter}
          isJobFilter
        />
      </Col>
      <Col lg={2}>
        <FilterSelect
          multi
          placeholder={t("jobs.search.profession_placeholder")}
          category="profession"
          appliedCategory="category_ids"
          items={searchFilters.categories}
          selected={toJS(appliedFilters.category_ids?.[0] || query?.filters?.category_ids?.[0])}
          applyFilter={applyFilter}
          urlBuilder={record => jobSearchUrlBuilder.buildForCategory(record, countrySelected, languageAndLanguage)}
          showJobsInText={showJobsInText}
          isJobFilter
        />
      </Col>
      <Col lg={2}>
        <FilterSelect
          multi
          placeholder={t("jobs.search.industry_placeholder")}
          sortItemsBy="name"
          category="industry"
          items={searchFilters.industries}
          selected={toJS(appliedFilters.industry_ids?.[0] || query?.filters?.industry_ids?.[0])}
          applyFilter={applyFilter}
          urlBuilder={record => jobSearchUrlBuilder.buildForIndustry(record, countrySelected, languageAndLanguage)}
          showJobsInText={showJobsInText}
          isJobFilter
        />
      </Col>
      <Col lg={2}>
        <FilterSelect
          placeholder={t("jobs.search.seniority_placeholder")}
          category="seniority"
          items={seniorities}
          selected={activeSeniority}
          applyFilter={applyFilter}
          urlBuilder={record => jobSearchUrlBuilder.buildForSeniority(record, countrySelected, languageAndLanguage)}
          showJobsInText={showJobsInText}
          isSeniority
          isJobFilter
        />
      </Col>
      {searchFilters.employability_programs?.length > 0 && (
        <Col lg={2}>
          <FilterSelect
            multi
            placeholder={t("jobs.search.exclusive_placeholder")}
            category="Exclusive jobs"
            appliedCategory="employability_program_ids"
            items={searchFilters.employability_programs}
            selected={toJS(appliedFilters.employability_program_ids?.[0])}
            applyFilter={applyFilter}
            isJobFilter
          />
        </Col>
      )}
    </Row>
  );
};

export default JobFilters;
