import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Route, Redirect, useHistory } from "react-router-dom";

import BaronaLogoSvg from "images/barona-logo.svg";
import Heading from "./components/Heading";
import Country from "./components/Country";
import JobType from "./components/JobType";
import PickJob from "./components/PickJob";
import YourEmail from "./components/YourEmail";
import NameAndPassword from "./components/NameAndPassword";
import YourName from "./components/YourName";
import JobTitles from "./components/JobTitles";
import InterestAreas from "./components/InterestAreas";
import Skills from "./components/Skills";
import SeniorityLevel from "./components/SeniorityLevel";
import { Wrapper, Overlay, OverlayInfo } from "./styled";
import { useQueryParams } from "../../../utils/hooks";
import useStore from "../../contexts/store";
import { Spinner } from "../../components/Core";
import { useTranslation } from "../../hooks/useTranslation";

const OnboardingFlow = () => {
  const { shorterFlow, return_to: returnTo, application_id: applicationId } = useQueryParams();
  const history = useHistory();
  const { user } = useStore("User");
  const { global_brand: globalBrand } = useStore("initialState");
  const { t } = useTranslation();

  useEffect(() => {
    if (!user && globalBrand.custom_authentication_b2c_url) {
      window.location.href = globalBrand.custom_authentication_b2c_url;
    }

    if (user && user.onboarding_steps_completed) {
      history.push("/home");
    }

    if (returnTo) {
      const prevOnboardingForm = JSON.parse(sessionStorage.getItem("onboardingFlow")) || {};
      const patternApplicationId = /application_id=(\d+)/;
      const returnApplicationId = returnTo.includes("application_id") ? returnTo.match(patternApplicationId)[1] : "";
      const normalizeReturnTo =
        returnApplicationId || applicationId
          ? `/jobs/${returnApplicationId || applicationId}/application`
          : returnTo || "/home";

      const onboardingForm = {
        ...prevOnboardingForm,
        returnTo: normalizeReturnTo,
        applicationId: returnApplicationId
      };

      sessionStorage.setItem("onboardingFlow", JSON.stringify(onboardingForm));
    }

    if (applicationId) {
      const prevOnboardingForm = JSON.parse(sessionStorage.getItem("onboardingFlow")) || {};

      const onboardingForm = {
        ...prevOnboardingForm,
        returnTo: prevOnboardingForm.returnTo || `/jobs/${applicationId}/application`,
        applicationId
      };

      sessionStorage.setItem("onboardingFlow", JSON.stringify(onboardingForm));
    }

    if (shorterFlow === "true") {
      history.push("/onboarding/your-email");

      const prevOnboardingForm = JSON.parse(sessionStorage.getItem("onboardingFlow")) || {};

      const onboardingForm = {
        ...prevOnboardingForm,
        isShorterFlow: true
      };

      sessionStorage.setItem("onboardingFlow", JSON.stringify(onboardingForm));
    }
  }, []);

  return (
    <Wrapper>
      {!user && globalBrand.custom_authentication_b2c_url && (
        <Overlay>
          <Spinner size="50px" margin="auto" />
          <BaronaLogoSvg />
          <OverlayInfo>{t("login.redirect_text")}</OverlayInfo>
        </Overlay>
      )}

      <Heading isHideLink={globalBrand.custom_authentication_b2c_url} />
      <Route exact path="/onboarding" render={() => <Redirect to="/onboarding/country" />} />
      <Route exact path="/onboarding/country" component={Country} />
      <Route exact path="/onboarding/job-type" component={JobType} />
      <Route exact path="/onboarding/pick-job" component={PickJob} />
      <Route exact path="/onboarding/job-titles" component={JobTitles} />
      <Route exact path="/onboarding/interest-areas" component={InterestAreas} />
      <Route exact path="/onboarding/skills" component={Skills} />
      <Route exact path="/onboarding/seniority-level" component={SeniorityLevel} />
      <Route exact path="/onboarding/your-email" component={YourEmail} />
      <Route exact path="/onboarding/name-and-password" component={NameAndPassword} />
      <Route exact path="/onboarding/your-name" component={YourName} />
    </Wrapper>
  );
};

export default observer(OnboardingFlow);
