import React, { useEffect, useState } from "react";
import { ErrorCircle } from "@styled-icons/boxicons-solid";

import { useTranslation } from "b2c/hooks/useTranslation";
import {
  Content,
  Label,
  Title,
  Flex,
  ProfessionItem,
  ProfessionTitle,
  ProfessionDescription,
  ProfessionFlex,
  EducationFileUploader,
  Error
} from "./styled";
import { Dropdown } from "../../../../components/Core";
import FileUploader from "../../../../components/FileUploader";
import { languageFormat } from "../../../../constants/main";

const ProfessionalCertificates = ({
  professionalCertificates,
  setAttachments,
  attachments,
  professionalCertificatesErrors
}) => {
  const [certificateDates, setDates] = useState([]);
  const { t, language } = useTranslation();
  const languageFormatType = languageFormat[language];

  const months = Array.from({ length: 12 }, (_, i) => ({
    name: new Date(null, i + 1, null).toLocaleString(languageFormatType, {
      month: "long"
    }),
    id: i + 1
  }));

  const getMonth = value => (months.some(m => m?.id == value) ? months.find(m => m?.id == value)?.name : "");

  const modifyDate = (index, dates = certificateDates) => {
    const pad = d => (d ? (d < 10 ? `0${d.toString()}` : d.toString()) : null);

    if (dates[index].month) {
      const modifiedMonth = months.some(m => m.id == dates[index].month)
        ? dates[index].month
        : months.find(m => dates[index].month == m?.name)?.id;

      return `${dates[index].year}-${pad(modifiedMonth)}-01`;
    }
  };

  const formatDate = date =>
    date
      ? {
          year: +date.substring(0, date.indexOf("-", 0)),
          month: +date.substring(date.indexOf("-", 0) + 1, date.indexOf("-", 5))
        }
      : {};

  const handleAttachment = (file, id, index) => {
    setAttachments(prev => {
      const professionalCertificatesUpdated = prev.professional_certificates?.filter(
        item => String(item.certificate_id) !== String(id)
      );

      return {
        ...prev,
        professional_certificates: prev.professional_certificates?.length
          ? [
              ...professionalCertificatesUpdated,
              { certificate_id: id, certificate: file, issued_at: modifyDate(index) }
            ]
          : [{ certificate_id: id, certificate: file, issued_at: modifyDate(index) }]
      };
    });
  };

  const handleDateChange = (name, id, index, itemId) => {
    const newList = [...certificateDates];
    newList[index] = {
      ...newList[index],
      [name]: id
    };

    setDates(newList);

    const findAttachment = attachments.professional_certificates.map(item => {
      if (String(item.certificate_id) === String(itemId)) {
        return { ...item, issued_at: modifyDate(index, newList) };
      }
      return item;
    });

    setAttachments(prev => {
      return {
        ...prev,
        professional_certificates: findAttachment
      };
    });
  };

  useEffect(() => {
    if (professionalCertificates?.length > 0) {
      setDates((professionalCertificates || []).map(item => formatDate(item?.certificate_item?.issued_at)));
    }
  }, [professionalCertificates]);

  const professionalCertificatesList = professionalCertificates?.map((item, index) => {
    const professionalItemError =
      Boolean(professionalCertificatesErrors) && Boolean(professionalCertificatesErrors?.[item.id])
        ? professionalCertificatesErrors[item.id]
        : {};

    return (
      <ProfessionItem key={item.id}>
        <ProfessionTitle>{item.name}</ProfessionTitle>
        {professionalItemError?.certificate?.[0] ? (
          <Error>
            <ErrorCircle />
            {professionalItemError?.certificate?.[0]}
          </Error>
        ) : null}

        {item.has_issued_date && (
          <>
            <ProfessionDescription>{t("application.attachments.certificates.date_label")}</ProfessionDescription>
            <ProfessionFlex>
              <Dropdown
                id="month"
                name="month"
                placeholder={t("application.attachments.certificates.date_placeholder")}
                defaultValue={getMonth(certificateDates?.[index]?.month)}
                className="dropdown-content"
              >
                <Dropdown.Menu disableSorting>
                  {months.map(({ id, name }) => (
                    <Dropdown.Item
                      key={id}
                      onClick={() =>
                        handleDateChange("month", id, index, item.id, item.certificate_item?.attachment?.url)
                      }
                    >
                      {name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown
                className="dropdown-content"
                id="year"
                name="year"
                placeholder={t("application.attachments.certificates.year_placeholder")}
                defaultValue={certificateDates?.[index]?.year}
              >
                <Dropdown.Menu disableSorting disableSearch>
                  {[...Array(60)]
                    .map((_, i) => new Date().getFullYear() - i)
                    .map(year => (
                      <Dropdown.Item
                        key={year}
                        onClick={() =>
                          handleDateChange("year", year, index, item.id, item.certificate_item?.attachment?.url)
                        }
                      >
                        {year}
                      </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
              </Dropdown>
            </ProfessionFlex>
          </>
        )}
        {/* isBig={!item.academic_certificate?.original_filename} */}
        <EducationFileUploader>
          <FileUploader
            accept=".pdf, .doc, .docx, .rtf, .txt"
            secondButtonText={t("wizard.upload.update_file")}
            name={item.certificate_item?.attachment?.name || t("application.attachments.certificates.file_name")}
            url={item.certificate_item?.attachment?.url}
            buttonText={
              item.certificate_item?.attachment?.url ? t("wizard.upload.update_file") : t("wizard.upload.choose_file")
            }
            onUpload={file => handleAttachment(file, item.id, index)}
            showTypesBelow
            // deleteEducationCertificate={() => deleteEducationCertificate(item.academic_certificate?.id)}
          />
        </EducationFileUploader>
      </ProfessionItem>
    );
  });

  return (
    <Content>
      <Title>{t("application.attachments.certificates.title")}</Title>
      <Label>{t("application.attachments.certificates.description")}</Label>
      <Flex>{professionalCertificatesList}</Flex>
    </Content>
  );
};

export default ProfessionalCertificates;
