import React from "react";
import { MdInfoOutline, MdLocationOn, MdTrendingUp, MdWork } from "react-icons/md";

import { jobSettings } from "b2c/constants/jobs";
import { useTranslation } from "b2c/hooks/useTranslation";
import SettingsSection from "../SettingsSection";
import { Title, Description, Info, Container } from "./styled";

const SearchSettings = ({
  loading,
  settings,
  sections = [],
  updateSettings,
  commonCountries,
  globalLocations,
  getGlobalLocations
}) => {
  const { t } = useTranslation();

  const descriptions = {
    preferred_countries: t("jobs.settings.descriptions_1"),
    seniority_levels: t("jobs.settings.descriptions_2"),
    interest_areas: t("jobs.settings.descriptions_3")
  };

  const placeholder = {
    preferred_countries: t("jobs.settings.placeholder_preferred_countries"),
    seniority_levels: t("jobs.settings.placeholder_seniority_levels"),
    interest_areas: t("jobs.settings.placeholder_interest_areas")
  };

  const emptyStateText = {
    preferred_countries: t("jobs.settings.empty_preferred_countries"),
    seniority_levels: t("jobs.settings.empty_seniority_levels"),
    interest_areas: t("jobs.settings.empty_interest_areas")
  };

  const emptyStateIcon = {
    preferred_countries: MdLocationOn,
    seniority_levels: MdTrendingUp,
    interest_areas: MdWork
  };

  const getItems = sectionType => {
    if (sectionType === jobSettings.preferredCountries && settings && settings.preferred_locations) {
      return settings.preferred_locations.map(item => ({ ...item, selected: true }));
    }

    return settings[sectionType];
  };

  const sectionName = name => {
    if (name === jobSettings.preferredCountries) {
      return jobSettings.preferredLocations;
    }

    return name;
  };

  const settingsList = sections.map(section => (
    <SettingsSection
      key={section}
      loading={loading}
      name={t(`jobs.settings.${section}`)}
      description={descriptions[section]}
      placeholder={placeholder[section]}
      emptyStateText={emptyStateText[section]}
      items={settings && settings[sectionName(section)] ? getItems(section) : []}
      updateSettings={list => updateSettings(list, section)}
      isPreferredCountries={section === jobSettings.preferredCountries}
      EmptyIcon={emptyStateIcon[section]}
      commonCountries={commonCountries}
      globalLocations={globalLocations}
      getGlobalLocations={getGlobalLocations}
    />
  ));

  return (
    <Container>
      <Title>{t("jobs.settings.title")}</Title> <Description>{t("jobs.settings.description")}</Description>
      <Info>
        <MdInfoOutline />
        <span>{t("jobs.settings.info")}</span>
      </Info>
      {settingsList}
    </Container>
  );
};

export default SearchSettings;
