import styled from "styled-components";

import { theme } from "b2c/contexts/theme";

export const Wrapper = styled.section`
  background: #f7f7f7;
  padding: 20px 0;

  @media screen and (min-width: 768px) {
    padding: 40px 0;
  }
`;

export const Content = styled.section`
  background-color: #fff;
  padding: 24px;
  max-width: 1200px;
  border-radius: 16px;
  margin: 0 auto;
  position: relative;

  @media screen and (min-width: 768px) {
    padding: 48px;
  }
`;

export const Title = styled.h1`
  font-weight: 600;
  font-size: 32px;
  line-height: 130%;
  color: #333333;
  max-width: 330px;
  margin: 0 auto 24px auto;

  @media screen and (min-width: 768px) {
    margin: 0 0 24px 0;
  }

  span {
    color: #408bfc;
  }
`;

export const LinkTo = styled.a`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  display: flex;
  background: ${theme.primary};
  border-radius: 5px;
  padding: 13.5px 24px;
  display: block;
  max-width: 330px;
  text-decoration: none;
  cursor: pointer;
  margin: 0 auto 24px auto;

  &:hover {
    filter: brightness(90%);
  }

  svg {
    margin-left: 8px;
    width: 18px;
  }

  @media screen and (min-width: 768px) {
    margin: 0;
  }
`;

export const PeoplesContent = styled.div`
  margin: 0 auto;
  text-align: center;

  img {
    width: 320px;
    height: 220px;
  }

  @media screen and (min-width: 768px) {
    position: absolute;
    right: 100px;
    bottom: 0px;
    margin: 0;
  }
`;

export const BallContent = styled.div`
  width: 115px;
  height: 135px;
  display: none;

  @media screen and (min-width: 768px) {
    display: block;
    position: absolute;
    right: 50px;
    top: 0px;
  }
`;
