import React, { useContext } from "react";
import { ScreenClassContext } from "react-grid-system";

import { useTranslation } from "b2c/hooks/useTranslation";
import jussi from "images/2021-jussi.png";
import ivy from "images/2022-ivy.jpeg";
import nikolay from "images/2022-nikolay.jpeg";
import terver from "images/2022-terver.jpeg";
import marko from "images/2021-marko.png";
import muthoni from "images/2021-muthoni.png";
import nabil from "images/2021-nabil.png";
import nelly from "images/2021-nelly.png";
import hanna from "images/2021-hanna.png";
import evelyn from "images/2021-evelyn.jpeg";
import { Title, Text, Carousel, Card, Image } from "../../../../components/Core";
import Layout from "../../../../components/Layout";
import { TeamSliderWrapper } from "./styled";

const TeamSliderSection = () => {
  const { t } = useTranslation();

  const data = [
    {
      name: "Jussi Hinkkanen",
      title: t("about_fuzu.team.jussi_role"),
      imgsrc: jussi
    },
    {
      name: "Nabil Anjarwalla",
      title: t("about_fuzu.team.nabil_role"),
      imgsrc: nabil
    },
    {
      name: "Marko Myllyluoma",
      title: t("about_fuzu.team.marko_role"),
      imgsrc: marko
    },
    {
      name: "Nelly Mutula",
      title: t("about_fuzu.team.nelly_role"),
      imgsrc: nelly
    },
    {
      name: "Nikolay Rys",
      title: t("about_fuzu.team.nikolay_role"),
      imgsrc: nikolay
    },
    {
      name: "Hanna Stenhammar",
      title: t("about_fuzu.team.hanna_role"),
      imgsrc: hanna
    },
    {
      name: "Muthoni Mathenge",
      title: t("about_fuzu.team.muthoni_role"),
      imgsrc: muthoni
    },
    {
      name: "Terver Gyer",
      title: t("about_fuzu.team.terver_role"),
      imgsrc: terver
    },
    {
      name: "Ivy Njeri",
      title: t("about_fuzu.team.ivy_role"),
      imgsrc: ivy
    },
    {
      name: "Evelyn Murage",
      title: t("about_fuzu.team.evelyn_role"),
      imgsrc: evelyn
    }
  ];

  const screen = useContext(ScreenClassContext);
  const isSm = ["xs", "sm"].includes(screen);

  return (
    <TeamSliderWrapper>
      <Layout.Content tag="div">
        <Title
          loaded
          level="2"
          fontWeight={600}
          margin="0 auto 25px"
          textAlign="center"
          style={{ fontSize: isSm ? 20 : 40 }}
        >
          {t("about_fuzu.team.title")}
        </Title>
        <Carousel
          className="wide-blue-buttons"
          settings={{
            slidesToShow: 3,
            fade: false,
            dots: false,
            responsive: [
              {
                breakpoint: 767,
                settings: {
                  slidesToShow: 1
                }
              }
            ]
          }}
        >
          {data?.map((itm, i) => (
            <Carousel.Slide key={i}>
              <Card>
                <Card.Content style={{ alignItems: "center" }}>
                  <Image src={itm.imgsrc} width="180px" height="180px" borderRadius="50%" />
                  <Text fontSize={22} fontWeight={600} margin="40px 0 0">
                    {itm.name}
                  </Text>
                  <Text fontSize={16}>{itm.title}</Text>
                </Card.Content>
              </Card>
            </Carousel.Slide>
          ))}
        </Carousel>
      </Layout.Content>
    </TeamSliderWrapper>
  );
};

export default TeamSliderSection;
