import styled from "styled-components";

export const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 720px;
  ${props => props.styled}

  .article-image {
    max-width: 100%;
    width: 100%;
    object-fit: contain;
  }
`;
