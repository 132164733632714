import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react";

import { useScrollHandler, useModal } from "utils/hooks";
import { Redirect } from "react-router-dom";
import useStore from "../../contexts/store";
import { withTrackedRoute } from "../../hocs";
import PricingSection from "./components/PricingSection";
import HeroSection from "./components/HeroSection";
import { useCountryDetection } from "../../hooks";
import { isBaronaBrand, withCustomPlatformHidden } from "../../contexts/branded";
import CountryContext from "../../contexts/country";
import ContactUsModal from "../../components/ContactUsModal";
import { LandingPageWrapper } from "./styled";
import Customers from "./components/Customers";
import WhatOffer from "./components/WhatOffer";
import BaronaContactUs from "../AboutUsScreen/components/BaronaContactUs";
import LandingContext from "../../contexts/landing";

const CompaniesBaronaLanding = () => {
  const { initializeEmployerLanding } = useStore("Landing");
  const { country } = useContext(CountryContext);
  const openContactUsModal = useModal(<ContactUsModal />, "", null, false);
  const { setVersion } = useContext(LandingContext);
  const isBarona = isBaronaBrand();
  const { setSelectedProductType } = useStore("ContactUs");
  if (!isBarona) {
    return <Redirect to="/employers" />;
  }

  useEffect(() => {
    if (window.scrollY) {
      window.scroll(0, 0);
    }

    initializeEmployerLanding(country?.id);
    setVersion("b2b");
  }, []);

  const handleOpenContactUsModal = id => {
    openContactUsModal({ aggressive: true });

    if (Number.isInteger(id)) {
      setSelectedProductType(id);
    }
  };

  useCountryDetection();

  const { scrollHandler, targetRef } = useScrollHandler();

  return (
    <LandingPageWrapper>
      <HeroSection openContactUsModal={handleOpenContactUsModal} scrollToPrices={scrollHandler} />
      <Customers />
      <PricingSection openContactUsModal={handleOpenContactUsModal} targetRef={targetRef} />
      <WhatOffer openContactUsModal={handleOpenContactUsModal} />
      <BaronaContactUs openContactUsModal={handleOpenContactUsModal} />
    </LandingPageWrapper>
  );
};

export default withCustomPlatformHidden(withTrackedRoute(observer(CompaniesBaronaLanding)));
