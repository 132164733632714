import React, { useEffect, useContext, useRef } from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";

import { ModalContext } from "contexts/modal";
import useStore from "../../contexts/store";
import ExitFlowModalContent from "../ExitFlowModalContent";

const NavigationBlocker = ({ allowRoutes = [], prohibitRoutes = [] }) => {
  const { application, submitted } = useStore("Application");
  const { toggle } = useContext(ModalContext);
  const history = useHistory();
  const unblock = useRef();
  const isSuccessPage = history.location.pathname.includes("/success");
  const isApplicationPage = history.location.pathname.includes("/application");

  useEffect(() => {
    const isShowModal = application?.id && !submitted && !unblock.current && !isSuccessPage && isApplicationPage;

    if (isShowModal) {
      unblock.current = history.block((location, action, callback) => {
        const routeAllowed =
          !location?.pathname ||
          (allowRoutes.some(route => location?.pathname.includes(route)) &&
            !prohibitRoutes.some(route => location?.pathname.includes(route)));

        let resolveHandler;
        const pendingConfirmation = new Promise(resolve => {
          resolveHandler = resolve;
        });

        if (routeAllowed) return true;
        toggle(<ExitFlowModalContent unblock={resolveHandler} close={() => toggle()} />, "");

        pendingConfirmation.then(() => {
          callback(true);
        });
      });
    } else if (unblock.current) {
      unblock.current();
      unblock.current = null;
    }
  }, [application?.id, submitted, isSuccessPage, isApplicationPage]);

  return null;
};

export default observer(NavigationBlocker);
