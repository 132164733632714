import styled from "styled-components";

export const StyledMarker = styled.div`
  height: 72px;
  text-align: center;
`;

export const StyledDescr = styled.div`
  display: inline-block;
`;

export const PricingWrapper = styled.section`
  max-width: ${({ isBigScreen }) => (isBigScreen ? 1440 : 1200)}px;
  padding: ${({ isBigScreen }) => (isBigScreen ? "30px 0" : "30px 20px")};
  width: 100%;
  margin: 0 auto;
  position: relative;
`;

export const TitleWrapper = styled.section`
  margin: 0 auto;
  text-align: center;
`;

export const PricingList = styled.div`
  display: flex;
  justify-content: space-between;
  overflow: auto;
  margin: 0 -10px;
  padding: 20px;
  cursor: grab;
  user-select: none;
`;
