import React, { useContext } from "react";
import { Row, Col, ScreenClassContext } from "react-grid-system";

import { useTranslation } from "b2c/hooks/useTranslation";
import logo1 from "images/about-disrupt.jpg";
import logo2 from "images/about-ey.jpg";
import logo3 from "images/about-itweb.jpg";
import logo4 from "images/abr-transparent.png";
import { Title, Text, Card, Image } from "../../../../components/Core";
import Layout from "../../../../components/Layout";
import { RecognitionsWrapper } from "./styled";

const Recognitions = () => {
  const screen = useContext(ScreenClassContext);
  const isSm = ["xs", "sm"].includes(screen);
  const { t } = useTranslation();

  return (
    <RecognitionsWrapper>
      <Layout.Content tag="div">
        <Title
          level="2"
          width="100%"
          textAlign="center"
          fontWeight={600}
          style={{ fontSize: isSm ? 20 : 40 }}
          margin="0 0 40px"
        >
          {t("about_fuzu.recognitions.title")}
        </Title>
        <Row>
          <Col md={6} lg={3}>
            <Card bordered he="100%" style={{ margin: "0 0 10px" }}>
              <Card.Content>
                <Image height={60} margin="0 0 10px" src={logo1} />
                <Text width="100%" textAlign="center" fontWeight={500}>
                  {t("about_fuzu.recognitions.sub-title_1")}
                </Text>
              </Card.Content>
            </Card>
          </Col>
          <Col md={6} lg={3}>
            <Card bordered he="100%" style={{ margin: "0 0 10px" }}>
              <Card.Content>
                <Image height={60} margin="0 0 10px" src={logo2} />
                <Text width="100%" textAlign="center" fontWeight={500}>
                  {t("about_fuzu.recognitions.sub-title_2")}
                </Text>
              </Card.Content>
            </Card>
          </Col>
          <Col md={6} lg={3}>
            <Card bordered he="100%" style={{ margin: "0 0 10px" }}>
              <Card.Content>
                <Image height={60} margin="0 0 10px" src={logo3} />
                <Text width="100%" textAlign="center" fontWeight={500}>
                  {t("about_fuzu.recognitions.sub-title_3")}
                </Text>
              </Card.Content>
            </Card>
          </Col>
          <Col md={6} lg={3}>
            <Card bordered he="100%" style={{ margin: "0 0 10px" }}>
              <Card.Content>
                <Image height={60} margin="0 0 10px" src={logo4} />
                <Text width="100%" textAlign="center" fontWeight={500}>
                  {t("about_fuzu.recognitions.sub-title_4")}
                </Text>
              </Card.Content>
            </Card>
          </Col>
        </Row>
      </Layout.Content>
    </RecognitionsWrapper>
  );
};

export default Recognitions;
