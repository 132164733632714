import styled from "styled-components";

import { Card } from "b2c/components/Core";

export const StyledCard = styled(Card)`
  margin: 0 10px 0;
  padding: 16px;
  position: relative;
  width: 100%;
  max-width: 388px;
`;

export const IconContent = styled.div`
  svg {
    rect {
      fill: #2496f5;
    }
    path {
      fill: #fff;
    }
  }
`;

export const ButtonBarona = styled.button`
  font-size: 16px;
  width: 100%;
  margin: 10px 0 0 0;
  padding: 11px 15px;
  background-color: #2496f5;
  color: #ffffff;
  border-radius: 5px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;
    fill: #fff;
  }

  @media screen and (min-width: 1008px) {
    text-align: center;
    justify-content: center;

    svg {
      display: none;
    }

    &:hover {
      opacity: 0.8;
    }
  }
`;
