import styled from "styled-components";

export const Label = styled.section`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #666666;
  position: relative;
  z-index: 1;
  margin-bottom: 24px;
  display: flex;
  justify-content: center;

  span {
    position: relative;
    z-index: 1;
    padding: 0 16px;
    background-color: #fff;
  }

  &:after {
    content: "";
    background: #c5c5c5;
    height: 1px;
    width: 100%;
    position: absolute;
    top: 50%;
    margin-top: -0.5px;
    left: 0;
  }
`;
