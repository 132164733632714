import styled from "styled-components";

export const LinkClose = styled.a`
  cursor: pointer;
  height: 32px;
  width: 32px;
  background: transparent;
  position: absolute;
  right: 100px;
  top: 20;
  display: ${({ isHide }) => (isHide ? "none" : "block")};

  svg {
    width: 24px;
    height: 24px;
    fill: #a4a4a4;
  }

  @media screen and (min-width: 640px) {
    display: block;
  }

  @media screen and (min-width: 768px) {
    top: 40px;
  }
`;

export const Label = styled.div`
  color: #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-bottom: 8px;
`;

export const DontHaveAccount = styled.div`
  display: flex;
  gap: 5px;
  margin-bottom: 24px;

  div {
    margin: 0;
  }

  a {
    color: #408bfc;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
`;

export const ForgotPassword = styled.div`
  a {
    color: #408bfc;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
`;

export const Overlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #fff;
  z-index: 11;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  svg {
    margin-top: 32px;
  }

  path {
    fill: #18181b;
  }

  div {
    margin: 0;
  }
`;

export const OverlayInfo = styled.span`
  color: #a4a4a4;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  display: block;
  margin: 24px 0 0;
`;
