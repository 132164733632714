import React, { createContext, useEffect, useContext } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";

import { storeContext } from "./store";
import ServerSide from "./ssr";

const CountryContext = createContext({});

class CountryProviderCore extends React.Component {
  static contextType = storeContext;

  state = {
    country: this.props.country,
    setCurrentCountry: this.setCurrentCountry.bind(this),
    setDefaultCountry: this.setDefaultCountry.bind(this)
  };

  setCurrentCountry(country) {
    this.setState({ country });
  }

  setDefaultCountry() {
    if (!this.context.User.user?.id && !this.state.country) {
      this.setCurrentCountry(this.context.initialState?.current_country);
    }
  }

  componentDidUpdate() {
    const { history } = this.props;
    const isEmployerPage = history.location.pathname.includes("employers");
    const isRemoveCurrentCountry = !isEmployerPage && !!this.context.User.user?.id && !!this.state.country;

    if (isRemoveCurrentCountry) {
      this.setCurrentCountry(null);
    }
  }

  render() {
    return <CountryContext.Provider value={this.state}>{this.props.children}</CountryContext.Provider>;
  }
}

export const CountryProvider = observer(withRouter(CountryProviderCore));

export const useDefaultCountry = () => {
  const { hydrated } = useContext(ServerSide);
  const { setDefaultCountry } = useContext(CountryContext);

  useEffect(() => {
    if (!hydrated) setDefaultCountry();
  }, []);
};

export default CountryContext;
