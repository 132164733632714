import React, { useContext, useReducer } from "react";
import { Row, Col, ScreenClassContext } from "react-grid-system";

import { useTranslation } from "b2c/hooks/useTranslation";
import Layout from "b2c/components/Layout";
import { Text, Carousel } from "b2c/components/Core";
import EmbedVideo from "b2c/components/EmbedVideo";
import { refReducer } from "b2c/utils/normalizeData";
import { TestimonialWrapper, TestimonialQuoteIcon, AuthorAvatar } from "./styled";
import LandingContext from "../../../../contexts/landing";

const TestimonialSliderSection = ({ contentSize }) => {
  const [refs, addRef] = useReducer(refReducer, []);
  const screen = useContext(ScreenClassContext);
  const isSm = ["xs", "sm"].includes(screen);
  const { t } = useTranslation();

  const { testimonialSliderData: { testimonials } = {} } = useContext(LandingContext);

  const beforeChange = () => {
    refs?.forEach(({ ref }) =>
      ref?.contentWindow?.postMessage("{ 'event': 'command', 'func': 'stopVideo', 'args': '' }", "*")
    );
  };

  return (
    <TestimonialWrapper>
      <Layout.Content tag="div" size={contentSize}>
        {testimonials?.length > 0 && (
          <Carousel
            className="wide-blue-buttons"
            settings={{
              fade: true,
              autoplay: !testimonials?.some(item => !!item?.videoID),
              autoplaySpeed: 10000,
              beforeChange,
              lazyLoad: false
            }}
          >
            {testimonials?.map(({ id, title, text, videoID, imageUrl, author }) => (
              <Carousel.Slide padding="20px" key={`uid_${id}`}>
                <Row>
                  <Col md={6}>
                    <TestimonialQuoteIcon />
                    <Text margin="0" fontSize={isSm ? 24 : 28} fontWeight={600} loaded width="100%">
                      {t(title)}
                    </Text>
                    <Text fontSize="18px" lineHeight="28px" color="black400" margin="5px 0 15px" width="100%" loaded>
                      {t(text)}
                    </Text>
                    <Text color="black200">{t(author)}</Text>
                  </Col>
                  <Col md={6}>
                    {videoID && !imageUrl ? (
                      <EmbedVideo
                        ref={ref => addRef({ ref, id })}
                        type="text/html"
                        videoID={videoID}
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        frameBorder="0"
                        allowFullScreen
                      />
                    ) : (
                      <AuthorAvatar
                        width={window?.screen?.width > 768 ? "320px" : "100%"}
                        height={window?.screen?.width > 768 ? "320px" : "auto"}
                        src={imageUrl}
                        alt="Author avatar"
                      />
                    )}
                  </Col>
                </Row>
              </Carousel.Slide>
            ))}
          </Carousel>
        )}
      </Layout.Content>
    </TestimonialWrapper>
  );
};

export default TestimonialSliderSection;
