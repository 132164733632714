import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { Plus } from "@styled-icons/boxicons-regular";
import { Row, Col } from "react-grid-system";

import SkillsIco from "images/b2c/profilePage/SkillsIco.svg";
import gear from "images/b2c/profilePage/we_gear.svg";
import SvgContent from "b2c/components/SvgContent";
import { useTranslation } from "b2c/hooks/useTranslation";
import useStore from "../../contexts/store";

import { Text, Tag, Card, SwitchInput, Dropdown } from "../Core";
import SubPageSection from "../SubPageSection";
import SkillSearch from "../SkillSearch";

const Skillset = ({ inProfileWizard, ...props }) => {
  const {
    Profile: {
      state: { loading, processing },
      skills: {
        search = [],
        categories = [],
        selected_skills,
        suggested_skills = [],
        current_category = [],
        max_skills_count = 50
      } = {},
      work_experience_details: { job_title, id, skills: work_skills = [] } = {},
      work_experience_options: { max_skills_count: work_skills_count = 0 } = {},
      initializeSkillset,
      getCategorySkills,
      getSkillsByTerm,
      updateSkills,
      updateWorkExperienceSkills
    }
  } = useStore("User");
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [allowEditing, setEditing] = useState(false);
  const maxSkillCount = id ? work_skills_count : max_skills_count;
  const { t } = useTranslation();

  useEffect(() => {
    initializeSkillset(id);
  }, []);

  useEffect(() => {
    if (!id) setSelectedSkills(selected_skills);
  }, [selected_skills]);

  useEffect(() => {
    if (id) setSelectedSkills(work_skills);
  }, [work_skills]);

  const handleCategorySelect = ids => {
    getCategorySkills(ids);
  };

  const handleSkillSelect = skill => {
    if (selectedSkills && selectedSkills.some(({ id: ids }) => ids == skill.id)) {
      setSelectedSkills(prev => prev.filter(({ id: ids }) => ids != skill.id));
    } else if (currentLength < maxSkillCount) setSelectedSkills(prev => prev.concat(skill));
  };

  const editSelected = skill => {
    if (!skill.work_experience_related || allowEditing) handleSkillSelect(skill);
  };

  const getSelectedProps = skill => ({
    removable: !skill.work_experience_related ? true : allowEditing,
    color: skill.work_experience_related ? (allowEditing ? "black500" : "black300") : "black500",
    borderColor:
      selectedSkills && selectedSkills.some(({ id: ids }) => ids == skill.id)
        ? skill.work_experience_related
          ? "grey300"
          : "primary300"
        : "grey300",
    bgColor: skill.work_experience_related ? (allowEditing ? "white" : "grey100") : "white"
  });

  const plusIcon20px = () => <Plus size={20} />;
  const getTagStyles = skill => ({
    Icon: selectedSkills && selectedSkills.some(({ id: ids }) => ids == skill.id) ? null : plusIcon20px,
    rightAlignIcon: true,
    borderColor: selectedSkills && selectedSkills.some(({ id: ids }) => ids == skill.id) ? "primary300" : "grey300",
    bgColor: selectedSkills && selectedSkills.some(({ id: ids }) => ids == skill.id) ? "primary100" : "white"
  });

  const currentLength = selectedSkills ? selectedSkills.length : 0;

  const disableSubmit = !id && (!selectedSkills || selectedSkills.length === 0);

  useEffect(() => {
    if (window.scrollY) {
      window.scroll(0, 0);
    }
  }, []);

  return (
    <SubPageSection
      title={id ? t("wizard.skills.sub-title_1") : t("wizard.skills.sub-title_2")}
      sectionIcon={
        <SvgContent>
          <SkillsIco />
        </SvgContent>
      }
      loading={loading}
      processing={processing}
      disableSubmit={disableSubmit}
      dynamicFields={{
        skill_ids: selectedSkills ? selectedSkills.map(skill => skill.id) : []
      }}
      submitAction={formData => (id ? updateWorkExperienceSkills(id, formData) : updateSkills(formData, allowEditing))}
      testScope="title"
      {...props}
    >
      {() => (
        <>
          {id && job_title && (
            <Text margin="15px 0">
              {`${t("wizard.skills.pick_skills")} `} {job_title}.
            </Text>
          )}

          <Row align="end">
            <Col sm={6}>
              <SkillSearch
                loading={loading}
                processing={processing}
                list={search}
                handleSearch={getSkillsByTerm}
                handleSelect={skill => handleSkillSelect(skill)}
              />
            </Col>
          </Row>

          {!id && !inProfileWizard && (
            <div className="editing-switch">
              <SwitchInput
                aria-label={t("wizard.skills.allow_editing")}
                checked={allowEditing}
                onChange={() => setEditing(!allowEditing)}
              />
              <Text inline margin="auto 8px" fontWeight={500}>
                {t("wizard.skills.allow_editing")}
              </Text>
            </div>
          )}

          {!id && (
            <Text margin="15px 0">
              {t("wizard.skills.selected_skills")}: <b>{currentLength}</b>
              {!inProfileWizard && (
                <>
                  {t("wizard.skills.left")}: <b>{maxSkillCount - currentLength}</b>
                </>
              )}
            </Text>
          )}

          <div className="skill-list selected-list" data-test-scope="selected-skills">
            {(selectedSkills || [])
              .slice()
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .sort(skill => (skill.work_experience_related ? 1 : -1))
              .map(skill => (
                <Tag
                  key={skill.name}
                  name={skill.name}
                  tooltipContent={skill.description}
                  RemovableBorderColor="secondary300"
                  onRemove={() => editSelected(skill)}
                  Icon={skill.work_experience_related && gear}
                  {...getSelectedProps(skill)}
                />
              ))}
          </div>

          <Text fontWeight={id ? 400 : 600} margin="16px 0 0 0">
            {t("wizard.skills.select_suggested")}:
          </Text>

          <Card bordered style={{ margin: "15px 0 30px" }} testScope="suggested-skills">
            <Card.Content row padding={20} className="skill-list">
              {suggested_skills.length > 0 ? (
                suggested_skills
                  .slice()
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map(skill => (
                    <Tag
                      key={skill.name}
                      name={skill.name}
                      removable={false}
                      tooltipContent={skill.description}
                      onClick={() => handleSkillSelect(skill)}
                      {...getTagStyles(skill)}
                    />
                  ))
              ) : id ? (
                <Text>{t("wizard.skills.select_category")}</Text>
              ) : (
                <Text>{t("wizard.skills.search_skills")}</Text>
              )}
            </Card.Content>
          </Card>

          {!id && (
            <>
              <Col sm={6}>
                <Dropdown
                  label={t("wizard.skills.skill_label")}
                  placeholder={t("wizard.skills.skill_placeholder")}
                  triggerDataCy="skill-categories"
                  id="skill_category"
                  testScope="skill-categories"
                >
                  <Dropdown.Menu disableSorting>
                    {categories.map(({ name, id: ids }) => (
                      <Dropdown.Item key={ids} onClick={() => handleCategorySelect(ids)}>
                        {name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>

              <Card bordered testScope="category-skills">
                <Card.Content row padding={20} className="skill-list">
                  {current_category.length > 0 ? (
                    current_category
                      .slice()
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map(skill => (
                        <Tag
                          key={skill.name}
                          name={skill.name}
                          removable={false}
                          tooltipContent={skill.description}
                          onClick={() => handleSkillSelect(skill)}
                          {...getTagStyles(skill)}
                        />
                      ))
                  ) : (
                    <Text>{t("wizard.skills.skill_empty")}</Text>
                  )}
                </Card.Content>
              </Card>
            </>
          )}
        </>
      )}
    </SubPageSection>
  );
};

export default observer(Skillset);
