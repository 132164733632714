import styled from "styled-components";

export const PricingWrapper = styled.section`
  max-width: 1230px;
  padding: 30px 0;
  width: 100%;
  margin: 0 auto;
  position: relative;
`;

export const TitleWrapper = styled.section`
  margin: 0 auto;
  text-align: center;
`;

export const PricingList = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 40px;
  overflow-x: auto;
  overflow-y: hidden;
  flex-wrap: wrap;
  justify-content: center;

  .b2c-card {
    margin-bottom: 24px;
  }

  .card-header {
    height: auto;
  }
`;

export const ContactUs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: 1008px) {
    flex-direction: row;
    justify-content: space-between;

    .b2c-button {
      &:hover {
        opacity: 0.8;
        color: #fff;
      }
    }
  }
`;

export const InfoContent = styled.div`
  max-width: 930px;
  margin: 0 auto 24px auto;

  @media screen and (min-width: 1008px) {
    margin: 0 auto 40px auto;
  }
`;
