import React, { useContext } from "react";
import { Row, Col, Hidden, ScreenClassContext } from "react-grid-system";

import { useTranslation } from "b2c/hooks/useTranslation";
import cvImage from "images/b2c/landing-page/img_cv.png";
import Layout from "b2c/components/Layout";
import { Button, Title, Text, Image, Card } from "b2c/components/Core";

const FreeCvPromo = ({ style }) => {
  const screen = useContext(ScreenClassContext);
  const isSm = /xs|sm/.test(screen);
  const { t } = useTranslation();

  return (
    <Layout.Section backgroundColor="transparent" padding="16px 0" style={style}>
      <Layout.Content size="lg" tag="div">
        <Card theme="primary">
          <Card.Content padding={0}>
            <Row>
              <Col
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: isSm ? "16px 24px 0" : 32
                }}
              >
                <Title level={2} scales fontWeight={600} width="100%" textAlign={isSm ? "center" : "left"} margin="0">
                  {t("landing_fuzu.cv_promo.title")}{" "}
                  <Text as="span" color="accent300" fontWeight={600} fontSize={isSm ? 24 : 32}>
                    {t("landing_fuzu.cv_promo.free")}
                  </Text>
                </Title>
                <Hidden xs sm>
                  <Text fontSize={16} margin="auto 0 0">
                    {t("landing_fuzu.cv_promo.description")}
                  </Text>
                </Hidden>
                <Button.Link
                  width="fit-content"
                  theme="accent300"
                  margin={isSm ? "12px auto 0" : "auto 0 0"}
                  to="/onboarding"
                >
                  {t("landing_fuzu.cv_promo.create_cv_button")}
                </Button.Link>
              </Col>
              <Col lg="content" sm={12} style={{ display: "flex", overflow: "hidden", height: isSm ? 100 : "auto" }}>
                <Image
                  src={cvImage}
                  width={260}
                  height={230}
                  margin="auto"
                  alt="Get a professional looking CV for free"
                />
              </Col>
            </Row>
          </Card.Content>
        </Card>
      </Layout.Content>
    </Layout.Section>
  );
};

export default FreeCvPromo;
