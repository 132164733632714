import React, { useRef, useState, useEffect, useContext, useMemo } from "react";
import { observer } from "mobx-react";
import { Route, Switch, useHistory } from "react-router-dom";
import { ScreenClassContext, Row, Col } from "react-grid-system";
import { LeftArrowAlt } from "@styled-icons/boxicons-regular";
import { toJS } from "mobx";

import fuzuLogo from "images/new_fuzu_logo_yellow.png";
import fuzuLogoBlack from "images/new_fuzu_logo_black.png";
import { useTranslation } from "b2c/hooks/useTranslation";
import useStore from "../../contexts/store";
import { withAuthorizationRedirect } from "../../components/WithAuth";
import { withTrackedRoute } from "../../hocs";
import BrandedContext, { isWhitelabelled, WhitelabelledHidden } from "../../contexts/branded";
import { Divider, Title, Button, Image } from "../../components/Core";
import Layout from "../../components/Layout";
import Tip from "./components/Tip";
import Details from "./components/Details";
import Steps from "./components/Steps";
import CoverLetter from "./components/CoverLetter";
import Attachments from "./components/Attachments";
import Questions from "./components/Questions";
import Success from "./components/Success";
import Instructions from "./components/Instructions";
import { applicationTypes } from "../../constants/jobSearch";
import { TestAndSurveyHeader } from "./styled";
import BaronaLogo from "../../components/BaronaLogo";
import { baronaEventName } from "../../constants/main";

const ApplicationScreen = ({
  location: { pathname, state },
  history: { push },
  match: {
    params: { id },
    url
  }
}) => {
  const {
    state: { loading, processing },
    steps,
    application,
    submitted,
    attachments,
    validation_errors,
    job_details: jobDetails = {},
    initializeApplication,
    updateCoverLetter,
    getAttachments,
    getAttachmentsEducationItems,
    professionalCertificates,
    getAttachmentsProfessionalCertificates,
    educationsItems,
    updateAttachments,
    switchModalType,
    cancelApplication,
    submitApplication,
    setIsInviteToApplyJob,
    isInviteToApplyJob,
    deleteEducationCertificate,
    applicationAttachmentsErrors,
    getApplicationFlowQuestions,
    postApplicationFlowQuestions,
    getApplicationFlowAnswers,
    questionAnswers,
    cleanApplicationFlowQuestions,
    questions,
    questionsErrors
  } = useStore("Application");
  const detailsRef = useRef();
  const { baronaTrack } = useStore("Tracking");
  const { global_brand: globalBrand } = useStore("initialState");

  const [currentStep, setStep] = useState({});
  const [scroll, setScroll] = useState(0);
  const { employer } = useContext(BrandedContext);
  const screen = useContext(ScreenClassContext);
  const isSm = ["xs", "sm"].includes(screen);
  const isSuccessPage = useMemo(() => pathname.includes("/success"), [pathname]);
  const isQuestionsPage = pathname.includes("/questions");
  const isFastApply = jobDetails?.application_type === applicationTypes.applicationTypeFastApply;
  const isAssigmentRequested = application?.fast_apply_status === applicationTypes.fastApplyAssignmentsRequested;
  const history = useHistory();
  const isInviteToApplyQuery = history.location.search.includes("from_invite_message");
  const whitelabelled = isWhitelabelled();
  const { t } = useTranslation();

  const {
    Profile: { getGeneralInfo }
  } = useStore("User");

  useEffect(() => {
    getGeneralInfo();
  }, []);

  useEffect(() => {
    if (jobDetails?.title) {
      if (pathname?.endsWith("/application")) {
        baronaTrack(baronaEventName.addToCard, {
          itemId: jobDetails.id,
          itemName: jobDetails.title,
          itemCategory: jobDetails.category.name,
          itemVariant: jobDetails.seniority_level.name,
          itemBrand: jobDetails.company_name,
          itemAffiliation: globalBrand.name
        });
      }

      if (isSuccessPage) {
        baronaTrack(baronaEventName.purchase, {
          itemId: jobDetails.id,
          itemName: jobDetails.title,
          itemCategory: jobDetails.category.name,
          itemVariant: jobDetails.seniority_level.name,
          itemBrand: jobDetails.company_name,
          itemAffiliation: globalBrand.name,
          transactionId: jobDetails.id,
          affiliation: globalBrand.name,
          value: "0",
          currency: "EUR"
        });
      }
    }
  }, [jobDetails?.title]);

  const handleInitializeApplication = () => {
    initializeApplication(id, true, false);
  };

  useEffect(() => {
    initializeApplication(id, true, false);
  }, []);

  useEffect(() => {
    if (steps) {
      const current = steps.find(({ variety }) => pathname.includes(variety));
      current && setStep(current);
    }
  }, [steps]);

  useEffect(() => {
    detailsRef.current = jobDetails;
  }, [jobDetails]);

  useEffect(() => {
    if (isInviteToApplyQuery && !isInviteToApplyJob) {
      setIsInviteToApplyJob(true);
    }

    if (!isInviteToApplyQuery && isInviteToApplyJob) {
      history.replace(`${history.location.pathname}?from_invite_message=true`);
    }
  }, [isInviteToApplyQuery, isInviteToApplyJob]);

  useEffect(() => {
    const isApplication = pathname?.endsWith("/application");
    if (isApplication) {
      window.scrollTo(0, scroll);
    }
  }, [pathname]);

  const changeStep = s => {
    const doc = document.documentElement;
    const top = window.scrollY || (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);

    const isApplication = pathname?.endsWith("/application");
    if (isApplication) {
      setScroll(top);
    }

    setStep(s);
  };

  const handleReturn = () => {
    setStep({});
    push(url);
  };

  const handleCancellation = () => {
    if (submitted) {
      cancelApplication();
    } else {
      switchModalType("cancel");
    }
  };

  const handleExternalApply = async () => {
    await submitApplication(jobDetails?.id, application?.id, false, true);
    window.location.reload();
  };

  const handleOpenModal = () => {
    switchModalType("submit");
  };

  const positionPath = {
    pathname: whitelabelled ? "/" : "/job/applications",
    state: { job_id: isSm ? null : jobDetails.id },
    search: state?.search
  };

  const positionPathExperiment =
    !isExternal && !submitted ? { pathname: `/jobs/${jobDetails.id}/application/success` } : positionPath;

  const submitDisabled = !steps?.every(({ completed }) => completed) || application?.submit_step?.completed || loading;

  const isExternal =
    jobDetails?.position_type === "external" || jobDetails?.application_type === "application_type_ats";

  const actionButtons = [
    <Col lg={7} sm={12} key="main">
      <Row>
        <Col lg={6} sm={12}>
          <Button.Link
            data-cy="cancel-application"
            to={positionPath}
            theme="grey200"
            width="100%"
            margin="0 0 16px 0"
            disabled={loading}
            onClick={handleCancellation}
          >
            {isSm ? t("global.cancel") : t("application.cancel_application")}
          </Button.Link>
        </Col>
        <Col lg={5} sm={12}>
          <Button.Link data-cy="leave-application" width="100%" margin="0 0 16px 0" to={positionPath} theme="grey200">
            {submitted ? t("application.back_to_job") : t("application.save_and_finish")}
          </Button.Link>
        </Col>
      </Row>
    </Col>,
    <Col lg={2} sm={12}>
      {!isExternal && !submitted && (
        <Button.Link
          width="100%"
          margin="0 0 16px 0"
          to={positionPathExperiment}
          theme="primary"
          disabled={submitDisabled}
          onClick={handleOpenModal}
          color="primaryButtonLabelColor"
          data-cy="submit-application"
        >
          {t("application.submit_application")}
        </Button.Link>
      )}
    </Col>
  ];

  return (
    <>
      <Route path={url}>
        <TestAndSurveyHeader isBranded={whitelabelled}>
          <Layout.Content
            size="xl"
            tag="div"
            style={{ position: "relative", justifyContent: "center", display: "flex" }}
          >
            <BaronaLogo>
              <Image
                className="brand-logo"
                src={isSuccessPage ? fuzuLogoBlack : employer?.logo || fuzuLogo}
                alt="logo"
                borderRadius="0"
              />
            </BaronaLogo>
          </Layout.Content>
        </TestAndSurveyHeader>
        {!isSuccessPage && !isQuestionsPage && (
          <Layout.Content style={{ paddingTop: 20, paddingBottom: isSm ? 72 : 32 }} size="xl" data-hide-footer-below>
            <WhitelabelledHidden>
              <Route exact path={url}>
                <Button.Link
                  to={positionPath}
                  disabled={loading}
                  icon={<LeftArrowAlt width={20} />}
                  fontSize="14px"
                  theme="black500"
                  margin="0 0 16px"
                  noBackground
                  padding="0"
                >
                  {t("application.return_to_job")}
                </Button.Link>
              </Route>

              {currentStep.title ? (
                <Title level="4" fontWeight={700} margin="0 0 8px">
                  {currentStep.title}
                </Title>
              ) : (
                <Tip type={jobDetails.position_type} employer={jobDetails.company_name} />
              )}
            </WhitelabelledHidden>

            <Switch>
              <Route exact path={url}>
                <Details
                  loading={loading}
                  {...jobDetails}
                  application={application}
                  isAssigmentRequested={isAssigmentRequested}
                  isFastApply={isFastApply}
                />
                {isExternal ? (
                  <Instructions
                    external_fields={jobDetails.external_fields}
                    type={application?.submit_step?.variety}
                    company={jobDetails.company_name}
                    submitApplication={handleExternalApply}
                  />
                ) : (
                  <Steps loading={loading} id={jobDetails?.id} steps={steps} setStep={changeStep} />
                )}
                <Divider margin="20px 0" />
                <Row justify="between">
                  {(isSm ? actionButtons.reverse() : actionButtons).map((children, index) => (
                    <React.Fragment key={`ab-${index}`}>{children}</React.Fragment>
                  ))}
                </Row>
              </Route>

              <Route exact path={`${url}/cover_letter`}>
                <CoverLetter
                  processing={processing}
                  back={handleReturn}
                  cover_letter={application?.cover_letter || ""}
                  handleUpdate={updateCoverLetter}
                />
              </Route>

              <Route exact path={`${url}/attachments`}>
                <Attachments
                  id={id}
                  loading={loading}
                  processing={processing}
                  attachments={attachments}
                  validation_errors={validation_errors}
                  back={handleReturn}
                  substeps={currentStep.substeps}
                  getAttachments={getAttachments}
                  getAttachmentsEducationItems={getAttachmentsEducationItems}
                  professionalCertificates={professionalCertificates}
                  getAttachmentsProfessionalCertificates={getAttachmentsProfessionalCertificates}
                  educationsItems={educationsItems}
                  updateAttachments={updateAttachments}
                  deleteEducationCertificate={deleteEducationCertificate}
                  handleInitializeApplication={handleInitializeApplication}
                  applicationAttachmentsErrors={applicationAttachmentsErrors}
                />
              </Route>
            </Switch>
          </Layout.Content>
        )}
      </Route>

      <Route exact path={`${url}/questions`}>
        <Questions
          processing={processing}
          id={id}
          postApplicationFlowQuestions={postApplicationFlowQuestions}
          getApplicationFlowAnswers={getApplicationFlowAnswers}
          questionAnswers={toJS(questionAnswers)}
          cleanApplicationFlowQuestions={cleanApplicationFlowQuestions}
          getApplicationFlowQuestions={getApplicationFlowQuestions}
          questions={toJS(questions)}
          questionsErrors={toJS(questionsErrors)}
        />
      </Route>
      <Route exact path={`${url}/success`}>
        <Success
          detailInfo={detailsRef.current}
          isFastApply={isFastApply}
          isAssigmentRequested={isAssigmentRequested}
          employer={employer}
        />
      </Route>
    </>
  );
};

export default withTrackedRoute(withAuthorizationRedirect(observer(ApplicationScreen)));
