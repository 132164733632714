import React from "react";
import { useTranslation } from "b2c/hooks/useTranslation";
import { Input, Hint } from "../../../Core";

const ValidateEmail = ({ email, error }) => {
  const onboardingForm = JSON.parse(localStorage.getItem("onboardingForm"));
  const emailFromStorage = onboardingForm?.email || "";
  const { t } = useTranslation();

  return (
    <>
      <Input
        type="email"
        name="email"
        placeholder=""
        fieldRequired
        label={t("onboarding.email.label")}
        defaultValue={email || emailFromStorage}
        errorMessage={error}
      />
      <Hint>{t("onboarding.hint")}</Hint>
    </>
  );
};

export default ValidateEmail;
