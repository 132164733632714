import React, { useState, useContext, useEffect, useCallback } from "react";
import { observer } from "mobx-react";
import { useScreenClass } from "react-grid-system";

import { highlightField, debounce } from "utils/helpers";
import { usePreventSubmission } from "utils/hooks";
import getIndustryStyles from "utils/industryImageMap";
import useStore from "../../contexts/store";
import ThemeContext from "../../contexts/theme";
import { Input, Text, Image } from "../Core";
import { FilterWrapper, EmployerItem, ProcessingState } from "./styled";

const CompanyInput = ({ loading = false, defaultValue = "", onChange, ...inputProps }) => {
  const {
    state: { loading: processing },
    employers,
    getCompanies
  } = useStore("Common");
  const theme = useContext(ThemeContext);
  const [focused, setFocused] = useState(false);
  const [field, setField] = useState("");
  const screen = useScreenClass();
  const isSm = /xs|sm/.test(screen);

  const getCompaniesDebounced = useCallback(debounce(getCompanies, 750), []);

  usePreventSubmission(focused);

  useEffect(() => {
    setField(defaultValue);
  }, [defaultValue]);

  const handleCompanySelect = ({ id, name }) => {
    setField(name);
    setFocused(false);
    onChange(null, "employer");
    onChange(id, "employer_id");
  };

  const handleInput = value => {
    setField(value);
    if (value.length > 2) getCompaniesDebounced(field);
    if (employers.some(item => item.name.toLowerCase() == value.toLowerCase()) && !isSm) {
      const found = employers.find(item => item.name.toLowerCase() == value.toLowerCase());
      setFocused(false);
      onChange(null, "employer");
      onChange(found.id, "employer_id");
    } else {
      if (!focused) setFocused(true);
      onChange(value, "employer");
      onChange(null, "employer_id");
    }
  };
  /*
  const handleUndefined = () => {
    onChange(field, "city");
    onChange(null, "city_id");
    setFocused(false);
  };
  */

  const handleAutoSelect = e => {
    if (e.keyCode == 13) {
      const found = employers[0];
      if (found) handleCompanySelect(found);
    }
  };

  const isMenuVisible = focused && (isSm || field.length >= 3);

  return (
    <FilterWrapper theme={theme}>
      <Input
        disabled={loading}
        value={field}
        onFocus={({ target }) => {
          target.select();
          setFocused(true);
        }}
        onBlur={() => {
          if (!isSm) setFocused(false);
        }}
        onChange={({ target: { value } }) => handleInput(value)}
        onKeyDown={handleAutoSelect}
        autoComplete="off"
        {...inputProps}
      />
      {processing && <ProcessingState size="24px" />}
      {isMenuVisible && (
        <div className="employer-menu">
          {employers.map(company => {
            const { Icon } = getIndustryStyles(company.industries.length > 0 ? company.industries[0].name : "");

            return (
              <React.Fragment key={company.id}>
                <EmployerItem
                  theme={theme}
                  onMouseDown={e => e.preventDefault()}
                  onClick={() => handleCompanySelect(company)}
                >
                  {company.logo ? <Image src={company.logo} /> : <Icon />}
                  <Text fontWeight={500}>{highlightField(company.name, field)}</Text>
                </EmployerItem>
              </React.Fragment>
            );
          })}
        </div>
      )}
    </FilterWrapper>
  );
};

export default observer(CompanyInput);
