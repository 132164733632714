import React from "react";

import FuzuCoin from "images/b2c/fuzu_coin.svg";
import { Card, Icon, Text, Title } from "../../../../components/Core";
import { LoadingContent } from "../../../../components/Core/Spinner";

const ProductInfo = ({ name, amount, description = "", currency = "", points, discounted }) => {
  return (
    <Card bordered>
      <LoadingContent loading={!amount} size="50px" margin="32px auto">
        <Card.Header>
          <Title level={5} fontWeight={500} lineHeight={1} margin="0 8px 0 0">
            {name}
          </Title>
        </Card.Header>
        <Card.Content>
          <Text>{description}</Text>
          {discounted && points > 0 && (
            <Text fontSize={12} fontWeight={400} margin="12px 0">
              <Icon as={FuzuCoin} width={22} height={22} margin="-6px 8px -6px 0" />
              You are using <b>{points}</b> Fuzu Points for this purchase.
            </Text>
          )}
        </Card.Content>
        <Card.Footer theme="grey100">
          <Text fontSize={16} fontWeight={500}>
            Total
          </Text>
          <Text fontSize={16} fontWeight={500} inline>
            {currency} {amount}
          </Text>
        </Card.Footer>
      </LoadingContent>
    </Card>
  );
};

export default ProductInfo;
