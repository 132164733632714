import React from "react";

import { Text, Button, Title } from "../../Core";
import { Modal } from "./styled";

export const ProductNotAvailable = ({ notAvailableText, close }) => {
  return (
    <Modal>
      <Title
        style={{
          fontSize: "24px",
          lineHeight: "130%",
          color: "#292929",
          margin: "0 auto",
          display: "block",
          fontWeight: "600"
        }}
      >
        Product not available in your location
      </Title>
      <Text
        style={{
          fontSize: "14px",
          color: "#000000",
          lineHeight: "140%",
          marginRight: "0",
          marginTop: "16px",
          textAlign: "center"
        }}
      >
        <>{notAvailableText}</>
      </Text>

      <Button.Link
        to="/points"
        theme="primary"
        onClick={close}
        style={{
          fontWeight: "600",
          fontSize: "16px",
          lineHeight: "24px",
          width: "100%",
          display: "block",
          marginTop: "26px",
          textAlign: "center",
          padding: "10px 0"
        }}
      >
        Got it!
      </Button.Link>
    </Modal>
  );
};
