import React from "react";

import CourseReview from "../CourseReview";
import { StyledModalContent } from "./styled";

const ReviewModalContent = ({ close }) => (
  <StyledModalContent>
    <CourseReview onReviewSubmitted={close} />
  </StyledModalContent>
);

export default ReviewModalContent;
