import styled from "styled-components";
import { Search } from "@styled-icons/boxicons-regular";

export const Container = styled.div``;

export const SearchWrapper = styled.div`
  position: relative;
  margin-bottom: 24px;
`;

export const SearchInput = styled.input`
  border-radius: 8px;
  border: 1px solid #c5c5c5;
  background: #fff;
  padding: 17px 16px 17px 40px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #212121;
  display: block;
  width: 100%;

  &:placeholder {
    color: #a4a4a4;
  }
`;

export const SearchInputIcon = styled(Search)`
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  left: 16px;
  top: 50%;
  fill: #a4a4a4;
  margin-top: -8px;
`;

export const Label = styled.span`
  color: #666;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  display: block;
  margin-bottom: 8px;
`;

export const Name = styled.span`
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 16px;

  svg {
    width: 32px;
    height: 24px;
  }
`;

export const NotFound = styled.span`
  color: #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-bottom: 8px;
`;

export const CountryContent = styled.div`
  margin-bottom: 24px;
`;

export const AllCountryContent = styled.div`
  margin-bottom: 124px;
`;
