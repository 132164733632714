import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

const LinkStyle = css`
  display: flex;
  width: 100%;
  padding: 24px 16px;
  background-color: #ffffff;
  color: #292929;
  text-decoration: none;
  cursor: pointer;

  &:visited {
    color: #292929;
  }

  & + & {
    margin-top: 2px;
  }

  ${({ $styled }) => $styled}
`;

export const StyledRootLink = styled(Link)`
  ${LinkStyle}
`;
export const StyledRootExternalLink = styled.a`
  ${LinkStyle}
`;

export const StyledContent = styled.div`
  width: 100%;
  padding-left: 12px;

  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-size: 12px;
  line-height: 100%;
`;

export const StyledIcon = styled.div`
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  position: relative;

  background: transparent;
  border: 1px solid #e1e1e1;
  border-radius: 4px;

  opacity: ${props => (props.expired ? "0.6" : "1")};

  &::after {
    content: "";
    top: 1px;
    left: 1px;
    z-index: 0;
    position: absolute;

    width: 44px;
    height: 44px;

    border-radius: 2px;
    background: #f5f5f5;
  }
`;

export const StyledImage = styled.img`
  top: 1px;
  left: 1px;
  z-index: 1;
  position: absolute;

  width: 44px;
  height: 44px;
  flex-shrink: 0;
`;

export const StyledTitle = styled.div`
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #292929;
  opacity: ${props => (props.expired ? "0.6" : "1")};
`;

export const StyledLocation = styled.div`
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #676767;
  opacity: ${props => (props.expired ? "0.6" : "1")};
`;

export const StyledMetaContainer = styled.div`
  margin-top: 4px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  justify-content: space-between;
`;

export const StyledStatus = styled.div`
  margin-top: 4px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  justify-content: space-between;
  opacity: ${props => (props.expired ? "0.6" : "1")};
`;

export const StyledJobExpiration = styled.div`
  display: flex;
  align-items: center;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #292929;
`;

/* Job statuses */

export const StyledFastApplyStatus = styled.div`
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 100%;
  color: #ffc829;
  margin-left: 4px;
`;

export const StyledEarlyApplicantStatus = styled.div`
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: #018747;
`;
