import React, { useState, useEffect, useRef, forwardRef } from "react";
import { Row, Col } from "react-grid-system";

import { useWindowSize } from "utils/hooks";
import LazyLoader from "../Core/LazyLoader";
import { CarouselHorizontal } from "../Core";
import { Wrapper, Expanding, HeaderRow, FooterRow } from "./styled";

const Section = ({
  Header,
  Footer,
  Empty,
  ShowMoreButton,
  onSectionMount,
  columns = 4,
  expandable = false,
  expanded = false,
  children,
  testScope,
  narrowCarousel,
  mobileMargin,
  ...rest
}) => {
  const [contentHeight, setContentHeight] = useState(0);
  const { width } = useWindowSize(250);
  const contentRef = useRef(null);

  useEffect(() => {
    contentRef.current && expandable && setContentHeight(contentRef.current.offsetHeight);
  }, [contentRef, expanded, width]);

  const columnCount = columns <= 4 ? Math.ceil(12 / columns) : 3;
  const items = React.Children.toArray(children);
  const isNarrowCarousel = narrowCarousel;

  const Content = forwardRef(({ loading = false }, ref) => (
    <div ref={ref}>
      {isNarrowCarousel ? (
        <CarouselHorizontal margin="-20px 0 0">
          {items.map((child, index) => (
            <CarouselHorizontal.Slide key={`col_${index + 1}`} width="275px">
              {child && (loading ? React.cloneElement(child, { loading }) : child)}
            </CarouselHorizontal.Slide>
          ))}
          {ShowMoreButton && !loading && (
            <CarouselHorizontal.Slide width="275px">{ShowMoreButton}</CarouselHorizontal.Slide>
          )}
        </CarouselHorizontal>
      ) : (
        <Row>
          {items.map((child, index) => (
            <Col xs={12} sm={6} md={6} lg={columnCount} key={`col_${index + 1}`} style={{ marginBottom: "20px" }}>
              {child && (loading ? React.cloneElement(child, { loading }) : child)}
            </Col>
          ))}
        </Row>
      )}
    </div>
  ));

  return (
    (items.length > 0 || !!Empty) && (
      <Wrapper {...rest} data-test-scope={testScope}>
        {Header && <HeaderRow>{Header}</HeaderRow>}
        {items.length > 0 ? (
          <LazyLoader
            offset={window.innerHeight / 4}
            once
            placeholder={
              <Expanding $height={contentHeight} $narrowCarousel={isNarrowCarousel}>
                <Content ref={contentRef} loading />
              </Expanding>
            }
          >
            <Mounted onSectionMount={onSectionMount}>
              <Expanding $height={contentHeight} $narrowCarousel={isNarrowCarousel} $mobileMargin={mobileMargin}>
                <Content ref={contentRef} />
              </Expanding>
            </Mounted>
          </LazyLoader>
        ) : (
          Empty
        )}
        {Footer && <FooterRow>{Footer}</FooterRow>}
      </Wrapper>
    )
  );
};

export const Mounted = ({ onSectionMount, children }) => {
  useEffect(() => {
    if (onSectionMount) onSectionMount();
  }, []);

  return children || null;
};

export default Section;
