import React from "react";
import { DownArrow, UpArrow } from "@styled-icons/boxicons-solid";

import { StyledAvatar, TriggerWrapper } from "./styled";

const AvatarTrigger = ({ isOpen, onClick, borderColor, avatar, name, isHideArrow, ...rest }) => {
  return (
    <TriggerWrapper className="nav-icon" isOpen={isOpen} onClick={onClick} tabIndex="0" {...rest}>
      <StyledAvatar avatar={avatar} name={name} borderColor={borderColor} />
      {!isHideArrow && (
        <>{isOpen ? <UpArrow width="10px" height="10px" /> : <DownArrow width="10px" height="10px" />}</>
      )}
    </TriggerWrapper>
  );
};

export default AvatarTrigger;
