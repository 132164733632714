import React, { useState, useEffect, useContext } from "react";
import { Redirect } from "react-router-dom";

import { withAuthorizationRedirect } from "b2c/components/WithAuth";
import HistoryListener from "b2c/components/HistoryListener";
import PhoneNumberModal from "b2c/components/ModalContents/PhoneNumber";
import { ProductNotAvailable } from "b2c/components/ModalContents/ProductNotAvailable";
import { useModal } from "../../../utils/hooks";
import { withTrackedRoute } from "../../hocs";
import ThemeContext from "../../contexts/theme";
import useStore from "../../contexts/store";
import LeaveModalContent from "./components/LeaveModalContent";
import StrengthCheck from "./components/StrengthCheck";
import TemplateSelect from "./components/TemplateSelect";
import Customize from "./components/CustomizationStep";
import { BuilderWrapper, CloseIcon } from "./styled";
import { isBaronaBrand } from "../../contexts/branded";

const CvBuilderScreen = ({ location: { state } }) => {
  const isBarona = isBaronaBrand();

  if (isBarona) {
    return <Redirect to="/" />;
  }

  const { initialPath, setInitialPath } = useStore("CvBuilder");
  const { user } = useStore("User");

  const { white } = useContext(ThemeContext);
  const [step, setStep] = useState(state?.step || 1);
  const { features } = useStore("Premium");

  const isFreeProduct = features?.premium_cv_creator?.activated;
  const isNotActiveProduct =
    features?.premium_cv_creator?.available === false && features?.premium_cv_creator?.activated === false;
  const notAvailableText = user?.product_country?.name
    ? `Unfortunately, CV Builder is not (yet) available in ${user?.product_country?.name}.`
    : "";

  const handleShowProductNotAvailable = useModal(
    <ProductNotAvailable notAvailableText={notAvailableText} />,
    "",
    () => ({}),
    false
  );

  const openModalPhoneNumber = useModal(
    <PhoneNumberModal
      isFreeProduct={isFreeProduct}
      isNotActiveProduct={isNotActiveProduct}
      notAvailableText={notAvailableText}
    />,
    "",
    () => {},
    false
  );

  const openLeaveModal = useModal(<LeaveModalContent />, "");

  const handleBackwards = (_, action) => {
    if (action === "POP" && step > 1) setStep(step - 1);
  };

  const CloseButton = props => {
    return <CloseIcon onClick={() => openLeaveModal({ from: initialPath || state?.from })} {...props} />;
  };

  const renderStep = () => {
    switch (step) {
      default:
      case 1:
        return <StrengthCheck CloseButton={CloseButton} setStep={setStep} />;
      case 2:
        return <TemplateSelect CloseButton={CloseButton} setStep={setStep} />;
      case 3:
        return <Customize CloseButton={CloseButton} setStep={setStep} update={state?.update} />;
    }
  };

  useEffect(() => {
    if (!user?.product_country?.phone_code) {
      openModalPhoneNumber();
    }
  }, [user]);

  useEffect(() => {
    if (isNotActiveProduct && user?.product_country?.phone_code) {
      handleShowProductNotAvailable();
    }
  }, [user, isNotActiveProduct]);

  useEffect(() => {
    if (state?.from) setInitialPath(state?.from);
  }, [state?.from]);

  return (
    <>
      <HistoryListener callback={handleBackwards} />
      <BuilderWrapper backgroundColor={white}>{renderStep()}</BuilderWrapper>
    </>
  );
};

export default withTrackedRoute(withAuthorizationRedirect(CvBuilderScreen));
