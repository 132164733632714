import React, { useContext } from "react";

import { Row, Col, ScreenClassContext } from "react-grid-system";
import {
  HeadingComponent,
  Content,
  Title,
  Text,
  GetAppButton,
  LearnMore,
  QuickStats,
  QuickStatsItem,
  QuickStatsTitle,
  QuickStatsText,
  Activity,
  FlexTablet,
  LeftSide,
  RightSide
} from "./styled";
import HomeScreen from "../HomeScreen";
import Availability from "../Availability";

const Heading = ({ getAppRef, smartNotificationRef }) => {
  const screen = useContext(ScreenClassContext);
  const isSm = ["xs", "sm", "md", "lg"].includes(screen);

  const handleClickGetApp = () => {
    getAppRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleClickSmartNotifications = () => {
    smartNotificationRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <HeadingComponent>
      <Content>
        <Row>
          <Col xs={12} xl={7}>
            <LeftSide>
              <Title>
                Africa’s
                <span> No.1 </span>
                Jobs and Career Growth App
              </Title>
              {isSm && <HomeScreen />}
              <Text>
                Make the most of every opportunity with Fuzu Android App! Bring your experience to the next level with
                your favourite Fuzu features plus more
              </Text>
              <FlexTablet>
                <Availability />
                <Activity>
                  <GetAppButton onClick={handleClickGetApp} type="button">
                    Get App
                  </GetAppButton>
                  <LearnMore onClick={handleClickSmartNotifications} type="button">
                    Learn more
                  </LearnMore>
                </Activity>
              </FlexTablet>

              <QuickStats>
                <QuickStatsItem>
                  <QuickStatsTitle>5,000 +</QuickStatsTitle>
                  <QuickStatsText>monthly job vacancies</QuickStatsText>
                </QuickStatsItem>
                <QuickStatsItem>
                  <QuickStatsTitle>3,000 +</QuickStatsTitle>
                  <QuickStatsText>online courses and articles</QuickStatsText>
                </QuickStatsItem>
                <QuickStatsItem>
                  <QuickStatsTitle>2M +</QuickStatsTitle>
                  <QuickStatsText>people in the Fuzu career community</QuickStatsText>
                </QuickStatsItem>
              </QuickStats>
            </LeftSide>
          </Col>
          {!isSm && (
            <Col xl={5}>
              <RightSide>
                <HomeScreen />
              </RightSide>
            </Col>
          )}
        </Row>
      </Content>
    </HeadingComponent>
  );
};

export default Heading;
