import { observer } from "mobx-react";
import React, { useState, useEffect } from "react";
import { useScreenClass } from "react-grid-system";
import { useTranslation } from "b2c/hooks/useTranslation";
import { Text, Button, Title, Dropdown, Input, Hint } from "../../Core";
import { Modal, PhoneNumberContent, DropdownContent } from "./styled";
import useStore from "../../../contexts/store";
import { useModal } from "../../../../utils/hooks";
import { ProductFree } from "../ProductFree";
import { ProductNotAvailable } from "../ProductNotAvailable";

const PhoneNumberModal = ({ isShowProductFree, isNotActiveProduct, handleCallBack, notAvailableText }) => {
  const [selectedCode, setSelectedCode] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const handleShowProductFree = useModal(<ProductFree />, "");
  const handleShowProductNotAvailable = useModal(<ProductNotAvailable notAvailableText={notAvailableText} />, "");
  const [codes, setCodes] = useState([]);
  const screenClass = useScreenClass();
  const isSm = /xs|sm/.test(screenClass);
  const { t } = useTranslation();

  const {
    getCountries,
    allCountries,
    state: { loading }
  } = useStore("Common");

  const {
    user,
    Profile: {
      addPhoneNumber,
      state: { processing, error }
    }
  } = useStore("User");

  const handleSave = () => {
    addPhoneNumber({
      phone: {
        code: selectedCode,
        number: phoneNumber
      },
      isShowProductFree,
      isNotActiveProduct,
      handleShowProductFree,
      handleShowProductNotAvailable,
      handleCallBack
    });
  };

  useEffect(() => {
    if (user?.preferred_countries) {
      const findNumber = codes.find(code => user.preferred_countries.some(o1 => o1.name === code.name));

      if (findNumber) {
        setSelectedCode(findNumber.phone_code);
      }
    }
  }, [user, codes]);

  useEffect(() => {
    if (!codes.length) {
      getCountries();
    }
  }, []);

  useEffect(() => {
    if (!codes.length && allCountries.length) {
      setCodes(allCountries);
    }
  }, [allCountries]);

  const findCode = codes.find(code => user.preferred_countries.some(country => country.name === code.name));

  return (
    <Modal>
      <Title
        style={{
          fontSize: "24px",
          lineHeight: "130%",
          color: "#292929",
          margin: "0 auto",
          display: "block",
          fontWeight: "600"
        }}
      >
        {t("promo.phone_number_title")}
      </Title>
      <Text
        style={{
          fontSize: "14px",
          color: "#000000",
          lineHeight: "140%",
          marginTop: "16px",
          textAlign: "center"
        }}
      >
        {t("promo.phone_number_description")}
      </Text>

      <PhoneNumberContent>
        <DropdownContent>
          <Dropdown
            name="codes"
            id={selectedCode}
            label="Phone number"
            defaultValue={findCode?.name ? `${findCode.name} (${findCode.phone_code})` : t("global.select")}
          >
            <Dropdown.Menu
              disableSorting
              disableSearch={isSm}
              style={{
                margin: 0,
                height: "180px",
                maxWidth: "100%",
                position: isSm ? "absolute" : "",
                top: isSm ? "70px" : ""
              }}
              currentValue={selectedCode}
            >
              {codes.map(({ name, phone_code }) => (
                <Dropdown.Item key={name} value={`${name} (${phone_code})`} onClick={() => setSelectedCode(phone_code)}>
                  {name} ({phone_code})
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </DropdownContent>

        <Input
          type="tel"
          name="phone"
          style={{ width: "100%" }}
          width="100%"
          value={phoneNumber}
          onChange={event => setPhoneNumber(event.target.value)}
        />
      </PhoneNumberContent>
      {error && (
        <Text
          style={{
            fontSize: "14px",
            color: "#C30000",
            lineHeight: "140%",
            marginBottom: "16px"
          }}
        >
          {t("promo.invalid_number_error")}
        </Text>
      )}

      <Hint>{t("promo.hint")} </Hint>
      <Button
        disabled={!selectedCode || !phoneNumber}
        onClick={handleSave}
        theme="primary"
        processing={processing || loading}
        style={{
          fontWeight: "600",
          fontSize: "16px",
          lineHeight: "24px",
          width: "100%",
          display: "block",
          marginTop: "26px"
        }}
      >
        {t("global.save_button")}
      </Button>
    </Modal>
  );
};

export default observer(PhoneNumberModal);
