import styled from "styled-components";

export const Label = styled.label`
  color: #333;
  font-size: 14px;
  font-weight: 500;
  display: block;
  margin-bottom: 8px;
`;

export const LastUpdated = styled.span`
  color: #666;
  font-size: 12px;
  font-weight: 400;
  display: block;
  margin-top: 16px;
`;

export const CheckList = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 8px;
`;

export const CheckItem = styled.span`
  color: ${({ $active }) => ($active ? "#00B55E" : "#666")};
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 4px;

  svg {
    width: 16px;
    height: 16px;
    flex: none;
    fill: ${({ $active }) => ($active ? "#00B55E" : "#676767")};
  }
`;

export const EmptyCycle = styled.span`
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid #c5c5c5;
`;
