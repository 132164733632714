import styled from "styled-components";

export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  max-width: 600px;
  margin-top: -43px;

  @media screen and (min-width: 768px) {
    //margin-top: -60px;
  }
`;

export const Title = styled.h5`
  color: #333;
  font-size: 16px;
  font-weight: 600;
  line-height: 170%;
  margin: 0 0 16px 0;
  text-align: left;

  @media screen and (min-width: 768px) {
    font-size: 24px;
    font-weight: 500;
    line-height: 120%;
    margin: 0 0 24px 0;
  }
`;

export const Description = styled.p`
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  display: inline-block;
  margin: 0 0 16px 0;

  @media screen and (min-width: 768px) {
    margin: 0 0 24px 0;
  }
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  gap: 8px;
  width: 100%;

  @media screen and (min-width: 768px) {
    flex-direction: row-reverse;
    justify-content: flex-start;
    gap: 16px;
  }
`;

export const Cancel = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 11px 12px;
  border-radius: 8px;
  background: #eee;
  color: #333;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  cursor: pointer;
  width: 100%;

  @media screen and (min-width: 768px) {
    width: 153px;
  }
`;

export const Submit = styled.button`
  border-radius: 8px;
  background: #eb3737;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;

  @media screen and (min-width: 768px) {
    width: 172px;
  }
`;
