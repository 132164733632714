import React from "react";

import { useModal } from "utils/hooks";
import { Share } from "@styled-icons/boxicons-regular";
import { Button, Text } from "../Core";
import { ShareModalContent } from "../ModalContents";
import useStore from "../../contexts/store";
import { useTranslation } from "../../hooks/useTranslation";

const DefaultButton = props => {
  return (
    <Button.Iconed theme="grey100" {...props}>
      <Share width={24} style={{ transform: "rotateY(180deg)" }} />
    </Button.Iconed>
  );
};

const ShareButton = ({ path, name, prefix = "", handlePictureDownload, Component = DefaultButton, ...rest }) => {
  const generatedUrl = path && path?.includes("http") ? path : window.location.origin + path;
  const url = path ? generatedUrl : window.location.href;
  const { global_brand } = useStore("initialState");
  const globalBrandName = global_brand.name.toLowerCase();
  const { t } = useTranslation();

  const openModal = useModal(
    <ShareModalContent url={url} name={name} handlePictureDownload={handlePictureDownload} />,
    <>
      <Text>
        {t("global.share.title")} {prefix}
      </Text>
      {name && (
        <Text fontSize={16} fontWeight={600} maxLength={48}>
          {name}
        </Text>
      )}
    </>
  );

  const handleShare = e => {
    e.preventDefault();
    e.stopPropagation();
    openModal();
  };

  // Acts as a link for SEO purposes (so that GoogleBot and others can find the URL link)
  return (
    <Component
      type="button"
      tag="a"
      className={`article__icon_${globalBrandName}`}
      href={url}
      onClick={handleShare}
      {...rest}
    />
  );
};

export default ShareButton;
