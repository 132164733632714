import { Link as LinkIcon } from "@styled-icons/boxicons-regular/Link";
import { FacebookSquare, Github, LinkedinSquare, Youtube } from "@styled-icons/boxicons-logos";
import React from "react";

const Icon = ({ type, ...rest }) => {
  switch (type) {
    default:
      return <LinkIcon {...rest} />;
    case "linkedin":
      return <LinkedinSquare {...rest} />;
    case "github":
      return <Github {...rest} />;
    case "facebook":
      return <FacebookSquare {...rest} />;
    case "youtube":
      return <Youtube {...rest} />;
  }
};

export default Icon;
