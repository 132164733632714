import React, { useEffect } from "react";
import styled from "styled-components";

import guestPromptImage from "images/b2c/guest_prompt.png";
import { Image, Button, Text } from "../Core";

const ModalContent = ({ onClose }) => {
  useEffect(() => {
    return () => {
      onClose();
    };
  }, []);

  return (
    <StyledContent>
      <Image src={guestPromptImage} alt="Get Started" height="24vh" />
      <Text fontWeight={500} margin="24px auto">
        Are you ready for success?
      </Text>
      <Text margin="auto auto 24px" lineHeight="21px">
        Fuzu is here to help you out to succeed in life and to grow at every turn. By registering we will be able to
        provide you with personalised support, jobs that match your interests, learning opportunities that align with
        your ambitions and much more.
      </Text>
      <Text margin="auto auto 24px">All for free!</Text>
      <Button.Signup width={200} margin="0 auto" onClick={onClose}>
        Get Started
      </Button.Signup>
    </StyledContent>
  );
};

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 650px;
`;

export default ModalContent;
