import React, { useLayoutEffect, useEffect } from "react";
import { observer } from "mobx-react";
import { LeftArrowAlt } from "@styled-icons/boxicons-regular";

import { useNotification, useQueryParams } from "utils/hooks";
import NotFoundContent from "b2c/components/NotFoundContent";
import { Button, Card } from "b2c/components/Core";
import { LoadingContent } from "b2c/components/Core/Spinner";
import JobDetails from "b2c/components/JobDetailsItem";
import Layout from "b2c/components/Layout";
import JobsSection from "b2c/components/FeedSections/JobsSection";
import JobViewWrapper from "b2c/components/JobViewWrapper";
import { isCustomJkuat, isCustomPlatformGuest, isWhitelabelled } from "../../../../contexts/branded";
import useStore from "../../../../contexts/store";
import SuggestionComponent from "../Suggestion";
import { useImpressionTrack, useIsBarona, useTriggerModalScroll } from "../../../../hooks";
import ExpiredNotification from "../ExpiredNotification";
import NotFoundNotification from "../NotFoundNotification";
import { baronaEventName } from "../../../../constants/main";
import { useTranslation } from "../../../../hooks/useTranslation";

const JobPageCore = ({ Actions, location: { pathname }, history: { push } }) => {
  const {
    state: { status },
    getPageInfo,
    job_details,
    pageInfo,
    trackJobView,
    clearPageInfo
  } = useStore("JobSearch");
  const isBarona = useIsBarona();

  const query = useQueryParams();
  const { handleSaving } = useStore("Saved");
  const { track, baronaTrack } = useStore("Tracking");
  const { global_brand: globalBrand } = useStore("initialState");

  const { feature_toggles: { minerva_similar_job_recommendation } = {} } = useStore("initialState");
  const { brand } = useStore("initialState");
  const notifyNotFound = useNotification(<NotFoundNotification />, "secondary300");
  const notifyExpired = useNotification(<ExpiredNotification />, "secondary300");
  const { targetRef: similarJobRef } = useImpressionTrack({
    type: "single_job_similar_jobs",
    algorithm: minerva_similar_job_recommendation ? "minerva" : "",
    disable: !job_details?.id
  });
  const whitelabelled = isWhitelabelled();
  const customJkuat = isCustomJkuat();
  const { t } = useTranslation();

  const { triggerContent } = useTriggerModalScroll(true);

  useLayoutEffect(() => {
    getPageInfo({ path: pathname, query });
  }, [pathname]);

  useLayoutEffect(() => {
    if (pageInfo.path) {
      if (pageInfo.expired && !pageInfo.expired_accessable) {
        notifyExpired();
      }
      push(pageInfo.path);
    }
  }, [pageInfo]);

  useEffect(() => {
    if (Number(status) === 404) {
      notifyNotFound();
    }
  }, [status]);

  useLayoutEffect(() => {
    if (job_details?.id) {
      trackJobView(job_details.id);
      baronaTrack(baronaEventName.viewItem, {
        itemId: job_details.id,
        itemName: job_details.title,
        itemCategory: job_details.category.name,
        itemVariant: job_details.seniority_level.name,
        itemBrand: job_details.company_name,
        itemAffiliation: globalBrand.name
      });
    }
  }, [job_details?.id]);

  useEffect(() => {
    return () => {
      clearPageInfo();
    };
  }, []);

  const handleSimilarJobTrack = () => {
    const algorithm = minerva_similar_job_recommendation ? "minerva" : "";
    track("promo_clicked", { type: "single_job_similar_jobs", algorithm });
  };

  return (
    <>
      <Layout.Content
        size="xl"
        id="main"
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "24px 20px 50px"
        }}
      >
        {Number(status) !== 404 && customJkuat && (
          <Button.Link
            to="/"
            fontSize={14}
            noBackground
            theme="black500"
            padding={0}
            style={{ alignSelf: "baseline" }}
            icon={<LeftArrowAlt width={16} />}
          >
            Back
          </Button.Link>
        )}
        <Card shadow>
          <LoadingContent loading={!job_details?.id} size="50px" margin="248px auto">
            {Number(status) !== 404 ? (
              <JobViewWrapper display="block" shadow={false} $guestMode={whitelabelled} overflow="visible">
                <section className="job-content" role="tabpanel" tabIndex="0">
                  <JobDetails
                    single
                    pathname="/job"
                    selected={job_details}
                    saveJob={(id, saved) => handleSaving(id, "job", saved)}
                    Actions={Actions}
                    isWhiteLabelled={whitelabelled}
                  />
                </section>
              </JobViewWrapper>
            ) : (
              <NotFoundContent />
            )}
          </LoadingContent>
        </Card>
      </Layout.Content>

      {!isBarona ? (
        <SuggestionComponent companyName={job_details.company_name} jobTitle={job_details.title} />
      ) : (
        <div />
      )}

      {job_details?.id && (!isCustomPlatformGuest() || customJkuat) && (
        <Layout.Section backgroundColor="white" padding="24px 0">
          <Layout.Content size="xl" tag="div">
            <div ref={similarJobRef}>
              <JobsSection
                filter="similar_to_job"
                title={t("application.success.similar_jobs")}
                count_limit={4}
                job_id={job_details?.id}
                titleProps={{ margin: "0" }}
                onClick={handleSimilarJobTrack}
                noFooter
                brandSlug={brand.slug}
              />
            </div>
          </Layout.Content>
        </Layout.Section>
      )}
      {triggerContent}
    </>
  );
};

export default observer(JobPageCore);
