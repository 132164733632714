import styled from "styled-components";

export const StyledActivityCenter = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  z-index: 98;
  width: 75%;
  height: 100%;
  display: block;
  background-color: #ffffff;
  border-radius: 5px;
  overflow: hidden auto;
  &:focus {
    border: none;
    outline: none;
  }
  ${({ $styled }) => $styled}
`;

export const StyledLabel = styled.div`
  display: inline-block;
  position: relative;
  top: 1px;
  left: 10px;
  font-weight: 500;
  font-size: 14px;
  color: ${props => props.color};
`;

export const StyledMenuHeader = styled.div`
  width: 100%;
  height: 99px;
  margin-bottom: 15px;
  background: ${({ theme }) => theme.primary};
  position: relative;
`;

export const StyledOverlay = styled.div`
  height: 100%;
  background: #1c1e2085;
  position: fixed;
  right: 0px;
  width: 25%;
`;
