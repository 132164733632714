import React from "react";

import { useTranslation } from "b2c/hooks/useTranslation";
import { Modal, Description, Actions, Cancel, HideProfile } from "./styled";

const HideProfileModal = ({ close, onClick }) => {
  const handleHideProfile = () => {
    onClick();
    close();
  };

  const { t } = useTranslation();

  return (
    <Modal>
      <Description>{t("wizard.profile_visibility.modal.description_1")}</Description>
      <Description>{t("wizard.profile_visibility.modal.description_2")}</Description>
      <Actions>
        <Cancel onClick={close}>{t("global.cancel")}</Cancel>
        <HideProfile onClick={handleHideProfile} data-cy="hide-profile">
          {t("wizard.profile_visibility.hide_profile")}
        </HideProfile>
      </Actions>
    </Modal>
  );
};

export default HideProfileModal;
