import styled from "styled-components";
import { theme } from "../../../../contexts/theme";

export const AppBannerStyled = styled.div`
  background-color: #f3f3f3;
  padding: 32px 20px 0 20px;

  @media screen and (min-width: 1240px) {
    padding: 32px 0 0 0;
  }
`;

export const Content = styled.div`
  padding: 16px 19px 16px 16px;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  background-color: #333333;
  border-radius: 12px;
  cursor: pointer;
  position: relative;

  @media screen and (min-width: 768px) {
    padding: 32px;
  }
`;

export const Title = styled.h2`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;

  @media screen and (min-width: 768px) {
    font-size: 32px;
    margin-bottom: 8px;
  }
`;
export const Text = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #ffffff;
  display: none;
  margin: 0 0 24px 0;
  padding-right: 20px;

  @media screen and (min-width: 768px) {
    display: block;
  }
`;

export const Button = styled.button`
  background-color: ${theme.primary};
  border-radius: 5px;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #292929;
  text-align: center;
  padding: 13.5px;
  width: 200px;
  display: none;

  @media screen and (min-width: 768px) {
    display: block;
  }
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;

  @media screen and (min-width: 768px) {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
`;

export const LogoContent = styled.div`
  width: 48px;
  height: 48px;
  margin-right: 16px;
  flex: none;

  img {
    width: 100%;
    height: 100%;
  }

  @media screen and (min-width: 768px) {
    width: 80px;
    height: 80px;
    margin: 0 0 0 auto;
  }
`;

export const ArrowContent = styled.div`
  margin-left: auto;

  @media screen and (min-width: 768px) {
    display: none;
  }
`;
