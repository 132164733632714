import styled from "styled-components";
import teamPhoto from "images/b2c/service/teamPhoto.png";

export const PromoSectionWrapper = styled.section`
  padding: 20px 0;
  background-image: linear-gradient(0, rgb(0 6 16 / 30%), rgb(0 6 16 / 30%)), url(${teamPhoto});
  background-size: cover;
  background-position: center center;
  text-align: center;
  height: 665px;
  max-height: 100vh;
  display: flex;
  align-items: center;
  @media screen and (max-width: 767px) {
    height: 400px;
  }
`;
