import React, { useContext, useEffect, useMemo } from "react";

import { useTranslation } from "b2c/hooks/useTranslation";
import Select from "react-select";
import { observer } from "mobx-react";
import { Tile, TileDescription, TileTitle, ActionsSubmit, Flex } from "../../styled";
import ThemeContext from "../../../../contexts/theme";
import useStore from "../../../../contexts/store";

const LanguageSection = ({ processing, language, updateLanguage }) => {
  const theme = useContext(ThemeContext);
  const [value, setValue] = React.useState({});
  const { global_brand } = useStore("initialState");

  const { t } = useTranslation();

  const onHandleChange = value => {
    setValue(value);
  };

  const handleSubmit = () => {
    updateLanguage(value.value).then(() => {
      window.location.reload();
    });
  };

  const options = useMemo(() => {
    const languages = global_brand.locales.translations;
    return Object.keys(languages).map(item => ({ label: languages[item], value: item }));
  }, [global_brand.locales.translations]);

  useEffect(() => {
    if (!value.value && global_brand.locales.translations) {
      setValue({ value: language, label: global_brand.locales.translations?.[language] });
    }
  });

  return (
    <Tile>
      <TileTitle $isError>{t("settings.language.title")}</TileTitle>
      <TileDescription>{t("settings.language.description")}</TileDescription>

      <Flex>
        <Select
          defaultValue={null}
          options={options}
          isMulti={false}
          className="fz-simple-select"
          classNamePrefix="fz-simple-select"
          isSearchable={false}
          onChange={evt => onHandleChange(evt)}
          value={value}
        />

        {value.value !== language ? (
          <ActionsSubmit
            $background={theme.primaryButtonBackground}
            $color={theme.primaryButtonLabelColor}
            disabled={processing}
            onClick={handleSubmit}
          >
            {t("settings.language.submit_button")}
          </ActionsSubmit>
        ) : null}
      </Flex>
    </Tile>
  );
};

export default observer(LanguageSection);
