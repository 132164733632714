import React, { useContext } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";

import { Text } from "../Core";
import LogoFooter from "../LogoFooter";
import BrandedContext from "../../contexts/branded";
import { Actions, Flex } from "./styled";

const WhitelabelledFooter = ({ isBrandFooterInformation }) => {
  const { employer } = useContext(BrandedContext);
  const country = employer?.address.country.name.toLowerCase();
  const jkuatFooter = isBrandFooterInformation ? "branded-footer_jkuat" : "";
  const currentYear = new Date().getFullYear();

  return (
    <footer
      className={cn("branded-footer", jkuatFooter)}
      onClick={() => {
        if (isBrandFooterInformation) {
          return;
        }

        window.location.replace(
          `https://www.fuzu.com/${["kenya", "uganda", "nigeria"].includes(country) ? `${country}` : ""}`
        );
      }}
    >
      <Flex>
        <Text fontSize={12} color="white" margin={isBrandFooterInformation ? "" : "auto 16px auto 0"} fontWeight={500}>
          Powered by
        </Text>
        <LogoFooter margin={isBrandFooterInformation ? "" : "auto 0px"} />
      </Flex>

      {isBrandFooterInformation && (
        <Actions>
          <Link to="/terms-and-conditions" className="hamburger-link" rel="noopener noreferrer" target="_self">
            Service Terms
          </Link>
          <Link to="/privacy-policy" className="hamburger-link" rel="noopener noreferrer" target="_self">
            Privacy Policy
          </Link>
          <span className="hamburger-text">© Fuzu Ltd {currentYear}</span>
        </Actions>
      )}
    </footer>
  );
};

export default WhitelabelledFooter;
