import styled from "styled-components";

export const TalentPoolWrapper = styled.section`
  background-image: linear-gradient(0deg, rgba(246, 252, 255, 0.0001) 0%, #f7f7f7 100%);
  width: 100%;
  padding-top: 80px;
  @media screen and (max-width: 767px) {
    padding-top: 30px;
  }
`;

export const TalentPoolContent = styled.div`
  width: 100%;
  max-width: 1230px;
  padding: 0 20px;
  margin: 0 auto;
  > div {
    @media screen and (max-width: 767px) {
      flex-direction: column-reverse;
    }
  }
`;

export const TalentInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 30px;
  .lp-info__ico {
    height: 120px;
  }
`;

export const TalentPoolImageWrapper = styled.div`
  img {
    display: block;
    width: 100%;
    height: 100%;
    max-width: 450px;
    margin: 0 auto;
  }
`;
