import React, { useContext, useEffect, useMemo } from "react";
import { ScreenClassContext, Visible } from "react-grid-system";
import { Link } from "react-router-dom";
import { RightArrow } from "@styled-icons/boxicons-solid";

import Logo from "components/Logo";
import yellowFuzuLogo from "images/new_fuzu_logo_yellow.png";
import SmallFuzuLogo from "images/b2c/small-fuzu-logo.svg";
import Highlight from "../Highlight";
import UserAccountMenu from "../UserAccountMenu";
import ThemeContext from "../../contexts/theme";
import { StyledAvatar, StyledAvatarWrapper } from "./styled";
import { ForEmployerOrSeekers } from "../Header/styled";
import BaronaLogo from "../BaronaLogo";

const NavigationMobile = ({
  employers,
  homePath,
  user,
  headerLogo,
  userAccountMenu,
  toggleUserAccountMenu,
  employerText,
  isCompaniesPage,
  isMultiLanguageBrand
}) => {
  const theme = useContext(ThemeContext);
  const screen = useContext(ScreenClassContext);
  const isSm = /xs|sm|md/.test(screen);

  useEffect(() => {
    if (userAccountMenu) {
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [userAccountMenu]);

  const onAvatarClick = e => {
    toggleUserAccountMenu();
    e.stopPropagation();
  };

  const isShowEmployerLink = useMemo(() => {
    return isSm && (isCompaniesPage || employers) && !user?.id && employerText && !isMultiLanguageBrand;
  }, [isSm && (isCompaniesPage || employers) && !user?.id && employerText && !isMultiLanguageBrand]);

  return (
    <Visible md sm xs>
      {user && (
        <Highlight highlightId="menu">
          <StyledAvatarWrapper role="button" tabIndex={0} className="nav-icon" onClick={onAvatarClick}>
            <StyledAvatar avatar={user.avatar} name={user.first_name} borderColor={theme.primary} />
            <RightArrow />
          </StyledAvatarWrapper>
        </Highlight>
      )}
      {user && userAccountMenu && <UserAccountMenu />}
      <div>
        <Link to={employers ? `${homePath}/employers` : `${homePath}`} style={{ display: "flex" }}>
          {headerLogo ? (
            <>
              {isSm && !user?.id ? (
                <BaronaLogo color="#fff" isSmallWidth>
                  <SmallFuzuLogo style={{ display: "flex", width: "36px", height: "36px" }} />
                </BaronaLogo>
              ) : (
                <Logo logo={headerLogo} style={{ display: "flex" }} />
              )}
            </>
          ) : (
            <>
              {isSm && !user?.id ? (
                <BaronaLogo color="#fff">
                  <SmallFuzuLogo style={{ display: "flex", width: "36px", height: "36px" }} />
                </BaronaLogo>
              ) : (
                <BaronaLogo>
                  <Logo logo={yellowFuzuLogo} style={{ display: "flex" }} />
                </BaronaLogo>
              )}
            </>
          )}
        </Link>

        {isShowEmployerLink && (
          <ForEmployerOrSeekers
            margin="0"
            isSmallFontSize={isCompaniesPage}
            color={theme.primaryButtonLabelColor}
            to={employers || isCompaniesPage ? `${homePath}` : `${homePath}/employers`}
          >
            {employerText}
          </ForEmployerOrSeekers>
        )}
      </div>
    </Visible>
  );
};

export default NavigationMobile;
