import styled from "styled-components";

export const StepWrapper = styled.div`
  .step-title {
    margin-bottom: 15px;
    .add-button {
      margin: 0 20px;

      @media screen and (max-width: 767px) {
        width: 100%;
        margin: 16px 20px;
      }
    }
  }
`;
