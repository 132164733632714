import styled from "styled-components";

export const AccessibilityItem = styled.div`
  ${({ backgroundColor }) => `
        display: flex;
        font-size: 14px;
        min-height: 30px;

        &:not(:last-child) {
            margin-bottom: 16px;
        }

        svg {
            border-radius: 5px;
            min-width: 30px;
            height: 30px;
            padding: 4px;
            margin: auto 16px auto 0;
            background: ${backgroundColor};
        }

        p {
            margin: auto 0;
        }
    `}
`;
