import React, { useContext, useLayoutEffect, useMemo } from "react";
import { observer } from "mobx-react";
import { GridAlt } from "@styled-icons/boxicons-regular";

import { usePrev } from "utils/hooks";
import { ScreenClassContext } from "react-grid-system";
import { useTranslation } from "b2c/hooks/useTranslation";
import Item from "./UserItem";
import { Button, Title } from "../../Core";
import CarouselSection from "../../Section/CarouselSection";
import useStore from "../../../contexts/store";

const UsersSection = observer(
  ({
    title,
    filter = "like_me",
    company_id,
    count_limit = 4,
    total_limit = 8,
    expandable = false,
    noHeader = false,
    ...rest
  }) => {
    const {
      state: { loading },
      leaders,
      getSimilarUsers
    } = useStore("Leaders");
    const expanded = false;
    const prevLoading = usePrev(loading);
    const loaded = useMemo(() => prevLoading, [loading, expanded]);
    const item_limit = expanded ? total_limit : count_limit;
    const userList = leaders ? leaders.slice(0, item_limit) : [...new Array(item_limit).keys()];
    const screen = useContext(ScreenClassContext);
    const isSm = /xs|sm/.test(screen);
    const { t } = useTranslation();

    useLayoutEffect(() => {
      if (!userList.length) {
        getSimilarUsers(filter, count_limit);
      }
    }, []);

    return (
      <CarouselSection
        expandable={expandable}
        expanded={expanded}
        columns={4}
        slideMobileWidth="230px"
        style={{
          marginTop: isSm ? 0 : "25px"
        }}
        Header={
          !noHeader && (
            <Title scales level={3} fontWeight={600} color="#292929" className="title_sentence">
              {title}
            </Title>
          )
        }
        ShowAll={
          <Button.Link to="/learn" theme="transparent" style={{ color: "#296ACC", fontSize: "14px", fontWeight: 600 }}>
            <GridAlt width={17} style={{ stroke: "#296ACC", marginRight: "10px" }} />
            {t("global.see_all")}
          </Button.Link>
        }
        {...rest}
      >
        {userList.map((item, index) => (
          <Item key={`user_${filter}_${index}`} {...item} loading={loading} loaded={loaded} />
        ))}
      </CarouselSection>
    );
  }
);

export default UsersSection;
