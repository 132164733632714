import React from "react";
import { useTranslation } from "b2c/hooks/useTranslation";

import { Text, Label } from "b2c/components/Core";
import { TermIcons } from "b2c/constants/pricing";

const Terms = ({ terms }) => {
  const { t } = useTranslation();

  return terms.map(({ name, label }) => {
    const Icon = TermIcons[name];

    return (
      <Label
        color="black500"
        fill="black500"
        fontSize={14}
        fontWeight={400}
        noBackground
        style={{ padding: 0 }}
        margin="14px 0"
        Icon={<Icon width={19} height={19} />}
        key={name}
      >
        <Text fontWeight={600} margin="0 5px 0 0">
          {t(label.bold)}
        </Text>
        <Text>{t(label.ordinary)}</Text>
      </Label>
    );
  });
};

export default Terms;
