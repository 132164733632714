import React from "react";

import { useTranslation } from "b2c/hooks/useTranslation";
import { Title, Text } from "b2c/components/Core";
import { faqList } from "b2c/constants/pricing";
import { EliteFAQSection, EliteFAQContent, FAQItemContent } from "./styled";

export const EliteFAQ = () => {
  const { t } = useTranslation();

  return (
    <EliteFAQSection>
      <EliteFAQContent>
        <Title
          level="2"
          style={{
            fontWeight: "600",
            lineHeight: "160%",
            color: "#000000",
            display: "block",
            margin: "0 auto 32px"
          }}
        >
          {t("pricing.elite_details.faq.title")}
        </Title>
        {faqList.map(({ title, description }) => {
          return (
            <FAQItemContent key={title}>
              <Title
                level="6"
                style={{
                  fontWeight: "600",
                  lineHeight: "160%",
                  color: "#292929",
                  display: "block",
                  marginBottom: "4px"
                }}
              >
                {t(title)}
              </Title>
              <Text
                style={{
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "140%",
                  color: "#292929"
                }}
              >
                {t(description)}
              </Text>
            </FAQItemContent>
          );
        })}
      </EliteFAQContent>
    </EliteFAQSection>
  );
};
