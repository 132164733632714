import React from "react";
import { observer } from "mobx-react";

import JobInput from "../../JobInput";

const InterestedJob = () => {
  return (
    <>
      <JobInput inputProps={{ label: "Job title" }} />
    </>
  );
};

export default observer(InterestedJob);
