import React, { useMemo } from "react";

import { Actions, Cancel, Delete, Description, StyledContent, Title } from "./styled";
import { useTranslation } from "../../../hooks/useTranslation";

const DeleteConfirmation = ({ title, close, handleDelete, name }) => {
  const { t } = useTranslation();

  const onDelete = () => {
    handleDelete().then(() => {
      close();
    });
  };

  const modalTitle = useMemo(() => {
    const titleLowerCase = title.toLowerCase();
    switch (titleLowerCase) {
      case "certificates":
        return "certificate";
      case "language skills":
        return "language";
      case "hobbies":
        return "hobby";
      case "associations":
        return "association";
      case "references":
        return "reference";
      default:
        return titleLowerCase;
    }
  }, [title]);

  return (
    <StyledContent>
      <Title>{t("global.delete_modal.title", { modalTitle })}</Title>
      <Description>{t("global.delete_modal.description", { name })}</Description>
      <Actions>
        <Cancel onClick={close}>{t("global.delete_modal.cancel")}</Cancel>
        <Delete onClick={onDelete}>{t("global.delete_modal.delete")}</Delete>
      </Actions>
    </StyledContent>
  );
};

export default DeleteConfirmation;
