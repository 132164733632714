import React from "react";

import PremiumAlternativeIco from "images/b2c/premium_alternative.svg";
import { FloatingSummary } from "./styled";
import { Card, Icon } from "../../../../components/Core";
import { LoadingContent } from "../../../../components/Core/Spinner";

const Summary = ({ loading, children, isStatic, courseBackground, premium }) => {
  return (
    <FloatingSummary $static={isStatic}>
      <Card shadow>
        <Card.Header padding="16px" height={200} backgroundImage={courseBackground}>
          {premium && (
            <Icon
              as={PremiumAlternativeIco}
              width={40}
              backgroundColor="black500"
              padding="8px"
              additionalCss={{ borderRadius: "50%", height: 40 }}
            />
          )}
        </Card.Header>
        <Card.Content padding="20px">
          <LoadingContent loading={loading} size="50px" margin="50px auto">
            {children}
          </LoadingContent>
        </Card.Content>
      </Card>
    </FloatingSummary>
  );
};

export default Summary;
