import React, { useContext, useMemo } from "react";
import { observer } from "mobx-react";

import { usePrev } from "utils/hooks";
import { ScreenClassContext } from "react-grid-system";
import CarouselSection from "../../Section/CarouselSection";
import Item from "./UnfinishedItem";
import Title from "../../Core/Title";
import useStore from "../../../contexts/store";

const UnfinishedSection = ({ onlyCourses, title, count_limit = 4, total_limit = 10, noHeader, ...rest }) => {
  const { state, unfinished, unfinishedCourses, getUnfinishedItems, getUnfinishedCourses } = useStore("Unfinished");
  const loading = state ? state.loading : true;
  const prevLoading = usePrev(loading);
  const loaded = useMemo(() => prevLoading, [loading]);
  const screen = useContext(ScreenClassContext);
  const isSm = /xs|sm/.test(screen);
  const item_limit = !loading ? total_limit : count_limit;
  const itemList = (() => {
    let list;
    if (onlyCourses) list = unfinishedCourses;
    else list = unfinished;

    return list ? list.slice(0, item_limit) : [...new Array(item_limit).keys()];
  })();

  const onSectionMount = () => {
    if (itemList.length && typeof itemList[0] === "object") {
      return;
    }

    if (onlyCourses) {
      getUnfinishedCourses(total_limit);
      return;
    }
    getUnfinishedItems(total_limit);
  };

  return (
    <CarouselSection
      onSectionMount={onSectionMount}
      columns={4}
      slideMobileWidth="230px"
      style={{
        marginTop: isSm ? 0 : "25px"
      }}
      Header={
        !noHeader && (
          <Title scales level={3} fontWeight={600} color="#292929" className="title_sentence">
            {title}
          </Title>
        )
      }
      {...rest}
    >
      {itemList.map((item, index) => (
        <Item key={`unfinished_${index}`} {...item} loading={loading} loaded={loaded} />
      ))}
    </CarouselSection>
  );
};

export default observer(UnfinishedSection);
