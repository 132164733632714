import React, { useContext } from "react";
import { Row, Col, ScreenClassContext } from "react-grid-system";

import ChatIcon from "images/b2c/icons/chat.svg";
import { useTranslation } from "b2c/hooks/useTranslation";
import Layout from "../Layout";
import { Title, Text, Image } from "../Core";
import LinkSupportChannel from "../LinkSupportChannel";
import { PromoSectionWrapper, Icon } from "./styled";
import { useIsBarona } from "../../hooks";
import ThemeContext from "../../contexts/theme";
import { buildImageUrlS3 } from "../../contexts/branded";
import { nameImages } from "../../constants/images";

const ChatLine = ({ backgroundColor }) => {
  const screen = useContext(ScreenClassContext);
  const isSm = ["xs", "sm"].includes(screen);
  const isBarona = useIsBarona();
  const theme = useContext(ThemeContext);
  const { t } = useTranslation();

  return (
    <PromoSectionWrapper backgroundColor={backgroundColor} paddingTop="32px">
      <Layout.Content tag="div" size="xl">
        <Row align="end">
          <Col sm={12} md={9} style={{ margin: "0 0 20px 0" }}>
            <Title
              level="2"
              style={{ fontSize: isSm ? 20 : 32 }}
              color="#292929"
              fontWeight={500}
              margin="0 0 10px"
              width="100%"
            >
              {t("global.chat_line.title")}
            </Title>
            <Text fontSize={isSm ? 14 : 16} color="#292929" width="100%">
              {isBarona
                ? t("global.chat_line.description")
                : "Fuzu Support Team is here to help and answer any question you might have."}
            </Text>
            <LinkSupportChannel height={48} style={{ fontWeight: 600 }} color="primaryButtonLabelColor">
              <Icon iconColor={theme.primaryButtonLabelColor}>
                <ChatIcon style={{ margin: "0 8px 0" }} />
              </Icon>
              {t("global.chat_line.button")}
            </LinkSupportChannel>
          </Col>
          <Col md={3} xs={0} sm={0} style={{ margin: "-50px 0 0 0" }}>
            <Image src={buildImageUrlS3(nameImages.supportChat)} style={{ margin: "0 0 0 auto", display: "block" }} />
          </Col>
        </Row>
      </Layout.Content>
    </PromoSectionWrapper>
  );
};

export default ChatLine;
