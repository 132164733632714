import React from "react";

import useStore from "b2c/contexts/store";
import { useNotificationsCenterNav } from "b2c/components/NotificationsCenter";

import {
  StyledRoot,
  StyledRootLink,
  StyledRootExternalLink,
  StyledIcon,
  StyledImage,
  StyledTitle,
  StyledContent
} from "./styled";

const NotificationItem = props => {
  const { id, highlight, text, icon_url: image, read, link, onClick } = props;

  const { Notifications } = useStore("User");
  const { open } = useNotificationsCenterNav();

  const actionHandler = () => {
    if (!link) {
      open(id);
    }
    if (onClick) {
      onClick();
    }
    Notifications.makeNotificationRead(id);
  };

  let Wrapper = StyledRoot;
  let wrapperParams = {};

  if (link) {
    const isExternalLink = link[0] !== "/";

    Wrapper = isExternalLink ? StyledRootExternalLink : StyledRootLink;
    wrapperParams = isExternalLink ? { href: link, target: "_blank" } : { to: link };
  }

  return (
    <Wrapper {...wrapperParams} onClick={actionHandler} isUnread={!read}>
      <StyledIcon>{Boolean(image) && <StyledImage src={image} />}</StyledIcon>
      <StyledContent>
        {Boolean(StyledTitle) && <StyledTitle>{highlight}</StyledTitle>}
        {text}
      </StyledContent>
    </Wrapper>
  );
};

export default NotificationItem;
