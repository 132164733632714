import React, { useContext } from "react";
import { observer } from "mobx-react";
import { Col, Row, ScreenClassContext } from "react-grid-system";

import { useDocumentTitle, useAddClassnameOnHomePage } from "utils/hooks";
import { ProfileHighlights } from "b2c/components/ProfileHighlights";
import { useTranslation } from "b2c/hooks/useTranslation";
import { FeatureHidden, withTrackedRoute } from "../../hocs";
import Layout from "../../components/Layout";
import Sections from "../../components/FeedSections";
import PromoSection from "../../components/PromoSection";
import LoadingScreen from "../LoadingScreen";
import { withAuthorizationRedirect } from "../../components/WithAuth";
import Chatline from "../../components/Chatline";
import useStore from "../../contexts/store";
import Highlight from "../../components/Highlight";
import ThemeContext from "../../contexts/theme";
import PromoBuildProfile from "./components/PromoBuildProfile";
import { isCustomJkuat } from "../../contexts/branded";

const Home = () => {
  const { state: { loading } = {}, hero, sections } = useStore("HomeLayout");
  const { userOnBoardingCategoriesTitle } = useStore("Jobs");
  const screen = useContext(ScreenClassContext);
  const isSm = /xs|sm/.test(screen);
  const theme = useContext(ThemeContext);
  const isCustomerSupportChanel = sections.length
    ? sections?.find(item => item.promotion_type === "customer_support_chatline")
    : false;
  const customJkuat = isCustomJkuat();
  const { t } = useTranslation();

  useDocumentTitle(t("home.meta_title"), false);
  useAddClassnameOnHomePage();

  const getSections = () => {
    return sections.reduce((acc, current, index) => {
      acc = acc || [];
      let firstJob;
      let secondJob;

      if (index === 0 && current.type === "jobs" && !customJkuat) {
        firstJob = { ...current };
        secondJob = { ...current };
        secondJob.position = 2;
        secondJob.title = userOnBoardingCategoriesTitle;

        secondJob.filter = "latest&with_category=true";
        firstJob.filter = "latest&with_category=false";
        acc.push(firstJob);
        acc.push(secondJob);
        return acc;
      }
      acc.push({ ...current, position: current.position + 1 });
      return acc;
    }, []);
  };

  const renderPromotions = promotionType => {
    if (promotionType === "customer_support_chatline") {
      return <Chatline backgroundColor="#F5F5F5" />;
    }

    if (promotionType === "promo_build_profile") {
      return <PromoBuildProfile />;
    }

    if (promotionType === "instant_feedback") {
      return (
        <FeatureHidden feature="instant_feedback_global" fallback={null}>
          <PromoSection type={promotionType} />
        </FeatureHidden>
      );
    }

    return <PromoSection type={promotionType} />;
  };

  return !loading ? (
    <>
      <Layout.Section backgroundColor={theme.white} padding={isSm ? "16px 0 0" : "32px 0 0"}>
        <Layout.Content size="xl" tag="div">
          <Row justify="center" align="center">
            <Highlight highlightId="profile">
              <Col sm={12}>
                <ProfileHighlights isShowText />
              </Col>
            </Highlight>
          </Row>
        </Layout.Content>
      </Layout.Section>

      <Layout.Content
        size="xl"
        style={{
          margin: isCustomerSupportChanel
            ? isSm
              ? "12px auto 0"
              : "40px auto 0"
            : isSm
              ? "12px auto 76px"
              : "40px auto 76px",
          backgroundColor: theme.body
        }}
      >
        {Object.keys(hero).length ? <Sections.Heading {...hero} loading={loading} /> : null}

        {getSections().length
          ? [...getSections()]
              .sort((a, b) => a.position > b.position)
              .map(section =>
                section.type === "promotions" ? (
                  <React.Fragment key={`section-${section.position}+${section.promotion_type}+${section.title}`}>
                    {renderPromotions(section.promotion_type)}
                  </React.Fragment>
                ) : (
                  <Sections.FeedSection
                    key={`section-${section.position}+${section.title}`}
                    narrowCarousel
                    {...section}
                  />
                )
              )
          : null}
      </Layout.Content>
    </>
  ) : (
    <LoadingScreen />
  );
};

export default withTrackedRoute(withAuthorizationRedirect(observer(Home)));
