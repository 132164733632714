import React, { useState } from "react";

import { methods } from "b2c/constants/payment";
import { useTranslation } from "b2c/hooks/useTranslation";
import { Title } from "../../../../components/Core";
import { LoadingContent } from "../../../../components/Core/Spinner";
import Mobile from "../Mobile";
import CreditCard from "../CreditCard";
import BankTransfer from "../BankTransfer";
import { PaymentMethodWrapper } from "./styled";
import MethodButton from "../MethodButton";
import PaymentActions from "../PaymentActions";

const PaymentMethod = ({
  processing,
  loading,
  mobile_options,
  bank_options,
  errors,
  getMobileOptions,
  getBankOptions,
  initiatePayment,
  cancelTransaction
}) => {
  const [method, setMethod] = useState({ mobile: {} });
  const { t } = useTranslation();

  return (
    <PaymentMethodWrapper>
      <Title level={5} fontWeight={500} margin="0 0 16px">
        {t("premium_cv.select_payment_method")}
      </Title>

      <LoadingContent loading={loading} size="72px" margin="72px auto">
        <div className="method-group">
          {Object.keys(methods).map(key => (
            <MethodButton key={key} active={!!method[key]} onClick={() => setMethod({ [key]: {} })} {...methods[key]} />
          ))}
        </div>

        {!!method.mobile && (
          <Mobile
            options={mobile_options}
            errors={errors}
            onMount={getMobileOptions}
            onSubmit={(...args) => initiatePayment("mobile", ...args)}
            Actions={props => (
              <PaymentActions processing={processing} cancelTransaction={cancelTransaction} {...props} />
            )}
          />
        )}
        {!!method.credit_card && (
          <CreditCard
            errors={errors}
            onSubmit={body => initiatePayment("credit_card", body)}
            Actions={props => (
              <PaymentActions processing={processing} cancelTransaction={cancelTransaction} {...props} />
            )}
          />
        )}
        {!!method.bank_transfer && (
          <BankTransfer
            options={bank_options}
            onMount={getBankOptions}
            onSubmit={(...args) => initiatePayment("bank_transfer", ...args)}
            Actions={props => (
              <PaymentActions processing={processing} cancelTransaction={cancelTransaction} {...props} />
            )}
          />
        )}
      </LoadingContent>
    </PaymentMethodWrapper>
  );
};

export default PaymentMethod;
