import React from "react";
import { Link } from "react-router-dom";
import { RightArrowAlt } from "@styled-icons/boxicons-regular/RightArrowAlt";

import { Card, Icon, Text } from "b2c/components/Core";
import { FeatureHidden } from "../../../../hocs";

const ProductItem = ({ path, title, slug, description }) => {
  return (
    <FeatureHidden feature={slug}>
      <Card wideOnMobile shadow style={{ margin: "0 0 20px" }}>
        <Card.Content padding="20px 15px">
          <Link to={path} className="link">
            {title}
            <Icon as={RightArrowAlt} fill="secondary200" />
          </Link>
          <Text>{description}</Text>
        </Card.Content>
      </Card>
    </FeatureHidden>
  );
};

export default ProductItem;
