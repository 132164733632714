import React, { useContext } from "react";
import { ScreenClassContext } from "react-grid-system";

import ClipboardIcon from "images/b2c/application/clipboard.svg";
import FileIcon from "images/b2c/application/file.svg";
import { Title, Text, Button, Card, List } from "../../../../components/Core";
import GenerateCvModalContent from "../GenerateCvModalContent";
import { useModal } from "../../../../../utils/hooks";
import { useTranslation } from "../../../../hooks/useTranslation";

const Instructions = ({
  company,
  external_fields: { external_url = "", external_postal_address = "", external_email_address = "" } = {},
  type = "submit_via_email",
  submitApplication
}) => {
  const { t } = useTranslation();
  const screen = useContext(ScreenClassContext);
  const isSm = ["xs", "sm"].includes(screen);
  const openCvModal = useModal(<GenerateCvModalContent />, t("jobs.instructions.cv_modal.title"));

  const { text, url, buttonText, post } = (() => {
    switch (type) {
      default:
      case "submit_via_email":
        return {
          text: t("jobs.instructions.email_description"),
          url: `mailto:${external_email_address}`,
          buttonText: t("jobs.instructions.email_button")
        };
      case "submit_in_employers_site":
        return {
          text: t("jobs.instructions.website_description"),
          url: external_url,
          buttonText: t("jobs.instructions.website_button")
        };
      case "submit_via_post":
        return {
          text: t("jobs.instructions.postal_description"),
          post: external_postal_address
        };
    }
  })();

  return (
    <>
      <Title level={4} fontWeight={600} margin="16px 0">
        1. {t("jobs.instructions.title")}
      </Title>

      <Card style={{ marginBottom: 15 }} bordered wideOnMobile>
        <Card.Header>
          <FileIcon style={{ margin: "0 10px 0 0" }} />
          <Title level={6} fontWeight={700}>
            {t("jobs.instructions.cv.title")}
          </Title>
        </Card.Header>

        <Card.Content padding="16px 24px">
          <Text margin="0 0 16px">{t("jobs.instructions.cv.description")}</Text>
          <Button width={230} margin={isSm ? "0 auto" : "0"} onClick={openCvModal}>
            {t("jobs.instructions.cv.button")}
          </Button>
        </Card.Content>
      </Card>

      <Card style={{ marginBottom: 15 }} bordered wideOnMobile>
        <Card.Header>
          <ClipboardIcon style={{ margin: "0 10px 0 0" }} />
          <Title level={6} fontWeight={700}>
            {t("jobs.instructions.practical_tips_title")}
          </Title>
        </Card.Header>

        <Card.Content padding="16px 24px">
          <Text fontWeight={600} margin="8px 0">
            {t("jobs.instructions.check_list_title")}
          </Text>
          <List>
            <List.Item>{t("jobs.instructions.chick_list_label_1")}</List.Item>
            <List.Item>{t("jobs.instructions.chick_list_label_2")}</List.Item>
            <List.Item>{t("jobs.instructions.chick_list_label_3")}</List.Item>
            <List.Item>{t("jobs.instructions.chick_list_label_4")}</List.Item>
            <List.Item>{t("jobs.instructions.chick_list_label_5")}</List.Item>
          </List>

          <Text fontWeight={600} margin="16px 0 8px">
            {t("jobs.instructions.what_happen_title")}
          </Text>
          <Text fontWeight={500} margin="0 0 10px">
            {t("jobs.instructions.what_happen_description")}
          </Text>

          <List>
            <List.Item>{t("jobs.instructions.what_happen_label_1")}</List.Item>
            <List.Item>{t("jobs.instructions.what_happen_label_2")}</List.Item>
          </List>
        </Card.Content>
      </Card>

      <Title level={4} fontWeight={600} margin="16px 0">
        2. {t("jobs.instructions.submit_title")}
      </Title>

      <Card
        data-cy="submission-instructions"
        style={{ marginBottom: 15 }}
        bordered
        wideOnMobile
        theme="primary50"
        borderColor="primary"
      >
        <Card.Content padding="16px 24px">
          <Title level={6} fontWeight={400} margin="0 0 16px">
            {t("jobs.instructions.submit_description")} {company}. {text}
          </Title>
          {url && (
            <Button
              tag="a"
              width={230}
              margin={isSm ? "0 auto" : "0"}
              href={url}
              target="_blank"
              onClick={submitApplication}
            >
              {buttonText}
            </Button>
          )}
          {post && <Text>{post}</Text>}
        </Card.Content>
      </Card>
    </>
  );
};

export default Instructions;
