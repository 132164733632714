import React, { useContext } from "react";
import { Col, Row, ScreenClassContext } from "react-grid-system";
import { RightArrowAlt } from "@styled-icons/boxicons-regular";

import { Card, Icon, Label, Text } from "b2c/components/Core";
import { HeroItemContainer } from "../HeroItemContainer";

const HeroItem = ({ as = HeroItemContainer, IconComponent, count, title, label, style, ...rest }) => {
  const screen = useContext(ScreenClassContext);
  const isSm = ["xs", "sm"].includes(screen);

  return (
    <Card
      as={as}
      shadow
      style={{ cursor: "pointer", marginBottom: 16, borderRadius: "8px", padding: "8px 0", ...style }}
      {...rest}
    >
      <Card.Content height={80}>
        <Row nowrap style={{ height: "100%" }}>
          {IconComponent && (
            <Col width="content" style={{ display: "flex" }}>
              <Icon as={IconComponent} width={isSm ? 48 : 64} height={isSm ? 48 : 64} margin="auto 0" />
            </Col>
          )}
          <Col
            style={{
              display: "flex",
              flexDirection: "column"
            }}
          >
            <Text margin="auto 0" fontSize={18} fontWeight={600}>
              {title}
            </Text>
            <Text margin="0 0 auto">
              <Label theme="secondary300" display="inline-flex" margin="0 6px 0 0">
                {count}
              </Label>
              {label}
            </Text>
          </Col>
          <Col width="content" style={{ display: "flex" }}>
            <Icon width={20} fill="black500" as={RightArrowAlt} margin="auto" />
          </Col>
        </Row>
      </Card.Content>
    </Card>
  );
};

export default HeroItem;
