import React, { useContext } from "react";

import ThemeContext from "../../../../contexts/theme";
import { StyledButton } from "../PaymentMethod/styled";
import { onEnterPress } from "../../../../../utils/helpers";

const MethodButton = ({ Icon = () => null, active = false, name = "", onClick, ...rest }) => {
  const theme = useContext(ThemeContext);

  return (
    <StyledButton
      theme={theme}
      id={name}
      active={active}
      tabIndex="0"
      role="button"
      aria-label={name}
      onClick={onClick}
      onKeyDown={e => onEnterPress(e, 1, onClick)}
      {...rest}
    >
      <Icon />
      {name}
    </StyledButton>
  );
};

export default MethodButton;
