import { Link } from "react-router-dom";
import React from "react";
import { Text } from "../../../../components/Core";

const NotFoundNotification = ({ close }) => (
  <Text color="white" margin="10px auto">
    Unfortunately, the job you tried to access is not available. Try{" "}
    <Link className="link underlined inherit" onClick={close} to="/job">
      searching for other jobs
    </Link>
    .
  </Text>
);

export default NotFoundNotification;
