import React from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";

import { getTimeSpanString } from "utils/helpers";
import logoPlaceholder from "images/b2c/logo-placeholder.png";
import { Card, Label, Text, Title, Image, Button } from "../../Core";
import SaveButton from "../../SaveButton";
import { GuestVisible } from "../../WithAuth";
import withLoading from "../../withLoading";
import { applicationTypes } from "../../../constants/jobSearch";
import { isWhitelabelledOrCustomBranded, isCustomJkuat } from "../../../contexts/branded";
import useStore from "../../../contexts/store";
import { useTranslation } from "../../../hooks/useTranslation";
import { languageFormat } from "../../../constants/main";

const JobsItem = ({
  company_logo: companyLogo = null,
  company_name: companyName = "Company",
  title = "Position Title",
  campaign_end_date: campaignEndDate,
  location,
  saved,
  loaded,
  brand_tag: brandTag,
  saveItem,
  application,
  expired,
  to,
  application_type,
  className,
  isBiggerHeight
}) => {
  const { t, language } = useTranslation();
  const languageFormatType = languageFormat[language];
  const since = getTimeSpanString(Date.now() - new Date(campaignEndDate), "Closing");
  const formatCurrentDate = new Date(campaignEndDate).toLocaleString(languageFormatType, {
    day: "numeric",
    month: "short",
    year: "numeric"
  });

  const formatNewDate = new Date().toLocaleString(languageFormatType, {
    day: "numeric",
    month: "short",
    year: "numeric"
  });

  const isCurrentDate = formatCurrentDate === formatNewDate;
  const expiredDateText = isCurrentDate ? t("jobs.content.closing_today") : since;

  const isTagShown = application || !!brandTag;
  const isFastApply = application_type === applicationTypes.applicationTypeFastApply;
  const isAssigmentRequested = [
    applicationTypes.fastApplyAssignmentsRequested,
    applicationTypes.fastApplyAssignmentsCompeted
  ].includes(application?.fast_apply_status);
  const isWhitelabelledOrCustom = isWhitelabelledOrCustomBranded();
  const customJkuat = isCustomJkuat();
  const { global_brand } = useStore("initialState");
  const isBarona = global_brand?.name?.includes("Barona");

  const isShowBadgeFastApply = isFastApply && !isAssigmentRequested;

  const badge = isTagShown && {
    title: application?.submitted
      ? t("jobs.content.unfinished")
      : brandTag
        ? `${t("jobs.content.only_on")} ${brandTag}`
        : "",
    color: application?.submitted
      ? "accent400"
      : brandTag === "Fuzu" || Boolean(brandTag) === false
        ? "primary"
        : "secondary300"
  };

  const budgeTitle = () => {
    if (customJkuat) {
      return null;
    }

    if (isShowBadgeFastApply) {
      return t("jobs.content.actions.fast_apply");
    }
    return badge?.title || "";
  };

  return (
    <Card
      as={Link}
      to={to}
      theme="white"
      clickable
      shadow
      bordered
      className={className}
      Button={
        <SaveButton
          aria-label="Save Job"
          saved={saved}
          handleSaving={saveItem}
          title={t("jobs.content.save.title_1")}
          titleSaved={t("jobs.content.save.title_2")}
          isWhitelabelledOrCustom={isWhitelabelledOrCustom}
        />
      }
      badge={isBarona ? null : budgeTitle()}
      badgeTheme={isBarona ? null : badge?.color}
      style={{
        border: "1px solid #E1E1E1",
        height: "100%",
        boxShadow: "none",
        minHeight: "240px"
      }}
    >
      <Card.Header padding="16px 16px 0">
        {companyLogo ? (
          <Image
            backgroundColor="white"
            src={companyLogo}
            alt={companyName}
            maxWidth={100}
            height={60}
            placeholder={logoPlaceholder}
            objectFit="contain"
            objectPosition="center"
            title={companyName}
          />
        ) : (
          <Image
            backgroundColor="white"
            src={logoPlaceholder}
            alt={companyName}
            maxWidth={100}
            height={60}
            placeholder={logoPlaceholder}
            objectFit="contain"
            objectPosition="center"
            title={companyName}
          />
        )}
      </Card.Header>

      <Card.Content padding="16px" height="172px">
        <Title
          color={expired ? "accent400" : "black0"}
          loaded={loaded}
          level={6}
          // height="44px"
          fontWeight="600"
          lineHeight="140%"
          width="100%"
          margin="0 0 4px 0"
          maxLength={60}
          truncatedLine={2}
          title={title}
          style={{ maxHeight: "44px", height: "100%" }}
        >
          {title}
        </Title>
        <Text
          loaded={loaded}
          color="black0"
          fontSize="14px"
          lineHeight="140%"
          margin="0 0 4px 0"
          truncated
          title={companyName}
        >
          {companyName}
        </Text>
        <Text loaded={loaded} color="grey650" fontSize="14px" margin="0" truncated title={location}>
          {location}
        </Text>
        <Card.Footer
          style={{
            padding: "0",
            marginTop: isBiggerHeight ? "4px" : "auto"
          }}
        >
          <Text
            loaded={loaded}
            color={isCurrentDate ? "accent400" : "grey650"}
            fontSize="12px"
            fontWeight={isCurrentDate ? "500" : "400"}
            truncated
          >
            {expiredDateText}
          </Text>
        </Card.Footer>
        {expired && (
          <Label
            rect
            noBackground
            bordered
            theme="accent400"
            height="24px"
            margin="4px 0 0 0"
            style={{ textTransform: "uppercase" }}
          >
            {t("jobs.content.closed_for_applications")}
          </Label>
        )}
        <GuestVisible>
          {!expired && (
            <Button
              color="primaryButtonLabelColor"
              theme="primaryButtonBackground"
              fontSize="14px"
              fontWeight="600"
              height="38px"
              margin="10px 0 0"
              width="100%"
            >
              {t("jobs.content.actions.apply")}
            </Button>
          )}
        </GuestVisible>
      </Card.Content>
    </Card>
  );
};

JobsItem.Placeholder = () => {
  return (
    <Card theme="white">
      <Card.Header padding="12px 16px 0" height={52}>
        <Image
          loading
          width={100}
          height={40}
          objectFit="contain"
          objectPosition="center"
          alt="Company logo placeholder"
          placeholder={logoPlaceholder}
        />
      </Card.Header>

      <Card.Content padding="7px 16px 15px">
        <Title loading level={6} fontWeight={500} margin="0 0 24px" />
        <Text loading margin="0 0 2px" fontSize={12} />
        <Text loading fontSize={12} />
      </Card.Content>
    </Card>
  );
};

export default withLoading(observer(JobsItem), JobsItem.Placeholder);
