import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";

import manualWork from "images/b2c/onboarding/jobInterest/accounting.png";
import office from "images/b2c/onboarding/jobInterest/office.png";
import agriculture from "images/b2c/onboarding/jobInterest/agriculture.png";
import business from "images/b2c/onboarding/jobInterest/business.png";
import social from "images/b2c/onboarding/jobInterest/social.png";
import customerService from "images/b2c/onboarding/jobInterest/customerService.png";
import drivingAndLogistic from "images/b2c/onboarding/jobInterest/driver.png";
import engineering from "images/b2c/onboarding/jobInterest/engineering.png";
import handyman from "images/b2c/onboarding/jobInterest/handyman.png";
import health from "images/b2c/onboarding/jobInterest/health.png";
import hotels from "images/b2c/onboarding/jobInterest/hotels.png";
import human from "images/b2c/onboarding/jobInterest/human.png";
import software from "images/b2c/onboarding/jobInterest/software.png";
import legal from "images/b2c/onboarding/jobInterest/legal.png";
import media from "images/b2c/onboarding/jobInterest/media.png";
import marketing from "images/b2c/onboarding/jobInterest/marketing.png";
import security from "images/b2c/onboarding/jobInterest/security.png";
import teaching from "images/b2c/onboarding/jobInterest/teaching.png";
import designInterest from "images/b2c/onboarding/jobInterest/designInterest.png";
import { useTranslation } from "b2c/hooks/useTranslation";

import { Checkbox, Image } from "../../../../components/Core";
import Content from "../Content";
import { Container, Item, Title, ImageContainer } from "./styled";
import Navigation from "../Navigation";
import useStore from "../../../../contexts/store";
import Steps from "../Steps";

const imageMapper = {
  "Accounting, Banking and Insurance": manualWork,
  Administration: office,
  Agriculture: agriculture,
  "Business and Management": business,
  "Community and social services, non-profits": social,
  "Customer Service": customerService,
  "Driving and Logistics": drivingAndLogistic,
  "Engineering and Manufacturing": engineering,
  "Handyman and Construction": handyman,
  "Health and Wellbeing": health,
  "Hotels, Travel, Food and Restaurants": hotels,
  "Human Resources": human,
  "ICT and Software": software,
  Legal: legal,
  "Media and Languages": media,
  "Sales and Marketing": marketing,
  Security: security,
  "Design, Arts and Entertainment": designInterest,
  "Teaching, Research and Academy": teaching
};

const InterestAreas = () => {
  const [selected, setSelected] = useState([]);
  const history = useHistory();
  const { getInterestAreas, onboardingFlow } = useStore("Onboarding");
  const { t } = useTranslation();

  const handleSelect = event => {
    const { value } = event.target;
    const findJob = selected.find(item => item === +value);

    if (selected.length < 3 || findJob) {
      setSelected(prev => {
        if (prev.includes(+value)) {
          return prev.filter(item => item !== +value);
        }
        return prev.concat(+value);
      });
    }
  };

  const handleSubmit = () => {
    const prevOnboardingForm = JSON.parse(sessionStorage.getItem("onboardingFlow"));

    const onboardingForm = {
      ...prevOnboardingForm,
      interestAreas: selected
    };

    sessionStorage.setItem("onboardingFlow", JSON.stringify(onboardingForm));

    history.push("/onboarding/skills");
  };

  useEffect(() => {
    getInterestAreas();
  }, []);

  useEffect(() => {
    const onboardingFlowStorage = JSON.parse(sessionStorage.getItem("onboardingFlow"));
    const interestAreas = onboardingFlowStorage?.interestAreas;

    if (interestAreas && !selected.length && onboardingFlow.interestAreas?.length) {
      setSelected(interestAreas);
    }
  }, [onboardingFlow.interestAreas?.length]);

  const backgroundColor = id => {
    return selected.length === 3 && !selected.includes(id) ? { backgroundColor: "#EEE" } : {};
  };

  const borderColor = id => {
    return selected.length === 3 && !selected.includes(id) ? { borderColor: "#C5C5C5" } : {};
  };

  return (
    <>
      <Steps width="240px" />

      <Container>
        <Content
          title={t("onboarding.job_interest_detail.title")}
          description={t("onboarding.job_interest_detail.description")}
        >
          {onboardingFlow.interestAreas?.map(({ id, title }) => (
            <Checkbox
              key={id}
              id={id}
              name="job"
              value={id}
              borderedView
              margin="6px 0"
              onChange={handleSelect}
              checked={selected.includes(id)}
              markColor="white"
              checkedColor="secondary200"
              checkedBgColor="secondary100"
              checkboxMarkProps={{
                style: {
                  ...backgroundColor(id),
                  ...borderColor(id)
                }
              }}
              style={{
                outline: "none"
              }}
              labelProps={{
                style: {
                  flexDirection: "row-reverse",
                  justifyContent: "space-between",
                  borderColor: selected.includes(id) ? "#4690FF" : "#C5C5C5",
                  marginBottom: "16px",
                  padding: "15px 16px",
                  borderRadius: "8px",
                  alignItems: "center"
                }
              }}
              label={
                <Item $isDisabled={selected.length === 3 && !selected.includes(id)}>
                  <ImageContainer>
                    <Image src={imageMapper[title] || manualWork} />
                  </ImageContainer>
                  <div>
                    <Title>{title}</Title>
                  </div>
                </Item>
              }
            />
          ))}
        </Content>
      </Container>
      <Navigation backUrl="/onboarding/job-titles" isDisabled={!selected.length} handleSubmit={handleSubmit} />
    </>
  );
};

export default observer(InterestAreas);
