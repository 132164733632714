import React, { useContext } from "react";
import { Row, Col, ScreenClassContext } from "react-grid-system";

import { Mounted } from "./index";
import { Carousel, LazyLoader, CarouselHorizontal } from "../Core";
import { Wrapper, HeaderRow, FooterRow, CarouselWrapper } from "./styled";

const CarouselSection = ({
  Header,
  Footer,
  ShowAll,
  onSectionMount,
  columns = 3,
  testScope,
  settings,
  slidePadding,
  slideMobileWidth,
  ShowMoreButton,
  children,
  isFullHeight,
  isMinDefaultWidth,
  ...rest
}) => {
  const screen = useContext(ScreenClassContext);
  const isSm = ["sm", "xs", "md"].includes(screen);
  const childrenCount = React.Children.count(children);
  const slideCount = !isSm ? columns : 1;

  const items = React.Children.toArray(children);

  const Content = ({ loading = false }) =>
    isSm ? (
      <CarouselHorizontal margin="0 -20px">
        {items.map((child, index) => (
          <CarouselHorizontal.Slide width={slideMobileWidth || `${100 / slideCount}%`} key={`col_${index + 1}`}>
            {child && (loading ? React.cloneElement(child, { loading }) : child)}
          </CarouselHorizontal.Slide>
        ))}
        {ShowMoreButton && (
          <CarouselHorizontal.Slide width={slideMobileWidth || `${100 / slideCount}%`} key="col_show-more`">
            {ShowMoreButton}
          </CarouselHorizontal.Slide>
        )}
      </CarouselHorizontal>
    ) : childrenCount <= slideCount ? (
      <Row
        style={{
          marginTop: "18px"
        }}
      >
        {React.Children.map(children, (child, index) => (
          <Col
            key={index}
            xs={12}
            sm={12 / columns}
            md={12 / columns}
            lg={12 / columns}
            style={{ marginBottom: "20px" }}
          >
            {child && (loading ? React.cloneElement(child, { loading }) : child)}
          </Col>
        ))}
      </Row>
    ) : (
      <CarouselWrapper slidePadding={slidePadding}>
        <Carousel
          settings={{
            className: `wide-blue-buttons slick-arrow-home-page ${isFullHeight ? "full-height" : ""} ${
              isMinDefaultWidth ? "slick-slider_default-min-width" : ""
            }`,
            fade: false,
            dots: false,
            infinite: false,
            slidesToShow: slideCount,
            ...settings
          }}
        >
          {React.Children.map(children, child => child && (loading ? React.cloneElement(child, { loading }) : child))}
        </Carousel>
      </CarouselWrapper>
    );

  return (
    childrenCount > 0 && (
      <Wrapper data-test-scope={testScope} {...rest}>
        {Header && (
          <HeaderRow width={childrenCount <= 4 ? "100%" : "calc(100% - 100px)"}>
            {Header} {ShowAll && !isSm && <>{ShowAll}</>}
          </HeaderRow>
        )}
        <LazyLoader offset={window.innerHeight / 4} once placeholder={<Content loading />}>
          <Mounted onSectionMount={onSectionMount}>
            <Content />
          </Mounted>
        </LazyLoader>
        {Footer && childrenCount > 0 && <FooterRow>{Footer}</FooterRow>}
      </Wrapper>
    )
  );
};

export default CarouselSection;
