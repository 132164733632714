import React from "react";
import { Download, File } from "@styled-icons/boxicons-regular";
import { Close } from "@styled-icons/evaicons-solid";

import { useTranslation } from "b2c/hooks/useTranslation";
import { Icon, Label, Text } from "../Core";
import FileAnchor from "../FileAnchor";
import { ButtonRemove } from "./styled";

const FileLabel = ({ url, fileName, emptyComponent, uploaded, isRemoveIcon, deleteEducationCertificate }) => {
  const { t } = useTranslation();

  return !url && emptyComponent ? (
    emptyComponent
  ) : (
    <>
      <FileAnchor href={url} target="_blank" uploaded={uploaded}>
        <Label
          Icon={(url || fileName) && !uploaded ? <File width={20} /> : null}
          fontSize={14}
          fontWeight={400}
          rect
          width="100%"
          fill="secondary300"
          theme="grey100"
          margin="0"
          padding="15px"
          style={{ justifyContent: "flex-start" }}
        >
          <Text truncated color={fileName ? "black500" : "black200"}>
            {fileName || t("global.no_file_uploaded")}
          </Text>
          {(url || uploaded) && <Icon as={Download} width={20} fill="secondary300" margin="0 0 0 auto" />}
        </Label>
      </FileAnchor>
      {url && !uploaded && isRemoveIcon && (
        <ButtonRemove type="button" onClick={deleteEducationCertificate}>
          <Icon as={Close} width={20} fill="grey700" margin="0 0 0 12px" />
        </ButtonRemove>
      )}
    </>
  );
};

export default FileLabel;
