import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { Row, Col } from "react-grid-system";

import { cardNetworks } from "b2c/constants/payment";
import validateCard, { formatCardNumber } from "utils/ccValidation";
import { Text, Card, Input, Button } from "../../../../components/Core";
import useStore from "../../../../contexts/store";

const CreditCard = ({ onSubmit, errors, disableTransactions, Actions = () => null, ...rest }) => {
  const {
    user: { first_name, last_name }
  } = useStore("User");
  const [cc_network, setNetwork] = useState("Visa");
  const [form, setForm] = useState({
    holder_name: "",
    number: "",
    expiry_month: "",
    expiry_year: "",
    cvv: ""
  });

  const [validation, setValidation] = useState({});

  useEffect(() => {
    if (first_name && last_name) setForm(prev => ({ ...prev, holder_name: `${first_name} ${last_name}` }));
  }, [first_name, last_name]);

  useEffect(() => {
    setValidation(errors);
  }, [errors]);

  const handleSubmission = () => {
    const { valid, error } = validateCard(form.number.replace(/-/g, ""), cc_network);
    if (valid) onSubmit({ ...form, expiry: `${form.expiry_month}/${form.expiry_year}` });
    else setValidation({ number: error });
  };

  const onInput = e => {
    e.persist();
    setValidation(prev => ({ ...prev, [e.target.name]: "" }));
    setForm(prev => ({ ...prev, [e.target.name]: e.target.value.slice(0, e.target.maxLength) }));
  };

  const onCardInput = e => {
    e.persist();
    setValidation(prev => ({ ...prev, number: "" }));
    setForm(prev => ({ ...prev, [e.target.name]: formatCardNumber(e.target.value) }));
  };

  const onExpiryInput = e => {
    e.persist();
    const input = e.target.value;
    if (input.length === 2) setForm(prev => ({ ...prev, expiry_year: " " }));
    const value =
      input.length < 3
        ? { expiry_month: input.replace(/\D|\s\/| /g, "") }
        : { expiry_year: input.replace(/\D|\s\/| /g, "").substring(2, 4) };
    setValidation(prev => ({ ...prev, expiry_month: "", expiry_year: "" }));
    setForm(prev => ({ ...prev, ...value }));
  };

  const disableSubmission = Object.values(form).some(value => !value);

  return (
    <>
      <Card bordered>
        <Card.Content>
          <Text fontWeight={500} margin="8px 0">
            Credit Card Network
          </Text>

          <Row style={{ marginBottom: 16 }}>
            {cardNetworks.map(({ name, Logo }) => (
              <Col sm={2} xs={3} key={name}>
                <Button.Iconed
                  theme={name === cc_network ? "success200" : "white"}
                  bordered
                  width="100%"
                  height={48}
                  type="button"
                  onClick={() => setNetwork(name)}
                >
                  <Logo height="100%" width="80%" />
                </Button.Iconed>
              </Col>
            ))}
          </Row>

          <Col md={8}>
            <Input
              label="Card Holder Name"
              name="holder_name"
              type="text"
              maxLength={120}
              value={form.holder_name}
              onChange={onInput}
            />
          </Col>

          <Col md={8}>
            <Input
              label="Card Number"
              type="tel"
              name="number"
              autocomplete="cc-number"
              placeholder="0000-0000-0000-0000"
              value={form.number}
              onChange={onCardInput}
              errorMessage={validation.number}
            />
          </Col>

          <Row align="end">
            <Col sm={4} xs={6}>
              <Input
                label="Validity Period"
                type="text"
                name="expiry"
                autocomplete="cc-exp"
                placeholder="MM / YY"
                value={`${form.expiry_month}${!form.expiry_year ? "" : " / "}${form.expiry_year}`}
                onChange={onExpiryInput}
              />
            </Col>
            <Col sm={4} xs={6}>
              <Input
                label="CVV Number"
                name="cvv"
                type="number"
                autocomplete="cc-csc"
                maxLength={3}
                value={form.cvv}
                onChange={onInput}
              />
            </Col>
          </Row>
        </Card.Content>
      </Card>

      <Actions disabled={disableSubmission} onSubmit={handleSubmission} {...rest} />
    </>
  );
};

export default observer(CreditCard);
