import React from "react";

import { Text, Title } from "b2c/components/Core";
import { browserList } from "b2c/constants/legacy";
import { withTrackedRoute } from "b2c/hocs";
import Logo from "components/Logo";
import { LegacyComponent, LegacyHeader, LegacyContent, BrowserList, BrowserItem, BrowserImage } from "./styled";

const Legacy = () => {
  const browserListItem = browserList.map(item => {
    return (
      <BrowserItem key={item.name} href={item.url} target="_blank" rel="noopener noreferrer">
        <BrowserImage src={item.imageUrl} alt={item.name} borderRadius="0" />
        <Text style={{ fontSize: "14px", fontWeight: "600", display: "block", color: "#0E50B5", width: "100%" }}>
          {item.name}
        </Text>
      </BrowserItem>
    );
  });

  return (
    <LegacyComponent>
      <LegacyHeader>
        <Logo width="89px" height="26px" />
      </LegacyHeader>
      <LegacyContent>
        <Title style={{ fontSize: "18px", fontWeight: "500", margin: "0 auto 12px" }}>
          Your browser is not supported
        </Title>
        <Text style={{ fontSize: "14px", fontWeight: "400", margin: "0 auto 40px", display: "block" }}>
          You are using a web browser we don’t support currently-. Try one of these options to have a the best
          experience on Fuzu.
        </Text>
        <BrowserList>{browserListItem}</BrowserList>
      </LegacyContent>
    </LegacyComponent>
  );
};

export default withTrackedRoute(Legacy);
