import styled from "styled-components";

const OnboardingWrapper = styled.section`
  display: flex;
  min-height: 100vh;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    margin-bottom: -60px;
  }
`;
const OnboardingLeftContainer = styled.div`
  background-color: #fafafa;
  width: 30%;
  flex: 0 0 30%;
  min-width: 0px;
  padding: 0 20px;
  @media screen and (max-width: 767px) {
    background-color: #fff;
    width: 100%;
    flex: 0 0 100%;
  }
`;
const OnboardingLeftContent = styled.div`
  max-width: 860px;
  width: 100%;
  margin: 0 auto;
`;

const OnboardingRightContainer = styled.div`
  background-color: #fff;
  display: flex;
  justify-content: center;
  width: 70%;
  flex: 0 0 70%;
  min-width: 0px;
  min-height: 100%;
  @media screen and (max-width: 767px) {
    background-color: #fff;
    width: 100%;
    flex: 1 0 100%;
  }
`;
const OnboardingRightContent = styled.div`
  max-width: 890px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const OnboardingAssistantContent = styled.div`
  max-width: 350px;
  width: 100%;
  margin: 150px auto 0;
`;
const OnboardingAssistantImage = styled.img`
  display: block;
  margin: 0 auto;
  max-width: 200px;
  width: 100%;
`;

const StyledLogo = styled.img`
  display: flex;
  width: 73px;
  margin: 45px 0 85px;
  transition: 0.2s ease;
  will-change: filter, transform;
  &:hover {
    filter: drop-shadow(0px 4px 0px #fff5d6);
  }
  &:active {
    transform: scale(1.02);
  }
  @media screen and (max-width: 767px) {
    margin: 25px 0;
  }
`;

const StyledNcpwdLogo = styled.img`
  display: flex;
  max-width: 75%;
  margin: 45px auto 85px;
  transition: 0.2s ease;
  will-change: transform;
  &:active {
    transform: scale(1.02);
  }
  @media screen and (max-width: 767px) {
    margin: 25px 0 35px;
  }
`;

// Inner content styles
const OnboardingContent = styled.div`
  padding: 0 20px;
  flex: 1 0 0;
  overflow: auto;
  @media screen and (max-width: 767px) {
    padding-bottom: 160px;
  }
`;

const OnboardingNav = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 30px 0;
  border-top: 1px solid #e0e0e0;
  margin: 0 20px;
  @media screen and (max-width: 767px) {
    position: fixed;
    padding: 15px 8px;
    width: 100%;
    bottom: 0;
    margin: 0;
    background: ${({ backgroundColor }) => backgroundColor};
  }
`;

const ControlsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Logout = styled.a`
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #eee;
  background: #fff;
  display: block;
  margin-right: auto;
  color: #000;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  text-decoration: none;
  width: 100px;
  align-items: center;
  height: 40px;
  padding-top: 10px;
`;

export {
  StyledLogo,
  StyledNcpwdLogo,
  OnboardingWrapper,
  OnboardingLeftContainer,
  OnboardingLeftContent,
  OnboardingRightContainer,
  OnboardingRightContent,
  OnboardingAssistantContent,
  OnboardingAssistantImage,
  OnboardingContent,
  OnboardingNav,
  ControlsContainer,
  Logout
};
