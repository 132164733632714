import styled from "styled-components";
import ProfileAvatar from "../../../components/ProfileAvatar";

export const StyledAvatar = styled(ProfileAvatar)`
  ${({ borderColor }) => `
        > img,
        div {
            height: 32px;
            width: 32px;
            margin: auto 0;
            border-radius: 5px;
            border: 2px solid ${borderColor};
            font-size: 18px;
        }
    `}
`;

export const StyledAvatarWrapper = styled.div`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
`;
