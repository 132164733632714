import styled from "styled-components";

export const Container = styled.div`
  background-color: #fff;
  padding: 32px 16px;

  @media screen and (min-width: 1008px) {
    padding: 64px 16px;
  }

  @media screen and (min-width: 1440px) {
    padding: 64px 0;
  }
`;

export const Content = styled.div`
  max-width: 1000px;
  width: 100%;

  @media screen and (min-width: 768px) {
    margin: 0 auto;
  }
`;

export const Item = styled.div`
  padding: 32px 0;
  position: relative;

  &::after {
    content: "";
    background-color: #e4e4e4;
    width: calc(100% + 32px);
    height: 1px;
    position: absolute;
    display: block;
    bottom: 0;
    left: -16px;
    margin-top: -0.5px;
    display: none;
  }

  &:first-child {
    padding-top: 0;
  }

  &:first-child:after {
    display: block;
  }

  &:last-child {
    padding-bottom: 0;
  }

  @media screen and (min-width: 1008px) {
    display: flex;
    gap: 64px;
    padding: 64px 0;

    &:first-child {
      flex-direction: row-reverse;
      padding-top: 0;

      &::after {
        width: 632px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &:last-child {
      padding-bottom: 0;
    }
  }
`;

export const Title = styled.h4`
  color: #292929;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 24px;

  @media screen and (min-width: 768px) {
    font-size: 32px;
    line-height: 140%;
  }
`;

export const Text = styled.h4`
  color: #292929;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  margin: 0 0 8px 0;

  &:last-child {
    margin: 0;
  }
`;

export const ImageContent = styled.div`
  width: 328px;
  height: 268px;
  flex: none;
  margin: 0 auto 32px;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  @media screen and (min-width: 1008px) {
    width: 460px;
    height: 374px;
    margin: 0;
  }
`;
