import styled from "styled-components";

export const ButtonRemove = styled.button`
  background-color: #f6f7f9;
  border: none;
  cursor: pointer;
  position: relative;
  z-index: 9;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  flex: none;
  margin-left: -22px;
`;

export const Flex = styled.button`
  display: flex;
  align-items: center;
`;
