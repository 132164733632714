import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { LeftArrowAlt } from "@styled-icons/boxicons-regular";

import { useTranslation } from "b2c/hooks/useTranslation";
import useStore from "../../../../contexts/store";
import WizardStep from "../WizardStep";
import StepProgress from "../../../../components/StepProgress";
import { Title, Text, Button, Card } from "../../../../components/Core";
import HistoryListener from "../../../../components/HistoryListener";
import { isWhitelabelledOrJkuat } from "../../../../contexts/branded";

const ProfileWizard = ({ params, track, onComplete }) => {
  const {
    User: {
      getUserStatus,
      Profile: {
        state: { processing },
        profile: { certificates, educations, languages, skills, work_experiences } = {}
      }
    },
    Application: {
      job_details: { title = "", company_name = "" }
    }
  } = useStore();
  const { id, slug } = params;
  const [currentStep, setStep] = useState(1);
  const whitelabelledOrJkuat = isWhitelabelledOrJkuat();
  const { t } = useTranslation();

  const defaultSteps = [
    t("wizard.tabs.personal_info"),
    t("wizard.tabs.education"),
    t("wizard.tabs.work_experience"),
    t("wizard.tabs.skills"),
    t("wizard.tabs.languages")
  ];
  const profileVisibility = t("wizard.tabs.profile_visibility");

  const profileWizardSteps = !whitelabelledOrJkuat ? [...defaultSteps, profileVisibility] : defaultSteps;

  useEffect(() => {
    window.scrollTo(0, 0);
    track("cv_wizard_step_change", { step: currentStep });
    if (currentStep > profileWizardSteps.length) {
      track("cv_wizard_complete");
      // if (id) initializeApplication(id, true);
      getUserStatus().then(onComplete);
    }
  }, [currentStep]);

  const handleBackwards = (_, action) => {
    if (action === "POP" && currentStep > 1) {
      setStep(currentStep - 1);
    }
  };

  return (
    <>
      <HistoryListener prevent={currentStep < profileWizardSteps.length} callback={handleBackwards} />

      {slug && (
        <Button.Link
          to={`/jobs/${slug}/application`}
          theme="grey200"
          fontSize={14}
          fontWeight={500}
          icon={<LeftArrowAlt width={20} />}
          margin="0 0 16px"
        >
          {t("wizard.return_to_application")}
        </Button.Link>
      )}

      <Title scales level={4} fontWeight={600} margin="0 0 16px">
        {id ? t("wizard.job_application") : t("wizard.build_profile")}
      </Title>

      {title && company_name && (
        <Card theme="grey100" wideOnMobile>
          <Card.Content height={70}>
            <Title truncatedLine={1} maxLength={72} level={6} fontWeight={600}>
              {title}
            </Title>
            <Text>{company_name}</Text>
          </Card.Content>
        </Card>
      )}

      <StepProgress
        width="100%"
        margin="16px 0px 24px"
        steps={profileWizardSteps}
        currentStep={currentStep}
        setStep={setStep}
      />

      <WizardStep
        processing={processing}
        currentStep={currentStep}
        setStep={setStep}
        certificates={certificates}
        educations={educations}
        languages={languages}
        skills={skills}
        work_experiences={work_experiences}
      />
    </>
  );
};

export default observer(ProfileWizard);
