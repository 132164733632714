import Ico3 from "images/b2b/ico_3.png";
import Ico4 from "images/b2b/ico_4.png";
import Ico5 from "images/b2b/ico_5.png";
import { Alarm, BriefcaseAlt, Envelope, IdCard, Show } from "@styled-icons/boxicons-regular";

export const global = {
  id: "global",
  country_code: "global",
  name: "Global",
  slug: "global"
};

export const deliverablesListNames = [
  "pricing.elite_details.delivers.list.description_1",
  "pricing.elite_details.delivers.list.description_2",
  "pricing.elite_details.delivers.list.description_3",
  "pricing.elite_details.delivers.list.description_4",
  "pricing.elite_details.delivers.list.description_5",
  "pricing.elite_details.delivers.list.description_6",
  "pricing.elite_details.delivers.list.description_7",
  "pricing.elite_details.delivers.list.description_8",
  "pricing.elite_details.delivers.list.description_9",
  "pricing.elite_details.delivers.list.description_10",
  "pricing.elite_details.delivers.list.description_11",
  "pricing.elite_details.delivers.list.description_12",
  "pricing.elite_details.delivers.list.description_13"
];

export const deliverablesList = [
  {
    title: "pricing.elite_details.delivers.column-title_2",
    popular: false,
    actives: [true, true, true, false, false, false, false, false, false, false, false, false, false]
  },
  {
    title: "pricing.elite_details.delivers.column-title_3",
    popular: true,
    actives: [true, true, true, true, true, true, true, true, true, true, true, false, false]
  },
  {
    title: "pricing.elite_details.delivers.column-title_4",
    popular: true,
    actives: [true, true, true, true, true, true, true, true, true, true, true, true, true]
  }
];

export const faqList = [
  {
    title: "pricing.elite_details.faq.list.title_1",
    description: "pricing.elite_details.faq.list.description_1"
  },
  {
    title: "pricing.elite_details.faq.list.title_2",
    description: "pricing.elite_details.faq.list.description_2"
  },
  {
    title: "pricing.elite_details.faq.list.title_3",
    description: "pricing.elite_details.faq.list.description_3"
  },
  {
    title: "pricing.elite_details.faq.list.title_4",
    description: "pricing.elite_details.faq.list.description_4"
  },
  {
    title: "pricing.elite_details.faq.list.title_5",
    description: "pricing.elite_details.faq.list.description_5"
  },
  {
    title: "pricing.elite_details.faq.list.title_6",
    description: "pricing.elite_details.faq.list.description_6"
  },
  {
    title: "pricing.elite_details.faq.list.title_7",
    description: "pricing.elite_details.faq.list.description_7"
  }
];

export const icons = [Ico5, Ico3, Ico4];

export const supports = [
  {
    number: 1,
    title: "pricing.elite_details.support.list.title_1",
    list: [
      "pricing.elite_details.support.list.description_1_1",
      "pricing.elite_details.support.list.description_1_2",
      "pricing.elite_details.support.list.description_1_3"
    ]
  },
  {
    number: 2,
    title: "pricing.elite_details.support.list.title_2",
    list: [
      "pricing.elite_details.support.list.description_2_1",
      "pricing.elite_details.support.list.description_2_2",
      "pricing.elite_details.support.list.description_2_3",
      "pricing.elite_details.support.list.description_2_4"
    ]
  },
  {
    number: 3,
    title: "pricing.elite_details.support.list.title_3",
    list: [
      "pricing.elite_details.support.list.description_3_1",
      "pricing.elite_details.support.list.description_3_2",
      "pricing.elite_details.support.list.description_3_3"
    ]
  }
];

export const carouselPoints = [
  { point: 520, padding: "100px" },
  { point: 510, padding: "95px" },
  { point: 500, padding: "90px" },
  { point: 490, padding: "85px" },
  { point: 480, padding: "80px" },
  { point: 470, padding: "75px" },
  { point: 460, padding: "70px" },
  { point: 450, padding: "65px" },
  { point: 440, padding: "60px" },
  { point: 430, padding: "55px" },
  { point: 420, padding: "50px" },
  { point: 410, padding: "45px" },
  { point: 400, padding: "40px" },
  { point: 390, padding: "35px" },
  { point: 380, padding: "30px" },
  { point: 370, padding: "25px" },
  { point: 360, padding: "20px" },
  { point: 350, padding: "15px" },
  { point: 340, padding: "10px" },
  { point: 330, padding: "5px" },
  { point: 320, padding: "0px" }
];

export const TermIcons = {
  job: BriefcaseAlt,
  cv: Show,
  contact: Envelope,
  idCard: IdCard,
  alarm: Alarm
};
