import React, { useContext } from "react";
import { Hidden } from "react-grid-system";

import BaronaLogo from "b2c/components/BaronaLogo";
import blackFuzuLogo from "images/new_fuzu_logo_black.png";
import Logo from "components/Logo";
import { useTranslation } from "b2c/hooks/useTranslation";
import Layout from "../Layout";
import { Button, Text } from "../Core";
import HistoryListener from "../HistoryListener";
import ThemeContext from "../../contexts/theme";
import { FlowWrapper, FlowNavBar, FlowProgress, StyledHeaderContainer } from "./styled";

const FlowNavigation = ({ size = "xl", steps = [], getBack, children }) => {
  const theme = useContext(ThemeContext);
  const currentStep = steps.filter(step => step.current)[0];
  const currentIndex = steps.indexOf(currentStep) + 1;
  const { t } = useTranslation();

  const handleBackwards = (_, action) => {
    if (action === "POP" && currentIndex > 1) getBack();
  };

  let name = currentStep?.name;
  const interest = JSON.parse(localStorage.getItem("onboardingForm"))?.interest;
  const skipState = JSON.parse(localStorage.getItem("onboardingStepSkipped")) ? "skipped" : "notSkipped";
  // NOTE Atte: Step number should be calculated dynamically based on choices which user has made
  // Commented out the current approach because it doesn't apply for different types of flows
  if (!name && currentStep) {
    if (interest in currentStep) {
      if (currentStep[interest][skipState]) {
        // currentStepTitle = currentStep[interest][skipState]?.step;
        name = currentStep[interest][skipState]?.name;
      } else {
        // currentStepTitle = currentStep[interest]?.step;
        name = currentStep[interest]?.name;
      }
    } else if (currentStep[skipState]) {
      // currentStepTitle = currentStep[skipState]?.step;
      name = currentStep[skipState]?.name;
    }
  }

  return (
    <FlowWrapper>
      <HistoryListener prevent={currentIndex < steps.length} callback={handleBackwards} />
      <StyledHeaderContainer
        className="main-header loaded"
        fontColor={theme.white}
        fontColorActive={theme.primary}
        fillColor={theme.white}
      >
        <Layout.Content size="xl" tag="div">
          <Hidden sm xs>
            <BaronaLogo>
              <Logo logo={blackFuzuLogo} />
            </BaronaLogo>
          </Hidden>
          <FlowNavBar>
            {currentIndex > 1 && (
              <Button
                theme="black200"
                noBackground
                bordered
                margin="auto 16px auto 0"
                width={32}
                height={32}
                onClick={getBack}
                aria-label="Go back"
                fontSize={20}
              >
                {"<"}
              </Button>
            )}
            <Text fontSize={16} fontWeight={600}>
              {t(name)}
            </Text>
          </FlowNavBar>
        </Layout.Content>
        <FlowProgress
          fillColorOne={theme.primary100}
          fillColorTwo={theme.primary300}
          emptyColor={theme.black300}
          currentStep={currentIndex}
          stepCount={steps.length}
        />
      </StyledHeaderContainer>
      <Layout.Section className="container" backgroundColor="white">
        <div className="inner">
          <Layout.Content size={size}>{children}</Layout.Content>
        </div>
      </Layout.Section>
    </FlowWrapper>
  );
};

export default FlowNavigation;
