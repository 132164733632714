import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";

import agriculture from "images/b2c/onboarding/jobInterest/agriculture.png";
import business from "images/b2c/onboarding/jobInterest/business.png";
import customerService from "images/b2c/onboarding/jobInterest/customerService.png";
import driver from "images/b2c/onboarding/jobInterest/driver.png";
import engineering from "images/b2c/onboarding/jobInterest/engineering.png";
import handyman from "images/b2c/onboarding/jobInterest/handyman.png";
import health from "images/b2c/onboarding/jobInterest/health.png";
import hotels from "images/b2c/onboarding/jobInterest/hotels.png";
import human from "images/b2c/onboarding/jobInterest/human.png";
import software from "images/b2c/onboarding/jobInterest/software.png";
import legal from "images/b2c/onboarding/jobInterest/legal.png";
import marketing from "images/b2c/onboarding/jobInterest/marketing.png";
import security from "images/b2c/onboarding/jobInterest/security.png";
import cashier from "images/b2c/onboarding/jobInterest/cashier.png";
import housekeeper from "images/b2c/onboarding/jobInterest/housekeeper.png";
import electrician from "images/b2c/onboarding/jobInterest/electrician.png";
import nurse from "images/b2c/onboarding/jobInterest/nurse.png";
import office from "images/b2c/onboarding/jobInterest/office.png";
import receptionist from "images/b2c/onboarding/jobInterest/receptionist.png";
import cook from "images/b2c/onboarding/jobInterest/cook.png";
import cleaner from "images/b2c/onboarding/jobInterest/cleaner.png";
import motoDriver from "images/b2c/onboarding/jobInterest/moto-driver.png";
import fieldSalesAgent from "images/b2c/onboarding/jobInterest/field-sales-agent.png";
import { useTranslation } from "b2c/hooks/useTranslation";
import { Image, RadioButton } from "../../../../components/Core";
import Content from "../Content";
import Navigation from "../Navigation";
import useStore from "../../../../contexts/store";
import { Item, Title, ImageContainer, Container } from "./styled";
import { useQueryParams } from "../../../../../utils/hooks";
import { buildOnboardingParams } from "../../../../utils/helpers";
import Steps from "../Steps";
import { isBaronaBrand } from "../../../../contexts/branded";

const iconsMapper = {
  legal,
  human,
  agriculture,
  health,
  engineering,
  business,
  "customer-service": customerService,
  cashier,
  cook,
  cleaner,
  software,
  driver,
  electrician,
  "field-sales-agent": fieldSalesAgent,
  housekeeper,
  handyman,
  nurse,
  office,
  receptionist,
  security,
  hotels,
  "moto-driver": motoDriver
};

const PickJob = () => {
  const [selected, setSelected] = useState({});
  const { getBlueCollarJobTitles, onboardingFlow, signUpDetails } = useStore("Onboarding");
  const history = useHistory();
  const { t } = useTranslation();
  const { refer_code: referCode } = useQueryParams();
  const { global_brand: globalBrand } = useStore("initialState");
  const isBarona = isBaronaBrand();

  const { user } = useStore("initialState");

  const handleSelect = event => {
    const selectedInterest = onboardingFlow.interestJobs.find(item => item?.id === +event.target.value);
    setSelected(selectedInterest);
  };

  const handleSubmit = () => {
    const prevOnboardingForm = JSON.parse(sessionStorage.getItem("onboardingFlow"));

    const onboardingForm = {
      ...prevOnboardingForm,
      pickJob: selected?.id
    };

    if (globalBrand.custom_authentication_b2c_url) {
      sessionStorage.setItem("onboardingFlow", JSON.stringify(onboardingForm));
      history.push("/onboarding/your-name");

      return;
    }

    if (user && !user?.onboarding_steps_completed) {
      const params = buildOnboardingParams(onboardingForm);

      signUpDetails(params, referCode).then(res => {
        if (res) {
          sessionStorage.removeItem("onboardingFlow");

          if (prevOnboardingForm.returnTo) {
            history.push(prevOnboardingForm.returnTo);
          }
        }
      });

      return;
    }

    sessionStorage.setItem("onboardingFlow", JSON.stringify(onboardingForm));
    history.push("/onboarding/your-email");
  };

  useEffect(() => {
    getBlueCollarJobTitles();
  }, []);

  useEffect(() => {
    const onboardingFlowStorage = JSON.parse(sessionStorage.getItem("onboardingFlow"));
    if (onboardingFlowStorage?.pickJob && !selected.id && onboardingFlow.interestJobs.length) {
      const selectedInterest = onboardingFlow.interestJobs.find(
        item => String(item?.id) === String(onboardingFlowStorage.pickJob)
      );
      setSelected(selectedInterest);
    }
  }, [onboardingFlow.interestJobs.length]);

  const stepWidth = user && !user?.onboarding_steps_completed ? "100%" : "75%";
  return (
    <>
      <Steps width={stepWidth} />

      <Container data-cy="pick-job">
        <Content title={t("onboarding.your_interests.title")} description={t("onboarding.your_interests.description")}>
          {onboardingFlow.interestJobs.map(({ id, title, logo_file }) => (
            <RadioButton
              key={id}
              id={+id}
              name="mostInterested"
              value={+id}
              borderedView
              margin="6px 0"
              onChange={handleSelect}
              checked={+selected?.id === +id}
              markColor="white"
              checkedColor="secondary200"
              checkedBgColor="secondary100"
              style={{ outline: "none" }}
              labelProps={{
                style: {
                  flexDirection: "row-reverse",
                  justifyContent: "space-between",
                  borderColor: selected?.id === id ? "#4690FF" : "#C5C5C5",
                  marginBottom: "16px",
                  padding: "15px 16px",
                  borderRadius: "8px",
                  alignItems: "center"
                }
              }}
              Label={
                <Item>
                  {!isBarona && (
                    <ImageContainer>
                      <Image src={iconsMapper[logo_file] || marketing} width={35} />
                    </ImageContainer>
                  )}
                  <div>
                    <Title>{title}</Title>
                  </div>
                </Item>
              }
            />
          ))}
        </Content>
      </Container>
      <Navigation backUrl="/onboarding/job-type" isDisabled={!selected?.id} handleSubmit={handleSubmit} />
    </>
  );
};

export default observer(PickJob);
