import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";

import { usePrev } from "utils/hooks";
import { useTranslation } from "b2c/hooks/useTranslation";
import { withTrackedRoute } from "../../hocs";
import useStore from "../../contexts/store";
import NotFound from "../NotFound";
import Layout from "../../components/Layout";
import Article from "./components/Article";
import ArticleSection from "../../components/FeedSections/ArticleSection";
import { Suggestion } from "../../components/Core";
import { Container } from "./styled";

const ArticleScreen = () => {
  const {
    state: { status, loading },
    article,
    getArticle,
    likeArticle,
    removeLike,
    addComment,
    updateComment,
    removeComment,
    addReply
  } = useStore("Article");
  const loaded = usePrev(loading);
  const { t } = useTranslation();
  const params = useParams();

  useEffect(() => {
    getArticle(params?.id, true);
  }, [params?.id]);

  useEffect(() => {
    if (window.scrollY) {
      window.scroll(0, 0);
    }
  }, [params?.id]);

  return (
    <Container>
      <Suggestion.Banner type="forum" margin="0 0 8px" />

      {status !== 404 ? (
        <>
          <Layout.Content size="sm" style={{ paddingTop: 20 }} id="main">
            <Article
              loading={loading}
              loaded={loaded}
              likeArticle={likeArticle}
              removeLike={removeLike}
              addComment={addComment}
              updateComment={updateComment}
              removeComment={removeComment}
              addReply={addReply}
              {...article}
            />
          </Layout.Content>
          {article?.id && (
            <Layout.Section backgroundColor="white">
              <Layout.Content size="xl" tag="div">
                <ArticleSection
                  filter="similar_to_article"
                  type={t("forum.similar_to_article")}
                  title={t("forum.sub-title_4")}
                  count_limit={3}
                  article_id={article?.id}
                  titleProps={{ margin: "24px 0 0" }}
                  noFooter
                />
              </Layout.Content>
            </Layout.Section>
          )}
        </>
      ) : (
        <NotFound />
      )}
    </Container>
  );
};

export default withTrackedRoute(observer(ArticleScreen));
// export default observer(ArticleScreen);
