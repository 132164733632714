import React, { useContext, useState, useMemo } from "react";
import { EyeSlashFill, EyeFill, CheckCircleFill } from "@styled-icons/bootstrap";
import { CloseCircle } from "@styled-icons/evaicons-solid";
import { formatDistance } from "date-fns";

import { useTranslation } from "b2c/hooks/useTranslation";
import {
  Tile,
  TileTitle,
  TileDescription,
  TileButton,
  TileContent,
  TileInputContainer,
  TileInputContent,
  TileInput,
  TileInputPasswordButton,
  Actions,
  ActionsCancel,
  ActionsSubmit
} from "../../styled";
import { Label, LastUpdated, CheckList, CheckItem, EmptyCycle } from "./styled";
import ThemeContext from "../../../../contexts/theme";
import { inputTypes } from "../../../../constants/main";

const UpdatePasswordSection = ({
  processing,
  passwordChangedAt,
  updatePassword,
  isPasswordJustChanged,
  customAuthenticationUrl
}) => {
  const theme = useContext(ThemeContext);
  const [isShowForm, setIsShowForm] = useState(false);
  const [passwordType, setPasswordType] = useState({
    currentPassword: inputTypes.password,
    newPassword: inputTypes.password,
    renewPassword: inputTypes.password
  });
  const { t } = useTranslation();

  const [formValues, setFormValues] = useState({
    currentPassword: "",
    newPassword: "",
    renewPassword: ""
  });

  const handleChangeForm = event => {
    setFormValues(prev => {
      return {
        ...prev,
        [event.target.name]: event.target.value
      };
    });
  };

  const handleChangePasswordType = name => {
    setPasswordType(prev => {
      return {
        ...prev,
        [name]: prev[name] === inputTypes.password ? inputTypes.text : inputTypes.password
      };
    });
  };

  const handleShowForm = () => {
    setIsShowForm(true);
  };

  const handleHideForm = () => {
    setIsShowForm(false);
  };

  const isPasswordLengthAllowed = useMemo(() => formValues.newPassword.length >= 8, [formValues.newPassword]);
  const isPasswordAllowed = useMemo(() => {
    const regex = /[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

    return regex.test(formValues.newPassword);
  }, [formValues.newPassword]);

  const handleSubmitPassword = () => {
    updatePassword({
      current_password: formValues.currentPassword,
      password: formValues.newPassword,
      password_confirmation: formValues.renewPassword
    }).then(res => {
      if (res) {
        handleHideForm();
        setFormValues({
          currentPassword: "",
          newPassword: "",
          renewPassword: ""
        });
      }
    });
  };

  const isDisableSubmit =
    processing ||
    !formValues.renewPassword.length ||
    !formValues.newPassword.length ||
    !formValues.currentPassword.length ||
    !isPasswordAllowed ||
    !isPasswordLengthAllowed ||
    !(formValues.renewPassword === formValues.newPassword);

  return (
    <Tile>
      <TileContent>
        <TileTitle>{t("settings.password.title")}</TileTitle>
        <TileDescription>{t("settings.password.description")}</TileDescription>

        {isShowForm ? (
          <>
            <Label>{t("settings.password.current_password_label")}</Label>
            <TileInputContainer>
              <TileInput
                value={formValues.currentPassword}
                name="currentPassword"
                onChange={handleChangeForm}
                type={passwordType.currentPassword}
              />
              <TileInputPasswordButton onClick={() => handleChangePasswordType("currentPassword")}>
                {passwordType.currentPassword === inputTypes.password ? <EyeSlashFill /> : <EyeFill />}
              </TileInputPasswordButton>
            </TileInputContainer>
            <Label>{t("settings.password.new_password_label")}</Label>

            <TileInputContent>
              <TileInputContainer>
                <TileInput
                  $marginBottom="0"
                  value={formValues.newPassword}
                  name="newPassword"
                  onChange={handleChangeForm}
                  type={passwordType.newPassword}
                />
                <TileInputPasswordButton onClick={() => handleChangePasswordType("newPassword")}>
                  {passwordType.newPassword === inputTypes.password ? <EyeSlashFill /> : <EyeFill />}
                </TileInputPasswordButton>
              </TileInputContainer>

              <CheckList>
                <CheckItem $active={isPasswordLengthAllowed}>
                  {formValues.newPassword ? (
                    <>{isPasswordLengthAllowed ? <CheckCircleFill /> : <CloseCircle />}</>
                  ) : (
                    <EmptyCycle />
                  )}
                  {t("settings.password.use_8_characters_validation")}
                </CheckItem>

                <CheckItem $active={isPasswordAllowed}>
                  {formValues.newPassword ? (
                    <>{isPasswordAllowed ? <CheckCircleFill /> : <CloseCircle />}</>
                  ) : (
                    <EmptyCycle />
                  )}
                  {t("settings.password.use_number_or_symbol_validation")}
                </CheckItem>
              </CheckList>
            </TileInputContent>

            <Label>{t("settings.password.re_new_password_label")}</Label>
            <TileInputContent>
              <TileInputContainer>
                <TileInput
                  $marginBottom="0"
                  value={formValues.renewPassword}
                  name="renewPassword"
                  onChange={handleChangeForm}
                  type={passwordType.renewPassword}
                />
                <TileInputPasswordButton onClick={() => handleChangePasswordType("renewPassword")}>
                  {passwordType.renewPassword === inputTypes.password ? <EyeSlashFill /> : <EyeFill />}
                </TileInputPasswordButton>
              </TileInputContainer>

              {formValues.newPassword && formValues.renewPassword === formValues.newPassword ? (
                <CheckList>
                  <CheckItem $active>
                    <CheckCircleFill />
                    {t("settings.password.password_match")}
                  </CheckItem>
                </CheckList>
              ) : null}
            </TileInputContent>

            <Actions>
              <ActionsCancel onClick={handleHideForm}>{t("global.cancel")}</ActionsCancel>
              <ActionsSubmit
                disabled={isDisableSubmit}
                $background={theme.primaryButtonBackground}
                $color={theme.primaryButtonLabelColor}
                onClick={handleSubmitPassword}
              >
                {t("settings.password.submit_button")}
              </ActionsSubmit>
            </Actions>
          </>
        ) : (
          <>
            <TileButton $disabled={customAuthenticationUrl} onClick={handleShowForm}>
              {t("settings.password.update_password_button")}
            </TileButton>
            {isPasswordJustChanged ? (
              <LastUpdated>{t("settings.password.last_updated_placeholder")}</LastUpdated>
            ) : (
              <>
                {passwordChangedAt ? (
                  <LastUpdated>
                    {t("settings.password.last_updated_ago", {
                      time: formatDistance(new Date(), new Date(passwordChangedAt))
                    })}
                  </LastUpdated>
                ) : (
                  <LastUpdated>{t("settings.password.never_updated")}</LastUpdated>
                )}
              </>
            )}
          </>
        )}
      </TileContent>
    </Tile>
  );
};

export default UpdatePasswordSection;
