import React, { useContext } from "react";
import { useHistory } from "react-router";

import BrandedContext, { isCustomPlatform, isWhitelabelled } from "../../contexts/branded";
import { Suggestion } from "../../components/Core";
import Job from "./components/Job";
import Company from "./components/Company";

const EntityPage = ({ type = "job", match: { params: { id } = {} } = {}, ...rest }) => {
  const { employer } = useContext(BrandedContext);
  const history = useHistory();
  const isCompanyPage = history.location.pathname.includes("/company");
  const customPlatform = isCustomPlatform();
  const whitelabelled = isWhitelabelled();

  return (
    <>
      {!isCompanyPage && <Suggestion.Banner type={type} />}
      {type === "job" && <Job id={id} {...rest} />}
      {type === "company" && <Company id={whitelabelled || customPlatform ? employer?.id : id} {...rest} />}
    </>
  );
};

export default EntityPage;
