import React from "react";
import { Link } from "react-router-dom";
import { Link as LinkIcon } from "@styled-icons/boxicons-regular";
import { LinkedinSquare } from "@styled-icons/boxicons-logos";
import { useTranslation } from "b2c/hooks/useTranslation";

import { Tipbox, Label, Spinner } from "b2c/components/Core";
import Icon from "../Icon";

const SocialLinks = ({ url, links = [], loading }) => {
  const { t } = useTranslation();

  return loading ? (
    <Spinner size="50px" margin="auto" />
  ) : (
    <>
      {links.length === 0 && <Tipbox text={t("profile.personal_info.social_links.helper_text")} margin="0 0 20px" />}
      {links.some(item => item.social_type === "personal_website") ? (
        <Label
          Icon={<Icon type="personal_website" width={20} />}
          fontSize={14}
          fontWeight={400}
          noBackground
          theme="black500"
          margin="0 0 15px"
          padding="0"
          display="block"
        >
          {links.find(item => item.social_type === "personal_website").url}
        </Label>
      ) : (
        <Link
          to={{
            pathname: `${url}/personal-information`,
            state: "social"
          }}
          className="link"
          style={{ fontSize: "14px", marginBottom: "15px" }}
        >
          <LinkIcon width={20} style={{ marginRight: "4px" }} />
          {`${t("profile.personal_info.social_links.add_web_site")} `}[+]
        </Link>
      )}
      {links.some(item => item.social_type === "linkedin") ? (
        <Label
          Icon={<Icon type="linkedin" width={20} />}
          fontSize={14}
          fontWeight={400}
          noBackground
          theme="black500"
          margin="0 0 15px"
          padding="0"
          display="block"
        >
          {links.find(item => item.social_type === "linkedin").url}
        </Label>
      ) : (
        <Link
          to={{
            pathname: `${url}/personal-information`,
            state: "social"
          }}
          className="link"
          style={{ fontSize: "14px", marginBottom: "15px" }}
        >
          <LinkedinSquare width={20} style={{ marginRight: "4px" }} />
          {`${t("profile.personal_info.social_links.add_linkedin")} `}[+]
        </Link>
      )}

      {links
        .filter(item => !["personal_website", "linkedin"].includes(item.social_type))
        .map(item => (
          <Label
            key={item.social_type}
            Icon={<Icon type={item.social_type} width={20} />}
            fontSize={14}
            fontWeight={400}
            noBackground
            theme="black500"
            margin="0 0 15px"
            padding="0"
            display="block"
          >
            {item.url}
          </Label>
        ))}
    </>
  );
};

export default SocialLinks;
