import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";

import FlowNavigation from "../FlowNavigation";
import OnboardingFlowStep from "./OnboardingFlowStep";
import useStore from "../../contexts/store";
import { useQueryParams } from "../../../utils/hooks";

const OnboardingFlow = () => {
  const {
    state: { loading, processing },
    currentStep,
    currentStepIndex,
    flow,
    formState,
    setInitialFlow,
    flowProceed,
    flowReturn
  } = useStore("Onboarding");
  const { user } = useStore("initialState");
  const { flow: qFlow = "generic", from: qFrom, shorterFlow, application_id, candidate } = useQueryParams();
  const {
    push,
    location: { state: { from } = {} }
  } = useHistory();

  useEffect(() => {
    if (!flow || !shorterFlow) {
      const getFlow = () => {
        if (user && !user.onboarding_steps_completed) {
          return "short";
        }

        if (shorterFlow === "true") {
          return "shorter";
        }

        return window?.localStorage.getItem("onboardingFlow") || qFlow;
      };

      setInitialFlow(getFlow(), shorterFlow, application_id, candidate);
    }
  }, [flow, qFlow, candidate]);

  const handleProceed = () => {
    currentStepIndex < flow.size ? flowProceed() : push(from || qFrom || "/home");
  };

  return (
    <FlowNavigation steps={flow ? [...flow.values()] : []} getBack={flowReturn} size="xs">
      <OnboardingFlowStep
        {...currentStep}
        {...formState}
        loading={loading}
        processing={processing}
        proceed={handleProceed}
      />
    </FlowNavigation>
  );
};

export default observer(OnboardingFlow);
