import { toJS } from "mobx";
import { Route, Switch } from "react-router-dom";
import React, { useEffect } from "react";
import { useTranslation } from "b2c/hooks/useTranslation";

import { Categories, Suggestion } from "b2c/components/Core";
import Navigation from "b2c/components/TabNavigation";
import Layout from "b2c/components/Layout";
import { GuestHidden, GuestVisible } from "b2c/components/WithAuth";
import PromoSection from "b2c/components/PromoSection";
import ArticleSection from "b2c/components/FeedSections/ArticleSection";
import ArticlesSectionList from "../ArticleSectionList";
import PopularPosts from "../PopularPosts";
import AuthorPosts from "../AuthorPosts";
import { useGetCurrentCountry } from "../../../../hooks";
import NotFound from "../../../NotFound";
import { Container } from "./styled";

const ForumCore = ({ sections, loading, getArticleCategories, url, location: { pathname } }) => {
  const { t } = useTranslation();

  const blogRoutes = [{ title: t("forum.home"), filter: "" }];
  const modifiedRoutes = sections ? blogRoutes.concat(toJS(sections)) : blogRoutes;
  const country = useGetCurrentCountry();
  const countryId = country?.id || "";

  useEffect(() => {
    if (sections.length <= 4) {
      getArticleCategories(countryId);
    }
  }, []);

  return (
    <Container>
      <Suggestion.Banner type="forum" margin="0 0 8px" />

      <Navigation>
        {modifiedRoutes.map(({ title, filter }, i) => {
          return (
            <Navigation.Tab
              key={i}
              selected={pathname === `${url}${filter ? `/${filter}` : ""}`}
              to={`${url}${filter ? `/${filter}` : ""}`}
            >
              {title}
            </Navigation.Tab>
          );
        })}
      </Navigation>
      <Layout.Content style={{ marginTop: 30, marginBottom: 30 }} id="main">
        <Switch>
          <Route exact path={url}>
            <PopularPosts id="main" title={t("forum.title")} loading={loading} count_limit={4} />
            <GuestHidden>
              <PromoSection type="invite_friends" />
            </GuestHidden>
            <ArticleSection
              title={t("forum.sub-title_1")}
              filter="popular"
              type={t("forum.popular")}
              count_limit={4}
              isMinDefaultWidth
              isHome
            />
            <ArticleSection
              title={t("forum.sub-title_2")}
              filter="recent"
              type={t("forum.recent")}
              count_limit={4}
              isMinDefaultWidth
              isHome
            />
          </Route>

          {modifiedRoutes.map(({ title, filter, h1, meta_title: metaTitle }) => (
            <Route key={title} exact path={`${url}/${filter}`}>
              <ArticlesSectionList
                id="main"
                title={h1}
                filter={filter}
                metaTitle={metaTitle}
                count_limit={12}
                titleProps={{ margin: "0 0 25px 0" }}
                pagination
              />
            </Route>
          ))}

          <Route key="author-articles" path={`${url}/authors/:authorId`}>
            <AuthorPosts id="main" count_limit={12} />
          </Route>

          <NotFound />
        </Switch>
      </Layout.Content>
      <Suggestion.Email type="forum" />

      <GuestVisible>
        <Categories title={t("forum.sub-title_3")}>
          {sections.map(({ title, filter }) => (
            <Categories.Item to={`${url}/${filter}`} key={title}>
              {title}
            </Categories.Item>
          ))}
        </Categories>
      </GuestVisible>
    </Container>
  );
};

export default ForumCore;
