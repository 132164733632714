import React, { useState, useEffect, useRef } from "react";
import parse from "html-react-parser";

import { onEnterPress } from "utils/helpers";
import Layout from "../../../../components/Layout";
import Introduction from "../../../../components/Introduction";
import { TestAndSurveyContent } from "./styled";
import Navigation from "../Navigation";
import TestCompleted from "../TestCompleted";
import SurveyPage from "../SurveyPage";
import { buildImageUrlS3 } from "../../../../contexts/branded";
import { nameImages } from "../../../../constants/images";

const MainContent = ({
  isJobPage,
  loading,
  processing,
  id,
  job_id,
  in_progress_id: inProgressId,
  handleRedirection,
  name,
  description,
  testCompleted,
  currentPage,
  startForm,
  getFormPage,
  submitResponses,
  completeModule,
  getTestResult,
  testResults,
  completePositionTests,
  hasNextForm,
  setFinished
}) => {
  const contentRef = useRef();
  const [responses, setResponses] = useState([]);

  useEffect(() => {
    if (currentPage.questions)
      setResponses(
        currentPage.questions.map(({ answer_type, answer_options, id: ids }) => {
          const responseObj = {};
          const hasSelected = answer_options.some(a => a.selected);
          if (answer_type === "option" && hasSelected) {
            responseObj.test_option_id = answer_options.find(answer => answer.selected).id.toString();
          } else if (answer_type === "checkbox" && hasSelected) {
            responseObj.test_option_ids = answer_options.filter(answer => answer.selected).map(answer => answer.id);
          }

          return { id: ids, response: responseObj };
        })
      );

    contentRef.current?.scrollTo(0, 0);
  }, [currentPage.page_number]);

  const updateResponses = response => {
    let resArr = [...responses];
    if (resArr.some(res => res.id === response.id)) {
      const index = resArr.indexOf(resArr.find(res => res.id === response.id));
      resArr[index] = response;
    } else resArr = resArr.concat(response);

    setResponses(resArr);
  };

  const handleSubmission = () => {
    if (!loading && (!hasMandatoryQuestions || hasResponses))
      submitResponses(responses, currentPage.form_session_id, job_id).then(completed => {
        if (completed) {
          completeModule();
          completePositionTests();
        }
      });
  };

  const hasMandatoryQuestions = currentPage.questions && currentPage.questions.some(q => q.mandatory);

  const hasResponses =
    currentPage.questions &&
    responses.length === currentPage.questions.length &&
    responses.every(({ response }) =>
      ["test_option_id", "test_option_ids", "text"].some(key => response[key] && response[key].length > 0)
    );

  return (
    <>
      <TestAndSurveyContent
        ref={contentRef}
        data-cy="test-content"
        onKeyDown={e => onEnterPress(e, 1, handleSubmission)}
      >
        <Layout.Content size="sm" style={{ display: "flex", flexDirection: "column" }}>
          {testCompleted ? (
            <TestCompleted
              id={id}
              getTestResult={getTestResult}
              testResults={testResults}
              setFinished={setFinished}
              isJobPage={isJobPage}
            />
          ) : currentPage.form_session_id ? (
            <SurveyPage
              processing={processing}
              questions={currentPage.questions}
              responses={responses}
              updateResponses={updateResponses}
            />
          ) : (
            <Introduction loading={loading} name={name} image={buildImageUrlS3(nameImages.surveyPlaceholder)}>
              {description ? parse(description) : null}
            </Introduction>
          )}
        </Layout.Content>
      </TestAndSurveyContent>

      <Navigation
        loading={loading}
        processing={processing}
        in_progress_id={inProgressId}
        startForm={() => startForm(id, job_id)}
        mandatory={hasMandatoryQuestions}
        hasResponses={hasResponses}
        getFormPage={getFormPage}
        submitResponses={handleSubmission}
        currentPage={currentPage}
        hasNextForm={hasNextForm}
        testCompleted={testCompleted}
        handleRedirection={handleRedirection}
      />
    </>
  );
};

export default MainContent;
