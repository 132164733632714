import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

const sharedStyle = css`
  ${({ theme }) => `
    padding: 10px 12px;
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: #333333;
    border-bottom: 1px solid #EEEEEE;
    display: block;

    span {
        background-color: ${theme.primary};
    }
    
    span.location-item__country {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #666666;
      display: block;
      background-color: transparent;
    }

    &.selected {
        font-weight: 500;
        color: ${theme.black500};
    }

    &:hover {
        background-color: ${theme.secondary200};
        color: #fff;
        
        span {
          color: #fff;
        }
    }
`}
`;

export const LocationItem = styled(Link)`
  ${sharedStyle}
`;

export const CountryItem = styled.button`
  ${sharedStyle};
  text-align: left;
  background: #fff;
`;

export const FilterWrapper = styled.div`
  ${({ theme }) => `
    width: 100%;
    position: relative;

    @media screen and (max-width: 767px) {
        margin: 8px 0 0;
    }

    .location-menu {
        position: absolute;
        z-index: 9;
        border: 1px solid ${theme.grey200};
        background-color: ${theme.white};
        border-radius: 5px;
        font-size: 14px;
        overflow: hidden;
        max-height: 236px;
        overflow-y: auto;
        height: fit-content;
        display: flex;
        flex-direction: column;
        width: 100%;

        @media screen and (max-width: 767px) {
            margin: 8px 0 0;
            width: 100%;
        }
    }
    
    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration {
      // -webkit-appearance: none;
      height: 24px;
      width: 24px;
      margin-left: .4em;
      cursor: pointer;
      background: red;
      opacity: 0;
      // z-index: 11;
    }
    
    .close-icon {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
      outline: none;
      border: none;
      // z-index: 1;
    }
`}
`;

export const EmptySearch = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #666666;
  padding: 12px;
`;

export const LocationMenuContainer = styled.div`
  display: none;
`;

export const HiddenButton = styled.button`
  display: none;
`;
