import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Search } from "@styled-icons/boxicons-regular";
import { Close } from "@styled-icons/evaicons-solid";

import flagSlovakia from "images/b2c/onboarding/slovakia.png";
import flagPoland from "images/b2c/onboarding/poland.png";
import flagRomania from "images/b2c/onboarding/romania.png";
import flagNepal from "images/b2c/onboarding/nepal.png";
import flagIndia from "images/b2c/onboarding/india.png";
import flagKenya from "images/b2c/onboarding/onboarding-kn-flag.png";
import flagNigeria from "images/b2c/onboarding/onboarding-ng-flag.png";
import flagUganda from "images/b2c/onboarding/onboarding-ug-flag.png";
import { ChevronDown, ChevronUp } from "@styled-icons/boxicons-solid";
import { useTranslation } from "b2c/hooks/useTranslation";
import useStore from "../../../../contexts/store";
import { Card, Checkbox, Image, Label, Button } from "../../../Core";
import {
  CountryContent,
  ShowAllCountriesContent,
  ShowAllCountriesTitle,
  SearchInput,
  NotFound,
  SearchInputContainer,
  SearchIcon,
  CloseIcon
} from "./styled";
import { useIsBarona } from "../../../../hooks";

const flags = new Map([
  ["kenya", flagKenya],
  ["nigeria", flagNigeria],
  ["uganda", flagUganda],
  ["slovakia", flagSlovakia],
  ["poland", flagPoland],
  ["romania", flagRomania],
  ["nepal", flagNepal],
  ["india", flagIndia]
]);

const Country = () => {
  const { countries, default_country: defaultCountry } = useStore("initialState");
  const [searchCountry, setSearchCountry] = useState("");
  const [allCountriesList, setAllCountriesList] = useState([]);

  const isBarona = useIsBarona();
  const { getCountries, allCountries = [] } = useStore("Common");
  const { t } = useTranslation();

  const [selected, setSelected] = useState(() => {
    const countriesList = isBarona && allCountries?.length ? [...allCountries, ...countries] : countries;

    const onboardingForm = JSON.parse(localStorage.getItem("onboardingForm"));
    const selectedCountry = countriesList.find(({ id }) => id === onboardingForm?.preferredCountryId);
    return selectedCountry || defaultCountry || {};
  });
  const [showMoreAllCountries, setShowMoreAllCountries] = useState(false);

  const handleSelect = event => {
    const selectedId = Number(event.target.value);
    const countriesList = allCountries?.length ? [...allCountries, ...countries] : countries;

    const selectedCountry = countriesList.find(({ id }) => id === selectedId);
    setSelected(selectedCountry);
  };

  const handleShowMoreAllCountries = () => {
    setShowMoreAllCountries(prev => !prev);
  };

  const handleChangeCountry = event => {
    const { value } = event.target;
    setSearchCountry(value);

    const findCountries = value
      ? allCountries.filter(item => item.name.toLowerCase().includes(value.toLowerCase()))
      : allCountries;

    setAllCountriesList(findCountries);
  };

  const handleRemoveSearchValues = () => {
    if (searchCountry) {
      setAllCountriesList(allCountries);
      setSearchCountry("");
    }
  };

  useEffect(() => {
    if (defaultCountry && defaultCountry.id !== selected?.id) {
      setSelected(defaultCountry);
    }
  }, [defaultCountry]);

  useEffect(() => {
    getCountries(true);
  }, [defaultCountry]);

  useEffect(() => {
    if (allCountries.length && !allCountriesList.length && !searchCountry) {
      setAllCountriesList(allCountries);
    }
  }, [allCountries.length]);

  const showMoreTextAllCountries = !showMoreAllCountries ? (
    <>
      {t("onboarding.country.show_more")} <ChevronDown size="24px" />
    </>
  ) : (
    <>
      {t("onboarding.country.show_less")}
      <ChevronUp size="24px" />
    </>
  );

  return (
    <Card theme="grey100">
      <Card.Content>
        <CountryContent>
          {countries.map(({ id, slug, name }) => (
            <Checkbox
              key={slug}
              id={slug}
              name="country"
              value={id}
              borderedView
              margin="6px 0"
              onChange={handleSelect}
              checked={selected?.id === id}
              markColor="white"
              checkedColor="secondary200"
              checkedBgColor="secondary100"
              style={{
                outline: "none"
              }}
              labelProps={{
                style: {
                  flexDirection: "row-reverse",
                  justifyContent: "space-between",
                  borderColor: selected?.id === id ? "#4690FF" : "transparent",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.08)"
                }
              }}
              checkboxMarkProps={{
                style: {
                  borderRadius: "50%"
                }
              }}
              label={
                <Label
                  theme="transparent"
                  Icon={<Image src={flags.get(slug)} height="32px" width="32px" margin="0 8px 0 0" />}
                  fontSize={14}
                  fontWeight={600}
                  margin="0"
                >
                  {name}
                </Label>
              }
            />
          ))}
        </CountryContent>
        {showMoreAllCountries && (
          <>
            <ShowAllCountriesTitle>{t("onboarding.country.all_countries")}</ShowAllCountriesTitle>
            <SearchInputContainer>
              <SearchIcon>
                <Search />
              </SearchIcon>

              <SearchInput type="text" value={searchCountry} onChange={handleChangeCountry} />

              <CloseIcon type="button" onClick={handleRemoveSearchValues}>
                <Close />
              </CloseIcon>
            </SearchInputContainer>

            <ShowAllCountriesContent>
              {allCountriesList.length ? (
                <>
                  {allCountriesList.map(({ id, slug, name }) => (
                    <Checkbox
                      key={slug}
                      id={slug}
                      name="country"
                      value={id}
                      borderedView
                      margin="6px 0"
                      onChange={handleSelect}
                      checked={selected?.id === id}
                      markColor="white"
                      checkedColor="secondary200"
                      checkedBgColor="secondary100"
                      style={{
                        outline: "none"
                      }}
                      labelProps={{
                        style: {
                          flexDirection: "row-reverse",
                          justifyContent: "space-between",
                          borderColor: selected?.id === id ? "#4690FF" : "transparent",
                          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.08)"
                        }
                      }}
                      checkboxMarkProps={{
                        style: {
                          borderRadius: "50%"
                        }
                      }}
                      label={
                        <Label theme="transparent" fontSize={14} fontWeight={600} margin="0">
                          {name}
                        </Label>
                      }
                    />
                  ))}
                </>
              ) : (
                <NotFound>{t("onboarding.country.not_found")}</NotFound>
              )}
            </ShowAllCountriesContent>
          </>
        )}

        {!searchCountry && (
          <Button.Iconed
            theme="transparent"
            type="button"
            className="b2c-iconed-flat-btn_no-filter"
            style={{
              margin: "8px auto",
              color: "#0E50B5",
              fontWeight: "600",
              fontSize: "14px",
              lineHeight: "21px"
            }}
            onClick={handleShowMoreAllCountries}
          >
            {showMoreTextAllCountries}
          </Button.Iconed>
        )}
      </Card.Content>
    </Card>
  );
};

export default observer(Country);
