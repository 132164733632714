import React, { useMemo, useContext } from "react";
import { ScreenClassContext, Row, Col } from "react-grid-system";

import { useRouteMatch } from "react-router-dom";
import { pickRandom } from "utils/helpers";
import { Text, Button, Label } from "b2c/components/Core";
import PremiumBox from "b2c/components/PremiumBox";
import { prompts } from "b2c/constants/wizard";
import useStore from "../../../../contexts/store";
import { useImpressionTrack } from "../../../../hooks";

const CareerCoachingBanner = ({ disable, ...props }) => {
  const { track } = useStore("Tracking");
  const { user } = useStore("User");
  const { path } = useRouteMatch();
  const isSm = /xs|sm/.test(useContext(ScreenClassContext));

  const { targetRef } = useImpressionTrack({ type: "career_coaching", disable });

  const prompt = useMemo(() => {
    switch (user?.segment) {
      default:
      case "hustlers":
      case "junior_professionals":
      case "workers":
        return pickRandom(prompts.junior);
      case "students":
      case "graduates":
        return pickRandom(prompts.student);
      case "experts":
      case "managers":
        return pickRandom(prompts.expert);
    }
  }, [user?.segment]);

  const trackClick = () => {
    track("promo_clicked", { prompt, match_path: path, type: "career_coaching" });
  };

  return (
    <PremiumBox ref={targetRef} {...props}>
      <Row align="center">
        <Col xs={12} sm="content">
          <Label.PremiumAlternative title="Premium" margin="0 auto" style={{ display: "flex" }} />
        </Col>
        <Col>
          <Text
            fontSize={16}
            fontWeight={600}
            lineHeight="140%"
            color="white"
            width="100%"
            textAlign="center"
            margin={isSm ? "10px 0 15px" : "0"}
          >
            Do you need professional help in building a strong CV?
          </Text>
        </Col>

        <Col xs={12} sm="content">
          <Button.Link
            to="/premium/career-coaching"
            onClick={trackClick}
            theme="additional200"
            fontSize={12}
            fontWeight={600}
            margin="0 auto"
            height={32}
            style={{ display: "flex" }}
          >
            Get Career Coaching
          </Button.Link>
        </Col>
      </Row>
    </PremiumBox>
  );
};

export default CareerCoachingBanner;
