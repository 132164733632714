import React from "react";
import { Row, Col } from "react-grid-system";
import { useTranslation } from "b2c/hooks/useTranslation";

import { Button, Text } from "b2c/components/Core";
import Layout from "b2c/components/Layout";
import { PricingWrapper } from "./styled";

const ContactSection = ({ isSm, openContactUsModal }) => {
  const { t } = useTranslation();

  return (
    <Layout.Section>
      <PricingWrapper>
        <Row>
          <Col md={10}>
            <Text fontSize={16} fontWeight={600} textAlign={isSm ? "center" : "start"} margin="20px 0">
              {`${t("pricing.still_have_questions_title")} `}
              {t("pricing.still_have_questions_description")}
            </Text>
          </Col>
          <Col md={2}>
            <div style={{ textAlign: isSm ? "center" : "end" }}>
              <Button
                theme="black0"
                color="white"
                fontSize={14}
                margin="0 13px 0 0"
                width={isSm ? 320 : 160}
                onClick={() => openContactUsModal({ aggressive: true })}
              >
                {t("pricing.still_have_questions_button")}
              </Button>
            </div>
          </Col>
        </Row>
      </PricingWrapper>
    </Layout.Section>
  );
};

export default ContactSection;
