import React, { useLayoutEffect, useContext } from "react";
import { ScreenClassContext } from "react-grid-system";
import styled from "styled-components";

import { Title } from "../../../../components/Core";
import TableGenerator from "../../../../components/TableGenerator";

const ActivitiesTable = ({ loading, invites, getFriendInvites }) => {
  const screen = useContext(ScreenClassContext);
  const isSm = ["xs", "sm"].includes(screen);

  useLayoutEffect(() => {
    getFriendInvites();
  }, []);

  return (
    <ActivitiesTableWrapper>
      <Title level="6" fontWeight={600} width="100%" textAlign={isSm ? "center" : "left"}>
        My invitations
      </Title>
      <TableGenerator
        loading={loading}
        tab="invites"
        items={invites?.length > 0 ? invites : [{ referee_email: "No invites", amount: 0 }]}
      />
    </ActivitiesTableWrapper>
  );
};

const ActivitiesTableWrapper = styled.div`
  margin: 0 0 40px;
  @media screen and (max-width: 767px) {
    tr {
      border-radius: 0;
    }
  }
`;

export default ActivitiesTable;
