import React, { useContext } from "react";
import { ScreenClassContext, Row, Col, Hidden } from "react-grid-system";

import { useTranslation } from "b2c/hooks/useTranslation";
import testimonial1 from "images/b2c/careerCoaching/testimonial1.jpg";
import testimonial2 from "images/b2c/careerCoaching/testimonial2.jpg";
import Layout from "b2c/components/Layout";
import { Title, Text, Carousel, Image } from "b2c/components/Core";

const CareerCoachingCarousel = () => {
  const screen = useContext(ScreenClassContext);
  const isSm = ["xs", "sm"].includes(screen);
  const { t } = useTranslation();

  return (
    <>
      <Layout.Section backgroundColor="white" padding="30px 0 50px">
        <Layout.Content tag="div">
          <Title
            loaded
            level="2"
            fontWeight={600}
            margin="0 auto 25px"
            textAlign="center"
            style={{ fontSize: isSm ? 20 : 36 }}
          >
            {t("career_coaching.testimonials.title")}
          </Title>
          <Carousel
            className="wide-blue-buttons"
            settings={{
              dots: true,
              adaptiveHeight: false
            }}
          >
            {testimonials.map(({ title, description, author, image }) => (
              <Carousel.Slide key={author} padding="20px 30px" style={{ height: "100%" }}>
                <Row>
                  <Col
                    md={6}
                    sm={12}
                    style={{
                      display: "flex",
                      flexDirection: "column"
                    }}
                  >
                    <Title level={3} fontWeight={600}>
                      {t(title)}
                    </Title>
                    <Text
                      margin="auto 0"
                      fontSize={isSm ? 14 : 20}
                      lineHeight={isSm ? "24px" : "32px"}
                      loaded
                      width="100%"
                    >
                      {t(description)}
                    </Text>
                    <Text color="black200" width="100%">
                      {t(author)}
                    </Text>
                  </Col>
                  <Hidden sm xs>
                    <Col md={6} sm={12}>
                      <Image src={image} />
                    </Col>
                  </Hidden>
                </Row>
              </Carousel.Slide>
            ))}
          </Carousel>
        </Layout.Content>
      </Layout.Section>
    </>
  );
};

const testimonials = [
  {
    title: "career_coaching.testimonials.list.title_1",
    description: "career_coaching.testimonials.list.description_1",
    author: "career_coaching.testimonials.list.author_1",
    image: testimonial2
  },
  {
    title: "career_coaching.testimonials.list.title_2",
    description: "career_coaching.testimonials.list.description_2",
    author: "career_coaching.testimonials.list.author_2",

    image: testimonial1
  }
];
export default CareerCoachingCarousel;
