import styled from "styled-components";
import { Link } from "react-router-dom";
import { theme } from "b2c/contexts/theme";

export const B2bOfferSectionContainer = styled.div`
  background-color: #f3f3f3;
  padding: 20px;

  @media screen and (min-width: 1200px) {
    padding: 56px 0;
  }
`;

export const B2bOfferSectionContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;

  @media screen and (min-width: 834px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const LeftSide = styled.div`
  max-width: 540px;
  margin: 20px auto 0 auto;

  @media screen and (min-width: 834px) {
    margin: 0;
  }
`;

export const Title = styled.h2`
  font-weight: 600;
  line-height: 130%;
  font-size: 20px;
  color: #333333;
  margin: 0 0 8px 0px;
  text-align: center;

  span {
    color: #408bfc;
  }

  @media screen and (min-width: 834px) {
    text-align: left;
    font-size: 24px;
  }

  @media screen and (min-width: 1000px) {
    font-size: 32px;
  }
`;

export const Description = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #666666;
  display: block;
  text-align: center;
  margin: 0 0 32px 0;

  @media screen and (min-width: 834px) {
    text-align: left;
  }

  @media screen and (min-width: 1000px) {
    font-size: 16px;
  }
`;

export const Actions = styled.div`
  @media screen and (min-width: 834px) {
    display: flex;
    gap: 32px;
  }
`;

export const Hire = styled(Link)`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  background: ${theme.primary};
  border-radius: 5px;
  max-width: 206px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0 auto 16px auto;
  padding: 13.5px 24px;
  text-decoration: none;

  @media screen and (min-width: 834px) {
    margin: 0;
  }
`;

export const HireRemote = styled.a`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #f3f3f3;
  background: #333333;
  border-radius: 5px;
  max-width: 206px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0 auto;
  padding: 13.5px 24px;
  text-decoration: none;

  @media screen and (min-width: 834px) {
    margin: 0;
  }
`;

export const RightSide = styled.div`
  width: 244px;
  height: 172px;
  background-color: #fffbe8;
  display: none;
  position: relative;
  overflow: hidden;
  border-radius: 24px;

  @media screen and (min-width: 834px) {
    display: flex;
    justify-content: center;
    align-items: end;
  }

  @media screen and (min-width: 1000px) {
    width: 316px;
    height: 222px;
  }

  @media screen and (min-width: 1200px) {
    width: 420px;
    height: 296px;
  }
`;

export const DecorationContent = styled.div`
  position: absolute;
  bottom: -125px;
  right: -210px;

  svg {
    width: 80%;
  }

  @media screen and (min-width: 1000px) {
    bottom: -88px;
    right: -170px;
  }

  @media screen and (min-width: 1200px) {
    bottom: -63px;
    right: -80px;

    svg {
      width: 100%;
    }
  }
`;

export const B2bOfferPeople = styled.img`
  width: 186px;
  height: 140px;
  position: relative;
  z-index: 1;
  margin-left: 24px;

  @media screen and (min-width: 1000px) {
    width: 240px;
    height: 180px;
  }

  @media screen and (min-width: 1200px) {
    width: 320px;
    height: 240px;
  }
`;
