import React from "react";

import { Divider, Text, Title } from "../../../../components/Core";
import AuthorInfo from "../../../../components/AuthorInfo";
import ArticleImage from "../ArticleImage";

const Placeholder = () => {
  return (
    <article className="article">
      <Text margin="0 0 12px" loading />
      <Title margin="0 0 16px" width="100%" loading />

      <Title level="5" color="black300" width="80%" lineHeight="180%" loading />

      <div className="article__info">
        <AuthorInfo loading />
      </div>
      <ArticleImage loading />
      <Divider margin="40px 0 20px" />

      <Text margin="0 0 24px" fontSize="18px" lineHeight="180%" width="100% !important" loading />
      <Text margin="0 0 24px" fontSize="18px" lineHeight="180%" width="100% !important" loading />
      <Text margin="0 0 24px" fontSize="18px" lineHeight="180%" width="100% !important" loading />
      <Text margin="0 0 24px" fontSize="18px" lineHeight="180%" width="50% !important" loading />
    </article>
  );
};

export default Placeholder;
