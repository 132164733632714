import styled from "styled-components";

export const SignUpPromptWrapper = styled.section`
  border-top: 1px solid #e4e4e4;
  background: ${({ backgroundColor }) => backgroundColor};
  text-align: center;
  padding: 48px 0;

  @media screen and (min-width: 768px) {
    padding: 64px 0;
  }
`;
