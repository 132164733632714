import styled from "styled-components";

export const Container = styled.div`
  margin-top: 24px;
`;

export const Item = styled.button`
  border-radius: 8px;
  border: 1px solid #c5c5c5;
  background: #fff;
  padding: 15px 16px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  outline: none;
  width: 100%;
  cursor: pointer;

  p {
    display: block;
    margin: 0 auto;
  }

  &:last-child {
    margin-right: 0;
  }
`;

export const IconContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 24px;
    height: 24px;
  }
`;
