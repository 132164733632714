import React from "react";

import { Image, Label, Ribbon, Text } from "../../../../components/Core";

const TemplateSlide = ({ image, currency, price, premium = true, popular = false, purchased = false, ...rest }) => {
  return (
    <>
      {premium ? (
        <Label.PremiumAlternative title="Premium" margin="auto auto 4px" />
      ) : (
        <div style={{ height: 18, margin: "auto auto 4px" }} />
      )}
      <div style={{ position: "relative", margin: "auto", width: "fit-content" }}>
        {popular && <Ribbon size={window.innerHeight / 8}>popular</Ribbon>}
        <Image src={image} margin="auto" width="100%" style={{ flex: "1 0 auto", cursor: "pointer" }} {...rest} />
      </div>
      {!purchased && (
        <Text color="white" width="100%" textAlign="center" fontWeight={500} fontSize={16}>
          {premium ? `${currency} ${price}` : "Free"}
        </Text>
      )}
    </>
  );
};

export default TemplateSlide;
