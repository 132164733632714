import React, { useMemo } from "react";
import { Row, Col } from "react-grid-system";
import parse from "html-react-parser";

import { useTranslation } from "b2c/hooks/useTranslation";
import Layout from "b2c/components/Layout";
import { Text, Title, Button } from "b2c/components/Core";
import AvatarProgress from "b2c/components/AvatarProgress";
import LoadingScreen from "b2c/pages/LoadingScreen";
import useStore from "../../../../contexts/store";

const StrengthCheck = ({ CloseButton, setStep }) => {
  const {
    user: { profile_completeness_percentage }
  } = useStore("User");
  const { t } = useTranslation();

  const moveToNextStep = () => setStep(2);

  const { color, prompt, title, description, Actions } = useMemo(() => {
    if (profile_completeness_percentage > 67)
      return {
        color: "success200",
        prompt: t("cv_creator.highest.prompt"),
        title: t("cv_creator.highest.title"),
        description: t("cv_creator.highest.description"),
        Actions: () => (
          <Row justify="center">
            <Col lg={4} sm={12}>
              <Button.Link
                width="100%"
                theme="grey200"
                margin="8px 0 0"
                to={{ pathname: "/profile", state: { noiseless: true, from: "/cv-creator" } }}
              >
                {t("cv_creator.highest.button_1")}
              </Button.Link>
            </Col>
            <Col lg={4} sm={12}>
              <Button width="100%" margin="8px 0 0" onClick={moveToNextStep}>
                {t("cv_creator.highest.button_2")}
              </Button>
            </Col>
          </Row>
        )
      };
    if (profile_completeness_percentage > 33)
      return {
        color: "primary300",
        prompt: t("cv_creator.medium.prompt"),
        title: t("cv_creator.medium.title"),
        description: t("cv_creator.medium.description"),
        Actions: () => (
          <Row justify="center">
            <Col lg={4} sm={12}>
              <Button width="100%" theme="grey200" margin="8px 0 0" onClick={moveToNextStep}>
                {t("cv_creator.medium.button_1")}
              </Button>
            </Col>
            <Col lg={4} sm={12}>
              <Button.Link
                width="100%"
                margin="8px 0 0"
                to={{ pathname: "/profile", state: { noiseless: true, from: "/cv-creator" } }}
              >
                {t("cv_creator.medium.button_2")}
              </Button.Link>
            </Col>
          </Row>
        )
      };
    return {
      color: "accent300",
      prompt: t("cv_creator.low.prompt"),
      title: t("cv_creator.low.title"),
      description: t("cv_creator.low.description"),
      Actions: () => (
        <Row justify="center">
          <Col lg={4} sm={12}>
            <Button.Link
              width="100%"
              margin="8px auto 0"
              to={{ pathname: "/profile", state: { noiseless: true, from: "/cv-creator" } }}
            >
              {t("cv_creator.low.button")}
            </Button.Link>
          </Col>
        </Row>
      )
    };
  }, [profile_completeness_percentage]);

  return profile_completeness_percentage ? (
    <>
      <CloseButton />
      <Layout.Section margin="auto" padding="24px 0">
        <Layout.Content tag="div" size="sm" style={{ display: "flex", flexDirection: "column" }}>
          <Title fontWeight={700} textAlign="center" margin="0 auto 24px">
            {parse(t("cv_creator.title"))}
          </Title>

          <AvatarProgress
            to={{ pathname: "/profile", state: { noiseless: true, from: "/cv-creator" } }}
            width={146}
            style={{ margin: "auto" }}
          />

          <Text textAlign="center" margin="24px auto">
            {t("cv_creator.profile_strength")}:{" "}
            <Text as="b" fontWeight={700} color={color}>
              {prompt}
            </Text>
          </Text>

          <Title margin="12px auto" fontWeight={700} level={4} textAlign="center">
            {title}
          </Title>

          <Text textAlign="center" fontSize={16} lineHeight="26px" margin="0 0 24px">
            {description}
          </Text>

          <Actions />
        </Layout.Content>
      </Layout.Section>
    </>
  ) : (
    <LoadingScreen />
  );
};

export default StrengthCheck;
