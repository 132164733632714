import styled from "styled-components";

export const Header = styled.header`
  padding: 17px;
  border-bottom: 1px solid #eee;
  margin-bottom: 32px;
  background: ${({ $background }) => $background};

  @media screen and (min-width: 768px) {
    padding: 17px 0;
  }
`;

export const Content = styled.div`
  max-width: 1000px;
  margin: 0 auto;
`;
