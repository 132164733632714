import styled from "styled-components";

export const ButtonBack = styled.button`
  color: #333;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  border-radius: 5px;
  border: 1px solid #eee;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 10px;
  margin-top: 4px;
  width: 100%;
  cursor: pointer;
`;
