import React from "react";

import { useTranslation } from "b2c/hooks/useTranslation";
import { Container, Content, Title, Text } from "./styled";

const BaronaMessage = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Content>
        <Title>{t("about.sub-title")}</Title>
        <Text>{t("about.description_1")}</Text>
        <Text>{t("about.description_2")}</Text>
        <Text> {t("about.description_3")}</Text>
      </Content>
    </Container>
  );
};

export default BaronaMessage;
