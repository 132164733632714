import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useModal } from "../../../utils/hooks";
import SuggestGlobalPageModal from "../SuggestGlobalPageModal";
import useStore from "../../contexts/store";
import { countriesList } from "../../constants/workforce";
import { useIsBarona } from "../../hooks";

const SuggestGlobalPage = () => {
  const handleSuggestGlobalPageModal = useModal(<SuggestGlobalPageModal />, "");
  const { getDefaultCountry, defaultCountry } = useStore("ContactUs");
  const isGoogleBot = window.navigator.userAgent.indexOf("Googlebot") !== -1;
  const isBarona = useIsBarona();

  useEffect(() => {
    const isGlobalSuggestedModalShown = localStorage.getItem("globalSuggestedModal");
    const isIncludeCountry = defaultCountry?.name
      ? countriesList.map(item => item.toLowerCase()).includes(defaultCountry.name.toLowerCase())
      : false;

    if (isIncludeCountry && !isGlobalSuggestedModalShown && !isGoogleBot && !isBarona) {
      localStorage.setItem("globalSuggestedModal", "true");
      handleSuggestGlobalPageModal();
    }
  }, [defaultCountry, defaultCountry?.name]);

  useEffect(() => {
    if (!defaultCountry?.name) {
      getDefaultCountry();
    }
  }, []);

  return null;
};

export default observer(SuggestGlobalPage);
