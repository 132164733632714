import React from "react";
import { Visible } from "react-grid-system";
import { Link, NavLink, useHistory } from "react-router-dom";
import { useTranslation } from "b2c/hooks/useTranslation";

import Logo from "components/Logo";
import { Briefcase, Chalkboard, Group, Home } from "@styled-icons/boxicons-solid";
// import { usePromptWhenUserTryNavigateJob } from "../../hooks";
import Highlight from "../Highlight";
import useStore from "../../contexts/store";
import { useLanguageAndCountryUrl } from "../../hooks";

const NavigationDesktop = ({
  homePath,
  employers,
  headerLogo,
  brandName,
  user,
  customJkuat,
  isBarona,
  isCompaniesPage,
  isMultiLanguageBrand
}) => {
  // remove this banner and logic in the future if we do not return this banner
  // const { activeBanner, activePrompt, handleActivePrompt, prompt } = usePromptWhenUserTryNavigateJob();
  const { global_brand } = useStore("initialState");
  const globalBrandName = global_brand.name.toLowerCase();
  const { t } = useTranslation();
  const history = useHistory();
  const activeJobPage = history.location.pathname.includes("/job") ? "is-active" : "";
  const languageAndLanguage = useLanguageAndCountryUrl();
  const baronaItemClassName = `nav-item_${globalBrandName}`;

  const languageAndLanguagePath = languageAndLanguage || homePath;

  return (
    <Visible xxl xl lg>
      <nav className="navigation-side">
        <a href="#main" tabIndex={0} className="skip-nav">
          {t("navigation.skip")}
        </a>
        <Link to={employers ? `${languageAndLanguagePath}/employers` : `${languageAndLanguagePath}`}>
          <Logo logo={headerLogo} alt={brandName} />
        </Link>
        {!employers && !isCompaniesPage && (
          <>
            {!isMultiLanguageBrand && (
              <Highlight highlightId="homepage" desktopOnly>
                <NavLink
                  exact
                  to={!user?.id ? languageAndLanguagePath || "/" : "/home"}
                  className={`nav-item nav-item_border ${baronaItemClassName}`}
                  activeClassName="is-active"
                >
                  <Home className="nav-item__icon" width="16px" />
                  {t("navigation.home")}
                </NavLink>
              </Highlight>
            )}

            {/*
            {activeBanner ? (
              <div style={{ display: "inline-block", position: "relative" }}>
                <NavLink
                  to={`${homePath || ""}/job`}
                  className="nav-item nav-item_border prompt"
                  activeClassName="is-active"
                  onClick={handleActivePrompt}
                  style={{ pointerEvents: activePrompt ? "none" : "" }}
                >
                  <Briefcase className="nav-item__icon" width="16px" /> Jobs
                </NavLink>
                {prompt && <>{prompt}</>}
              </div>
            ) : (

            )}
          */}

            <a
              href={`${languageAndLanguagePath || ""}/job`}
              className={`nav-item nav-item_border ${baronaItemClassName} ${activeJobPage}`}
            >
              <Briefcase className="nav-item__icon" width="16px" />
              {t("navigation.jobs")}
            </a>

            {!customJkuat && (
              <>
                <Highlight highlightId="learn">
                  <NavLink
                    to={`${isBarona ? languageAndLanguagePath || "" : ""}/learn`}
                    className={`nav-item nav-item_border ${baronaItemClassName}`}
                    activeClassName="is-active"
                  >
                    <Chalkboard className="nav-item__icon" width="16px" />
                    {t("navigation.learn")}
                  </NavLink>
                </Highlight>
                <Highlight highlightId="blog">
                  <NavLink
                    to={`${languageAndLanguagePath || ""}/forum`}
                    className={`nav-item nav-item_border ${baronaItemClassName}`}
                    activeClassName="is-active"
                  >
                    <Group className="nav-item__icon" width="16px" />
                    {isBarona ? t("navigation.articles") : t("navigation.forum")}
                  </NavLink>
                </Highlight>
              </>
            )}
          </>
        )}
      </nav>
    </Visible>
  );
};

export default NavigationDesktop;
