import React from "react";
import { Like } from "@styled-icons/boxicons-solid";

import { useTranslation } from "b2c/hooks/useTranslation";
import { Button } from "../../../../components/Core";
import { GuestHidden, GuestVisible } from "../../../../components/WithAuth";
import { SocialBottomControlsWrapper, StyledSectionTitle } from "./styled";

const SocialBottomControls = ({ liked, likes, likeHander }) => {
  const { t } = useTranslation();

  return (
    <SocialBottomControlsWrapper>
      <StyledSectionTitle margin="0 24px 0 0" fontWeight="500">
        {t("forum.socials")}
      </StyledSectionTitle>
      <GuestHidden>
        <Button
          theme={liked ? "secondary100" : "grey200"}
          color={liked ? "secondary200" : "black500"}
          icon={<Like width="20px" />}
          margin="0 12px 0 0"
          role="button"
          tabIndex="0"
          aria-label="Like article"
          onClick={likeHander}
        >
          {likes}
        </Button>
      </GuestHidden>
      <GuestVisible>
        <Button
          theme="grey200"
          color="black500"
          icon={<Like width="20px" />}
          margin="0 12px 0 0"
          role="button"
          tabIndex="0"
          aria-label="Like article"
          disabled
        >
          {likes}
        </Button>
      </GuestVisible>

      {/* <StyledSectionTitle margin='0 24px 0 auto' fontWeight='500'>
        Share
      </StyledSectionTitle>
      <Button
        theme='grey200'
        icon={<FacebookSquare width='20px' />}
        margin='0 12px 0 0'
        role='button'
        tabIndex='0'
        aria-label='Share article on Facebook'
      >
        5
      </Button>
      <Button
        theme='grey200'
        icon={<LinkedinSquare width='20px' />}
        margin='0 12px 0 0'
        role='button'
        tabIndex='0'
        aria-label='Share article on LinkedIn'
      >
        0
      </Button>
      <Button
        theme='grey200'
        icon={<Twitter width='20px' />}
        margin='0 12px 0 0'
        role='button'
        tabIndex='0'
        aria-label='Share article on Twitter'
      >
        1
      </Button>
      <Button
        theme='grey200'
        icon={<Link width='20px' />}
        role='button'
        tabIndex='0'
        aria-label='Copy article URL'
      /> */}
    </SocialBottomControlsWrapper>
  );
};

export default SocialBottomControls;
