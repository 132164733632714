import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react";
import { Row, Col, ScreenClassContext } from "react-grid-system";
import { Phone, Envelope } from "@styled-icons/boxicons-regular";

import supportImage from "images/b2c/onboarding/support.png";
import PinIcon from "images/b2c/icons/pinIcon.svg";
import QuestionIcon from "images/b2c/icons/questionIco.svg";
import MessagesOrange from "images/b2c/icons/messagesOrange.svg";
import BaronaLogoSvg from "images/barona-logo.svg";
import { useTranslation } from "b2c/hooks/useTranslation";
import Layout from "../../components/Layout";
import LinkSupportChannel from "../../components/LinkSupportChannel";
import { Button, Text, Card, Image, Spinner } from "../../components/Core";
import { withTrackedRoute } from "../../hocs";
import { useDocumentTitle } from "../../../utils/hooks";
import { useIsBarona, useLanguageAndCountryUrl, useRedirectContactPage } from "../../hooks";
import { IconQuestion } from "./styled";
import ContactTeam from "../ContactTeam";
import { getCompanyName } from "../../contexts/branded";
import { Overlay } from "../Onboarding/styled";
import useStore from "../../contexts/store";

const ContactUsScreen = () => {
  const isBarona = useIsBarona();
  const screen = useContext(ScreenClassContext);
  const isSm = /xs|sm|md/.test(screen);
  const { t } = useTranslation();
  const languageAndLanguage = useLanguageAndCountryUrl();

  const { global_brand } = useStore("initialState");
  const isShowLoading = global_brand.external_contact_us_url;

  useDocumentTitle(`${t("contact.meta_title")} | ${getCompanyName()}`);
  useRedirectContactPage(global_brand.external_contact_us_url);

  useEffect(() => {
    if (window.scrollY) {
      window.scroll(0, 0);
    }
  }, []);

  return (
    <>
      {isShowLoading && (
        <Overlay>
          <Spinner size="50px" margin="auto" />
          <BaronaLogoSvg />
        </Overlay>
      )}

      {isBarona ? (
        <>
          <Layout.Content tag="div" size="xl">
            <Text
              width="100%"
              fontSize={isSm ? "22px" : "36px"}
              fontWeight="600"
              margin={isSm ? "0 auto 64px auto" : "64px auto"}
              textAlign="center"
            >
              {t("contact.title")}
            </Text>
          </Layout.Content>
          <Layout.Content
            size="xl"
            style={{
              display: "flex",
              flexWrap: "wrap",
              marginBottom: isSm ? "40px" : "64px",
              gap: isSm ? "0" : "40px",
              justifyContent: "center"
            }}
          >
            <Card
              shadow
              style={{ margin: isSm ? "0 auto 64px auto" : "0 0 24px 0", maxWidth: "400px", borderRadius: "8px" }}
            >
              <div
                style={{
                  justifyContent: "center",
                  width: "64px",
                  height: "64px",
                  backgroundColor: "#fff",
                  borderRadius: "50%",
                  filter: "drop-shadow(0 11px 20px rgb(0 0 0 / 10%))",
                  margin: "-40px auto 0",
                  alignItems: "center",
                  display: "flex"
                }}
              >
                <IconQuestion>
                  <QuestionIcon
                    style={{
                      width: "48px",
                      height: "48px"
                    }}
                  />
                </IconQuestion>
              </div>
              <Card.Content padding="12px 16px 16px">
                <Text fontSize={18} fontWeight={600} width="100%" textAlign="center" margin="0 0 15px">
                  {t("contact.help.title_1")}
                </Text>
                <Text margin="0 0 15px" textAlign="center">
                  {t("contact.help.description_1")}
                </Text>

                <Button.Link
                  margin="24px auto 12px"
                  width="100%"
                  theme="primary"
                  color="white"
                  to={`${languageAndLanguage}/faq`}
                >
                  {t("contact.help.button_1")}
                </Button.Link>
              </Card.Content>
            </Card>
            <Card
              shadow
              style={{ margin: isSm ? "0 auto 64px auto" : "0 0 24px 0", maxWidth: "400px", borderRadius: "8px" }}
            >
              <div
                style={{
                  justifyContent: "center",
                  width: "64px",
                  height: "64px",
                  backgroundColor: "#fff",
                  borderRadius: "50%",
                  filter: "drop-shadow(0 11px 20px rgb(0 0 0 / 10%))",
                  margin: "-40px auto 0",
                  alignItems: "center",
                  display: "flex"
                }}
              >
                <IconQuestion>
                  <QuestionIcon
                    style={{
                      width: "48px",
                      height: "48px"
                    }}
                  />
                </IconQuestion>
              </div>
              <Card.Content padding="12px 16px 16px">
                <Text fontSize={18} fontWeight={600} width="100%" textAlign="center" margin="0 0 15px">
                  {t("contact.help.title_2")}
                </Text>
                <Text margin="0 0 15px" textAlign="center">
                  {t("contact.help.description_2")}
                </Text>

                <Button.Link
                  margin="24px auto 12px"
                  width="100%"
                  theme="primary"
                  color="white"
                  to={`${languageAndLanguage}/faq`}
                >
                  {t("contact.help.button_2")}
                </Button.Link>
              </Card.Content>
            </Card>
            <Card
              shadow
              style={{ margin: isSm ? "0 auto 64px auto" : "0 0 24px 0", maxWidth: "400px", borderRadius: "8px" }}
            >
              <div
                style={{
                  justifyContent: "center",
                  width: "64px",
                  height: "64px",
                  backgroundColor: "#fff",
                  borderRadius: "50%",
                  filter: "drop-shadow(0 11px 20px rgb(0 0 0 / 10%))",
                  margin: "-40px auto 0",
                  alignItems: "center",
                  display: "flex"
                }}
              >
                <IconQuestion className="icon-chat">
                  <MessagesOrange
                    style={{
                      width: "32px",
                      height: "32px"
                    }}
                  />
                </IconQuestion>
              </div>
              <Card.Content padding="12px 16px 16px">
                <Text fontSize={18} fontWeight={600} width="100%" textAlign="center" margin="0 0 15px">
                  {t("contact.help.title_3")}
                </Text>
                <Text margin="0 0 15px" textAlign="center">
                  {t("contact.help.description_3")}
                </Text>
                <LinkSupportChannel theme="primary" width="100%" color="white" margin="0">
                  {t("contact.help.button_3")}
                </LinkSupportChannel>
              </Card.Content>
            </Card>
          </Layout.Content>

          <ContactTeam />
        </>
      ) : (
        <>
          <Layout.Section backgroundColor="white" padding="24px 0 0" margin="0 0 70px">
            <Layout.Content tag="div" size="xl">
              <Row align="center">
                <Col>
                  <Text width="100%" fontSize={22} fontWeight={600} margin="0 0 25px">
                    Contact Us
                  </Text>
                  <Text width="100%" lineHeight="20px" margin="0 0 30px 0">
                    Fuzu Limited is a company registered in Finland. Fuzu Ltd, Lapinlahdenkatu 16, 00180 Helsinki,
                    Finland. Feel free to contact us if you need any assistance with using Fuzu or want to learn more
                    about our products and services.
                  </Text>
                </Col>
                <Col xs="content" style={{ display: "flex" }}>
                  <Image src={supportImage} width="130px" />
                </Col>
              </Row>
            </Layout.Content>
          </Layout.Section>
          <Layout.Content tag="div" size="xl">
            <Text width="100%" fontSize={22} fontWeight={600} margin="0 0 50px 20px">
              {t("contact_fuzu.sub-title")}
            </Text>
          </Layout.Content>
          <Layout.Content size="xl" style={{ display: "flex", flexWrap: "wrap" }}>
            <Card shadow style={{ margin: "0 10px 50px", maxWidth: "430px" }}>
              <div
                style={{
                  justifyContent: "center",
                  width: "80px",
                  height: "80px",
                  backgroundColor: "#fff",
                  borderRadius: "50%",
                  filter: "drop-shadow(0 11px 20px rgb(0 0 0 / 10%))",
                  margin: "-40px auto 0",
                  alignItems: "center",
                  display: "flex"
                }}
              >
                <QuestionIcon
                  style={{
                    width: "48px",
                    height: "48px"
                  }}
                />
              </div>
              <Card.Content padding="12px 16px 16px">
                <Text fontSize={18} fontWeight={600} width="100%" textAlign="center" margin="0 0 15px">
                  {t("contact.help.title_1")}
                </Text>
                <Text margin="0 0 15px" textAlign="center">
                  {t("contact.help.description_1")}
                </Text>

                <Button.Link margin="24px auto" width="100%" theme="black0" to="/faq">
                  {t("contact.help.button_1")}
                </Button.Link>
              </Card.Content>
            </Card>
            <Card shadow style={{ margin: "0 10px 50px", maxWidth: "430px" }}>
              <div
                style={{
                  justifyContent: "center",
                  width: "80px",
                  height: "80px",
                  backgroundColor: "#fff",
                  borderRadius: "50%",
                  filter: "drop-shadow(0 11px 20px rgb(0 0 0 / 10%))",
                  margin: "-40px auto 0",
                  alignItems: "center",
                  display: "flex"
                }}
              >
                <MessagesOrange
                  style={{
                    width: "32px",
                    height: "32px"
                  }}
                />
              </div>
              <Card.Content padding="12px 16px 16px">
                <Text fontSize={18} fontWeight={600} width="100%" textAlign="center" margin="0 0 15px">
                  {t("contact.help.title_3")}
                </Text>
                <Text margin="0 0 15px" textAlign="center">
                  {t("contact.help.description_3_fuzu")}
                </Text>
                <LinkSupportChannel theme="black0" width="100%" margin={0}>
                  {t("contact.help.button_3")}
                </LinkSupportChannel>
              </Card.Content>
            </Card>
          </Layout.Content>

          <Layout.Content tag="div" size="xl">
            <Text width="100%" fontSize={22} fontWeight={600} margin="0 0 50px 20px">
              {t("contact_fuzu.offices.title")}
            </Text>
          </Layout.Content>
          <Layout.Content size="xl" style={{ display: "flex", flexWrap: "wrap" }}>
            <Card shadow style={{ margin: "0 auto 50px", maxWidth: "430px" }}>
              <Card.Header padding="0" style={{ marginTop: "-25px", justifyContent: "center" }}>
                <PinIcon
                  style={{
                    filter: "drop-shadow(0 11px 20px rgb(0 0 0 / 10%))"
                  }}
                />
              </Card.Header>
              <Card.Content padding="12px 16px 16px">
                <Text fontSize={18} fontWeight={600} width="100%" textAlign="center" margin="0 0 15px">
                  {t("contact_fuzu.offices.kenya")}
                </Text>
                <Text fontWeight={600} margin="0 0 7px">
                  {t("contact_fuzu.offices.customer_support")}
                </Text>
                <Text fontSize={16}>
                  <a href="tel:+254713985473" className="link underlined">
                    <Phone width={20} style={{ marginRight: 10 }} />
                    +254 71 398 5473
                  </a>
                </Text>
                <Text fontSize={16}>
                  <a href="mailto:support@fuzu.com" className="link underlined">
                    <Envelope width={20} style={{ marginRight: 10 }} />
                    support@fuzu.com
                  </a>
                </Text>

                <Card.Separator margin="15px 0" />

                <Text fontWeight={600} margin="0 0 7px">
                  {t("contact_fuzu.offices.employers_and_businesses")}
                </Text>
                <Text fontSize={16}>
                  <a href="tel:+254717565840" className="link">
                    <Phone width={20} style={{ marginRight: 10 }} />
                    +254 71 756 5840
                  </a>
                </Text>
                <Text fontSize={16}>
                  <a href="mailto:business@fuzu.com" className="link underlined">
                    <Envelope width={20} style={{ marginRight: 10 }} />
                    business@fuzu.com
                  </a>
                </Text>
              </Card.Content>
            </Card>
            <Card shadow style={{ margin: "0 auto 50px", maxWidth: "430px" }}>
              <Card.Header padding="0" style={{ marginTop: "-25px", justifyContent: "center" }}>
                <PinIcon
                  style={{
                    filter: "drop-shadow(0 11px 20px rgb(0 0 0 / 10%))"
                  }}
                />
              </Card.Header>
              <Card.Content padding="12px 16px 16px">
                <Text fontSize={18} fontWeight={600} width="100%" textAlign="center" margin="0 0 15px">
                  Uganda
                </Text>
                <Text fontWeight={600} margin="0 0 7px">
                  Customer Support
                </Text>
                <Text fontSize={16}>
                  <a href="tel:+256778576535" className="link">
                    <Phone width={20} style={{ marginRight: 10 }} />
                    +256778 576535
                  </a>
                </Text>
                <Text fontSize={16}>
                  <a href="mailto:support@fuzu.com" className="link underlined">
                    <Envelope width={20} style={{ marginRight: 10 }} />
                    support@fuzu.com
                  </a>
                </Text>

                <Card.Separator margin="15px 0" />

                <Text fontWeight={600} margin="0 0 7px">
                  Employers and businesses
                </Text>
                <Text fontSize={16}>
                  <a href="tel:+256778576535" className="link">
                    <Phone width={20} style={{ marginRight: 10 }} />
                    +256 77 857 6535
                  </a>
                </Text>
                <Text fontSize={16}>
                  <a href="mailto:business@fuzu.com" className="link underlined">
                    <Envelope width={20} style={{ marginRight: 10 }} />
                    business@fuzu.com
                  </a>
                </Text>
              </Card.Content>
            </Card>
            <Card shadow style={{ margin: "0 auto 50px", maxWidth: "430px" }}>
              <Card.Header padding="0" style={{ marginTop: "-25px", justifyContent: "center" }}>
                <PinIcon
                  style={{
                    filter: "drop-shadow(0 11px 20px rgb(0 0 0 / 10%))"
                  }}
                />
              </Card.Header>
              <Card.Content padding="12px 16px 16px">
                <Text fontSize={18} fontWeight={600} width="100%" textAlign="center" margin="0 0 15px">
                  {t("contact_fuzu.offices.nigeria")}
                </Text>
                <Text fontWeight={600} margin="0 0 7px">
                  {t("contact_fuzu.offices.customer_support")}
                </Text>
                <Text fontSize={16}>
                  <a href="tel:+2348121688744" className="link">
                    <Phone width={20} style={{ marginRight: 10 }} />
                    +234 812 168 8744
                  </a>
                </Text>
                <Text fontSize={16}>
                  <a href="mailto:support@fuzu.com" className="link underlined">
                    <Envelope width={20} style={{ marginRight: 10 }} />
                    support@fuzu.com
                  </a>
                </Text>

                <Card.Separator margin="15px 0" />

                <Text fontWeight={600} margin="0 0 7px">
                  {t("contact_fuzu.offices.employers_and_businesses")}
                </Text>
                <Text fontSize={16}>
                  <a href="tel:+2348121688744" className="link">
                    <Phone width={20} style={{ marginRight: 10 }} />
                    +234 812 168 8744
                  </a>
                </Text>
                <Text fontSize={16}>
                  <a href="mailto:business@fuzu.com" className="link underlined">
                    <Envelope width={20} style={{ marginRight: 10 }} />
                    business@fuzu.com
                  </a>
                </Text>
              </Card.Content>
            </Card>
          </Layout.Content>
        </>
      )}
    </>
  );
};

export default withTrackedRoute(observer(ContactUsScreen));
