import styled from "styled-components";

export const IntroductionContents = styled.div`
  @media screen and (max-width: 767px) {
    margin: 0 -20px;
  }

  .b2c-card {
    @media screen and (max-width: 767px) {
      width: 100%;
    }

    .card-header {
      height: 320px;

      @media screen and (max-width: 767px) {
        height: 140px;
      }
    }

    p:not(:last-child) {
      margin-bottom: 12px;
    }
  }
`;
