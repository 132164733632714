import styled from "styled-components";
import { Link } from "react-router-dom";

export const AuthWelcomeImage = styled.img`
  ${({ margin = "auto", width = 780, smWidth = 780 }) => `
    max-width: 100%;
    width: ${width}px;
    margin: ${margin};
    @media screen and (max-width: 767px) {
      position: absolute;
      width: ${smWidth}px;
      right: 0;
      bottom: -25%;
      z-index: -1;
      opacity: 0.2;
    }
  `}
`;

export const AuthLeftContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ isJKUAT }) => (isJKUAT ? "flex-start" : "center")};
  width: 100%;
  height: 100%;
`;

export const LogoLink = styled(Link)`
  outline: none;
  display: block;
  margin: 0 auto 24px auto;

  svg {
    width: 182px;
    height: 40px;
  }

  @media screen and (min-width: 768px) {
    margin: 0 auto 72px;
  }
`;

export const LogoLinkKepsa = styled(Link)`
  border-radius: 8px;
  background: #fff;
  padding: 8px;
  width: 48px;
  height: 48px;
  display: block;
  margin-bottom: 16px;

  img {
    max-width: 100%;
  }

  @media screen and (min-width: 768px) {
    width: 112px;
    height: 112px;
    margin-bottom: 40px;
  }
`;

export const AuthInfo = styled.div`
  margin-top: 20px;

  @media screen and (min-width: 768px) {
    margin-top: 40px;
  }
`;

export const AuthTitle = styled.h1`
  color: #000;
  font-size: 20px;
  font-weight: 600;
  line-height: 130%;
  margin-bottom: 8px;

  span {
    display: block;
  }

  @media screen and (min-width: 768px) {
    font-size: 36px;
    line-height: 120%;
  }
`;

export const AuthText = styled.p`
  color: #5b705a;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  max-width: 480px;

  @media screen and (min-width: 768px) {
    font-size: 20px;
    line-height: 160%;
  }
`;

export const AuthTitleKepsa = styled.h1`
  color: #000;
  font-size: 20px;
  font-weight: 600;
  line-height: 130%;

  @media screen and (min-width: 768px) {
    font-size: 36px;
    line-height: 120%;
  }
`;

export const AuthTextKepsa = styled.p`
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin: 8px 0 0;

  @media screen and (min-width: 768px) {
    margin: 16px 0 0;
    font-size: 16px;
  }
`;

export const AuthLeftContainer = styled.div`
  background: ${({ $backGroundImage }) => $backGroundImage};
  background-size: cover;
  background-position: center center;
  width: 50%;
  flex: 0 0 50%;
  min-width: 0px;
  padding: 50px 24px;
  position: relative;
  overflow: hidden;
  height: 100%;
  z-index: 1;

  @media screen and (max-width: 767px) {
    width: 100%;
    flex: 0 0 ${({ isHideHeight }) => (isHideHeight ? "auto" : "320px")};
    height: ${({ isHideHeight }) => (isHideHeight ? "auto" : "320px")};
    max-height: ${({ isHideHeight }) => (isHideHeight ? "initial" : "320px")};
    padding: 20px;
  }

  @media screen and (min-width: 768px) {
    padding: ${({ isJKUAT }) => (isJKUAT ? "50px 80px" : "  padding: 50px 24px")};
  }
`;

export const BaronaTitle = styled.p`
  font-family: Originals2;
  font-size: 60px;
  line-height: 50px;
  font-weight: 400;
  text-align: center;
  margin: 0 auto;
  color: #ffffff;
  font-style: italic;

  @media screen and (min-width: 768px) {
    font-size: 120px;
    line-height: 100px;
  }
`;

export const Content = styled.p`
  max-width: 480px;
  text-align: left;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
