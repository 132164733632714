import React, { useContext, useMemo, forwardRef } from "react";
import { observer } from "mobx-react";

import { useRouteMatch, useLocation } from "react-router-dom";
import styled from "styled-components";
import { pickRandom } from "utils/helpers";
import useStore from "../../contexts/store";

import { MarketPromoHidden } from "../../contexts/branded";
import { GuestHidden } from "../WithAuth";

import { Text, Button, Label } from "../Core";
import { LoadingContent } from "../Core/Spinner";
import PremiumBox from "../PremiumBox";
import EmployersLandingPromo from "../EmployersLandingPromo";
import ThemeContext from "../../contexts/theme";
import { useImpressionTrack } from "../../hooks";

const PremiumBanner = forwardRef(
  (
    {
      title,
      Button: ButtonComponent,
      loading,
      additional = null,
      visible_for_guests = false,
      alternative_text = "Premium",
      type,
      ...rest
    },
    ref
  ) => {
    const { track } = useStore("Tracking");
    const { path } = useRouteMatch();
    const { targetRef } = useImpressionTrack({ type, disable: loading });

    const trackPromoClick = () => {
      track("promo_clicked", { match_path: path, type });
    };

    const VisibilityWrapper = visible_for_guests ? React.Fragment : GuestHidden;

    return (
      <VisibilityWrapper>
        <MarketPromoHidden>
          <PremiumBox ref={ref || targetRef} onClickCapture={trackPromoClick} {...rest}>
            <LoadingContent
              loading={loading}
              size="42px"
              margin="40px auto"
              primaryFill="secondary300"
              secondaryFill="secondary100"
            >
              <Label.PremiumAlternative title={alternative_text} margin="0 auto auto" />
              {title && (
                <Text
                  fontSize={16}
                  fontWeight={600}
                  lineHeight="140%"
                  margin="0 0 auto"
                  textAlign="center"
                  width="100%"
                  color="white"
                >
                  {title}
                </Text>
              )}
              {additional}
              {ButtonComponent}
            </LoadingContent>
          </PremiumBox>
        </MarketPromoHidden>
      </VisibilityWrapper>
    );
  }
);

PremiumBanner.Cv = observer(
  forwardRef(({ title = "Stand out with Premium CV", divided, ...props }, ref) => {
    const { features: { premium_cv_creator } = {} } = useStore("Premium");
    const { pathname } = useLocation();

    const Theme = useContext(ThemeContext);

    return !premium_cv_creator?.activated ? (
      <>
        {divided && (
          <StyledDivider backgroundColor={Theme.grey200} maskColor={Theme.white} textColor={Theme.black200} />
        )}
        <PremiumBanner
          ref={ref}
          loading={!premium_cv_creator}
          title={title}
          type="premium_cv_creator"
          Button={
            <Button.Link
              fontSize={14}
              margin="0 auto"
              width={180}
              height={32}
              theme="additional200"
              to={{ pathname: "/cv-creator", state: { from: pathname } }}
            >
              Get Premium CV
            </Button.Link>
          }
          {...props}
        />
      </>
    ) : null;
  })
);

PremiumBanner.CareerCoaching = observer(
  forwardRef((props, ref) => {
    const { user } = useStore("User");

    const prompt = useMemo(() => {
      switch (user?.segment) {
        default:
        case "hustlers":
        case "junior_professionals":
        case "workers":
          return pickRandom(prompts.career_coaching.junior);
        case "students":
        case "graduates":
          return pickRandom(prompts.career_coaching.student);
        case "experts":
        case "managers":
          return pickRandom(prompts.career_coaching.expert);
      }
    }, [user?.segment]);

    return (
      <PremiumBanner
        ref={ref}
        title={prompt}
        type="career_coaching"
        Button={
          <Button.Link
            to="/premium/career-coaching"
            fontSize={14}
            margin="0 auto"
            width={180}
            height={32}
            theme="additional200"
          >
            Get Career Coaching
          </Button.Link>
        }
        {...props}
      />
    );
  })
);

PremiumBanner.InstantFeedback = observer(
  forwardRef(({ title = "Tired of not getting feedback? Want to know how you compare to others?", ...props }, ref) => {
    return (
      <PremiumBanner
        ref={ref}
        title={title}
        type="instant_feedback"
        Button={
          <Button.Link
            to="/premium/instant-feedback"
            fontSize={14}
            margin="0 auto"
            width={180}
            height={32}
            theme="additional200"
          >
            Get Instant Feedback
          </Button.Link>
        }
        {...props}
      />
    );
  })
);

PremiumBanner.EmployerLandingPage = observer(
  forwardRef(({ title = "Looking for talent?", ...props }, ref) => {
    return (
      <PremiumBanner
        ref={ref}
        title={title}
        type="employer_landing_page"
        alternative_text="Fuzu for employers"
        visible_for_guests
        Button={
          <Button.Link to="/employers" fontSize={14} margin="0 auto" width={180} height={32} theme="additional200">
            Hire talent through Fuzu
          </Button.Link>
        }
        {...props}
      />
    );
  })
);

PremiumBanner.EmployerLandingPromo = props => {
  const { targetRef } = useImpressionTrack({ type: "employer_landing_page" });
  return (
    <div ref={targetRef}>
      <EmployersLandingPromo type={props.type} linkTo={props.linkTo} />
    </div>
  );
};

const prompts = {
  career_coaching: {
    student: [
      "Struggling with job search? We can help!",
      "Feeling lost and alone with your career?",
      "Want to learn the best tactics for job search?"
    ],
    junior: [
      "Searching for your dream job? We can help!",
      "Unsure about your next career move?",
      "Want to become a manager?"
    ],
    expert: [
      "Looking for the next leap in your career?",
      "Want to jump to the next pay grade?",
      "Stuck in your current job?"
    ]
  }
};

export const StyledDivider = styled.hr`
  display: flex;
  border: none;
  outline: none;
  width: 100%;
  margin: 16px 0;
  user-select: none;
  box-shadow: none;

  :before {
    position: absolute;
    font-size: 14px;
    padding: 0 14px;
    content: "or";
    right: 50%;
    transform: translateY(-50%) translateX(50%);
    color: ${({ textColor }) => textColor};
    background-color: ${({ maskColor }) => maskColor};
  }

  :after {
    content: " ";
    width: 100%;
    height: 2px;
    background-color: ${({ backgroundColor }) => backgroundColor};
  }
`;

export default PremiumBanner;
