import styled from "styled-components";

export const InfoSliderWrapper = styled.section`
  background-image: linear-gradient(0deg, rgba(246, 252, 255, 0.0001) 0%, #f7f7f7 100%);
  padding: 40px 0;
  width: 100%;
`;

export const InfoImageWrapper = styled.div`
  img {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

export const InfoSlideContent = styled.div`
  max-width: 540px;
  margin: 0 auto;
  @media screen and (max-width: 767px) {
    text-align: center;
  }
`;
