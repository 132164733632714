import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
  background-color: #f3f3f3;
  padding: 32px 16px;

  @media screen and (min-width: 1008px) {
    padding: 64px 16px;
  }

  @media screen and (min-width: 1440px) {
    padding: 64px 0;
  }
`;

export const Content = styled.div`
  max-width: 880px;
  width: 100%;

  @media screen and (min-width: 768px) {
    margin: 0 auto;
  }
`;

export const Title = styled.h3`
  color: #18181b;
  text-align: center;
  font-family: Poppins;
  font-size: 28px;
  font-weight: 500;
  line-height: 54px;
  margin-bottom: 24px;

  @media screen and (min-width: 768px) {
    font-size: 36px;
    line-height: 54px;
  }
`;

const getInTouchButton = `
  border-radius: 5px;
  background: #18181b;
  width: 175px;
  height: 48px;
  align-items: center;
  padding: 8px 15px;
  color: #f3f3f3;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.2px;
  display: flex;
  gap: 8px;
  margin: 0 auto;
  text-decoration: none;
  cursor: pointer;
  
  svg {
    width: 16px;
    height: 16px;
  }
  
  @media screen and (min-width: 1008px) {
    &:hover {
      opacity: 0.8;
    }
  }
 
`;

export const GetInTouchLink = styled(Link)`
  ${getInTouchButton}
`;

export const GetInTouchButton = styled.button`
  ${getInTouchButton}
`;
