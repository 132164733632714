import React, { useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useScreenClass } from "react-grid-system";

import light_fuzu_logo from "images/new_fuzu_logo_yellow.png";
import baronaLogo from "images/barona-logo.png";
import fuzu_logo from "images/new_fuzu_logo_black.png";
import BannerContent from "b2c/components/BannerContent";
import MtnBanner from "b2c/components/MtnBanner";
import { FirstTimeModalContent } from "b2c/components/ModalContents";
import { useModal, useNotification } from "utils/hooks";
import { clearBrowserHistory } from "b2c/utils/helpers";
import ServerSide from "../contexts/ssr";
import ThemeContext from "../contexts/theme";
import useStore from "../contexts/store";
import BrandedContext, {
  isCustomPlatformLoggedIn,
  isNormalFuzu,
  isWhitelabelled,
  isKepsa,
  isWhitelabelledOrCustomBranded
} from "../contexts/branded";
import CountryContext from "../contexts/country";
import { useShowModalAllowNotify } from "./index";

export const useHeader = ({ isBarona }) => {
  const { ssr } = useContext(ServerSide);
  const Theme = useContext(ThemeContext);
  const {
    feature_toggles: { mtn_pulse_banner },
    impersonate_user: impersonateUser
  } = useStore("initialState");

  const {
    user,
    navigation,
    toggleUserAccountMenu,
    userAccountMenu,
    Profile: { userDevice }
  } = useStore("User");
  const { unreadCount } = useStore("Messages");
  const { state: { loading } = {}, header: { header_text_color = "white", logo = light_fuzu_logo } = {} } =
    useStore("HomeLayout");

  const { name: brandName, employer, landing_url: loadingUrl } = useContext(BrandedContext);
  const whitelabelledOrCustomBranded = isWhitelabelledOrCustomBranded();
  const { country } = useContext(CountryContext);
  const { pathname } = useLocation();
  const isHeroCard = pathname.indexOf("/career-hero") !== -1;
  const activateBanner = useNotification(
    <BannerContent />,
    "primary",
    window.localStorage?.setItem("fuzuBannerShown", true)
  );
  const notifyPulsers = useNotification(<MtnBanner />, "success300");
  const { push } = useHistory();
  const screen = useScreenClass();
  const isMessage = pathname === "/messages";

  const homePath = country?.slug ? `/${country?.slug}` : "";
  const employers = /employers/.test(pathname);
  const isCompaniesPage = /companies/.test(pathname);
  const normalFuzu = isNormalFuzu();
  const isKepsaBrand = isKepsa();
  const customPlatformLoggedIn = isCustomPlatformLoggedIn() || (isKepsaBrand && user?.id);
  const isMobile = ["sm", "xs"].includes(screen);
  const isShowHeader = !navigation.mobile && isMessage && isMobile;
  const isHome = pathname === "/home";
  const headerClass = loading && !ssr ? "loading" : "loaded";
  const { isShowNotificationModalAllowNotify, openNotificationModalAllowNotify } = useShowModalAllowNotify();
  let timeout;
  const openFirstTimeModal = useModal(<FirstTimeModalContent />, "");
  const whitelabelled = isWhitelabelled();

  useEffect(() => {
    if (
      user?.b2c_onboarding_tutorial_completed === false &&
      user?.onboarding_steps_completed &&
      !whitelabelled &&
      !loading &&
      !isBarona &&
      isHome
    ) {
      clearBrowserHistory();

      openFirstTimeModal({ isDisableWindowScroll: true });
    }
  }, [user?.b2c_onboarding_tutorial_completed, loading, navigation, pathname]);

  useEffect(() => {
    if (
      user?.b2c_onboarding_tutorial_completed &&
      user?.onboarding_steps_completed &&
      isShowNotificationModalAllowNotify &&
      !userDevice?.allowPushNotifications
    ) {
      timeout = window.setTimeout(() => {
        openNotificationModalAllowNotify();
      }, 30000);
    }

    return () => {
      window?.clearTimeout(timeout);
    };
  }, [user?.b2c_onboarding_tutorial_completed, user?.onboarding_steps_completed, userDevice?.allowPushNotifications]);

  const headerLogo = isBarona
    ? baronaLogo
    : (whitelabelledOrCustomBranded && employer?.logo) ||
      logo ||
      (header_text_color === "black" ? fuzu_logo : light_fuzu_logo);

  useEffect(() => {
    if (!window.localStorage?.getItem("fuzuBannerShown") && user?.id && pathname !== "/home") {
      activateBanner();
    }
    if (mtn_pulse_banner && pathname === "/uganda") notifyPulsers();
  }, [pathname, user]);

  return {
    Theme,
    unreadCount,
    brandName,
    loadingUrl,
    isHeroCard,
    push,
    homePath,
    employers,
    isCompaniesPage,
    normalFuzu,
    customPlatformLoggedIn,
    isShowHeader,
    headerClass,
    user,
    headerLogo,
    navigation,
    toggleUserAccountMenu,
    userAccountMenu,
    pathname,
    impersonateUser
  };
};
