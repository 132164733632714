import React from "react";
import { observer } from "mobx-react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import useStore from "../../contexts/store";
import { useStandaloneMode } from "../../contexts/standalone";
import StandaloneLandingPage from "../StandaloneLandingPage";

const authRoutes = [
  "/login",
  "/signin",
  "/reset",
  "/signup",
  "/onboarding",
  "/pro/auth/linkedin/callback",
  "/pro/password/edit"
];

const StandaloneRoutes = () => {
  const { user } = useStore("User");
  const isStandalone = useStandaloneMode();
  const isAuthRoute = useRouteMatch(authRoutes);

  const isPageAllowed = !isStandalone || user?.id || isAuthRoute;

  return (
    <Switch>
      <Route
        path="/standalone-landing"
        render={() => {
          if (!isStandalone || user?.id) {
            return <Redirect to="/" />;
          }

          return <StandaloneLandingPage />;
        }}
      />

      {!isPageAllowed && <Redirect to="/standalone-landing" />}
    </Switch>
  );
};

export default observer(StandaloneRoutes);
