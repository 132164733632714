import React, { useMemo, useContext, useRef, useEffect, useState } from "react";

import { useRouteMatch } from "react-router-dom";

import PropTypes from "prop-types";
import { Row, Col, ScreenClassContext, Hidden, Visible } from "react-grid-system";

/* hide for this ticket https://app.shortcut.com/fuzu/story/4129/remove-instant-feedback-remove-all-promo-elements-advertising-if-from-b2c-app */
// import { useModal } from "utils/hooks";
import { pickRandom } from "utils/helpers";
import { MailSend } from "@styled-icons/boxicons-regular";

import invitePromoImage from "images/b2c/promo/invitePromoImage.png";
// import ifPromo from "images/b2c/promo/ifPromo.png";
import guidancePromoImage from "images/b2c/promo/guidancePromoImage.jpg";
import exclusivePromoImage from "images/b2c/promo/exclusivePromoImage.png";
import circleBG_dark from "images/b2c/circleBG_dark.png";
import cup from "images/b2c/cup.png";
import FuzuCoin from "images/b2c/fuzu_coin.svg";
import { useTranslation } from "../../hooks/useTranslation";
import { PromoImage, PromoUniCaf, PromoUniCafBackground } from "./styled";
import { PromotionCountryVisible } from "../WithAuth";
// import { GetInstanceFeedbackContent, InstantFeedbackContent } from "../ModalContents";
import PremiumCv from "../PremiumCvPromo";
import { Button, Label, Card, Text, Title, Image } from "../Core";
import UnderDevelopment from "../UnderDevelopment";
import { MarketPromoHidden } from "../../contexts/branded";
import useStore from "../../contexts/store";
import { useImpressionTrack } from "../../hooks";

const PromoSection = ({ position_id, type, margin = "30px 0px", custom, instant_feedback, ...props }) => {
  const { track } = useStore("Tracking");
  const { user } = useStore("User");
  const { path } = useRouteMatch();
  const { targetRef } = useImpressionTrack({ type });
  const { t } = useTranslation();

  const isActiveProduct = ["NG", "KE"].includes(user?.product_country?.country_code);

  const segmentCategory = useMemo(() => {
    switch (user?.segment) {
      default:
      case "hustlers":
      case "junior_professionals":
      case "workers":
        return "junior";
      case "students":
      case "graduates":
        return "student";
      case "experts":
      case "managers":
        return "expert";
    }
  }, [user?.segment]);

  /*
  const openInstantFeedbackModal = useModal(
    instant_feedback?.activated ? (
      <InstantFeedbackContent position_id={position_id} />
    ) : (
      <GetInstanceFeedbackContent position_id={position_id} />
    ),
    ""
  );
  */

  const prompts = {
    career_coaching: {
      student: [t("promo.career_coaching.student.description_1"), t("promo.career_coaching.student.description_2")],
      junior: [
        t("promo.career_coaching.junior.description_1"),
        t("promo.career_coaching.junior.description_2"),
        t("promo.career_coaching.junior.description_3")
      ],
      expert: [
        t("promo.career_coaching.expert.description_1"),
        t("promo.career_coaching.expert.description_2"),
        t("promo.career_coaching.expert.description_3"),
        t("promo.career_coaching.expert.description_4")
      ]
    }
  };

  const prompt = pickRandom(prompts.career_coaching[segmentCategory]);

  const { title, text, actionUrl, onClick, imageUrl, buttonTitle, buttonIcon, points, isPremium, imageStyles } =
    (() => {
      switch (type) {
        case "invite_friends":
          return {
            title: t("promo.invite_friends.title"),
            text: t("promo.invite_friends.description"),
            actionUrl: "/invite",
            imageUrl: invitePromoImage,
            buttonTitle: t("promo.invite_friends.button"),
            buttonIcon: <MailSend width={20} />,
            points: "20",
            imageStyles: { margin: "0 0 -20px", maxWidth: "320px" }
          };
        case "career_coaching":
          return {
            title: t("promo.career_coaching.title"),
            text: pickRandom(prompts.career_coaching[segmentCategory]),
            imageUrl: guidancePromoImage,
            actionUrl: "/premium/career-coaching",
            buttonTitle: t("promo.career_coaching.button"),
            isPremium: true,
            imageStyles: { maxWidth: "500px" },
            onClick: () => {
              track("promo_clicked", { prompt, match_path: path, type });
            }
          };
        /*        
      case "instant_feedback":
        return {
          title: "Tired of not getting feedback?",
          text:
            "With Fuzu's Instant Feedback you will understand better how you stack against other applicants and how you can improve your chances.",
          imageUrl: ifPromo,
          buttonTitle: "Learn more",
          imageStyles: { margin: "0 0 -20px", maxWidth: "260px" },
          isPremium: true,
          ...(position_id
            ? { onClick: openInstantFeedbackModal }
            : {
                actionUrl: "/premium/instant-feedback",
                onClick: () => {
                  track("promo_clicked", { prompt, match_path: path, type });
                }
              })
        };
        */
        case "fuzu_premium":
          return {
            title: t("promo.fuzu_premium.title"),
            text: t("promo.fuzu_premium.description"),
            imageUrl: exclusivePromoImage,
            buttonTitle: t("promo.fuzu_premium.button"),
            imageStyles: { margin: "0 0 -20px", maxWidth: "380px" }
          };
        case "career_goal":
          return {
            title: t("promo.career_goal.title"),
            text: t("promo.career_goal.text"),

            imageUrl: cup,
            buttonTitle: t("promo.career_goal.button"),
            isPremium: false,
            imageStyles: { margin: "0 36px 18px", maxWidth: "100px" }
          };
        case "custom":
          return {
            title: custom?.title,
            text: custom?.text,
            imageUrl: custom?.imageUrl,
            buttonTitle: custom?.buttonTitle,
            imageStyles: custom?.imageStyles
          };
        default:
          return {
            title: "Title",
            text: "Promo Text",
            imageUrl: null,
            buttonTitle: "Button Title"
          };
      }
    })();

  const screen = useContext(ScreenClassContext);
  const isSm = ["xs", "sm"].includes(screen);
  const isUniCafPromo = type === "unicaf_promo";

  return (
    <PromotionCountryVisible countryCodes={type === "career_coaching" && isActiveProduct ? ["KE", "UG"] : []}>
      <MarketPromoHidden>
        {isUniCafPromo ? (
          <Card.Content padding="20px">
            <Row justify="between">
              <PromoUnicafContainer />
            </Row>
          </Card.Content>
        ) : (
          <Card
            ref={targetRef}
            shadow={!isPremium}
            backgroundImage={isPremium ? circleBG_dark : null}
            backgroundSize="auto"
            style={{ margin }}
            wideOnMobile
            {...props}
          >
            <Card.Content padding="20px">
              <Row justify="between">
                <>
                  <Col md={8}>
                    {isPremium && (
                      <Visible sm xs>
                        <Label.PremiumAlternative title="Premium" margin="0 auto 15px" style={{ display: "flex" }} />
                      </Visible>
                    )}
                    <Title
                      level={isSm ? "3" : "2"}
                      margin="0 0 5px 0"
                      fontWeight={500}
                      color={isPremium ? "white" : "black500"}
                      width="100%"
                      textAlign={isPremium && isSm ? "center" : "left"}
                    >
                      {title}
                    </Title>
                    <Text
                      fontSize={16}
                      margin="0 0 20px"
                      color={isPremium ? "white" : "black300"}
                      width="100%"
                      textAlign={isPremium && isSm ? "center" : "left"}
                    >
                      {text}
                    </Text>
                    <div style={{ display: "flex" }}>
                      <UnderDevelopment disabled={!!actionUrl || !!onClick}>
                        <Button.Link
                          icon={buttonIcon && buttonIcon}
                          theme={isPremium ? "additional200" : "primary"}
                          height="40px"
                          width={isSm ? "100%" : "auto"}
                          to={actionUrl || "#"}
                          onClick={onClick}
                        >
                          {buttonTitle}
                        </Button.Link>
                      </UnderDevelopment>
                      {isPremium && (
                        <Hidden sm xs>
                          <Label.PremiumAlternative title="Premium" margin="0 0 0 20px" />
                        </Hidden>
                      )}

                      {points && (
                        <Label
                          rect
                          Icon={<FuzuCoin width={16} />}
                          noBackground
                          fontWeight="400"
                          fill="primary"
                          color="black500"
                          fontSize={14}
                          height="40px"
                          width={isSm ? "100%" : "auto"}
                          margin={isSm ? "0" : "0 0 0 15px"}
                        >
                          <b style={{ marginRight: "3px" }}>{points}</b> {t("promo.per_friend")}
                        </Label>
                      )}
                    </div>
                  </Col>
                  <Hidden sm xs md={isPremium}>
                    <Col style={{ display: "flex", alignItems: "center" }}>
                      {imageUrl &&
                        (isPremium ? (
                          <Image src={imageUrl} borderRadius="8px" />
                        ) : (
                          <PromoImage src={imageUrl} alt={title} $styled={imageStyles} />
                        ))}
                    </Col>
                  </Hidden>
                </>
              </Row>
            </Card.Content>
          </Card>
        )}
      </MarketPromoHidden>
    </PromotionCountryVisible>
  );
};

const PromoUnicafContainer = () => {
  const { track } = useStore("Tracking");
  const bannerRef = useRef(null);
  const [hasBeenVisible, setHasBeenVisible] = useState(false);

  const handleClickUnicaf = () => {
    track("click_unicaf_banner");
  };

  const handleVisibility = () => {
    const banner = bannerRef.current;

    if (banner && !hasBeenVisible) {
      const { top, bottom } = banner.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      if (top < windowHeight && bottom >= 0) {
        setHasBeenVisible(true);
        track("banner_displayed");
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleVisibility);
    window.addEventListener("resize", handleVisibility);

    handleVisibility();

    return () => {
      window.removeEventListener("scroll", handleVisibility);
      window.removeEventListener("resize", handleVisibility);
    };
  }, [hasBeenVisible]);

  return (
    <PromoUniCaf ref={bannerRef}>
      <PromoUniCafBackground
        href="https://apply.unicaf.org/unicaf/all-partners/all-programmes/kenya?cm=1&utm_source=ground-marketing&utm_medium=website&utm_campaign=kenfuz0124&dg_tag=738205f4"
        target="_blank"
        rel="noreferrer"
        onClick={handleClickUnicaf}
      />
    </PromoUniCaf>
  );
};

PromoSection.PremiumCv = PremiumCv;

PromoSection.propTypes = {
  custom: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
    imageUrl: PropTypes.string,
    buttonTitle: PropTypes.string,
    imageStyles: PropTypes.object
  })
};

export default PromoSection;
