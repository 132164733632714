import React, { useEffect } from "react";
import { observer } from "mobx-react";

import SvgContent from "b2c/components/SvgContent";
import { capitalize } from "utils/helpers";
import MainInformationIco from "images/b2c/profilePage/MainInformationIco.svg";
import { Text, Input, Textarea } from "b2c/components/Core";
import SubPageSection from "b2c/components/SubPageSection";
import { useTranslation } from "b2c/hooks/useTranslation";
import useStore from "../../../../contexts/store";

const BasicInformation = props => {
  const {
    Profile: {
      state: { loading, processing },
      profile: { basic_info: { headline, career_intro } = {} } = {},
      validation_errors = {},
      getBasicInfo,
      updateBasicInfo
    }
  } = useStore("User");
  const { t } = useTranslation();

  useEffect(() => {
    if (!headline || !career_intro) {
      getBasicInfo();
    }
  }, []);

  useEffect(() => {
    if (window.scrollY) {
      window.scroll(0, 0);
    }
  }, []);

  const formatError = error => (error ? capitalize(error.join(", ")) : null);

  return (
    <SubPageSection
      title={t("profile.career_intro.title")}
      sectionIcon={
        <SvgContent>
          <MainInformationIco />
        </SvgContent>
      }
      submitAction={updateBasicInfo}
      validation={validation_errors}
      initialFields={{ headline, career_intro }}
      requiredFields={["headline", "career_intro"]}
      loading={loading}
      processing={processing}
      {...props}
    >
      {handleChange => (
        <>
          <Input
            name="headline"
            label={
              <>
                <Text fontSize="inherit" fontWeight="500">
                  {t("profile.career_intro.headline_label")}*
                </Text>
                <Text fontSize="12px">{t("profile.career_intro.headline_description")}</Text>
              </>
            }
            defaultValue={headline}
            placeholder={t("profile.career_intro.headline_placeholder")}
            onChange={handleChange}
            maxLength={200}
            errorMessage={formatError(validation_errors.headline)}
          />
          <Textarea
            name="career_intro"
            inputHeight="268px"
            label={
              <>
                <Text fontSize="inherit" fontWeight="500">
                  {t("profile.career_intro.intro_label")}*
                </Text>
                <Text fontSize="12px">{t("profile.career_intro.intro_description")}</Text>
              </>
            }
            defaultValue={career_intro}
            placeholder={t("profile.career_intro.intro_placeholder")}
            onChange={handleChange}
            errorMessage={formatError(validation_errors.career_intro)}
          />
        </>
      )}
    </SubPageSection>
  );
};

export default observer(BasicInformation);
