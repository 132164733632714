import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";

import manualWork from "@images/b2c/onboarding/jobInterest/accounting.png";
import office from "@images/b2c/onboarding/jobInterest/office.png";
import agriculture from "@images/b2c/onboarding/jobInterest/agriculture.png";
import business from "@images/b2c/onboarding/jobInterest/business.png";
import social from "@images/b2c/onboarding/jobInterest/social.png";
import customerService from "@images/b2c/onboarding/jobInterest/customerService.png";
import drivingAndLogistic from "@images/b2c/onboarding/jobInterest/driver.png";
import engineering from "@images/b2c/onboarding/jobInterest/engineering.png";
import handyman from "@images/b2c/onboarding/jobInterest/handyman.png";
import health from "@images/b2c/onboarding/jobInterest/health.png";
import hotels from "@images/b2c/onboarding/jobInterest/hotels.png";
import human from "@images/b2c/onboarding/jobInterest/human.png";
import software from "@images/b2c/onboarding/jobInterest/software.png";
import legal from "@images/b2c/onboarding/jobInterest/legal.png";
import media from "@images/b2c/onboarding/jobInterest/media.png";
import marketing from "@images/b2c/onboarding/jobInterest/marketing.png";
import security from "@images/b2c/onboarding/jobInterest/security.png";
import teaching from "@images/b2c/onboarding/jobInterest/teaching.png";
import designInterest from "@images/b2c/onboarding/jobInterest/designInterest.png";
import { Card, Checkbox, Image, Label } from "../../../Core";

const imageMapper = {
  "Accounting, Banking and Insurance": manualWork,
  Administration: office,
  Agriculture: agriculture,
  "Business and Management": business,
  "Community and social services, non-profits": social,
  "Customer Service": customerService,
  "Driving and Logistics": drivingAndLogistic,
  "Engineering and Manufacturing": engineering,
  "Handyman and Construction": handyman,
  "Health and Wellbeing": health,
  "Hotels, Travel, Food and Restaurants": hotels,
  "Human Resources": human,
  "ICT and Software": software,
  Legal: legal,
  "Media and Languages": media,
  "Sales and Marketing": marketing,
  Security: security,
  "Design, Arts and Entertainment": designInterest,
  "Teaching, Research and Academy": teaching
};

const JobInterestDetail = ({ list }) => {
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    if (list) {
      const onboardingForm = JSON.parse(localStorage.getItem("onboardingForm"));
      const interestDetails = onboardingForm?.jobInterestDetails;
      if (interestDetails) {
        setSelected(interestDetails.map(id => +id));
      }
    }
  }, [list]);

  const handleSelect = event => {
    const { value } = event.target;
    const findJob = selected.find(item => item === +value);

    if (selected.length < 3 || findJob) {
      setSelected(prev => {
        if (prev.includes(+value)) {
          return prev.filter(item => item !== +value);
        }
        return prev.concat(+value);
      });
    }
  };

  return (
    <Card theme="grey100">
      <Card.Content>
        {list.map(({ id, title }) => (
          <Checkbox
            key={id}
            id={id}
            name="job"
            value={id}
            borderedView
            margin="6px 0"
            onChange={handleSelect}
            checked={selected.includes(id)}
            markColor="white"
            checkedColor="secondary200"
            checkedBgColor="secondary100"
            style={{
              outline: "none"
            }}
            labelProps={{
              style: {
                flexDirection: "row-reverse",
                justifyContent: "space-between",
                borderColor: selected.includes(id) ? "#4690FF" : "transparent",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.08)",
                padding: "8px"
              }
            }}
            label={
              <Label
                theme="transparent"
                Icon={
                  <Image
                    src={imageMapper[title] || manualWork}
                    style={{
                      maxWidth: "34px",
                      maxHeight: "34px",
                      marginRight: "14px"
                    }}
                  />
                }
                fontSize="14px"
                lineHeight="21px"
                fontWeight={600}
                margin="0"
                padding="0 15px 0 0"
              >
                {title}
              </Label>
            }
          />
        ))}
      </Card.Content>
    </Card>
  );
};

export default observer(JobInterestDetail);
