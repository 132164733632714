import React from "react";
import { Row, Col } from "react-grid-system";

import { onEnterPress } from "utils/helpers";
import { Title, Text, Spinner, RadioButton } from "b2c/components/Core";

const CareerStep = ({ career_interests = [], selected, onSelect }) => {
  return (
    <form onKeyDown={e => onEnterPress(e, 1, () => ({}))} noValidate>
      <Title id="label" level="3" fontWeight={500} margin="0 0 12px">
        The last question, we promise! What`s your motivation for growth?
      </Title>
      <Text lineHeight="140%" margin="0 0 40px" color="black300">
        Please choose one option that defines best your primary interest for professional growth.
      </Text>

      <Row role="radiogroup" aria-labelledby="label">
        {career_interests.length <= 1 ? (
          <Col>
            <Spinner size="50px" />
          </Col>
        ) : (
          career_interests.map(({ id, title, description }) => (
            <Col sm={6} xs={12} key={id}>
              <RadioButton
                name="carreer"
                value={id}
                id={id}
                checked={id === selected}
                Label={
                  <>
                    <Text fontSize={16} fontWeight={500}>
                      {title}
                    </Text>
                    <Text lineHeight="140%" margin="4px 0 0 0" color="black300">
                      {description}
                    </Text>
                  </>
                }
                onChange={() => onSelect(id)}
                boxedView
              />
            </Col>
          ))
        )}
      </Row>
    </form>
  );
};

export default CareerStep;
