import React, { useContext, useEffect, Fragment } from "react";
import { hot } from "react-hot-loader";
import { observer } from "mobx-react";
import "array-flat-polyfill";

import { Router, StaticRouter, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import patchBlockFn from "history-block-async";

import { NotificationProvider } from "contexts/notification";
import { ModalProvider } from "contexts/modal";
import Modal from "components/Modal";
import Layout from "../../components/Layout";

import LandingScreen from "../../pages/LandingScreen";
import LanguageSelector from "../../pages/LanguageSelector";
import FuzuApp from "../../pages/FuzuApp";
import EmployersLanding from "../../pages/EmployersLanding";
import CompaniesBaronaLanding from "../../pages/CompaniesBaronaLanding";
import HeroCard from "../../pages/HeroCard";
import RemoveMyData from "../../pages/RemoveMyData";
import UserStatusManager from "../../components/UserStatusManager";
import NotificationsCenter from "../../components/NotificationsCenter";
import NotificationsSettings from "../../components/NotificationsSettings";
import ServiceWorkerManager from "../../components/ServiceWorkerManager";
import JobSearchScreen from "../../pages/JobSearch";
import EntityPage from "../../pages/EntityPage";
import Pricing from "../../pages/Pricing";
import Forum from "../../pages/Forum";
import Learning from "../../pages/Learning";
import { BrandedModeProvider, BrandHidden, WhitelabelledOrJkuatHidden } from "../../contexts/branded";
import { GuestModeProvider } from "../../contexts/guest";
import { LandingProvider } from "../../contexts/landing";
import { CountryProvider } from "../../contexts/country";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

import { OnRouteChange } from "../../../utils/hooks";
import ServerSide from "../../contexts/ssr";
import InnerRoutes from "../InnerRoutes";
import StandaloneRoutes from "../../pages/StandaloneRoutes";
import SuggestGlobalPage from "../../components/SuggestGlobalPage";
import StandaloneModeProvider from "../../contexts/standalone";
import Legacy from "../../pages/Legacy";
import { useRedirectToLegacy, useSetCountryAndLocale } from "../../hooks";
import { useTranslation } from "../../hooks/useTranslation";

const history = patchBlockFn(createBrowserHistory(), "block");
const context = {};

const scroll = (scrolls = 0) => {
  if (scrolls && window && window.scrollTo) {
    window.scrollTo({ top: typeof scrolls !== "number" ? 0 : scrolls, behavior: "auto" });
  }
};
const refreshAppCues = () => {
  window.Appcues && window.Appcues.page();
};

const trackPageView = () => {
  if (window.ga && process.env.NODE_ENV !== "development") {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "Pageview",
      pagePath: window.location.pathname,
      pageTitle: document.title
    });
  }
};

const routeChangeCallback = ({ pathname, query }) => {
  if (!pathname.includes("/application")) {
    scroll(query?.scroll);
  }
  refreshAppCues();
  trackPageView();
};

const RootRouter = ({
  user,
  brand,
  countries,
  globalBrand,
  countriesAndLocales,
  isEnableNewCountryLocalesPath,
  isMultiLanguageBrand
}) => {
  const statusUpdateRoutes = ["/application", "/messages", "/learn", "/profile", "/forum"];
  const countriesPaths = isEnableNewCountryLocalesPath ? countriesAndLocales : countries;

  const jobPaths = [
    "/job",
    "/job/:slug",
    "/job/:slug/:level",
    "/job/:slug/:level/:another",
    "/job/:slug/cf",
    "/job/:slug/instant-feedback",
    "/job/:slug/application-booster"
  ];

  const globalPaths = [
    "/",
    "/employers",
    "/companies",
    "/fuzu-app",
    "/employers/:type",
    "/companies/:type",
    "/nigeria/career-hero",
    "/nigeria/career-hero/:token",
    "remove-my-data/:token"
  ];

  const globalPathsWithCountryAndLanguage =
    countriesPaths
      ?.map(({ slug }) => [
        "/",
        "/companies",
        "/companies/:type",
        `/${slug}/companies`,
        `/${slug}/companies/:type`,
        "/employers",
        "/employers/:type",
        "/fuzu-app",
        "/nigeria/career-hero",
        "/nigeria/career-hero/:token",
        "/remove-my-data/:token"
      ])
      .flat() || [];

  const employerPaths = countriesPaths?.map(({ slug }) => ["/employers", `/${slug}/employers`]).flat() || [];
  const companiesPaths = countriesPaths?.map(({ slug }) => ["/companies", `/${slug}/companies`]).flat() || [];

  const jobPathWithCountryAndLanguage =
    countriesPaths
      ?.map(({ slug }) => [
        "/job",
        `/${slug}/job`,
        `/${slug}/jobs`,
        "/job/:slug",
        `/${slug}/job/:slug`,
        "/job/:slug/:level",
        `/${slug}/job/:slug/:level`,
        "/job/:slug/:level/:another",
        `/${slug}/job/:slug/:level/:another`,
        "/job/:slug/cf",
        `/${slug}/job/:slug/:cf`,
        "/job/:slug/instant-feedback",
        `/${slug}/job/instant-feedback`,
        "/job/:slug/application-booster",
        `/${slug}/job/application-booster`
      ])
      .flat() || [];

  const rootPaths = globalPaths
    .concat(
      countriesPaths?.map(({ slug }) =>
        [
          "/",
          "/jobs",
          "/job",
          "/employers",
          "/employers/:type",
          "/company/:id",
          "/companies/:type",
          "/learn",
          "/jobs/:id",
          `/${slug}`,
          `/${slug}/employers/:type`,
          `/${slug}/companies`,
          `/${slug}/job`,
          `/${slug}/jobs`,
          `/${slug}/jobs/:id`,
          `/${slug}/company/:id`,
          `/${slug}/employers`,
          `/${slug}/learn`,
          `/${slug}/forum`,
          `/${slug}/forum/:id`,
          `/${slug}/forum/article/:id`
        ].concat(jobPaths.map(path => `/${slug}${path}`))
      ) || []
    )
    .flat();

  useRedirectToLegacy(history);
  useSetCountryAndLocale(history, isMultiLanguageBrand);

  const { changeLanguage } = useTranslation();

  useEffect(() => {
    if (user && user.ui_locale) {
      changeLanguage(user.ui_locale);
    }
  }, [user?.ui_locale]);

  const isBarona = globalBrand?.name?.includes("Barona");
  const CountryAndLocalesProvider = isEnableNewCountryLocalesPath ? Fragment : CountryProvider;

  return (
    <RouterVersion user={user} isMultiLanguageBrand={isMultiLanguageBrand}>
      <HotAppWrapper>
        <UserStatusManager routes={statusUpdateRoutes} />
        <ServiceWorkerManager />

        <BrandedModeProvider brand={brand} countries={countries}>
          <StandaloneModeProvider>
            <GuestModeProvider>
              <ModalProvider>
                <Modal />
                <NotificationsCenter />
                <NotificationsSettings />

                <OnRouteChange callback={routeChangeCallback}>
                  <NotificationProvider>
                    <LandingProvider>
                      <StandaloneRoutes />
                      <SuggestGlobalPage />
                      <Legacy exact path="/legacy" />
                      <Route exact path="/nigeria/career-hero" component={props => <HeroCard {...props} />} />
                      <Route exact path="/nigeria/career-hero/:token" component={props => <HeroCard {...props} />} />
                      <Route exact path="/remove-my-data/:token?" component={props => <RemoveMyData {...props} />} />

                      <Layout>
                        <WhitelabelledOrJkuatHidden>
                          <Route exact path={rootPaths}>
                            <Route exact path={globalPathsWithCountryAndLanguage}>
                              {!isMultiLanguageBrand && (
                                <Header isBarona={isBarona} isMultiLanguageBrand={isMultiLanguageBrand} />
                              )}
                              <BrandHidden brand="global">
                                <Route
                                  exact
                                  path="/"
                                  render={props => (
                                    <>
                                      {!isMultiLanguageBrand ? (
                                        <LandingScreen
                                          isMultiLanguageBrand={isMultiLanguageBrand}
                                          type="job"
                                          {...props}
                                        />
                                      ) : (
                                        <LanguageSelector
                                          countries={globalBrand.countries}
                                          defaultPage={globalBrand.default}
                                          globalBrand={globalBrand}
                                        />
                                      )}
                                    </>
                                  )}
                                />
                              </BrandHidden>
                              <Route exact path="/fuzu-app" component={FuzuApp} />
                              <Route exact path={employerPaths} component={EmployersLanding} />
                              <Route
                                exact
                                path={countriesPaths
                                  ?.map(({ slug }) => ["/employers/:type", `/${slug}/employers/:type`])
                                  .flat()}
                                component={Pricing}
                              />
                              <Route exact path={companiesPaths} component={CompaniesBaronaLanding} />
                              {!isMultiLanguageBrand && (
                                <Footer isBarona={isBarona} isMultiLanguageBrand={isMultiLanguageBrand} />
                              )}
                            </Route>
                            {countriesPaths.map(country => (
                              <Route key={country.slug} path={`/${country.slug}`}>
                                <CountryAndLocalesProvider country={country}>
                                  <Header isBarona={isBarona} isMultiLanguageBrand={isMultiLanguageBrand} />
                                  <Switch>
                                    <Route
                                      path={countriesPaths
                                        ?.map(({ slug }) => ["/job", "/jobs/:id", `/${slug}/jobs`, `/${slug}/jobs/:id`])
                                        .flat()}
                                      render={props => <EntityPage type="job" {...props} />}
                                    />
                                    <Route
                                      path={countriesPaths
                                        .map(({ slug }) => ["/company/:id", `/${slug}/company/:id`])
                                        .flat()}
                                      render={props => <EntityPage type="company" {...props} />}
                                    />
                                    <Forum path={`/${country.slug}/forum`} />
                                    <Learning path={`/${country.slug}/learn`} />
                                    <JobSearchScreen exact path={jobPathWithCountryAndLanguage} />
                                    <Route
                                      exact
                                      path={`/${country.slug}`}
                                      render={props => (
                                        <LandingScreen isMultiLanguageBrand={isMultiLanguageBrand} {...props} />
                                      )}
                                    />
                                    <Route exact path={`/${country.slug}/employers`} component={EmployersLanding} />
                                    <Route exact path={`/${country.slug}/employers/:type`} component={Pricing} />
                                  </Switch>

                                  {!isMultiLanguageBrand && (
                                    <Footer isBarona={isBarona} isMultiLanguageBrand={isMultiLanguageBrand} />
                                  )}
                                </CountryAndLocalesProvider>
                              </Route>
                            ))}
                          </Route>
                        </WhitelabelledOrJkuatHidden>
                        <InnerRoutes
                          countryPaths={countriesPaths}
                          isBarona={isBarona}
                          jobPaths={jobPaths}
                          countries={countries}
                          isEnableNewCountryLocalesPath={isEnableNewCountryLocalesPath}
                          isMultiLanguageBrand={isMultiLanguageBrand}
                        />
                      </Layout>
                    </LandingProvider>
                  </NotificationProvider>
                </OnRouteChange>
              </ModalProvider>
            </GuestModeProvider>
          </StandaloneModeProvider>
        </BrandedModeProvider>
      </HotAppWrapper>
    </RouterVersion>
  );
};

const RouterVersion = ({ children, user, isMultiLanguageBrand, countries, ...rest }) => {
  const { ssr, url } = useContext(ServerSide);

  const [country, lang] = url.split("/").slice(1, 3);

  if (country?.length !== 2 && lang?.length !== 2 && url !== "/" && !user && isMultiLanguageBrand) {
    history.push("/");
  }

  return ssr ? (
    <StaticRouter location={url} context={context} {...rest}>
      {children}
    </StaticRouter>
  ) : (
    <Router history={history} {...rest}>
      {children}
    </Router>
  );
};

const AppWrapper = ({ children }) => children;

const HotAppWrapper = process.env.RAILS_ENV === "test" ? AppWrapper : hot(module)(AppWrapper);

export default observer(RootRouter);
