import React, { useContext } from "react";
import { Row, Col, ScreenClassContext, Hidden } from "react-grid-system";

import premiumCvExample from "images/b2c/premiumCvExample.png";
import ListMarkerYellow from "images/b2c/icons/listMarkerYellow.svg";
import Product from "../Product";
import { Text, Card, Image, Label, Button } from "../Core";
import { useTranslation } from "../../hooks/useTranslation";

const PremiumCV = ({ targetRef, close }) => {
  const screen = useContext(ScreenClassContext);
  const isSm = ["xs", "sm"].includes(screen);
  const { t } = useTranslation();

  return (
    <>
      <Card bordered>
        <Card.Content>
          <Row align="center">
            <Col>
              <Text fontSize={16} fontWeight={600} margin="0 0 10px">
                {t("premium_cv.products.title")}
              </Text>
              <div className="list-vertical">
                <Label
                  Icon={
                    <ListMarkerYellow
                      style={{
                        marginRight: "15px"
                      }}
                    />
                  }
                  lineHeight="26px"
                  noBackground
                  color="black500"
                  fontSize={14}
                  fontWeight={400}
                  padding="0"
                  margin="0 0 15px"
                >
                  {t("premium_cv.products.description_1")}
                </Label>
                <Label
                  Icon={
                    <ListMarkerYellow
                      style={{
                        marginRight: "15px"
                      }}
                    />
                  }
                  lineHeight="26px"
                  noBackground
                  color="black500"
                  fontSize={14}
                  fontWeight={400}
                  padding="0"
                  margin="0 0 15px"
                >
                  {t("premium_cv.products.description_2")}
                </Label>
                <Label
                  Icon={
                    <ListMarkerYellow
                      style={{
                        marginRight: "15px"
                      }}
                    />
                  }
                  lineHeight="26px"
                  noBackground
                  color="black500"
                  fontSize={14}
                  fontWeight={400}
                  padding="0"
                  margin="0 0 15px"
                >
                  {t("premium_cv.products.description_3")}
                </Label>
                <Label
                  Icon={
                    <ListMarkerYellow
                      style={{
                        marginRight: "15px"
                      }}
                    />
                  }
                  lineHeight="26px"
                  noBackground
                  color="black500"
                  fontSize={14}
                  fontWeight={400}
                  padding="0"
                  margin="0 0 15px"
                >
                  {t("premium_cv.products.description_4")}
                </Label>
                <Label
                  Icon={
                    <ListMarkerYellow
                      style={{
                        marginRight: "15px"
                      }}
                    />
                  }
                  lineHeight="26px"
                  noBackground
                  color="black500"
                  fontSize={14}
                  fontWeight={400}
                  padding="0"
                  margin="0 0 15px"
                >
                  {t("premium_cv.products.description_5")}
                </Label>
              </div>
              <a
                href="https://s3.amazonaws.com/fuzu.com-carrierwave/cvs/000-demo-premium.pdf"
                target="_blank"
                rel="noreferrer"
              >
                <Button theme="grey200" width={isSm ? "100%" : "270px"}>
                  {t("premium_cv.click_to_preview")}
                </Button>
              </a>
            </Col>
            <Col sm="content">
              <Hidden xs sm>
                <Image src={premiumCvExample} width="236px" margin="0 auto" display="block" />
              </Hidden>
            </Col>
          </Row>
        </Card.Content>
      </Card>
      <Product
        ref={targetRef}
        slug="permanent-cv-printing"
        style={{ margin: "25px auto 0", width: 340 }}
        onClick={close}
      />
    </>
  );
};

export default PremiumCV;
