import styled from "styled-components";

export const ProcessingMask = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${({ maskColor }) => maskColor};
  display: flex;
  z-index: 10;
`;

export const PreviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;

  .image-wrapper {
    position: relative;
    margin: auto;
    width: fit-content;
  }
`;
