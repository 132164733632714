import React from "react";

import { Text } from "b2c/components/Core";

const ExpiredNotification = () => (
  <Text color="white" margin="11px auto">
    Unfortunately, the job you tried to access has expired. Here is a list of similar jobs.
  </Text>
);

export default ExpiredNotification;
