import styled from "styled-components";

export const StepWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;

  > form {
    margin: 0 auto;
    width: 400px;

    @media screen and (max-width: 767px) {
      width: 100%;
      padding-bottom: 50px;
    }
  }
`;

export const Footer = styled.div`
  height: 60px;
  border-top: 2px solid #b9b9b9;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  z-index: 1;
`;

export const BackToLogin = styled.a`
  height: 42px;
  background-color: #f3f3f3;
  color: #444444;
  margin: 24px 0 0 0;
  display: block;
  padding: 9px;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  border-radius: 5px;
`;
