import React from "react";

import { useTranslation } from "b2c/hooks/useTranslation";
import Layout from "../../../../components/Layout";
import Section from "../../../../components/Section";
import CategoryItem from "../CategoryItem";
import { Title } from "../../../../components/Core";
import { useDocumentTitle } from "../../../../../utils/hooks";
import { getCompanyName } from "../../../../contexts/branded";

const Categories = ({ categories = [...new Array(8).fill({ loading: true })], selectCategory }) => {
  useDocumentTitle(getCompanyName());
  const { t } = useTranslation();

  return (
    <Layout.Content size="xl">
      <Section
        expanded
        columns={4}
        Header={
          <Title level={3} fontWeight={600} margin="24px 0 0">
            {t("learn.search_title")}
          </Title>
        }
      >
        {categories.map((item, i) => (
          <CategoryItem key={`category-${i + 1}`} onClick={() => selectCategory(item.id)} {...item} />
        ))}
      </Section>
    </Layout.Content>
  );
};

export default Categories;
