import React, { useContext } from "react";
import { Row, Col, ScreenClassContext } from "react-grid-system";

import { useTranslation } from "b2c/hooks/useTranslation";
import Layout from "b2c/components/Layout";
import { Title, Text, Carousel } from "b2c/components/Core";
import LandingContext from "../../../../contexts/landing";
import { InfoSliderWrapper, InfoImageWrapper, InfoSlideContent } from "./styled";

const InfoSliderSection = () => {
  const screen = useContext(ScreenClassContext);
  const isSm = ["xs", "sm"].includes(screen);
  const { howItWorks } = useContext(LandingContext);
  const { t } = useTranslation();

  return (
    <InfoSliderWrapper>
      <Layout.Content tag="div">
        {howItWorks?.title && (
          <Title
            loaded
            level="2"
            fontWeight={600}
            margin="0 auto 25px"
            textAlign="center"
            style={{ fontSize: isSm ? 20 : 36 }}
          >
            {t(howItWorks?.title)}
          </Title>
        )}
        <Carousel>
          {howItWorks?.slides?.map(({ id, title, text, videoUrl }) => (
            <Carousel.Slide key={id} margin="20px 0">
              <Row align="center">
                <Col sm={6}>
                  <InfoImageWrapper>
                    <video width="100%" autoPlay="autoplay" loop muted playsInline>
                      <source src={videoUrl} type="video/mp4" />
                    </video>
                  </InfoImageWrapper>
                </Col>
                <Col sm={6}>
                  <InfoSlideContent>
                    <Title level={2} margin="20px 0 30px">
                      {t(title)}
                    </Title>
                    <Text fontSize="20px" margin="0 0 20px">
                      {t(text)}
                    </Text>
                  </InfoSlideContent>
                </Col>
              </Row>
            </Carousel.Slide>
          ))}
        </Carousel>
      </Layout.Content>
    </InfoSliderWrapper>
  );
};

export default InfoSliderSection;
