import React, { useContext } from "react";
import { observer } from "mobx-react";
import { ScreenClassContext } from "react-grid-system";

import useStore from "../../contexts/store";
import { withAuthorizationRedirect } from "../../components/WithAuth";
import Layout from "../../components/Layout";
import { withTrackedRoute } from "../../hocs";
import InvitePromo from "./components/InvitePromo";
import InviteDetails from "./components/InviteDetails";
import InviteUsers from "./components/InviteUsers";
import ActivitiesTable from "./components/ActivitiesTable";

const InviteFriendScreen = () => {
  const {
    state: { loading, processing },
    user: { fuzu_points, refer_code },
    invites,
    getFriendInvites,
    sendInvite
  } = useStore("User");
  const screen = useContext(ScreenClassContext);
  const isSm = ["xs", "sm"].includes(screen);

  return (
    <>
      <InvitePromo />

      <Layout.Content
        id="main"
        size="xl"
        style={{
          padding: isSm ? "0" : "0 20px"
        }}
      >
        <InviteUsers
          loading={loading}
          processing={processing}
          count={invites.filter(invite => invite.invitation_accepted).length}
          refer_code={refer_code}
          sendInvite={sendInvite}
        />

        <InviteDetails loading={loading} fuzu_points={fuzu_points} />

        <ActivitiesTable
          loading={loading}
          invites={invites.filter(invite => invite.invitation_accepted)}
          getFriendInvites={getFriendInvites}
        />
      </Layout.Content>
    </>
  );
};

export default withTrackedRoute(withAuthorizationRedirect(observer(InviteFriendScreen)));
