import styled from "styled-components";

export const Modal = styled.div`
  max-width: 640px;
  margin: -76px -20px -32px -20px;
  padding: 32px;
`;

export const Title = styled.h2`
  color: #333;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 24px;
`;

export const Description = styled.p`
  color: #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-bottom: 8px;
`;

export const List = styled.ul`
  margin-bottom: 24px;
  padding-left: 20px;

  li {
    color: #666;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const Warning = styled.span`
  color: #eb3737;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  display: block;
  margin-bottom: 24px;
`;

export const Label = styled.span`
  color: #333;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  display: block;
  margin-bottom: 8px;
`;

export const Input = styled.input`
  background: #fff;
  padding: 10px 12px;
  border-radius: 5px;
  border: 1px solid #cdcdcd;
  margin-bottom: 24px;
  display: block;
  width: 100%;
`;

export const CheckboxLabel = styled.span`
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 24px;
`;

export const Cancel = styled.button`
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 5px;
  background: #eee;
  color: #333;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  cursor: pointer;
  width: 130px;
  justify-content: center;
`;

export const Delete = styled.button`
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 5px;
  background: #eb3737;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  cursor: pointer;
  width: 190px;
  justify-content: center;

  &:disabled {
    pointer-events: none;
    opacity: 0.3;
  }
`;
