import styled from "styled-components";
import { theme } from "../../../../contexts/theme";

export const HeadingComponent = styled.div`
  background-color: #f3f3f3;
`;

export const Content = styled.div`
  padding: 20px;
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;

  @media screen and (min-width: 1200px) {
    padding: 56px 20px;
  }

  @media screen and (min-width: 1440px) {
    padding: 56px 50px;
  }
`;

export const Title = styled.h1`
  font-weight: 600;
  font-size: 24px;
  line-height: 130%;
  color: #292929;
  text-align: center;
  max-width: 580px;
  margin: 0 auto 20px auto;

  span {
    color: #4690ff;
  }

  @media screen and (min-width: 1200px) {
    font-size: 48px;
    margin: 0 0 16px 0;
    text-align: left;
  }
`;

export const Text = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #656565;
  text-align: center;
  max-width: 490px;
  margin: 0 auto 20px auto;

  @media screen and (min-width: 1200px) {
    font-size: 16px;
    text-align: left;
    margin: 0 0 32px 0;
  }
`;

export const Activity = styled.div`
  @media screen and (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 32px;
  }
`;

export const GetAppButton = styled.button`
  background: ${theme.primary};
  border-radius: 5px;
  padding: 13.5px;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #292929;
  margin-bottom: 12px;
  display: block;
  width: 100%;
  cursor: pointer;

  @media screen and (min-width: 768px) {
    margin-bottom: 0;
  }
`;

export const LearnMore = styled.button`
  background: #292929;
  border-radius: 5px;
  padding: 13.5px;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  margin-bottom: 32px;
  display: block;
  width: 100%;
  cursor: pointer;

  @media screen and (min-width: 768px) {
    margin-bottom: 0;
  }
`;

export const QuickStats = styled.div`
  max-width: 490px;
  margin: 0 auto;

  @media screen and (min-width: 768px) {
    display: flex;
    justify-content: space-between;
  }

  @media screen and (min-width: 1200px) {
    margin: 0;
  }
`;

export const QuickStatsItem = styled.div`
  text-align: center;
  width: 132px;
  margin: 0 auto 16px auto;

  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (min-width: 768px) {
    margin: 0;
    text-align: left;
  }
`;

export const QuickStatsTitle = styled.h3`
  font-weight: 600;
  font-size: 24px;
  line-height: 130%;
  color: #292929;
  margin-bottom: 4px;
`;

export const QuickStatsText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #656565;
`;

export const FlexTablet = styled.div`
  max-width: 412px;
  margin: 0 auto;

  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: column-reverse;
  }

  @media screen and (min-width: 1200px) {
    margin: 0;
  }
`;

export const LeftSide = styled.div`
  @media screen and (min-width: 1200px) {
  }
`;

export const RightSide = styled.div`
  @media screen and (min-width: 1200px) {
    display: flex;
    height: 100%;
    align-items: center;
  }
`;
