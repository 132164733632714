import React, { useContext } from "react";

import { useLocation } from "react-router-dom";

import { Button } from "../Core";

import ServerSide from "../../contexts/ssr";
import useStore from "../../contexts/store";

const OnboardingButton = ({ flow = "generic", from, onClick, ...rest }) => {
  const { ssr } = useContext(ServerSide);
  const { setInitialFlow } = useStore("Onboarding");
  const { pathname } = useLocation();

  const handleFlow = () => {
    setInitialFlow(flow);
    if (onClick) onClick();
  };

  return (
    <Button.Link
      {...rest}
      to={{
        pathname: ssr ? `/onboarding?flow=${flow}&from=${from || pathname}` : "/onboarding",
        state: { from: from || pathname }
      }}
      onClick={handleFlow}
    />
  );
};

export default OnboardingButton;
