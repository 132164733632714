import React, { Component } from "react";
import ServerSide from "../../contexts/ssr";

const withLoading = (MainComponent, PlaceholderComponent) => {
  class Loader extends Component {
    constructor(props) {
      super(props);
    }

    render() {
      return this.props.loading && !this.context.ssr ? (
        <PlaceholderComponent {...this.props} />
      ) : (
        <MainComponent loading={this.props.loading} {...this.props} />
      );
    }
  }

  Loader.contextType = ServerSide;

  return Loader;
};

export default withLoading;
