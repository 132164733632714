import styled from "styled-components";

export const FastApplyComponent = styled.div`
  margin: ${({ margin }) => margin};
  @media screen and (min-width: 768px) {
    display: flex;
    align-items: center;
    align-self: baseline;
    justify-content: flex-end;
  }
`;

export const Banner = styled.div`
  background-color: ${({ backgroundColor }) => backgroundColor || "#ffe140"};
  color: ${({ color }) => color || "#000000"};
  border-radius: 5px;
  padding: 2px 6px 2px 2px;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  width: 96px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-between;
  letter-spacing: 1px;
  flex: none;

  @media screen and (min-width: 768px) {
    margin-right: 8px;
  }
`;
