import React from "react";
import { Row, Col, Hidden } from "react-grid-system";

import { PaymentMethodWrapper } from "./styled";
import { Card, Title, Button, Text } from "../../../../components/Core";
import LinkSupportChannel from "../../../../components/LinkSupportChannel";

const BankInstructions = ({ processing, provider, from, cancelTransaction }) => {
  const { head, fields } = (() => {
    let lines = provider.instructions?.split(";") || [];
    const result = {};

    result.head = lines[0];
    result.fields = [];
    lines.shift();
    lines.pop();
    lines = lines.flatMap(item => item.split(":"));
    for (let i = 0; i < lines.length; i += 2) {
      result.fields.push({ name: lines[i], value: lines[i + 1] });
    }

    return result;
  })();

  return (
    <PaymentMethodWrapper>
      <Title level={5} fontWeight={500} margin="0 0 16px">
        {}
        Bank transfer – {provider.name}
      </Title>

      <Card bordered>
        <Card.Content>
          <Text margin="0 0 16px">{head}</Text>

          {fields.map(({ name, value }, i) => (
            <Row key={`fn-${i}`} style={{ marginBottom: 8 }}>
              <Col>
                <Text fontWeight={600}>{name}</Text>
              </Col>
              <Col>
                <Text>{value}</Text>
              </Col>
            </Row>
          ))}

          <Text margin="16px 0">
            The product will be activated after we have received your payment. This might take a few days. You will get
            an email once the product has been activated. If you need help, please contact{" "}
            <LinkSupportChannel theme="transparent" as="a" target="_blank" color="secondary300" isTargetBlank>
              chat with Fuzu Support
            </LinkSupportChannel>
          </Text>

          <Row>
            <Col lg="content" sm={6}>
              <Button processing={processing} onClick={cancelTransaction} theme="grey100" width="100%">
                Cancel
              </Button>
            </Col>
            <Col lg="content" sm={6}>
              <Button.Link to={from} width="100%">
                Back{" "}
                <Hidden xs sm>
                  to previous page
                </Hidden>
              </Button.Link>
            </Col>
          </Row>
        </Card.Content>
      </Card>
    </PaymentMethodWrapper>
  );
};

export default BankInstructions;
