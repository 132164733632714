import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Redirect } from "react-router-dom";

import { useScrollHandler } from "utils/hooks";
import Chatline from "b2c/components/Chatline";
import PhoneNumberModal from "b2c/components/ModalContents/PhoneNumber";
import { ProductNotAvailable } from "b2c/components/ModalContents/ProductNotAvailable";
import CareerCoachingHero from "../CareerCoachingHero";
import CareerCoachingContent from "../CareerCoachingContent";
import CareerCoachingCarousel from "../CareerCoachingCarousel";
import CareerCoachingTestimonials from "../CareerCoachingTestimonials";
import CareerCoachingPricing from "../CareerCoachingPricing";
import CareerCoachingFaq from "../CareerCoachingFaq";
import { useModal } from "../../../../../utils/hooks";
import useStore from "../../../../contexts/store";
import { isBaronaBrand } from "../../../../contexts/branded";

const CareerCoaching = () => {
  const isBarona = isBaronaBrand();

  if (isBarona) {
    return <Redirect to="/" />;
  }

  const { scrollHandler, targetRef } = useScrollHandler();
  const { user } = useStore("User");
  const { features } = useStore("Premium");

  const isFreeProduct = features?.coaching?.activated;
  const isNigeria = user?.product_country?.country_code === "NG";
  const isNotActiveProduct =
    isNigeria || (features?.coaching?.available === false && features?.coaching?.activated === false);

  const notAvailableText = user?.product_country?.name
    ? `Unfortunately, Career Coaching is not (yet) available in ${user?.product_country?.name}.`
    : "";

  const handleShowProductNotAvailable = useModal(
    <ProductNotAvailable notAvailableText={notAvailableText} />,
    "",
    () => ({}),
    false
  );

  const openModalPhoneNumber = useModal(
    <PhoneNumberModal
      isShowProductFree={isFreeProduct}
      isNotActiveProduct={isNotActiveProduct}
      notAvailableText={notAvailableText}
    />,
    "",
    () => {},
    false
  );

  useEffect(() => {
    if (!user?.product_country?.phone_code) {
      openModalPhoneNumber();
    }
  }, [user]);

  useEffect(() => {
    if (isNotActiveProduct && user?.product_country?.phone_code) {
      handleShowProductNotAvailable();
    }
  }, [user, isNotActiveProduct]);

  return (
    <>
      <CareerCoachingHero scrollHandler={scrollHandler} />
      <CareerCoachingContent scrollHandler={scrollHandler} />
      <CareerCoachingCarousel />
      <CareerCoachingTestimonials />
      <CareerCoachingPricing targetRef={targetRef} />
      <CareerCoachingFaq />
      <Chatline />
    </>
  );
};

export default observer(CareerCoaching);
