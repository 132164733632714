import React, { useState, useEffect, useMemo, useRef } from "react";
import { Visible } from "react-grid-system";
import { Link } from "react-router-dom";
import { Square } from "@styled-icons/boxicons-solid";

import { useTranslation } from "b2c/hooks/useTranslation";
import Diamond from "images/b2c/premium_alternative.svg";
import Layout from "b2c/components/Layout";
import { Button, RadioButton, Checkbox, Text, Spinner, Icon, Label } from "b2c/components/Core";
import { CloseIcon, OptionsWrapper } from "./styled";

const BuilderOptions = ({ templates, options, applied, processing, applyOptions, close }) => {
  const optionsRef = useRef();
  const [optionsState, setOptionsState] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    optionsRef.current?.scrollTo(0, 0);
  }, [optionsRef.current]);

  useEffect(() => {
    if (options.length === 0) setOptionsState({});
  }, [options]);

  useEffect(() => {
    setOptionsState(applied);
  }, [applied]);

  const changesPending = useMemo(() => {
    return JSON.stringify(optionsState) != JSON.stringify(applied);
  }, [optionsState]);

  const handleOptionChange = e => {
    const { name, value } = e.target;
    setOptionsState(prev => {
      let section = [...prev.options[name]] || [];
      if (section.includes(value)) section = section.filter(el => el != value);
      else section.push(value);
      return { ...prev, options: { ...prev.options, [name]: section } };
    });
  };

  const handleRadioChange = e => {
    const { name, value } = e.target;
    setOptionsState(prev => {
      return { ...prev, options: { ...prev.options, [name]: value } };
    });
  };

  const hanldeTemplateChange = e => {
    setOptionsState(prev => {
      return { ...prev, template_id: +e.target.value };
    });
  };

  const handleApply = () => {
    applyOptions(optionsState);
    if (close) close();
  };

  const plainSelected = optionsState.template_id == 4;

  const optionSelected = (option, slug) =>
    [option, option.toString()].some(el => optionsState?.options?.[slug]?.includes(el)) && !plainSelected;

  const renderOptionType = ({ name, type, slug, values }) => {
    switch (type) {
      default:
      case "checkbox":
        return (
          <Layout.Section tag="fieldset" key={slug} padding="16px 0">
            <Text fontSize={18} as="legend" level={3} fontWeight={600} margin="0 0 16px">
              {name}
            </Text>
            {values.map(item => (
              <Checkbox
                key={item.value}
                id={item.value}
                label={item.name}
                name={slug}
                value={item.value}
                checkedColor="secondary200"
                checked={optionSelected(item.value, slug)}
                onChange={handleOptionChange}
              />
            ))}
          </Layout.Section>
        );
      case "radio":
        return (
          <Layout.Section tag="fieldset" key={slug} padding="16px 0">
            <Text fontSize={18} as="legend" level={3} fontWeight={600} margin="0 0 16px">
              {name}
            </Text>
            {values.map(item => (
              <RadioButton
                key={item.value}
                id={item.value}
                Label={
                  slug === "color" ? (
                    <Label
                      theme="white"
                      Icon={<Icon as={Square} width={18} customFill={item.value} />}
                      padding={0}
                      margin={0}
                      fontSize={16}
                      fontWeight={400}
                      fill="additional200"
                    >
                      {item.name}
                    </Label>
                  ) : (
                    item.name
                  )
                }
                name={slug}
                value={item.value}
                checkedColor="secondary200"
                checked={optionsState?.options?.[slug] == item.value.toString() && !plainSelected}
                onChange={handleRadioChange}
              />
            ))}
          </Layout.Section>
        );
    }
  };

  return (
    <OptionsWrapper ref={optionsRef}>
      <Visible sm xs>
        <div className="options-header">
          <Layout.Content tag="div" size="sm">
            <Text fontSize={18} level={5} fontWeight={16} margin="revert">
              {t("cv_creator.flow.customize_cv")}
            </Text>
            <Icon as={CloseIcon} height={32} margin="auto" onClick={close} />
          </Layout.Content>
        </div>
      </Visible>
      {!optionsState.options ? (
        <Spinner margin="auto" size="72px" />
      ) : (
        <Layout.Content tag="div" size="sm">
          <Layout.Section tag="fieldset" padding="16px 0">
            <Text fontSize={18} as="legend" level={3} fontWeight={600} margin="0 0 16px">
              {t("cv_creator.flow.select_design")}
            </Text>
            {templates.map(({ id, name, product }) => (
              <RadioButton
                key={`template-${id}`}
                id={`template-${id}`}
                Label={
                  <Label
                    theme="white"
                    Icon={product ? <Diamond width={14} /> : null}
                    padding={0}
                    margin={0}
                    fontSize={16}
                    fontWeight={400}
                    fill="additional200"
                  >
                    {name}
                  </Label>
                }
                name={name}
                value={id}
                checkedColor="secondary200"
                checked={optionsState?.template_id == id}
                onChange={hanldeTemplateChange}
              />
            ))}
          </Layout.Section>
          {plainSelected && <Text> {t("cv_creator.flow.plan_design_selected")}</Text>}
          <div className="main-options">
            {plainSelected && <div className="options-mask" />}
            {options.map(renderOptionType)}
            <Text margin="16px 0 32px" fontWeight={500}>
              {t("cv_creator.flow.helper")}
              <Link
                className="link underlined"
                to={{
                  pathname: "/profile",
                  state: {
                    noiseless: true,
                    from: { pathname: "/cv-creator", state: { step: 3, update: true } }
                  }
                }}
              >
                {t("cv_creator.flow.edit_fuzu_profile")}
              </Link>
            </Text>
          </div>
        </Layout.Content>
      )}
      <Button
        className="apply-button"
        processing={processing}
        disabled={!changesPending || options.length === 0}
        color="white"
        height="56px"
        theme="secondary200"
        onClick={handleApply}
      >
        {t("cv_creator.flow.apply_button")}
      </Button>
    </OptionsWrapper>
  );
};

export default BuilderOptions;
