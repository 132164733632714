import React, { useLayoutEffect, useEffect } from "react";
import { observer } from "mobx-react";

import { useTranslation } from "b2c/hooks/useTranslation";
import teamPhoto from "images/b2c/service/teamPhoto.png";
import blockquoteImg from "images/b2c/service/visionImage.png";
import BlockquoteSection from "b2c/components/BlockquoteSection";
import BrandsSection from "b2c/components/BrandsSection";
import { withTrackedRoute } from "../../hocs";
import useStore from "../../contexts/store";
import Layout from "../../components/Layout";
import { Image } from "../../components/Core";
import { useDocumentTitle } from "../../../utils/hooks";
import TimelineSection from "./components/TimelineSection";
import PromoSection from "./components/PromoSection";
import TeamSliderSection from "./components/TeamSliderSection";
import Recognitions from "./components/Recognitions";
import AboutBarona from "./components/AboutBarona";
import { isBaronaBrand } from "../../contexts/branded";

const AboutUsScreen = () => {
  const isBarona = isBaronaBrand();

  if (isBarona) {
    return <AboutBarona />;
  }
  const { t } = useTranslation();

  const { getTrendingCompanies, trendingCompanies } = useStore("Landing");

  useLayoutEffect(() => {
    if (getTrendingCompanies) {
      getTrendingCompanies();
    }
  }, []);

  useEffect(() => {
    if (window.scrollY) {
      window.scroll(0, 0);
    }
  }, []);

  useDocumentTitle(t("about_fuzu.meta_title"));

  return (
    <>
      <PromoSection />

      <BlockquoteSection
        text={t("about_fuzu.vision.text")}
        author={t("about_fuzu.vision.author")}
        role={t("about_fuzu.vision.role")}
        img={blockquoteImg}
      />
      <TimelineSection
        data={[
          {
            id: 1,
            date: 2015,
            text: t("about_fuzu.list.year_2015")
          },
          {
            id: 2,
            date: 2016,
            text: t("about_fuzu.list.year_2016")
          },
          {
            id: 3,
            date: 2017,
            text: t("about_fuzu.list.year_2017")
          },
          {
            id: 4,
            date: 2018,
            text: t("about_fuzu.list.year_2018")
          },
          {
            id: 5,
            date: 2019,
            text: t("about_fuzu.list.year_2019")
          },
          {
            id: 6,
            date: 2020,
            text: t("about_fuzu.list.year_2020")
          },
          {
            id: 7,
            date: 2021,
            text: t("about_fuzu.list.year_2021")
          },
          {
            id: 8,
            date: 2022,
            text: t("about_fuzu.list.year_2022")
          },
          {
            id: 9,
            date: 2023,
            text: t("about_fuzu.list.year_2023")
          }
        ]}
      />

      <Layout.Section backgroundColor="white">
        <Layout.Content size="xl" tag="div" style={{ padding: 0 }}>
          <Image borderRadius="0" margin="0 0 40px" src={teamPhoto} />
        </Layout.Content>
      </Layout.Section>

      <TeamSliderSection />
      <Recognitions />
      <BrandsSection
        title={t("about_fuzu.customers")}
        companies={trendingCompanies}
        /*
        actionButton={
          <Button tag="a" href="https://press.fuzu.com/" padding="0 40px" to="/press">
            View Fuzu Press Releases
          </Button>
        }
        */
      />
    </>
  );
};

export default withTrackedRoute(observer(AboutUsScreen));
