import React from "react";

import BaronaLogoSvg from "images/barona-logo.svg";
import { BaronaLogoContainer } from "./styled";
import { useIsBarona } from "../../hooks";

const BaronaLogo = ({ children, isSmallWidth, color }) => {
  const isBarona = useIsBarona();

  /* Fuzu has a variety of logos, while Barona has only two types. */
  return (
    <>
      {isBarona ? (
        <BaronaLogoContainer color={color} isSmallWidth={isSmallWidth}>
          <BaronaLogoSvg />
        </BaronaLogoContainer>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default BaronaLogo;
