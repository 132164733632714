import React, { useRef, useEffect, useState, useContext } from "react";
import { ScreenClassContext } from "react-grid-system";
import styled from "styled-components";
import { Play } from "@styled-icons/boxicons-regular";

import { Icon } from "../Core";

const EmbedVideo = ({ alt, videoID, ...rest }) => {
  const screen = useContext(ScreenClassContext);
  const isSm = /xs|sm/.test(screen);
  const wrapper = useRef();
  const imageRef = useRef();
  const iframeRef = useRef();
  const [show, setVisibility] = useState(false);

  const width = isSm ? 328 : 480;
  const height = isSm ? 160 : 220;

  useEffect(() => {
    if (!imageRef.current) {
      const image = new Image(width, height);
      imageRef.current = image;

      const onImageLoad = () => {
        if (!show && wrapper.current) {
          wrapper.current.appendChild(image);
          wrapper.current.onclick = () => {
            image.remove();
            setVisibility(true);
            iframeRef?.current?.contentWindow?.postMessage(
              "{ 'event': 'command', 'func': 'playVideo', 'args': '' }",
              "*"
            );
          };
        }
      };

      if (wrapper.current) {
        image.src = `https://img.youtube.com/vi/${videoID}/sddefault.jpg`;
        image.alt = alt;
        image.loading = "lazy";
        onImageLoad();
      }
    }
  }, [wrapper.current]);

  return (
    <YtWrapper ref={wrapper} show={show} wrapperHeight={height}>
      <>
        {show && (
          <iframe
            title="video"
            ref={iframeRef}
            type="text/html"
            src={`https://www.youtube.com/embed/${videoID}?enablejsapi=1controls=1&autoplay=1`}
            width={width}
            height={height}
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
            {...rest}
          />
        )}
        <Icon
          width={32}
          height={32}
          as={Play}
          fill="black500"
          backgroundColor="primary"
          additionalCss="border-radius: 100%;"
        />
      </>
    </YtWrapper>
  );
};

const YtWrapper = styled.div`
  position: relative;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 5px 5px 10px 3px rgba(33, 33, 33, 0.15);
  display: flex;
  width: 100%;
  height: ${({ wrapperHeight }) => wrapperHeight}px;
  border-radius: 5px;

  svg {
    display: ${({ show }) => (show ? "none" : "block")};
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translate(-50%, -50%);
  }

  img {
    background-color: #000;
    object-fit: cover;
  }

  iframe {
    display: ${({ show }) => (show ? "block" : "none")};
  }

  img,
  iframe {
    width: 100%;
    left: 0;
    border: none;
  }
`;

export default EmbedVideo;
