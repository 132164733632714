import React, { useState } from "react";

import { onEnterPress } from "utils/helpers";
import { Input, Button, Text } from "b2c/components/Core";
import { ArrowForward } from "@styled-icons/evaicons-solid";

const Ncpwd = ({ state: { loading, processing, message }, form, submitForm }) => {
  const [formState, setFormState] = useState(form);

  const [validationState, setValidation] = useState({
    number: { error: false },
    national_id: { error: false }
  });

  const handleSubmission = e => {
    e.preventDefault();

    const nonEmpty = !!formState.number;

    if (!nonEmpty) {
      return setValidation({ ...validationState, number: { error: "NCPWD Disability ID is required" } });
    }

    return submitForm(formState);
  };

  return (
    <form onSubmit={handleSubmission} onKeyDown={e => onEnterPress(e, 1, () => handleSubmission(e))} noValidate>
      <Input
        data-cy="ncpwd-id"
        label="Enter your NCPWD Disability ID "
        placeholder="1234567"
        prependedText="NCPWD/P/"
        type="text"
        value={formState.number}
        onChange={({ target: { value } }) => setFormState(prev => ({ ...prev, number: value }))}
        errorMessage={validationState.number.error}
      />

      <Input
        data-cy="national-id"
        label="Enter your National ID"
        placeholder="National ID"
        type="text"
        value={formState.national_id}
        onChange={({ target: { value } }) => setFormState(prev => ({ ...prev, national_id: value }))}
        errorMessage={validationState.national_id.error}
      />

      {message && (
        <Text color="accent300" margin="4px 0 0">
          {message}
        </Text>
      )}

      <Button
        type="submit"
        disabled={!formState.number || !formState.national_id || loading}
        processing={processing}
        fontSize="14px"
        width="100%"
        height="48px"
        margin="20px 0"
      >
        Continue <ArrowForward width="16px" height="16px" />
      </Button>
    </form>
  );
};

export default Ncpwd;
