import styled from "styled-components";
import { X } from "@styled-icons/boxicons-regular";

export const CloseIcon = styled(X)`
  position: absolute;
  width: 24px;
  height: 24px;
  top: 24px;
  right: 24px;
  cursor: pointer;
  z-index: 11;
  @media screen and (max-width: 767px) {
    top: 12px;
    right: 12px;
  }

  ${({ alternative }) =>
    alternative
      ? `
        background: #fff;
        border-radius: 50%;
    `
      : ""}
`;

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  padding: 0 0 96px;

  .apply-button {
    position: absolute;
    bottom: 32px;
    width: 312px;
    margin: 0 24px;
    z-index: 10;
  }

  .main-options {
    position: relative;

    > .options-mask {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: white;
      opacity: 0.7;
      z-index: 2;
    }
  }

  @media screen and (max-width: 767px) {
    padding: 72px 0 96px;
    position: absolute;
    background: white;
    z-index: 100;

    .apply-button {
      position: fixed;
      width: 80vw;
      margin: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    .options-header {
      width: 100%;
      background: white;
      height: 72px;
      margin: -72px 0 0;
      position: fixed;
      display: flex;
      z-index: 101;

      svg {
        top: 24px;
        right: 24px;
      }
    }
  }
`;
