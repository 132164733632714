import { ScreenClassContext } from "react-grid-system";
import React, { useContext } from "react";
import { observer } from "mobx-react";
import { Redirect, Route } from "react-router-dom";

import { useTranslation } from "b2c/hooks/useTranslation";
import feedbackPromoImage from "images/b2c/feedbackPromoImage.png";
import premiumCVPromo from "images/b2c/premiumCVPromo.png";
import Diamond from "images/b2c/icons/diamond.svg";
import PremiumCV from "b2c/components/PremiumCV";
import useStore from "../../../../contexts/store";
import { useScrollHandler } from "../../../../../utils/hooks";
import { Button, Card, Image, Label, List, Text, Title } from "../../../../components/Core";
import Layout from "../../../../components/Layout";
import { FeatureHidden } from "../../../../hocs";
import InstantFeedback from "../InstantFeedback";
import NotFound from "../../../NotFound";
import { isBaronaBrand } from "../../../../contexts/branded";

const PremiumProductCore = ({
  match: {
    params: { slug }
  }
}) => {
  const isBarona = isBaronaBrand();

  if (isBarona) {
    return <Redirect to="/" />;
  }

  const { applications, getApplicationList } = useStore("Premium");
  const { productDetails } = useStore("Payment");
  const { scrollHandler, targetRef } = useScrollHandler();
  const screen = useContext(ScreenClassContext);
  const isSm = ["xs", "sm"].includes(screen);
  const { t } = useTranslation();

  const getProductPrice = slugId => {
    const product = productDetails[slugId];
    return `${product?.currency || "..."} ${product?.discounted_price || "..."}`;
  };

  const { title, promoImage, description, price, processing } = (() => {
    switch (slug) {
      case "application-booster":
      case "instant-feedback":
        return {
          title: t("instant_feedback.title"),
          promoImage: feedbackPromoImage,
          description: [t("instant_feedback.description_1"), t("instant_feedback.description_2")],
          price: getProductPrice("position-specific-application-booster"),
          processing: !productDetails["position-specific-application-booster"]
        };
      case "premium-cv":
        return {
          title: t("premium_cv.title"),
          promoImage: premiumCVPromo,
          description: [t("premium_cv.description_1"), t("premium_cv.description_2")],
          price: getProductPrice("permanent-cv-printing"),
          processing: !productDetails["permanent-cv-printing"]
        };
      default:
        return {};
    }
  })();

  return title ? (
    <>
      {promoImage && (
        <Image src={promoImage} height={isSm ? 150 : 300} objectFit="cover" width="100%" margin="0" borderRadius="0" />
      )}
      <Layout.Content>
        <Label
          theme="primary300"
          color="black500"
          fill="black500"
          fontWeight={500}
          margin={isSm ? "20px 0 0" : "50px 0 0"}
          Icon={<Diamond width={16} />}
        >
          {t("instant_feedback.premium")}
        </Label>
        <Title fontWeight={600} margin="10px 0 15px" style={{ fontSize: isSm ? 20 : 42 }}>
          {title}
        </Title>
        {Array.isArray(description) ? (
          <List>
            {description.map((text, i) => (
              <List.Item key={i} bulletColor="black500">
                {text}
              </List.Item>
            ))}
          </List>
        ) : (
          <Text fontSize={16}>{description}</Text>
        )}
        <Button margin="20px 0" height="48px" width="320px" onClick={scrollHandler} processing={processing}>
          {t("instant_feedback.buy_from")} {price}
        </Button>

        <Route exact path={["/premium/instant-feedback", "/premium/application-booster"]}>
          <FeatureHidden feature="instant_feedback_global" fallback={<Redirect to="/home" />}>
            <InstantFeedback
              targetRef={targetRef}
              applications={applications}
              getApplicationList={getApplicationList}
            />
          </FeatureHidden>
        </Route>
        <Route exact path="/premium/premium-cv">
          <FeatureHidden feature="premium_cv_creator" fallback={<Redirect to="/home" />}>
            <Card style={{ marginBottom: 25 }} shadow wideOnMobile>
              <Card.Content padding="16px">
                <PremiumCV targetRef={targetRef} />
              </Card.Content>
            </Card>
          </FeatureHidden>
        </Route>
      </Layout.Content>
    </>
  ) : (
    <NotFound />
  );
};

export default observer(PremiumProductCore);
