import styled from "styled-components";

export const AvatarUploaderWrapper = styled.div`
  background: #ffffff;
  padding: 15px;
  ${({ bordered, borderColor }) =>
    bordered ? `border: 1px solid ${borderColor};` : "box-shadow: 0px 5px 15px 3px rgba(33, 33, 33, 0.05);"}

  border-radius: 5px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
`;

export const AvatarUploaderLabel = styled.label`
  display: flex;
  box-sizing: border-box;
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 100%;
  cursor: pointer;
`;

export const AvatarImageWrapper = styled.div`
  ${({ $hasImage }) => `
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f6f7f9;
    border: ${$hasImage ? "0px dashed transparent" : "1px dashed #dedede "};
    border-radius: 5px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    ${
      !$hasImage &&
      `
      &:after {
        content: attr(data-label);
        position: absolute;
        text-align: center;
      }
    `
    }
  
    ${
      $hasImage &&
      `
      content: "";

        &:hover {
          img {
            filter: contrast(50%) brightness(60%);
          }

          &:after {
            content: "Click here to select file";
            text-shadow: 0px 0px 6px #000;
            position: absolute;
            text-align: center;
            color: #fff;
          }
        }
      `
    }
    `}
`;

export const AvatarUploaderControl = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 0;
  height: 0;
`;
