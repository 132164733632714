import styled from "styled-components";

export const Modal = styled.div`
  max-width: 540px;
  min-height: 340px;
`;

export const DropdownContent = styled.div`
  width: 156px;
  flex: none;
`;

export const PhoneNumberContent = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: flex-end;
`;
