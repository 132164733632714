import React from "react";

import BaronaLogo from "images/barona-logo.svg";
import { useTranslation } from "b2c/hooks/useTranslation";
import { Head, HeadContent, HeadTitle, LogoContainer } from "./styled";

const HeadingBarona = ({ backgroundImage }) => {
  const { t } = useTranslation();

  return (
    <Head backgroundImage={backgroundImage}>
      <HeadContent>
        <LogoContainer>
          <BaronaLogo />
        </LogoContainer>
        <HeadTitle>{t("about.title")}</HeadTitle>
      </HeadContent>
    </Head>
  );
};

export default HeadingBarona;
