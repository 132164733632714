import styled from "styled-components";
import { Spinner } from "../Core";

const InlineMenu = styled.div`
  ${({ theme }) => `
    border: 1px solid ${theme.grey200};
    background-color: ${theme.white};
    border-radius: 5px;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    width: 100%;
`}
`;
const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;

const Item = styled.div`
  ${({ theme }) => `
    padding: 10px 12px;
    cursor: pointer;
    color: ${theme.black300};
    min-width: 250px;
    display: flex;
    white-space: pre;

    span {
        background-color: ${theme.primary100};
    }

    &.selected {
        font-weight: 500;
        color: ${theme.black500};
    }

    &:hover {
        background-color: ${theme.primary100};
    }

    @media screen and (max-width: 767px) {
        padding: 12px 16px;
        
        :not(:last-child) {
            border-bottom: 1px solid ${theme.grey300};
        }
    }
`}
`;

const ProcessingState = styled(Spinner)`
  ${({ bottom = 12 }) => `bottom: ${bottom}px;`}
  position: absolute;
  right: 0;
  transform: translate(-50%);
  z-index: 10;
`;

export default { Wrapper, InlineMenu, Item, ProcessingState };
