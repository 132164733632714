import styled from "styled-components";
import { Text } from "b2c/components/Core";

export const SocialBottomControlsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 32px 0;
`;

export const StyledSectionTitle = styled(Text)`
  @media screen and (max-width: 767px) {
    display: flex;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 5px;
  }
`;
