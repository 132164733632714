import styled from "styled-components";

export const Container = styled.div`
  background: #f3f3f3;
  padding: 32px 16px;

  @media screen and (min-width: 1008px) {
    padding: 64px 16px;
  }

  @media screen and (min-width: 1440px) {
    padding: 64px 0;
  }
`;

export const Content = styled.div`
  max-width: 880px;
  width: 100%;

  @media screen and (min-width: 768px) {
    margin: 0 auto;

    display: flex;
    flex-direction: row-reverse;
    gap: 40px;
  }
`;

export const ImageContent = styled.div`
  width: 144px;
  height: 144px;
  margin: 0 auto 16px;
  border-radius: 100%;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  @media screen and (min-width: 768px) {
    width: 296px;
    height: 296px;
    margin: 0;
    flex: none;
  }
`;

export const Text = styled.p`
  color: #212121;
  font-family: Poppins;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 150%;
  align-self: stretch;
  margin-bottom: 16px;

  @media screen and (min-width: 768px) {
    font-size: 20px;
    line-height: 160%;
  }
`;

export const Name = styled.span`
  color: #666;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;

  @media screen and (min-width: 768px) {
    font-size: 16px;
  }
`;
