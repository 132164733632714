import React, { useContext, useEffect, useState } from "react";
import { Edit, Plus, Trash } from "@styled-icons/boxicons-regular";
import { Col, Row } from "react-grid-system";
import { useTranslation } from "b2c/hooks/useTranslation";

import { useModal } from "../../../utils/hooks";
import DeleteReferral from "../ModalContents/DeleteReferral";
import {
  ReferralContent,
  ReferralButton,
  Label,
  Title,
  FormContent,
  GreyButton,
  AddMoreContent,
  ReferralsContent
} from "./styled";
import References from "../../pages/ProfileScreen/components/References";
import useStore from "../../contexts/store";
import { Button, Text } from "../Core";
import { ActionsViewHandler } from "../DescriptionSection";
import { normalizeReferences } from "../../utils/normalizeData";
import ThemeContext from "../../contexts/theme";

const Referral = () => {
  const [showForm, setShowForm] = useState(false);
  const [showList, setShowList] = useState(false);
  const [editItemIdd, setEditItemIdd] = useState("");
  const {
    Profile: {
      getGeneralInfo,
      removeReference,
      setReferenceDetails,
      profile: { references }
    }
  } = useStore("User");
  const Theme = useContext(ThemeContext);
  const { t } = useTranslation();

  const deleteReferral = useModal(<DeleteReferral />, "");

  const handleOpenDeleteReferral = handleDelete => {
    deleteReferral({ handleDelete });
  };

  useEffect(() => {
    getGeneralInfo();
  }, []);

  useEffect(() => {
    if (references?.length && !showList) {
      setShowList(true);
    }
  }, [references]);

  const normalizedReferenceData = normalizeReferences(references, removeReference);

  const handleAddMore = () => {
    removeEditItemId();
    handleShowMore();
  };

  const handleShowMore = () => {
    setShowList(false);
    setShowForm(true);
  };

  const handleBack = () => {
    setShowForm(false);
    removeEditItemId();

    if (references?.length) {
      setShowList(true);
    }
  };

  const handleAddReferrals = () => {
    setShowForm(true);
    removeEditItemId();
  };

  const handleEdit = id => {
    setReferenceDetails(id);
    setEditItemIdd(id);
    handleShowMore();
  };

  const removeEditItemId = () => {
    if (editItemIdd) {
      setEditItemIdd("");
      setReferenceDetails({});
    }
  };

  return (
    <ReferralContent>
      <Title>{t("application.attachments.referrals.title")}</Title>
      <Label>{t("application.attachments.referrals.description")}</Label>
      {showList ? (
        <>
          <StandardContent data={normalizedReferenceData} onEdit={handleEdit} handleRemove={handleOpenDeleteReferral} />
          <AddMoreContent>
            <GreyButton onClick={handleAddMore}>
              <Plus />
              {t("global.add_more_button")}
            </GreyButton>
          </AddMoreContent>
        </>
      ) : null}

      {showForm ? (
        <FormContent>
          <References itemId={editItemIdd} isApplicationAttachment handleBack={handleBack} />
        </FormContent>
      ) : null}

      {!showList && !showForm && (
        <ReferralButton onClick={handleAddReferrals} theme={Theme}>
          <Plus />
          {t("application.attachments.referrals.add_button")}
        </ReferralButton>
      )}
    </ReferralContent>
  );
};

const StandardContent = ({ data, onEdit, handleRemove }) => {
  const { t } = useTranslation();

  return data.map(({ id, title = "", info, text = "", onDelete }, index) => (
    <ReferralsContent key={`uid_${index}`}>
      <Row align="center" style={{ marginTop: index < 1 ? "0" : "15px" }}>
        <Col>
          <Text fontSize="16px" fontWeight="600" color="grey750" margin="0 0 8px 0">
            {title}
          </Text>
          {info && (
            <Text fontWeight="400" color="black0" fontSize="14px" margin="0 0 8px 0">
              {info}
            </Text>
          )}
          {text && (
            <Text color="grey650" margin="0" style={{ whiteSpace: "pre-wrap" }} fontSize="14px">
              {text}
            </Text>
          )}
        </Col>
        <Col xs="content">
          <ActionsViewHandler>
            <Button
              // to={{ ...actionUrl, state: { ...actionUrl.state, item_id: id } }}
              noBackground
              height="140%"
              color="black0"
              fontSize="14px"
              fontWeight="400"
              icon={<Edit width={18} />}
              onClick={() => onEdit(id)}
            >
              {t("global.edit")}
            </Button>
            <Button
              noBackground
              height="140%"
              color="black0"
              fontSize="14px"
              fontWeight="400"
              icon={<Trash width={18} />}
              onClick={() => handleRemove(onDelete)}
            >
              {t("global.delete")}
            </Button>
          </ActionsViewHandler>
        </Col>
      </Row>
    </ReferralsContent>
  ));
};

export default Referral;
