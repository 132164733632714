import React, { useContext } from "react";
import { Row, Col, ScreenClassContext } from "react-grid-system";

import talents from "images/b2c/landing-page/talents.png";
import Layout from "b2c/components/Layout";
import { Text, Button, Image } from "b2c/components/Core";
import LinkWithFullUrl from "components/LinkWithFullUrl";
import { useGetCurrentCountry } from "../../hooks";
import { pathWithCountry } from "../../utils/routes";

const LookingTalentSection = ({ isShowAdditional, backgroundColor = "grey100" }) => {
  const screen = useContext(ScreenClassContext);
  const isSm = ["xs", "sm"].includes(screen);
  const country = useGetCurrentCountry();

  return (
    <Layout.Section backgroundColor={backgroundColor} padding={isSm ? "32px 0" : "64px 0"}>
      <Layout.Content tag="div">
        <Row align={isSm ? "center" : "normal"}>
          <Col md={10}>
            <Text
              fontWeight={400}
              style={{ fontSize: isSm ? 30 : 36 }}
              margin="0 0 16px"
              width="100%"
              textAlign={isSm ? "center" : "left"}
            >
              {isShowAdditional ? "Need support with recruitment?" : "Looking for talent?"}
            </Text>
            {isShowAdditional && (
              <Text
                fontWeight={400}
                style={{ fontSize: "16px" }}
                margin="0 0 16px"
                width="100%"
                textAlign={isSm ? "center" : "left"}
                color="#292929"
              >
                Fuzu has a talent pool of 2.5 Million+ ambitious career builders ready for the next opportunity. Save
                time and money on recruitment with Fuzu`s Artificial Intelligence powered match-making technology and
                candidate assessments. Or let our talent experts hold your hand throughout the recruitment process. We
                have supported over 1,000 organizations in finding validated talent that match the culture and needs of
                the partner employer.
              </Text>
            )}

            <Row>
              <Col width={isSm ? "100%" : "fit-content"} style={{ display: "flex" }}>
                <Image width={292} height={45} src={talents} margin="auto auto 8px" alt="Looking for talent?" />
              </Col>
              <Col width={isSm ? "100%" : "fit-content"} style={{ display: "flex" }}>
                <Text fontSize={16} fontWeight={600} margin="auto">
                  2.5 Million+ job seekers ready to apply
                </Text>
              </Col>
            </Row>
          </Col>
          <Col md={2} style={{ display: "flex", marginTop: 16 }}>
            <Button.Link
              as={LinkWithFullUrl}
              arrow
              theme="black500"
              width="fit-content"
              to={pathWithCountry(country, "/employers")}
              margin="auto"
            >
              Hire talent
            </Button.Link>
          </Col>
        </Row>
      </Layout.Content>
    </Layout.Section>
  );
};

export default LookingTalentSection;
