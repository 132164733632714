import React, { useState } from "react";
import { observer } from "mobx-react";

import { useTranslation } from "b2c/hooks/useTranslation";
import { Text, Textarea, Button, Rate } from "../../../../components/Core";
import useStore from "../../../../contexts/store";

const CourseReview = ({ onReviewSubmitted }) => {
  const {
    CourseStore: {
      state: { processing },
      addReview
    }
  } = useStore("Learning");
  const [comment, setComment] = useState("");
  const [rating, setRating] = useState();
  const { t } = useTranslation();

  const handleReview = () => {
    addReview(rating, comment).then(onReviewSubmitted);
  };

  const handleRating = ratings => {
    setRating(ratings / 2);
  };

  return (
    <>
      <Text fontSize="18px" fontWeight="500" width="100%" textAlign="center">
        {t("learn.module.rate_course")}
      </Text>

      <Rate padding="0 48px" onRate={handleRating} />

      <Textarea
        label={t("learn.module.your_comment")}
        disabled={processing}
        onChange={e => setComment(e.target?.value)}
      />

      <Button
        width="172px"
        margin="0 auto"
        disabled={!rating || !comment}
        color={rating && comment && "primaryButtonLabelColor"}
        processing={processing}
        onClick={handleReview}
      >
        {t("learn.module.send_review")}
      </Button>
    </>
  );
};

export default observer(CourseReview);
