import styled from "styled-components";

export const StyledContent = styled.div`
  min-width: 320px;
  background-color: #fff;
  margin-top: -40px;

  @media screen and (min-width: 992px) {
    width: 640px;
  }
`;

export const Title = styled.h2`
  color: #333;
  font-size: 24px;
  font-weight: 600;
  display: block;
  margin-bottom: 24px;
`;

export const Description = styled.p`
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  display: block;
  margin: 0 0 24px 0;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
`;

export const Cancel = styled.button`
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 5px;
  background: #eee;
  color: #333;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  cursor: pointer;
  width: 110px;
  justify-content: center;
`;

export const Delete = styled.button`
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 5px;
  background: #eb3737;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  cursor: pointer;
  width: 170px;
  justify-content: center;
`;
