import React from "react";
import { observer } from "mobx-react";
import { Switch, Route, Redirect } from "react-router-dom";

import { withTrackedRoute } from "../../hocs";
import Layout from "../../components/Layout";
import Navigation from "../../components/TabNavigation";
import NotFound from "../NotFound";
import FuzuPointsInfo from "./components/FuzuPointsInfo";
import TransactionsTable from "./components/TransactionsTable";
import { withAuthorizationRedirect } from "../../components/WithAuth";
import useStore from "../../contexts/store";
import { isBaronaBrand } from "../../contexts/branded";

const FuzuPointsScreen = ({ match: { url }, location: { pathname } }) => {
  const isBarona = isBaronaBrand();

  if (isBarona) {
    return <Redirect to="/" />;
  }

  const {
    state: { loading },
    user: { fuzu_points },
    transactions,
    getTransactions
  } = useStore("User");

  const pointsTabs = [
    { name: "Fuzu Points" },
    {
      name: "Received Points",
      path: "debit"
    },
    {
      name: "Purchases",
      path: "credit"
    }
  ];

  return (
    <>
      <Navigation innerLayoutSize="xl">
        {pointsTabs.map(({ name, path }, i) => (
          <Navigation.Tab
            key={i}
            selected={pathname === `${url}${path ? `/${path}` : ""}`}
            to={`${url}${path ? `/${path}` : ""}`}
          >
            {name}
          </Navigation.Tab>
        ))}
      </Navigation>

      <Layout.Content size="xl" id="main" style={{ marginTop: 30, marginBottom: 30 }}>
        <Switch>
          <Route exact path={url}>
            <FuzuPointsInfo points={fuzu_points && fuzu_points.toString()} />
          </Route>
          {["debit", "credit"].map(type => (
            <Route key={type} exact path={`${url}/${type}`}>
              <TransactionsTable
                loading={loading}
                transactions={transactions[type]?.filter(({ status }) => status === "success")}
                type={type}
                getTransactions={sort_by => getTransactions(type, sort_by)}
              />
            </Route>
          ))}
          <NotFound />
        </Switch>
      </Layout.Content>
    </>
  );
};

export default withTrackedRoute(withAuthorizationRedirect(observer(FuzuPointsScreen)));
