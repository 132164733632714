import React from "react";

import QuestionCard from "../QuestionCard";

const SurveyPage = ({ processing, questions = [], responses = [], updateResponses }) => {
  return questions.map(({ description, answer_type, answer_options, id, ...rest }, index) => (
    <QuestionCard
      index={index}
      key={`${id}_${index}`}
      processing={processing}
      id={id}
      title={description}
      type={answer_type}
      answers={answer_options}
      selected={responses.some(res => res.id === id) && responses.find(res => res.id === id).response}
      setQuestionResponse={response => updateResponses({ id, response })}
      {...rest}
    />
  ));
};

export default SurveyPage;
