import React from "react";
import fuzuLogo from "images/new_fuzu_logo_black.png";
import { Image } from "b2c/components/Core";
import { MobileContent, Text } from "./styled";

const MobileHeading = () => {
  return (
    <MobileContent>
      <Image
        src={fuzuLogo}
        onClick={() => history.push("/")}
        width={100}
        padding="0"
        borderRadius="0"
        margin="0 auto 15px auto"
        display="block"
      />
      <Text>Welcome back!</Text>
    </MobileContent>
  );
};

export default MobileHeading;
