import React, { useEffect, useState } from "react";
import { Edit } from "@styled-icons/boxicons-regular";
import { MdClose } from "react-icons/md";

import { useTranslation } from "b2c/hooks/useTranslation";
import Layout from "../../../../components/Layout";
import SelectSearch from "../SelectSearch";
import Select from "../Select";
import {
  CountryContainer,
  Content,
  Title,
  Description,
  Heading,
  EditButton,
  SelectedList,
  SelectedItem,
  SelectedDefaultItem,
  EmptyState,
  EmptyStateIcon,
  Actions,
  Save,
  Cancel
} from "./styled";
import { Spinner } from "../../../../components/Core";

const SettingsSection = ({
  loading,
  name,
  description,
  items,
  updateSettings,
  isPreferredCountries,
  getGlobalLocations,
  placeholder,
  globalLocations,
  emptyStateText,
  EmptyIcon
}) => {
  const [selected, setSelected] = useState([]);
  const [showEmptyText, setShowEmptyText] = useState(false);
  const [disableSearch, setDisableSearch] = useState(false);
  const { t } = useTranslation();
  const [countrySearchValue, setCountrySearchValue] = useState("");
  const [activeCountry, setActiveCountry] = useState("");
  const [activeValue, setActiveValue] = useState("");
  const [isEditedMode, setIsEditedMode] = useState(false);

  useEffect(() => {
    if (!selected.length && items.length) {
      setSelected(items.filter(item => item.selected));
    }
  }, [items]);

  const handleSelect = value => {
    const found = items.find(item => String(item?.id) === String(value.id));

    if (found) {
      setSelected(prev => {
        return prev.some(item => String(item.id) === String(found?.id))
          ? prev.filter(item => String(item?.id) !== String(found?.id))
          : prev.concat({ ...found, isNew: true });
      });
    }

    setActiveValue(null);
  };

  const handleRemoval = id => {
    setSelected(prev => prev.filter(item => String(item.id) !== String(id)));
  };

  const handleCountrySearch = value => {
    setCountrySearchValue(value);

    if (!value && activeCountry) {
      setActiveCountry("");
    }
  };

  const handleChangeLocation = value => {
    const found = globalLocations.find(item => String(item?.name) === String(value?.name));

    if (found) {
      setSelected(prev => {
        return prev.some(item => String(item.id) === String(found?.id))
          ? prev.filter(item => String(item?.id) !== String(found?.id))
          : prev.concat({ ...found, isNew: true });
      });
    }

    setActiveCountry(value);
  };

  const handleSubmit = () => {
    const sendItems = isPreferredCountries
      ? selected.map(item => ({ id: item.id, type: item.type || "country" }))
      : selected.map(item => item?.id);
    updateSettings(sendItems).then(() => {
      setIsEditedMode(false);
      setCountrySearchValue("");
      setActiveCountry("");
    });
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!disableSearch && isPreferredCountries && countrySearchValue.length >= 2) {
        if (showEmptyText) {
          setShowEmptyText(false);
        }
        getGlobalLocations(countrySearchValue).then(() => {
          setShowEmptyText(true);
        });
      }
    }, 300);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [countrySearchValue, isPreferredCountries]);

  return (
    <Layout.Section margin="0 0 24px">
      <Content>
        <Heading>
          <Title level={4} fontWeight={700} style={{ textTransform: "capitalize" }}>
            {name.replace(/_/g, " ")}
          </Title>

          {!isEditedMode && (
            <EditButton onClick={() => setIsEditedMode(true)}>
              <Edit />
              {t("global.edit")}
            </EditButton>
          )}
        </Heading>

        <Description>{description}</Description>

        {!isEditedMode ? (
          <>
            {selected.length > 0 ? (
              <SelectedList>
                {selected.map(item => (
                  <SelectedDefaultItem key={`${name}_${item?.id}`}>{item.name || item.title}</SelectedDefaultItem>
                ))}
              </SelectedList>
            ) : (
              <EmptyState>
                <EmptyStateIcon>
                  <EmptyIcon />
                </EmptyStateIcon>
                <p>{emptyStateText}</p>
              </EmptyState>
            )}
          </>
        ) : (
          <>
            <CountryContainer>
              {isPreferredCountries ? (
                <>
                  <SelectSearch
                    value={activeCountry}
                    defaultValue={placeholder}
                    options={globalLocations}
                    onInputChange={(newValue, actions) => {
                      if (disableSearch) {
                        setDisableSearch(false);
                      }

                      if (actions?.action === "input-change") {
                        handleCountrySearch(newValue);
                      }

                      if (!actions) {
                        handleCountrySearch("");
                      }
                    }}
                    onSelect={value => {
                      handleChangeLocation(value);

                      if (value?.name) {
                        if (!disableSearch) {
                          setDisableSearch(true);
                        }
                        handleCountrySearch(value.name);
                      }
                    }}
                    inputValue={countrySearchValue}
                    noOptionsMessage={() => {
                      if (countrySearchValue.length) {
                        return loading || !showEmptyText ? (
                          <Spinner size="30px" margin="auto" secondaryFill="grey200" />
                        ) : (
                          "No result found"
                        );
                      }

                      return null;
                    }}
                    isSearchable
                  />

                  {/*
                  {loading ? (
                    <Loading>
                      <Spinner size="10%" margin="auto" secondaryFill="grey200" />
                    </Loading>
                  ) : null}
                  */}
                </>
              ) : (
                <Select
                  value={activeValue}
                  defaultValue={placeholder}
                  options={items.map(item => ({ name: item.name || item.title, id: item.id }))}
                  onSelect={handleSelect}
                  isSearchable
                />
              )}
            </CountryContainer>

            <SelectedList>
              {selected.map(item => (
                <SelectedItem key={`${name}_${item?.id}`} onClick={() => handleRemoval(item?.id)}>
                  {item.name || item.title} <MdClose />
                </SelectedItem>
              ))}
            </SelectedList>

            <Actions>
              <Cancel onClick={() => setIsEditedMode(false)}>{t("global.cancel")}</Cancel>
              <Save onClick={handleSubmit}> {t("global.save_button")}</Save>
            </Actions>
          </>
        )}
      </Content>
    </Layout.Section>
  );
};

export default SettingsSection;
