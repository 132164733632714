import { observer } from "mobx-react";

import React from "react";
import useStore from "../../contexts/store";
import { isWhitelabelled } from "../../contexts/branded";
import { ModalContentWrapper } from "./styled";
import { LoadingContent } from "../Core/Spinner";
import JobDetails from "../JobDetailsItem";
import JobViewWrapper from "../JobViewWrapper";

const JobModalContentCore = () => {
  const {
    state: { loading },
    job_details
  } = useStore("JobSearch");
  const { handleSaving } = useStore("Saved");
  const whitelabelled = isWhitelabelled();

  return (
    <ModalContentWrapper>
      <LoadingContent loading={loading} size="50px" margin="248px auto">
        <JobViewWrapper shadow={false} $guestMode={whitelabelled} overflow="visible">
          <section className="job-content" role="tabpanel" tabIndex="0">
            <JobDetails
              pathname="/job"
              selected={job_details}
              saveJob={(id, saved) => handleSaving(id, "job", saved)}
              Actions={null}
            />
          </section>
        </JobViewWrapper>
      </LoadingContent>
    </ModalContentWrapper>
  );
};

export default observer(JobModalContentCore);
