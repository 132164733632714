import React, { useCallback, useEffect, useContext } from "react";
import { observer } from "mobx-react";
import { Close } from "@styled-icons/material/Close";
import { Link } from "react-router-dom";

import fuzuLogo from "images/new_fuzu_logo_yellow.png";
import BaronaLogo from "../../../../components/BaronaLogo";
import BrandedContext, { isWhitelabelled } from "../../../../contexts/branded";
import useStore from "../../../../contexts/store";
import Layout from "../../../../components/Layout";
import { Text, Button, Image } from "../../../../components/Core";
import MainContent from "../Content";
import { TestAndSurveyHeader, TestAndSurveyHeaderAction, TestAndSurveySubHeader } from "./styled";

const TestAndSurvey = ({
  history,
  location: { state: locationState = {} },
  match: {
    params: { id, job_id, course_id },
    url
  }
}) => {
  const {
    Survey: {
      state: { loading, processing },
      form_details: { name, description, in_progress_question_response_id, completed, next_form_id } = {},
      currentPage,
      getForm,
      startForm,
      getFormPage,
      submitResponses,
      finished,
      setFinished,
      setNCPWDTestCompleted
    },
    Learning: {
      CourseStore: { course, module, getTestResult, testResults, getCourseDetails, getCourseModule, completeModule }
    },
    Application: { completeStep }
  } = useStore();
  const { employer } = useContext(BrandedContext);
  const isJobPage = url.includes("job");
  const whitelabelled = isWhitelabelled();

  const { slug } = useContext(BrandedContext);
  const isNCPWD = slug === "ncpwd";

  useEffect(() => {
    getForm(id, job_id);
  }, [id, job_id]);

  useEffect(() => {
    if (course_id && (!course || course_id != course?.id)) {
      getCourseDetails(course_id);
    }
  }, [course_id]);

  useEffect(() => {
    if (course?.progress?.current_module?.id) getCourseModule(course?.progress?.current_module?.id);
  }, [course?.progress]);

  const rootUrl = (() => {
    const rootPath = url.substring(0, url.lastIndexOf("/"));
    const nextFormUrl = next_form_id ? `${rootPath}/${next_form_id}` : null;
    const entityPath = url.substring(0, url.indexOf("/tests"));

    return {
      pathname: isNCPWD ? "/home" : nextFormUrl || entityPath || "/home",
      state: { ...locationState, test_completed: completed }
    };
  })();

  const handleRedirection = useCallback(() => {
    if (isNCPWD) {
      setNCPWDTestCompleted(true);
    }

    history.push(rootUrl);
  }, [rootUrl]);

  useEffect(() => {
    if (locationState.from === "/profile/personality" && finished) {
      history.push({
        pathname: locationState.from,
        state: {
          test_completed: finished
        }
      });
    }
  }, [locationState, finished]);

  return (
    <>
      <TestAndSurveyHeader isBranded={whitelabelled}>
        <Layout.Content size="xl" tag="div" style={{ position: "relative", textAlign: "center" }}>
          <BaronaLogo>
            <Image className="brand-logo" src={employer?.logo || fuzuLogo} alt="logo" borderRadius="0" />
          </BaronaLogo>

          {!locationState.mandatory && (
            <TestAndSurveyHeaderAction>
              <Link to={rootUrl}>
                <Button.Iconed tabIndex="-1" theme={whitelabelled ? "grey200" : "black300"}>
                  <Close width={24} />
                </Button.Iconed>
              </Link>
            </TestAndSurveyHeaderAction>
          )}
        </Layout.Content>
      </TestAndSurveyHeader>

      {currentPage.form_session_id && (
        <TestAndSurveySubHeader>
          <Text fontWeight={600}>{name}</Text>
        </TestAndSurveySubHeader>
      )}

      <MainContent
        loading={loading}
        processing={processing}
        handleRedirection={handleRedirection}
        id={id}
        getTestResult={getTestResult}
        testResults={testResults}
        job_id={job_id}
        in_progress_id={in_progress_question_response_id}
        name={name}
        description={description}
        currentPage={currentPage}
        startForm={startForm}
        getFormPage={getFormPage}
        submitResponses={submitResponses}
        testCompleted={finished}
        setFinished={setFinished}
        completeModule={() => {
          if (module?.id) completeModule();
        }}
        completePositionTests={() => completeStep("tests")}
        hasNextForm={!!next_form_id}
        isJobPage={isJobPage}
      />
    </>
  );
};

export default observer(TestAndSurvey);
