import React, { useState, useEffect, useContext } from "react";

import ModalConsumer from "contexts/modal";
import NoAvatarIcon from "images/b2c/user-empty.svg";
import { useTranslation } from "b2c/hooks/useTranslation";
import ThemeContext from "../../contexts/theme";
import { AvatarUploaderWrapper, AvatarUploaderControl, AvatarUploaderLabel, AvatarImageWrapper } from "./styled";

import ImageEditor from "../ImageEditor";

const AvatarUploader = ({
  id = "avatarUploader",
  imageUrl,
  avatarUploaded,
  updatePhoto,
  bordered,
  borderColor = "grey200"
}) => {
  const [image, setImage] = useState({ url: "", file: null });
  const { t } = useTranslation();

  const handleUpload = (e, toggle) => {
    e.preventDefault();
    e.persist();

    const file = e.target.files[0];
    const imageReader = new FileReader();

    if (file instanceof Blob) {
      imageReader.onloadend = () => {
        toggle(<ImageEditor source={imageReader.result} close={() => toggle()} setImage={setImage} />, "Edit photo");
      };

      imageReader.readAsDataURL(e.target.files[0]);
    } else {
      toggle("File format is not supported or corrupt", "Upload error");
    }
  };

  useEffect(() => {
    if (image.file) updatePhoto(image.file);
  }, [image.file]);

  const Theme = useContext(ThemeContext);

  return (
    <AvatarUploaderWrapper bordered={bordered} borderColor={Theme[borderColor]}>
      <ModalConsumer>
        {({ toggle }) => (
          <>
            <AvatarUploaderControl
              name="avatar"
              type="file"
              id={id}
              accept=".jpeg, .jpg, .png"
              onChange={e => handleUpload(e, toggle)}
            />
            <AvatarUploaderLabel htmlFor={id}>
              <AvatarImageWrapper
                data-label={t("wizard.personal_info.select_file")}
                $hasImage={avatarUploaded || image.url}
              >
                {imageUrl ? <img src={image.url || imageUrl} alt="User Avatar Image" /> : <NoAvatarIcon />}
              </AvatarImageWrapper>
            </AvatarUploaderLabel>
          </>
        )}
      </ModalConsumer>
    </AvatarUploaderWrapper>
  );
};

export default AvatarUploader;
