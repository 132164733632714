import React, { useState } from "react";
import { Redirect } from "react-router-dom";

import { validatePassword } from "utils/validation";
import { useQueryParams } from "utils/hooks";
import { Title, Text, Input, Button } from "b2c/components/Core";

const PasswordUpdate = ({ state: { processing, message }, validation_errors, userUpdatePassword }) => {
  const { reset_password_token } = useQueryParams();
  const [validationError, setError] = useState({
    password: "",
    confirmation: ""
  });

  const handleSubmission = e => {
    e.preventDefault();
    const password = e.target.password.value;
    const password_confirmation = e.target.confirmation.value;
    setError({
      password: "",
      confirmation: ""
    });

    const validPassword = validatePassword(e.target.password.value);
    const validConfirm = {
      test: password_confirmation && password === password_confirmation,
      error: password_confirmation && password === password_confirmation ? "" : "Passwords do not match"
    };

    return validPassword.test && validConfirm.test
      ? userUpdatePassword({
          reset_password_token,
          password,
          password_confirmation
        })
      : setError({
          password: validPassword.error,
          confirmation: validConfirm.error
        });
  };

  return reset_password_token ? (
    <>
      <Title level="3" fontWeight={500} margin="0 0 30px">
        Update your password
      </Title>
      <Text lineHeight="140%" margin="0 0 40px" color="black300">
        Type in your new password
      </Text>
      <form onSubmit={handleSubmission} noValidate>
        <Input
          label="Create your password"
          placeholder="Minimum 8 characters"
          type="password"
          name="password"
          autoComplete="new-password"
          errorMessage={validationError.password || validation_errors.password}
        />
        <Input
          label="Confirm password"
          placeholder="Re-enter your password"
          type="password"
          name="confirmation"
          autoComplete="new-password"
          errorMessage={validationError.confirmation || validation_errors.password_confirmation}
        />
        {(!!message || !!validation_errors.reset_password_token) && (
          <Text color="accent300" margin="4px 0 0" loaded>
            {message || validation_errors.reset_password_token}
          </Text>
        )}
        <Button
          type="submit"
          color={!processing && "primaryButtonLabelColor"}
          processing={processing}
          fontSize="14px"
          margin="15px 0"
          width="100%"
          height="48px"
        >
          Update password
        </Button>
      </form>
    </>
  ) : (
    <Redirect to="/login" />
  );
};

export default PasswordUpdate;
