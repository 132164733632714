import React from "react";
import { Row, Col } from "react-grid-system";
import { useTranslation } from "b2c/hooks/useTranslation";
import parse from "html-react-parser";

import { PlayCircle } from "@styled-icons/boxicons-regular";
import { useModal } from "utils/hooks";
// import { pluralize } from "utils/helpers";
import { Text, Card, Button, List, Image, ProgressBar, Divider } from "../../../../components/Core";
import Summary from "../Summary";
import StarRating from "../../../../components/StarRating";
import { StartPremiumCourseContent } from "../../../../components/ModalContents";
import { GuestHidden, GuestVisible, AuthorizationButton } from "../../../../components/WithAuth";
import ReviewModalContent from "../ReviewModalContent";
import { useIsBarona } from "../../../../hooks";
import { languageFormat } from "../../../../constants/main";

const CourseProgress = ({
  loading,
  processing,
  modules = [],
  duration_seconds,
  progress,
  review,
  completed_at,
  tests_count,
  premium,
  ncpwd,
  getCourseModule,
  startCourse,
  retakeCourse,
  cancelCourse,
  finishAffiliate,
  affiliate_link_url,
  organizer,
  price,
  level,
  language,
  image
}) => {
  const { t, language: languageType } = useTranslation();
  const languageFormatType = languageFormat[languageType];

  const openReviewModal = useModal(<ReviewModalContent />, "");
  const openPremiumModal = useModal(
    <StartPremiumCourseContent url={affiliate_link_url} organizer={organizer} startCourse={startCourse} />,
    ""
  );
  // {t("learn.title", { name: companyName })}

  const isBarona = useIsBarona();
  const overview = [
    ...(organizer ? [`${t("learn.detail.organized_by")} ${organizer.name}`] : []),
    ...(duration_seconds > 0 ? [t("learn.detail.course_take", { time: Math.round(duration_seconds / 3600) })] : []),
    ...(tests_count > 0 ? [`${tests_count} ${t("learn.detail.quizzes")}`] : []),
    ...(level ? [`${level} ${t("learn.detail.level")}`] : []),
    ...(language ? [`${t("learn.detail.language")} ${language}`] : [])
  ];

  const moduleIndex = modules.map(({ id }) => id).indexOf(progress?.current_module?.id) + 1;

  return (
    <Summary loading={loading} courseBackground={image} premium={premium && !ncpwd}>
      {progress?.completed_count >= 0 ? (
        <>
          <Text fontSize={18} fontWeight={600} margin="0 0 15px" loaded>
            {t("learn.detail.label")}{" "}
            {progress.finished ? ` ${t("learn.detail.completed")}` : ` ${t("learn.detail.in_progress")}`}
          </Text>
          {!premium && (
            <>
              <ProgressBar
                currentValue={progress.completed_count}
                maxValue={progress.modules_count}
                style={{ margin: "0 0 5px" }}
              />
              <Text margin="0 0 30px">
                {parse(
                  t("learn.module.have_completed", { count: `${progress.completed_count}/${progress.modules_count}` })
                )}
              </Text>
            </>
          )}
          {!progress.finished ? (
            <>
              {!premium ? (
                <>
                  <Card theme="grey100" bordered borderColor="secondary200">
                    <Card.Content>
                      <Text fontWeight={600} margin="0 0 5px">
                        {t("learn.detail.next")}
                      </Text>
                      <Text color="secondary300" fontWeight={600}>
                        {t("learn.detail.modules_name_module")} {moduleIndex}
                      </Text>
                      <Row>
                        <Col>
                          <Text color="secondary300" margin="0" lineHeight="20px">
                            {progress.current_module?.name}
                          </Text>
                        </Col>
                        <Col width="content">
                          <PlayCircle width={20} fill="#666666" style={{ margin: "0 5px", flex: "0 0 20px" }} />
                        </Col>
                      </Row>
                    </Card.Content>
                  </Card>
                  <Button
                    processing={processing}
                    width="100%"
                    theme={premium ? "additional200" : "primary"}
                    margin="10px 0"
                    onClick={() => getCourseModule(progress.current_module?.id)}
                    color="primaryButtonLabelColor"
                  >
                    {t("learn.detail.continue")}
                  </Button>
                  <Button processing={processing} theme="grey200" width="100%" onClick={cancelCourse}>
                    {t("learn.detail.cancel")}
                  </Button>
                </>
              ) : (
                <>
                  <Button as="a" href={affiliate_link_url} target="_self" width="100%" theme="additional200">
                    {t("learn.detail.view_course")} {organizer?.name}
                  </Button>
                  <Button width="100%" theme="grey200" onClick={finishAffiliate} margin="10px 0">
                    {t("learn.detail.completed_this_course")}
                  </Button>
                  <Button width="100%" theme="grey200" onClick={cancelCourse}>
                    {t("learn.detail.remove_from_unfinished")}
                  </Button>
                </>
              )}
            </>
          ) : (
            <>
              {(completed_at || review?.grade) && (
                <Card theme="grey100">
                  <Card.Content padding="8px 10px">
                    {completed_at && (
                      <>
                        <Text color="black300" fontWeight={600}>
                          {t("learn.detail.completion_date")}
                        </Text>
                        <Text color="black300" fontWeight={500} margin="0 0 15px">
                          {new Date(completed_at).toLocaleString(languageFormatType, {
                            month: "long",
                            day: "numeric",
                            year: "numeric"
                          })}
                        </Text>
                      </>
                    )}
                    {!premium && (
                      <>
                        <Text color="black300" fontWeight={600}>
                          {t("learn.detail.your_rating")}:
                        </Text>
                        {review?.grade ? (
                          <StarRating rating={review.grade} hideNumbers />
                        ) : (
                          <Button
                            theme="secondary300"
                            padding="4px"
                            noBackground
                            height="fit-content"
                            onClick={openReviewModal}
                          >
                            {t("learn.detail.rate_course")}
                          </Button>
                        )}
                      </>
                    )}
                  </Card.Content>
                </Card>
              )}
              {premium ? (
                <>
                  <Button
                    as="a"
                    href={affiliate_link_url}
                    target="_self"
                    width="100%"
                    theme="additional200"
                    margin="10px 0"
                  >
                    {t("learn.detail.view_course")} {organizer?.name}
                  </Button>
                  <Button width="100%" theme="grey200" onClick={cancelCourse}>
                    {t("learn.detail.remove_from_completed")}
                  </Button>
                </>
              ) : (
                <>
                  <Button processing={processing} theme="primary" margin="10px 0" width="100%" onClick={retakeCourse}>
                    {t("learn.detail.retake_course")}
                  </Button>
                  <Button processing={processing} theme="grey200" width="100%" onClick={cancelCourse}>
                    {t("learn.detail.cancel")}
                  </Button>
                </>
              )}
            </>
          )}
        </>
      ) : (
        <>
          <Text fontSize={22} margin="0 0 24px" loaded>
            {t("learn.detail.price")}: <b>{price && !ncpwd ? `USD ${price}` : t("learn.detail.free")}</b>
          </Text>

          <GuestVisible>
            {isBarona ? (
              <Button.Link to="/login" color="white" theme="primary" width="100%" type="learn">
                {t("learn.detail.sign_in_to_start")}
              </Button.Link>
            ) : (
              <AuthorizationButton to="/signup" theme={premium ? "additional200" : "primary"} width="100%" type="learn">
                {t("learn.detail.sign_in_to_start")}
              </AuthorizationButton>
            )}
          </GuestVisible>

          <GuestHidden>
            {ncpwd ? (
              <Button
                as="a"
                href={affiliate_link_url}
                target="_blank"
                width="100%"
                theme="additional200"
                onClick={() => {
                  if (!progress?.started) startCourse();
                }}
              >
                {progress?.started ? "Continue" : "Start"} course
              </Button>
            ) : (
              <Button
                processing={processing}
                width="100%"
                onClick={premium ? openPremiumModal : startCourse}
                theme={premium ? "additional200" : "primary"}
                color="primaryButtonLabelColor"
              >
                {premium ? `Purchase on ${organizer?.name}` : t("learn.detail.start_course")}
              </Button>
            )}
          </GuestHidden>
        </>
      )}
      {overview.length > 0 && (
        <>
          <Text margin="20px 0 10px" fontSize={14} fontWeight={600}>
            {t("learn.detail.overview")}
          </Text>
          <List>
            {overview?.map(
              (item, i) =>
                item && (
                  <List.Item key={i} fontSize={12}>
                    {item}
                  </List.Item>
                )
            )}
          </List>
        </>
      )}

      {organizer && (
        <>
          <Divider margin="20px 0 10px" />
          <Text fontSize={14} fontWeight={600}>
            {t("learn.detail.course_organized")}
          </Text>
          <Image src={organizer?.logo} alt={organizer?.name} height="auto" maxHeight="125px" maxWidth="100%" />
        </>
      )}
    </Summary>
  );
};

export default CourseProgress;
