import React, { useMemo } from "react";
import BaronaLogoSvg from "images/barona-logo.svg";
import { Globe } from "@styled-icons/boxicons-regular";
import { useHistory } from "react-router-dom";

import FinlandFlag from "images/b2c/finlandFlag.svg";
import {
  PageWrapper,
  Header,
  HeaderContent,
  Container,
  Title,
  Subtitle,
  Option,
  Flag,
  LanguageLabel,
  LanguageFooter,
  Global,
  OptionContent,
  Footer,
  FooterContent
} from "./styled";
import { useTranslation } from "../../hooks/useTranslation";

const LanguageSelector = ({ countries, defaultPage, isModal, globalBrand }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const replaceCountryAndLanguage = path => {
    return history.location.pathname.replace(/^\/[a-zA-Z]{2}\/[a-zA-Z]{2}/, path);
  };

  const onSelectCountryAndLocale = path => {
    window.location.href = isModal ? replaceCountryAndLanguage(path) : `${path}/job`;
  };

  const countryAndLanguage = useMemo(() => {
    const { pathname } = history.location;
    const [country, language] = pathname.split("/").slice(1, 3);

    return { country, language };
  }, [globalBrand?.locales?.translations]);

  return (
    <PageWrapper $isModal={isModal}>
      {!isModal && (
        <Header>
          <HeaderContent>
            <BaronaLogoSvg />
          </HeaderContent>
        </Header>
      )}

      <Container $isModal={isModal}>
        <Title $isModal={isModal}>{t("global.languageSelector.title")}</Title>
        <Subtitle $isModal={isModal}>{t("global.languageSelector.description")}</Subtitle>
        {countries.map(country => (
          <Option key={country.country_code}>
            <Flag>
              <FinlandFlag />
            </Flag>

            {country.translations.map(locale => {
              return (
                <OptionContent
                  key={locale.locale_name}
                  $isActive={
                    countryAndLanguage.language === locale.locale &&
                    countryAndLanguage.country === country.country_code.toLowerCase()
                  }
                  onClick={() => onSelectCountryAndLocale(`/${country.country_code.toLowerCase()}/${locale.locale}`)}
                >
                  <LanguageLabel>{locale.locale_name}</LanguageLabel>
                  <span>{locale.name}</span>
                </OptionContent>
              );
            })}
          </Option>
        ))}

        <LanguageFooter>
          Looking for opportunities across the Nordics? Visit our global website for international talent. <br />
          <Global href={defaultPage.url}>
            <Globe />
            International (English)
          </Global>
        </LanguageFooter>
      </Container>

      {!isModal && (
        <Footer>
          <FooterContent>© Barona 2024</FooterContent>
        </Footer>
      )}
    </PageWrapper>
  );
};

export default LanguageSelector;
