import React from "react";
import imagePlaceholder from "images/placeholder-news.jpg";
import Image from "../../../../components/Core/Image";
import { ImageWrapper } from "./styled";

const ArticleImage = ({ src, alt, margin = "30px -20px 40px", loading = false }) => {
  return (
    <ImageWrapper styled={{ margin }}>
      <Image className="article-image" src={src} placeholder={imagePlaceholder} alt={alt} loading={loading} />
      {/* <ImageCaption
        styled={{
          color: Theme["black200"],
          authorColor: Theme["primary300"],
        }}
      >
        Photo credit: <span>Retha Ferguson</span>
      </ImageCaption> */}
    </ImageWrapper>
  );
};

ArticleImage.defaultProps = {
  src: imagePlaceholder,
  alt: "Article Preview Image"
};

export default ArticleImage;
