import React from "react";

import categoryPlaceholder from "images/b2c/category_placeholder.jpg";
import { Card, Text, Title } from "../../../../components/Core";

const CategoryItemPlaceholder = () => {
  return (
    <Card theme="white" shadow>
      <Card.Header padding="12px 16px 0" height={96} backgroundImage={categoryPlaceholder} />

      <Card.Content padding="7px 16px 15px" height={64}>
        <Title loading level={6} height={40} fontWeight={500} width="100%" maxLength={60} truncatedLine={2}>
          ___
        </Title>

        <Text color="black300" loading>
          ___
        </Text>
      </Card.Content>
    </Card>
  );
};

export default CategoryItemPlaceholder;
