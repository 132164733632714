import React from "react";

import { Text } from "../Core";
import { theme } from "../../contexts/theme";

const JobSeekerStep = ({ text }) => {
  return (
    <Text fontWeight="400" fontSize="14px" color={theme.black300} margin="24px 0 10px 0" style={{ padding: "0 26px" }}>
      {text}
    </Text>
  );
};

export default JobSeekerStep;
