import React, { useState, useMemo, useEffect } from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";

import { useTranslation } from "b2c/hooks/useTranslation";
import { jobTypes } from "b2c/constants/onboarding";
import Content from "../Content";
import Navigation from "../Navigation";
import { Label, SearchInput, SearchWrapper, Container, Description, Or } from "./styled";
import { getCompanyName, isBaronaBrand } from "../../../../contexts/branded";
import SocialAuthorization from "../SocialAuthorization";
import Steps from "../Steps";

const YourEmail = () => {
  const [emailValue, setEmailValue] = useState("");
  const isBarona = isBaronaBrand();
  const { t } = useTranslation();
  const history = useHistory();

  const validateEmail = input => {
    if (emailValue) {
      const regex = /^\S+@\S+\.\S+$/;
      if (!regex.test(input)) {
        return { error: true };
      }
    }

    return { error: false };
  };

  const { error } = useMemo(() => {
    return validateEmail(emailValue);
  }, [emailValue]);

  const finishRegistration = () => {
    const prevOnboardingForm = JSON.parse(sessionStorage.getItem("onboardingFlow"));

    sessionStorage.removeItem("onboardingFlow");

    if (prevOnboardingForm.returnTo) {
      history.push(prevOnboardingForm.returnTo);
    }
  };

  const backUrl = useMemo(() => {
    const prevOnboardingForm = JSON.parse(sessionStorage.getItem("onboardingFlow"));

    if (prevOnboardingForm?.jobType === jobTypes.skilled) {
      return "/onboarding/pick-job";
    }

    return "/onboarding/seniority-level";
  }, []);

  const handleChange = event => {
    setEmailValue(event.target.value);
  };

  const handleSubmit = () => {
    const prevOnboardingForm = JSON.parse(sessionStorage.getItem("onboardingFlow"));

    const onboardingForm = {
      ...prevOnboardingForm,
      yourEmail: emailValue
    };

    sessionStorage.setItem("onboardingFlow", JSON.stringify(onboardingForm));

    history.push("/onboarding/name-and-password");
  };

  useEffect(() => {
    const onboardingFlowStorage = JSON.parse(sessionStorage.getItem("onboardingFlow"));
    if (onboardingFlowStorage?.yourEmail && !emailValue) {
      setEmailValue(onboardingFlowStorage.yourEmail);
    }
  }, []);

  return (
    <>
      <Steps width="420px" />

      <Container data-cy="your-email">
        <Content title={t("onboarding.email.title")} description={t("onboarding.email.description")}>
          <Label>{t("onboarding.email.label")}</Label>
          <SearchWrapper>
            <SearchInput placeholder={t("onboarding.email.placeholder")} onChange={handleChange} value={emailValue} />
          </SearchWrapper>
          <Description>
            {isBarona ? t("onboarding.email.agreement_1_without_age") : t("onboarding.email.agreement_1")}
            <a href="/terms-and-conditions"> {t("onboarding.email.service_terms", { name: getCompanyName() })}</a>{" "}
            {t("onboarding.email.and")}{" "}
            <a href={isBarona ? "https://policies.barona.fi/" : "/terms-and-conditions"}>
              {" "}
              {t("onboarding.email.privacy")}{" "}
            </a>
            {t("onboarding.email.agreement_2")}
          </Description>

          <>
            {!isBarona && (
              <>
                <Or>
                  <span>{t("onboarding.email.or")}</span>
                </Or>
                <SocialAuthorization label={t("onboarding.email.continue_with")} onSuccess={finishRegistration} />
              </>
            )}
          </>
        </Content>
      </Container>
      <Navigation backUrl={backUrl} isDisabled={!emailValue || Boolean(error)} handleSubmit={handleSubmit} />
    </>
  );
};

export default observer(YourEmail);
