import React, { useEffect } from "react";
import { toJS } from "mobx";
import { observer } from "mobx-react";

import { ErrorBoundaryProvider } from "utils/hocs/errorBoundary";
import useStore from "../../contexts/store";

const UserProvider = ({ children, initUser, ...props }) => {
  const { user, updateUser } = useStore("User");

  useEffect(() => {
    if (initUser && !user) updateUser(initUser);
  }, [initUser]);

  return process.env.NODE_ENV !== "development" ? (
    <ErrorBoundaries user={toJS(user)} {...props}>
      {children}
    </ErrorBoundaries>
  ) : (
    children
  );
};

function ErrorBoundaries({ user, children }) {
  return (
    <ErrorBoundaryProvider user={user} isUpdateUser>
      {children}
    </ErrorBoundaryProvider>
  );
}

export default observer(UserProvider);
