import React from "react";
import { Text, Button, Title } from "../../Core";
import { Modal } from "./styled";

export const ProductFree = () => {
  return (
    <Modal>
      <Title
        style={{
          fontSize: "24px",
          lineHeight: "130%",
          color: "#292929",
          margin: "0 auto",
          display: "block",
          fontWeight: "600"
        }}
      >
        Product is FREE in your country!
      </Title>
      <Text
        style={{
          fontSize: "14px",
          color: "#000000",
          lineHeight: "140%",
          marginTop: "16px",
          textAlign: "center"
        }}
      >
        Lucky you, Instant Feedback is currently available for free in Nigeria. Enjoy!
      </Text>

      <Button
        theme="primary"
        style={{
          fontWeight: "600",
          fontSize: "16px",
          lineHeight: "24px",
          width: "100%",
          display: "block",
          marginTop: "26px"
        }}
      >
        Got it!
      </Button>
    </Modal>
  );
};
