import styled, { css } from "styled-components";

export const ContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  @media screen and (max-width: 767px) {
    flex-direction: ${({ $reverseOnMobile }) => ($reverseOnMobile ? "column-reverse" : "column")};
  }
`;

export const ContentSide = styled.div`
  ${({ padding = "0" }) => css`
    width: 100%;
    max-width: 835px;
    padding: ${padding};
  `}
`;

export const StyledModuleContent = styled.div`
  width: 100%;
  font-size: 16px;
  line-height: 1.6;
  font-weight: 400;
  margin: 0px 0px 16px;
  overflow: hidden;

  img {
    object-fit: contain;
    max-width: 100%;
  }
`;
