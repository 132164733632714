import styled from "styled-components";

export const CountryContainer = styled.div`
  height: 48px;
  margin-bottom: 20px;

  .select__indicator-separator,
  .select__indicator {
    display: none;
  }

  .select__control {
    border-color: #e8eaef !important;
    border-radius: 5px;
  }

  .dropdown-label {
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    margin: 0 0 8px;
  }

  .select__input {
    opacity: 1 !important;
  }

  .select__control_error .select__control {
    border-color: #eb3737 !important;
    background-color: #ffe5e5;
  }
`;

export const DropdownContent = styled.div`
  .dropdown-label {
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    margin: 0 0 8px;
  }
`;
