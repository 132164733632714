import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "b2c/hooks/useTranslation";

import SvgContent from "b2c/components/SvgContent";
import { withRoute } from "utils/hocs/withRoute";
import MainInformationIco from "images/b2c/profilePage/MainInformationIco.svg";
import { Title, Text, Button, Spinner, Card } from "b2c/components/Core";
import PersonalityScore from "../PersonalityScore";
import useStore from "../../../../contexts/store";
import EmptyContent from "../EmptyContent";

const Personality = ({ location: { state } }) => {
  const {
    Profile: {
      state: { loading },
      profile: { personality = [] },
      getPersonality
    }
  } = useStore("User");
  const { t } = useTranslation();

  useEffect(() => {
    getPersonality(state?.test_completed);
  }, [state?.test_completed]);

  useEffect(() => {
    if (window.scrollY) {
      window.scroll(0, 0);
    }
  }, []);
  return (
    <Card shadow style={{ margin: "0 0 20px" }}>
      <Card.Header
        padding="12px 15px"
        style={{
          alignItems: "center",
          minHeight: loading ? 0 : "64px"
        }}
      >
        {!loading &&
          (personality.length > 0 ? (
            <>
              <SvgContent style={{ display: "flex", alignItems: "center", flex: "0 0 auto" }}>
                <MainInformationIco />
              </SvgContent>

              <Title
                level={5}
                margin="0 0 0 15px"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center"
                }}
              >
                {t("profile.personality.title")}
              </Title>
            </>
          ) : (
            <Title level={4} margin="auto" fontWeight={500}>
              {t("profile.personality.empty.title")}
            </Title>
          ))}
      </Card.Header>

      <Card.Content>
        {loading ? (
          <Spinner size="42px" margin="75px auto" />
        ) : personality.length > 0 ? (
          <>
            <Text fontSize={16} fontWeight={500}>
              {t("profile.personality.sub-title")}
            </Text>
            <Text margin="8px 0 16px" color="black400">
              {t("profile.personality.description")}
            </Text>
            {personality.map(({ mean, score, headline, bottom_text, top_text, description }, i) => (
              <PersonalityScore
                key={`${headline}_${i}`}
                title={headline}
                userScore={(score - 0.5) * 200}
                averageScore={(mean - 50) * 2}
                bottomText={bottom_text}
                topText={top_text}
                description={description}
              />
            ))}
            <Button.Link
              color="primaryButtonLabelColor"
              to={{
                pathname: "/tests/4",
                state: { from: "/profile/personality" }
              }}
              margin="0 auto 8px"
            >
              {t("profile.personality.retake_test_button")}
            </Button.Link>
          </>
        ) : (
          <EmptyContent from="/profile/personality" />
        )}
      </Card.Content>
    </Card>
  );
};

export default withRoute(observer(Personality));
