import React, { useState, useContext } from "react";
import { Row, Col, Visible, useScreenClass } from "react-grid-system";
import { Reset, Search, X } from "@styled-icons/boxicons-regular";

import { useTranslation } from "b2c/hooks/useTranslation";
import { Title, Button, Icon } from "../../../../components/Core";
import CourseFilters from "../CourseFilters";
import Layout from "../../../../components/Layout";
import SearchRow from "../../../../components/SearchRow";
import { StyledFilters } from "../../../../components/SearchTools/styled";
import ThemeContext from "../../../../contexts/theme";

const CourseSearchTools = ({
  loading,
  minimize,
  applyFilter,
  appliedFilters,
  searchFilters,
  resetFilters,
  appliedCount
}) => {
  const isMobile = /xs|sm/.test(useScreenClass());
  const theme = useContext(ThemeContext);
  const [enabled, enableFilters] = useState(false);
  const { t } = useTranslation();

  const batchReset = () => {
    resetFilters();
    enableFilters(false);
  };

  return (
    <>
      <Visible sm xs>
        <Layout.Section backgroundColor="white" padding="12px 20px">
          <Row nowrap>
            <Col>
              <Button
                width="100%"
                onClick={() => enableFilters(true)}
                color="primaryButtonLabelColor"
                icon={appliedCount > 0 ? null : <Icon as={Search} width={20} fill="primaryButtonLabelColor" />}
              >
                {appliedCount > 0 ? t("learn.edit_search") : t("learn.search_courses")}
              </Button>
            </Col>
            {appliedCount > 0 && (
              <Col>
                <Button width="100%" onClick={resetFilters} theme="grey200" icon={<Icon as={Reset} width={20} />}>
                  {t("global.clear_filters_button")}
                </Button>
              </Col>
            )}
          </Row>
        </Layout.Section>
      </Visible>

      {(!isMobile || enabled) && (
        <StyledFilters backgroundColor={theme.white}>
          <Layout.Content size="xl">
            <Visible sm xs>
              <Row justify="between" style={{ marginBottom: 20 }}>
                <Col style={{ display: "flex" }}>
                  <Title level={5} fontWeight={600}>
                    {t("learn.search_courses")}
                  </Title>
                </Col>
                <Col width="content">
                  <Button.Iconed noBackground onClick={() => enableFilters(false)}>
                    <Icon as={X} width={24} />
                  </Button.Iconed>
                </Col>
              </Row>
            </Visible>

            <SearchRow
              loading={loading}
              term={appliedFilters?.term}
              appliedFilters={appliedFilters}
              applyFilter={applyFilter}
              resetFilters={resetFilters}
              appliedCount={appliedCount}
              showLocation={false}
              placeholder={t("learn.placeholder")}
              buttonText={t("learn.show_courses_button")}
            />

            {!minimize && (
              <CourseFilters searchFilters={searchFilters} appliedFilters={appliedFilters} applyFilter={applyFilter} />
            )}

            <Visible sm xs>
              <Col lg={2}>
                <Button margin="8px 0 0" width="100%" onClick={() => enableFilters(false)}>
                  {t("learn.show_courses")}
                </Button>
              </Col>

              {appliedCount > 0 && (
                <Col lg={2}>
                  <Button
                    width="100%"
                    onClick={batchReset}
                    theme="grey200"
                    margin="16px 0 0"
                    icon={<Icon as={Reset} width={20} />}
                  >
                    {t("global.clear_filters_button")}
                  </Button>
                </Col>
              )}
            </Visible>
          </Layout.Content>
        </StyledFilters>
      )}
    </>
  );
};

export default CourseSearchTools;
