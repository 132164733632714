import React, { lazy, Suspense } from "react";
import { Link } from "react-router-dom";

const AuthorizedContainer = lazy(() => import("../AuthorizedContainer"));

export const HeroItemContainer = ({ children, isonboarding, ...rest }) => {
  return (
    <Suspense
      fallback={
        <Link {...rest} to="/signup">
          {children}
        </Link>
      }
    >
      <AuthorizedContainer isonboarding={isonboarding} {...rest} isNewModalFlow>
        {children}
      </AuthorizedContainer>
    </Suspense>
  );
};
