import React, { useEffect, useContext } from "react";
import { observer } from "mobx-react";
import { ToastContainer, toast, cssTransition } from "react-toastify";

import { debounce } from "utils/helpers";
import { CheckCircle, Error } from "@styled-icons/boxicons-regular";
import { Close } from "@styled-icons/material/Close";
import Text from "../Core/Text";
import ThemeContext from "../../contexts/theme";
import useStore from "../../contexts/store";

const Fade = cssTransition({
  enter: "fadeInFromBottom",
  exit: "fadeOutToBottom",
  duration: 300
});

const StateMessage = () => {
  const theme = useContext(ThemeContext);
  const {
    state: { type, message, status },
    setAppState
  } = useStore("AppState");

  useEffect(() => {
    if (message && status > 0)
      debounce(
        toast(
          <>
            {/error/.test(type) && <Error fill={theme.accent300} width={36} />}
            {/success/.test(type) && <CheckCircle fill={theme.success300} width={36} />}
            <Text margin="auto" textAlign="center" maxLength={100}>
              {message}
            </Text>
          </>,
          {
            type: type.slice(type.indexOf("_") + 1, type.length),
            onClose: () => setAppState({})
          }
        ),
        500
      );
  }, [message, type]);

  return (
    <ToastContainer
      toastClassName="state-message-toast"
      autoClose={3000}
      transition={Fade}
      position="bottom-center"
      closeButton={<Close className="Toastify__close-button" />}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      pauseOnFocusLoss
    />
  );
};

export default observer(StateMessage);
