import React, { useState, useEffect, useMemo } from "react";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import { LeftArrowAlt } from "@styled-icons/boxicons-regular";
import format from "date-fns/format";
import { useTranslation } from "b2c/hooks/useTranslation";

import { validateNonempty, validateEmail, validateNumber } from "utils/validation";
import signUpClose from "images/signup-close.png";
import OptionSelectionStep from "./OptionSelectionStep";
import Button from "../Core/Button";
import { Image, Text } from "../Core";
import CalendarStep from "./CalendarStep";
import FormStep from "./FormStep";
import JobSeekerStep from "./JobSeekerStep";
import ConfirmedStep from "./ConfirmedStep";
import BaronaConfirmedStep from "./BaronaConfirmedStep";
import useStore from "../../contexts/store";
import { StyledStepsCount, StyledModalWrapper, StyledTitle, StyledContent, StyledFooter } from "./styled";
import { stepsInfo, steps, productTypes } from "../../constants/contactUs";
import { isBaronaBrand } from "../../contexts/branded";

const kenyaCountryId = 1;

const formInit = {
  name: "",
  phoneCode: "",
  phone: "",
  email: "",
  organization: "",
  size: null,
  about: "",
  country: null
};

const defaultValidation = {
  name: { error: false },
  phoneCode: { error: false },
  phone: { error: false },
  email: { error: false },
  organization: { error: false },
  size: { error: false },
  about: { error: false },
  country: { error: false }
};

const validateForm = ({ name, phoneCode, phone, email, organization, size, about, country }) => {
  const validation = {
    name: validateNonempty(name),
    phoneCode: validateNonempty(phoneCode),
    phone: validateNumber(phone),
    email: validateEmail(email),
    organization: validateNonempty(organization),
    size: validateNonempty(size),
    about: validateNonempty(about),
    country: validateNonempty(country)
  };

  const valid = Object.keys(validation).every(key => validation[key].test);

  return { validation, valid };
};

const ContactUsModal = ({ close }) => {
  const {
    getAppointments,
    addTimeSlots,
    savePotentialEmployers,
    getPhoneCodes,
    getEmployerSizes,
    getCountries,
    appointments = [],
    employerSizes = [],
    countries = [],
    codes = [],
    setSelectedProductType,
    selectedProductType
  } = useStore("ContactUs");
  const { track } = useStore("Tracking");
  const [validationState, setValidation] = useState(defaultValidation);
  const [userId, setUserId] = useState(null);
  const { current_country: currentCountry } = useStore("initialState");
  const [step, setStep] = useState(steps.optionSelectionStep);
  const [prevStep, setPrevStep] = useState(null);
  const [selected, setSelected] = useState({});
  const [selectedHour, setSelectedHour] = useState(null);
  const [selectedDate, setSelectaedDate] = useState(null);
  const isBarona = isBaronaBrand();
  const baronaPriorityCountries = ["Sweden", "Norway", "Finland", "Denmark", "Estonia"];
  const { global_brand } = useStore("initialState");
  const globalBrandName = global_brand.name.toLowerCase();
  const { t } = useTranslation();

  const sortedCountries = useMemo(() => {
    const priorityCountiesIds = [198, 168, 73, 62, 67];
    return toJS(countries).sort((a, b) => {
      return (
        priorityCountiesIds.findIndex(item => item === b.id) - priorityCountiesIds.findIndex(item => item === a.id)
      );
    });
  }, [countries]);

  const sortedCodes = useMemo(() => {
    const priorityCodes = baronaPriorityCountries;
    return toJS(countries).sort((a, b) => {
      return priorityCodes.findIndex(item => item === b.name) - priorityCodes.findIndex(item => item === a.name);
    });
  }, [countries]);

  const [formState, setFormState] = useState({
    ...formInit
  });

  const onStepChange = () => {
    let employerFormBody = {};
    if (step === steps.optionSelectionStep) {
      setPrevStep(step);

      if (selected.id) {
        track("b2b_contact_us", { action: "started" });
        setStep(steps.formStep);
      } else if (isBarona) {
        setStep(steps.baronaJobSeekerStep);
      } else {
        setStep(steps.jobSeekerStep);
      }
    }
    if (step === steps.formStep) {
      setValidation(defaultValidation);
      const { valid, validation } = validateForm(formState);
      setValidation(validation);
      if (valid) {
        const contactUsPricing = JSON.parse(localStorage.getItem("contactUsPricing"));
        employerFormBody = {
          potential_employer: {
            name: formState.name,
            phone_number: formState.phoneCode + formState.phone,
            email: formState.email,
            organization_name: formState.organization,
            employer_size_id: formState.size,
            primary_country_of_hiring_id: formState.country,
            how_did_you_hear_about_fuzu: formState.about,
            fuzu_country_id: currentCountry.id === "global" ? kenyaCountryId : currentCountry.id
          }
        };

        if (contactUsPricing?.title || Number.isInteger(selectedProductType)) {
          employerFormBody.potential_employer.selected_product_type =
            productTypes[contactUsPricing?.title] || selectedProductType;
        }
        savePotentialEmployers(employerFormBody).then(res => {
          track("b2b_contact_us", { action: "submitted" });
          setPrevStep(step);
          setUserId(res.data?.potential_employer?.id);
          if (isBarona) {
            setStep(steps.baronaConfirmedStep);
          } else {
            setStep(steps.calendarStep);
          }
        });
      }
    }
    if (step === steps.calendarStep) {
      setPrevStep(step);
      const body = {
        potential_employer: {
          call_date: selectedDate,
          call_time: format(new Date(`Jan 1, 2000 ${selectedHour}`), "HH:mm")
        }
      };

      if (selectedHour) {
        addTimeSlots(body, userId).then(() => {
          setStep(steps.confirmedStep);
        });
      } else {
        setStep(steps.confirmedStep);
      }
    }
    if (step === steps.confirmedStep || step === steps.baronaConfirmedStep) {
      close();
    }
    if (step === steps.jobSeekerStep || step === steps.baronaJobSeekerStep) {
      close();
      window.location.replace("/");
    }
  };

  useEffect(() => {
    return () => {
      localStorage.removeItem("contactUsPricing");
      setSelectedProductType("");
    };
  }, []);

  useEffect(() => {
    getPhoneCodes();
    getEmployerSizes();
    getCountries();
    if (currentCountry) {
      getAppointments(currentCountry.id === "global" ? kenyaCountryId : currentCountry.id);
    }

    addEventListener("scroll", event => {
      event.preventDefault();
    });
  }, [currentCountry]);

  const isDisableSaveButton =
    !formState.name ||
    !formState.phoneCode ||
    !formState.phone ||
    !formState.email ||
    !formState.organization ||
    !formState.size ||
    !formState.country ||
    !formState.about;

  return (
    <StyledModalWrapper $isPadding={step === steps.formStep}>
      {(step === steps.jobSeekerStep || step === steps.baronaJobSeekerStep) && (
        <Image
          src={signUpClose}
          onClick={close}
          alt="close"
          style={{
            position: "absolute",
            cursor: "pointer",
            width: "140px",
            height: "40px",
            top: "10px",
            right: "-40px",
            zIndex: "2"
          }}
        />
      )}
      <StyledTitle>
        {stepsInfo[step].arrow && (
          <LeftArrowAlt
            onClick={() => setStep(prevStep)}
            height={24}
            style={{
              display: "inline-block",
              cursor: "pointer",
              position: "relative",
              bottom: "4px"
            }}
          />
        )}
        <Text
          fontWeight="500"
          fontSize="24px"
          color="black300"
          margin="auto 0 auto 10px"
          style={{ display: "inline-block" }}
        >
          {step === steps.formStep ? t(stepsInfo.formStep.title[globalBrandName]) : t(stepsInfo[step].title)}
        </Text>

        {stepsInfo[step].step && (
          <StyledStepsCount>
            {stepsInfo[step].step} / {stepsInfo.lastStep[globalBrandName]}
          </StyledStepsCount>
        )}
      </StyledTitle>

      <StyledContent>
        {step === steps.confirmedStep && (
          <ConfirmedStep currentCountry={currentCountry} selectedDate={selectedDate} selectedHour={selectedHour} />
        )}
        {step === steps.baronaConfirmedStep && <BaronaConfirmedStep />}
        {(step === steps.jobSeekerStep || step === steps.baronaJobSeekerStep) && (
          <JobSeekerStep text={t(stepsInfo.jobSeekerText[globalBrandName])} />
        )}
        {step === steps.optionSelectionStep && <OptionSelectionStep selected={selected} setSelected={setSelected} />}
        {step === steps.calendarStep && (
          <CalendarStep
            currentCountry={currentCountry}
            appointments={appointments}
            setSelectaedDate={setSelectaedDate}
            selectedDate={selectedDate}
            selectedHour={selectedHour}
            setSelectedHour={setSelectedHour}
          />
        )}
        {step === steps.formStep && (
          <FormStep
            formState={formState}
            setFormState={setFormState}
            employerSizes={employerSizes}
            codes={isBarona ? sortedCodes : codes}
            countries={isBarona ? sortedCountries : toJS(countries)}
            dividerPosition={isBarona ? baronaPriorityCountries.length - 1 : 2}
            validationState={validationState}
            currentCountry={currentCountry}
            title={stepsInfo.formStep.title[globalBrandName]}
          />
        )}
      </StyledContent>

      <StyledFooter>
        {stepsInfo[step].cancel && (
          <Button noBackground margin="0" theme="black200" onClick={close}>
            {t("companies.contact_us_modal.cancel")}
          </Button>
        )}
        <Button
          margin="0"
          onClick={onStepChange}
          color="primaryButtonLabelColor"
          style={{
            opacity: step === steps.formStep && isDisableSaveButton ? "0.2" : "1",
            pointerEvent: step === steps.formStep && isDisableSaveButton ? "none" : "inherit",
            outline: "none"
          }}
        >
          {stepsInfo[step].button
            ? t(stepsInfo[step].button)
            : selectedHour
              ? t("companies.contact_us_modal.continue")
              : t("companies.contact_us_modal.continue_without")}
        </Button>
      </StyledFooter>
    </StyledModalWrapper>
  );
};

export default observer(ContactUsModal);
