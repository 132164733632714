import React, { useContext, forwardRef } from "react";
import { Row, Col, ScreenClassContext, Hidden } from "react-grid-system";
import { useTranslation } from "b2c/hooks/useTranslation";

import LandingContext from "../../contexts/landing";
import Layout from "../Layout";
import { Title, Text, Image, LazyLoader, Button, Icon } from "../Core";
import LinkWithFullUrl from "../../../components/LinkWithFullUrl";
import { HowItWorksWrapper, HowItWorksTextContainer } from "./styled";
import { pathWithCountry } from "../../utils/routes";
import useStore from "../../contexts/store";

const HowItWorksSection = ({ padding = "60px 0", country, isShowFullContent, style, isBarona }, ref) => {
  const screen = useContext(ScreenClassContext);
  const isSm = ["xs", "sm"].includes(screen);
  const isNigeria = country?.slug === "nigeria";
  const { global_brand } = useStore("initialState");
  const globalBrandName = global_brand.name.toLowerCase();
  const { t } = useTranslation();

  const { whatFuzuOffers: { title, slides, slidesNigeria } = {} } = useContext(LandingContext);

  const getSlides = () => {
    if (isNigeria) {
      return slidesNigeria;
    }

    return slides[globalBrandName];
  };

  return (
    <HowItWorksWrapper $padding={padding} ref={ref} style={style}>
      <Layout.Content tag="div">
        {isNigeria || isShowFullContent ? (
          <>
            <Title
              loaded
              level="2"
              fontWeight={600}
              margin="0 auto 25px"
              textAlign="center"
              style={{ fontSize: isSm ? 20 : 36 }}
            >
              {t("landing_fuzu.offers.title")}
            </Title>
            <Text loaded margin="25px auto" textAlign="center">
              {t("landing_fuzu.offers.description")}
            </Text>
          </>
        ) : (
          <>
            {title && (
              <Title
                loaded
                level="2"
                fontWeight={600}
                margin="0 auto 25px"
                textAlign="center"
                style={{ fontSize: isSm ? 20 : 36 }}
              >
                {t(title)}
              </Title>
            )}
          </>
        )}
        {getSlides()?.map(
          ({
            id,
            title: titleProp,
            subtitle,
            items,
            action,
            text,
            imageUrl,
            smImageHeight,
            smImageWidth,
            imageHeight,
            imageWidth,
            direction,
            alt,
            ...rest
          }) => (
            <Row
              key={`slide_uid_${id}`}
              align="center"
              justify="center"
              style={{
                flexDirection: direction === "reverse" ? "row-reverse" : "row"
              }}
            >
              <Col md={6}>
                <Hidden xs sm>
                  <LazyLoader once height={isSm ? smImageHeight : imageHeight}>
                    <Image
                      display="flex"
                      src={imageUrl}
                      alt={alt}
                      {...rest}
                      {...(isSm
                        ? {
                            height: smImageHeight,
                            width: smImageWidth,
                            margin: "0 auto"
                          }
                        : {
                            height: imageHeight,
                            width: imageWidth
                          })}
                    />
                  </LazyLoader>
                </Hidden>
              </Col>
              <Col md={6} style={{ display: "flex" }}>
                <HowItWorksTextContainer className="how-slide">
                  {titleProp && (
                    <>
                      {isBarona ? (
                        <Title
                          style={{
                            fontFamily: "Originals2",
                            fontSize: isSm ? "32px" : "72px",
                            textTransform: "uppercase",
                            lineHeight: "100%"
                          }}
                          level="2"
                          fontWeight="400"
                          margin="0"
                          width="100%"
                        >
                          {t(titleProp)}
                        </Title>
                      ) : (
                        <Title
                          style={{
                            lineHeight: "120%"
                          }}
                          level={isNigeria ? "3" : "2"}
                          fontWeight="600"
                          margin="0"
                          width="100%"
                        >
                          {t(titleProp)}
                        </Title>
                      )}
                    </>
                  )}
                  {subtitle && (
                    <Text fontWeight={600} width="100%" margin="4px 0 22px">
                      {t(subtitle)}
                    </Text>
                  )}
                  {text && (
                    <Text fontSize={isSm ? 14 : 20} margin="0px 0 20px" width="100%">
                      {t(text)}
                    </Text>
                  )}
                  {items?.map(item => (
                    <Text key={item.text} margin="4px 0" lineHeight={2} style={{ display: "flex" }}>
                      {item.icon && <Icon as={item.icon} width={20} margin="0 12px 0 0" style={{ flex: "none" }} />}
                      {t(item.text)}
                    </Text>
                  ))}
                  {action && (
                    <Button
                      as={action.isAnchor ? "a" : LinkWithFullUrl}
                      arrow
                      to={action.to === "/learn" ? action.to : pathWithCountry(country, action.to)}
                      href={action.to}
                      margin="18px 0 0"
                      color="primaryButtonLabelColor"
                      target={action.isAnchor ? "_blank" : "_self"}
                    >
                      {t(action.text)}
                    </Button>
                  )}
                </HowItWorksTextContainer>
              </Col>
            </Row>
          )
        )}
      </Layout.Content>
    </HowItWorksWrapper>
  );
};

export default forwardRef(HowItWorksSection);
