import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { LogOut } from "@styled-icons/boxicons-regular/LogOut";

import { getCompanyName } from "b2c/contexts/branded";
import { useTranslation } from "b2c/hooks/useTranslation";
import { Dropdown } from "../Core";
import ThemeContext from "../../contexts/theme";
import AvatarTrigger from "../AvatarTrigger";
import { useNotificationsSettingsNav } from "../NotificationsSettings";
import useStore from "../../contexts/store";
import { isCustomJkuat, isBaronaBrand } from "../../contexts/branded";
import { Heading, Name, Email, DropdownMenu, ImageContent } from "./styled";

const AccountMenu = ({ id, right = false, fullName, email, ...rest }) => {
  const theme = useContext(ThemeContext);
  const { open } = useNotificationsSettingsNav();
  const {
    User: {
      Notifications: { updateCounterBadge }
    }
  } = useStore();
  const customJkuat = isCustomJkuat();
  const isBarona = isBaronaBrand();
  const { t } = useTranslation();

  return (
    <Dropdown
      id="account-menu"
      name="account-menu"
      trigger={<AvatarTrigger borderColor={theme.primary} {...rest} isHideArrow />}
      closeOnSelect
      menuStyle="width: 240px"
      wrapperStyle="margin-bottom: 0;"
    >
      <DropdownMenu disableSorting left={!right}>
        <Heading>
          <ImageContent>
            <img src={rest.avatar} alt="" />
          </ImageContent>
          <div>
            <Name>
              <span>{fullName}</span>
            </Name>
            <Email>
              <span>{email}</span>
            </Email>
          </div>
        </Heading>
        <Dropdown.Item event as={Link} to="/profile" fontWeight={500}>
          {t("navigation.myProfile")}
        </Dropdown.Item>
        {!customJkuat && !isBarona && (
          <Dropdown.Item event as={Link} to="/points" fontWeight={500}>
            {getCompanyName()} {t("global.points")}
          </Dropdown.Item>
        )}
        {!customJkuat && (
          <Dropdown.Item event fontWeight={500} onClick={open}>
            {t("navigation.notification_settings")}
          </Dropdown.Item>
        )}

        <Dropdown.Item event as={Link} to="/settings" fontWeight={500}>
          {t("navigation.account_settings")}
        </Dropdown.Item>

        <a href="/logout" onClick={() => updateCounterBadge()} className="dropdown-item dropdown-item_logout">
          <LogOut />
          {t("navigation.logout")}
        </a>
      </DropdownMenu>
    </Dropdown>
  );
};

export default AccountMenu;
