import React from "react";

import { useTranslation } from "b2c/hooks/useTranslation";
import { FollowingBlog } from "./styled";
import { List, Title } from "../Core";
import { isWhitelabelledOrCustomBranded, isBaronaBrand } from "../../contexts/branded";

const articles = [
  {
    text: "jobs.content.articles.description_1",
    link: "https://www.fuzu.com/forum/article/get-interviews-5-steps-to-a-perfect-cover-letter"
  },
  {
    text: "jobs.content.articles.description_2",
    link: "https://www.fuzu.com/forum/article/salary-negotiation-tips-and-what-you-should-focus-on"
  },
  {
    text: "jobs.content.articles.description_3",
    link: "https://www.fuzu.com/forum/article/why-do-successful-people-love-to-do-these-5-things-before-job-interviews-let-s-find-out"
  },
  {
    text: "jobs.content.articles.description_4",
    link: "https://www.fuzu.com/forum/article/are-you-prepared-for-these-8-unique-interview-questions"
  },
  {
    text: "jobs.content.articles.description_5",
    link: "https://www.fuzu.com/forum/article/10-questions-you-can-ask-your-interviewer"
  }
];

const Articles = () => {
  const whitelabelledOrCustomBranded = isWhitelabelledOrCustomBranded();
  const isBarona = isBaronaBrand();
  const { t } = useTranslation();

  if (whitelabelledOrCustomBranded || isBarona) {
    return null;
  }

  const listArticles = articles.map(({ text, link }) => {
    return (
      <List.Item key={text}>
        <a
          href={link}
          style={{
            fontSize: "12px",
            lineHeight: "20px",
            color: "#296ACC",
            padding: "0"
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t(text)}
        </a>
      </List.Item>
    );
  });
  return (
    <FollowingBlog>
      <Title
        style={{
          fontWeight: 600,
          fontSize: "12px",
          lineHeight: "20px",
          color: "#212121",
          marginBottom: "10px",
          marginTop: "30px"
        }}
        level="2"
      >
        {t("jobs.content.articles.title")}
      </Title>
      <List style={{ padding: "0 0 0 13px" }}>{listArticles}</List>
    </FollowingBlog>
  );
};

export default Articles;
