import styled from "styled-components";

export const Container = styled.div`
  max-width: 1328px;
  width: 100%;
  margin: 0 auto;
`;

export const Title = styled.h1`
  color: #333;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  margin-bottom: 8px;
`;

export const Description = styled.p`
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  display: block;
  margin-bottom: 24px;
`;

export const Info = styled.div`
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #408bfc;
  background: #f3f8ff;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;

  svg {
    width: 20px;
    height: 20px;
    fill: #408bfc;
    flex: none;
  }

  span {
    color: #333;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
  }
`;
