import React from "react";
import DOMPurify from "dompurify";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  color: #292929;
  margin-top: 16px;
`;

const Image = styled.img`
  width: fit-content;
  height: 306px;
`;

const LandingSlide = ({ image, title }) => {
  return (
    <Wrapper>
      <Image src={image} />
      <Title dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(title) }} />
    </Wrapper>
  );
};

export default LandingSlide;
