import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { CloseCircle } from "@styled-icons/evaicons-solid";
import { CheckCircleFill, EyeFill, EyeSlashFill } from "@styled-icons/bootstrap";

import { useTranslation } from "b2c/hooks/useTranslation";
import { buildOnboardingParams } from "b2c/utils/helpers";
import { useHistory } from "react-router-dom";
import Content from "../Content";
import Navigation from "../Navigation";
import {
  Label,
  SearchInput,
  SearchWrapper,
  Container,
  CheckItem,
  CheckList,
  EmptyCycle,
  TileInputPasswordButton
} from "./styled";
import { inputTypes } from "../../../../constants/main";
import useStore from "../../../../contexts/store";
import { useQueryParams } from "../../../../../utils/hooks";
import Steps from "../Steps";

const YourEmail = () => {
  const [nameValue, setNameValue] = useState("");
  const [passwordType, setPasswordType] = useState(inputTypes.password);
  const [passwordValue, setPasswordValue] = useState("");
  const { signUp } = useStore("Onboarding");
  const { candidate, refer_code: referCode } = useQueryParams();
  const { t } = useTranslation();
  const history = useHistory();

  const handleChange = event => {
    setNameValue(event.target.value);
  };

  const handlePassword = event => {
    setPasswordValue(event.target.value);
  };

  const handleSubmit = () => {
    const prevOnboardingForm = JSON.parse(sessionStorage.getItem("onboardingFlow"));
    const applicationId = prevOnboardingForm.applicationId
      ? { applicationId: Number(prevOnboardingForm.applicationId) }
      : {};

    const paramsValues = {
      ...prevOnboardingForm,
      name: nameValue,
      password: passwordValue,
      ...applicationId
    };

    const params = buildOnboardingParams(paramsValues);

    signUp(params, referCode, candidate).then(res => {
      if (res) {
        sessionStorage.removeItem("onboardingFlow");

        if (prevOnboardingForm.returnTo) {
          history.push(prevOnboardingForm.returnTo);
        }
      }
    });
  };

  const handleChangePasswordType = () => {
    setPasswordType(prev => (prev === inputTypes.password ? inputTypes.text : inputTypes.password));
  };

  const isPasswordLengthAllowed = useMemo(() => passwordValue.length >= 8, [passwordValue]);
  const isPasswordAllowed = useMemo(() => {
    const regex = /[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

    return regex.test(passwordValue);
  }, [passwordValue]);

  useEffect(() => {
    const onboardingFlowStorage = JSON.parse(sessionStorage.getItem("onboardingFlow"));
    const name = onboardingFlowStorage?.name;

    if (name && !nameValue) {
      setNameValue(name);
    }
  }, []);

  useEffect(() => {
    const promptInterval = setTimeout(() => {
      const prevOnboardingForm = JSON.parse(sessionStorage.getItem("onboardingFlow"));

      const onboardingForm = {
        ...prevOnboardingForm,
        name: nameValue
      };

      sessionStorage.setItem("onboardingFlow", JSON.stringify(onboardingForm));
    }, 1500);

    return () => {
      window.clearTimeout(promptInterval);
    };
  }, [nameValue]);

  return (
    <>
      <Steps width="100%" />

      <Container data-cy="name-and-password">
        <Content
          title={t("onboarding.name_and_password.title")}
          description={t("onboarding.name_and_password.description")}
        >
          <Label>{t("onboarding.name_and_password.label_1")}</Label>

          <SearchWrapper>
            <SearchInput
              placeholder={t("onboarding.name_and_password.placeholder_1")}
              type="text"
              onChange={handleChange}
              value={nameValue}
              data-cy="first-name"
            />
          </SearchWrapper>

          <Label>{t("onboarding.name_and_password.label_2")}</Label>

          <SearchWrapper>
            <SearchInput
              placeholder={t("onboarding.name_and_password.placeholder_2")}
              type={passwordType}
              onChange={handlePassword}
              value={passwordValue}
              data-cy="password"
            />
            <TileInputPasswordButton onClick={handleChangePasswordType}>
              {passwordType === inputTypes.password ? <EyeSlashFill /> : <EyeFill />}
            </TileInputPasswordButton>
          </SearchWrapper>

          <CheckList>
            <CheckItem $active={isPasswordLengthAllowed}>
              {passwordValue ? <>{isPasswordLengthAllowed ? <CheckCircleFill /> : <CloseCircle />}</> : <EmptyCycle />}
              {t("onboarding.name_and_password.use_8_characters_validation")}
            </CheckItem>

            <CheckItem $active={isPasswordAllowed}>
              {passwordValue ? <>{isPasswordAllowed ? <CheckCircleFill /> : <CloseCircle />}</> : <EmptyCycle />}
              {t("onboarding.name_and_password.use_number_or_symbol_validation")}
            </CheckItem>
          </CheckList>
        </Content>
      </Container>
      <Navigation
        backUrl="/onboarding/your-email"
        isFinish
        isDisabled={!passwordValue || !isPasswordLengthAllowed || !isPasswordAllowed}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

export default observer(YourEmail);
