import React from "react";
import { observer } from "mobx-react";

import Education from "b2c/components/Education";
import { Card, Image, Text } from "b2c/components/Core";
import { useTranslation } from "b2c/hooks/useTranslation";
import useStore from "../../../../contexts/store";
import ProfileItem from "../ProfileItem";
import { EmptyContent, ItemList } from "./styled";
import { buildImageUrlS3 } from "../../../../contexts/branded";
import { nameImages } from "../../../../constants/images";
import { languageFormat } from "../../../../constants/main";

const EducationStep = ({ list = [], stepState: { id, editing }, setStepState, FormActions, resolveAction }) => {
  const {
    Profile: { removeEducation }
  } = useStore("User");
  const { t, language } = useTranslation();
  const languageFormatType = languageFormat[language];

  const handleProgress = e => {
    e.preventDefault();
    resolveAction();
  };

  const handleEdit = ids => {
    setStepState({ editing: true, id: ids });
  };

  const convertDate = string =>
    string ? new Date(string).toLocaleString(languageFormatType, { month: "short", year: "numeric" }) : "Present";

  const sortByPriority = (itemA, itemB, priority) => (itemA[priority] ? -1 : itemA.end_date > itemB.end_date ? -1 : 1);

  const disableSubmit = list.some(item => item.waits_user_review);

  return !editing ? (
    <form onSubmit={handleProgress}>
      {list.length > 0 ? (
        <ItemList>
          {list
            .slice()
            .sort((a, b) => sortByPriority(a, b, "current_education"))
            .map(
              ({
                id: ids,
                school_name,
                degree_name,
                description,
                start_date,
                end_date,
                grade,
                waits_user_review
              } = {}) => (
                <ProfileItem
                  key={ids}
                  {...{
                    ids,
                    title: degree_name,
                    info: school_name,
                    additional: grade ? `${t("wizard.education.grade")}: ${grade}` : null,
                    date: `${convertDate(start_date)} - ${convertDate(end_date)}`,
                    text: description,
                    onEdit: () => handleEdit(ids),
                    onDelete: () => removeEducation(ids),
                    alertMessage: waits_user_review && <>{t("wizard.education.error_text")}</>
                  }}
                />
              )
            )}
        </ItemList>
      ) : (
        <Card wideOnMobile>
          <EmptyContent>
            <Image src={buildImageUrlS3(nameImages.educationEmpty)} style={{ margin: "auto", display: "block" }} />

            <Text fontSize={16} textAlign="center" margin="24px auto" fontWeight={500}>
              {t("wizard.education.empty_state")}
            </Text>
          </EmptyContent>
        </Card>
      )}

      <FormActions disableSubmit={disableSubmit} />
    </form>
  ) : (
    <Education hiddenTitle itemId={id} FormActions={FormActions} resolveAction={resolveAction} />
  );
};

export default observer(EducationStep);
