import React from "react";

import { useTranslation } from "b2c/hooks/useTranslation";
import { Container, Content, Title, Video } from "./styled";
import EmbedVideo from "../../../../components/EmbedVideo";

const BaronaIntroduction = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Content>
        <Title>{t("about.introduction_title")}</Title>
        <Video>
          <EmbedVideo videoID="RNOQw7EkQ-Q" alt="Barona introduction" />
        </Video>
      </Content>
    </Container>
  );
};

export default BaronaIntroduction;
