import styled from "styled-components";

export const AvailabilityStyled = styled.div`
  border: 1px solid #666666;
  border-radius: 5px;
  padding: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #666666;
  display: flex;
  align-items: center;
  margin-bottom: ${({ isGetApp }) => (isGetApp ? "24px" : "20px")};

  svg {
    margin-right: 8px;
    fill: #666666;
    width: 16px;
    height: 16px;
  }

  @media screen and (min-width: 768px) {
    margin-bottom: ${({ isGetApp }) => (isGetApp ? "29px" : "56px")} ;
    font-size: 14px;
    padding 11px;

    svg {
      width: 24px;
      height: 24px;
    }
  }
`;
