import styled from "styled-components";
import { Spinner } from "../Core";

export const FilterWrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const EmployerItem = styled.div`
  ${({ theme }) => `
    padding: 10px 12px;
    cursor: pointer;
    color: ${theme.black300};
    min-width: 250px;
    display: flex;

    img, svg {
        border: 2px solid ${theme.grey100};
        border-radius: 4px;
        width: 36px;
        height: 36px;
        margin-right: 8px;
    }

    img {
        padding: 2px;
    }

    svg {
        padding: 6px;
        > path {
            fill: ${theme.grey300};
        }
    }

    span {
        background-color: ${theme.primary100};
    }

    &.selected {
        font-weight: 500;
        color: ${theme.black500};
    }

    &:hover {
        background-color: ${theme.primary100};
    }
`}
`;

export const ProcessingState = styled(Spinner)`
  ${({ bottom = 12 }) => `bottom: ${bottom}px;`}
  position: absolute;
  right: 0;
  transform: translate(-50%);
  z-index: 10;
`;
