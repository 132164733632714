import React from "react";

import fuzuLogoBlack from "images/new_fuzu_logo_black.png";
import { Modal, Description, Logo, Content, Actions, Close, GoToGlobal } from "./styled";

const SuggestGlobalPageModal = ({ close }) => {
  return (
    <Modal>
      <Content>
        <Logo>
          <img src={fuzuLogoBlack} alt="" />
        </Logo>

        <Description>
          Are you visiting Fuzu.com from Europe or US? Visit Fuzu Global Workforce to explore our remote talent solution
          or continue to Fuzu.com to browse jobs.
        </Description>
        <Actions>
          <Close onClick={close}>Continue to Fuzu.com</Close>
          <GoToGlobal href="https://global.fuzu.com/">Go to Fuzu Global Workforce</GoToGlobal>
        </Actions>
      </Content>
    </Modal>
  );
};

export default SuggestGlobalPageModal;
