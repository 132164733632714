import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import Home from "../../pages/Home";
import Forum from "../../pages/Forum";
import UniversalSearch from "../../pages/UniversalSearch";
import JobSearchScreen from "../../pages/JobSearch";
import ProfileScreen from "../../pages/ProfileScreen";
import EntityPage from "../../pages/EntityPage";
import SettingsScreen from "../../pages/SettingsScreen";
import FuzuPointsScreen from "../../pages/FuzuPointsScreen";
import MessagesScreen from "../../pages/MessagesScreen";
import InviteFriendScreen from "../../pages/InviteFriendScreen";
import Learning from "../../pages/Learning";
import PremiumProducts from "../../pages/PremiumProductScreen";
import NotFound from "../../pages/NotFound";
import FAQScreen from "../../pages/FAQScreen";
import WorkingInTheNordics from "../../pages/WorkingInTheNordics";
import ContactUsScreen from "../../pages/ContactUsScreen";
import TermsScreen from "../../pages/TermsScreen";
import PrivacyPolicyScreen from "../../pages/PrivacyPolicyScreen";
import AboutUsScreen from "../../pages/AboutUsScreen";
import CareerScreen from "../../pages/CareerScreen";
import { WhitelabelledOrJkuatVisible } from "../../contexts/branded";
import { useDefaultCountry } from "../../contexts/country";
import { useRedirectToTest, useShowToastMessage } from "../../hooks";

const MainRoutes = ({ jobPaths, countries, countryPaths }) => {
  useDefaultCountry();
  useShowToastMessage();
  useRedirectToTest();

  return (
    <Switch>
      <Home exact path={countryPaths?.map(({ slug }) => ["/home", `/${slug}/home`]).flat()} />
      <InviteFriendScreen exact path="/invite" />
      <FuzuPointsScreen path="/points" />
      <SettingsScreen exact path="/settings" />
      <MessagesScreen path="/messages" />

      <Forum path="/forum" />

      <Learning path={countryPaths?.map(({ slug }) => ["/learn", `/${slug}/learn`]).flat()} />

      <Route exact path={["/search", "/saved", "/search/:tab", "/saved/:tab"]}>
        <Route
          exact
          path={["/search", "/saved"]}
          render={({ location }) => <Redirect to={`${location.pathname}/jobs${location.search}`} />}
        />
        <UniversalSearch />
      </Route>
      <PremiumProducts path="/premium" />
      <JobSearchScreen exact path={jobPaths} />
      <ProfileScreen path="/profile" />
      <Route path="/company/:id" render={props => <EntityPage type="company" {...props} />} />

      <Route
        path={countryPaths?.map(({ slug }) => ["/jobs/:id", `/${slug}/jobs/:id`]).flat()}
        render={props => <EntityPage type="job" {...props} />}
      />
      <FAQScreen exact path={countryPaths?.map(({ slug }) => ["/faq", `/${slug}/faq`]).flat()} />
      <WorkingInTheNordics
        exact
        path={countryPaths?.map(({ slug }) => ["/working-in-nordics", `/${slug}/working-in-nordics`]).flat()}
        // path="/working-in-nordics"
      />
      <PrivacyPolicyScreen
        exact
        path={countryPaths?.map(({ slug }) => ["/privacy-policy", `/${slug}/privacy-policy`]).flat()}
      />

      <TermsScreen
        exact
        path={countryPaths?.map(({ slug }) => ["/terms-and-conditions", `/${slug}/terms-and-conditions`]).flat()}
      />

      <ContactUsScreen exact path={countryPaths?.map(({ slug }) => ["/contact", `/${slug}/contact`]).flat()} />

      <AboutUsScreen exact path={countryPaths?.map(({ slug }) => ["/about", `/${slug}/about`]).flat()} />

      <CareerScreen exact path={countryPaths?.map(({ slug }) => ["/careers", `/${slug}/careers`]).flat()} />

      <WhitelabelledOrJkuatVisible>
        {countries.map(({ slug }) => (
          <Route key={slug} path={`/${slug}/jobs/:id`} render={props => <EntityPage type="job" {...props} />} />
        ))}
      </WhitelabelledOrJkuatVisible>
      <NotFound />
    </Switch>
  );
};

export default MainRoutes;
