import React, { useContext, useLayoutEffect, useState, useEffect } from "react";
import { RightArrowAlt } from "@styled-icons/boxicons-regular";
import onboardingCountryWorker from "images/b2c/onboarding/onboarding-country-worker.png";
import motivationLily from "images/b2c/onboarding/motivationLily.png";
import fromEntries from "fromentries";
import parse from "html-react-parser";

import { getCompanyName } from "b2c/contexts/branded";
import { ScreenClassContext } from "react-grid-system";
import { useHistory } from "react-router-dom";
import { useTranslation } from "b2c/hooks/useTranslation";
import { Title, Text, Button, Spinner, Card, Image, Label } from "../Core";
import { StepWrapper, Footer, BackToLogin } from "./styled";
import Steps from "./steps";
import LoadingStep from "./LoadingStep";
import useStore from "../../contexts/store";
import SocialAuthorization from "../SocialAuthorization";
import { useIsBarona } from "../../hooks";
import { useQueryParams } from "../../../utils/hooks";

const Loading = () => <Spinner size="10%" margin="auto" secondaryFill="grey200" />;

const OnboardingFlowStep = ({
  loading,
  processing,
  title,
  description,
  slug,
  skip,
  validate,
  onMount,
  onSubmit = Promise.resolve(),
  proceed,
  ...rest
}) => {
  const screen = useContext(ScreenClassContext);
  const isSm = ["xs", "sm"].includes(screen);
  const { trackOnboardingEvent } = useStore("Onboarding");
  const { user } = useStore("initialState");
  const [error, setError] = useState("");
  const StepComponent = slug ? Steps.get(slug) : LoadingStep;
  const history = useHistory();
  const isBarona = useIsBarona();
  const { return_to: returnTo, application_id } = useQueryParams();
  const patternApplicationId = /application_id=(\d+)/;
  const getApplicationId =
    history.location.search && history.location.search.includes("application_id")
      ? history.location.search.match(patternApplicationId)[1]
      : "";
  const { t } = useTranslation();

  const getReturnToUrl = () => {
    if (returnTo && returnTo.includes("/job") && application_id) {
      return `?return_to=/jobs/${application_id}/application`;
    }

    return returnTo ? `?return_to=${returnTo}` : "";
  };

  const queryParams = useQueryParams();
  const queryParamJobId = queryParams?.filters?.job_id;

  useEffect(() => {
    if (user && user.onboarding_steps_completed) {
      history.push("/home");
    }
  }, []);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    if (onMount) onMount();
    setError(null);
    if (slug) {
      trackOnboardingEvent("sign_up_flow_step_started", { step: slug });
    }
  }, [slug]);

  const finishRegistration = () => {
    const onboardingContext = localStorage.getItem("onboardingContext");
    const onboardingForm = JSON.parse(localStorage.getItem("onboardingForm"));

    localStorage.removeItem("onboardingCurrentStep");
    localStorage.removeItem("onboardingFlow");

    if (onboardingContext === "job" && (onboardingForm.applied_job_id || queryParamJobId)) {
      window.location.href = `/jobs/${onboardingForm.applied_job_id || queryParamJobId}/application`;
    } else if (getApplicationId) {
      window.location.href = `/jobs/${getApplicationId}/application`;
    } else if (returnTo) {
      window.location.href = returnTo;
    } else {
      window.location.href = "/home";
    }

    localStorage.removeItem("onboardingForm");
    localStorage.removeItem("onboardingContext");
  };

  const processStep = (event, skipped) => {
    if (skipped) {
      localStorage.setItem("onboardingStepSkipped", "true");
      trackOnboardingEvent("sign_up_flow_step_completed", { step: slug, data: {}, skipped: true });
      proceed();
      return;
    }

    const errorMessage = validate && validate(event.target);
    setError(errorMessage);

    if (!errorMessage) {
      const formData = new FormData(event.target);
      const formProps = fromEntries(formData);
      const isUpdateRegistration =
        ["mostInterested", "seniorityLevel"].includes(slug) && user && !user.onboarding_steps_completed;

      const result = onSubmit(event.target, isUpdateRegistration);
      if (result?.then) {
        result.then(() => {
          trackOnboardingEvent("sign_up_flow_step_completed", {
            step: slug,
            data: formProps,
            skipped: false
          });
          if (slug !== "emailConfirmation") {
            finishRegistration();
          }
        });
      } else {
        // Hack for including job ID in job title step
        const jobId = event.target?.jobInput?.id;

        trackOnboardingEvent("sign_up_flow_step_completed", {
          step: slug,
          data: { ...formProps, ...(jobId ? { jobId } : {}) },
          skipped: false
        });

        proceed();
      }
    } else {
      trackOnboardingEvent("onboarding_step_error", { step: slug, error: errorMessage });
    }
  };

  const handleSubmission = (e, skipped) => {
    e.preventDefault();
    processStep(e, skipped);
  };

  return (
    <StepWrapper style={{ width: "100%" }}>
      <Title scales level={2} fontWeight={600} textAlign="center" width="100%">
        {t(title)}
      </Title>
      <Text textAlign="center" width="100%" margin={isSm ? "6px auto 16px" : "12px auto 32px"}>
        {t(description)}
      </Text>
      <form noValidate onSubmit={handleSubmission}>
        {loading ? (
          <Loading />
        ) : (
          <div
            style={{
              border:
                error && !["validate", "credentials", "job_type", "interested_job"].includes(slug)
                  ? "1px red solid"
                  : "none",
              borderRadius: "5px"
            }}
          >
            <StepComponent {...rest} error={error} user={user} />
          </div>
        )}
        {error && !["validate", "credentials"].includes(slug) && (
          <div
            style={{
              margin: "5px 0 0 0",
              fontSize: "12px",
              fontWeight: "400",
              color: "accent400"
            }}
          >
            {t(error)}
          </div>
        )}
        <Button
          arrow={slug === "credentials"}
          width="100%"
          margin={isSm ? "8px auto 0 auto" : "32px auto 0 auto"}
          type="submit"
          style={{ zIndex: 1 }}
          processing={processing}
          disabled={loading}
          color="primaryButtonLabelColor"
          data-cy={slug === "credentials" ? "submit-registration" : ""}
        >
          {slug === "credentials" ? t("global.done") : t("global.next")}
        </Button>

        <BackToLogin href={`/login${returnTo ? `?return_to=${returnTo}` : ""}`}>
          {t("onboarding.back_to_login")}
        </BackToLogin>

        {slug === "country" && !isBarona && (
          <MotivationElement
            min={30}
            image={onboardingCountryWorker}
            title={
              <div>
                <div>{parse(t("onboarding.motivation_title_1"))}</div>
                <div>
                  <b>{t("onboarding.motivation_position_1")}</b>🎉
                </div>
              </div>
            }
          />
        )}
      </form>

      {skip && (
        <Button
          className="link underlined"
          theme="secondary300"
          noBackground
          margin="16px auto 0"
          onClick={e => handleSubmission(e, true)}
          disabled={loading || processing}
        >
          {t(skip)}
          <RightArrowAlt width={24} />
        </Button>
      )}
      {slug === "interested_job" && !isBarona && (
        <MotivationElement
          min={2}
          image={motivationLily}
          title={
            <div>
              <div>{t("onboarding.motivation_title_2")}</div>
              <div>{parse(t("onboarding.motivation_position_2"))}🎉</div>
            </div>
          }
        />
      )}
      {slug === "validate" && (
        <>{!isBarona && <SocialAuthorization label="login.continue_with_social" onSuccess={finishRegistration} />}</>
      )}
      <EmailFooter returnTo={getReturnToUrl()} />
    </StepWrapper>
  );
};

const MotivationElement = ({ min = 1, image, title }) => {
  return (
    <Card shadow style={{ padding: "10px", maxWidth: "400px", marginTop: "32px" }}>
      <Label
        theme="transparent"
        Icon={<Image src={image} height="51px" width="51px" margin="0 8px 0 0" borderRadius="100%" />}
        fontSize={14}
        fontWeight={600}
        margin="0"
      >
        <Text fontSize="14px" lineHeight="20px">
          {title}
          <div>
            <Text margin="5px 0 0 0" lineHeight="20px" style={{ display: "block", color: "grey" }} fontSize="10px">
              {min} min{min !== 1 ? "s" : ""} ago
            </Text>
          </div>
        </Text>
      </Label>
    </Card>
  );
};

const EmailFooter = ({ returnTo }) => {
  const { t } = useTranslation();

  return (
    <Footer>
      <Text
        fontSize="14px"
        width="100%"
        fontWeight="600"
        style={{
          color: "#656565",
          textAlign: "center",
          marginTop: "20px"
        }}
      >
        {t("onboarding.already_have_account_title", { name: getCompanyName() })}
        <a href={`/login${returnTo || ""}`}>
          <Text
            fontSize={14}
            width="100%"
            fontWeight={600}
            style={{
              display: "inline",
              color: "#0e50b5",
              width: "100%",
              marginTop: "20px",
              fontFamily: "Poppins",
              fontStyle: "normal"
            }}
          >
            {` ${t("global.login")}`}
          </Text>
        </a>
      </Text>
    </Footer>
  );
};

export default OnboardingFlowStep;
