const countryPath = country => {
  return country?.featured ? `/${country.slug}` : `/job?page=1&filters[country_id]=${country.id}`;
};

const townPath = town => {
  if (town.path) return town.path;

  const path = town?.slug ? `/job/${town.slug}` : "/job?page=1";
  if (town?.fuzu_country_featured) {
    return `/${town.fuzu_country_slug}${path}`;
  }

  return path;
};

const pathWithCountry = (country, path) => {
  if (!country) return path;
  if (!country.featured) return path;

  return `/${country.slug}${path}`;
};

const jobPathWithUser = (user, job) => {
  if (!user?.id) {
    return job.path;
  }

  return `/job?filters[job_id]=${job.id}`;
};

const jobPath = job => {
  const prefix = job.country?.featured ? `/${job.country?.slug}` : "";

  return `${prefix}/jobs/${job.slug}`;
};

/*
const normalizePathWithCountryAndLanguage = (paths, countryPaths) => {
  return countryPaths
    ?.map(({ slug }) => [
      `/${slug}`,
      `/${slug}/employers`,
      `/${slug}/employers/:type`,
      "/companies/:type",
      "/employers/:type",
      "/nigeria/career-hero",
      "/nigeria/career-hero/:token",
      "remove-my-data/:token"
    ])
    .flat() || [];
};
*/

export { countryPath, pathWithCountry, townPath, jobPathWithUser, jobPath };
