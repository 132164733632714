import React from "react";
import { CheckCircle, ErrorCircle } from "@styled-icons/boxicons-solid";

import { applicationTypes } from "b2c/constants/jobSearch";
import { Title, Text, Button } from "b2c/components/Core";
import { getCompanyName } from "b2c/contexts/branded";
import { useTranslation } from "b2c/hooks/useTranslation";
import { JobInfoComponent, JobInfoHead, JobInfoHeadItem } from "./styled";
import { getTimeSince } from "../../../../../utils/helpers";
import { languageFormat } from "../../../../constants/main";

const JobInfo = ({ selected }) => {
  if (!selected?.application) {
    return null;
  }
  const { t, language } = useTranslation();
  const languageFormatType = languageFormat[language];

  const { application, id } = selected || {};
  const isInternal = selected?.application_type === applicationTypes.applicationTypeInternal;
  const isExternal = selected?.application_type === applicationTypes.applicationTypeExternal;
  const isAssigmentRequested = application?.fast_apply_status === applicationTypes.fastApplyAssignmentsRequested;
  const isAssigmentCompleted = application?.fast_apply_status === applicationTypes.fastApplyAssignmentsCompeted;
  const isFastApplied = application?.fast_apply_status === applicationTypes.fastApplyApplied;
  const isNotSubmitted = !selected.application.submitted;
  const left = getTimeSince(selected?.application?.complete_due_date);
  const daysLeft = Math.ceil((selected.campaign_end_date - Date.now() / 1000) / (3600 * 24));
  const expired = daysLeft < 0;
  const expiredDateText = !expired && daysLeft === 0 ? t("jobs.content.closing_today") : left;
  const formatSubmittedDate = new Date(selected.application.submitted_at).toLocaleString(languageFormatType, {
    month: "short",
    day: "numeric",
    year: "numeric"
  });

  const reviewStatusText = expired
    ? t("jobs.application_state.closed_for_applications")
    : t("jobs.application_state.open_for_applications");

  const renderContent = () => {
    /* remove it on the future
    if (isDeadline) {
      return {
        status: "Action required",
        dateText: "Assignment deadline",
        date: "3 days ago",
        reviewStatus: "Closed for applications",
        description:
          "Employer has shortlisted your profile and is requesting additional information. Unfortunately, the deadline has passed and you cannot anymore complete the actions.",
        isRequired: true
      };
    }
    */

    if (isNotSubmitted && !isAssigmentRequested) {
      return {
        status: t("jobs.application_state.in_progress.status"),
        dateText: t("jobs.application_state.in_progress.date_text"),
        date: t("jobs.application_state.in_progress.date"),
        reviewStatus: t("jobs.application_state.in_progress.review_status"),
        description: ""
      };
    }

    if (isInternal) {
      return {
        status: t("jobs.application_state.application_sent_internal.status"),
        dateText: t("jobs.application_state.application_sent_internal.date_text"),
        date: formatSubmittedDate,
        reviewStatus: reviewStatusText
      };
    }

    if (isFastApplied) {
      return {
        status: t("jobs.application_state.application_sent_fast_apply.status"),
        dateText: t("jobs.application_state.application_sent_fast_apply.date_text"),
        date: formatSubmittedDate,
        reviewStatus: reviewStatusText,
        description: t("jobs.application_state.application_sent_fast_apply.description", { name: getCompanyName() })
      };
    }

    if (isAssigmentRequested) {
      return {
        status: t("jobs.application_state.action_required.status"),
        dateText: t("jobs.application_state.action_required.date_text"),
        date: expiredDateText.replace("remaining", " left to complete"),
        reviewStatus: reviewStatusText,
        isRequired: true,
        description: (
          <>
            {t("jobs.application_state.action_required.description")}
            <Button.Link
              to={`/jobs/${id}/application`}
              color="#4690FF"
              fontSize="14px"
              fontWeight="400"
              lineHeight="140%"
              style={{ textDecoration: "underline", display: "block", textAlign: "left", padding: 0 }}
              theme="transparent"
              height="20px"
            >
              {`"${t("jobs.application_state.action_required.button")} >"`}
            </Button.Link>
          </>
        )
      };
    }

    if (isAssigmentCompleted) {
      return {
        status: t("jobs.application_state.application_sent.status"),
        dateText: t("jobs.application_state.application_sent.date_text"),
        date: formatSubmittedDate,
        reviewStatus: reviewStatusText,
        description: t("jobs.application_state.application_sent.description")
      };
    }

    return {
      status: t("jobs.application_state.in_progress.status"),
      dateText: t("jobs.application_state.in_progress.date_text"),
      date: t("jobs.application_state.in_progress.date"),
      reviewStatus: t("jobs.application_state.in_progress.review_status"),
      description: ""
    };
  };

  if (isExternal) return null;

  const { status, date, dateText, reviewStatus, description, isRequired } = renderContent();

  return (
    <JobInfoComponent>
      <JobInfoHead>
        <JobInfoHeadItem>
          <Title
            fontSize="14px"
            fontWeight="600"
            lineHeight="21px"
            color="#212121"
            style={{ display: "block" }}
            margin="0 0 2px 0"
          >
            {t("jobs.application_state.application_status")}
          </Title>
          <Text fontSize="14px" fontWeight="400" lineHeight="140%" color={isRequired ? "accent400" : "black0"}>
            {isRequired ? (
              <ErrorCircle width="14px" color="#C30000" style={{ display: "inline-block", margin: "0 5px 4px 0" }} />
            ) : (
              <CheckCircle width="14px" color="#018747" style={{ display: "inline-block", margin: "0 5px 4px 0" }} />
            )}
            {status}
          </Text>
        </JobInfoHeadItem>
        <JobInfoHeadItem>
          <Title
            fontSize="14px"
            fontWeight="600"
            lineHeight="21px"
            color="#212121"
            style={{ display: "block" }}
            margin="0 0 2px 0"
          >
            {dateText}
          </Title>
          <Text fontSize="14px" fontWeight="400" lineHeight="140%" color={isRequired ? "accent400" : "black0"}>
            {date}
          </Text>
        </JobInfoHeadItem>
        <JobInfoHeadItem>
          <Title
            fontSize="14px"
            fontWeight="600"
            lineHeight="21px"
            color="#212121"
            style={{ display: "block" }}
            margin="0 0 2px 0"
          >
            {t("jobs.application_state.employer_status")}
          </Title>
          <Text fontSize="14px" fontWeight="400" lineHeight="140%" color="black0">
            {reviewStatus}
          </Text>
        </JobInfoHeadItem>
      </JobInfoHead>
      {description && (
        <Text
          fontSize="14px"
          fontWeight="400"
          lineHeight="140%"
          color="black0"
          style={{ display: "block" }}
          margin="25px 0 0 0"
        >
          {description}
        </Text>
      )}
    </JobInfoComponent>
  );
};

export default JobInfo;
