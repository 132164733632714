import React, { useState } from "react";

import { useTranslation } from "b2c/hooks/useTranslation";
import { Checkbox } from "../../../../components/Core";
import {
  Modal,
  Title,
  Description,
  List,
  Warning,
  Label,
  Input,
  CheckboxLabel,
  Actions,
  Cancel,
  Delete
} from "./styled";

const DeleteAccountModal = ({ close, deleteAccount, processing }) => {
  const [value, setValue] = useState("");
  const [confirm, setConfirm] = useState(false);
  const { t } = useTranslation();

  const handleRemove = () => {
    deleteAccount().then(res => {
      if (res) {
        close();
        window.location.replace("/logout");
      }
    });
  };

  const handleChange = event => {
    setValue(event.target.value);
  };

  const toggleCheckbox = () => {
    setConfirm(prev => !prev);
  };

  const isDisableDeleteButton = value.toLowerCase() !== "delete" || !confirm || processing;

  return (
    <Modal>
      <Title>{t("settings.delete_account.confirmation.title")}</Title>
      <Description>{t("settings.delete_account.confirmation.description")}:</Description>
      <List>
        <li>{t("settings.delete_account.confirmation.list_1")}</li>
        <li>{t("settings.delete_account.confirmation.list_2")}</li>
        <li>{t("settings.delete_account.confirmation.list_3")}</li>
      </List>
      <Warning>{t("settings.delete_account.confirmation.warning")}</Warning>
      <Label>{t("settings.delete_account.confirmation.label", { name: "Delete" })}</Label>
      <Input value={value} onChange={handleChange} />

      <Checkbox
        label={<CheckboxLabel>{t("settings.delete_account.confirmation.info")}</CheckboxLabel>}
        checked={confirm}
        onChange={toggleCheckbox}
      />

      <Actions>
        <Cancel onClick={close}>{t("global.cancel")}</Cancel>
        <Delete disabled={isDisableDeleteButton} onClick={handleRemove}>
          {t("settings.delete_account.confirmation.submit_button")}
        </Delete>
      </Actions>
    </Modal>
  );
};

export default DeleteAccountModal;
