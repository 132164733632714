import React, { useRef } from "react";
import { useTranslation } from "b2c/hooks/useTranslation";

import { typeMap } from "b2c/constants/testAndSurvey";
import { useInitialFocus } from "utils/hooks";
import { Title, Card, Text, Image } from "../../../../components/Core";
import { QuestionsWrapper } from "./styled";
import AnswerOption from "../AnswerOption";

const QuestionCard = ({
  index,
  id,
  processing,
  full_image,
  title,
  type,
  answers = [],
  selected,
  setQuestionResponse
}) => {
  const questionsRef = useRef();
  const { t } = useTranslation();

  const handleMultipleOptions = e => {
    const ids = +e.target.value;
    const prev = selected.test_option_ids || [];
    setQuestionResponse({
      test_option_ids: !prev.includes(ids) ? prev.concat(ids) : prev.filter(itemId => itemId !== ids)
    });
  };

  const renderQuestionAnswerTitle = typeQuestion => {
    switch (typeQuestion) {
      case "text":
        return t("test_and_survey.types.text");
      case "option":
        return t("test_and_survey.types.option");
      case "checkbox":
        return t("test_and_survey.types.checkbox");

      default:
        return null;
    }
  };

  if (index === 0) useInitialFocus(questionsRef);

  return (
    <Card style={{ margin: "0 -20px 20px" }} width="auto" shadow>
      <Card.Header style={{ flexDirection: "column" }}>
        <Title level="4" fontWeight={600}>
          {title}
        </Title>
        {full_image && <Image src={full_image} alt="Question image" margin="16px 0" style={{ alignSelf: "center" }} />}
      </Card.Header>
      <Card.Content>
        {type && (
          <Text id={`q-${id}-description`} margin="0 0 15px">
            {renderQuestionAnswerTitle(type)}
          </Text>
        )}
        <QuestionsWrapper
          ref={questionsRef}
          tabIndex="-1"
          role={type === "option" ? "radiogroup" : ""}
          aria-labelledby={`q-${id}-description`}
        >
          {type !== "text" && answers ? (
            answers.map(({ id: ids, description, ...rest }) => (
              <AnswerOption
                key={ids}
                id={ids}
                type={type}
                processing={processing}
                response={selected[typeMap[type]]}
                text={description}
                onChange={value => setQuestionResponse({ [typeMap[type]]: value })}
                onMultiChange={handleMultipleOptions}
                {...rest}
              />
            ))
          ) : (
            <AnswerOption
              type={type}
              processing={processing}
              onChange={value => setQuestionResponse({ [typeMap[type]]: value })}
            />
          )}
        </QuestionsWrapper>
      </Card.Content>
    </Card>
  );
};

export default QuestionCard;
