import styled from "styled-components";

export const SuccessContent = styled.div`
  background-color: #fff;
  padding-bottom: 50px;
  @media screen and (min-width: 768px) {
    padding-bottom: 100px;
  }
`;

export const Success = styled.div`
  max-width: 500px;
  margin: 0 auto;
  padding-top: 40px;
`;

export const JobPropose = styled.div`
  width: 100%;
  padding: 12px 12px 12px 14px;
  display: flex;
  background: #f5f5f5;
  border-radius: 8px;
  margin-bottom: 24px;
  align-items: center;
`;

export const Content = styled.div`
  max-width: 500px;
  margin: 0 auto;
  padding-top: 40px;
`;

export const TextContent = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

export const ButtonContent = styled.div`
  margin-top: 24px;
  @media screen and (min-width: 768px) {
    display: flex;
  }
`;

export const SlideContent = styled.div`
  max-width: 1360px;
  margin: 40px auto 0;
  @media screen and (min-width: 768px) {
    margin-top: 70px;
  }
`;

export const DidYouKnow = styled.div`
  margin-top: 12px;
  margin-left: 12px;
  @media screen and (min-width: 768px) {
    display: flex;
  }
`;
