import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";

import student from "@images/b2c/onboarding/onboarding-seniority-student.png";
import junior from "@images/b2c/onboarding/onboarding-seiority-junior.png";
import professional from "@images/b2c/onboarding/onboarding-seiority-experienced.png";
import manager from "@images/b2c/onboarding/onboarding-seiority-manager.png";
import { Card, Checkbox, Image, Label, Text } from "../../../Core";

const imageMapper = {
  "Junior professional": junior,
  "Student or recent graduate": student,
  "Experienced professional": professional,
  "Manager or executive": manager
};

const SeniorityLevel = ({ list }) => {
  const [selected, setSelected] = useState({});

  useEffect(() => {
    if (list) {
      const onboardingForm = JSON.parse(localStorage.getItem("onboardingForm"));
      const selectedLevel = list?.find(item => String(item?.id) === String(onboardingForm?.seniorityLevel));
      if (selectedLevel) {
        setSelected(selectedLevel);
      }
    }
  }, [list]);

  const handleSelect = event => {
    const { value } = event.target;
    const findJob = list?.find(item => item?.id === +value);

    setSelected(findJob);
  };

  return (
    <Card theme="grey100">
      <Card.Content>
        {list?.length
          ? list.map(({ id, title, description }, index) => {
              return index ? (
                <Checkbox
                  key={id}
                  id={id}
                  name="job"
                  value={id}
                  borderedView
                  margin="6px 0"
                  onChange={handleSelect}
                  checked={selected?.id === id}
                  markColor="white"
                  checkedColor="secondary200"
                  checkedBgColor="secondary100"
                  labelProps={{
                    style: {
                      flexDirection: "row-reverse",
                      justifyContent: "space-between",
                      borderColor: selected?.id === id ? "#4690FF" : "transparent",
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.08)",
                      padding: "8px"
                    }
                  }}
                  checkboxMarkProps={{
                    style: {
                      borderRadius: "50%"
                    }
                  }}
                  style={{
                    outline: "none"
                  }}
                  label={
                    <Label
                      theme="transparent"
                      Icon={
                        <Image
                          src={imageMapper[title] || student}
                          borderRadius="100%"
                          style={{
                            maxWidth: "48px",
                            maxHeight: "48px",
                            marginRight: "8px"
                          }}
                        />
                      }
                      fontSize="14px"
                      lineHeight="21px"
                      fontWeight={600}
                      margin="0"
                      padding="0 15px 0 0"
                    >
                      <span>
                        {title}
                        <Text>{description}</Text>
                      </span>
                    </Label>
                  }
                />
              ) : null;
            })
          : null}
      </Card.Content>
    </Card>
  );
};

export default observer(SeniorityLevel);
