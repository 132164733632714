import React from "react";
import { observer } from "mobx-react";
import { useScreenClass } from "react-grid-system";

import circleBG_white from "images/b2c/circleBG_white.png";
import Layout from "b2c/components/Layout";
import { Title } from "b2c/components/Core";
import CarouselSection from "b2c/components/Section/CarouselSection";
import { useTranslation } from "b2c/hooks/useTranslation";
import PriceCard from "../PriceCard";
import useStore from "../../../../contexts/store";

const CareerCoachingPricing = ({ targetRef }) => {
  const { productDetails, getCoachingPackages } = useStore("Payment");
  const screen = useScreenClass();
  const isSm = ["xs", "sm"].includes(screen);
  const { t } = useTranslation();

  return (
    <>
      <Layout.Section style={{ background: `url(${circleBG_white})` }} padding="30px 0 50px">
        <Layout.Content tag="div" ref={targetRef}>
          <CarouselSection
            onSectionMount={getCoachingPackages}
            columns={2}
            Header={
              <Title
                loaded
                level="2"
                fontWeight={600}
                margin="0 auto 15px"
                textAlign="center"
                style={{ fontSize: isSm ? 20 : 36 }}
              >
                {t("career_coaching.packages.title")}
              </Title>
            }
            settings={{ variableWidth: false, infinite: true }}
            slidePadding="5px"
          >
            {(productDetails?.career_coaching_packages || [...new Array(2).keys()]).map((pack, index) => (
              <PriceCard key={`pack-${index}`} loading={!productDetails?.career_coaching_packages} {...pack} />
            ))}
          </CarouselSection>
        </Layout.Content>
      </Layout.Section>
    </>
  );
};

export default observer(CareerCoachingPricing);
