import React, { useState, useEffect } from "react";
import { Trash } from "@styled-icons/boxicons-regular";

import { useTranslation } from "b2c/hooks/useTranslation";
import Text from "../Core/Text";
import Button from "../Core/Button";
import TypeArea from "../CommentTypeArea";
import { MessageWrapper, MessageActions, MessageDetails, UserAvatar, UserInfo } from "./styled";
import { FORUM_COMMENT_ACTIONS, FORUM_COMMENT_STATUSES } from "../../../constants/forums";
import { buildImageUrlS3 } from "../../contexts/branded";
import { nameImages } from "../../constants/images";

const CommentMessage = ({
  status = "",
  userAvatar,
  avatarURL,
  authorName = "Author Name",
  postingTime,
  messageText = "Message Text",
  threadMessage = false,
  authored = false,
  removable = false,
  onReply,
  onUpdate,
  onRemove,
  children,
  isScrollTo,
  commentRef
}) => {
  const [typeAreaAction, setTypeAreaAction] = useState("");
  const avatar = userAvatar || buildImageUrlS3(nameImages.avatarEmpty);
  const updatedAvatarUrl = avatarURL || buildImageUrlS3(nameImages.avatarEmpty);
  const { t } = useTranslation();

  const onClickReplyHandle = () => {
    setTypeAreaAction(FORUM_COMMENT_ACTIONS.REPLY);
  };

  const onClickEditHandle = () => {
    setTypeAreaAction(FORUM_COMMENT_ACTIONS.EDIT);
  };

  const cancelHandle = () => {
    setTypeAreaAction("");
  };

  const onTextAreaSubmit = value => {
    typeAreaAction === FORUM_COMMENT_ACTIONS.EDIT ? onUpdate(value) : onReply(value);
  };

  if (threadMessage && status === FORUM_COMMENT_STATUSES.DELETED) {
    return null;
  }

  useEffect(() => {
    commentRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <MessageWrapper threadMessage={threadMessage} ref={isScrollTo ? commentRef : null}>
      <UserAvatar src={updatedAvatarUrl} threadMessage={threadMessage} />
      <MessageDetails>
        <UserInfo>
          <Text fontWeight="500" color="black500">
            {authorName}
          </Text>
          <i className="text-divider" />
          <Text color="black200">{postingTime}</Text>
        </UserInfo>
        {status === FORUM_COMMENT_STATUSES.DELETED ? (
          <Text lineHeight="140%" color="black200" margin="8px 0 0">
            <Trash size={18} /> {t("global.comments.comment_was_deleted")}.
          </Text>
        ) : (
          <Text lineHeight="140%" color="black500" margin="8px 0 0">
            {messageText}
          </Text>
        )}
        <MessageActions>
          {!threadMessage && (
            <Button
              noBackground
              color="secondary200"
              fontSize="12px"
              margin="8px 0"
              padding="0"
              height="auto"
              onClick={onClickReplyHandle}
            >
              {t("global.comments.reply")}
            </Button>
          )}
          {authored && (
            <>
              {!threadMessage && <i className="text-divider" />}
              <Button
                noBackground
                color="secondary200"
                fontSize="12px"
                margin="8px 0"
                padding="0"
                height="auto"
                onClick={onClickEditHandle}
              >
                {t("global.edit")}
              </Button>
            </>
          )}
          {removable && (
            <>
              <i className="text-divider" />
              <Button
                noBackground
                color="secondary200"
                fontSize="12px"
                margin="8px 0"
                padding="0"
                height="auto"
                onClick={onRemove}
              >
                {t("global.delete")}
              </Button>
            </>
          )}
        </MessageActions>
        {[FORUM_COMMENT_ACTIONS.REPLY, FORUM_COMMENT_ACTIONS.EDIT].includes(typeAreaAction) && (
          <TypeArea
            margin="0 0 10px"
            mode={typeAreaAction}
            initialValue={typeAreaAction === FORUM_COMMENT_ACTIONS.EDIT ? messageText : ""}
            onCancelClick={cancelHandle}
            replyTargetName={authorName}
            avatarURL={avatar}
            onSubmit={onTextAreaSubmit}
          />
        )}
        {children}
      </MessageDetails>
    </MessageWrapper>
  );
};

export default CommentMessage;
