import styled from "styled-components";

export const Container = styled.div`
  max-width: 480px;
  margin: 0 auto 40px auto;
  padding: 0 24px 80px 24px;

  @media screen and (min-width: 768px) {
    padding: 0;
  }
`;

export const Title = styled.h1`
  color: #333;
  font-size: 32px;
  font-weight: 500;
  line-height: 120%;
  margin-bottom: 16px;
`;

export const Description = styled.p`
  color: #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-bottom: 32px;
`;
