import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "b2c/hooks/useTranslation";

import InfoSection from "b2c/components/InfoSection";
import { withTrackedRoute } from "../../hocs";
import Layout from "../../components/Layout";
import { Title, Text, Button } from "../../components/Core";
import { useDocumentTitle } from "../../../utils/hooks";
import Chatline from "../../components/Chatline";
import { ContentQuestions } from "./styled";
import { getCompanyName } from "../../contexts/branded";
import { useIsBarona, useLanguageAndCountryUrl } from "../../hooks";

const FAQScreen = () => {
  const { t } = useTranslation();
  const isSm = /xs|sm|md/.test(screen);
  const isBarona = useIsBarona();

  const faqFuzu = [
    {
      id: 1,
      title: t("faq_fuzu.list.title_1"),
      sections: [
        {
          id: 1,
          title: t("faq_fuzu.list.sub-title_1_1"),
          text: t("faq_fuzu.list.description_1_1")
        },
        {
          id: 2,
          title: t("faq_fuzu.list.sub-title_1_2"),
          text: t("faq_fuzu.list.description_1_2")
        },
        {
          id: 3,
          title: t("faq_fuzu.list.sub-title_1_3"),
          text: t("faq_fuzu.list.description_1_3")
        },
        {
          id: 4,
          title: t("faq_fuzu.list.sub-title_1_4"),
          text: t("faq_fuzu.list.description_1_4")
        }
      ]
    },
    {
      id: 2,
      title: t("faq_fuzu.list.title_2"),
      sections: [
        {
          id: 5,
          title: t("faq_fuzu.list.sub-title_2_1"),
          text: t("faq_fuzu.list.description_2_1")
        },
        {
          id: 6,
          title: t("faq_fuzu.list.sub-title_2_2"),
          text: t("faq_fuzu.list.description_2_2")
        },
        {
          id: 7,
          title: t("faq_fuzu.list.sub-title_2_3"),
          text: t("faq_fuzu.list.description_2_3")
        },
        {
          id: 8,
          title: t("faq_fuzu.list.sub-title_2_4"),
          text: t("faq_fuzu.list.description_2_4")
        },
        {
          id: 9,
          title: t("faq_fuzu.list.sub-title_2_5"),
          text: t("faq_fuzu.list.description_2_5")
        },
        {
          id: 10,
          title: t("faq_fuzu.list.sub-title_2_6"),
          text: t("faq_fuzu.list.description_2_6")
        }
      ]
    },
    {
      id: 3,
      title: t("faq_fuzu.list.title_3"),
      sections: [
        {
          id: 1,
          title: t("faq_fuzu.list.sub-title_3_1"),
          text: t("faq_fuzu.list.description_3_1")
        },
        {
          id: 2,
          title: t("faq_fuzu.list.sub-title_3_2"),
          text: t("faq_fuzu.list.description_3_2")
        },
        {
          id: 3,
          title: t("faq_fuzu.list.sub-title_3_3"),
          text: t("faq_fuzu.list.description_3_3")
        }
      ]
    },
    {
      id: 4,
      title: t("faq_fuzu.list.title_4"),
      sections: [
        {
          id: 1,
          title: t("faq_fuzu.list.sub-title_4_1"),
          text: t("faq_fuzu.list.description_4_1")
        },
        {
          id: 2,
          title: t("faq_fuzu.list.sub-title_4_2"),
          text: t("faq_fuzu.list.description_4_2")
        },
        {
          id: 3,
          title: t("faq_fuzu.list.sub-title_4_3"),
          text: t("faq_fuzu.list.description_4_3")
        },
        {
          id: 4,
          title: t("faq_fuzu.list.sub-title_4_4"),
          text: t("faq_fuzu.list.description_4_4")
        }
      ]
    },
    {
      id: 5,
      title: t("faq_fuzu.list.title_5"),
      sections: [
        {
          id: 1,
          title: t("faq_fuzu.list.sub-title_5_1"),
          text: t("faq_fuzu.list.description_5_1")
        },
        {
          id: 2,
          title: t("faq_fuzu.list.sub-title_5_2"),
          text: t("faq_fuzu.list.description_5_2")
        },
        {
          id: 3,
          title: t("faq_fuzu.list.sub-title_5_3"),
          text: t("faq_fuzu.list.description_5_3")
        },
        {
          id: 4,
          title: t("faq_fuzu.list.sub-title_5_4"),
          text: t("faq_fuzu.list.description_5_4")
        },
        {
          id: 5,
          title: t("faq_fuzu.list.sub-title_5_5"),
          text: t("faq_fuzu.list.description_5_5")
        },
        {
          id: 6,
          title: t("faq_fuzu.list.sub-title_5_6"),
          text: t("faq_fuzu.list.description_5_6")
        },
        {
          id: 7,
          title: t("faq_fuzu.list.sub-title_5_7"),
          text: t("faq_fuzu.list.description_5_7")
        },
        {
          id: 8,
          title: t("faq_fuzu.list.sub-title_5_8"),
          text: t("faq_fuzu.list.description_5_8")
        }
      ]
    },
    {
      id: 6,
      title: t("faq_fuzu.list.title_6"),
      sections: [
        {
          id: 1,
          title: t("faq_fuzu.list.sub-title_6_1"),
          text: t("faq_fuzu.list.description_6_1")
        },
        {
          id: 2,
          title: t("faq_fuzu.list.sub-title_6_2"),
          text: t("faq_fuzu.list.description_6_2")
        },
        {
          id: 3,
          title: t("faq_fuzu.list.sub-title_6_3"),
          text: t("faq_fuzu.list.description_6_3")
        },
        {
          id: 4,
          title: t("faq_fuzu.list.sub-title_6_4"),
          text: t("faq_fuzu.list.description_6_4")
        },
        {
          id: 5,
          title: t("faq_fuzu.list.sub-title_6_5"),
          text: t("faq_fuzu.list.description_6_5")
        },
        {
          id: 6,
          title: t("faq_fuzu.list.sub-title_6_6"),
          text: t("faq_fuzu.list.description_6_6")
        },
        {
          id: 7,
          title: t("faq_fuzu.list.sub-title_6_7"),
          text: t("faq_fuzu.list.description_6_7")
        }
      ]
    }
  ];

  const baronaFaq = [
    {
      id: 1,
      title: t("faq.list.title_1"),
      sections: [
        {
          id: 1,
          title: t("faq.list.sub-title_1_1"),
          text: t("faq.list.description_1_1")
        },
        {
          id: 2,
          title: t("faq.list.sub-title_1_2"),
          text: t("faq.list.description_1_2")
        },
        {
          id: 3,
          title: t("faq.list.sub-title_1_3"),
          text: t("faq.list.description_1_3")
        },
        {
          id: 4,
          title: t("faq.list.sub-title_1_4"),
          text: t("faq.list.description_1_4")
        }
      ]
    },
    {
      id: 2,
      title: t("faq.list.title_2"),
      sections: [
        {
          id: 5,
          title: t("faq.list.sub-title_2_1"),
          text: t("faq.list.description_2_1")
        },
        {
          id: 6,
          title: t("faq.list.sub-title_2_2"),
          text: t("faq.list.description_2_2")
        },
        {
          id: 7,
          title: t("faq.list.sub-title_2_3"),
          text: t("faq.list.description_2_3")
        },
        {
          id: 8,
          title: t("faq.list.sub-title_2_4"),
          text: t("faq.list.description_2_4")
        },
        {
          id: 8,
          title: t("faq.list.sub-title_2_5"),
          text: t("faq.list.description_2_5")
        }
      ]
    },
    {
      id: 3,
      title: t("faq.list.title_3"),
      sections: [
        {
          id: 1,
          title: t("faq.list.sub-title_3_1"),
          text: t("faq.list.description_3_1")
        },
        {
          id: 2,
          title: t("faq.list.sub-title_3_2"),
          text: t("faq.list.description_3_2")
        },
        {
          id: 3,
          title: t("faq.list.sub-title_3_3"),
          text: t("faq.list.description_3_3")
        }
      ]
    },
    {
      id: 4,
      title: t("faq.list.title_4"),
      sections: [
        {
          id: 1,
          title: t("faq.list.sub-title_4_1"),
          text: t("faq.list.description_4_1")
        },
        {
          id: 2,
          title: t("faq.list.sub-title_4_2"),
          text: t("faq.list.description_4_2")
        },
        {
          id: 3,
          title: t("faq.list.sub-title_4_3"),
          text: t("faq.list.description_4_3")
        },
        {
          id: 4,
          title: t("faq.list.sub-title_4_4"),
          text: t("faq.list.description_4_4")
        }
      ]
    },
    {
      id: 5,
      title: t("faq.list.title_5"),
      sections: [
        {
          id: 1,
          title: t("faq.list.sub-title_5_1"),
          text: t("faq.list.description_5_1")
        },
        {
          id: 2,
          title: t("faq.list.sub-title_5_2"),
          text: t("faq.list.description_5_2")
        },
        {
          id: 3,
          title: t("faq.list.sub-title_5_3"),
          text: t("faq.list.description_5_3")
        },
        {
          id: 4,
          title: t("faq.list.sub-title_5_4"),
          text: t("faq.list.description_5_4")
        },
        {
          id: 5,
          title: t("faq.list.sub-title_5_5"),
          text: t("faq.list.description_5_5")
        },
        {
          id: 6,
          title: t("faq.list.sub-title_5_6"),
          text: t("faq.list.description_5_6")
        },
        {
          id: 7,
          title: t("faq.list.sub-title_5_7"),
          text: t("faq.list.description_5_7")
        },
        {
          id: 8,
          title: t("faq.list.sub-title_5_8"),
          text: t("faq.list.description_5_8")
        }
      ]
    }
  ];

  const faqDataList = isBarona ? baronaFaq : faqFuzu;
  const languageAndLanguage = useLanguageAndCountryUrl();

  useDocumentTitle(`${t("faq.meta_title")} | ${getCompanyName()}`);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout.Content size="xl" style={{ margin: isSm ? "24px auto 0" : "64px auto 0" }}>
      <Title level="2" fontWeight="600">
        {t("faq.title")}
      </Title>
      <Text fontSize={16} margin="8px 0 20px 0">
        {t("faq.description")}
        <Button.Link
          to={`${languageAndLanguage}/contact`}
          theme="transparent"
          color="secondary200"
          style={{ textDecoration: "underline", fontWeight: "400", padding: "0 0 0 4px" }}
          height="auto"
        >
          {t("faq.button")}
        </Button.Link>
      </Text>
      <ContentQuestions>
        <InfoSection data={faqDataList} />
      </ContentQuestions>
      <Chatline />
    </Layout.Content>
  );
};

export default withTrackedRoute(observer(FAQScreen));
