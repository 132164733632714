import React from "react";

import { Card, Spinner } from "../../../../components/Core";

const Updating = () => {
  return (
    <Card
      wideOnMobile
      shadow
      style={{
        height: 400,
        width: "100%",
        display: "flex"
      }}
    >
      <Spinner size="75px" margin="auto" />
    </Card>
  );
};

export default Updating;
