import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Route, Switch } from "react-router-dom";

import { withTrackedRoute } from "../../hocs";
import Article from "../ArticleScreen";
import useStore from "../../contexts/store";
import ForumWithLoading from "./components/ForumWithLoading";
import { getCompanyName } from "../../contexts/branded";

const Forum = ({ match: { url }, ...rest }) => {
  const {
    state: { loading },
    sections,
    getArticleCategories
  } = useStore("BlogLayout");

  const { article } = useStore("Article");

  const companyName = getCompanyName();
  useEffect(() => {
    document.title = article?.meta_title || `${article?.headline ? `${article?.headline} | ` : ""}${companyName}`;
  }, [companyName, article?.meta_title]);

  return (
    <Switch>
      <Article exact path={`${url}/article/:id`} />

      <Route path={url}>
        <ForumWithLoading
          loading={loading}
          sections={sections}
          getArticleCategories={getArticleCategories}
          url={url}
          {...rest}
        />
      </Route>
    </Switch>
  );
};

export default withTrackedRoute(observer(Forum));
