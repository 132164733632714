import Visa from "images/b2c/payment/visa_logo.svg";
import MasterCard from "images/b2c/payment/mc_logo.svg";
import AmEx from "images/b2c/payment/amex_logo.svg";
import Jcb from "images/b2c/payment/jcb_logo.svg";
import MobileIcon from "images/b2c/icons/mobile.svg";
import BankTransferIcon from "images/b2c/icons/bank_transfer.svg";

export const cardNetworks = [
  { name: "Visa", Logo: Visa },
  { name: "MasterCard", Logo: MasterCard },
  { name: "AmEx", Logo: AmEx },
  { name: "JCB", Logo: Jcb }
];

export const methods = {
  mobile: { Icon: MobileIcon, name: "Mobile payment" },
  bank_transfer: { Icon: BankTransferIcon, name: "Bank Transfer" }
};
