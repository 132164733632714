import React from "react";

import { useTranslation } from "b2c/hooks/useTranslation";
import { useModal } from "../../../../../utils/hooks";
import DeleteAccountModal from "../DeleteAccountModal";
import { Tile, TileButton, TileDescription, TileTitle } from "../../styled";

const DeleteAccountSection = ({ processing, deleteAccount }) => {
  const openDeleteAccountModal = useModal(
    <DeleteAccountModal processing={processing} deleteAccount={deleteAccount} />,
    ""
  );
  const { t } = useTranslation();

  return (
    <Tile>
      <TileTitle $isError>{t("settings.delete_account.title")}</TileTitle>
      <TileDescription>{t("settings.delete_account.description")}</TileDescription>
      <TileButton $isError onClick={openDeleteAccountModal}>
        {t("settings.delete_account.delete_account_button")}
      </TileButton>
    </Tile>
  );
};

export default DeleteAccountSection;
