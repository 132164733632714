import styled from "styled-components";

export const TimelineWrapper = styled.section`
  padding: 65px 0;
  background-color: white;
`;

export const Timeline = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1100px;
  margin: 0 auto;
  width: 100%;
`;

export const TimelineLeft = styled.div`
  width: 100px;
  flex: 0 0 100px;
  @media screen and (max-width: 767px) {
    position: absolute;
    left: 30px;
    top: 0;
  }
`;

export const TimelineContent = styled.div`
  border-left: 1px solid ${({ bulletTheme }) => bulletTheme};
  padding: 0 0 60px 40px;
  position: relative;
  @media screen and (max-width: 767px) {
    padding: 35px 0 30px 30px;
  }
  &::after {
    content: "";
    position: absolute;
    left: -16px;
    top: 0;
    display: flex;
    width: 32px;
    height: 32px;
    background-color: ${({ bulletTheme }) => bulletTheme};
    border: 1px solid ${({ bulletTheme }) => bulletTheme};
    box-shadow: inset 0 0 0 6px white;
    border-radius: 50%;
  }
`;

export const TimelineRow = styled.div`
  display: flex;
  position: relative;
  @media screen and (max-width: 767px) {
    margin-left: 15px;
  }
  &:last-child {
    ${TimelineContent} {
      border-left: none;
      padding-bottom: 0;
    }
  }
`;
