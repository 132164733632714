import styled from "styled-components";

export const Container = styled.div`
  max-width: 480px;
  margin: 0 auto 32px auto;
  padding: 0 24px;

  @media screen and (min-width: 768px) {
    padding: 0;
  }
`;

export const StepsLine = styled.div`
  height: 6px;
  background: ${({ $background }) => $background};
  width: ${({ $width }) => $width};
`;
