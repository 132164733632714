import styled from "styled-components";
import { Link } from "react-router-dom";

export const B2bOfferSectionContainer = styled.div`
  background-color: #fff;
  padding: 48px 16px;
  text-align: center;

  border-top: 1px solid #e4e4e4;

  @media screen and (min-width: 1008px) {
    padding: 64px 16px;
  }
`;

export const Title = styled.h2`
  color: #292929;
  text-align: center;
  font-size: 28px;
  font-weight: 600;
  line-height: 54px;
  margin: 0 0 24px 0;

  @media screen and (min-width: 1000px) {
    font-size: 36px;
    font-weight: 500;
  }
`;

export const LinkTo = styled(Link)`
  border-radius: 5px;
  background: #2496f5;
  border: none;
  outline: none;
  margin: 0;
  padding: 12px;
  text-align: center;
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.2px;
  text-decoration: none;
`;
