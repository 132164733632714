import React, { useEffect } from "react";

import logoPlaceholder from "images/b2c/logo-placeholder.png";
import { Card, Image } from "b2c/components/Core";
import Company from "b2c/components/Company";
import CompanyContent from "../CompanyContent";

const CompanyPage = ({ id, ...rest }) => {
  useEffect(() => {
    if (window.scrollY) {
      window.scroll(0, 0);
    }
  }, [id]);

  return (
    <Company
      company_id={id}
      shadow
      wideOnMobile
      showPositions
      Header={({ industry_background: industryBackground, logo, Icon }) => (
        <Card.Header
          className="card-header_company-page entity-page_company_header"
          height={320}
          backgroundImage={industryBackground}
          style={{ backgroundPosition: "center center" }}
        >
          {logo ? (
            <Image src={logo} placeholder={logoPlaceholder} width={110} height={110} objectFit="contain" />
          ) : (
            <Icon width={110} height={110} />
          )}
        </Card.Header>
      )}
      Content={CompanyContent}
      {...rest}
    />
  );
};

export default CompanyPage;
