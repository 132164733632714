import React, { useContext } from "react";

import ThemeContext from "../../../../contexts/theme";
import { Container, StepsLine } from "./styled";

const Steps = ({ width }) => {
  const theme = useContext(ThemeContext);

  return (
    <Container>
      <StepsLine $background={theme.primaryGradient} $width={width} />
    </Container>
  );
};

export default Steps;
