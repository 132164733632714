import React, { useEffect } from "react";
import { Col, Row } from "react-grid-system";
import { observer } from "mobx-react";

import { useTranslation } from "b2c/hooks/useTranslation";
import BrandsSection from "b2c/components/BrandsSection";
import LinkWithFullUrl from "components/LinkWithFullUrl";
import { pathWithCountry } from "b2c/utils/routes";
import useStore from "b2c/contexts/store";
import { useGetCurrentCountry } from "b2c/hooks";
import { Button } from "../Core";

const HireEmployers = ({ showFuzuAppText, padding = "100px 0 30px", isLanding, companies }) => {
  const { trendingCompanies, getTrendingCompanies, setTrendingCompanies } = useStore("Landing");
  const { t } = useTranslation();
  const country = useGetCurrentCountry();
  const countryName = country?.slug ? country?.name : t("landing_fuzu.africa");
  const isNigeria = country?.slug === "nigeria";

  const renderTitle = () => {
    if (showFuzuAppText) {
      return t("landing_fuzu.hire.title_1");
    }
    if (isNigeria) {
      return t("landing_fuzu.hire.title_2");
    }

    return `${t("landing_fuzu.hire.title_3")} ${countryName}`;
  };

  useEffect(() => {
    if (!isLanding) {
      getTrendingCompanies(country?.id);
    }
  }, [country?.id]);

  useEffect(() => {
    if (isLanding && companies?.length) {
      setTrendingCompanies(companies);
    }
  }, [country?.id]);

  return (
    <BrandsSection
      title={renderTitle()}
      padding={padding}
      companies={trendingCompanies?.slice(0, 12)}
      actionButton={
        <Row justify="center">
          <Col lg={2} sm={10} xs={10}>
            <Button.Link as={LinkWithFullUrl} arrow width="100%" to={pathWithCountry(country, "/job")} margin="4px 0">
              {t("landing_fuzu.hire.find_work_button")}
            </Button.Link>
          </Col>
          <Col lg={2} sm={10} xs={10}>
            <Button.Link
              as={LinkWithFullUrl}
              arrow
              theme="black500"
              width="100%"
              to={pathWithCountry(country, "/employers")}
              margin="4px 0"
            >
              {t("landing_fuzu.hire.find_talent_button")}
            </Button.Link>
          </Col>
        </Row>
      }
    />
  );
};

export default observer(HireEmployers);
