import React, { useContext } from "react";
import { Row, Col, ScreenClassContext } from "react-grid-system";

import { useTranslation } from "b2c/hooks/useTranslation";
import promoImage from "images/b2c/service/promo_image.png";
import Layout from "../../../../components/Layout";
import { Title, Text, Image } from "../../../../components/Core";
import { PromoSectionWrapper } from "./styled";

const PromoSection = () => {
  const screen = useContext(ScreenClassContext);
  const isSm = ["xs", "sm"].includes(screen);
  const { t } = useTranslation();

  return (
    <PromoSectionWrapper>
      <Layout.Content tag="div">
        <Row align="center">
          <Col md={6}>
            <Title
              level="2"
              style={{ fontSize: isSm ? 22 : 32 }}
              color="white"
              fontWeight={600}
              margin="0 0 20px"
              width="100%"
            >
              {t("about_fuzu.title")}
            </Title>
            <Text fontSize={isSm ? 16 : 26} lineHeight="140%" color="white" width="100%">
              {t("about_fuzu.description")}
            </Text>
          </Col>
          <Col md={6}>
            <Image src={promoImage} />
          </Col>
        </Row>
      </Layout.Content>
    </PromoSectionWrapper>
  );
};

export default PromoSection;
