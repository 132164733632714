import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Row, Col, ScreenClassContext } from "react-grid-system";
import { useTranslation } from "b2c/hooks/useTranslation";

import { thousandSeparator } from "utils/helpers";
import baronaMobile from "images/b2c/landing-page/baronaMobile.jpg";
import baronaDesctop from "images/b2c/landing-page/baronaDesktop.jpg";
import Layout from "b2c/components/Layout";
import { Title } from "b2c/components/Core";

import HeroItem from "../HeroItem";
import { Wrapper } from "./styled";

const HeroSectionBarona = ({ jobsCount }) => {
  const screen = useContext(ScreenClassContext);
  const isSm = ["xs", "sm"].includes(screen);
  const { t } = useTranslation();

  return (
    <Wrapper backgroundImage={isSm ? baronaMobile : baronaDesctop}>
      <Layout.Content tag="div" size="lg">
        <Row justify="between">
          <Col
            lg={7}
            sm={12}
            style={{
              minHeight: isSm ? 240 : 374,
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
              padding: 0
            }}
          >
            <Title
              level={1}
              textAlign={isSm ? "center" : "left"}
              fontSize={isSm ? 60 : 90}
              margin="auto 0"
              lineHeight="100%"
              fontWeight="400"
              color="white"
              width="100%"
              style={{
                fontFamily: "Originals2",
                fontStyle: "italic"
              }}
            >
              {t("landing.title")}
            </Title>
          </Col>
          <Col
            lg={4}
            sm={12}
            style={{
              display: "flex",
              justifyItems: "center",
              flexDirection: "column",
              maxHeight: 432
            }}
          >
            <HeroItem
              IconComponent={null}
              title={t("landing.hero.title_1")}
              label={t("landing.hero.description_1")}
              count={thousandSeparator(jobsCount)}
              type="job"
              style={{ margin: "auto 0 16px" }}
              as={Link}
              to="/job"
            />

            <HeroItem
              IconComponent={null}
              title={t("landing.hero.title_2")}
              label={t("landing.hero.description_2")}
              type="learn"
              count="382"
              as={Link}
              to="/learn"
            />

            <HeroItem
              IconComponent={null}
              title={t("landing.hero.title_3")}
              label={t("landing.hero.description_3")}
              count={t("landing.hero.count_3")}
              type="join"
              style={{ margin: "0 0 auto" }}
              as={Link}
              to="/onboarding"
            />
          </Col>
        </Row>
      </Layout.Content>
    </Wrapper>
  );
};

export default HeroSectionBarona;
