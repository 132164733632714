import { observer } from "mobx-react";
import React, { useLayoutEffect } from "react";
import { Col, Row } from "react-grid-system";
import { Link, useLocation } from "react-router-dom";

import getIndustryStyles from "utils/industryImageMap";
import { useTranslation } from "b2c/hooks/useTranslation";
import useStore from "../../contexts/store";
import LinkWithFullUrl from "../../../components/LinkWithFullUrl";
import { useDocumentTitle } from "../../../utils/hooks";
import {
  getCompanyName,
  isCustomJkuat,
  isWhitelabelled,
  isWhitelabelledOrCustomBranded,
  WhitelabelledOrCustomHidden,
  isKepsa
} from "../../contexts/branded";
import { useIsBarona, useLanguageAndCountryUrl, useTriggerModalScroll } from "../../hooks";
import { countryPath, jobPathWithUser, pathWithCountry, townPath } from "../../utils/routes";
import Accessibilities from "../Accessibilities";
import { Card, Image, Spinner, Suggestion, Text, Title } from "../Core";
import CompaniesSection from "../FeedSections/CompaniesSection";
import JobItem from "../FeedSections/JobsSection/JobsItem";
import Layout from "../Layout";
import Post from "../Post";
import PremiumBanner from "../PremiumBanner";
import Section from "../Section";
import { GuestHidden } from "../WithAuth";
import { DescriptionText, DetailSection, Label, Value, ValueAnchor, ValueBlue, ValueText } from "./styled";

const Company = ({
  job_id,
  company_id,
  Header = DefaultHeader,
  Content = DefaultContent,
  showPositions = false,
  showSimilar = true,
  ...cardProps
}) => {
  const { handleSaving } = useStore("Saved");
  const {
    company_details: {
      position_id,
      id,
      industries = [],
      name,
      banner,
      logo,
      location,
      website,
      phone_number,
      employer_size,
      facebook,
      twitter,
      description,
      accessibilities,
      jobs,
      articles,
      address,
      social_links: socialLinks = []
    } = {},
    getCompanyDetails
  } = useStore("JobSearch");
  const { pathname } = useLocation();
  const showPromo = !jobs?.some(job => job?.brand_tag === "Fuzu") && pathname !== "/";
  const { triggerContent } = useTriggerModalScroll(true);
  const isBarona = useIsBarona();
  const { t } = useTranslation();
  const isKepsaBrand = isKepsa();
  const { background: industry_background, Icon } = getIndustryStyles(
    industries.length > 0 ? industries[0].name : null
  );

  useLayoutEffect(() => {
    getCompanyDetails(job_id, company_id, isKepsaBrand);
  }, [company_id, job_id]);

  useDocumentTitle(name ? `${name} ${t("company.meta_title")} | ${getCompanyName()}` : null);

  const websiteLinks = socialLinks.filter(link => link.social_type === "personal_website");
  const socialMediaLinks = socialLinks.filter(link => link.social_type !== "personal_website");

  const contentProps = {
    name,
    jobs,
    articles,
    location,
    website,
    phone_number,
    employer_size,
    facebook,
    twitter,
    industries,
    accessibilities,
    description,
    address,
    handleSaving,
    socialMediaLinks,
    websiteLinks
  };

  const linkToEmployersPage = ["KE", "NG", "UG"].includes(address?.country?.country_code)
    ? pathWithCountry(address?.country, "/employers")
    : "/employers";

  return (
    <>
      <Layout.Content
        size="xl"
        id="main"
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "24px 20px 50px"
        }}
      >
        {String(job_id) !== String(position_id) || !id ? (
          <Card {...cardProps}>
            <Spinner size="50px" margin="248px auto" />
          </Card>
        ) : (
          <>
            <Card {...cardProps}>
              <Header
                industry_background={banner || industry_background}
                Icon={Icon}
                logo={logo}
                backgroundSize="cover"
                style={{ backgroundPosition: "center center" }}
              />
              <Content {...contentProps} />
            </Card>
            {showPositions && (
              <Company.Jobs name={name} jobs={jobs} handleSaving={handleSaving} country={address?.country} />
            )}
            <Company.Articles name={name} articles={articles} />
            <Suggestion.Email type="company" subject={industries[0]?.name || location || "Africa"} />
          </>
        )}
      </Layout.Content>
      {showSimilar && company_id && (
        <WhitelabelledOrCustomHidden>
          <Layout.Section backgroundColor="white">
            <Layout.Content size="xl" tag="div">
              <CompaniesSection
                title="Similar companies"
                company_id={company_id}
                count_limit={4}
                titleProps={{ margin: "24px 0 0" }}
              />
            </Layout.Content>
          </Layout.Section>
        </WhitelabelledOrCustomHidden>
      )}

      {showPromo && !isBarona && <PremiumBanner.EmployerLandingPromo type="company" linkTo={linkToEmployersPage} />}

      {triggerContent}
    </>
  );
};

const DefaultHeader = ({ industry_background }) => (
  <div className="view-summary">
    <Image
      src={industry_background}
      objectFit="cover"
      width="100%"
      borderRadius={5}
      maxHeight="300px"
      display="block"
    />
  </div>
);

const DefaultContent = ({ name, jobs, articles, handleSaving, ...details }) => <Company.Content {...details} />;

Company.Content = ({
  // location,
  industries,
  employer_size,
  phone_number,
  accessibilities,
  description,
  address,
  socialMediaLinks,
  websiteLinks
}) => {
  const whitelabelledOrCustomBranded = isWhitelabelledOrCustomBranded();
  const townName = address?.town_record?.name;
  const countryName = address?.country?.name;
  const whitelabelled = isWhitelabelled();
  const customJkuat = isCustomJkuat();
  const { t } = useTranslation();
  const languageAndLanguage = useLanguageAndCountryUrl();

  const renderValueIndustry = () => {
    if (customJkuat) {
      return ValueText;
    }
    if (whitelabelledOrCustomBranded) {
      return ValueBlue;
    }

    return Value;
  };

  return (
    <>
      <Card bordered style={{ margin: "8px 0" }}>
        <Card.Content padding="16px 0">
          <Row style={{ margin: "0 0 -16px 0" }}>
            {(address?.country && whitelabelled) || websiteLinks.length > 0 ? (
              <Col sm={4}>
                {address?.country && !whitelabelled && (
                  <DetailSection>
                    <Label>{t("company.location")}</Label>
                    <ValueBlue>
                      {countryName && (
                        <>
                          {customJkuat ? (
                            <ValueText>
                              {countryName}
                              {`${townName ? "," : ""} `}
                            </ValueText>
                          ) : (
                            <Value to={countryPath(address.country)}>
                              {countryName}
                              {`${townName ? "," : ""} `}
                            </Value>
                          )}
                        </>
                      )}
                      {townName && (
                        <>
                          {customJkuat ? (
                            <ValueText>{townName}</ValueText>
                          ) : (
                            <Value to={townPath(address.town_record)}>{townName}</Value>
                          )}
                        </>
                      )}
                    </ValueBlue>
                  </DetailSection>
                )}

                {websiteLinks.length > 0 && (
                  <GuestHidden>
                    <DetailSection>
                      <Label>{t("company.websites")}</Label>
                      <ValueBlue>
                        {websiteLinks.map(({ id, url }) => (
                          <React.Fragment key={id}>
                            <Value
                              as="a"
                              className="link underlined"
                              color="secondary300"
                              fontWeight={500}
                              href={url}
                              maxLength={48}
                            >
                              {url}
                            </Value>
                          </React.Fragment>
                        ))}
                      </ValueBlue>
                    </DetailSection>
                  </GuestHidden>
                )}
              </Col>
            ) : null}
            {industries.length > 0 || socialMediaLinks?.length > 0 ? (
              <Col sm={4}>
                {industries.length > 0 && (
                  <DetailSection>
                    <Label>{t("company.industry")}</Label>
                    {industries.map(item => (
                      <ValueBlue key={item.id}>
                        <Text
                          className={whitelabelledOrCustomBranded ? "" : ""}
                          as={renderValueIndustry()}
                          to={
                            languageAndLanguage
                              ? `${languageAndLanguage}/job/${item.slug}`
                              : pathWithCountry(address?.country, `/job/${item.slug}`)
                          }
                          color={whitelabelledOrCustomBranded ? "black200" : "secondary300"}
                          maxLength={48}
                          fontWeight={500}
                        >
                          {item.name}
                        </Text>
                      </ValueBlue>
                    ))}
                  </DetailSection>
                )}
                {socialMediaLinks?.length > 0 && (
                  <GuestHidden>
                    <DetailSection>
                      <Label>{t("company.social_media")}</Label>
                      {socialMediaLinks.map(({ id, url }) => (
                        <ValueBlue key={id} rel="noopener noreferrer" target="_blank">
                          <ValueAnchor href={url}>{url}</ValueAnchor>
                        </ValueBlue>
                      ))}
                    </DetailSection>
                  </GuestHidden>
                )}
              </Col>
            ) : null}

            {employer_size?.name || phone_number ? (
              <Col sm={4}>
                {employer_size?.name && (
                  <DetailSection>
                    <Label>{t("company.company_size")}</Label>
                    <ValueText>{employer_size?.name}</ValueText>
                  </DetailSection>
                )}
                {phone_number && (
                  <GuestHidden>
                    <DetailSection>
                      <Label>{t("company.contact_info")}</Label>
                      <ValueText>{phone_number}</ValueText>
                    </DetailSection>
                  </GuestHidden>
                )}
              </Col>
            ) : null}
          </Row>
        </Card.Content>
      </Card>
      <div className="view-summary">
        <Title scales level={5} fontWeight={600} margin="8px 0">
          {t("company.about")}
        </Title>
        {description && (
          <div className="view-summary-content">
            <DescriptionText>{description || ""}</DescriptionText>
          </div>
        )}
      </div>
      {!!accessibilities && Object.keys(accessibilities).length > 0 && (
        <div className="view-summary">
          <Title scales level={5} fontWeight={600} margin="8px 0">
            {t("company.accessibility")}
          </Title>
          <div className="view-summary-content">
            <Accessibilities accesibilities={accessibilities} />
          </div>
        </div>
      )}
    </>
  );
};

Company.Jobs = ({ name, title, jobs = [], handleSaving, country }) => {
  const { user } = useStore("User");
  const isWhitelabelledOrCustom = isWhitelabelledOrCustomBranded();
  const { t } = useTranslation();

  return (
    <Section
      style={{ marginTop: "25px" }}
      columns={4}
      Header={
        jobs?.length ? (
          <Title scales level={5} fontWeight={600} margin="0 0 18px 0">
            {title ? (
              <>{title}</>
            ) : (
              <>
                {" "}
                {`${t("company.jobs_at")} `} {name}
              </>
            )}
          </Title>
        ) : (
          <></>
        )
      }
      Empty={
        isWhitelabelledOrCustom ? (
          <></>
        ) : (
          <Text margin="0 0 72px">
            {t("company.no_open_jobs", { name })}
            {country && (
              <Text
                as={isWhitelabelledOrCustom ? LinkWithFullUrl : Link}
                color="secondary300"
                fontWeight={600}
                className="link underlined"
                margin="0 0 0 8px"
                to={`/${country?.slug}/job`}
              >
                {`${t("company.view_all_jobs")} `} {country?.name}
              </Text>
            )}
          </Text>
        )
      }
    >
      {jobs.map((item, index) => (
        <JobItem
          {...item}
          saveItem={saved => handleSaving(item.id, "job", saved)}
          to={isWhitelabelledOrCustom ? pathWithCountry(country, `/jobs/${item.slug}`) : jobPathWithUser(user, item)}
          key={`job_${name}_${index}`}
          className="b2c-card_bigger-height"
          isBiggerHeight
        />
      ))}
    </Section>
  );
};

Company.Articles = ({ name, articles = [] }) => {
  const { t } = useTranslation();

  return (
    articles.length > 0 && (
      <Section
        columns={3}
        Header={
          <>
            <Title scales level={5} fontWeight={600}>
              {`${t("company.articles_about")}`} {name}
            </Title>
          </>
        }
      >
        {articles.map((item, index) => (
          <Post {...item} to={item.path} key={`article_${name}_${index}`} />
        ))}
      </Section>
    )
  );
};

export default observer(Company);
