import React, { useContext } from "react";
import { StreetView } from "@styled-icons/boxicons-regular";

import { useTranslation } from "b2c/hooks/useTranslation";
import Text from "../Core/Text";
import { AuthorInfoWrapper, Avatar, DetailsContainer, InfoRow, AvatarPlaceholder } from "./styled";
import ThemeContext from "../../contexts/theme";
import { buildImageUrlS3 } from "../../contexts/branded";
import { nameImages } from "../../constants/images";

const AuthorInfo = ({
  author,
  margin = "0 0",
  views,
  date,
  avatarImg,
  loading = false,
  countString,
  linkable = false,
  onClick,
  loaded
}) => {
  const Theme = useContext(ThemeContext);
  const avatar = avatarImg || buildImageUrlS3(nameImages.avatarEmpty);
  const { t } = useTranslation();

  return !loading ? (
    <AuthorInfoWrapper styled={{ margin }} loaded={loaded} linkable={linkable} onClick={onClick}>
      <Avatar src={avatar} />
      <DetailsContainer>
        <InfoRow>
          <Text fontWeight={700} fontSize={14} margin="0" color="black500">
            {author}
          </Text>
          {date && (
            <>
              <span className="text-divider" />
              <Text fontWeight={700} fontSize={14} margin="0" color="black500">
                {date}
              </Text>
            </>
          )}
        </InfoRow>

        <Text
          fontWeight={500}
          fontSize={14}
          margin="0"
          color="black500"
          style={{ display: "flex", alignItems: "center", lineHeight: 1 }}
        >
          <StreetView width={20} style={{ marginRight: "4px" }} />
          {views} {countString || t("global.views")}
        </Text>
      </DetailsContainer>
    </AuthorInfoWrapper>
  ) : (
    <AuthorInfoWrapper styled={{ margin }} loaded={loaded}>
      <AvatarPlaceholder theme={Theme} />
      <DetailsContainer>
        <InfoRow>
          <Text fontWeight={400} fontSize={14} margin="0" color="black500" loading />
          <span className="text-divider" />
          <Text fontWeight={400} fontSize={14} margin="0" color="black300" loading />
        </InfoRow>

        <Text fontWeight={500} fontSize={14} color="black500" margin="4px 0 0" loading />
      </DetailsContainer>
    </AuthorInfoWrapper>
  );
};

export default AuthorInfo;
