import React, { useEffect, useCallback } from "react";
import { observer } from "mobx-react";
import { useLocation } from "react-router-dom";
import { Row, Col } from "react-grid-system";

import SvgContent from "b2c/components/SvgContent";
import ReferencesIco from "images/b2c/profilePage/ReferencesIco.svg";
import { capitalize } from "utils/helpers";
import { Input, Dropdown, Text } from "b2c/components/Core";
import SubPageSection from "b2c/components/SubPageSection";
import { useTranslation } from "b2c/hooks/useTranslation";
import useStore from "../../../../contexts/store";
import { Optional } from "./styled";

const References = ({ itemId, handleBack, isApplicationAttachment, ...rest }) => {
  const {
    Profile: {
      state: { loading, processing },
      reference_options: { relations = [] } = {},
      reference_details: {
        name,
        title_and_organization,
        email,
        phone_code,
        phone_number,
        relation_to,
        waits_user_review
      } = {},
      personal_options: { phone_codes: { common = [], priorities = [] } = {} } = {},
      validation_errors,
      initializeReference,
      addReference,
      updateReference
    }
  } = useStore("User");
  const { state } = useLocation();
  const { t } = useTranslation();
  const item_id = state ? state.item_id : "";

  useEffect(() => {
    initializeReference(itemId || item_id, isApplicationAttachment);
  }, [itemId, item_id]);

  const requiredFields = ["name", "title_and_organization", "email", "relation_to"];
  const phoneCode = !phone_code && !itemId ? "+254" : phone_code;
  const initialFields = { name, title_and_organization, email, phone_code, phone_number, relation_to };
  const dynamicFields = {};

  const formatError = useCallback(
    key => {
      return validation_errors[key]
        ? capitalize(validation_errors[key].join(", "))
        : waits_user_review && requiredFields.includes(key) && !initialFields[key]
          ? "Field is missing"
          : null;
    },
    [validation_errors]
  );

  useEffect(() => {
    if (window.scrollY) {
      window.scroll(0, 0);
    }
  }, []);

  const handleUpdateReference = form => {
    const normalizeForm = { ...form, phone_code: form.phone_code && form.phone_number ? form.phone_code : "" };

    return updateReference(item_id || itemId, normalizeForm);
  };

  const handleAddReference = form => {
    const normalizeForm = { ...form, phone_code: form.phone_code && form.phone_number ? form.phone_code : "" };

    return addReference(normalizeForm);
  };

  return (
    <SubPageSection
      title={t("profile.references.title")}
      sectionIcon={
        <SvgContent>
          <ReferencesIco />
        </SvgContent>
      }
      loading={loading}
      processing={processing}
      submitAction={
        item_id || itemId ? formData => handleUpdateReference(formData) : formData => handleAddReference(formData)
      }
      validation={validation_errors}
      confirmation={false}
      initialFields={initialFields}
      dynamicFields={dynamicFields}
      requiredFields={requiredFields}
      hiddenTitle={isApplicationAttachment}
      isApplicationAttachment={isApplicationAttachment}
      handleBack={handleBack}
      {...rest}
    >
      {handleChange => (
        <Row>
          <Col sm={6}>
            <Input
              name="name"
              label={t("profile.references.name_label")}
              fieldRequired
              placeholder=""
              defaultValue={name}
              onChange={handleChange}
              errorMessage={formatError("name")}
            />
          </Col>

          <Col sm={6}>
            <Input
              name="title_and_organization"
              label={t("profile.references.organization_label")}
              fieldRequired
              placeholder=""
              defaultValue={title_and_organization}
              onChange={handleChange}
              errorMessage={formatError("title_and_organization")}
            />
          </Col>

          <Col sm={6}>
            <Input
              name="email"
              label={t("profile.references.email_label")}
              fieldRequired
              type="email"
              placeholder=""
              defaultValue={email}
              onChange={handleChange}
              errorMessage={formatError("email")}
            />
          </Col>

          <Col sm={6}>
            <Row gutterWidth={10} align="end">
              <Col sm={3}>
                <Dropdown
                  id="code"
                  name="phone_code"
                  label={
                    <Text fontSize="inherit" fontWeight="500" style={{ whiteSpace: "nowrap" }}>
                      {t("profile.references.phone_number_label")} <Optional>({t("global.optional")})</Optional>
                    </Text>
                  }
                  placeholder={t("profile.references.phone_number_placeholder")}
                  defaultValue={phoneCode}
                  errorMessage={formatError("phone_code")}
                >
                  <Dropdown.Menu disableSorting>
                    {priorities.concat(common).map(item => (
                      <Dropdown.Item
                        key={`${item.code}:${item.phone_code}`}
                        onClick={() => handleChange(null, item.phone_code, "phone_code")}
                      >
                        {`${item.name} (${item.phone_code})`}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              <Col sm={9}>
                <Input
                  label=""
                  name="phone_number"
                  tyepe="tel"
                  placeholder=""
                  defaultValue={phone_number}
                  onChange={handleChange}
                  errorMessage={formatError("phone_code")}
                />
              </Col>
            </Row>
          </Col>

          <Col sm={6}>
            <Dropdown
              label={`${t("profile.references.relation_label")}*`}
              placeholder={t("profile.references.relation_placeholder")}
              id="relation_to"
              name="relation_to"
              defaultValue={relation_to}
              errorMessage={formatError("relation_to")}
            >
              <Dropdown.Menu disableSorting>
                {relations.map((item, i) => (
                  <Dropdown.Item key={`relation-${i}`} onClick={() => handleChange(null, item, "relation_to")}>
                    {item}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      )}
    </SubPageSection>
  );
};

export default observer(References);
