import styled from "styled-components";

export const MobileContent = styled.div`
  padding: 45px 20px 32px 20px;
  text-align: center;
  background-color: #ffffff;
`;

export const Text = styled.p`
  font-weight: 600;
  font-size: 24px;
  line-height: 130%;
  color: #292929;
`;
