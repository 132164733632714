import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { ScreenClassContext } from "react-grid-system";

import courses_placeholder from "images/b2c/courses-placeholder.jpg";
import { getCategoryBackground } from "utils/industryImageMap";
import { useTranslation } from "b2c/hooks/useTranslation";
import { Title, Text, Card, Label } from "../../Core";
import StarRating from "../../StarRating";
import SaveButton from "../../SaveButton";
import withLoading from "../../withLoading";
import { useLanguageAndCountryUrl } from "../../../hooks";

const CoursesItem = ({
  slug,
  loaded,
  name,
  category,
  categories,
  participants_count: participantsCount = 0,
  saved = false,
  photo,
  saveItem,
  premium,
  progress,
  rating
}) => {
  const background = getCategoryBackground(categories?.[0]?.slug);
  const isTagShown = premium || !!progress;
  const screen = useContext(ScreenClassContext);
  const isSm = /sm|xs/.test(screen);
  const { t } = useTranslation();
  const languageAndLanguage = useLanguageAndCountryUrl();

  const badge = isTagShown && {
    title: premium ? (
      <Label.PremiumAlternative title="Premium" iconSize="10px" fontSize={10} />
    ) : progress?.finished ? (
      t("learn.completed")
    ) : (
      t("learn.unfinished")
    ),
    color: premium ? "black500" : progress?.finished ? "success300" : "accent400"
  };

  return (
    <Card
      as={Link}
      to={`${languageAndLanguage}/learn/${slug}`}
      theme="white"
      clickable
      shadow
      badge={badge.title}
      badgeTheme={badge.color}
      Button={
        <SaveButton
          saved={saved}
          handleSaving={saveItem}
          title={t("learn.save_course")}
          titleSaved={t("learn.remove_course")}
        />
      }
      style={{
        border: "1px solid #E1E1E1",
        boxShadow: "none",
        height: isSm ? "auto" : "260px"
      }}
    >
      <Card.Header
        height={0}
        padding={isSm ? "16px 12px 37% 16px" : "16px 12px 44% 16px"}
        backgroundImage={photo?.url || background || courses_placeholder}
        placeholder={courses_placeholder}
        style={{
          height: isSm ? "100px" : "160px"
        }}
      />

      <Card.Content padding="8px">
        <Text
          loaded={loaded}
          fontWeight={400}
          maxLength={62}
          margin="0"
          truncated
          height={20}
          title={category || categories?.[0]?.name}
        >
          {category || categories?.[0]?.name}
        </Text>
        <Title
          loaded={loaded}
          level={6}
          height={40}
          fontWeight={500}
          width="100%"
          margin="2px 0 0"
          truncatedLine={2}
          title={name}
        >
          {name}
        </Title>
      </Card.Content>

      <Card.Footer padding="0 8px 8px">
        <StarRating rating={rating} hideStars={!rating} hideNumbers={!rating} hideCount />
        {participantsCount ? (
          <Text loaded={loaded} fontWeight={400} color="grey900">
            {participantsCount} {t("forum.students")}
          </Text>
        ) : null}
      </Card.Footer>
    </Card>
  );
};

CoursesItem.Placeholder = () => (
  <Card theme="white" shadow>
    <Card.Header height={0} padding="16px 12px 58% 16px" backgroundImage={courses_placeholder} />

    <Card.Content>
      <Text loading margin="0">
        <Label theme="primary" />
      </Text>
      <Title loading level={4} height={60} fontWeight={500} margin="8px 0 11px" />
    </Card.Content>

    <Card.Footer padding="0 16px 8px">
      <div>
        <Text loading />
        <Text margin="3px 0 0" loading />
      </div>
    </Card.Footer>
  </Card>
);

export default withLoading(CoursesItem, CoursesItem.Placeholder);
