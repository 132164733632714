import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { ScreenClassContext } from "react-grid-system";

import logo_placeholder from "images/b2c/logo-placeholder.png";
import getIndustryStyles from "utils/industryImageMap";
import { Card, Icon, Text, Title, Image } from "../../Core";
import withLoading from "../../withLoading";

const CompanyItem = ({
  loaded,
  logo,
  name,
  industries = [],
  jobs_locations: jobsLocations = [],
  jobs_count: jobsCount,
  path
}) => {
  const { Icon: IndustryIcon } = getIndustryStyles(industries[0]);
  const screen = useContext(ScreenClassContext);
  const location = jobsLocations.length > 1 ? "Multiple locations" : jobsLocations[0];
  const isSm = /xs|sm/.test(screen);

  return (
    <Card
      as={Link}
      to={path || ""}
      theme="white"
      clickable
      shadow
      style={{
        height: "100%",
        border: "1px solid #E1E1E1",
        boxShadow: "none"
      }}
    >
      <Card.Header height="72px" padding="16px 12px 0 16px">
        <div
          style={{
            width: "56px",
            height: " 56px",
            border: "1px solid #EEEEEE",
            borderRadius: "8px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 auto"
          }}
        >
          {logo ? (
            <Image
              backgroundColor="white"
              src={logo}
              alt={name}
              placeholder={logo_placeholder}
              margin="0 auto"
              objectFit="contain"
              width="100%"
              height="100%"
              maxWidth="48px"
              maxHeight="48px"
            />
          ) : (
            <Icon
              className="industry-icon"
              as={IndustryIcon}
              fill="black300"
              width="100%"
              height="100%"
              maxWidth="48px"
              maxHeight="48px"
              margin="0 auto"
            />
          )}
        </div>
      </Card.Header>

      <Card.Content style={{ flex: "1 0 auto" }} padding={isSm ? "0 16px" : "0 16px 16px"}>
        <Title
          level={5}
          textAlign="center"
          fontWeight={600}
          margin="16px auto 5px"
          loaded={loaded}
          truncatedLine={1}
          title={name}
          height="22px"
          fontSize="16px"
        >
          {name}
        </Title>
        <Text color="grey700" textAlign="center" margin="0 auto" loaded={loaded}>
          {`${jobsCount || "No"} open job${Number(jobsCount) === 1 ? "" : "s"}`}
        </Text>
        <Text color="grey700" textAlign="center" margin="0 auto" loaded={loaded}>
          {location || ""}
        </Text>
      </Card.Content>
    </Card>
  );
};

CompanyItem.Placeholder = () => {
  const { Icon: IndustryIcon } = getIndustryStyles("default");

  return (
    <Card theme="white" shadow>
      <Card.Header height={100}>
        <Icon className="industry-icon" as={IndustryIcon} fill="black300" margin="auto" width={120} height={68} />
      </Card.Header>

      <Card.Content>
        <Title loading level={5} fontSize={20} margin="0 auto 6px" />
        <Text loading margin="0 auto" />
      </Card.Content>

      <Card.Footer padding="20px 16px 25px">
        <Text loading margin="7px auto 6px" />
      </Card.Footer>
    </Card>
  );
};

export default withLoading(CompanyItem, CompanyItem.Placeholder);
