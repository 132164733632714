import React from "react";
import { enableStaticRendering } from "mobx-react";
import { ScreenClassProvider, setConfiguration } from "react-grid-system";

// import ErrorBoundaries from "./contexts/boundaries";
import { lookupScreenClass } from "utils/helpers";
import ErrorBoundaryProvider from "./components/ErrorBoundaries";
import { StoreProvider } from "./contexts/store";
import { ServerSideProvider } from "./contexts/ssr";

import RootRouter from "./routes/RootRouter";
import StateMessage from "./components/StateMessage";
import { initI18n } from "./i18n";

setConfiguration({ gutterWidth: 20 });

if (window?.ssr) {
  React.useLayoutEffect = React.useEffect;
}

const removeFallbackText = () =>
  document?.getElementById("react-fallback-text") && (document.getElementById("react-fallback-text").outerHTML = "");

const App = initialState => {
  enableStaticRendering(initialState.ssr);
  removeFallbackText();

  initI18n(initialState.user ? initialState.user.ui_locale : initialState.context.locale);

  const availableLanguages = initialState.global_brand.locales.items;
  const countryAndLocalesPathPaths = initialState.global_brand.countries.flatMap(country =>
    availableLanguages.map(language => ({
      ...country,
      slug: `${country.country_code.toLowerCase()}/${language}`
    }))
  );

  return (
    <ServerSideProvider ssr={initialState.ssr} url={initialState.url} hydrated={initialState.hydration}>
      <ScreenClassProvider fallbackScreenClass={lookupScreenClass(initialState.device.mobile)}>
        <StoreProvider initialState={initialState}>
          <ErrorBoundaryProvider initUser={initialState.user}>
            <StateMessage />
            <RootRouter
              brand={initialState.brand}
              user={initialState.user}
              globalBrand={initialState.global_brand}
              countries={initialState.countries}
              toastMessage={initialState.toast_message}
              countriesAndLocales={
                initialState.global_brand.enforce_locale_based_paths ? countryAndLocalesPathPaths : []
              }
              isEnableNewCountryLocalesPath={initialState.global_brand.enforce_locale_based_paths}
              isMultiLanguageBrand={Boolean(
                initialState.global_brand.enforce_locale_based_paths &&
                  initialState.global_brand.enforce_locale_based_paths &&
                  !initialState.user
              )}
            />
          </ErrorBoundaryProvider>
        </StoreProvider>
      </ScreenClassProvider>
    </ServerSideProvider>
  );
};

export default App;
