import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";

import SvgContent from "b2c/components/SvgContent";
import { Row, Col } from "react-grid-system";
import AssociationsIco from "images/b2c/profilePage/AssociationsIco.svg";
import { capitalize } from "utils/helpers";
import { Button, Input } from "b2c/components/Core";
import SubPageSection from "b2c/components/SubPageSection";
import { useTranslation } from "b2c/hooks/useTranslation";
import useStore from "../../../../contexts/store";

const Associations = props => {
  const {
    Profile: {
      state: { loading, processing },
      associations,
      validation_errors,
      initializeAssociations,
      updateAssociations
    }
  } = useStore("User");
  const [associationItems, setAssociationItems] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    initializeAssociations();
  }, []);

  useEffect(() => {
    if (associations && associations.length > 0) setAssociationItems(associations);
    else if (associationItems.length === 0) addAssociation();
  }, [associations]);

  const addAssociation = () => {
    setAssociationItems(prev =>
      prev.concat({
        name: null,
        role: null
      })
    );
  };

  const handleAssociationChange = (e, index) => {
    e.persist();
    const newList = [...associationItems];
    newList[index] = {
      ...newList[index],
      [e.target.name]: e.target.value
    };

    setAssociationItems(newList);
  };

  const formatError = error => (error ? capitalize(error.join(", ")) : null);

  useEffect(() => {
    if (window.scrollY) {
      window.scroll(0, 0);
    }
  }, []);

  return (
    <SubPageSection
      title={t("profile.associations.title")}
      sectionIcon={
        <SvgContent>
          <AssociationsIco />
        </SvgContent>
      }
      loading={loading}
      processing={processing}
      submitAction={updateAssociations}
      disableSubmit={associationItems.some(item => !item.name || !item.role)}
      dynamicFields={{
        associations: associationItems
      }}
      validation={validation_errors}
      {...props}
    >
      {() => (
        <>
          {(associationItems || []).map((association, index) => (
            <Row key={`association-${index + 1}`}>
              <Col sm={4}>
                <Input
                  name="name"
                  label={t("profile.associations.name_label")}
                  placeholder={t("profile.associations.name_placeholder")}
                  defaultValue={association.name}
                  onChange={e => handleAssociationChange(e, index)}
                  errorMessage={formatError(
                    validation_errors.associations && validation_errors.associations[index].name
                  )}
                />
              </Col>

              <Col sm={4}>
                <Input
                  label={t("profile.associations.role_label")}
                  placeholder={t("profile.associations.role_placeholder")}
                  name="role"
                  defaultValue={association.role}
                  onChange={e => handleAssociationChange(e, index)}
                  errorMessage={formatError(
                    validation_errors.associations && validation_errors.associations[index].role
                  )}
                />
              </Col>
            </Row>
          ))}
          <Button type="button" width="150px" onClick={addAssociation} color="primaryButtonLabelColor">
            {t("global.add_more_button")}
          </Button>
        </>
      )}
    </SubPageSection>
  );
};

export default observer(Associations);
