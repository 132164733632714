import React from "react";
import { Row, Col, Visible, Hidden } from "react-grid-system";
import styled from "styled-components";

import InviteIllustration from "images/b2c/invite-ilustration-1.svg";
import Layout from "../../../../components/Layout";
import { Text } from "../../../../components/Core";

const InvitePromo = () => {
  return (
    <PromoWrapper>
      <Layout.Content tag="div">
        <Hidden xs sm>
          <Row align="center">
            <Col>
              <Text width="100%" fontSize={22} fontWeight={600} margin="0 0 25px">
                Invite your friends and family to Fuzu
              </Text>
              <Text width="100%" lineHeight="20px">
                Do yourself and your friends a favor - invite them to Fuzu, Africa’s fastest growing career community.
                You get Fuzu Points for every successful registration, providing you with discounts on awesome Fuzu
                Premium features.
              </Text>
            </Col>
            <Col xs="content">
              <InviteIllustration width="205px" />
            </Col>
          </Row>
        </Hidden>
        <Visible xs sm>
          <Row align="center" style={{ textAlign: "center" }}>
            <Col xs={12}>
              <Text width="100%" fontSize={16} fontWeight={600} margin="25px 0 0" textAlign="center">
                Invite your friends and family to Fuzu
              </Text>
            </Col>
            <Col xs={12}>
              <InviteIllustration width="205px" />
            </Col>
            <Col xs={12}>
              <Text width="100%" lineHeight="20px" margin="-20px 0 15px">
                Do yourself and your friends a favor - invite them to Fuzu, Africa’s fastest growing career community.
                You get Fuzu Points for every successful registration, providing you with discounts on awesome Fuzu
                Premium features.
              </Text>
            </Col>
          </Row>
        </Visible>
      </Layout.Content>
    </PromoWrapper>
  );
};

const PromoWrapper = styled.div`
  background-color: #fff;
  margin-bottom: 20px;
`;

export default InvitePromo;
