import styled from "styled-components";

export const TestAndSurveyHeader = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  background-color: #fff;
  border-bottom: 1px solid #e1e1e1;
`;

export const WizardWrapper = styled.div`
  padding: ${({ isPassed }) => (!isPassed ? 16 : 60)}px 0;
`;
