import React, { useContext, useEffect } from "react";
import { useRouteMatch, Redirect } from "react-router-dom";
import { observer } from "mobx-react";
import { Row, Col } from "react-grid-system";
import styled from "styled-components";

import { useTranslation } from "b2c/hooks/useTranslation";
import BrandsSection from "b2c/components/BrandsSection";
import { useScrollHandler, useModal } from "utils/hooks";
import LandingContext from "../../contexts/landing";
import useStore from "../../contexts/store";
import { withTrackedRoute } from "../../hocs";
import { Button } from "../../components/Core";
import HowItWorksSection from "../../components/HowItWorksSection";
import TestimonialSliderSection from "./components/TestimonialSliderSection";
import PricingSection from "./components/PricingSection";
import TalentPoolSection from "./components/TalentPoolSection";
import HeroSection from "./components/HeroSection";
import InfoSliderSection from "./components/InfoSliderSection";
import SignupPrompt from "../../components/SignupPrompt";
import { useCountryDetection } from "../../hooks";
import { isBaronaBrand, withCustomPlatformHidden } from "../../contexts/branded";
import CountryContext from "../../contexts/country";
import ContactUsModal from "../../components/ContactUsModal";
import GlobalWorkforceBanner from "../../components/GlobalWorkforceBanner";

const EmployersLanding = () => {
  const isBarona = isBaronaBrand();

  if (isBarona) {
    return <Redirect to="/companies" />;
  }

  const { trendingCompanies = [], initializeEmployerLanding } = useStore("Landing");
  const { track } = useStore("Tracking");
  const { setVersion } = useContext(LandingContext);
  const { country } = useContext(CountryContext);
  const { path } = useRouteMatch();
  const isGlobal = country?.id === "global" || !country;
  const openContactUsModal = useModal(<ContactUsModal />, "", null, false);
  const { t } = useTranslation();

  useCountryDetection();

  useEffect(() => {
    initializeEmployerLanding(country?.id);
    track("b2b_employers_landing", { match_path: path, type: "employers_landing" });
    setVersion("b2b");
  }, []);

  const { scrollHandler, targetRef } = useScrollHandler();

  return (
    <LandingPageWrapper>
      <HeroSection
        employers
        actions={
          <Row>
            <Col lg={6} md={12}>
              <Button
                theme="accent300"
                fontWeight={600}
                fontSize={18}
                height={52}
                width="100%"
                onClick={() => openContactUsModal({ aggressive: true })}
                margin="0 0 10px"
              >
                {t("employers.start_hiring_button")}
              </Button>
            </Col>
            <Col lg={6} md={12}>
              <Button
                theme="black0"
                color="white"
                fontWeight={600}
                fontSize={18}
                height={52}
                width="100%"
                margin="0 0 35px"
                onClick={scrollHandler}
              >
                {t("employers.show_features_button")}
              </Button>
            </Col>
          </Row>
        }
      />

      <BrandsSection
        title={t("employers.join_title")}
        companies={trendingCompanies?.slice(0, 6)?.map(item => ({ ...item, isExternal: true }))}
      />

      <TestimonialSliderSection contentSize="md" />

      <PricingSection openContactUsModal={openContactUsModal} ref={targetRef} />

      {isGlobal && <GlobalWorkforceBanner />}

      <InfoSliderSection />

      <HowItWorksSection padding="60px 0 0" />

      <TalentPoolSection />

      <SignupPrompt
        Actions={() => (
          <Button onClick={() => openContactUsModal({ aggressive: true })} width={260}>
            {t("employers.start_hiring_button")}
          </Button>
        )}
      />
    </LandingPageWrapper>
  );
};

const LandingPageWrapper = styled.main`
  background-color: #fff;
`;

export default withCustomPlatformHidden(withTrackedRoute(observer(EmployersLanding)));
