import React, { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";

import Skillset from "b2c/components/Skillset";
import { useTranslation } from "b2c/hooks/useTranslation";
import WorkExperience from "../WorkExperience";
import StepProgress from "../StepProgress";

const ExperienceFlow = ({ itemId, hiddenTitle, resolveAction, inProfileWizard, ...rest }) => {
  const { state: { item_id } = {} } = useLocation();
  const [currentStep, setStep] = useState(1);
  const { t } = useTranslation();
  const tabs = [t("profile.work_experience.steps.work_experience"), t("profile.work_experience.steps.related_skills")];

  useEffect(() => {
    if (window.scrollY) {
      window.scroll(0, 0);
    }
  }, []);

  if (inProfileWizard) {
    return (
      <WorkExperience
        {...rest}
        hiddenTitle={hiddenTitle}
        itemId={itemId || item_id}
        resolveAction={resolveAction}
        inProfileWizard
      />
    );
  }
  return (
    <>
      <StepProgress currentStep={currentStep} width="460px" margin="0 0 20px" steps={tabs} mobileSteps={tabs} />

      {currentStep === 1 && (
        <WorkExperience
          {...rest}
          hiddenTitle={hiddenTitle}
          itemId={itemId || item_id}
          resolveAction={() => setStep(2)}
        />
      )}

      {currentStep === 2 && (
        <Skillset {...rest} hiddenTitle={hiddenTitle} resolveAction={resolveAction} backHandler={() => setStep(1)} />
      )}
    </>
  );
};

export default ExperienceFlow;
