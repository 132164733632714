import React from "react";

import { Image } from "b2c/components/Core";

import step1 from "images/step1.png";
import step2 from "images/step2.png";
import step3 from "images/step3.png";
import {
  Container,
  Content,
  Title,
  Steps,
  StepsItem,
  StepsHead,
  StepsTitle,
  StepsText,
  ImageContent,
  StepsCycle
} from "./styled";

const Install = () => {
  return (
    <Container>
      <Content>
        <Title>
          Install manually <br />
          with these <span>3</span> easy steps
        </Title>
        <Steps>
          <StepsItem>
            <StepsHead>
              <StepsCycle>1</StepsCycle>
              <StepsTitle>Open browser options</StepsTitle>
            </StepsHead>
            <StepsText>Click the 3 dots icon next to the address bar to open Browser Options</StepsText>
            <ImageContent imageWidth="320px" imageHeight="216px" imageWidthTablet="275px" imageHeightTablet="186px">
              <Image src={step1} />
            </ImageContent>
          </StepsItem>
          <StepsItem>
            <StepsHead>
              <StepsCycle>2</StepsCycle>
              <StepsTitle>Install the app</StepsTitle>
            </StepsHead>
            <StepsText>Click Install app from the options and then Install in the pop-up</StepsText>
            <ImageContent imageWidth="320px" imageHeight="229px" imageWidthTablet="260px" imageHeightTablet="160px">
              <Image src={step2} />
            </ImageContent>
          </StepsItem>
          <StepsItem>
            <StepsHead>
              <StepsCycle isLast>3</StepsCycle>
              <StepsTitle>Enjoy Fuzu!</StepsTitle>
            </StepsHead>
            <StepsText>Once installed, Fuzu App will appear among other apps on your phone </StepsText>
            <ImageContent imageWidth="320px" imageHeight="229px" imageWidthTablet="260px" imageHeightTablet="136px">
              <Image src={step3} />
            </ImageContent>
          </StepsItem>
        </Steps>
      </Content>
    </Container>
  );
};

export default Install;
