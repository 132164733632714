import React from "react";

import JobFilters from "../JobFilters";
import ApplicationFilters from "../ApplicationFilters";

const SearchFilters = ({ jobType, searchFilters, applicationFilters, appliedFilters, applyFilter, showJobsInText }) => {
  return jobType ? (
    <JobFilters
      searchFilters={searchFilters}
      appliedFilters={appliedFilters}
      applyFilter={applyFilter}
      showJobsInText={showJobsInText}
    />
  ) : (
    <ApplicationFilters
      applicationFilters={applicationFilters}
      appliedFilters={appliedFilters}
      applyFilter={applyFilter}
    />
  );
};

export default SearchFilters;
