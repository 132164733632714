import { observer } from "mobx-react";
import React, { useContext } from "react";
import { Link, useLocation, useRouteMatch, useHistory } from "react-router-dom";
import { Col, Row, useScreenClass, Visible } from "react-grid-system";
import { Home as HomeSolid } from "@styled-icons/boxicons-solid/Home";
import { Briefcase, Chalkboard, Group, Home, Chat } from "@styled-icons/boxicons-regular";
import { Briefcase as BriefcaseSolid } from "@styled-icons/boxicons-solid/Briefcase";
import { Chat as ChatSolid } from "@styled-icons/boxicons-solid/Chat";
import { Chalkboard as ChalkboardSolid } from "@styled-icons/boxicons-solid/Chalkboard";
import { Group as GroupSolid } from "@styled-icons/boxicons-solid/Group";

import { socialGlobal, socialKenya, socialNigeria, socialUganda, countryKeys } from "b2c/constants/main";
import { useTranslation } from "b2c/hooks/useTranslation";
import { useLanguageAndCountryUrl } from "b2c/hooks";
import CountryContext from "../../contexts/country";
import { useGetCurrentCountry } from "../../hooks";
import { isCustomPlatform } from "../../contexts/branded";
import useStore from "../../contexts/store";
import Layout from "../Layout";
import LogoFooter from "../LogoFooter";
import { Badge, Button, Icon, Text } from "../Core";
import { GuestVisible } from "../WithAuth";
import { MessageContent, Indent, SocialContent } from "./styled";
import FooterNavItem from "../FooterNavItem";
import { useStandaloneMode } from "../../contexts/standalone";
import BaronaFooter from "../BaronaFooter";

const DefaultFooter = ({ isBarona, isMultiLanguageBrand }) => {
  const { user, navigation } = useStore("User");
  const { navigationMethod } = useStore("JobSearch");
  const { countries } = useStore("initialState");
  const { unreadCount } = useStore("Messages");
  const { country } = useContext(CountryContext);
  const { pathname } = useLocation();
  const { url } = useRouteMatch();
  const screen = useScreenClass();
  const employers = /employers/.test(pathname);
  const isCompaniesPage = /companies/.test(pathname);
  const languageAndLanguage = useLanguageAndCountryUrl();

  const homePath = languageAndLanguage || (country?.slug ? `/${country?.slug}` : "");
  const customPlatform = isCustomPlatform();
  const ChatIcon = <Chat width={24} height={24} fill="#676767" />;
  const ChatSolidIcon = <ChatSolid width={24} height={24} fill="#000000" />;
  const currentCountry = useGetCurrentCountry();
  const { t } = useTranslation();
  const history = useHistory();

  const buildPath = path => {
    if (languageAndLanguage) {
      return `${languageAndLanguage}${path}`;
    }

    return path;
  };

  const normalizeSocials = () => {
    const currentCountryName = currentCountry.name.toLowerCase();

    if (currentCountryName === countryKeys.kenya.toLowerCase()) {
      return socialKenya;
    }
    if (currentCountryName === countryKeys.nigeria.toLowerCase()) {
      return socialNigeria;
    }

    if (currentCountryName === countryKeys.uganda.toLowerCase()) {
      return socialUganda;
    }

    return socialGlobal;
  };

  const unreadCountBadge = !isNaN(unreadCount) && (
    <Badge
      style={{
        width: "24px",
        height: "20px",
        border: "2px solid #FFFFFF",
        fontSize: "10px",
        fontWeight: "600",
        top: "-6px",
        right: "9px"
      }}
    >
      {unreadCount}
    </Badge>
  );

  const handleClickNavigationJob = () => {
    if (navigationMethod) {
      navigationMethod();
    }
  };

  const support = [
    !employers && {
      text: t("navigation.faq"),
      to: buildPath("/faq")
    },
    { text: t("navigation.contact"), to: buildPath("/contact") },
    {
      text: t("navigation.service_terms"),
      to: buildPath("/terms-and-conditions")
    }
  ];

  const contactUs = [
    { text: t("navigation_fuzu.about"), to: buildPath("/about") },
    { text: t("navigation_fuzu.careers"), to: "/careers" },
    // { text: t("navigation_fuzu.press"), to: "https://press.fuzu.com/", isExternal: true },
    !employers
      ? { text: t("navigation_fuzu.for_employers"), to: `${homePath}/employers` }
      : { text: t("navigation_fuzu.for_job_seekers"), to: `${homePath}` },
    { text: t("navigation_fuzu.workforce"), to: "https://global.fuzu.com/", isExternal: true }
  ];

  const isActiveJobs = /job/.test(history.location.pathname);
  const isActiveHome = url === (user?.id ? "/home" : homePath || "/") && !isActiveJobs;
  const isActiveLearn = /learn/.test(url);
  const isActiveForum = /forum/.test(url);
  const isMessages = /messages/.test(url);

  const homePage = !isMultiLanguageBrand
    ? {
        title: t("navigation.home"),
        Icon: isActiveHome ? <HomeSolid width={24} fill="#000000" /> : <Home width={24} fill="#676767" />,
        to: user?.id ? "/home" : homePath || "/",
        active: isActiveHome,
        highlightId: "homepage",
        guest: true
      }
    : {};
  const mobileNavigation = [
    homePage,
    {
      title: t("navigation.jobs"),
      Icon: isActiveJobs ? <BriefcaseSolid width={24} fill="#000000" /> : <Briefcase width={24} fill="#676767" />,
      to: `${homePath || ""}/job`,
      active: isActiveJobs,
      onClick: handleClickNavigationJob,
      guest: true,
      isJobPage: true
    },
    {
      title: t("navigation.learn"),
      Icon: isActiveLearn ? <ChalkboardSolid width={24} fill="#000000" /> : <Chalkboard width={24} fill="#676767" />,
      to: buildPath("/learn"),
      active: isActiveLearn,
      highlightId: "learn",
      guest: true
    },
    {
      title: t("navigation.forum"),
      Icon: isActiveForum ? <GroupSolid width={24} fill="#000000" /> : <Group width={24} fill="#676767" />,
      to: `${homePath || ""}/forum`,
      active: isActiveForum,
      highlightId: "article",
      guest: true
    }
  ];

  if (user?.id) {
    mobileNavigation.push({
      title: t("navigation.messages"),
      Icon: (
        <MessageContent>
          {isMessages ? ChatSolidIcon : ChatIcon}
          {unreadCountBadge}
        </MessageContent>
      ),
      to: "/messages",
      active: isMessages,
      highlightId: "messages",
      guest: true
    });
  }

  const colMargin = `${/xs|sm|md/.test(screen) ? "16px" : "0"} 0 16px`;
  const isStandalone = useStandaloneMode();
  const isGlobal = window.location.href.includes("global");

  return (
    <>
      {isBarona ? (
        <BaronaFooter
          isCompaniesPage={isCompaniesPage}
          colMargin={colMargin}
          support={support}
          mobileNavigation={mobileNavigation}
          navigation={navigation}
          user={user}
          isMultiLanguageBrand={isMultiLanguageBrand}
          languageAndLanguage={languageAndLanguage}
        />
      ) : (
        <>
          {!isStandalone && !customPlatform && navigation?.desktop && (
            <footer className="site-footer" data-cy="footer">
              <Layout.Content size="xl" tag="div" padding="46px 0">
                <Row justify="around">
                  <SocialContent lg={3} sm={12}>
                    <LogoFooter margin={colMargin} />
                    <Row nowrap gutterWidth={25}>
                      {normalizeSocials().map(({ href, icon, ...rest }) => (
                        <Col width="content" key={href}>
                          <Button
                            as="a"
                            height="fit-content"
                            target="_blank"
                            noBackground
                            href={href}
                            padding="0"
                            rel="noreferrer"
                            {...rest}
                          >
                            <Icon as={icon} fill="white" />
                          </Button>
                        </Col>
                      ))}
                    </Row>
                  </SocialContent>

                  <Col lg={3} xs={12}>
                    {!employers && (
                      <GuestVisible>
                        <Text margin={colMargin} fontWeight="600" color="footerSectionHeaderColor">
                          {t("navigation_fuzu.support_title")}
                        </Text>
                        {countries.map(({ name, slug }) => (
                          <Button
                            key={slug}
                            theme="transparent"
                            as="a"
                            href={`/${slug === "" ? "" : slug}`}
                            color="white"
                            margin="8px 0"
                            style={{
                              fontWeight: "400",
                              fontSize: "14px",
                              textAlign: "left",
                              display: "flex",
                              padding: "0"
                            }}
                            height="auto"
                          >
                            {name}
                          </Button>
                        ))}
                      </GuestVisible>
                    )}
                  </Col>

                  <Col lg={3} xs={12}>
                    <Text margin={colMargin} fontWeight="600" color="footerSectionHeaderColor">
                      {t("navigation_fuzu.support_title")}
                    </Text>

                    {[...support, { text: t("navigation_fuzu.privacy_policy"), to: buildPath("/privacy-policy") }].map(
                      ({ text, to }) =>
                        to && (
                          <Text key={to} as={Link} to={to} color="white" margin="8px 0" style={{ display: "flex" }}>
                            {text}
                          </Text>
                        )
                    )}
                  </Col>

                  <Col lg={3} xs={12}>
                    <Text margin={colMargin} fontWeight="600" color="footerSectionHeaderColor">
                      {t("navigation_fuzu.about_us_title")}
                    </Text>
                    {contactUs.map(({ text, to, isExternal }) => (
                      <Text
                        as={isExternal ? "a" : Link}
                        key={to}
                        color="white"
                        margin="8px 0"
                        style={{ display: "flex" }}
                        {...(isExternal ? { href: to } : { to })}
                      >
                        {text}
                      </Text>
                    ))}
                  </Col>
                </Row>
              </Layout.Content>
            </footer>
          )}
          {!isCompaniesPage && !employers && navigation.mobile && !isGlobal && (
            <Visible sm xs>
              {isStandalone && <Indent />}

              <div className="mobile-navigation">
                <Row justify="around" gutterWidth={4}>
                  {mobileNavigation
                    .filter(item => Boolean(item.title))
                    .map(
                      ({ guest, ...props }) =>
                        (guest || user?.id) && (
                          <Col key={props.title} width="auto">
                            <FooterNavItem {...props} />
                          </Col>
                        )
                    )}
                </Row>
              </div>
            </Visible>
          )}
        </>
      )}
    </>
  );
};

export default observer(DefaultFooter);
