import styled from "styled-components";

export const PricingWrapper = styled.section`
  max-width: 1200px;
  padding: 0 0 30px 0;
  width: 100%;
  margin: 0 auto;
  position: relative;
`;

export const TitleWrapper = styled.section`
  margin: 0 auto;
  text-align: center;
`;
