import React, { useState, createContext } from "react";
import { useLocation } from "react-router-dom";

import { useNCPWD, useInstallPWA, useAddClassStandalone, useBarona } from "../hooks";
import { landing } from "../constants/landing";

const LandingContext = createContext({});

export const LandingProvider = ({ children }) => {
  const { pathname } = useLocation();
  const landingType = pathname.includes("/employers") ? "b2b" : "b2c";
  const [version, setVersion] = useState(landingType);

  useInstallPWA();
  useAddClassStandalone();
  useNCPWD();
  useBarona();

  return (
    <LandingContext.Provider value={{ ...landing[version], version, setVersion }}>{children}</LandingContext.Provider>
  );
};

export default LandingContext;
