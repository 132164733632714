import React, { useMemo } from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { Col, Row, Visible } from "react-grid-system";

import { socialBarona } from "b2c/constants/main";
import { useTranslation } from "b2c/hooks/useTranslation";
import Layout from "../Layout";
import LogoFooter from "../LogoFooter";
import { Button, Icon, Text } from "../Core";
import { SocialContent } from "../DefaultFooter/styled";
import FooterNavItem from "../FooterNavItem";

const BaronaDefaultFooter = ({
  support,
  mobileNavigation,
  colMargin,
  isCompaniesPage,
  navigation,
  user,
  isMultiLanguageBrand,
  languageAndLanguage
}) => {
  const baronaFooterLinks = useMemo(() => {
    const homePage = !isMultiLanguageBrand
      ? {
          name: "navigation.home",
          slug: "/"
        }
      : {};

    return [
      homePage,
      {
        name: "navigation.jobs",
        slug: `${languageAndLanguage}/job`
      },
      {
        name: "navigation.learn",
        slug: `${languageAndLanguage}/learn`
      },
      {
        name: "navigation.articles",
        slug: `${languageAndLanguage}/forum`
      }
    ];
  }, [isMultiLanguageBrand]);

  const { t } = useTranslation();

  const supportPrivacy = { text: t("navigation.privacy"), to: "https://policies.barona.fi/", isExternal: true };

  const footerLinks = useMemo(() => {
    const links = [
      { text: t("navigation.working_in_the_nordics"), to: `${languageAndLanguage}/working-in-nordics` },
      { text: t("navigation.about"), to: `${languageAndLanguage}/about` }
    ];

    if (!isMultiLanguageBrand) {
      links.push({ text: t("navigation.for_companies"), to: "/companies", isExternal: true });
    }

    return links;
  }, []);

  return (
    <>
      {navigation?.desktop && (
        <footer className="site-footer site-footer_barona" data-cy="footer">
          <Layout.Content size="xl" tag="div" padding="46px 0">
            <Row justify="around">
              <SocialContent lg={3} sm={12}>
                <LogoFooter margin={colMargin} />
                <Row nowrap gutterWidth={25}>
                  {socialBarona.map(({ href, icon, ...rest }) => (
                    <Col width="content" key={href}>
                      <Button
                        as="a"
                        height="fit-content"
                        target="_blank"
                        noBackground
                        href={href}
                        padding="0"
                        rel="noreferrer"
                        {...rest}
                      >
                        <Icon as={icon} fill="white" />
                      </Button>
                    </Col>
                  ))}
                </Row>
              </SocialContent>

              <Col lg={3} xs={12}>
                <Text margin={colMargin} fontWeight="600" style={{ color: "#2496f5" }}>
                  {t("navigation.brand_name")}
                </Text>

                {baronaFooterLinks
                  .filter(item => item.name)
                  .map(({ name, slug }) => (
                    <Text key={name} as={Link} to={slug} color="white" margin="8px 0" style={{ display: "flex" }}>
                      {t(name)}
                    </Text>
                  ))}
              </Col>

              <Col lg={3} xs={12}>
                <Text margin={colMargin} fontWeight="600" color="footerSectionHeaderColor">
                  {t("navigation.support")}
                </Text>

                {[...support, supportPrivacy].map(({ text, to, isExternal }) => (
                  <Text
                    key={to}
                    as={isExternal ? "a" : Link}
                    {...(isExternal ? { href: to } : { to })}
                    target={isExternal ? "_blank" : "_self"}
                    color="white"
                    margin="8px 0"
                    style={{ display: "flex" }}
                  >
                    {text}
                  </Text>
                ))}
              </Col>

              <Col lg={3} xs={12}>
                <Text margin={colMargin} fontWeight="600" color="footerSectionHeaderColor">
                  {t("navigation.about_us")}
                </Text>
                {footerLinks.map(({ text, to, isExternal }) => (
                  <Text
                    as={isExternal ? "a" : Link}
                    key={to}
                    color="white"
                    margin="8px 0"
                    style={{ display: "flex" }}
                    {...(isExternal ? { href: to } : { to })}
                  >
                    {text}
                  </Text>
                ))}
              </Col>
            </Row>
          </Layout.Content>
        </footer>
      )}

      {!isCompaniesPage && navigation.mobile && (
        <Visible sm xs>
          <div className="mobile-navigation">
            <Row justify="around" gutterWidth={4}>
              {mobileNavigation
                .map(item => {
                  if (item.highlightId === "article") {
                    return {
                      title: t("navigation.articles"),
                      ...item
                    };
                  }
                  return item;
                })
                .map(
                  ({ guest, ...props }) =>
                    (guest || user?.id) && (
                      <Col key={props.title} width="auto">
                        <FooterNavItem {...props} />
                      </Col>
                    )
                )}
            </Row>
          </div>
        </Visible>
      )}
    </>
  );
};

export default observer(BaronaDefaultFooter);
