import React from "react";
import { Row, Col } from "react-grid-system";

import { useTranslation } from "b2c/hooks/useTranslation";
import talentPoolImage from "images/b2b/landing-page/professionals.png";
import Title from "b2c/components/Core/Title";
import Text from "b2c/components/Core/Text";
import { TalentPoolWrapper, TalentPoolContent, TalentInfoItem, TalentPoolImageWrapper } from "./styled";

const TalentPoolSection = () => {
  const { t } = useTranslation();

  return (
    <TalentPoolWrapper>
      <TalentPoolContent>
        <Title scales level={2} margin="0 auto 30px" textAlign="center">
          {t("employers.talent_pool.title")}
        </Title>
        <Row align="center">
          <Col xs={12} sm={12} md={6}>
            <TalentPoolImageWrapper>
              <img src={talentPoolImage} alt="" />
            </TalentPoolImageWrapper>
          </Col>
          <Col xs={12} sm={12} md={6}>
            <Row>
              <Col xs={6} sm={6} md={4}>
                <TalentInfoItem>
                  <Title level={3} fontWeight={700} margin="5px 0">
                    {t("employers.talent_pool.sub-title_1")}
                  </Title>
                  <Text fontSize="16px">{t("employers.talent_pool.description_1")}</Text>
                </TalentInfoItem>
              </Col>
              <Col xs={6} sm={6} md={4}>
                <TalentInfoItem>
                  <Title level={3} fontWeight={700} margin="5px 0">
                    {t("employers.talent_pool.sub-title_2")}
                  </Title>
                  <Text fontSize="16px">{t("employers.talent_pool.description_2")}</Text>
                </TalentInfoItem>
              </Col>
              <Col xs={6} sm={6} md={4}>
                <TalentInfoItem>
                  <Title level={3} fontWeight={700} margin="5px 0">
                    {t("employers.talent_pool.sub-title_3")}
                  </Title>
                  <Text fontSize="16px">{t("employers.talent_pool.description_3")}</Text>
                </TalentInfoItem>
              </Col>
              <Col xs={6} sm={6} md={4}>
                <TalentInfoItem>
                  <Title level={3} fontWeight={700} margin="5px 0">
                    {t("employers.talent_pool.sub-title_4")}
                  </Title>
                  <Text fontSize="16px">{t("employers.talent_pool.description_4")}</Text>
                </TalentInfoItem>
              </Col>
              <Col xs={6} sm={6} md={4}>
                <TalentInfoItem>
                  <Title level={3} fontWeight={700} margin="5px 0">
                    {t("employers.talent_pool.sub-title_5")}
                  </Title>
                  <Text fontSize="16px">{t("employers.talent_pool.description_5")}</Text>
                </TalentInfoItem>
              </Col>
              <Col xs={6} sm={6} md={4}>
                <TalentInfoItem>
                  <Title level={3} fontWeight={700} margin="5px 0">
                    {t("employers.talent_pool.sub-title_6")}
                  </Title>
                  <Text fontSize="16px">{t("employers.talent_pool.description_6")}</Text>
                </TalentInfoItem>
              </Col>
            </Row>
          </Col>
        </Row>
      </TalentPoolContent>
    </TalentPoolWrapper>
  );
};

export default TalentPoolSection;
