import React from "react";
import { observer } from "mobx-react";

import { StyledContent, Title, Description, Actions, Cancel, Delete } from "./styled";

const DeleteReferral = ({ close, handleDelete }) => {
  const onDelete = () => {
    handleDelete().then(() => {
      close();
    });
  };
  return (
    <StyledContent>
      <Title>Delete this reference?</Title>
      <Description>This will remove it from your profile and your application.</Description>
      <Actions>
        <Cancel onClick={close}>Cancel</Cancel>
        <Delete onClick={onDelete}>Yes, delete it</Delete>
      </Actions>
    </StyledContent>
  );
};

export default observer(DeleteReferral);
