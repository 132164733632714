import styled from "styled-components";

export const EliteSupportSection = styled.section`
  padding: 64px 30px;
  background-color: #f3f3f3;

  @media screen and (max-width: 1200px) {
    padding: 20px 30px;
  }
`;

export const EliteSupportContent = styled.div`
  max-width: 1160px;
  width: 100%;
  margin: 0 auto;
`;

export const EliteCircle = styled.div`
  width: 48px;
  height: 48px;
  background-color: #dff1ff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 24px;
  color: #4690ff;
  margin-right: 16px;
  flex: none;
`;
