import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";

import SvgContent from "b2c/components/SvgContent";
import HobbiesIco from "images/b2c/profilePage/HobbiesIco.svg";
import { capitalize } from "utils/helpers";
import { Button, Textarea } from "b2c/components/Core";
import SubPageSection from "b2c/components/SubPageSection";
import { useTranslation } from "b2c/hooks/useTranslation";
import useStore from "../../../../contexts/store";

const Hobbies = props => {
  const {
    Profile: {
      state: { loading, processing },
      hobbies,
      validation_errors,
      initializeHobbies,
      updateHobbies
    }
  } = useStore("User");
  const [hobbyItems, setHobbyItems] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    initializeHobbies();
  }, []);

  useEffect(() => {
    if (hobbies && hobbies.length > 0) setHobbyItems(hobbies);
    else if (hobbyItems.length === 0) addHobby();
  }, [hobbies]);

  useEffect(() => {
    if (window.scrollY) {
      window.scroll(0, 0);
    }
  }, []);

  const addHobby = () => {
    setHobbyItems(prev =>
      prev.concat({
        name: null
      })
    );
  };

  const handleHobbyChange = (e, index) => {
    e.persist();
    const newList = [...hobbyItems];
    newList[index] = {
      ...newList[index],
      name: e.target.value
    };

    setHobbyItems(newList);
  };

  const formatError = error => (error ? capitalize(error.join(", ")) : null);

  return (
    <SubPageSection
      title={t("profile.hobbies.title")}
      sectionIcon={
        <SvgContent>
          <HobbiesIco />
        </SvgContent>
      }
      loading={loading}
      processing={processing}
      submitAction={updateHobbies}
      dynamicFields={{
        hobbies: hobbyItems
      }}
      validation={validation_errors}
      {...props}
    >
      {() => (
        <>
          {(hobbyItems || []).map((hobby, index) => (
            <Textarea
              key={`hobby-${index + 1}`}
              inputHeight="72px"
              placeholder={t("profile.hobbies.hobby_placeholder")}
              defaultValue={hobby.name || ""}
              onChange={e => handleHobbyChange(e, index)}
              errorMessage={formatError(validation_errors.hobbies && validation_errors.hobbies[index].name)}
            />
          ))}
          <Button type="button" width="150px" onClick={addHobby} color="primaryButtonLabelColor">
            {t("global.add_more_button")}
          </Button>
        </>
      )}
    </SubPageSection>
  );
};

export default observer(Hobbies);
