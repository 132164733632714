import React, { useState } from "react";
import { observer } from "mobx-react";

import { Text } from "b2c/components/Core";
import { Form, StyledButton, Label, Email, B2bInviteHeaderWrapper, Content } from "./styled";
import useStore from "../../contexts/store";
import BaronaLogo from "../../components/BaronaLogo";
import B2bInvite from "../../../../assets/images/b2b_invite_logo_fuzu.svg";

const RemoveMyData = ({ match: { params } }) => {
  const [showSuccess, setShowSuccess] = useState(false);
  const { deleteRemoveMyData } = useStore("User");
  const { prospect, employer, global_brand } = useStore("initialState");

  const handleSubmission = e => {
    e.preventDefault();
    deleteRemoveMyData(params.token).then(res => {
      if (res) {
        setShowSuccess(true);
      }
    });
  };

  return (
    <div>
      <B2bInviteHeaderWrapper>
        <BaronaLogo>
          <B2bInvite />
        </BaronaLogo>
      </B2bInviteHeaderWrapper>

      <Content>
        {!showSuccess ? (
          <>
            <Text
              fontSize={36}
              fontWeight={600}
              lineHeight="43px"
              width="100%"
              margin="50px 0 0 0"
              textAlign="center"
              style={{
                fontFamily: "Poppins",
                fontStyle: "normal"
              }}
            >
              Confirm removal request
            </Text>
            <Text
              fontSize="14px"
              fontWeight="400"
              lineHeight="20px"
              width="100%"
              margin="16px 0 32px 0"
              textAlign="center"
              style={{
                fontFamily: "Poppins",
                fontStyle: "normal"
              }}
            >
              Youʼve requested to remove your personal data from {employer.name} recruitment process on{" "}
              {global_brand.name}. Confirm request by clicking the button below.
            </Text>
            <Form onSubmit={handleSubmission} noValidate>
              <Label>Your email</Label>
              <Email>{prospect.details.email}</Email>
              <StyledButton type="submit" fontSize="14px" margin="15px 0" width="100%" height="48px">
                Remove my data
              </StyledButton>
            </Form>
          </>
        ) : (
          <>
            <Text
              fontSize={36}
              fontWeight={600}
              lineHeight="43px"
              width="100%"
              margin="50px 0 0 0"
              textAlign="center"
              style={{
                fontFamily: "Poppins",
                fontStyle: "normal"
              }}
            >
              Request submitted
            </Text>
            <Text
              fontSize="14px"
              fontWeight="400"
              lineHeight="20px"
              width="100%"
              margin="16px 0 32px 0"
              textAlign="center"
              style={{
                fontFamily: "Poppins",
                fontStyle: "normal"
              }}
            >
              Your data removal request has been received. <br />
              Your data will be deleted from {employer.name} recruitment process on {global_brand.name}. You can close
              this tab.
            </Text>
          </>
        )}
      </Content>
    </div>
  );
};

export default observer(RemoveMyData);
