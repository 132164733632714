import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import ThemeContext from "../../contexts/theme";

import { TableWrapper, TableRow, TableCell } from "./styled";

const ResultTable = ({ headings = [], rows = [] }) => {
  const { push } = useHistory();
  const Theme = useContext(ThemeContext);

  return (
    <TableWrapper thColor={Theme.grey200} tableBg={Theme.white}>
      <table className="result-table">
        <thead className="result-table__header">
          <tr>
            {headings.map(h => (
              <th className="result-table__headcell" key={h}>
                {h}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {rows.map((row, i) => (
            <TableRow
              backgroundColor={Theme.white}
              hoverColor={Theme.primary}
              borderColor={Theme.grey300}
              className="result-table__row"
              key={`row-${i}`}
              withAction={!!row.to}
              onClick={() => {
                if (row.to) push(row.to);
              }}
            >
              {row.item.map((item, j) => (
                <TableCell
                  borderColor={Theme.grey200}
                  iconFill={Theme.black200}
                  iconBg={Theme.grey100}
                  className="result-table__cell"
                  key={`item-${j}`}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </tbody>
      </table>
    </TableWrapper>
  );
};

export default ResultTable;
