import React, { useState, useContext } from "react";

import ThemeContext from "../../../contexts/theme";

import { RadioButton } from "../../Core";

import { StyledDivider } from "../../PremiumBanner";

const ShareExperience = () => {
  const [checked, setChecked] = useState("fill_profile");
  const Theme = useContext(ThemeContext);

  const handleCheck = e => {
    setChecked(e.target.value);
  };

  return (
    <>
      <RadioButton
        borderedView
        hideIndicator
        id="flowOption1"
        name="share_exp"
        value="fill_profile"
        checked={checked === "fill_profile"}
        onChange={handleCheck}
        Label="Create Fuzu profile"
      />
      <StyledDivider backgroundColor={Theme.grey200} maskColor={Theme.white} textColor={Theme.black200} />
      <RadioButton
        borderedView
        hideIndicator
        id="flowOption2"
        name="share_exp"
        value="upload_cv"
        checked={checked === "upload_cv"}
        onChange={handleCheck}
        Label="Upload your CV"
      />
    </>
  );
};

export default ShareExperience;
