import React from "react";
import { Link } from "react-router-dom";

import Highlight from "../Highlight";
import { Badge, Button, Text } from "../Core";

export const FooterNavItem = ({
  title,
  count = 0,
  active,
  Icon: IconProp,
  onClick,
  to = "#",
  highlightId,
  isJobPage,
  ...rest
}) => {
  return (
    <Highlight highlightId={highlightId}>
      <Text
        as={isJobPage ? "a" : Link}
        {...(isJobPage ? { href: to } : { to })}
        className="link"
        onClick={onClick}
        {...rest}
        style={{ pointerEvents: active ? "none" : "" }}
      >
        <Button.Iconed
          margin="auto"
          width={56}
          height={50}
          padding={0}
          noBackground
          theme={active ? "#000000" : "#676767"}
          style={{ pointerEvents: active ? "none" : "" }}
        >
          <div>
            {IconProp}
            <Text
              fontSize={12}
              fontWeight={500}
              margin="0"
              style={{
                fontWeight: active ? 600 : "normal",
                color: active ? "#000000" : "#676767"
              }}
            >
              {title}
            </Text>
          </div>
          {count > 0 && <Badge>{count}</Badge>}
        </Button.Iconed>
      </Text>
    </Highlight>
  );
};

export default FooterNavItem;
