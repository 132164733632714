import { useEffect } from "react";

import useStore from "b2c/contexts/store";
import { updateUserDevice } from "b2c/utils/api/profile";
import { notificationPermissionTypes } from "b2c/constants/main";
import { getNotificationPermission, getPushSubscription, getUserDeviceData } from "b2c/utils/helpers";

let messageChannel;

const useServiceWorker = () => {
  const {
    User: {
      user,
      Profile: { userDevice },
      setNotificationPrompt,
      Notifications: { incrementNotificationsCount, setNotificationsCount, updateCounterBadge }
    }
  } = useStore();
  const isLoggedIn = Boolean(user?.id);
  const isNotificationsAllowed = getNotificationPermission() === notificationPermissionTypes.granted;
  const { timeZone, language, userAgent } = getUserDeviceData();

  useEffect(() => {
    if (!isLoggedIn || !isNotificationsAllowed) {
      return;
    }

    getPushSubscription(userDevice?.deviceId, setNotificationPrompt).then(({ deviceId, subscription }) => {
      if (subscription) {
        updateUserDevice(deviceId, { push_notification_creds: subscription, timeZone, language, userAgent });
      }
    });
  }, [isLoggedIn]);

  useEffect(() => {
    messageChannel = messageChannel || new MessageChannel();

    messageChannel.port1.onmessage = e => {
      const { unread_count: unreadCount } = e.data.payload || {};

      if (unreadCount !== undefined) {
        setNotificationsCount(unreadCount);
        updateCounterBadge(unreadCount);
      } else {
        incrementNotificationsCount();
      }
    };
  }, []);
};

export default useServiceWorker;
