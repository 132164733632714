import React, { useState, useMemo, useEffect, useContext } from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import { useScreenClass } from "react-grid-system";
import { DownArrowAlt, RightArrowAlt } from "@styled-icons/boxicons-regular";

import { usePrev } from "utils/hooks";
import ArticleIco from "images/b2c/emptyStates/emptyArticle.svg";
import Section from "b2c/components/Section";
import Post from "b2c/components/Post";
import { Title, Text, Button, Card, Icon, Pagination } from "b2c/components/Core";
import useStore from "../../../../contexts/store";
import { useQueryParams } from "../../../../../utils/hooks";
import ServerSide from "../../../../contexts/ssr";
import { useTranslation } from "../../../../hooks/useTranslation";

const ArticlesSectionList = observer(
  ({
    title,
    filter,
    article_id,
    author_id,
    pagination = false,
    count_limit = 3,
    total_limit = 6,
    narrowCarousel,
    expandable = false,
    noHeader,
    noFooter,
    titleProps,
    metaTitle,
    ...rest
  }) => {
    const { ssr } = useContext(ServerSide);
    const isXs = useScreenClass() === "xs";
    const { articles, total, state, getArticles, h1Title, appliedQueryParams } = useStore("Articles");
    const { push } = useHistory();
    const { t } = useTranslation();

    const { page: pageNum = 1 } = useQueryParams();
    const currentPage = parseInt(appliedQueryParams?.page || pageNum || 1);
    const [page, setPage] = useState(currentPage);
    const [loadNewPage, setLoadNewPage] = useState(false);
    const loading = state[filter] ? state[filter].loading : true;
    const [expanded, setExpanded] = useState(false);
    const prevLoading = usePrev(loading);
    const loaded = useMemo(() => prevLoading, [loading, expanded]);
    const item_limit = expanded || (narrowCarousel && isXs) ? total_limit || count_limit : count_limit;
    const articleList = articles[filter] ? articles[filter].slice(0, item_limit) : [...new Array(item_limit).keys()];
    const basePath = `/forum/${filter}`;
    const pageCount = total[filter] ? Math.ceil(total[filter] / item_limit) : 0;

    const handleMore = () => {
      if (!loading) {
        if (expandable && !expanded && !(narrowCarousel && isXs)) {
          setExpanded(true);
        } else {
          push(basePath);
        }
      }
    };

    const handlePagination = pages => {
      push(`${basePath}?page=${pages}`);
      setPage(pages);
      if (!loadNewPage) {
        setLoadNewPage(true);
      }
    };

    useEffect(() => {
      const limit = expandable ? total_limit : count_limit;

      if (!articles[filter]?.length || state[filter]?.isHome || loadNewPage) {
        getArticles(filter, limit, page, article_id, undefined, author_id);
      }

      window.scrollTo(0, 0);
    }, [page, loadNewPage]);

    useEffect(() => {
      if (articles[filter] && articles[filter].length === 0 && page > 1) {
        setPage(1);
        getArticles(filter, count_limit, 1, undefined, undefined, author_id);
      }
    }, [articles]);

    useEffect(() => {
      if (metaTitle && document.title !== metaTitle) {
        document.title = metaTitle;
      }
    }, []);

    return (
      <Section
        expandable={expandable}
        expanded={expanded}
        columns={3}
        testScope="articles-section"
        narrowCarousel={narrowCarousel && isXs}
        mobileMargin="0"
        ShowMoreButton={
          <Card style={{ height: "100%" }} onClick={handleMore}>
            <Card.Content
              style={{
                height: "100%",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Icon width="120px" as={ArticleIco} additionalCss="height: 110px" />
              <Text width="100%" textAlign="center" margin="10px 0" fontWeight={600} color="primaryButtonLabelColor">
                {t("forum.show_more_button")}
                <RightArrowAlt width={20} />
              </Text>
            </Card.Content>
          </Card>
        }
        Header={
          !noHeader && (
            <Title scales level={2} fontWeight={600} {...titleProps}>
              {ssr ? <>{h1Title}</> : <>{title}</>}
            </Title>
          )
        }
        Footer={
          !noFooter &&
          (!pagination ? (
            <Button onClick={handleMore} padding="12px 24px 12px 28px" fontSize={14} color="primaryButtonLabelColor">
              {t("forum.show_more_button")}
              {!expandable || (expandable && expanded) ? <RightArrowAlt width={20} /> : <DownArrowAlt width={20} />}
            </Button>
          ) : (
            articleList.length > 0 && (
              <Pagination
                padding="32px 0"
                disabled={loading}
                page={page}
                last={pageCount}
                setPage={handlePagination}
                basePath={basePath}
                isLink
              />
            )
          ))
        }
        {...rest}
      >
        {articleList.map((item, index) => (
          <Post
            key={`article_${filter}_${index}`}
            {...item}
            type={filter}
            onClick={() => push(item.path)}
            loading={loading}
            loaded={loaded}
            iArticleSectionList
          />
        ))}
      </Section>
    );
  }
);

export default ArticlesSectionList;
