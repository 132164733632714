import React from "react";
import { Row, Col } from "react-grid-system";
import { observer } from "mobx-react";
import { useTranslation } from "b2c/hooks/useTranslation";

import { useIsBarona } from "b2c/hooks";
import { Card, Text, Title, Button } from "../../../../components/Core";
import AvatarProgress from "../../../../components/AvatarProgress";
import PromoSection from "../../../../components/PromoSection";
// import VisibilitySettings from "../../../../components/VisibilitySettings";
import useStore from "../../../../contexts/store";
import { WhitelabelledHidden, isCustomJkuat, getCompanyName } from "../../../../contexts/branded";
import { useDocumentTitle } from "../../../../../utils/hooks";

const WizardOutro = ({ location: { state } }) => {
  const { getFeatureStatus } = useStore("Premium");
  const customJkuat = isCustomJkuat();
  const isBarona = useIsBarona();
  const { t } = useTranslation();

  useDocumentTitle(getCompanyName());

  const handleClick = () => {
    if (!state?.from?.includes("application")) {
      getFeatureStatus();
    }
  };

  return (
    <>
      <Card shadow wideOnMobile style={{ marginBottom: "24px" }}>
        <Card.Header>
          <AvatarProgress to="/profile" indicator width={124} style={{ margin: "auto" }} />
        </Card.Header>
        <Card.Content padding="24px">
          <Title level={4} fontWeight={600} margin="auto auto 16px">
            {t("wizard.success.title", { name: getCompanyName() })}
          </Title>
          <Text>{t("wizard.success.description", { name: getCompanyName() })}</Text>
        </Card.Content>

        <Card.Footer display="block" padding="16px">
          <Row justify="center">
            <Col lg={3}>
              <Button.Link
                width="100%"
                to={{
                  pathname: state?.from || "/profile",
                  state: { from: "/wizard", update: true }
                }}
                color="primaryButtonLabelColor"
                onClick={handleClick}
              >
                {state?.from?.includes("application")
                  ? t("wizard.success.back_to_application_button")
                  : t("wizard.success.done")}
              </Button.Link>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
      <WhitelabelledHidden>
        {/*
        <Title level={4} fontWeight={600} margin="32px 0 16px">
          Define who can see your profile
        </Title>
        <VisibilitySettings />
        */}
        {!customJkuat && !isBarona && (
          <PromoSection.PremiumCv title={t("promo.premium_cv.title")} redirectTo="/profile">
            {t("promo.premium_cv.description")}
          </PromoSection.PremiumCv>
        )}
      </WhitelabelledHidden>
    </>
  );
};

export default observer(WizardOutro);
