import React from "react";
import { Redirect } from "react-router-dom";

import RouteExclude from "components/RouteExclude";
import { isKepsa, isWhitelabelledOrJkuat, withBrandedRoute } from "../../contexts/branded";
import NavigationBlocker from "../../components/NavigationBlocker";
import NetworkObserver from "../../components/NetworkObserver";
import AuthRoutes from "../../pages/AuthRoutes";
import MainRoutes from "../MainRoutes";
import WizardScreen from "../../pages/WizardScreen";
import PaymentScreen from "../../pages/PaymentScreen";
import TestAndSurveyScreen from "../../pages/TestAndSurveyScreen";
import EntityPage from "../../pages/EntityPage";
import CvBuilderScreen from "../../pages/CvBuilder";
import GuestModeManager from "../../components/GuestModeManager";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { CountryProvider } from "../../contexts/country";
import ApplicationScreen from "../../pages/ApplicationScreen";
import useStore from "../../contexts/store";

/*
const paths = [
  "/forum",
  "/home",
  "/forum/article",
  "/search",
  "/saved",
  "/job",
  "/jobs",
  "/profile",
  "/application",
  "/company",
  "/settings",
  "/messages",
  "/points",
  "/invite",
  "/af/en/learn",
  "/learn",
  "/faq",
  "/working-in-nordics",
  "/privacy-policy",
  "/terms-and-conditions",
  "/contact",
  "/press",
  "/about",
  "/careers",
  "/premium",
  "/products",
  "/learn"
];
*/

const exclude = [
  "/jobs/:id/application/tests/:test_id",
  "/learn/:id/tests/:test_id",
  "/jobs/:position_id/product/:product_id",
  "/jobs/:id/application"
];

const InnerRoutes = ({
  jobPaths,
  countries,
  isBarona,
  countryPaths,
  isEnableNewCountryLocalesPath,
  isMultiLanguageBrand
}) => {
  /*

    const defaultPaths = [
    "/forum",
    "/home",
    "/forum/article",
    "/search",
    "/saved",
    "/job",
    "/jobs",
    "/profile",
    "/application",
    "/company",
    "/settings",
    "/messages",
    "/points",
    "/invite",
    "/af/en/learn",
    "/learn",
    "/faq",
    "/working-in-nordics",
    "/privacy-policy",
    "/terms-and-conditions",
    "/contact",
    "/press",
    "/about",
    "/careers",
    "/premium",
    "/products",
    "/learn"
  ];

  const pathsWithCountryAndLocales = countryPaths?.map(({ slug }) => [
    `/${slug}/forum`,
    `/${slug}/forum/article`,
    `/${slug}/search`,
    `/${slug}/saved`,
    `/${slug}/job`,
    `/${slug}/jobs`,
    `/${slug}/application`,
    `/${slug}/privacy-policy`,
    `/${slug}/terms-and-conditions`,
    `/${slug}/careers`,
    `/${slug}/faq`,
    `/${slug}/contact`,
    `/${slug}/working-in-nordics`,
    `/${slug}/learn`,
    `/${slug}/about`,
    `/${slug}/contact`,
    `/${slug}/invite`,
    `/${slug}/working-in-nordics`,
    "/forum",
    "/home",
    "/forum/article",
    "/search",
    "/saved",
    "/job",
    "/jobs",
    "/profile",
    "/application",
    "/company",
    "/settings",
    "/messages",
    "/points",
    "/invite",
    "/af/en/learn",
    "/learn",
    "/faq",
    "/working-in-nordics",
    "/privacy-policy",
    "/terms-and-conditions",
    "/contact",
    "/press",
    "/about",
    "/careers",
    "/premium",
    "/products",
    "/learn"
  ]);





  const paths = [
    "/forum",
    "/home",
    "/forum/article",
    "/search",
    "/saved",
    "/job",
    "/jobs",
    "/profile",
    "/application",
    "/company",
    "/settings",
    "/messages",
    "/points",
    "/invite",
    "/af/en/learn",
    "/learn",
    "/faq",
    "/working-in-nordics",
    "/privacy-policy",
    "/terms-and-conditions",
    "/contact",
    "/press",
    "/about",
    "/careers",
    "/premium",
    "/products",
    "/learn"
  ];
*/

  const defaultPaths = [
    "/forum",
    "/home",
    "/forum/article",
    "/search",
    "/saved",
    "/job",
    "/jobs",
    "/profile",
    "/application",
    "/company",
    "/settings",
    "/messages",
    "/points",
    "/invite",
    "/faq",
    "/working-in-nordics",
    "/privacy-policy",
    "/terms-and-conditions",
    "/contact",
    "/press",
    "/about",
    "/careers",
    "/premium",
    "/products",
    "/learn"
  ];

  const pathsWithCountryAndLocales =
    countryPaths
      ?.map(({ slug }) => [
        `/${slug}/forum`,
        `/${slug}/home`,
        `/${slug}/forum/article`,
        `/${slug}/forum/article/:id`,
        `/${slug}/search`,
        `/${slug}/saved`,
        // `/${slug}/job`,
        // `/${slug}/jobs`,
        `/${slug}/profile`,
        `/${slug}/application`,
        `/${slug}/company`,
        `/${slug}/settings`,
        `/${slug}/messages`,
        `/${slug}/points`,
        `/${slug}/privacy-policy`,
        `/${slug}/terms-and-conditions`,
        `/${slug}/careers`,
        `/${slug}/faq`,
        `/${slug}/contact`,
        `/${slug}/working-in-nordics`,
        `/${slug}/premium`,
        `/${slug}/products`,
        `/${slug}/learn`,
        `/${slug}/about`,
        `/${slug}/contact`,
        `/${slug}/invite`,
        `/${slug}/working-in-nordics`,
        // `/${slug}/login`,
        "/home",
        "/job",
        "/privacy-policy",
        "/jobs",
        "/about",
        "/profile",
        "/search",
        "/settings",
        "/messages",
        "/forum",
        "/forum/:id",
        "/forum/article/:id",
        "/invite",
        "/learn",
        "/faq",
        "/working-in-nordics",
        "/terms-and-conditions",
        "/contact",
        "/company",
        "/press",
        "/about",
        "/careers",
        "/premium",
        "/products"
      ])
      .flat() || [];

  const paths = isEnableNewCountryLocalesPath ? pathsWithCountryAndLocales : defaultPaths;

  const jobsCountryLinks = countries.map(({ slug }) => `/${slug}/jobs/:id`);
  const { user } = useStore("User");
  const whitelabelledOrJkuat = isWhitelabelledOrJkuat();
  const newPaths = whitelabelledOrJkuat ? [...paths, ...jobsCountryLinks] : paths;
  const isTermsAndConditionsOrPrivacyPolicy =
    window.location.pathname === "/privacy-policy" || window.location.pathname === "/terms-and-conditions";

  if (
    user &&
    !user.onboarding_steps_completed &&
    user.email &&
    !window.location.pathname.includes("/onboarding") &&
    !whitelabelledOrJkuat &&
    !isTermsAndConditionsOrPrivacyPolicy
  ) {
    window.location.href = "/onboarding";
    // resetUser();
    return null;
  }

  return (
    <NetworkObserver>
      <NavigationBlocker
        allowRoutes={["/profile", "/wizard", "/application", "/product", "/cv-creator"]}
        prohibitRoutes={["/applications", "/success"]}
      />

      <GuestModeManager>
        <RouteExclude path={newPaths} exclude={exclude}>
          <CountryProvider>
            <Header isBarona={isBarona} isMultiLanguageBrand={isMultiLanguageBrand} />
            <MainRoutes
              isMultiLanguageBrand={isMultiLanguageBrand}
              countryPaths={countryPaths}
              jobPaths={jobPaths}
              countries={countries}
            />
            <Footer isBarona={isBarona} isMultiLanguageBrand={isMultiLanguageBrand} />
          </CountryProvider>
        </RouteExclude>
        <BrandedCompanyPage exact path="/" />
      </GuestModeManager>

      <RouteExclude exclude={["/jobs/:job_id/application/tests/:id"]} path="/jobs/:id/application">
        <ApplicationScreen path="/jobs/:id/application" />
      </RouteExclude>

      <TestAndSurveyScreen
        exact
        path={["/jobs/:job_id/application/tests/:id", "/learn/:course_id/tests/:id", "/tests/:id"]}
      />

      <PaymentScreen exact path={["/product/:slug", "/jobs/:position_id/product/:slug"]} />

      <WizardScreen exact path={["/wizard", "/wizard/:id"]} />

      <CvBuilderScreen exact path="/cv-creator" />

      <AuthRoutes countryPaths={countryPaths} />
    </NetworkObserver>
  );
};

const BrandedCompanyPage = withBrandedRoute(props => {
  const isKepsaBrand = isKepsa();
  const { user } = useStore("User");

  if (isKepsaBrand && user?.id) {
    return <Redirect to="/home" />;
  }

  return (
    <>
      <Header />
      <EntityPage type="company" {...props} />
      <Footer />
    </>
  );
});

export default InnerRoutes;
