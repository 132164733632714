import React from "react";

import { useTranslation } from "b2c/hooks/useTranslation";
import NotificationJobs from "../NotificationJobs";
import NotificationsCenterLayout from "../NotificationsCenterLayout";

const NestedNotificationsList = ({ notificationId, notifications, onBackward, onClose }) => {
  const { t } = useTranslation();

  let title;
  let content;

  const notification = notifications.entities[notificationId] || {};
  const { notification_type: type } = notification;

  if (type === "daily_recommendations") {
    title = t("notifications.daily_alert");
    content = <NotificationJobs jobs={notification.payload} />;
  } else if (type === "job_pick") {
    title = t("notifications.job_alert");
    content = <NotificationJobs jobs={notification.payload} />;
  } else {
    onBackward();
    return null;
  }

  return (
    <NotificationsCenterLayout title={title} onBackward={onBackward} onClose={onClose} withBackwardButton>
      {content}
    </NotificationsCenterLayout>
  );
};

export default NestedNotificationsList;
