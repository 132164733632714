import React, { useEffect } from "react";
import { observer } from "mobx-react";
import parse from "html-react-parser";
import { Row, Col } from "react-grid-system";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { LeftArrowAlt } from "@styled-icons/boxicons-regular";
import { useTranslation } from "b2c/hooks/useTranslation";

import ExperienceFlow from "b2c/components/ExperienceFlow";
import PersonalInformation from "b2c/components/PersonalInformation";
import Skillset from "b2c/components/Skillset";
import LanguageSkills from "b2c/components/LanguageSkills";
import Education from "b2c/components/Education";
import { getCompanyName } from "b2c/contexts/branded";
import { withTrackedRoute, withWizardRedirect } from "../../hocs";
import useStore from "../../contexts/store";
import Layout from "../../components/Layout";
import Navigation from "../../components/TabNavigation";
import { Button, Tipbox, Text, List } from "../../components/Core";
import NotFound from "../NotFound";
import { withAuthorizationRedirect } from "../../components/WithAuth";
import MyCareer from "./components/MyCareer";
import Personality from "./components/Personality";
import BasicInformation from "./components/BasicInformation";
import References from "./components/References";
import Certificates from "./components/Certificates";
import Hobbies from "./components/Hobbies";
import Associations from "./components/Associations";

const ProfileScreen = ({ match: { url }, location, ...routeProps }) => {
  const {
    user: { id },
    toggleNavigation,
    Profile: { initializeProfile }
  } = useStore("User");
  const { job_details } = useStore("Application");
  const { t } = useTranslation();

  const noiselessContents = from => {
    const stringToTest = typeof from === "string" ? from : from?.pathname;
    return /application/.test(stringToTest)
      ? {
          description: parse(t("profile.application_description", { name: getCompanyName() })),
          cta: "application"
        }
      : {
          description: parse(t("profile.cv_creator_title", { name: getCompanyName() })),
          cta: "CV creator"
        };
  };

  useEffect(() => {
    if (id) {
      initializeProfile(id);
    }
    const outsideApplicationFlow = location.state?.noiseless && !/application/.test(location.state?.from);
    if (outsideApplicationFlow) {
      toggleNavigation({ desktop: false, mobile: false });
    }
    return () => {
      if (outsideApplicationFlow) {
        toggleNavigation({ desktop: true, mobile: true });
      }
    };
  }, [id]);

  useEffect(() => {
    if (window.scrollY) {
      window.scroll(0, 0);
    }
  }, []);

  const profileTabs = [
    { name: t("profile.tabs.my_career"), to: url },
    {
      name: t("profile.tabs.personality"),
      to: `${url}/personality`
    }
  ];

  const activeTab = profileTabs.some(({ to }) => location.pathname === to);

  const contentStyle = { margin: activeTab || job_details ? "15px auto" : "30px auto" };

  const { description, cta } = noiselessContents(location.state?.from);
  const history = useHistory();

  const handleBackReferrals = () => {
    history.push("/profile");
  };

  return (
    <>
      {location.state?.noiseless ? (
        <Route exact path={url}>
          <Layout.Section backgroundColor="white" padding="16px 0">
            <Layout.Content size="xl">
              <Text>{description}</Text>
              <Tipbox
                margin="8px 0"
                title={t("profile.tip.title")}
                text={t("profile.tip.sub-title")}
                expandContent={
                  <List style={{ margin: "10px 15px" }}>
                    <List.Item bulletColor="secondary300">{t("profile.tip.description_1")}</List.Item>
                    <List.Item bulletColor="secondary300">{t("profile.tip.description_2")}</List.Item>
                    <List.Item bulletColor="secondary300">{t("profile.tip.description_3")}</List.Item>
                  </List>
                }
              />
              <Row>
                <Col lg={2} sm={12}>
                  <Button.Link
                    to={location.state?.from}
                    icon={<LeftArrowAlt width={20} />}
                    fontSize="14px"
                    width="100%"
                    color="primaryButtonLabelColor"
                  >
                    {`${t("profile.back_to")} `} {cta}
                  </Button.Link>
                </Col>
              </Row>
            </Layout.Content>
          </Layout.Section>
        </Route>
      ) : (
        activeTab && (
          <Navigation innerLayoutSize="xl">
            {profileTabs.map(({ name, to }, i) => (
              <Navigation.Tab key={i} to={to} selected={location.pathname === to}>
                {name}
              </Navigation.Tab>
            ))}
          </Navigation>
        )
      )}
      <Layout.Content size="xl" style={contentStyle} id="main">
        <Switch>
          <MyCareer exact path={url} url={url} location={location} {...routeProps} />
          <Personality exact path={`${url}/personality`} id={id} location={location} {...routeProps} />
          <Route exact path={`${url}/personal-information`}>
            <PersonalInformation location={location} {...routeProps} />
          </Route>
          <Route exact path={`${url}/basic-information`}>
            <BasicInformation location={location} {...routeProps} />
          </Route>
          <Route exact path={`${url}/work-experience`}>
            <ExperienceFlow location={location} {...routeProps} />
          </Route>
          <Route exact path={`${url}/education`}>
            <Education location={location} {...routeProps} />
          </Route>
          <Route exact path={`${url}/skills`}>
            <Skillset location={location} {...routeProps} />
          </Route>
          <Route exact path={`${url}/languages`}>
            <LanguageSkills location={location} {...routeProps} />
          </Route>
          <Route exact path={`${url}/certificates`}>
            <Certificates location={location} {...routeProps} />
          </Route>
          <Route exact path={`${url}/associations`}>
            <Associations location={location} {...routeProps} />
          </Route>
          <Route exact path={`${url}/hobbies`}>
            <Hobbies location={location} {...routeProps} />
          </Route>
          <Route exact path={`${url}/references`}>
            <References location={location} {...routeProps} handleBack={handleBackReferrals} />
          </Route>
          <Redirect from={`${url}/edit`} to={url} />
          <Redirect from={`${url}/:category/new`} to={`${url}/:category`} />
          <Redirect from={`${url}/skill_tags`} to={`${url}/skills`} />
          <NotFound />
        </Switch>
      </Layout.Content>
    </>
  );
};

export default withTrackedRoute(withAuthorizationRedirect(withWizardRedirect(observer(ProfileScreen))));
