import styled from "styled-components";

export const BannerBuildProfileComponent = styled.div`
  background-color: ${({ backgoundColor }) => backgoundColor};
  border: 1px solid #e1e1e1;
  border-radius: 6px;
  padding: 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 240px;
`;
