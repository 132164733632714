import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Col, Row, Visible } from "react-grid-system";
import { useLocation } from "react-router-dom";

import SvgContent from "b2c/components/SvgContent";
import LanguageSkillsIco from "images/b2c/profilePage/LanguageSkillsIco.svg";
import { capitalize } from "utils/helpers";
import { useTranslation } from "b2c/hooks/useTranslation";
import SubPageSection from "../SubPageSection";
import { Divider, Dropdown } from "../Core";
import useStore from "../../contexts/store";

const LanguageSkills = ({ itemId, ...wizardProps }, ...props) => {
  const {
    Profile: {
      state: { loading, processing },
      language_info: { language_levels, languages, language_items } = {},
      validation_errors,
      initializeLanguages,
      updateLanguages
    }
  } = useStore("User");
  const [languageItems, setLanguageItems] = useState([]);
  const { state } = useLocation();
  const { t } = useTranslation();

  const languageId = state ? state.item_id : itemId;

  useEffect(() => {
    initializeLanguages();
  }, []);

  useEffect(() => {
    if (language_items?.length > 0) {
      const selectedLanguage = language_items.filter(item => item.id === languageId);
      setLanguageItems(selectedLanguage);
    }
    if (language_items?.length === 0 || !languageId) {
      addLanguage();
    }
  }, [language_items, languageId, itemId]);

  const addLanguage = () => {
    setLanguageItems([
      {
        language_id: null,
        written_language_level_id: null,
        spoken_language_level_id: null
      }
    ]);
  };

  const handleLanguageChange = (name, id, index) => {
    const newList = [...languageItems];
    newList[index] = {
      ...newList[index],
      [name]: id
    };

    setLanguageItems(newList);
  };

  const formatError = error => (error ? capitalize(error.join(", ")) : null);

  const handleDefaultLanguage = id => languages?.find(item => item.id == id)?.name;

  const handleDefaultLevel = id => language_levels?.find(item => item.id == id)?.name;

  const items_errors = validation_errors.language_items || {};

  useEffect(() => {
    if (window.scrollY) {
      window.scroll(0, 0);
    }
  }, []);

  return (
    <SubPageSection
      title={t("wizard.languages.sub-title")}
      // title={`${languageId ? "Edit" : "Add"} language skill`}
      sectionIcon={
        <SvgContent>
          <LanguageSkillsIco />
        </SvgContent>
      }
      loading={loading}
      processing={processing}
      submitAction={updateLanguages}
      dynamicFields={{
        language_items: languageItems
      }}
      validation={validation_errors}
      {...props}
      {...wizardProps}
    >
      {() => (
        <>
          {(languageItems || []).map((language, index) => (
            <React.Fragment key={`language-${index + 1}`}>
              <Row>
                <Col sm={4}>
                  <Dropdown
                    label={t("wizard.languages.language_label")}
                    placeholder={t("wizard.languages.language_placeholder")}
                    id="language_id"
                    name="language_id"
                    triggerDataCy="select-language"
                    defaultValue={handleDefaultLanguage(language?.language_id)}
                    errorMessage={formatError(items_errors[index]?.language_id)}
                  >
                    <Dropdown.Menu disableSorting>
                      {languages?.map(({ name, id }) => (
                        <Dropdown.Item key={id} onClick={() => handleLanguageChange("language_id", id, index)}>
                          {name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
                <Col sm={3} xs={10}>
                  <Dropdown
                    label={t("wizard.languages.language_spoken_level_label")}
                    placeholder={t("wizard.languages.language_spoken_level_placeholder")}
                    id="spoken_language_level_id"
                    name="spoken_language_level_id"
                    triggerDataCy="spoken_language_level"
                    defaultValue={handleDefaultLevel(language?.spoken_language_level_id)}
                    errorMessage={formatError(items_errors[index]?.spoken_language_level_id)}
                  >
                    <Dropdown.Menu disableSorting>
                      {language_levels?.map(({ name, id }) => (
                        <Dropdown.Item
                          key={id}
                          onClick={() => handleLanguageChange("spoken_language_level_id", id, index)}
                        >
                          {name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
                <Col sm={3} xs={10}>
                  <Dropdown
                    label={t("wizard.languages.language_written_level_label")}
                    placeholder={t("wizard.languages.language_written_level_placeholder")}
                    id="written_language_level_id"
                    name="written_language_level_id"
                    triggerDataCy="written_language_level"
                    defaultValue={handleDefaultLevel(language?.written_language_level_id)}
                    errorMessage={formatError(items_errors[index]?.written_language_level_id)}
                  >
                    <Dropdown.Menu disableSorting triggerDataCy="written_language_level-menu">
                      {language_levels?.map(({ name, id }) => (
                        <Dropdown.Item
                          key={id}
                          onClick={() => handleLanguageChange("written_language_level_id", id, index)}
                        >
                          {name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </Row>
              {index != languageItems?.length - 1 && (
                <Visible sm xs>
                  <Divider margin="0 0 16px" />
                </Visible>
              )}
            </React.Fragment>
          ))}
        </>
      )}
    </SubPageSection>
  );
};

export default observer(LanguageSkills);
