import React, { useContext } from "react";

import ThemeContext from "../../../../contexts/theme";
import { NavProgressWrapper, NavProgressStep } from "./styled";

const NavProgress = ({ currentPage, pageTotal }) => {
  const Theme = useContext(ThemeContext);

  return (
    <NavProgressWrapper>
      {[...Array(pageTotal).keys()].map(i => (
        <NavProgressStep
          key={i}
          $isActive={i + 1 <= currentPage}
          activeColor={Theme.primary}
          backgroundColor={Theme.grey200}
        />
      ))}
    </NavProgressWrapper>
  );
};

export default NavProgress;
