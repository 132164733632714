import React from "react";
import { useTranslation } from "b2c/hooks/useTranslation";

import backgroundMobile from "images/b2c/companiesMobile.png";
import backgroundDesktop from "images/b2c/companiesDesktop.png";
import { Play } from "@styled-icons/boxicons-regular";
import {
  Container,
  Content,
  AsideLeft,
  AsideRight,
  VideoLink,
  Title,
  Description,
  Actions,
  ContactUs,
  LearnMore,
  VideoPlay
} from "./styled";

const HeroSection = ({ openContactUsModal, scrollToPrices }) => {
  const { t } = useTranslation();

  return (
    <Container backgroundMobile={backgroundMobile} backgroundDesktop={backgroundDesktop}>
      <Content>
        <AsideLeft>
          <Title>{t("companies.title")}</Title>
          <Description>{t("companies.description_1")}</Description>
          <Description>{t("companies.description_2")}</Description>

          <Actions>
            <ContactUs onClick={openContactUsModal}>{t("companies.contact")}</ContactUs>
            <LearnMore onClick={scrollToPrices}>{t("companies.learn_more")}</LearnMore>
          </Actions>
        </AsideLeft>
        <AsideRight>
          <VideoLink href="https://www.youtube.com/embed/RNOQw7EkQ-Q" target="_blank">
            <VideoPlay>
              <Play />
            </VideoPlay>
            {t("companies.video")}
          </VideoLink>
        </AsideRight>
      </Content>
    </Container>
  );
};

export default HeroSection;
