import React, { useState, useContext, useRef, useEffect } from "react";
import { Link } from "react-router-dom";

import BaronaLogoSvg from "images/barona-logo.svg";
import { validateLogin } from "utils/validation";
import { onEnterPress } from "utils/helpers";
import { useErrorFocus, useQueryParams } from "utils/hooks";
import { Title, Text, Input, Button, Spinner } from "b2c/components/Core";
import { Close } from "@styled-icons/evaicons-solid";
import { useTranslation } from "b2c/hooks/useTranslation";
import { ScreenClassContext } from "react-grid-system";
import BrandedContext, { BrandVisible, BrandHidden, isCustomJkuat, isKepsa } from "../../../../contexts/branded";
import { withTracking } from "../../../../hocs";
import { useStandaloneMode } from "../../../../contexts/standalone";
import { Label, DontHaveAccount, ForgotPassword, LinkClose, Overlay, OverlayInfo } from "./styled";
import PoweredByFuzu from "../PoweredByFuzu";
import { brandName } from "../../../../constants/brand";
import { useIsBarona } from "../../../../hooks";
import useStore from "../../../../contexts/store";

const defaultValidation = {
  email: { error: false },
  password: { error: false }
};

const Login = ({
  state: { processing, message },
  match: {
    params: { slug }
  },
  userLogin,
  notifyMnt
}) => {
  const { global_brand: globalBrand } = useStore("initialState");
  const { employer } = useContext(BrandedContext);
  const form = useRef();
  const isStandalone = useStandaloneMode();
  const customJkuat = isCustomJkuat();
  const isBarona = useIsBarona();
  const isKepsaBrand = isKepsa();
  const { t, changeLanguage } = useTranslation();
  const screen = useContext(ScreenClassContext);
  const isSm = ["xs", "sm"].includes(screen);

  const [formState, setFormState] = useState({
    email: "",
    password: "",
    remember_me: true
  });

  const [validationState, setValidation] = useState({
    email: { error: false },
    password: { error: false }
  });

  const { return_to: returnTo } = useQueryParams();

  const handleSubmission = e => {
    e.preventDefault();
    setValidation(defaultValidation);

    const { valid, validation } = validateLogin(formState);

    return userLogin(valid, formState, { context: "login_page" }).then(user => {
      setValidation(validation);

      if (user?.ui_locale) {
        changeLanguage(user.ui_locale);
      }

      if (slug === "mtn-pulse") {
        notifyMnt();
      }
    });
  };

  useErrorFocus(form, validationState);

  const handleClose = () => {
    if (returnTo) {
      return returnTo;
    }

    return window.location.origin;
  };

  useEffect(() => {
    if (globalBrand.custom_authentication_b2c_url) {
      window.location.href = globalBrand.custom_authentication_b2c_url;
    }
  }, []);

  return (
    <>
      {globalBrand.custom_authentication_b2c_url && (
        <Overlay>
          <Spinner size="50px" margin="auto" />
          <BaronaLogoSvg />
          <OverlayInfo>{t("login.redirect_text")}</OverlayInfo>
        </Overlay>
      )}
      {!isStandalone && (
        <>
          <Title level="4" fontWeight={600} margin="0 0 8px">
            {t("login.sub-title")}
            {!isBarona && !isKepsaBrand && (
              <>
                <BrandVisible brand="www"> to Fuzu</BrandVisible>
                <BrandHidden brand="www"> to {employer?.name}</BrandHidden>
              </>
            )}
          </Title>

          {!isSm && (
            <BrandHidden brand="www">
              <LinkClose href={handleClose()} isHide={customJkuat}>
                <Close />
              </LinkClose>
            </BrandHidden>
          )}

          <Text lineHeight="140%" margin="0 0 15px" color="black300">
            <BrandHidden brand="www">
              <Label>{employer?.name} portal is powered by Fuzu ©</Label>
            </BrandHidden>
            {!isKepsaBrand && (
              <DontHaveAccount>
                <Label>{t("login.create_account_label")}</Label>

                <Link className="link" to={`/onboarding${slug ? `/${slug}` : ""}`}>
                  {t("login.create_account_button")}
                </Link>
              </DontHaveAccount>
            )}
          </Text>
          <div className="page-separator" style={{ margin: "0 0 24px" }} />
        </>
      )}

      <form
        ref={form}
        onSubmit={handleSubmission}
        onKeyDown={e => onEnterPress(e, 1, () => handleSubmission(e))}
        noValidate
        style={{ margin: isStandalone ? "0" : "24px 0" }}
      >
        <Input
          label={isStandalone ? "Email" : t("login.form.email_label")}
          placeholder="example@domain.com"
          type="email"
          autoComplete="email"
          onChange={({ target: { value } }) => setFormState(prev => ({ ...prev, email: value }))}
          errorMessage={validationState?.email?.error}
        />

        <Input
          label={t("login.form.password_label")}
          placeholder={t("login.form.password_placeholder")}
          type="password"
          onChange={({ target: { value } }) => setFormState(prev => ({ ...prev, password: value }))}
          errorMessage={validationState?.password?.error}
        />

        {isStandalone && (
          <Text lineHeight="140%" margin="-2px 0 0 auto" color="black300">
            <Link className="link" to="/reset" style={{ color: "#408BFC" }}>
              {t("login.form.forgot_password")}
            </Link>
          </Text>
        )}

        {message && (
          <Text color="accent300" margin="4px 0 0">
            {message}
          </Text>
        )}

        <Button
          type="submit"
          disabled={!formState?.email || !formState?.password}
          processing={processing}
          fontSize={isStandalone ? "16px" : "14px"}
          fontWeight={isStandalone ? "600" : "500"}
          margin="24px 0 16px"
          width="100%"
          height="48px"
          color="primaryButtonLabelColor"
        >
          {t("login.form.button")}
        </Button>

        {!isStandalone && !isKepsaBrand && (
          <ForgotPassword>
            <Link className="link" to="/reset">
              {t("login.form.forgot_password")}
            </Link>
          </ForgotPassword>
        )}

        <BrandVisible brand={brandName.jkuat}>
          <PoweredByFuzu />
        </BrandVisible>
      </form>
    </>
  );
};

export default withTracking(Login, "login_flow_started", { context: "login_page" });
