import React from "react";

import { useScreenClass } from "react-grid-system";

import Layout from "b2c/components/Layout";
import { Title } from "b2c/components/Core";
import FaqItem from "b2c/components/FaqItem";
import { useTranslation } from "../../../../hooks/useTranslation";

const CareerCoachingFaq = () => {
  const isSm = ["xs", "sm"].includes(useScreenClass());
  const { t } = useTranslation();

  return (
    <Layout.Section backgroundColor="white" padding="30px 0 0">
      <Layout.Content size="xl">
        <Title level="2" fontWeight={600} margin="0 auto 25px" textAlign="center" style={{ fontSize: isSm ? 20 : 36 }}>
          {t("career_coaching.faq.title")}
        </Title>
        {faqList.map(item => (
          <FaqItem key={item.title} {...item} />
        ))}
      </Layout.Content>
    </Layout.Section>
  );
};

const faqList = [
  {
    title: "career_coaching.faq.list.title_1",
    text: "career_coaching.faq.list.description_1"
  },
  {
    title: "career_coaching.faq.list.title_2",
    text: "career_coaching.faq.list.description_2"
  },
  {
    title: "career_coaching.faq.list.title_3",
    text: "career_coaching.faq.list.description_3"
  },
  {
    title: "career_coaching.faq.list.title_4",
    text: "career_coaching.faq.list.description_4"
  },
  {
    title: "career_coaching.faq.list.title_5",
    text: "career_coaching.faq.list.description_5"
  },
  {
    title: "career_coaching.faq.list.title_6",
    text: "career_coaching.faq.list.description_6"
  }
];

export default CareerCoachingFaq;
