import styled from "styled-components";

export const TableCell = styled.td`
  min-width: 128px;
  padding: 20px 10px;
  font-size: 14px;
  @media screen and (max-width: 768px) {
    display: block;
    padding: 0;
    .save-button {
      position: absolute;
      top: 0;
      right: 0;
      padding: 8px;
    }
  }

  &:first-child {
    border-radius: 5px 0 0 5px;
  }

  &:last-child {
    border-radius: 0 5px 5px 0;
    min-width: 0;
  }

  img {
    border-radius: 5px;
    border: 1px solid ${({ borderColor }) => borderColor};
  }

  svg.logo {
    background: ${({ iconBg }) => iconBg};
    padding: 16px;
    border-radius: 5px;
    > path {
      fill: ${({ iconFill }) => iconFill};
    }
  }
`;

export const TableRow = styled.tr`
  cursor: ${({ withAction }) => (withAction ? "pointer" : "default")};
  position: relative;
  border-radius: 5px;
  box-shadow: 0 0 0 1px ${({ borderColor }) => borderColor};
  transition: box-shadow 0.2s ease;
  min-height: 100px;
  background-color: ${({ backgroundColor }) => backgroundColor};

  @media screen and (max-width: 768px) {
    display: block;
    padding: 20px 15px;
    margin-bottom: 15px;
  }

  &:hover {
    box-shadow: 0 0 0 1px ${({ hoverColor }) => hoverColor};
  }
`;

export const TableWrapper = styled.div`
  ${({ thColor }) => `
  .result-table {
    margin: 0;
    width: 100%;
    text-align: left;
    word-break: break-word;
    border-spacing: 0 10px;

    &__header {
      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    &__headcell {
      padding: 12px 10px;
      background: ${thColor};
      font-size: 12px;
      min-width: 60px;
      text-transform: uppercase;
      :first-child {
        border-radius: 5px 0 0 5px;
      }
      :last-child {
        border-radius: 0 5px 5px 0;
      }
    }
  }
`}
`;
