import React, { useContext } from "react";
import { Link } from "react-router-dom";

import BaronaLogo from "b2c/components/BaronaLogo";
import Logo from "components/Logo";
import blackFuzuLogo from "images/new_fuzu_logo_black.png";
import ThemeContext from "../../../../contexts/theme";
import { Header, Content } from "./styled";

const Heading = ({ isHideLink }) => {
  const theme = useContext(ThemeContext);

  return (
    <Header $background={theme.secondaryBackground}>
      <Content>
        {isHideLink ? (
          <BaronaLogo color="#fff">
            <Logo logo={blackFuzuLogo} />
          </BaronaLogo>
        ) : (
          <Link to="/">
            <BaronaLogo color="#fff">
              <Logo logo={blackFuzuLogo} />
            </BaronaLogo>
          </Link>
        )}
      </Content>
    </Header>
  );
};

export default Heading;
