import React, { useContext, useMemo } from "react";
import { observer } from "mobx-react";
import { Row, Col, Visible, Hidden, ScreenClassContext } from "react-grid-system";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Comment, Like } from "@styled-icons/boxicons-regular";
import { RemoveRedEye } from "@styled-icons/material";
import { useTranslation } from "b2c/hooks/useTranslation";

import { thousandShortcut } from "utils/helpers";
import Highlight from "../../Highlight";
import { Title, Text, Label, Carousel, Button, Card, Icon } from "../../Core";
import Layout from "../../Layout";
import useStore from "../../../contexts/store";

const Heading = ({ type, loading, ...rest }) => {
  const InnerContent = () => {
    switch (type) {
      default:
      case "onboarding":
        return <Single {...rest} />;
      case "carousel":
        return <ArticleCarousel {...rest} />;
    }
  };

  return (
    <Highlight highlightId="carousel" desktopOnly>
      <InnerContent />
    </Highlight>
  );
};

const Single = ({
  type,
  title = "",
  cta_url = "",
  cta_text = "",
  synopsis = "",
  background_image: { image_url } = {
    link_url: "",
    image_url: ""
  },
  article_stats,
  disabled
}) => {
  const handleDisableCheck = e => {
    if (disabled) e.preventDefault();
  };

  return (
    <HeadingWrapper as={Link} onClick={handleDisableCheck} to={cta_url}>
      <Row data-test-scope="header-section">
        <Col md={6}>
          <MainImage>
            <img src={image_url} alt={title} />
            <Visible xs sm>
              <HeadingMobileContent>
                <Title
                  role="heading"
                  level="3"
                  truncatedLine={2}
                  fontWeight={600}
                  lineHeight={1.2}
                  color="white"
                  margin="0 0 15px"
                  style={{ fontSize: 18 }}
                  title={title}
                >
                  {title}
                </Title>
                <Text color="primary">
                  <span className="link inherit underlined">{cta_text}</span>
                </Text>
              </HeadingMobileContent>
            </Visible>
          </MainImage>
        </Col>
        <Hidden xs sm>
          <Col md={6}>
            <HeadingContent>
              <Text fontSize={16} fontWeight={600} margin="35px 0 15px" style={{ textTransform: "capitalize" }}>
                {type}
              </Text>

              <Highlight highlightId="article" desktopOnly>
                <Title
                  role="heading"
                  level="3"
                  truncatedLine={2}
                  fontWeight={600}
                  lineHeight={1.2}
                  color="black500"
                  margin="0 0 15px"
                  style={{ fontSize: 32 }}
                  title={title}
                >
                  {title}
                </Title>
              </Highlight>
              <Text fontSize={16} theme="black400" margin="8px 0 20px" color="black500" width="90%" maxLength={160}>
                {synopsis}
                <span className="link underlined">{cta_text}</span>
              </Text>
              {article_stats && (
                <div className="labels-list" style={{ marginTop: "auto" }}>
                  <Label
                    theme="black300"
                    noBackground
                    fontSize={14}
                    margin="0 15px 20px 0"
                    padding="0"
                    Icon={<RemoveRedEye width={20} />}
                  >
                    {thousandShortcut(article_stats.views_count)} views
                  </Label>
                  <Label
                    theme="black300"
                    noBackground
                    fontSize={14}
                    margin="0 15px 20px auto"
                    padding="0"
                    Icon={<Like width={20} />}
                  >
                    {thousandShortcut(article_stats.votes_count)} votes
                  </Label>
                  <Label
                    theme="black300"
                    noBackground
                    fontSize={14}
                    margin="0 15px 20px 0"
                    padding="0"
                    Icon={<Comment width={20} />}
                  >
                    {thousandShortcut(article_stats.comments_count)} comments
                  </Label>
                </div>
              )}
            </HeadingContent>
          </Col>
        </Hidden>
      </Row>
    </HeadingWrapper>
  );
};

const ArticleCarousel = observer(() => {
  const {
    hero: { items, count_limit = 1 },
    highlightedRegions
  } = useStore("HomeLayout");
  const headerArticles = useMemo(
    () =>
      items
        ? items.slice(0, highlightedRegions.length > 0 ? 1 : +count_limit)
        : [
            {
              title: "Loading",
              background_image: "none",
              front_image: null,
              id: 0
            }
          ],
    [items, count_limit, highlightedRegions]
  );
  const screen = useContext(ScreenClassContext);
  const isSm = ["xs", "sm"].includes(screen);

  return (
    <Layout.Section margin={isSm ? "0 0 12px" : "25px 0 50px"}>
      <Carousel
        settings={{
          className: "wide-blue-buttons dots-bottom slick-stretched-on-mobile slick-home-heading",
          fade: false,
          dots: isSm,
          initialSlide: 0,
          slidesToShow: 3,
          slidesToSCroll: 1,
          autoplay: true,
          autoplaySpeed: 8000,
          responsive: [
            {
              breakpoint: 767,
              settings: {
                arrows: false,
                slidesToShow: 1,
                fade: false
              }
            }
          ]
        }}
      >
        {headerArticles.map(({ title, background_image, front_image, article_stats = {} }, i) => (
          <Carousel.Slide key={`article_slide_${i}`} padding="0 8px">
            <HeroItem
              isSm={isSm}
              title={title}
              background_image={background_image}
              front_image={front_image}
              disabled={highlightedRegions.length > 0}
              font_color="white"
              {...article_stats}
            />
          </Carousel.Slide>
        ))}
      </Carousel>
    </Layout.Section>
  );
});

const HeroItem = ({ isSm, id, title, background_image, font_color, comments_count, votes_count }) => {
  const fill = font_color === "white" ? "white" : "black500";
  const { t } = useTranslation();

  return (
    <HeroCard background={background_image}>
      <Card.Header height={isSm ? 122 : 162}>
        <Title level={isSm ? 4 : 3} color={fill} fontWeight={500} margin="0">
          {title}
        </Title>
      </Card.Header>
      <Card.Footer>
        <Button.Link to={`/forum/article/${id}`} theme={fill} arrow margin="0 auto 0 0">
          {t("home.read_story")}
        </Button.Link>
        <Label noBackground color={fill} fontSize={14}>
          <Icon as={Like} width={16} fill={fill} /> {votes_count}
        </Label>
        <Label noBackground color={fill} fontSize={14}>
          <Icon as={Comment} width={16} fill={fill} /> {comments_count}
        </Label>
      </Card.Footer>
    </HeroCard>
  );
};

const HeroCard = styled(Card)`
  ${({ background }) => `
        background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${background});
        background-size: cover;
    `}
`;

const HeadingWrapper = styled.div`
  overflow: hidden;
  z-index: 1;
  display: block;
  background-color: #fff;
  border-radius: 8px;
  padding: 0;
  position: relative;
  margin: 0 auto;
  text-decoration: none;
  outline: none;
  @media screen and (max-width: 767px) {
    border-radius: 0;
  }
`;

const MainImage = styled.div`
  max-width: 100%;
  height: 100%;
  min-height: 320px;
  position: relative;
  img {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

const HeadingContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 16px;
`;
const HeadingMobileContent = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 15px;
  width: 100%;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
`;

export default Heading;
