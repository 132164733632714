import React, { useState, useMemo, useEffect, useRef } from "react";

import { Redirect, useHistory } from "react-router-dom";

import { Row } from "react-grid-system";

import ModalConsumer from "contexts/modal";
import { useErrorFocus } from "utils/hooks";
import alert from "images/b2c/profilePage/alert.png";
import { useTranslation } from "b2c/hooks/useTranslation";
import Card from "../Core/Card";
import Title from "../Core/Title";
import Button from "../Core/Button";
import Spinner from "../Core/Spinner";
import Text from "../Core/Text";
import Image from "../Core/Image";

import { TitleIconHolder, ActionRow } from "./styled";

let resolveHandler;
let rejectHanlder;

const SubPageSection = ({
  title,
  sectionIcon,
  submitAction,
  resolveAction,
  loading = true,
  processing,
  disableSubmit = false,
  initialFields,
  dynamicFields,
  redirect,
  confirmation,
  validation,
  children,
  FormActions = DefaultFormActions,
  backAction,
  hiddenTitle = false,
  testScope,
  isApplicationAttachment,
  handleBack,
  ...rest
}) => {
  const form = useRef();
  const { goBack } = useHistory();
  const [formData, setFormData] = useState({});
  const { t } = useTranslation();

  useErrorFocus(form, validation);

  useEffect(() => {
    if (!loading) {
      const modified = { ...formData };

      Object.keys(modified).map(key => {
        if (!modified[key]) delete modified[key];
      });

      setFormData({
        ...initialFields,
        ...modified,
        ...dynamicFields
      });
    } else setFormData();
  }, [dynamicFields, initialFields, loading]);

  const handleChange = (e, value, name) => {
    const target = e ? e.target : { value: "", name: "" };
    setFormData(prev => ({
      ...prev,
      [name || target.name]: value || target.value
    }));
  };

  const handleBackStep = () => {
    if (handleBack) {
      handleBack();
      return;
    }

    goBack();
  };

  const handleSubmit = (e, toggle) => {
    e.preventDefault();

    const pendingConfirmation = new Promise((resolve, reject) => {
      resolveHandler = resolve;
      rejectHanlder = reject;
    });

    submitAction(formData, isApplicationAttachment).then(success => {
      if (success) {
        if (confirmation) {
          toggle(
            <ConfiramtionModalContent
              resolve={resolveHandler}
              reject={rejectHanlder}
              close={() => toggle()}
              {...confirmation}
            />,
            t("global.confirmation")
          );
        } else resolveHandler();
      }
    });

    pendingConfirmation.then(() => (resolveAction ? resolveAction() : handleBackStep())).catch();
  };

  const contentPadding = useMemo(() => {
    if (isApplicationAttachment) {
      return "0";
    }

    if (hiddenTitle) {
      return "30px 15px 0";
    }

    return "0 15px";
  }, [isApplicationAttachment, hiddenTitle]);

  return redirect ? (
    <Redirect to={redirect} />
  ) : (
    <Card
      shadow={!isApplicationAttachment}
      style={{
        margin: "0 0 25px",
        padding: 16,
        animation: "fadeFromBottom 0.5s ease forwards",
        ...(loading && { height: 400, width: "100%", display: "flex" })
      }}
    >
      {loading ? (
        <Spinner size="75px" margin="auto" />
      ) : (
        <ModalConsumer>
          {({ toggle }) => (
            <form onSubmit={e => handleSubmit(e, toggle)} noValidate>
              {!hiddenTitle && (
                <Card.Header
                  padding="15px 15px 30px"
                  style={{
                    alignItems: "center"
                  }}
                >
                  <TitleIconHolder>{sectionIcon}</TitleIconHolder>

                  <Title level={4} fontWeight={700} margin="0 0 0 15px" testScope={testScope}>
                    {title}
                  </Title>
                </Card.Header>
              )}
              <Card.Content padding={contentPadding}>{children(handleChange)}</Card.Content>
              <Card.Separator margin="15px 0 35px" />
              <Card.Footer
                styled={{
                  justifyContent: "flex-end !important",
                  padding: "0 15px 15px"
                }}
              >
                <FormActions
                  loading={loading}
                  processing={processing}
                  disableSubmit={!formData || disableSubmit}
                  backAction={backAction}
                  goBack={handleBackStep}
                  dataKeysLength={formData ? Object.keys(formData).length : 0}
                  {...rest}
                />
              </Card.Footer>
            </form>
          )}
        </ModalConsumer>
      )}
    </Card>
  );
};

const DefaultFormActions = ({ loading, processing, disableSubmit, goBack, backAction, dataKeysLength }) => {
  const { t } = useTranslation();

  return (
    <Row justify="between" style={{ margin: 0, width: "100%", flexWrap: "nowrap" }}>
      {backAction ? (
        <Button type="button" theme="grey100" margin="auto 16px 0 0" width={46} onClick={backAction}>
          {"<"}
        </Button>
      ) : (
        <span />
      )}
      <ActionRow>
        <Button type="button" onClick={goBack} theme="grey100" margin="0" tag="span">
          {t("global.cancel")}
        </Button>
        <Button
          processing={processing}
          type="submit"
          margin="0 0 0 20px"
          disabled={processing || loading || dataKeysLength === 0 || disableSubmit}
          testControl="save-button"
          color="primaryButtonLabelColor"
        >
          {t("global.save_button")}
        </Button>
      </ActionRow>
    </Row>
  );
};
const ConfiramtionModalContent = ({ resolve, reject, description, decline, accept, onAccept, close }) => {
  return (
    <>
      <Row justify="center">
        <Image width={100} objectFit="contain" src={alert} margin="auto" />
      </Row>
      <Text textAlign="center" margin="24px auto" fontSize={16} style={{ maxWidth: 500 }}>
        {description}
      </Text>
      <Row justify="center">
        <Button
          theme="grey100"
          margin="auto 8px"
          width="150px"
          onClick={() => {
            resolve();
            close();
          }}
        >
          {decline}
        </Button>
        <Button
          width="150px"
          margin="auto 8px"
          onClick={() => {
            reject();
            onAccept();
            close();
          }}
        >
          {accept}
        </Button>
      </Row>
    </>
  );
};

export default SubPageSection;
