import React, { useLayoutEffect, useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";

import { useDocumentTitle, useQueryParams } from "utils/hooks";
import { stringify } from "utils/qsUtil";
import { useTranslation } from "b2c/hooks/useTranslation";
import Categories from "../Categories";
import Layout from "../../../../components/Layout";
import Section from "../../../../components/Section";
import { Pagination, Text, Title } from "../../../../components/Core";
import CourseSearchTools from "../CourseSearchTools";
import Item from "../../../../components/FeedSections/CoursesSection/CoursesItem";
import { LoadingContent } from "../../../../components/Core/Spinner";
import useStore from "../../../../contexts/store";
import { getCompanyName } from "../../../../contexts/branded";

const Search = () => {
  const {
    Learning: {
      state: { loading },
      params: { filters: appliedFilters, page, per_page: perPage },
      title,
      setPage,
      courses,
      pageCount,
      categories,
      filters,
      initializeSearch,
      appliedCount,
      applyFilter,
      resetFilters,
      disableSearch,
      setOldStringifyParams
    },
    Saved: { handleSaving }
  } = useStore();
  const [categoryView, setCategoryView] = useState(!categories);
  const query = useQueryParams();
  const history = useHistory();
  const basePath = `${history.location.pathname}?${stringify({ per_page: perPage, filters: appliedFilters })}`;
  const anyFilter = !(
    appliedFilters?.category_id ||
    appliedFilters?.organizer_id ||
    appliedFilters?.term ||
    appliedFilters?.skill_level_ids?.length
  );
  const isNotViewCategoryPage = anyFilter || categoryView;
  const { t } = useTranslation();

  useLayoutEffect(() => {
    initializeSearch(history, query);
    !categoryView && setPage(1);

    return () => {
      disableSearch();
    };
  }, []);

  useLayoutEffect(() => {
    if (appliedCount > 0) {
      setCategoryView(false);
      window?.scrollTo(0, 0);
    }
  }, [appliedCount]);

  const changePage = pages => {
    window.scrollTo(0, 0);
    setPage(pages);
  };

  const activeCategoryName =
    appliedFilters?.category_id && categories?.length
      ? categories.find(item => String(item.id) === String(appliedFilters.category_id)).name
      : title;

  useEffect(() => {
    return () => {
      setOldStringifyParams("");
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useDocumentTitle(getCompanyName());

  return (
    <LoadingContent loading={!(courses || categories)} size="72px" margin="auto" minHeight={600} secondaryFill="white">
      <CourseSearchTools
        loading={loading}
        minimize={categoryView}
        appliedCount={appliedCount}
        searchFilters={{ ...filters, categories }}
        applyFilter={applyFilter}
        appliedFilters={appliedFilters || {}}
        resetFilters={resetFilters}
      />
      {isNotViewCategoryPage ? (
        <Categories categories={categories} selectCategory={category_id => applyFilter({ category_id })} />
      ) : (
        <>
          <Layout.Content size="xl" style={{ margin: "24px auto 76px" }}>
            {activeCategoryName && (
              <Title level="1" fontWeight="600" margin="0 0 24px" fontSize="24px">
                {activeCategoryName}
              </Title>
            )}
            <Section expanded columns={4}>
              {((!loading && courses) || [...new Array(8).fill({ loading: true })]).map((item, i) => (
                <Item
                  key={`item-${i + 1}`}
                  loading={loading}
                  saveItem={saved => handleSaving(item.id, "course", saved)}
                  {...item}
                  reviews_count={item?.rating?.count}
                  rating={item?.rating?.value}
                />
              ))}
            </Section>

            <Pagination
              padding="32px 0"
              disabled={loading}
              page={page}
              last={pageCount}
              setPage={changePage}
              isLink
              isFullUrl
              basePath={`${basePath}&page=`}
            />
            {courses?.length === 0 && (
              <Text fontSize={18} fontWeight={600}>
                {t("learn.no_courses")}
              </Text>
            )}
          </Layout.Content>
        </>
      )}
    </LoadingContent>
  );
};

export default observer(Search);
