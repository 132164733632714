import React, { useContext, useEffect, useRef, useState, useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import { ScreenClassContext } from "react-grid-system";
import { Globe, Menu, X, RightArrowAlt } from "@styled-icons/boxicons-regular";

import MessagesIcon from "images/b2c/icons/messages_black.svg";
import MessagesEmptyIcon from "images/b2c/icons/messages-empty.svg";
import BlackLogo from "images/b2b_invite_logo_fuzu.svg";
import Logo from "components/Logo";
import { useHeader } from "b2c/hooks/useHeader";
import { useLanguageAndCountryUrl, useGetPreviousLocation } from "b2c/hooks";
import { disableScroll, enableScroll } from "utils/helpers";
import { useTranslation } from "b2c/hooks/useTranslation";
import BaronaLogo from "b2c/components/BaronaLogo";

import UserSide from "../UserSide";
import {
  HeroCardWrapper,
  StyledHeader,
  StyledHeaderContainer,
  ForEmployerOrSeekers,
  Login,
  LoginSidebar,
  LoginFlow,
  LoginEmployer,
  LanguageSwitcherContainer,
  LanguageSwitcherDivider,
  LanguageSwitcher,
  JoinNow,
  HamburgerContent,
  HamburgerButton,
  HamburgerFooter,
  FuzuLtd,
  Actions,
  // Flex,
  HamburgerMenu,
  MobileSidebar,
  MobileSidebarHeading,
  MobileSidebarHeadingClose,
  MobileSidebarNavItem,
  MobileSidebarNavItemLink,
  MobileSidebarFooter
} from "./styled";
import NavigationDesktop from "../NavigationDesktop";
import NavigationMobile from "../NavigationMobile";
import NotificationBanner from "../NotificationBanner";
import Impersonate from "../../../components/Support/Impersonate";
import { BrandHidden, isCustomJkuat, isKepsa } from "../../contexts/branded";
import PoweredByFuzu from "../../pages/AuthRoutes/components/PoweredByFuzu";
import useStore from "../../contexts/store";
import { useModal } from "../../../utils/hooks";
import LanguageSelector from "../../pages/LanguageSelector";

const Header = ({ isBarona, isMultiLanguageBrand }) => {
  const {
    Theme,
    unreadCount,
    brandName,
    headerLogo,
    loadingUrl,
    isHeroCard,
    push,
    homePath,
    employers,
    isCompaniesPage,
    normalFuzu,
    customPlatformLoggedIn,
    isShowHeader,
    headerClass,
    user,
    navigation,
    toggleUserAccountMenu,
    userAccountMenu,
    pathname,
    impersonateUser
  } = useHeader({ isBarona });
  const history = useHistory();

  const { isPreviousApplicationDetailPage } = useGetPreviousLocation();
  const lastPoint = useRef(null);
  const scrollPending = useRef(false);
  const isMessage = pathname === "/messages";
  const isLandingOrEmployerPage = pathname === "/" || pathname === "/employer" || pathname === "/companies";
  const screen = useContext(ScreenClassContext);
  const isSm = /xs|sm|md/.test(screen);
  const [activeHamburgerJkuat, setActiveHamburgerJkuat] = useState(false);
  const [activeMainSidebar, setActiveMainSidebar] = useState(false);
  const { t } = useTranslation();

  const { global_brand: globalBrand } = useStore("initialState");

  const openLanguageSelector = useModal(
    <LanguageSelector
      countries={globalBrand.countries}
      defaultPage={globalBrand.default}
      globalBrand={globalBrand}
      isModal
    />,
    ""
  );

  const handleHamburgerJkuat = () => {
    setActiveHamburgerJkuat(prev => !prev);
  };

  const handleHamburgerMain = () => {
    setActiveMainSidebar(prev => !prev);
  };

  const onClickHandler = () => {
    const isAccountMenuOpen = user && userAccountMenu;
    if (isAccountMenuOpen) {
      toggleUserAccountMenu();
    }
  };

  const onTouchstartHandler = e => {
    lastPoint.current = e.touches[0].clientX;
  };

  const onScrollHandler = () => {
    scrollPending.current = true;
  };

  const onTouchendHandler = event => {
    const endOfTouch = event.changedTouches[0].clientX;
    const startToTouch = lastPoint.current;
    const edgeOfTheScreen = 15;
    const admissibleTouch = 5;
    const allowToOpen =
      user &&
      !userAccountMenu &&
      startToTouch <= edgeOfTheScreen &&
      startToTouch < endOfTouch - admissibleTouch &&
      !scrollPending.current;
    const allowToClose =
      user && userAccountMenu && startToTouch > endOfTouch + admissibleTouch && !scrollPending.current;

    if (allowToOpen || allowToClose) {
      toggleUserAccountMenu();
    }

    scrollPending.current = false;
  };

  const messageIcon = isMessage ? (
    <MessagesIcon width={32} fill={Theme.black350} />
  ) : (
    <MessagesEmptyIcon width={32} fill={Theme.black350} />
  );

  useEffect(() => {
    window.addEventListener("touchstart", onTouchstartHandler);
    window.addEventListener("touchend", onTouchendHandler);
    window.addEventListener("click", onClickHandler);
    window.addEventListener("scroll", onScrollHandler);
    return () => {
      window.removeEventListener("touchstart", onTouchstartHandler);
      window.removeEventListener("touchend", onTouchendHandler);
      window.removeEventListener("click", onClickHandler);
      window.addEventListener("scroll", onScrollHandler);
    };
  }, [userAccountMenu]);

  useEffect(() => {
    if (activeHamburgerJkuat || activeMainSidebar) {
      disableScroll();
    } else {
      enableScroll();
    }

    return () => {
      enableScroll();
    };
  }, [activeHamburgerJkuat]);

  const isShowNormalHeader = !isPreviousApplicationDetailPage && navigation && (normalFuzu || customPlatformLoggedIn);
  const mainHeaderGuest = isSm && isLandingOrEmployerPage && !user?.id ? "main-header_guest" : "";
  const styledHeaderClassName = !isBarona && isSm && !user?.id ? "styled-header_guest" : "";
  const customJkuat = isCustomJkuat();
  const isKepsaBrand = isKepsa();
  const styledHeaderJkuat = isSm && customJkuat ? "styled-header_jkuat" : "";
  const languageAndLanguage = useLanguageAndCountryUrl();
  const languageAndLanguagePath = languageAndLanguage || homePath;

  const renderEmployerText = () => {
    if (isBarona) {
      return <>{!isCompaniesPage ? t("navigation.companies") : t("navigation.job_seekers")}</>;
    }
    return <>{!employers ? t("navigation.employers") : t("navigation.job_seekers")}</>;
  };

  const countryAndLanguage = useMemo(() => {
    const { pathname } = history.location;
    const [country, lang] = pathname.split("/").slice(1, 3);

    const currentCountry = globalBrand?.countries.find(item =>
      item.country_code ? item.country_code.toLowerCase() === country : {}
    );
    const currentLanguage = currentCountry ? currentCountry.translations.find(item => item.locale === lang) : {};

    return { country: currentLanguage?.locale?.toUpperCase(), language: currentLanguage.name };
  }, []);

  const renderUserSide = () => {
    if (user) {
      return (
        <UserSide
          Theme={Theme}
          pathname={pathname}
          id={user.id}
          fullName={user.full_name}
          email={user.email}
          firstName={user.first_name}
          avatar={user.avatar}
          unreadCount={unreadCount}
          push={push}
          MessageIcon={messageIcon}
          customJkuat={customJkuat}
        />
      );
    }

    return (
      <LoginFlow>
        <MobileSidebar $isActive={activeMainSidebar}>
          <MobileSidebarHeading>
            <BaronaLogo>
              <BlackLogo />
            </BaronaLogo>
            <MobileSidebarHeadingClose onClick={handleHamburgerMain}>
              <X />
            </MobileSidebarHeadingClose>
          </MobileSidebarHeading>

          <MobileSidebarNavItem to={!user?.id ? languageAndLanguagePath || "/" : "/home"} onClick={handleHamburgerMain}>
            {t("navigation.home")} <RightArrowAlt />
          </MobileSidebarNavItem>

          <MobileSidebarNavItemLink href={`${languageAndLanguagePath || ""}/job`} onClick={handleHamburgerMain}>
            {t("navigation.jobs")}
            <RightArrowAlt />
          </MobileSidebarNavItemLink>

          <MobileSidebarNavItem
            to={`${isBarona ? languageAndLanguagePath || "" : ""}/learn`}
            onClick={handleHamburgerMain}
          >
            {t("navigation.learn")}
            <RightArrowAlt />
          </MobileSidebarNavItem>

          <MobileSidebarNavItem to={`${languageAndLanguagePath || ""}/forum`} onClick={handleHamburgerMain}>
            {isBarona ? t("navigation.articles") : t("navigation.forum")}
            <RightArrowAlt />
          </MobileSidebarNavItem>

          {!isMultiLanguageBrand && (
            <MobileSidebarNavItem
              onClick={handleHamburgerMain}
              to={employers || isCompaniesPage ? `${languageAndLanguagePath}` : `${languageAndLanguagePath}/employers`}
            >
              {renderEmployerText()}
              <RightArrowAlt />
            </MobileSidebarNavItem>
          )}

          {isMultiLanguageBrand && countryAndLanguage?.country && (
            <LanguageSwitcherContainer>
              <LanguageSwitcher onClick={openLanguageSelector}>
                <Globe />
                {countryAndLanguage.language} – {countryAndLanguage.country}
              </LanguageSwitcher>
            </LanguageSwitcherContainer>
          )}

          <MobileSidebarFooter>
            <LoginSidebar to="/login">{t("global.login")}</LoginSidebar>

            <JoinNow
              backgroundColor={Theme.primaryButtonBackground}
              color={Theme.primaryButtonLabelColor}
              border="none"
              to="/onboarding"
              marginRight="0"
              data-cy="login-button"
            >
              {t("global.joinNow")}
            </JoinNow>
          </MobileSidebarFooter>
        </MobileSidebar>

        {!isSm && isMultiLanguageBrand && (
          <>
            <LanguageSwitcher onClick={openLanguageSelector}>
              <Globe />
              {countryAndLanguage.language} – {countryAndLanguage.country}
            </LanguageSwitcher>
            <LanguageSwitcherDivider />
          </>
        )}

        {!isSm && renderEmployerText() && !isMultiLanguageBrand ? (
          <ForEmployerOrSeekers
            color={Theme.primaryButtonLabelColor}
            margin="0 14px 0 0"
            to={employers || isCompaniesPage ? `${languageAndLanguagePath}` : `${languageAndLanguagePath}/employers`}
          >
            <>{renderEmployerText()}</>
          </ForEmployerOrSeekers>
        ) : null}

        {!employers ? (
          <>
            <Login
              backgroundColor={Theme.guestHeaderBackgroundColor}
              color={Theme.primaryButtonLabelColor}
              border={`1px solid ${Theme.secondaryButtonBorder}`}
              data-cy="login-button"
              to="/login"
            >
              {t("global.login")}
            </Login>

            <JoinNow
              backgroundColor={Theme.primaryButtonBackground}
              color={Theme.primaryButtonLabelColor}
              border="none"
              to="/onboarding"
              marginRight="0"
              data-cy="login-button"
            >
              {t("global.joinNow")}
            </JoinNow>

            <HamburgerMenu onClick={handleHamburgerMain} $color={isBarona ? "#fff" : "#666666"}>
              <Menu />
            </HamburgerMenu>
          </>
        ) : (
          <LoginEmployer href="https://manage.fuzu.com/employers/login" target="_self">
            {t("global.login")}
          </LoginEmployer>
        )}
      </LoginFlow>
    );
  };

  const renderLogoWhenLoading = () => {
    const currentYear = new Date().getFullYear();

    if (loadingUrl) {
      return (
        <a href={loadingUrl} style={{ margin: "auto" }}>
          <Logo logo={headerLogo} />
        </a>
      );
    }

    if (isKepsaBrand) {
      return (
        <>
          <Link to="/" style={{ margin: "auto 0" }}>
            <Logo logo={headerLogo} width="180px" height="40px" />
          </Link>
          <Login to="/login">Log in</Login>
        </>
      );
    }

    if (customJkuat) {
      return (
        <>
          <Link to={languageAndLanguagePath}>
            <Logo logo={headerLogo} margin="0 auto 0 0" />
          </Link>
          <HamburgerButton onClick={handleHamburgerJkuat}>
            <Menu />
          </HamburgerButton>
          <HamburgerContent $isActive={activeHamburgerJkuat}>
            <Login to="/login">Login</Login>
            <HamburgerFooter>
              <Actions>
                <a href="/terms-and-conditions" className="hamburger-link" rel="noopener noreferrer" target="_blank">
                  Service Terms
                </a>
                |
                <a href="/privacy-policy" className="hamburger-link" rel="noopener noreferrer" target="_blank">
                  Privacy Policy
                </a>
              </Actions>
              <PoweredByFuzu margin="0" />
              <FuzuLtd>© Fuzu Ltd {currentYear}</FuzuLtd>
            </HamburgerFooter>
          </HamburgerContent>
        </>
      );
    }

    return <Logo logo={headerLogo} margin="auto o" />;
  };

  return (
    <>
      {!isShowHeader && (
        <StyledHeader className={styledHeaderClassName}>
          <StyledHeaderContainer
            className={`main-header ${headerClass} ${styledHeaderJkuat} ${mainHeaderGuest} main-header_home`}
            fontColor={Theme.black350}
            fontColorActive={Theme.black900}
            background_color={Theme.guestHeaderBackgroundColor}
            borderBottom={`1px solid ${Theme.grey400}`}
          >
            {!isHeroCard && (
              <div className="content" data-cy="header-content">
                {isShowNormalHeader ? (
                  <>
                    <NavigationDesktop
                      headerLogo={headerLogo}
                      brandName={brandName}
                      homePath={homePath}
                      user={user}
                      employers={employers}
                      customJkuat={customJkuat}
                      isBarona={isBarona}
                      isCompaniesPage={isCompaniesPage}
                      isMultiLanguageBrand={isMultiLanguageBrand}
                    />
                    <NavigationMobile
                      headerLogo={headerLogo}
                      user={user}
                      userAccountMenu={userAccountMenu}
                      toggleUserAccountMenu={toggleUserAccountMenu}
                      employers={employers}
                      homePath={homePath}
                      employerText={renderEmployerText()}
                      isCompaniesPage={isCompaniesPage}
                      isMultiLanguageBrand={isMultiLanguageBrand}
                    />
                    {renderUserSide()}
                  </>
                ) : (
                  <>{renderLogoWhenLoading()}</>
                )}
              </div>
            )}
            {isHeroCard && (
              <HeroCardWrapper>
                <Link to={homePath}>
                  <Logo logo={headerLogo} alt={brandName} />
                </Link>
              </HeroCardWrapper>
            )}
          </StyledHeaderContainer>
        </StyledHeader>
      )}
      <BrandHidden brand="global">
        <NotificationBanner />
      </BrandHidden>
      {impersonateUser && <Impersonate impersonateUser={impersonateUser} />}
    </>
  );
};

export default observer(Header);
