import React from "react";
import { useTranslation } from "b2c/hooks/useTranslation";

import { capitalize } from "utils/helpers";
import { Tag, Title } from "../Core";
import HardReloadLink from "../../../components/HardNavigationLink";
import { pathWithCountry } from "../../utils/routes";
import { useLanguageAndCountryUrl } from "../../hooks";

const patterns = ["/:category", "/:industry", "/:seniority-level", "/:town", "/:country", "/:city-category"];

const PositionTags = ({ country, industry, town, category, seniority_level, job_type }) => {
  const { t } = useTranslation();
  const languageAndLanguage = useLanguageAndCountryUrl();

  const availablePatterns = (() => {
    return patterns
      .filter(p => !!category?.slug || !/:category/.test(p))
      .filter(p => (!!category?.slug && !!town?.slug) || !/:city-category/.test(p))
      .filter(p => !!industry?.slug || !/:industry/.test(p))
      .filter(p => !!seniority_level?.key || !/:seniority-level/.test(p))
      .filter(p => !!town?.slug || !/:town/.test(p))
      .map(path => {
        if (languageAndLanguage) {
          return `${languageAndLanguage}/job${path}`;
        }

        if (/:country/.test(path)) {
          return pathWithCountry(country, path);
        }

        return pathWithCountry(country, `/job${path}`);
      });
  })();

  const generateTag = path => {
    const tag = {};

    tag.name = (() => {
      const name = [];

      if (/:category/.test(path)) name.push(category?.name);
      if (/:city-category/.test(path) && category?.name)
        name.push(`${category?.name} ${t("jobs.content.jobs_in")} ${town?.name}`);
      if (/:seniority-level/.test(path)) name.unshift(seniority_level?.name);
      if (/:work-type/.test(path)) name.unshift(job_type?.name);
      if (/:industry/.test(path)) {
        if (name.length > 0) name.push(t("jobs.content.jobs_in"));
        name.push(
          `${industry?.name} ${t("jobs.content.industry")}${name.length > 0 ? "" : ` ${t("jobs.content.jobs")}`}`
        );
      }
      if (!/jobs/.test(name.join()) && !/:city-category/.test(path)) name.push(t("jobs.content.jobs"));
      if (/:town/.test(path)) name.push(`${t("jobs.content.in")} ${town?.name}`);
      if (/:country/.test(path)) name.push(`${t("jobs.content.in")} ${country?.name}`);

      return capitalize(name.join(" "));
    })();

    tag.pathname = path
      .replace(":category", category?.slug)
      .replace(":city-category", `${category?.slug}/${town?.slug ? town?.slug : ""}`)
      .replace(":industry", industry?.slug)
      .replace(":seniority-level", seniority_level?.key)
      .replace(":work-type", job_type?.slug)
      .replace(":town", town?.slug)
      .replace("/:country", "");

    return tag;
  };

  const renderTag = path => {
    const { name, pathname } = generateTag(path);
    if (!pathname) {
      return;
    }

    return (
      <Tag
        key={pathname}
        as={HardReloadLink}
        name={name.replace("Jobs in", t("jobs.content.jobs_in")).replace("jobs in", t("jobs.content.jobs_in_lower"))}
        to={pathname}
        borderColor="secondary200"
        padding="4px 8px"
        height="auto"
        removable={false}
        maxLength={100}
        tabIndex="-1"
        margin="0 0 8px 8px"
      />
    );
  };

  return availablePatterns.length > 0 ? (
    <div className="view-summary">
      <Title scales level={5} fontWeight={600} margin="8px 0">
        {t("jobs.content.tags")}
      </Title>
      <div className="view-summary-content view-summary-content_tag">{availablePatterns.map(renderTag)}</div>
    </div>
  ) : null;
};

export default PositionTags;
