import React from "react";

import { Input } from "../../Core";

const ValidateEmail = ({ email, error }) => {
  return (
    <>
      <Input
        type="email"
        name="email"
        placeholder="Enter your email address"
        label="Email address"
        defaultValue={email}
        errorMessage={error}
      />
    </>
  );
};

export default ValidateEmail;
