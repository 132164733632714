import React from "react";

import { Card, Text, Title } from "../Core";
import { useTranslation } from "../../hooks/useTranslation";

const FaqItem = ({ title, text }) => {
  const { t } = useTranslation();

  return (
    <Card wideOnMobile theme="grey100" bordered style={{ margin: "20px 0 0" }}>
      <Card.Content padding="15px">
        <Title level="6" fontWeight={500} margin="0 0 4px">
          {t(title)}
        </Title>
        <Text fontSize={14}>{t(text)}</Text>
      </Card.Content>
    </Card>
  );
};

export default FaqItem;
