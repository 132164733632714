import React, { useState, useLayoutEffect, useContext } from "react";
import { Redirect, useParams } from "react-router-dom";

import { useDocumentTitle, useQueryParams } from "utils/hooks";
import { brandName } from "b2c/constants/brand";
import NcpwdCheck from "../NcpwdCheck";
import Form from "../Form";
import Confirmation from "../Confirmation";
import Ncpwd from "../Ncpwd";
import BrandedContext, { isCustomJkuat, isKepsa, isWhitelabelledOrJkuat } from "../../../../contexts/branded";

import RegistrationHeadContent from "../RegistrationHeadContent";

const formInit = {
  first_name: "",
  last_name: "",
  passport_number: "",
  email: "",
  password: "",
  confirmation: "",
  fuzu_country_id: null,
  visible: false,
  terms: false
};

const Registration = ({
  state,
  first_name,
  email,
  countries,
  initialStep = 1,
  getCountries,
  userRegister,
  userDetails,
  resetUser,
  ncpwdValidateUser,
  checkEmail,
  ncpwdLogin,
  validation_errors,
  resetValidationErrors,
  fromUrl = "/home",
  notifyMnt
}) => {
  const whitelabelled = isWhitelabelledOrJkuat();
  const isKepsaBrand = isKepsa();
  const customJkuat = isCustomJkuat();

  const redirectTo = () => {
    if (whitelabelled) {
      if (isKepsaBrand) {
        return "/home";
      }
      if (fromUrl === "/home") {
        return "/";
      }
      return fromUrl || "/";
    }

    return "/onboarding";
  };

  const { slug, employer } = useContext(BrandedContext);
  const { refer_code, return_to } = useQueryParams();
  const [step, setStep] = useState(1);
  const { slug: routeSlug } = useParams();
  const [userForm, setUserForm] = useState({
    ...formInit,
    email,
    first_name,
    fuzu_country_id: slug === "ncpwd" ? 1 : routeSlug === "mtn-pulse" ? 232 : null
  });

  const [ncpwd, setNcpwd] = useState({
    number: undefined,
    national_id: undefined
  });

  const [confirmation, setConfirmation] = useState({
    email: email || "",
    fuzu_country_id: null,
    talent_pool_visibility: true
  });

  useLayoutEffect(() => {
    if (first_name !== userForm.first_name) setUserForm(prev => ({ ...prev, first_name }));
  }, [first_name]);

  useLayoutEffect(() => {
    if ((initialStep <= 2 || initialStep === 4) && !countries) getCountries(routeSlug);
  }, []);

  useLayoutEffect(() => {
    setStep(initialStep);
  }, [initialStep]);

  useLayoutEffect(() => {
    resetValidationErrors();
  }, [step]);

  const formStepSuccess = (valid, form) => {
    setUserForm(form);
    return userRegister(valid, form, ncpwd.number, refer_code, slug === brandName.jkuat).then(proceed => {
      if (proceed) {
        if (isKepsaBrand || customJkuat) {
          window.location.href = "/home";
          return null;
        } else {
          setStep(3);
          if (routeSlug === "mtn-pulse") {
            notifyMnt();
          }
        }
      }
    });
  };

  const handleNcpwd = form => {
    setNcpwd(form);
    return ncpwdValidateUser(form).then(proceed => {
      if (proceed) {
        setStep(4);
      }
    });
  };

  const handleConfirmation = form => {
    setConfirmation(form);
    return userDetails(form).then(proceed => {
      if (proceed) {
        setStep(3);
      }
    });
  };

  useDocumentTitle(`Registration - Step ${step + 1 - initialStep} of ${3 - initialStep}`);

  const renderContents = () => {
    switch (step) {
      case 0:
        return <Ncpwd state={state} form={ncpwd} submitForm={handleNcpwd} />;
      case 4:
        return (
          <NcpwdCheck
            state={state}
            ncpwd_id={ncpwd?.number}
            email={userForm?.email}
            checkEmail={checkEmail}
            setEmail={emails => setUserForm(prev => ({ ...prev, email: emails }))}
            ncpwdLogin={ncpwdLogin}
            setStep={setStep}
          />
        );
      default:
      case 1:
        return (
          <Form
            state={state}
            form={userForm}
            ncpwd={slug === "ncpwd"}
            isJKUAT={slug === brandName.jkuat}
            countries={countries}
            submitForm={formStepSuccess}
            validation_errors={validation_errors}
            authChannel={routeSlug}
            setStep={setStep}
            setEmail={emails => setUserForm(prev => ({ ...prev, email: emails }))}
          />
        );
      case 2:
        return (
          <Confirmation
            state={state}
            countries={countries}
            form={confirmation}
            submitForm={handleConfirmation}
            validation_errors={validation_errors}
            resetUser={resetUser}
          />
        );
      case 3:
        return <Redirect to={redirectTo()} />;
    }
  };

  return (
    <>
      <RegistrationHeadContent
        slug={slug}
        step={step}
        employer={employer}
        routeSlug={routeSlug}
        resetUser={resetUser}
        returnTo={return_to}
      />
      {renderContents()}
    </>
  );
};

export default Registration;
