import styled from "styled-components";

import EmptyListImage from "./assets/empty-list.svg";

export const StyledEmptyListImage = styled(EmptyListImage)`
  margin: 48px auto 0;
  display: block;
`;

export const StyledTitle = styled.div`
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #000000;
  margin-top: 24px;
`;

export const StyledContent = styled.div`
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #666666;
  margin-top: 4px;
`;
