import React, { useState, useEffect, useCallback, useRef } from "react";
import { observer } from "mobx-react";
import { Row, Col } from "react-grid-system";
import { useTranslation } from "b2c/hooks/useTranslation";

import SvgContent from "b2c/components/SvgContent";
import Tooltip from "components/Tooltip";
import WorkExperienceIco from "images/b2c/profilePage/WorkExperienceIco.svg";
import { capitalize } from "utils/helpers";
import useStore from "../../contexts/store";
import { Card, Text, Input, Dropdown, Textarea, Checkbox } from "../Core";
import SubPageSection from "../SubPageSection";
import CompanyInput from "../CompanyInput";
import { getCompanyName } from "../../contexts/branded";
import { languageFormat } from "../../constants/main";

const WorkExperience = ({ itemId, inProfileWizard, ...rest }) => {
  const {
    Profile: {
      state: { loading, processing },
      profile: { personal_info: { country = {} } = {} } = {},
      work_experience_options: {
        disciplines = [],
        employer_sizes = [],
        industries = [],
        job_levels = [],
        salary_currencies = []
      } = {},
      work_experience_details: {
        id: item_id,
        current_work,
        description,
        discipline_id,
        employer,
        employer_size_id,
        end_date,
        industry_id,
        job_level_id,
        job_title,
        salary,
        salary_currency,
        start_date,
        waits_user_review
      } = {},
      validation_errors,
      initializeWorkExperience,
      addWorkExperience,
      updateWorkExperience
    }
  } = useStore("User");
  const [startDate, setStartDate] = useState({});
  const [endDate, setEndDate] = useState({});
  const [isCurrentWork, setCurrentWork] = useState(false);
  const [salaryValue, setSalaryValue] = useState("");
  const { t, language } = useTranslation();
  const languageFormatType = languageFormat[language];
  const companyName = getCompanyName();
  const salaryRef = useRef();

  useEffect(() => {
    initializeWorkExperience(itemId);
  }, [itemId]);

  useEffect(() => {
    setStartDate(formatDate(start_date));
  }, [start_date]);

  useEffect(() => {
    setSalaryValue(salary);
  }, [salary]);

  useEffect(() => {
    setEndDate(formatDate(end_date));
  }, [end_date]);

  useEffect(() => {
    setCurrentWork(current_work);
  }, [current_work]);

  const formatDate = date =>
    date
      ? {
          year: +date.substring(0, date.indexOf("-", 0)),
          month: +date.substring(date.indexOf("-", 0) + 1, date.indexOf("-", 5)),
          day: +date.substring(date.length, date.indexOf("-", 7) + 1)
        }
      : {};

  const formatError = useCallback(
    key => {
      return validation_errors[key]
        ? capitalize(validation_errors[key].join(", "))
        : waits_user_review && requiredFields.includes(key) && !initialFields[key]
          ? "Field is missing"
          : null;
    },
    [waits_user_review, validation_errors]
  );

  const months = Array.from({ length: 12 }, (_, i) => ({
    name: new Date(null, i + 1, null).toLocaleString(languageFormatType, { month: "long" }),
    id: i + 1
  }));

  const getMonth = value => (months.some(m => m.id == value) ? months.find(m => m.id == value).name : "");
  /*
  const confirmation = {
    description: `Are you sure you don't want to fill in salary information?
                  Your salary is not shared with anyone and is used solely
                  for collecting statistics about the job market salary trends.`,
    decline: "No, just save",
    accept: "Fill in Salary",
    onAccept: () => salaryRef.current && salaryRef.current.focus()
  };
  */

  const dynamicFields = {
    start: startDate,
    end: endDate,
    current_work: isCurrentWork,
    salary: salaryValue
  };

  const initialFields = {
    employer,
    industry_id,
    employer_size_id,
    job_title,
    discipline_id,
    job_level_id,
    salary_currency: salary_currency || country?.currency,
    description
  };

  const requiredFields = ["employer", "industry_id", "job_title", "discipline_id", "job_level_id"];

  // const disableSubmit = ["month", "year"].some(key => !startDate[key] || (!endDate[key] && !isCurrentWork));

  const getDefaultValue = (id, list) =>
    (list?.some(item => item.id == id) && list.find(item => item.id == id).name) || "";

  return (
    <SubPageSection
      title={t("wizard.work_experience.sub-title")}
      sectionIcon={
        <SvgContent>
          <WorkExperienceIco />
        </SvgContent>
      }
      loading={loading}
      processing={processing}
      submitAction={
        itemId || item_id
          ? formData => updateWorkExperience(itemId || item_id, formData, inProfileWizard)
          : formData => addWorkExperience(formData, true)
      }
      initialFields={initialFields}
      dynamicFields={dynamicFields}
      validation={validation_errors}
      confirmation={false}
      requiredFields={requiredFields}
      {...rest}
    >
      {handleChange => (
        <>
          <Row>
            <Col sm={4}>
              <CompanyInput
                label={t("wizard.work_experience.employer_label")}
                placeholder={t("wizard.work_experience.employer_placeholder")}
                name="employer"
                fieldRequired
                defaultValue={employer}
                onChange={(value, key) => handleChange(null, value, key)}
                errorMessage={formatError("employer")}
              />
            </Col>
            <Col sm={4}>
              <Dropdown
                label={`${t("wizard.work_experience.industry_label")}*`}
                placeholder={t("wizard.work_experience.industry_placeholder")}
                id="industry_id"
                name="industry_id"
                triggerDataCy="choose-industry"
                defaultValue={getDefaultValue(industry_id, industries)}
                errorMessage={formatError("industry_id")}
              >
                <Dropdown.Menu disableSorting>
                  {industries.map(({ name, id }) => (
                    <Dropdown.Item key={id} onClick={() => handleChange(null, id, "industry_id")}>
                      {name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <Col sm={4}>
              <Dropdown
                label={t("wizard.work_experience.size_label")}
                placeholder={t("wizard.work_experience.size_placeholder")}
                id="employer_size_id"
                name="employer_size_id"
                triggerDataCy="select-size"
                defaultValue={getDefaultValue(employer_size_id, employer_sizes)}
                errorMessage={formatError("employer_size_id")}
              >
                <Dropdown.Menu disableSorting>
                  {employer_sizes.map(({ name, id }) => (
                    <Dropdown.Item key={id} onClick={() => handleChange(null, id, "employer_size_id")}>
                      {name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
          <Card.Separator margin="15px 0 30px" />
          <Row>
            <Col sm={4}>
              <Input
                label={t("wizard.work_experience.job_title_label")}
                placeholder={t("wizard.work_experience.job_title_placeholder")}
                name="job_title"
                fieldRequired
                defaultValue={job_title}
                onChange={handleChange}
                errorMessage={formatError("job_title")}
              />
            </Col>
            <Col sm={4}>
              <Row gutterWidth={10} align="end">
                <Col xs={6}>
                  <Dropdown
                    label={`${t("wizard.work_experience.start_month_label")}*`}
                    placeholder={t("wizard.work_experience.start_month_placeholder")}
                    id="employment_start_month"
                    name="employment_start_month"
                    triggerDataCy="start-of-employments-month"
                    defaultValue={getMonth(startDate.month)}
                  >
                    <Dropdown.Menu disableSorting triggerDataCy="start-of-employments-month-menu">
                      {months.map(({ name, id }) => (
                        <Dropdown.Item
                          key={id}
                          onClick={() =>
                            setStartDate({
                              ...startDate,
                              month: id
                            })
                          }
                        >
                          {name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
                <Col xs={6}>
                  <Dropdown
                    id="employment_start_year"
                    name="employment_start_year"
                    triggerDataCy="start-of-employments-year"
                    placeholder={t("wizard.work_experience.start_year_placeholder")}
                    defaultValue={startDate.year}
                  >
                    <Dropdown.Menu disableSorting disableSearch triggerDataCy="start-of-employments-year-menu">
                      {[...Array(92)]
                        .map((_, i) => new Date().getFullYear() - i)
                        .map(year => (
                          <Dropdown.Item
                            key={year}
                            onClick={() =>
                              setStartDate({
                                ...startDate,
                                year
                              })
                            }
                          >
                            {year}
                          </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </Row>
              {validation_errors.start_date && (
                <Text color="accent300" fontSize="12px" margin="4px 0 0" loaded={!!validation_errors.start_date}>
                  {formatError("start_date")}
                </Text>
              )}
            </Col>
            <Col sm={4}>
              <Row gutterWidth={10} align="end">
                <Col xs={6}>
                  <Dropdown
                    label={`${t("wizard.work_experience.end_month_label")}*`}
                    placeholder={t("wizard.work_experience.end_month_placeholder")}
                    id="employment_end_month"
                    name="employment_end_month"
                    triggerDataCy="end-of-employments-month"
                    disabled={isCurrentWork}
                    defaultValue={getMonth(endDate.month)}
                  >
                    <Dropdown.Menu disableSorting triggerDataCy="end-of-employments-month-menu">
                      {months.map(({ name, id }) => (
                        <Dropdown.Item
                          key={id}
                          onClick={() =>
                            setEndDate({
                              ...endDate,
                              month: id
                            })
                          }
                        >
                          {name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
                <Col xs={6}>
                  <Dropdown
                    id="employment_end_year"
                    name="employment_end_year"
                    triggerDataCy="end-of-employments-year"
                    placeholder={t("wizard.work_experience.end_year_placeholder")}
                    disabled={isCurrentWork}
                    defaultValue={endDate.year}
                  >
                    <Dropdown.Menu disableSorting disableSearch triggerDataCy="end-of-employments-year-menu">
                      {[...Array(92)]
                        .map((_, i) => new Date().getFullYear() - i)
                        .map(year => (
                          <Dropdown.Item
                            key={year}
                            onClick={() =>
                              setEndDate({
                                ...endDate,
                                year
                              })
                            }
                          >
                            {year}
                          </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </Row>
              <Checkbox
                name="current_work"
                label={<Text>{t("wizard.work_experience.current_work_label")}</Text>}
                checked={isCurrentWork}
                onChange={() => setCurrentWork(!isCurrentWork)}
                errorMessage={formatError("current_work")}
                margin="-8px 0 8px"
              />
              {validation_errors.end_date && (
                <Text color="accent300" fontSize="12px" margin="4px 0 0" loaded={!!validation_errors.end_date}>
                  {formatError("end_date")}
                </Text>
              )}
            </Col>
          </Row>
          <Row align="end">
            <Col sm={4}>
              <Dropdown
                id="discipline_id"
                name="discipline_id"
                label={
                  <>
                    <Text fontSiz="inherit" fontWeight={500}>
                      {t("wizard.work_experience.category_label")}*
                    </Text>
                    <Text fontSiz="12px">{t("wizard.work_experience.category_description")}</Text>
                  </>
                }
                placeholder={t("wizard.work_experience.category_placeholder")}
                triggerDataCy="choose-job-category"
                defaultValue={getDefaultValue(discipline_id, disciplines)}
                errorMessage={formatError("discipline_id")}
              >
                <Dropdown.Menu disableSorting>
                  {disciplines.map(({ name, id }) => (
                    <Dropdown.Item key={id} onClick={() => handleChange(null, id, "discipline_id")}>
                      {name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <Col sm={4}>
              <Dropdown
                id="job_level_id"
                name="job_level_id"
                label={
                  <>
                    <Text fontSiz="inherit" fontWeight={500}>
                      {t("wizard.work_experience.job_level_label")}*
                    </Text>
                    <Text fontSiz="12px">{t("wizard.work_experience.job_level_description")}</Text>
                  </>
                }
                placeholder={t("wizard.work_experience.job_level_placeholder")}
                triggerDataCy="choose-job-level"
                defaultValue={getDefaultValue(job_level_id, job_levels)}
                errorMessage={formatError("job_level_id")}
              >
                <Dropdown.Menu disableSorting>
                  {job_levels.map(({ name, id }) => (
                    <Dropdown.Item key={id} onClick={() => handleChange(null, id, "job_level_id")}>
                      {name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <Col sm={4}>
              <Row gutterWidth={10} align="end">
                <Col xs={8}>
                  <Dropdown
                    label={t("wizard.work_experience.salary_label")}
                    placeholder={t("wizard.work_experience.salary_placeholder")}
                    id="salary_currency"
                    name="salary_currency"
                    triggerDataCy="monthly-salary"
                    defaultValue={salary_currency || country?.currency}
                    errorMessage={formatError("salary_currency")}
                  >
                    <Dropdown.Menu disableSorting>
                      {salary_currencies.map(currency => (
                        <Dropdown.Item key={currency} onClick={() => handleChange(null, currency, "salary_currency")}>
                          {currency}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
                <Col xs={4}>
                  <Tooltip tooltipContent={t("wizard.work_experience.salary_description")}>
                    <Input
                      ref={salaryRef}
                      name="salary"
                      type="number"
                      placeholder="0000"
                      value={salaryValue}
                      onChange={e => setSalaryValue(e.target.value)}
                      errorMessage={formatError("salary")}
                    />
                  </Tooltip>
                </Col>
              </Row>
            </Col>
          </Row>
          <Textarea
            name="description"
            label={
              <>
                <Text fontSize="inherit" fontWeight="500">
                  {t("wizard.work_experience.description_label")}
                </Text>
                <Text fontSize="12px">
                  {t("wizard.work_experience.description_description", { name: companyName })}
                </Text>
              </>
            }
            placeholder={t("wizard.work_experience.description_placeholder")}
            defaultValue={description}
            onChange={handleChange}
            errorMessage={formatError("description")}
          />
        </>
      )}
    </SubPageSection>
  );
};

export default observer(WorkExperience);
