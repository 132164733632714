import React from "react";
import { observer } from "mobx-react";
import parse from "html-react-parser";

import { Text, Image } from "b2c/components/Core";
import { buildImageUrlS3, getCompanyName } from "b2c/contexts/branded";
import { theme } from "b2c/contexts/theme";
import { nameImages } from "b2c/constants/images";
import { Banner, FastApplyComponent } from "./styled";

const FastApply = ({ t, isHideDescription, isShow, margin, onClick, isAssigmentRequested }) => {
  if (!isShow) {
    return null;
  }

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <FastApplyComponent margin={margin}>
      <Banner
        color={theme.primaryButtonLabelColor}
        backgroundColor={theme.primaryButtonBackground}
        onClick={handleClick}
        tabIndex="0"
        role="button"
      >
        <Image src={buildImageUrlS3(nameImages.fastApply)} width="16px" height="16px" />
        {t("global.fast_apply.title")}
      </Banner>
      {!isHideDescription && (
        <Text color="#292929">
          {isAssigmentRequested ? (
            <>{parse(t("global.fast_apply.description_1", { name: getCompanyName() }))}</>
          ) : (
            <>{parse(t("global.fast_apply.description_2", { name: getCompanyName() }))}</>
          )}
        </Text>
      )}
    </FastApplyComponent>
  );
};

export default observer(FastApply);
