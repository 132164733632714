import React from "react";
import { Comment } from "@styled-icons/boxicons-regular";
import { Link, useHistory } from "react-router-dom";
import { RemoveRedEye } from "@styled-icons/material";

import { useTranslation } from "b2c/hooks/useTranslation";
import { HeroCard } from "./styled";
import { Button, Card, Icon, Label, Title } from "../../Core";

const HeroItem = ({
  isSm,
  title,
  background_image: backgroundImage,
  font_color: fontColor,
  comments_count: commentsCount,
  path,
  views_count: viewsCount
}) => {
  const fill = fontColor === "white" ? "white" : "black500";
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <HeroCard background={backgroundImage}>
      <Link to={path}>
        <Card.Header height={isSm ? 122 : 162} padding="20px 20px 0 20px">
          <Title
            level={isSm ? 5 : 3}
            color={fill}
            fontWeight={600}
            margin="0"
            lineHeight={isSm ? "130%" : "150%"}
            style={{
              height: isSm ? "70px" : "110px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              "-webkit-line-clamp": "3",
              "-webkit-box-orient": "vertical"
            }}
          >
            {title}
          </Title>
        </Card.Header>
        <Card.Footer padding="0 20px 16px 20px">
          <Button theme={fill} arrow margin="0 auto 0 0" onClick={() => history.push(path)}>
            {t("home.read_story")}
          </Button>
          <Label noBackground color={fill} fontSize={14}>
            <Icon as={RemoveRedEye} width={16} /> {viewsCount}
          </Label>
          <Label noBackground color={fill} fontSize={14}>
            <Icon as={Comment} width={16} fill={fill} /> {commentsCount}
          </Label>
        </Card.Footer>
      </Link>
    </HeroCard>
  );
};

export default HeroItem;
