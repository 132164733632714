import React, { useRef, useEffect } from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";

import feature1 from "images/feature1.png";
import feature2 from "images/feature2.png";
import feature3 from "images/feature3.png";
import feature4 from "images/feature4.png";
import { withCustomPlatformHidden } from "b2c/contexts/branded";
import { withTrackedRoute } from "b2c/hocs";
import HireEmployers from "b2c/components/HireEmployers";
import LookingTalentSection from "b2c/components/LookingTalentSection";
import { useStandaloneMode } from "b2c/contexts/standalone";
import Heading from "./components/Heading";
import Feature from "./components/Feature";
import GetApp from "./components/GetApp";
import Install from "./components/Install";
import WhatIsFuzu from "./components/WhatIsFuzu";
import { useGetCurrentCountry } from "../../hooks";

const FuzuApp = () => {
  const getAppRef = useRef();
  const smartNotificationRef = useRef();
  const country = useGetCurrentCountry();
  const isStandalone = useStandaloneMode();

  const history = useHistory();

  useEffect(() => {
    if (isStandalone) {
      history.push("/");
    }
  }, []);

  useEffect(() => {
    if (window.scrollY) {
      window.scroll(0, 0);
    }
  }, []);

  return (
    <>
      <Heading getAppRef={getAppRef} smartNotificationRef={smartNotificationRef} />
      <Feature
        title="Smart notifications"
        text="Fuzu App notifies you about new opportunities or messages from employers and other Fuzu users"
        image={feature1}
        imageWidth="320px"
        imageHeight="115px"
        imageWidthTablet="378px"
        imageHeightTablet="136px"
        smartNotificationRef={smartNotificationRef}
      />
      <Feature
        title="Offline experience"
        text="Use Fuzu App anytime and anywhere. Download content over WiFi and consume later"
        image={feature2}
        isReverse
        imageWidth="320px"
        imageHeight="154px"
        imageWidthTablet="335px"
        imageHeightTablet="161px"
      />
      <Feature
        title="Low data consumption"
        text="Fuzu App helps you spend less on mobile data, by optimizing the content delivery"
        image={feature3}
        imageWidth="320px"
        imageHeight="223px"
        imageWidthTablet="313px"
        imageHeightTablet="218px"
      />
      <Feature
        title="Download only once"
        text="Download and install Fuzu App just once, no need to worry about the updates"
        image={feature4}
        isReverse
        imageWidth="320px"
        imageHeight="163px"
        imageWidthTablet="366px"
        imageHeightTablet="186px"
      />
      <GetApp getAppRef={getAppRef} />
      <Install />
      <WhatIsFuzu country={country} />
      <LookingTalentSection isShowAdditional backgroundColor="transparent" />
      <HireEmployers showFuzuAppText padding="64px 0" />
    </>
  );
};

export default withCustomPlatformHidden(withTrackedRoute(observer(FuzuApp)));
