import React, { useContext } from "react";
import { RightArrowAlt } from "@styled-icons/boxicons-regular";
import { ScreenClassContext } from "react-grid-system";
import { useHistory } from "react-router-dom";

import { useTranslation } from "b2c/hooks/useTranslation";
import { getCompanyName } from "b2c/contexts/branded";
import { BannerBuildProfileComponent } from "./styled";
import { Text, Title, Button } from "../Core";
import ThemeContext from "../../contexts/theme";

const BannerBuildProfile = () => {
  const screen = useContext(ScreenClassContext);
  const isSm = /xs|sm|md|lg/.test(screen);
  const history = useHistory();
  const Theme = useContext(ThemeContext);

  const handleClick = () => {
    history.push("/wizard");
  };

  const { t } = useTranslation();

  return (
    <BannerBuildProfileComponent backgoundColor={Theme.primary} onClick={handleClick} tabIndex="-1" role="button">
      <Title
        style={{
          fontWeight: 600,
          fontSize: isSm ? "12px" : "16px",
          lineHeight: isSm ? "120%" : "140%",
          color: Theme.primaryButtonLabelColor,
          marginBottom: isSm ? "5px" : "10px",
          marginTop: 0
        }}
      >
        {t("promo.want_better.title", { name: getCompanyName() })}
      </Title>
      <Text
        style={{
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: isSm ? "120%" : "140%",
          color: Theme.primaryButtonLabelColor,
          marginBottom: "5px",
          marginTop: "0"
        }}
      >
        {t("promo.want_better.description")}
      </Text>
      <Button.Link
        to="/wizard"
        height="32px"
        margin="auto 0 0 0"
        width="140px"
        style={{
          fontWeight: 500,
          fontSize: "14px",
          lineHeight: "130%",
          color: "#FFFFFF",
          background: "#292929",
          borderRadius: "5px"
        }}
      >
        {t("promo.want_better.button")}
        <RightArrowAlt width={20} />
      </Button.Link>
    </BannerBuildProfileComponent>
  );
};

export default BannerBuildProfile;
