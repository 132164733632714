import styled from "styled-components";

export const PageWrapper = styled.div`
  min-height: 100vh;
  background-color: #f8f9fa;
  display: flex;
  flex-direction: column;

  ${({ $isModal }) =>
    $isModal
      ? `
        max-width: 540px;
        background-color: transparent;
        min-height: auto;
        margin-top: -44px
      `
      : ""}
`;

export const Header = styled.header`
  width: 100%;
  background-color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 16px;

  @media screen and (min-width: 768px) {
    padding: 20px 0;
  }
`;

export const HeaderContent = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  width: 100%;
`;

export const Footer = styled.footer`
  margin-top: auto;
  padding: 0 16px;

  @media screen and (min-width: 768px) {
    padding: 0;
  }
`;

export const FooterContent = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  border-top: 1px solid #c5c5c5;
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  padding: 24px 0 40px 0;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 494px;
  text-align: center;
  padding: ${({ $isModal }) => ($isModal ? "0 12px 0 12px" : "32px 16px")};
  border-radius: 8px;
  margin: ${({ $isModal }) => ($isModal ? "0" : "32px auto")};

  @media screen and (min-width: 768px) {
    padding: ${({ $isModal }) => ($isModal ? "0 12px 0 12px" : "32px")};
    background-color: #fff;
  }
`;

export const Title = styled.h2`
  color: #333;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  margin: 0 0 8px 0;
  text-align: left;

  @media screen and (min-width: 768px) {
    font-size: 24px;
    line-height: 120%;
    text-align: ${({ $isModal }) => ($isModal ? "left" : "center")};
  }
`;

export const Subtitle = styled.p`
  color: #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin: 0 0 16px 0;
  text-align: left;

  @media screen and (min-width: 768px) {
    margin: 0 0 24px 0;
    text-align: ${({ $isModal }) => ($isModal ? "left" : "center")};
  }
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  margin-bottom: 8px;
  cursor: pointer;
  gap: 8px;

  @media screen and (min-width: 768px) {
    gap: 16px;
    margin-bottom: 16px;
  }
`;

export const OptionContent = styled.button`
  padding: 8px 16px;
  text-align: left;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  width: 144px;
  border-radius: 8px;
  border: ${({ $isActive }) => ($isActive ? "1px solid #408bfc" : "1px solid transparent")};

  span {
    color: #666;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    text-align: left;
  }

  @media screen and (min-width: 1300px) {
    &:hover {
      background: #f3f3f3;
    }
  }
`;

export const Flag = styled.div`
  width: 40px;
  height: 24px;
  align-items: center;
  justify-content: center;
  display: none;

  @media screen and (min-width: 768px) {
    display: flex;
  }
`;

export const LanguageLabel = styled.h6`
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin: 0 0 4px 0;
  text-align: left;
`;

export const LanguageFooter = styled.p`
  color: #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  text-align: left;
  margin-top: 40px;
`;

export const Global = styled.a`
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  gap: 16px;
  display: flex;
  align-self: center;
  margin-top: 16px;
  text-decoration: none;

  svg {
    width: 24px;
    height: 24px;
    fill: #666666;
  }
`;
