import React from "react";
import { Input, Hint } from "../../../Core";

const EmailConfirmation = ({ email = "", firstName = "", error }) => {
  return (
    <>
      <Input name="firstName" fieldRequired label="Your first name" defaultValue={firstName} errorMessage={error} />
      <Input type="email" name="email" fieldRequired label="email address" defaultValue={email} errorMessage={error} />
      <Hint>Make sure you give a valid email address so employers can contact you.</Hint>
    </>
  );
};

export default EmailConfirmation;
