import React from "react";
import parse from "html-react-parser";
import { Row } from "react-grid-system";
import { Link } from "react-router-dom";
import { RightArrowAlt } from "@styled-icons/boxicons-regular";
import { useTranslation } from "b2c/hooks/useTranslation";

import Marker from "images/b2c/icons/marker.svg";
import { Button, Text, List, Label, Card, Icon } from "b2c/components/Core";
import { StyledCard } from "./styled";

const PricingPlan = ({ type, badge, countryCode, countrySlug, title, tagline, description, prices, isSm }) => {
  const getIsSafari = () => {
    let isSafari = true;
    const ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf("safari") !== -1) {
      if (ua.indexOf("chrome") > -1) {
        isSafari = false;
      }
    }

    return isSafari;
  };

  const isSafari = getIsSafari();
  const { t } = useTranslation();

  return (
    !(countryCode === "NG" && title === "Starter") && (
      <StyledCard
        shadow
        width="384px"
        bordered
        style={{
          height: isSm ? "660px" : "632px",
          flex: isSm ? "0 0 277px" : "0 0 380px"
        }}
      >
        <Text
          style={{
            position: isSm ? "static" : "absolute",
            top: isSm ? "0" : "30px",
            left: isSm ? "0" : "20px",
            margin: "0"
          }}
        >
          <Label margin="0" theme="secondary200" color="white">
            {t(badge)}
          </Label>
        </Text>
        <Card.Header
          padding={isSm ? "0" : "10px 0 0 0"}
          style={{
            flexDirection: "column",
            marginTop: isSm ? "12px" : "55px"
            // height: isSm ? "100px" : "auto"
          }}
        >
          <Text fontSize={28} margin="0" fontWeight={600}>
            {t(title)}
          </Text>
          <Text fontSize={14} margin="0" fontWeight={600} color="black300">
            {parse(t(tagline))}
          </Text>
        </Card.Header>
        <Card.Separator height={1} margin={isSm ? "10px 0" : "20px 0 10px"} />
        <Card.Content padding={isSm ? "0" : "10px 0"} style={{ flex: "1 0 auto" }}>
          <List style={{ padding: "0", height: isSm ? "235px" : "230px" }}>
            {description.map((desc, i) => (
              <List.Item
                key={i}
                margin="0 0 10px"
                bulletSize="0"
                style={{ display: "block", height: isSafari ? (i === 2 ? "auto" : "auto") : "initial" }}
              >
                <Label
                  Icon={
                    <Marker
                      width={isSm ? 20 : 24}
                      style={{
                        marginRight: "15px"
                      }}
                    />
                  }
                  lineHeight="20px"
                  noBackground
                  theme="primary"
                  color="black500"
                  fontSize={14}
                  fontWeight={400}
                  padding="0"
                  margin={isSm ? "0 0 10px" : "0 0 12px"}
                >
                  {t(desc)}
                </Label>
              </List.Item>
            ))}
          </List>
        </Card.Content>
        <Card.Footer style={{ flexDirection: "column" }} padding={isSm ? "0" : `${isSafari ? "5px" : "5px"} 0`}>
          {type !== "free" && !prices[countryCode].helperText ? (
            <Text fontSize={14} color="black300">
              {t("pricing.from")}
            </Text>
          ) : null}

          {prices[countryCode].helperText && (
            <Text fontSize={16} color="accent300" fontWeight={600}>
              {t(prices[countryCode].helperText)}
            </Text>
          )}

          <Row>
            <Text
              fontSize={26}
              fontWeight={500}
              margin="0 0 0 9px"
              style={prices[countryCode].isThroughPrice ? { textDecorationLine: "line-through" } : {}}
            >
              {t(prices[countryCode].value)}
            </Text>
            <Text fontSize={14} color="black300">
              {" "}
              {t(prices[countryCode].per)}
            </Text>
          </Row>
          <Link
            to={`/${countrySlug || ""}${countrySlug ? "/" : ""}employers/${type}`}
            style={{ textDecoration: "none" }}
          >
            <Button fontSize={16} width="100%" margin="10px 0 0 0">
              {t("pricing.learn_more")} <Icon as={RightArrowAlt} width={16} fill="black500" margin="auto 0 auto 8px" />
            </Button>
          </Link>
        </Card.Footer>
      </StyledCard>
    )
  );
};

export default PricingPlan;
