import React, { useContext, useLayoutEffect, useMemo } from "react";
import { observer } from "mobx-react";

import { usePrev } from "utils/hooks";
import { ScreenClassContext } from "react-grid-system";
import Item from "./CompanyItem";
import { Title } from "../../Core";
import useStore from "../../../contexts/store";
import CarouselSection from "../../Section/CarouselSection";
import { isBaronaBrand } from "../../../contexts/branded";

const CompaniesSection = observer(
  ({
    title,
    filter = "similar_to_company",
    company_id,
    count_limit = 4,
    total_limit = 8,
    expandable = false,
    noHeader = false,
    titleProps,
    ...rest
  }) => {
    const {
      state: { loading },
      id,
      companies,
      getCompanies
    } = useStore("Companies");
    const { handleSaving } = useStore("Saved");
    const expanded = false;
    const prevLoading = usePrev(loading);
    const loaded = useMemo(() => prevLoading, [loading, expanded]);
    const item_limit = expanded ? total_limit : count_limit;
    const companyList = companies ? companies.slice(0, item_limit) : [...new Array(item_limit).keys()];
    const screen = useContext(ScreenClassContext);
    const isSm = /xs|sm/.test(screen);
    const isBarona = isBaronaBrand();

    useLayoutEffect(() => {
      if (!company_id || company_id !== id) {
        getCompanies(filter, company_id, count_limit);
      }
    }, [company_id]);

    return (
      <>
        {isBarona && !companyList.length ? (
          <CarouselSection
            expandable={expandable}
            expanded={expanded}
            columns={4}
            slideMobileWidth="230px"
            style={{
              marginTop: isSm ? 0 : "25px"
            }}
            Header={
              !noHeader && (
                <Title scales level={3} fontWeight={600} color="#292929" {...titleProps} className="title_sentence">
                  {title}
                </Title>
              )
            }
            {...rest}
          >
            {companyList.map((item, index) => (
              <Item
                key={`company_${filter}_${index}`}
                {...item}
                saveItem={saved => handleSaving(item.id, "company", saved)}
                loading={loading}
                loaded={loaded}
              />
            ))}
          </CarouselSection>
        ) : null}
      </>
    );
  }
);

export default CompaniesSection;
