import React from "react";
import { Row, Col } from "react-grid-system";
import parse from "html-react-parser";
import { useTranslation } from "b2c/hooks/useTranslation";

import Layout from "../../../../components/Layout";
import { Text, Button } from "../../../../components/Core";
import { NavWrapper, NavQuestionCounter } from "./styled";
import NavProgress from "../NavProgress";

const Navigation = ({
  loading,
  processing,
  in_progress_id: inProgressId,
  mandatory,
  hasResponses,
  startForm,
  submitResponses,
  getFormPage,
  currentPage: { form_session_id, previous_question_response_id, page_number, page_total },
  hasNextForm,
  testCompleted,
  handleRedirection
}) => {
  const { t } = useTranslation();

  const actionProps = (() => {
    if (testCompleted)
      return {
        text: hasNextForm ? t("test_and_survey.next_test_button") : t("global.finish_button"),
        action: handleRedirection,
        disabled: loading
      };
    if (form_session_id)
      return {
        text: !hasResponses ? t("global.skip") : t("global.next"),
        action: submitResponses,
        disabled: loading || (mandatory && !hasResponses)
      };
    if (inProgressId)
      return {
        text: t("test_and_survey.resume_button"),
        action: () => getFormPage(inProgressId),
        disabled: loading
      };
    return {
      text: t("test_and_survey.start_test_button"),
      action: startForm,
      disabled: loading
    };
  })();

  return (
    <NavWrapper>
      <NavProgress currentPage={page_number} pageTotal={page_total} />

      <Layout.Content size="xl" tag="div">
        {page_number && page_total && (
          <NavQuestionCounter>
            <Text>{parse(t("test_and_survey.count_page", { page_number, page_total }))}</Text>
          </NavQuestionCounter>
        )}

        <Row align="center">
          <Col>
            {testCompleted && (
              <Button disabled={loading} processing={processing} onClick={startForm} width="auto" theme="grey200">
                {t("test_and_survey.redo_test_button")}
              </Button>
            )}
            {previous_question_response_id && (
              <Button
                disabled={loading}
                processing={processing}
                onClick={() => getFormPage(previous_question_response_id)}
                width="130px"
                theme="grey200"
              >
                {t("test_and_survey.previous")}
              </Button>
            )}
          </Col>
          <Col xs="content">
            <Button
              color="primaryButtonLabelColor"
              processing={processing}
              disabled={actionProps.disabled}
              width="130px"
              onClick={actionProps.action}
            >
              {actionProps.text}
            </Button>
          </Col>
        </Row>
      </Layout.Content>
    </NavWrapper>
  );
};

export default Navigation;
