import React from "react";
import { Link } from "react-router-dom";

import { useTranslation } from "b2c/hooks/useTranslation";
import { Button, Card, Title } from "b2c/components/Core";
import { isCustomJkuat } from "b2c/contexts/branded";
import LogoPwa from "images/b2c/fuzu-logo-border.svg";
import { FuzuAndroidAppPromoStyled, LogoContent } from "./styled";
import { useIsBarona } from "../../hooks";

const FuzuAndroidAppPromo = ({ isSmallText, isJob }) => {
  const heightOfCardHeader = isJob ? 70 : isSmallText ? 110 : 140;
  const customJkuat = isCustomJkuat();
  const isBarona = useIsBarona();
  const { t } = useTranslation();

  return (
    <>
      {!customJkuat && !isBarona ? (
        <FuzuAndroidAppPromoStyled $isJob={isJob}>
          <Link to="/fuzu-app">
            <Card.Header
              height={heightOfCardHeader}
              padding="20px 20px 0 20px"
              style={{ position: "relative", zIndex: "2" }}
            >
              <Title
                level={isSmallText ? 5 : 3}
                fontWeight={600}
                margin="0"
                lineHeight={isSmallText ? "130%" : "150%"}
                color="white"
                style={{
                  height: isSmallText ? "auto" : "110px"
                }}
              >
                {t("fuzu_app.title")}
              </Title>
            </Card.Header>
            <Card.Footer
              padding="0 20px 16px 20px"
              style={{ alignItems: "flex-end", position: "relative", zIndex: "2" }}
            >
              <Button theme="primary" arrow margin="0" color="#333333">
                {t("fuzu_app.learn_more_button")}
              </Button>
              <LogoContent $isJob={isJob}>
                <LogoPwa />
              </LogoContent>
            </Card.Footer>
          </Link>
        </FuzuAndroidAppPromoStyled>
      ) : null}
    </>
  );
};

export default FuzuAndroidAppPromo;
