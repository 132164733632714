import React, { useCallback, useContext, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";

import { useTranslation } from "b2c/hooks/useTranslation";
import Content from "../Content";
import Navigation from "../Navigation";
import {
  Label,
  SearchInput,
  SearchWrapper,
  Container,
  SearchInputIcon,
  Skip,
  InlineMenu,
  Item,
  Relative
} from "./styled";
import useStore from "../../../../contexts/store";
import { debounce, highlightField } from "../../../../../utils/helpers";
import ThemeContext from "../../../../contexts/theme";
import Styled from "../../../../components/JobInput/styled";
import Steps from "../Steps";

const JobTitles = () => {
  const [value, setValue] = useState("");
  const [jobId, setJobId] = useState("");
  const [focused, setFocused] = useState(false);
  const history = useHistory();
  const [processing, setProcessing] = useState(false);
  const { getMatchingJobClusters, onboardingFlow } = useStore("Onboarding");
  const theme = useContext(ThemeContext);
  const { t } = useTranslation();

  const getJobsDebounced = useCallback(
    debounce(term => {
      setProcessing(true);
      getMatchingJobClusters(term)
        .then(() => {
          setProcessing(false);
        })
        .catch(() => {
          setProcessing(false);
        });
    }, 750),
    []
  );

  const handleChange = event => {
    const title = event.target.value;
    setValue(title);
    setJobId("");

    if (title && title.length > 2) {
      getJobsDebounced(title);
    }
  };

  const handleSubmit = () => {
    const prevOnboardingForm = JSON.parse(sessionStorage.getItem("onboardingFlow"));
    let jobIdValue = jobId;

    if (!jobId && value) {
      const findJob = onboardingFlow.jobTitles.find(item => item.title.toLowerCase() === value.toLowerCase());

      if (findJob) {
        jobIdValue = findJob.id;
      }
    }

    const onboardingForm = {
      ...prevOnboardingForm,
      jobTitles: value,
      jobTitlesId: jobIdValue,
      skills: prevOnboardingForm.jobTitles !== value ? [] : prevOnboardingForm.skills,
      interestAreas: prevOnboardingForm.jobTitles !== value ? [] : prevOnboardingForm.interestAreas
    };

    if (jobIdValue) {
      history.push("/onboarding/seniority-level");
      onboardingForm.skills = [];
      onboardingForm.interestAreas = [];
    } else {
      history.push("/onboarding/interest-areas");
    }

    sessionStorage.setItem("onboardingFlow", JSON.stringify(onboardingForm));
  };

  const handleJobClusterSelect = ({ id, title }) => {
    setValue(title);
    setJobId(id);
    setFocused(false);
  };

  const isMenuVisible = focused && value?.length >= 3;

  useEffect(() => {
    const onboardingFlowStorage = JSON.parse(sessionStorage.getItem("onboardingFlow"));

    if (onboardingFlowStorage.jobTitlesId) {
      setJobId(onboardingFlowStorage.jobTitlesId);
    }

    if (onboardingFlowStorage.jobTitles) {
      setValue(onboardingFlowStorage.jobTitles);
      getJobsDebounced(onboardingFlowStorage.jobTitles);
    }
  }, []);

  return (
    <>
      <Steps width="180px" />

      <Container data-cy="job-titles">
        <Content title={t("onboarding.interested_job.title")} description={t("onboarding.interested_job.description")}>
          <Label>{t("onboarding.interested_job.label")}</Label>

          <SearchWrapper>
            <SearchInput
              placeholder={t("onboarding.interested_job.placeholder")}
              onChange={handleChange}
              value={value}
              onFocus={() => {
                setFocused(true);
              }}
              data-cy="search-input"
            />
            <SearchInputIcon />
            {processing && <Styled.ProcessingState size="24px" />}
          </SearchWrapper>
          {isMenuVisible && (
            <Relative>
              <InlineMenu theme={theme}>
                {onboardingFlow.jobTitles.map(job_cluster => {
                  return (
                    <Item
                      key={job_cluster?.id}
                      theme={theme}
                      onMouseDown={e => e.preventDefault()}
                      onClick={() => handleJobClusterSelect(job_cluster)}
                    >
                      {highlightField(job_cluster.title, value)}
                    </Item>
                  );
                })}
              </InlineMenu>
            </Relative>
          )}

          <Skip to="/onboarding/interest-areas">{t("onboarding.interested_job.skip")}</Skip>
        </Content>
      </Container>
      <Navigation backUrl="/onboarding/job-type" isDisabled={!value} handleSubmit={handleSubmit} />
    </>
  );
};

export default observer(JobTitles);
