import React, { useState, useEffect, useLayoutEffect, useContext, useRef, useCallback, useMemo } from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { ScreenClassContext } from "react-grid-system";
import { Helmet } from "react-helmet";

import { useNotification, useModal, useIsLoggedIn } from "utils/hooks";
import { stringify } from "utils/qsUtil";
import { Text, Pagination, Spinner, Suggestion, Image } from "b2c/components/Core";
import JobDetailsItem from "b2c/components/JobDetailsItem";
import SearchTools from "b2c/components/SearchTools";
import HistoryListener from "b2c/components/HistoryListener";
import JobViewWrapper from "b2c/components/JobViewWrapper";
import { useImpressionTrack } from "b2c/hooks";
import useStore from "b2c/contexts/store";
import FuzuAndroidAppPromo from "b2c/components/FuzuAndroidAppPromo";
import { ModalContext } from "contexts/modal";
import PremiumBanner from "b2c/components/PremiumBanner";
import { jobCustomBanner, jobBanner } from "b2c/constants/jobs";
import { baronaEventName } from "b2c/constants/main";
import LoginModalContent from "b2c/components/ModalContents/SignUpModalContent";
import ThemeContext from "b2c/contexts/theme";
import BrandedContext, { buildImageUrlS3, isWhitelabelled } from "b2c/contexts/branded";
import { PromotionCountryVisible, GuestHidden } from "b2c/components/WithAuth";
import Layout from "b2c/components/Layout";
import { useTranslation } from "b2c/hooks/useTranslation";
import SpeciesJobs from "../SpeciesJobs";
import ViewTools from "../ViewTools";
import NotFoundNotification from "../NotFoundNotification";
import { EpmtyStateWrapper } from "./styled";
import Item from "../ViewItem";
import { useStandaloneMode } from "../../../../contexts/standalone";
import { nameImages } from "../../../../constants/images";

let promptInterval;

const JobView = ({
  state,
  authorized,
  jobId,
  list,
  title,
  pageParams: { page = 1, per_page = 10 },
  setDefaultCountryId,
  totalCount = 0,
  activeCount = 0,
  setPage,
  handleSaving,
  cancelApplication,
  slug,
  location: { search, pathname } = {},
  addIdParameter,
  applyFilter,
  appliedFilters,
  searchFilters,
  applicationFilters,
  resetFilters,
  appliedCount,
  setNavigationMethod,
  positionNotFound,
  trackJobView,
  jobSearchUrlBuilder,
  push,
  location,
  meta,
  getPageInfo,
  setIsSelectedCurrentCountry
}) => {
  const { features } = useStore("Premium");
  const { user } = useStore("User");
  const { toggle } = useContext(ModalContext);
  const { track, baronaTrack } = useStore("Tracking");
  const { isBaronaState: isBarona } = useStore("Onboarding");
  const { global_brand: globalBrand } = useStore("initialState");
  const aggressivePrompt = localStorage.getItem("aggressivePrompt");

  const loading = state?.loading;
  const selectedRef = useRef();
  const { targetRef: listPromptRef, parentRef: listRef } = useImpressionTrack({
    type: authorized ? "career_coaching" : "guest_job_list"
  });
  const { targetRef: descPromptRef, parentRef: jobDescriptionRef } = useImpressionTrack({
    rootMargin: "0px 0px 148px",
    type: authorized ? "instant_feedback" : "guest_job"
  });
  const notifyNotFound = useNotification(<NotFoundNotification />, "secondary300");
  const disableNotification = useNotification(null);
  const openLoginModal = useModal(<LoginModalContent type="auto" />, null, () => {}, 0);

  const screen = useContext(ScreenClassContext);
  const Theme = useContext(ThemeContext);

  const { brand_type } = useContext(BrandedContext);
  const isMobile = ["sm", "xs"].includes(screen);

  const [jobs, setJobs] = useState(list);
  const [appliedAllFilters, setAppliedAllFilters] = useState({});
  const selectedDefault = list && list.length ? list[0] : {};
  const findSelectedMobile = list && list.length && jobId ? list.find(item => String(item.id) === String(jobId)) : {};
  const [selected, setSelected] = useState(isMobile ? findSelectedMobile : selectedDefault);

  const [prevScrollPosition, setScrollPosition] = useState(0);
  const [selectCount, setSelectCount] = useState(0);
  const lastPage = Math.ceil(totalCount / per_page);
  const { query } = jobSearchUrlBuilder.paginationParams();
  const isLoggedIn = useIsLoggedIn();
  const whitelabelled = isWhitelabelled();
  const { t } = useTranslation();
  const { current_country: currentCountry } = useStore("initialState");

  const buildBasePath = () => {
    const params = stringify(query);
    if (params) {
      return `${location.pathname}?${params}&page=`;
    }
    return `${location.pathname}?page=`;
  };

  const changePage = pages => {
    window.scrollTo(0, 0);
    setPage(pages);
  };

  const onSelectHandle = job => {
    if (selected?.id !== job.id) {
      setSelected(toJS(job));
      if (!authorized && selectCount !== 3) {
        setSelectCount(selectCount + 1);
      }
    }

    if (!isMobile) {
      jobDescriptionRef.current.focus();
    } else {
      push(location.pathname + location.search);
    }
  };

  useEffect(() => {
    if (isMobile && jobDescriptionRef.current) jobDescriptionRef.current.focus();
  }, [jobDescriptionRef]);

  const getCountryId = useMemo(() => {
    return currentCountry.id && currentCountry.id !== "global" ? currentCountry.id : "";
  }, [currentCountry.id, appliedFilters.country_id]);

  useEffect(() => {
    if (getCountryId) {
      setDefaultCountryId(getCountryId);
    }

    if (!user && aggressivePrompt === "open") {
      setSelectCount(3);
    }

    return () => {
      toggle();
      window?.clearTimeout(promptInterval);
    };
  }, [getCountryId, currentCountry.id]);

  useEffect(() => {
    setIsSelectedCurrentCountry(Boolean(currentCountry.id && currentCountry.id !== "global"));
  }, []);

  useEffect(() => {
    if (selected?.id) {
      addIdParameter(selected.id);
      if (slug !== "applications") {
        trackJobView(selected.id);
        baronaTrack(baronaEventName.viewItem, {
          itemId: selected.id,
          itemName: selected.title,
          itemCategory: selected.category.name,
          itemVariant: selected.seniority_level.name,
          itemBrand: selected.company_name,
          itemAffiliation: globalBrand.name
        });
      }

      if (!authorized && selectCount === 0 && !promptInterval && !isBarona) {
        promptInterval = setTimeout(() => {
          openLoginModal();
        }, 5000);
      }
    }
    if (jobDescriptionRef.current) {
      jobDescriptionRef.current?.scrollTo(0, 0);
    }

    if (isMobile) {
      if (selected?.id) {
        setScrollPosition(window.scrollY);
        window.scroll(0, 0);
      } else {
        window.scrollTo(0, prevScrollPosition);
      }
    }
  }, [selected]);

  useEffect(() => {
    if (selectCount === 3 && !isBarona) {
      openLoginModal();
      setSelectCount(0);

      window?.clearTimeout(promptInterval);
    }
  }, [selectCount]);

  useLayoutEffect(() => {
    if (list?.length > 0 && appliedFilters && user?.id) {
      const { sort_by = "recommended", ...filters } = appliedFilters;
      track("job_search_impression", { sort_by, filters, page, per_page });
    }

    if (list) {
      list.splice(list.length >= 2 ? 2 : list.length, 0, jobBanner);

      setJobs(list);
    }
  }, [list]);

  useEffect(() => {
    return () => {
      if (!!selected && isMobile) setSelected({});
    };
  }, [pathname]);

  useEffect(() => {
    setAppliedAllFilters(toJS(appliedFilters));
  }, [
    Object.keys(toJS(appliedFilters)).length,
    appliedFilters.industry_ids?.length,
    appliedFilters.category_ids?.length,
    appliedFilters.seniority_ids?.length
  ]);

  const handleSetFilters = filters => {
    setAppliedAllFilters(prev => {
      return {
        ...prev,
        ...filters
      };
    });
  };

  useLayoutEffect(() => {
    return () => {
      disableNotification();
    };
  }, [slug]);

  useLayoutEffect(() => {
    if (positionNotFound && !slug) {
      notifyNotFound();
    }
  }, [positionNotFound]);

  useLayoutEffect(() => {
    setNavigationMethod(isMobile ? () => setSelected({}) : undefined);
  }, [isMobile]);

  useEffect(() => {
    if (list.length > 0) {
      if (jobId && list.some(job => String(job?.id) === String(jobId))) {
        setSelected(list.find(job => String(job?.id) === String(jobId)));
      } else if (!isMobile) {
        setSelected(list[0]);
      }
    }
  }, [jobId, list, isMobile]);

  useEffect(() => {
    if (selectedRef.current && !isMobile && listRef.current) {
      listRef.current?.scrollTo(0, selectedRef.current.offsetTop - 392);
    }
  }, [selectedRef, listRef]);

  const saveJob = useCallback(
    (id, saved) => {
      handleSaving(id, "job", saved).then(() => {
        const index = jobs?.findIndex(item => String(item?.id) === String(id));
        if (slug === "saved") {
          setSelected(jobs[index + 1]) ? jobs[index + 1] : jobs[index - 1];
          setJobs(prev => prev.filter(item => String(item?.id) !== String(id)));
        } else {
          const modified = [...jobs];

          if (modified[index]) {
            modified[index] = {
              ...modified[index],
              saved: !modified[index]?.saved
            };
          }
          setJobs(modified);
        }
      });
    },
    [jobs]
  );

  const removeApplication = useCallback(
    (id, app_id) => {
      cancelApplication(id, app_id).then(() => {
        const index = jobs?.findIndex(item => String(item?.id) === String(id));

        if (slug === "applications") {
          setSelected(jobs[index + 1]) ? jobs[index + 1] : jobs[index - 1];
          setJobs(prev => prev.filter(item => String(item.id) !== String(id)));
        }
      });
    },
    [jobs]
  );

  const handleBackwards = (_, action) => {
    if (action === "POP" && isMobile) setSelected({});
  };

  const emptyContent = (() => {
    if (slug === "saved")
      return {
        icon: <Image width="160px" src={buildImageUrlS3(nameImages.savedJobsEmpty)} />,
        text: t("jobs.empty.saved")
      };
    if (slug === "applications")
      return {
        icon: <Image width="190px" src={buildImageUrlS3(nameImages.applicationEmpty)} />,
        text: t("jobs.empty.applications")
      };
    return {
      icon: <Image width="150px" src={buildImageUrlS3(nameImages.jobsEmpty)} />,
      text: t("jobs.empty.default")
    };
  })();

  const sortingOptions = slug === "applications" ? applicationFilters.sort_by : searchFilters.sort_by;
  const showPromo = !/saved|applicatoins/.test(slug) && !features?.coaching?.activated && Number(page) === 1;
  const isStandalone = useStandaloneMode();
  const jobsList = isStandalone || isBarona ? jobs.filter(item => item.type !== jobCustomBanner) : jobs;
  const isEmpty = jobsList.length === 0 || (jobsList.length === 1 && jobsList[0].type === jobCustomBanner);

  return (
    <>
      <HistoryListener callback={handleBackwards} />
      {(!isMobile || !selected?.id) && (
        <>
          {meta && (
            <Helmet>
              {meta.title && <title>{meta.title}</title>}
              {meta.description && <meta name="description" content={meta.description} />}
              {meta.description && <meta name="twitter:description" content={meta.description} />}
              {meta.description && <meta name="og:description" content={meta.description} />}
              {meta.links?.length &&
                meta.links.map((link, idx) => {
                  const hreflang = link.hreflang ? { hreflang: link.hreflang } : {};
                  return <link key={idx} rel={link.rel} href={link.href} {...hreflang} />;
                })}
              {meta.index_follow && <meta name="robots" content={meta.index_follow} />}
            </Helmet>
          )}
          <SearchTools
            state={state}
            slug={slug}
            isMobile={isMobile}
            applyFilter={handleSetFilters}
            appliedFilters={toJS(appliedAllFilters)}
            selectedAppliedFilters={toJS(appliedFilters)}
            searchFilters={searchFilters}
            applicationFilters={applicationFilters}
            resetFilters={resetFilters}
            appliedCount={appliedCount}
            handleApplyFilter={applyFilter}
            getPageInfo={getPageInfo}
          />
          <ViewTools
            title={title}
            slug={slug}
            totalCount={slug === "applications" ? totalCount : activeCount}
            currentSorting={appliedFilters.sort_by}
            sortingOptions={sortingOptions}
            applyFilter={applyFilter}
          />
        </>
      )}

      <Layout.Content
        size="xl"
        id="main"
        data-cy="job-viewer"
        style={{
          display: "flex",
          flexDirection: "column"
        }}
        data-hide-footer-below
      >
        <JobViewWrapper $borderColor={Theme.grey300} $backgroundColor={Theme.white} $guestMode={whitelabelled}>
          {loading ? (
            <Spinner size="50px" margin="auto" />
          ) : (
            <>
              {!isEmpty ? (
                <>
                  {(!isMobile || !selected?.id) && (
                    <section className="job-list" ref={listRef} role="tablist">
                      {jobsList.slice(0, jobs.length - 1).map(job => {
                        if (job.type === jobCustomBanner && !isStandalone && !isBarona) {
                          return <FuzuAndroidAppPromo key={job.type} isSmallText isJob />;
                        }
                        return (
                          <Item
                            isBarona={isBarona}
                            role="tab"
                            key={job?.id}
                            ref={job?.id === jobId ? selectedRef : null}
                            selected={selected?.id === job?.id}
                            onClick={e => {
                              e.preventDefault();
                              onSelectHandle(job);
                            }}
                            {...job}
                          />
                        );
                      })}

                      {showPromo && !isBarona && (
                        <GuestHidden>
                          <PromotionCountryVisible countryCodes={["KE", "UG"]}>
                            <PremiumBanner.CareerCoaching height={130} ref={listPromptRef} />
                          </PromotionCountryVisible>
                        </GuestHidden>
                      )}

                      <Suggestion.Job ref={listPromptRef} />

                      {!isStandalone && !isBarona && (
                        <>
                          {jobs[jobs.length - 1]?.title && (
                            <Item
                              isBarona={isBarona}
                              role="tab"
                              key={jobs[jobs.length - 1]?.id}
                              ref={String(jobs[jobs.length - 1]?.id) === String(jobId) ? selectedRef : null}
                              selected={String(selected?.id) === String(jobs[jobs.length - 1]?.id)}
                              onClick={e => {
                                e.preventDefault();
                                onSelectHandle(jobs[jobs.length - 1]);
                              }}
                              {...jobs[jobs.length - 1]}
                            />
                          )}
                        </>
                      )}

                      <footer>
                        <Pagination
                          padding={isMobile ? (isLoggedIn ? "8px 0 80px" : "8px 0 20px") : "8px 0"}
                          page={page}
                          last={lastPage}
                          basePath={buildBasePath()}
                          isLink
                          isFullUrl
                          setPage={changePage}
                        />
                      </footer>
                      <SpeciesJobs searchFilters={searchFilters} />
                    </section>
                  )}
                  {(!isMobile || selected?.id) && (
                    <section
                      className="job-content"
                      data-cy="job-content"
                      role="tabpanel"
                      tabIndex="0"
                      ref={jobDescriptionRef}
                    >
                      {selected?.id && (
                        <JobDetailsItem
                          {...{
                            selected,
                            pathname,
                            selectedIndex: jobs?.findIndex(j => j?.id === selected?.id),
                            search,
                            slug,
                            saveJob,
                            setSelected,
                            cancelApplication: removeApplication,
                            isMobile,
                            brand_type,
                            promptRef: descPromptRef
                          }}
                        />
                      )}
                    </section>
                  )}
                </>
              ) : (
                <EpmtyStateWrapper className="fadeInAnim">
                  {emptyContent.icon}
                  <Text fontSize={16} fontWeight={500} margin="10px" width="100%">
                    {emptyContent.text}
                  </Text>
                </EpmtyStateWrapper>
              )}
            </>
          )}
        </JobViewWrapper>
      </Layout.Content>
    </>
  );
};

export default observer(JobView);
