import styled from "styled-components";

export const WhatIsFuzuComponent = styled.div`
  background-color: #fff;
`;

export const WhatIsFuzuContent = styled.div`
  padding: 20px 20px 30px;
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;

  @media screen and (min-width: 1200px) {
    padding: 0 20px;
  }

  @media screen and (min-width: 1440px) {
    padding: 0 50px;
  }
`;

export const HowItWorkContent = styled.div`
  margin: 0 -20px;
`;
