import React, { useState } from "react";
import { Visible } from "react-grid-system";
import cn from "classnames";

import HeaderSearchInput from "b2c/components/HeaderSearchInput";
import NotificationsButton from "b2c/components/NotificationsButton";
import { onEnterPress } from "utils/helpers";
import { Badge, Button } from "../Core";
import Highlight from "../Highlight";
import AccountMenu from "../AccountMenu";
import MessagesLink from "../MessagesLink";
import useStore from "../../contexts/store";

const UserSide = ({
  Theme,
  pathname,
  id,
  firstName,
  avatar,
  push,
  unreadCount,
  MessageIcon,
  customJkuat,
  email,
  fullName
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const { global_brand } = useStore("initialState");
  const globalBrandName = global_brand.name.toLowerCase();

  const handleUniversalSearch = () => {
    push(`/search?term=${searchTerm}`);
    setSearchTerm("");
  };

  return (
    <div className="user-side">
      <HeaderSearchInput
        handleUniversalSearch={handleUniversalSearch}
        Theme={Theme}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        onEnterPress={onEnterPress}
        pathname={pathname}
        className={`nav-icon_${globalBrandName}`}
      />

      <Visible xxl xl lg md>
        <Highlight highlightId="messages">
          <MessagesLink>
            <Button.Iconed
              className={cn("nav-icon", `nav-icon_${globalBrandName}`)}
              theme="transparent"
              tabIndex={-1}
              aria-label="Messages"
            >
              {MessageIcon}
              {!isNaN(unreadCount) && <Badge>{unreadCount}</Badge>}
            </Button.Iconed>
          </MessagesLink>
        </Highlight>
      </Visible>

      {!customJkuat && (
        <Highlight>
          <NotificationsButton />
        </Highlight>
      )}
      <Visible xxl xl lg>
        <Highlight highlightId="menu">
          <AccountMenu id={id} avatar={avatar} name={firstName} fullName={fullName} email={email} />
        </Highlight>
      </Visible>
    </div>
  );
};

export default UserSide;
