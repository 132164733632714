import React from "react";
import parse from "html-react-parser";
import { useTranslation } from "b2c/hooks/useTranslation";

import { useNotificationsCenterNav } from "../../index";
import NotificationsCenterLayout from "../NotificationsCenterLayout";
import { StyledTitle, StyledContent, StyledEmptyListImage } from "./styled";

const EmptyNotificationsList = ({ onBackward, onClose }) => {
  const { notificationId } = useNotificationsCenterNav();
  const isNestedList = notificationId !== undefined;
  const { t } = useTranslation();

  return (
    <NotificationsCenterLayout
      title={t("notifications.title")}
      onBackward={onBackward}
      onClose={onClose}
      withBackwardButton={isNestedList}
    >
      <StyledEmptyListImage />
      <StyledTitle>{t("notifications.empty_title")}</StyledTitle>
      <StyledContent>{parse(t("notifications.empty_description"))}</StyledContent>
    </NotificationsCenterLayout>
  );
};

export default EmptyNotificationsList;
