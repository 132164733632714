import styled from "styled-components";

export const FollowingBlog = styled.div`
  padding-bottom: 30px;
  background-color: transparent;

  @media screen and (min-width: 768px) {
    padding-bottom: 0;
  }
`;
