import React, { useContext } from "react";
import { ScreenClassContext } from "react-grid-system";

import { useTranslation } from "b2c//hooks/useTranslation";
import { SignUpPromptWrapper } from "./styled";
import { GuestVisible } from "../WithAuth";
import ThemeContext from "../../contexts/theme";
import { WhitelabelledOrCustomHidden } from "../../contexts/branded";
import Layout from "../Layout";
import { Text, Button } from "../Core";

const DefaultActions = props => {
  const { t } = useTranslation();

  return (
    <Button.Signup width={290} {...props}>
      {t("global.sign_up_button")}
    </Button.Signup>
  );
};

const SignupPrompt = ({ Actions = DefaultActions, style, ...rest }) => {
  const screen = useContext(ScreenClassContext);
  const { white } = useContext(ThemeContext);
  const isSm = ["xs", "sm"].includes(screen);
  const { t } = useTranslation();

  return (
    <WhitelabelledOrCustomHidden>
      <GuestVisible>
        <SignUpPromptWrapper backgroundColor={white} style={style}>
          <Layout.Content size="md" tag="div">
            <Text fontSize={isSm ? 28 : 36} margin="0 auto 24px" textAlign="center" fontWeight="500">
              {t("landing_fuzu.sign_up_title")}
            </Text>

            <Actions {...rest} />
          </Layout.Content>
        </SignUpPromptWrapper>
      </GuestVisible>
    </WhitelabelledOrCustomHidden>
  );
};

export default SignupPrompt;
