import styled from "styled-components";

export const StyledLabel = styled.div`
  display: inline-block;
  position: relative;
  top: 1px;
  left: 10px;
  font-weight: 500;
  font-size: 14px;
  color: ${props => props.color};
`;
