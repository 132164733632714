import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";

import { useTranslation } from "b2c/hooks/useTranslation";
import { RadioButton } from "../../../../components/Core";
import Content from "../Content";
import Navigation from "../Navigation";
import useStore from "../../../../contexts/store";
import { Item, Title, Container, Description } from "./styled";
import { useQueryParams } from "../../../../../utils/hooks";
import { buildOnboardingParams } from "../../../../utils/helpers";
import Steps from "../Steps";

const SeniorityLevel = () => {
  const [selected, setSelected] = useState({});
  const { getSeniorityLevels, onboardingFlow, signUpDetails } = useStore("Onboarding");
  const history = useHistory();
  const { t } = useTranslation();
  const { user } = useStore("initialState");
  const { refer_code: referCode } = useQueryParams();
  const { global_brand: globalBrand } = useStore("initialState");

  const handleSelect = id => {
    const selectedInterest = onboardingFlow.seniorityLevel.find(item => String(item?.id) === String(id));
    setSelected(selectedInterest);
  };

  const handleSubmit = () => {
    const prevOnboardingForm = JSON.parse(sessionStorage.getItem("onboardingFlow"));

    const onboardingForm = {
      ...prevOnboardingForm,
      seniorityLevel: selected?.id
    };

    if (globalBrand.custom_authentication_b2c_url) {
      sessionStorage.setItem("onboardingFlow", JSON.stringify(onboardingForm));
      history.push("/onboarding/your-name");

      return;
    }

    if (user && !user?.onboarding_steps_completed) {
      const params = buildOnboardingParams(onboardingForm);

      signUpDetails(params, referCode).then(res => {
        if (res) {
          sessionStorage.removeItem("onboardingFlow");

          if (prevOnboardingForm.returnTo) {
            history.push(prevOnboardingForm.returnTo);
          }
        }
      });

      return;
    }

    sessionStorage.setItem("onboardingFlow", JSON.stringify(onboardingForm));
    history.push("/onboarding/your-email");
  };

  const backUrl = () => {
    const prevOnboardingForm = JSON.parse(sessionStorage.getItem("onboardingFlow"));

    if (prevOnboardingForm?.skills?.length) {
      return "/onboarding/skills";
    }

    return "/onboarding/job-titles";
  };

  useEffect(() => {
    getSeniorityLevels();
  }, []);

  useEffect(() => {
    const onboardingFlowStorage = JSON.parse(sessionStorage.getItem("onboardingFlow"));
    const seniorityLevel = onboardingFlowStorage?.seniorityLevel;

    if (seniorityLevel && !selected.id && onboardingFlow.seniorityLevel?.length) {
      const selectedInterest = onboardingFlow.seniorityLevel.find(item => String(item?.id) === String(seniorityLevel));

      setSelected(selectedInterest);
    }
  }, [onboardingFlow.seniorityLevel?.length]);

  const stepWidth = user && !user?.onboarding_steps_completed ? "100%" : "75%";

  return (
    <>
      <Steps width={stepWidth} />

      <Container data-cy="seniority-level">
        <Content
          title={t("onboarding.seniority_level.title")}
          description={t("onboarding.seniority_level.description")}
        >
          {onboardingFlow.seniorityLevel.slice(1, 5).map(({ id, title, description }) => (
            <RadioButton
              key={id}
              id={+id}
              name="mostInterested"
              value={+id}
              borderedView
              margin="6px 0"
              onChange={() => handleSelect(id)}
              checked={selected?.id === id}
              markColor="white"
              checkedColor="secondary200"
              checkedBgColor="secondary100"
              style={{ outline: "none" }}
              labelProps={{
                style: {
                  flexDirection: "row-reverse",
                  justifyContent: "space-between",
                  borderColor: selected?.id === id ? "#4690FF" : "#C5C5C5",
                  marginBottom: "16px",
                  padding: "15px 16px",
                  borderRadius: "8px",
                  alignItems: "center"
                }
              }}
              Label={
                <Item>
                  <Title>{title}</Title>
                  <Description>{description}</Description>
                </Item>
              }
            />
          ))}
        </Content>
      </Container>
      <Navigation backUrl={backUrl} isDisabled={!selected?.id} handleSubmit={handleSubmit} />
    </>
  );
};

export default observer(SeniorityLevel);
