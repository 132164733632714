import styled from "styled-components";

export const FeedbackSliderWrapper = styled.div`
  margin: 0 -20px 20px;
  height: max-content;

  @media screen and (max-width: 767px) {
    height: 300px;
  }

  .slick-arrow {
    position: absolute;
    bottom: 100%;
    background-color: #4690ff;
    color: white;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 4px;
    &.slick-prev {
      right: 50px;
    }
    &.slick-next {
      right: 10px;
    }
  }
`;
