import React from "react";
import parse from "html-react-parser";
import { Row, Col } from "react-grid-system";
import { Link } from "react-router-dom";

import ListMarker from "images/b2c/icons/listMarker.png";
import LeftIcon from "images/b2c/landing-page/left-arrow.png";
import AbsentIcon from "images/b2c/icons/absent.png";
import laptopImage from "images/b2b/landing-page/laptopImage.png";
import { Button, Text, List, Label, Card, Title, Image, Carousel } from "b2c/components/Core";
import { useTranslation } from "b2c/hooks/useTranslation";
import { theme } from "b2c/contexts/theme";
import Layout from "b2c/components/Layout";
import { carouselPoints } from "b2c/constants/pricing";
import { PricingWrapper, TitleWrapper } from "./styled";
import Terms from "../Terms";

const PlanSm = ({ setActivePlan, openContactUsModal, details, country, price, backURL }) => {
  const { t } = useTranslation();
  const onModalOpenHandler = pricing => {
    openContactUsModal();
    const pricingData = {
      title: pricing.title,
      value: pricing.prices.value,
      per: pricing.prices.per
    };
    localStorage.setItem("contactUsPricing", JSON.stringify(pricingData));
  };

  return (
    <Layout.Section style={{ backgroundColor: "white" }}>
      <PricingWrapper>
        <Card>
          <div style={{ padding: "0 20px 0 20px" }}>
            <Row>
              <Link
                to={{
                  pathname: backURL,
                  query: { scroll: 2000 }
                }}
              >
                <Image margin="15px" width={15} height={15} loadingType="eager" src={LeftIcon} alt="Left Icon" />
              </Link>
            </Row>
            <TitleWrapper>
              <Title
                loaded
                level="2"
                fontWeight={600}
                margin="0 auto 15px"
                textAlign="center"
                style={{ fontSize: 24, display: "inline-block" }}
              >
                {t(details.title)}
              </Title>
            </TitleWrapper>
            <Text fontSize={{ fontSize: 12 }} lineHeight="24px" width="100%" textAlign="center" margin="0 0 30px">
              {t(details.tagline)}
              <br />
            </Text>

            <Card bordered style={{ backgroundColor: "#f3f3f3", marginBottom: "30px", overflow: "hidden" }}>
              <div style={{ margin: "20px 20px 0 20px" }}>
                <Text fontSize="16px" fontWeight={700}>
                  {parse(t("pricing.compare_products"))}
                </Text>
              </div>
              <Image
                margin="0 0 0 80px"
                width={300}
                height={200}
                loadingType="eager"
                src={laptopImage}
                alt="Laptop Image"
              />
            </Card>
          </div>
          <div>
            <Carousel
              afterChange={setActivePlan}
              settings={{
                slidesToShow: 1,
                arrows: false,
                fade: false,
                dots: false,
                className: "center",
                centerMode: true,
                centerPadding: "60px",
                responsive: carouselPoints.map(({ point, padding }) => ({
                  breakpoint: point,
                  settings: {
                    className: "center",
                    centerPadding: padding,
                    centerMode: true,
                    slidesToShow: 1
                  }
                }))
              }}
            >
              {details[country.country_code].pricings.map((priceItem, i) => (
                <Carousel.Slide key={i}>
                  <Card
                    bordered
                    width="280px"
                    borderWidth="1px"
                    borderColor={priceItem.popular ? "primary" : "black200"}
                    style={{
                      margin: "0 20px",
                      height: "430px",
                      flex: "0 0 280px",
                      position: "relative",
                      backgroundColor: priceItem.popular ? theme.primary50 : "white"
                    }}
                  >
                    <Card.Header
                      style={{ flexDirection: "column", position: "relative" }}
                      padding="40px 20px 10px 20px"
                    >
                      {priceItem.popular ? (
                        <Text style={{ position: "absolute", left: "13px", top: "13px" }}>
                          <Label theme="secondary200" color="white">
                            {t("pricing.popular")}
                          </Label>
                        </Text>
                      ) : null}
                      <Text fontSize={28} margin={0} fontWeight={600}>
                        {t(priceItem.title)}
                      </Text>
                      <Text fontSize={14} margin={0} fontWeight={600} color="black300">
                        {parse(t(priceItem.tagline))}
                      </Text>
                      <Row align="end" style={{ height: "8hpx" }}>
                        <Col>
                          {priceItem.prices.discount && (
                            <Text
                              fontSize={16}
                              color="accent400"
                              fontWeight={600}
                              style={{ textDecorationLine: "line-through" }}
                            >
                              {priceItem.prices.discount}
                            </Text>
                          )}
                          <Row style={{ margin: "20px -10px" }}>
                            <Text fontSize={28} margin="0 0 0 9px">
                              {t(priceItem.prices.value)}
                            </Text>
                            <Text fontSize={12}>{t(priceItem.prices.per)}</Text>
                          </Row>
                        </Col>
                      </Row>

                      <Button fontSize={18} width="100%" onClick={() => onModalOpenHandler(priceItem)}>
                        {t("pricing.get_started")}
                      </Button>
                      <div className="list-vertical">
                        <Terms terms={priceItem.terms} />
                      </div>
                    </Card.Header>
                  </Card>
                </Carousel.Slide>
              ))}
            </Carousel>
          </div>

          <Card.Content padding="0" style={{ flex: "1 0 auto", marginTop: "30px", padding: "0 20px 0 20px" }}>
            <List style={{ padding: 0 }}>
              {details[country.country_code].descr.map((desc, i) => (
                <React.Fragment key={i}>
                  <List.Item style={{ height: "52px" }} margin="0 0 10px" bulletSize="0">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "8px 20px 7px 5px"
                      }}
                    >
                      <Label
                        lineHeight="140%"
                        noBackground
                        theme="primary"
                        color="black500"
                        fontSize={14}
                        fontWeight={400}
                        padding="0"
                      >
                        {t(desc)}
                      </Label>
                      <Image
                        width={18}
                        height={18}
                        loadingType="eager"
                        src={price.actives[i] && typeof price.actives[i] !== "string" ? ListMarker : AbsentIcon}
                        alt="Icon"
                      />
                    </div>
                  </List.Item>
                  <Card.Separator height={1} />
                </React.Fragment>
              ))}
            </List>
          </Card.Content>

          <Card.Footer style={{ flexDirection: "column" }} padding="20px 15px">
            <Text fontSize={20} fontWeight={600}>
              {t(price.title)}
            </Text>
            <Row>
              <Text fontSize={18} margin="0 0 0 9px">
                {t(price.prices.value)}
              </Text>
              <Text fontSize={12}>{t(price.prices.per)}</Text>
            </Row>

            <Button fontSize={18} width="100%" margin="5px 0 20px 0" onClick={openContactUsModal}>
              {t("pricing.get_started")}
            </Button>
          </Card.Footer>
        </Card>
      </PricingWrapper>
    </Layout.Section>
  );
};

export default PlanSm;
