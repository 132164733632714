import styled from "styled-components";

export const EliteDeliverablesSection = styled.section`
  background-color: #ffffff;
  padding: 75px 30px 90px;
`;

export const EliteDeliverablesContent = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
`;
