import React from "react";
import { Row, Col } from "react-grid-system";

import Payment from "images/b2c/icons/payment.svg";
import { Card, Title, Button, Text } from "../../../../components/Core";
import LinkSupportChannel from "../../../../components/LinkSupportChannel";
import { PaymentMethodWrapper, TitleIconHolder } from "./styled";

const PaymentProblems = ({ gateway, provider, from, processing, tryAgain }) => {
  const items = gateway?.instructions?.split(";") || [];

  return (
    <Card shadow>
      <Card.Header>
        <TitleIconHolder>
          <Payment />
        </TitleIconHolder>

        <Title
          level={4}
          margin="0 0 0 15px"
          fontWeight={500}
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center"
          }}
        >
          Problems with payment?
        </Title>
      </Card.Header>

      <Card.Content>
        <Text margin="16px 0">
          If you have issues with the payment, please try again or follow manual {provider.paymentname} instructions.
        </Text>

        <Card bordered>
          <Card.Header>
            <Title level={4}>
              <b>Option 1:</b> Try again
            </Title>
          </Card.Header>
          <Card.Content>
            <Text margin="16px 0">Try the payment again from the start.</Text>

            <Row>
              <Col lg="content" sm={12}>
                <Button width="100%" processing={processing} onClick={tryAgain}>
                  Try again
                </Button>
              </Col>
            </Row>
          </Card.Content>
        </Card>

        <PaymentMethodWrapper>
          <Card bordered>
            <Card.Header>
              <Title level={4}>
                <b>Option 2:</b> Follow manual {provider.paymentname} instructions
              </Title>
            </Card.Header>
            <Card.Content>
              <Text margin="16px 0">You can also pay using Lipa na MPESA by using the following instructions:</Text>

              {items.map((item, i) => (
                <Text key={`minst-${i}`} fontSize={16} fontWeight={500} margin="0 0 8px">
                  {item}
                </Text>
              ))}

              <Text margin="16px 0">
                The product will be activated within a few minutes after we have received your payment. You will get an
                email once the product has been activated. If you need help, please contact{" "}
                <LinkSupportChannel
                  theme="transparent"
                  target="_blank"
                  color="secondary300"
                  padding="0"
                  fontSize="14px"
                  margin="0"
                  style={{ display: "inline-block" }}
                  isTargetBlank
                >
                  chat with Fuzu Support
                </LinkSupportChannel>
              </Text>

              <Row>
                <Col lg="content" sm={12}>
                  <Button.Link to={from} width="100%">
                    Back to previous page
                  </Button.Link>
                </Col>
              </Row>
            </Card.Content>
          </Card>
        </PaymentMethodWrapper>
      </Card.Content>
    </Card>
  );
};

export default PaymentProblems;
