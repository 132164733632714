import React from "react";

import { PaymentMethodWrapper } from "./styled";
import { Card, Title, Button, Spinner, Text } from "../../../../components/Core";
import ResultContent from "../ResultContent";

const TransactionStatus = ({
  from,
  processing,
  completed,
  error,
  message,
  provider,
  onProblems,
  cancelTransaction
}) => {
  return (
    <PaymentMethodWrapper>
      <Title level={5} fontWeight={500} margin="0 0 16px">
        Payment Status
      </Title>

      <Card bordered>
        <Card.Content>
          {!completed && !error && (
            <>
              <Spinner size="72px" margin="48px auto 16px" />
              <Text margin="auto auto 0px" fontWeight={600} fontSize={18}>
                {message}
              </Text>
              <Text margin="16px auto" textAlign="center">
                We sent {provider?.paymentname} payment instructions to your mobile device, please follow the
                instructions on your phone. The product will be activated after we have received your payment. This
                might take a few minutes.
              </Text>
            </>
          )}

          {error && <ResultContent error={error} message={message} from={from} />}

          <Button margin="24px auto 12px" width="220px" onClick={onProblems}>
            Problems with payment?
          </Button>

          <Button
            processing={processing}
            margin="0px auto 16px"
            theme="grey100"
            width="220px"
            onClick={cancelTransaction}
          >
            Cancel
          </Button>
        </Card.Content>
      </Card>
    </PaymentMethodWrapper>
  );
};

export default TransactionStatus;
