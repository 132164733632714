import styled from "styled-components";

export const Title = styled.h1`
  color: #333;
  font-size: 32px;
  font-weight: 500;
  line-height: 120%;
  margin: 0 0 24px 0;
`;

export const Tile = styled.div`
  border-radius: 8px;
  background: #fff;
  padding: 16px;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (min-width: 1200px) {
    padding: 24px;
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const TileContent = styled.div`
  @media screen and (min-width: 768px) {
    width: 400px;
  }
`;

export const TileTitle = styled.h3`
  font-size: 20px;
  font-weight: 500;
  line-height: 130%;
  margin-bottom: 8px;
  color: ${({ $isError }) => ($isError ? "##EB3737" : "#333")};
`;

export const TileDescription = styled.p`
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 160%;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  flex-wrap: wrap;
  gap: 4px;

  span {
    color: #408bfc;
    font-weight: 500;
    margin: 0;
    background: transparent;
  }
`;

export const TileButton = styled.button`
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
  text-align: center;
  padding: 13px 24px;
  border-radius: 5px;
  cursor: pointer;
  color: ${({ $isError }) => ($isError ? "#FFF" : "#333")};
  background: ${({ $isError }) => ($isError ? "#EB3737" : "#eee")};

  ${({ $disabled }) =>
    $disabled
      ? `
        border-radius: 5px;
        opacity: 0.4;
        pointer-events: none;
        cursor: none;
      `
      : ""}}
`;

export const TileInputContainer = styled.div`
  position: relative;
`;

export const TileInputContent = styled.div`
  margin: 0 0 16px 0;
`;

export const TileInputPasswordButton = styled.div`
  position: absolute;
  right: 12px;
  top: 50%;
  height: 24px;
  width: 24px;
  transform: translateY(-50%);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: #a4a4a4;
  }
`;

export const TileInput = styled.input`
  padding: 11px 12px;
  background: #fff;
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: ${({ $marginBottom }) => $marginBottom || "16px"};
  border: 1px solid ${({ $isError }) => ($isError ? "#EB3737" : "#cdcdcd")};
  display: block;
  width: 100%;
  border-radius: 8px;

  &::placeholder {
    color: #a4a4a4;
  }
`;

export const InputError = styled.span`
  color: #eb3737;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  margin-top: 8px;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
`;

export const ActionsCancel = styled.button`
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
  text-align: center;
  padding: 13px 24px;
  border-radius: 5px;
  cursor: pointer;
  color: #333;
  background: #eee;
`;

export const ActionsSubmit = styled.button`
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
  text-align: center;
  padding: 13px 24px;
  border-radius: 5px;
  cursor: pointer;
  color: ${({ $color }) => $color};
  background: ${({ $background }) => $background};

  &:disabled {
    pointer-events: none;
    opacity: 0.4;
  }
`;

export const Info = styled.div`
  padding: 24px;
  border-radius: 8px;
  border: 1px solid #f3a900;
  background: rgba(255, 251, 232, 0.5);
  margin-bottom: 24px;

  h3 {
    color: #333;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 8px;
    display: block;
  }

  p {
    color: #666;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
    margin: 0;
  }
`;

export const Flex = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;

  .fz-simple-select__control {
    width: 368px;
    height: 40px;
  }

  .fz-simple-select__indicator-separator {
    display: none;
  }
`;
