import React from "react";
import parse from "html-react-parser";
import { useTranslation } from "b2c/hooks/useTranslation";

import { RightArrowAlt } from "@styled-icons/boxicons-regular";
import Marker from "images/b2c/icons/marker.svg";
import { Text, List, Label, Card } from "b2c/components/Core";
import { StyledCard, IconContent, ButtonBarona } from "./styled";

const PricingPlan = ({ badge, title, tagline, description, buttonText, isSm, openContactUsModal }) => {
  const { t } = useTranslation();

  const getIsSafari = () => {
    let isSafari = true;
    const ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf("safari") !== -1) {
      if (ua.indexOf("chrome") > -1) {
        isSafari = false;
      }
    }

    return isSafari;
  };

  const isSafari = getIsSafari();

  return (
    <StyledCard
      style={{
        height: isSm ? "600px" : "612px"
      }}
    >
      <Text
        style={{
          position: isSm ? "static" : "absolute",
          top: isSm ? "0" : "30px",
          left: isSm ? "0" : "20px",
          margin: "0"
        }}
      >
        <Label margin="0" theme="secondary200" color="white">
          {t(badge)}
        </Label>
      </Text>
      <Card.Header
        padding={isSm ? "0" : "10px 0 0 0"}
        style={{
          flexDirection: "column",
          marginTop: isSm ? "12px" : "40px"
        }}
      >
        <Text fontSize="28px" margin="0 0 4px 0" fontWeight="600" style={{ color: "#18181B" }}>
          {t(title)}
        </Text>
        <Text fontSize="14px" margin="0" fontWeight="600" style={{ color: "#666" }}>
          {parse(t(tagline))}
        </Text>
      </Card.Header>
      <Card.Separator height={1} margin={isSm ? "24px 0" : "20px 0 10px"} />
      <Card.Content padding={isSm ? "0" : "10px 0"} style={{ flex: "1 0 auto" }}>
        <List style={{ padding: "0", height: isSm ? "235px" : "230px" }}>
          {description.map((desc, i) => (
            <List.Item
              key={i}
              margin="0 0 10px"
              bulletSize="0"
              style={{ display: "block", height: isSafari ? (i === 2 ? "auto" : "auto") : "initial" }}
            >
              <Label
                width="20"
                height="20"
                Icon={
                  <IconContent>
                    <Marker
                      width={isSm ? 20 : 24}
                      style={{
                        marginRight: "15px"
                      }}
                    />
                  </IconContent>
                }
                lineHeight="20px"
                noBackground
                theme="primary"
                fontSize="14px"
                fontWeight="400"
                padding="0"
                margin="0 0 18px"
                style={{ color: "#666" }}
              >
                {t(desc)}
              </Label>
            </List.Item>
          ))}
        </List>
      </Card.Content>
      <Card.Footer
        style={{ flexDirection: "column" }}
        padding={isSm ? "0" : `${isSafari ? "5px" : "5px"} 0`}
        onClick={openContactUsModal}
      >
        <ButtonBarona color="white" fontSize="16px" width="100%" margin="10px 0 0 0">
          {t(buttonText)}
          <RightArrowAlt />
        </ButtonBarona>
      </Card.Footer>
    </StyledCard>
  );
};

export default PricingPlan;
