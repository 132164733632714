import React, { forwardRef, useRef } from "react";
import { observer } from "mobx-react";
import { Row, Col, Visible, useScreenClass } from "react-grid-system";
import { useLocation } from "react-router-dom";

import { getTimeSince } from "utils/helpers";
import phone_comments from "images/b2c/phone_comments.png";
import { useTranslation } from "b2c/hooks/useTranslation";
import useStore from "../../contexts/store";
import Message from "../CommentMessage";
import TypeArea from "../CommentTypeArea";
import { LazyLoader, Text, Image, Title, Suggestion } from "../Core";
import { GuestHidden, AuthorizationButton } from "../WithAuth";
import { useConfirmation, useImpressionTrack } from "../../hooks";
import { CommentsWrapper, CommentsList } from "./styled";
import { getSearchParams } from "../../../utils/getSearchParams";

const Comments = (
  { id: articleId, comments = [], comments_count = 0, addComment, updateComment, removeComment, margin, loaded },
  ref
) => {
  const location = useLocation();
  const isSm = /xs|sm|md/.test(useScreenClass());
  const { user } = useStore("User");
  const { t } = useTranslation();
  const sortedComments = [...comments]
    .filter(item => item?.status !== "deleted")
    .sort((a, b) => (new Date(a.created_at) > new Date(b.created_at) ? -1 : 1));
  const { targetRef } = useImpressionTrack({ type: "guest_comments" });

  const handleRemoval = useConfirmation(
    removeComment,
    t("global.comments.delete_modal.title"),
    t("global.delete"),
    {
      theme: "accent300"
    },
    t("global.confirmation")
  );
  const commentRef = useRef();
  const queryCommentId = getSearchParams(location.search).get("comment_id");

  return (
    <LazyLoader offset={150} once placeholder={t("global.comments.loading")}>
      <CommentsWrapper styled={{ margin }} ref={ref}>
        <Title level={3} margin="8px 0" fontWeight={600}>
          {comments_count} {t("global.comments.title")}
        </Title>

        <GuestHidden>
          <TypeArea
            margin="0 0 15px"
            avatarURL={user?.avatar}
            onSubmit={body => addComment(articleId, body)}
            loaded={loaded}
          />
          <CommentsList>
            {sortedComments.map(
              ({ id, user: commentUser, created_at, body = "", parent_id = null, children, status }) =>
                !parent_id && (
                  <Comments.Message
                    key={id}
                    isScrollTo={String(id) === queryCommentId}
                    authored={user?.id === commentUser?.id}
                    removable={user?.id === commentUser?.id}
                    status={status}
                    avatarURL={commentUser?.avatar}
                    userAvatar={user?.avatar}
                    authorName={`${commentUser?.first_name || ""} ${commentUser?.last_name || ""}`}
                    postingTime={getTimeSince(created_at * 1000, "")}
                    messageText={body}
                    onReply={bodyProp => addComment(articleId, bodyProp, id)}
                    onUpdate={bodyProp => updateComment(articleId, bodyProp, id)}
                    onRemove={() => handleRemoval(articleId, id)}
                    commentRef={commentRef}
                  >
                    {children &&
                      children.map(
                        ({
                          id: childId,
                          user: childUser,
                          created_at: childCreated_at = "",
                          body: childBody = "",
                          status: childStatus
                        }) => (
                          <Comments.Message
                            threadMessage
                            isScrollTo={String(id) === queryCommentId}
                            authored={user?.id === childUser?.id}
                            removable={user?.id === childUser?.id}
                            status={childStatus}
                            key={childId}
                            avatarURL={childUser?.avatar}
                            userAvatar={user?.avatar}
                            authorName={`${childUser?.first_name || ""} ${childUser?.last_name || ""}`}
                            postingTime={getTimeSince(childCreated_at * 1000, "")}
                            messageText={childBody}
                            onUpdate={bodyProp => updateComment(articleId, bodyProp, childId, id)}
                            onRemove={() => handleRemoval(articleId, childId, id)}
                            commentRef={commentRef}
                          />
                        )
                      )}
                  </Comments.Message>
                )
            )}
          </CommentsList>
        </GuestHidden>
        <Suggestion ref={targetRef} style={{ borderRadius: 6 }}>
          <Row>
            <Visible xxl xl lg>
              <Col width="content">
                <Image display="block" src={phone_comments} width={160} margin="28px 24px 0" />
              </Col>
            </Visible>
            <Col lg={7} style={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly" }}>
              <Text fontSize={isSm ? 18 : 24} margin={isSm ? "12px 0" : "0"} textAlign={isSm ? "center" : "left"}>
                {t("global.comments.sign_in_prompt.title")}
              </Text>
              <AuthorizationButton
                to="/login"
                type="join"
                theme="accent300"
                width="fit-content"
                margin={isSm ? "0 auto 12px" : "0"}
              >
                {t("global.comments.sign_in_prompt.button")}
              </AuthorizationButton>
            </Col>
          </Row>
        </Suggestion>
      </CommentsWrapper>
    </LazyLoader>
  );
};

Comments.Message = Message;

export default observer(forwardRef(Comments));
