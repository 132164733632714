import React, { useContext } from "react";
import { Content } from "./styled";
import ThemeContext from "../../contexts/theme";

const SvgContent = ({ children, style = {} }) => {
  const theme = useContext(ThemeContext);

  return (
    <Content fill={theme.primarySvgFillColor} style={style}>
      {children}
    </Content>
  );
};

export default SvgContent;
