import React, { useState, useLayoutEffect, useEffect } from "react";
import { observer } from "mobx-react";
import { Row, Col } from "react-grid-system";
import { Redirect } from "react-router-dom";

import { useTranslation } from "b2c/hooks/useTranslation";
import ProfileCVIco from "images/b2c/icons/profileCVIco.svg";
import DeskIco from "images/b2c/icons/deskIco.svg";
import BrainIco from "images/b2c/icons/brainIco.svg";
import { Text, Card, SelectInput } from "b2c/components/Core";
import Product from "b2c/components/Product";
import PhoneNumberModal from "b2c/components/ModalContents/PhoneNumber";
import useStore from "../../../../contexts/store";
import { useModal } from "../../../../../utils/hooks";
import { isBaronaBrand } from "../../../../contexts/branded";

const InstantFeedback = ({ applications, getApplicationList, targetRef }) => {
  const isBarona = isBaronaBrand();

  if (isBarona) {
    return <Redirect to="/" />;
  }

  const [selectedId, setSelectedId] = useState();
  const { user } = useStore("User");
  const { features } = useStore("Premium");
  const isFreeProduct = features?.instant_feedback_global?.activated;
  const isNotActiveProduct = features?.instant_feedback_global?.available === false;
  const { t } = useTranslation();

  const notAvailableText = user?.product_country?.name
    ? `${t("instant_feedback.not_available")} ${user?.product_country?.name}.`
    : "";

  const openModalPhoneNumber = useModal(
    <PhoneNumberModal
      isFreeProduct={isFreeProduct}
      isNotActiveProduct={isNotActiveProduct}
      notAvailableText={notAvailableText}
    />,
    "",
    () => {},
    false
  );

  useEffect(() => {
    if (!user?.product_country?.phone_code) {
      openModalPhoneNumber();
    }
  }, [user]);

  useLayoutEffect(() => {
    getApplicationList();
  }, []);

  const handleApplicationSelect = e => {
    const found = applications.find(job => `${job.title} - ${job.company_name}` === e.target?.value);
    setSelectedId(found?.id);
  };

  return (
    <>
      <Card bordered style={{ marginBottom: 15 }} wideOnMobile>
        <Card.Content padding="16px">
          <Row align="center">
            <Col xs="content">
              <DeskIco />
            </Col>
            <Col>
              <Text>{t("instant_feedback.label_1")}</Text>
            </Col>
          </Row>
        </Card.Content>
      </Card>
      <Card bordered style={{ marginBottom: 15 }} wideOnMobile>
        <Card.Content padding="16px">
          <Row align="center">
            <Col xs="content">
              <BrainIco />
            </Col>
            <Col>
              <Text>{t("instant_feedback.label_2")}</Text>
            </Col>
          </Row>
        </Card.Content>
      </Card>
      <Card bordered style={{ marginBottom: 15 }} wideOnMobile>
        <Card.Content padding="16px">
          <Row align="center">
            <Col xs="content">
              <ProfileCVIco />
            </Col>
            <Col>
              <Text>{t("instant_feedback.label_3")}</Text>
            </Col>
          </Row>
        </Card.Content>
      </Card>

      <Row>
        <Col md={6}>
          <Card style={{ marginBottom: 15 }} shadow wideOnMobile>
            <Card.Content padding="16px">
              <Text fontSize={16} fontWeight={600} margin="0 0 15px">
                {t("instant_feedback.products.title_1")}
              </Text>
              <SelectInput
                name="if_uid"
                label={t("instant_feedback.products.sub-title_1")}
                placeholder={t("instant_feedback.products.placeholder")}
                options={(applications || [])?.map(job => `${job.title} - ${job.company_name}`)}
                onChange={handleApplicationSelect}
                disabled={!applications}
              />
              <Product
                ref={targetRef}
                slug="position-specific-application-booster"
                position_id={selectedId}
                disabled={!selectedId}
                style={{ margin: "0 auto", width: "340px" }}
              />
            </Card.Content>
          </Card>
        </Col>
        <Col md={6} style={{ display: "flex" }}>
          <Card style={{ marginBottom: 15, flex: "1 0 0" }} shadow wideOnMobile>
            <Card.Content padding="16px" height="100%" style={{ justifyContent: "space-between" }}>
              <Text fontSize={16} fontWeight={600} margin="0 0 15px">
                {t("instant_feedback.products.title_2")}
              </Text>
              <Product
                theme="grey100"
                slug="global-application-booster-30-days"
                pro
                style={{ margin: "0 auto", width: "340px" }}
              />
            </Card.Content>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default observer(InstantFeedback);
