import styled from "styled-components";

export const Actions = styled.div`
  margin-top: 32px;

  .hamburger-link,
  .hamburger-text {
    color: #eee;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    display; block;
    margin-bottom: 12px;
    text-decoration: none;
    display: block;
  }
  
  .hamburger-text {
    cursor: default;
    margin: 0;
  }

  @media screen and (min-width: 768px) {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-left: auto;   
    margin-top: 0;
    
    .hamburger-link {
      margin-bottom: 0;
    }
  }
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
