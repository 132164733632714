import React, { useState, useContext } from "react";
import "finally-polyfill";
import { Link, useHistory } from "react-router-dom";

import { brandTypes, brandName } from "b2c/constants/brand";
import { validateForm } from "utils/validation";
import { onEnterPress } from "utils/helpers";
import { useQueryParams, useValidationTimeout } from "utils/hooks";
import { Dropdown, Text, Input, Checkbox, Button } from "b2c/components/Core";
import BrandedContext, {
  BrandVisible,
  BrandHidden,
  WhitelabelledOrCustomHidden,
  isMatchBrand,
  isCustomJkuat
} from "../../../../contexts/branded";
import { withTracking } from "../../../../hocs";
import { Flex, ButtonBack } from "./styled";

const defaultValidation = {
  first_name: { error: false },
  last_name: { error: false },
  passport_number: { error: false },
  email: { error: false },
  password: { error: false },
  confirmation: { error: false },
  visible: { error: false },
  terms: { error: false },
  fuzu_country_id: { error: false }
};

const FormStep = ({
  state: { loading, processing, message },
  form,
  countries = [],
  submitForm,
  validation_errors,
  setStep,
  setEmail,
  isJKUAT
}) => {
  const { employer, brand_type } = useContext(BrandedContext);
  const [formState, setFormState] = useState(form);
  const { isTimedOut, setTimeout } = useValidationTimeout(3);
  const history = useHistory();
  const [validationState, setValidation] = useState(defaultValidation);
  const { return_to: returnTo } = useQueryParams();
  const isCustomPlatform = brand_type === brandTypes.customPlatform;
  const customJkuat = isCustomJkuat();

  const handleSubmission = e => {
    e.preventDefault();
    setValidation(defaultValidation);
    if (isTimedOut) return;

    const { valid, validation } = validateForm(formState);
    const phoneNumber = formState?.phone_number?.value;

    if (phoneNumber && phoneNumber.includes(" ")) {
      formState.phone_number.value = phoneNumber.replaceAll(" ", "");
    }

    return submitForm(valid, formState)?.finally(() => {
      setValidation(validation);
      setTimeout();
    });
  };

  const isDisableButtonDefault =
    !formState?.first_name || !formState.password || !formState.confirmation || !formState.terms || loading;

  const isDisableButton = isDisableButtonDefault || !formState?.email;
  const isDisableButtonBrand = isDisableButtonDefault || !formState.phone_number;
  const isDisableButtonJkuatBrand =
    isDisableButtonBrand ||
    !formState.passport_number ||
    !formState.last_name ||
    !formState.email ||
    !formState.email ||
    !formState?.phone_number?.value ||
    formState?.phone_number?.value === "+";

  const handleBackButton = () => {
    if (!isCustomPlatform && returnTo) {
      history.push(returnTo);
    } else {
      setEmail("");
      setStep(4);
    }
  };

  const onChangePassword = ({ target: { value } }) => {
    if (customJkuat) {
      setFormState(prev => ({ ...prev, password: value, confirmation: value }));
    } else {
      setFormState(prev => ({ ...prev, password: value }));
    }
  };

  const disableButton = () => {
    if (isMatchBrand(["ncpwd", "demo"])) {
      return isDisableButtonBrand;
    }

    if (isJKUAT) {
      return isDisableButtonJkuatBrand;
    }

    return isDisableButton;
  };
  return (
    <form onSubmit={handleSubmission} onKeyDown={e => onEnterPress(e, 1, () => handleSubmission(e))} noValidate>
      <Flex>
        <Input
          data-cy="first-name"
          key="first_name"
          label="First name"
          placeholder="Your first name"
          autoComplete="name"
          fieldRequired
          value={formState.first_name}
          onChange={({ target: { value } }) => setFormState(prev => ({ ...prev, first_name: value }))}
          errorMessage={validationState?.first_name?.error || validation_errors.first_name}
        />

        {isJKUAT && (
          <Input
            key="last_name"
            label="Middle and last name"
            placeholder="Your middle and last name"
            autoComplete="name"
            fieldRequired
            value={formState.last_name}
            onChange={({ target: { value } }) => setFormState(prev => ({ ...prev, last_name: value }))}
            errorMessage={validationState?.last_name?.error || validation_errors.last_name}
          />
        )}
      </Flex>

      <BrandHidden brand={["ncpwd", "demo"]}>
        <Input
          key="email"
          label="Email"
          placeholder="example@mail.com"
          type="email"
          autoComplete="email"
          fieldRequired
          value={formState?.email}
          onChange={({ target: { value } }) => setFormState(prev => ({ ...prev, email: value }))}
          errorMessage={validationState?.email?.error || validation_errors?.email}
        />
      </BrandHidden>

      {isJKUAT && (
        <Input
          key="passport_number"
          label="National ID / Passport number"
          placeholder="e.g. 12345678"
          fieldRequired
          value={formState.passport_number}
          onChange={({ target: { value } }) => setFormState(prev => ({ ...prev, passport_number: value }))}
          errorMessage={validationState?.passport_number?.error || validation_errors.passport_number}
        />
      )}

      <Dropdown
        id="country-dropdown"
        data-cy="country-dropdown"
        name="country"
        label="Country"
        placeholder="Select an option"
        defaultValue={countries.find(country => country.id === formState.fuzu_country_id)?.name || ""}
        errorMessage={validationState?.fuzu_country_id?.error || validation_errors.fuzu_country_id}
      >
        <Dropdown.Menu disableSorting isFixedSm={!!navigator.platform && /iPhone/.test(navigator.platform)}>
          {countries.map(({ id, name }) => (
            <Dropdown.Item
              key={id}
              value={name}
              onClick={() => setFormState(prev => ({ ...prev, fuzu_country_id: id }))}
            >
              {name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>

      <BrandVisible brand={["ncpwd", "demo", brandName.jkuat]}>
        <Input
          label="Your telephone number"
          placeholder="123456789"
          type="tel"
          fieldRequired
          value={formState.phone_number ? formState.phone_number.value : ""}
          onChange={({ target: { value } }) => setFormState(prev => ({ ...prev, phone_number: { value } }))}
          errorMessage={validation_errors["phone_number.value"]}
          inputMask="+999 999 99 99 99 9999 9999 9999"
        />
      </BrandVisible>

      <Input
        data-cy="password"
        label="Password"
        placeholder="Minimum 8 characters"
        type="password"
        autoComplete="new-password"
        fieldRequired
        value={formState.password}
        onChange={onChangePassword}
        errorMessage={validationState.password.error || validation_errors.password}
      />

      {!isJKUAT && (
        <Input
          data-cy="confirmation"
          label="Confirm password"
          placeholder="Re-enter your password"
          type="password"
          autoComplete="new-password"
          fieldRequired
          value={formState.confirmation}
          onChange={({ target: { value } }) => setFormState(prev => ({ ...prev, confirmation: value }))}
          errorMessage={validationState.confirmation.error || validation_errors.password_confirmation}
        />
      )}

      <WhitelabelledOrCustomHidden>
        <Checkbox
          describedBy="visibility-description"
          label={
            <Text id="visibility-description" color="black400">
              Make my <BrandVisible brand="www">Fuzu </BrandVisible>profile visible to employers so that they can find
              me and send me messages inside
              <BrandVisible brand="www"> Fuzu platform</BrandVisible>
              <BrandHidden brand="www"> {employer?.name} Career Platform</BrandHidden>
            </Text>
          }
          id="test1"
          checked={formState.visible}
          onChange={({ target: { checked } }) => setFormState(prev => ({ ...prev, visible: checked }))}
          errorMessage={validation_errors.talent_pool_visibility}
        />
      </WhitelabelledOrCustomHidden>
      <Checkbox
        data-cy="terms_privacy_consent"
        describedBy="agreement-description"
        label={
          <Text id="agreement-description" color="black400">
            By registering, you agree with our{" "}
            <Link className="link link_blue" to="/terms-and-conditions">
              Service Terms
            </Link>{" "}
            and{" "}
            <Link className="link link_blue" to="/privacy-policy">
              Privacy Policy
            </Link>
          </Text>
        }
        id="test2"
        checked={formState.terms}
        onChange={({ target: { checked } }) => setFormState(prev => ({ ...prev, terms: checked }))}
        errorMessage={validationState.terms.error || validation_errors.terms_privacy_consent}
      />
      {message ||
        (isTimedOut && (
          <Text color="accent300" margin="4px 0 0">
            {isTimedOut
              ? "You have reached the limit of registration attempts. Please try again in few minutes."
              : message}
          </Text>
        ))}
      <Button
        type="submit"
        disabled={disableButton()}
        processing={processing}
        fontSize="14px"
        margin="15px 0"
        width="100%"
        height="48px"
      >
        Register
      </Button>

      <BrandVisible brand={["ncpwd"]}>
        <ButtonBack type="button" onClick={handleBackButton}>
          Back
        </ButtonBack>
      </BrandVisible>
    </form>
  );
};

export default withTracking(FormStep, "sign_up_flow_started", { flow: "old" });
