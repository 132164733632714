import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";

import { useTranslation } from "b2c/hooks/useTranslation";
import { Checkbox } from "../../../../components/Core";
import Content from "../Content";
import { Container, Item, Title } from "./styled";
import Navigation from "../Navigation";
import useStore from "../../../../contexts/store";
import Steps from "../Steps";

const Skills = () => {
  const [selected, setSelected] = useState([]);
  const history = useHistory();
  const { getOnboardingSkills, onboardingFlow } = useStore("Onboarding");
  const { t } = useTranslation();

  const handleSelect = event => {
    const { value } = event.target;
    // const findJob = selected.find(item => item === +value);

    setSelected(prev => {
      if (prev.includes(+value)) {
        return prev.filter(item => item !== +value);
      }
      return prev.concat(+value);
    });
  };

  const handleSubmit = () => {
    const prevOnboardingForm = JSON.parse(sessionStorage.getItem("onboardingFlow"));

    const onboardingForm = {
      ...prevOnboardingForm,
      skills: selected
    };

    sessionStorage.setItem("onboardingFlow", JSON.stringify(onboardingForm));

    history.push("/onboarding/seniority-level");
  };

  useEffect(() => {
    if (!onboardingFlow?.skills?.length) {
      getOnboardingSkills();
    }
  }, []);

  useEffect(() => {
    const onboardingFlowStorage = JSON.parse(sessionStorage.getItem("onboardingFlow"));
    const skills = onboardingFlowStorage?.skills;

    if (skills && !selected.length && onboardingFlow.skills?.length) {
      setSelected(skills);
    }
  }, [onboardingFlow.skills?.length]);

  return (
    <>
      <Steps step="300" />

      <Container>
        <Content title={t("onboarding.super_power.title")} description={t("onboarding.super_power.description")}>
          {onboardingFlow.skills?.map(({ id, name }) => (
            <Checkbox
              key={id}
              id={id}
              name="job"
              value={id}
              borderedView
              margin="6px 0"
              onChange={handleSelect}
              checked={selected.includes(id)}
              markColor="white"
              checkedColor="secondary200"
              checkedBgColor="secondary100"
              style={{
                outline: "none"
              }}
              labelProps={{
                style: {
                  flexDirection: "row-reverse",
                  justifyContent: "space-between",
                  borderColor: selected?.id === id ? "#4690FF" : "#C5C5C5",
                  marginBottom: "16px",
                  padding: "15px 16px",
                  borderRadius: "8px",
                  alignItems: "center"
                }
              }}
              label={
                <Item>
                  <Title>{name}</Title>
                </Item>
              }
            />
          ))}
        </Content>
      </Container>
      <Navigation backUrl="/onboarding/interest-areas" isDisabled={!selected.length} handleSubmit={handleSubmit} />
    </>
  );
};

export default observer(Skills);
