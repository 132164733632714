import React, { useRef } from "react";
import Select from "react-select";
import { MdClose } from "react-icons/md";
import cn from "classnames";

import { Container, Close } from "./styles";

const SelectComponent = ({
  className,
  options = [],
  defaultValue,
  value,
  noOptionsMessage = () => "No options",
  onSelect
}) => {
  const wrapper = useRef();
  const refContainer = useRef();

  const handleSelect = e => {
    onSelect(e);
  };

  const handleRemove = () => {
    onSelect(null);
  };

  return (
    <Container ref={refContainer}>
      <Select
        className={cn("fz-simple-select", className)}
        classNamePrefix="select"
        placeholder={defaultValue}
        getOptionLabel={option => option.name}
        getOptionValue={option => option.id.toString()}
        isClearable
        isSearchable
        options={options}
        onChange={handleSelect}
        noOptionsMessage={noOptionsMessage}
        value={value}
        ref={wrapper}
      />
      <Close type="button" onClick={handleRemove}>
        <MdClose />
      </Close>
    </Container>
  );
};

export default SelectComponent;
