import styled from "styled-components";

export const Label = styled.label`
  color: #333;
  font-size: 14px;
  font-weight: 500;
  display: block;
  margin-bottom: 4px;
`;

export const InputDescription = styled.span`
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  display: block;
  margin-bottom: 8px;
`;
