import React, { useRef } from "react";
import Select from "react-select";
import { MdClose, MdSearch } from "react-icons/md";
import cn from "classnames";

import { Container, Close, SearchIcon } from "./styles";

const customSingleValue = () => {
  return null;
};

const SelectSearch = ({
  className,
  options = [],
  defaultValue,
  value,
  onInputChange,
  inputValue,
  noOptionsMessage = () => "No options",
  onSelect,
  onRemove,
  classNameInput = ""
}) => {
  const wrapper = useRef();
  const refContainer = useRef();

  const handleSelect = e => {
    onSelect(e);
  };

  const handleRemove = () => {
    onSelect(null);
    onInputChange(null);

    if (onRemove) {
      onRemove();
    }
  };

  return (
    <Container ref={refContainer}>
      <SearchIcon>
        <MdSearch />
      </SearchIcon>
      <Select
        classNames={{
          input: () => classNameInput
        }}
        className={cn("fz-simple-select", className)}
        classNamePrefix="select"
        placeholder={defaultValue}
        getOptionLabel={option => option.name}
        getOptionValue={option => option.id.toString()}
        isClearable
        isSearchable
        options={options}
        onChange={handleSelect}
        noOptionsMessage={noOptionsMessage}
        onInputChange={onInputChange}
        inputValue={inputValue}
        value={value}
        components={{ SingleValue: customSingleValue }}
        ref={wrapper}
      />
      <Close type="button" onClick={handleRemove}>
        <MdClose />
      </Close>
    </Container>
  );
};

export default SelectSearch;
