import React, { useState } from "react";
import { Link } from "react-router-dom";

import { useTranslation } from "b2c/hooks/useTranslation";
import { validateEmail } from "utils/validation";
import { Title, Text, Input, Button, Divider } from "b2c/components/Core";

const PasswordReset = ({ state: { processing, message }, userResetPassword }) => {
  const [sent, setEmailSent] = useState(false);
  const [validationError, setError] = useState("");
  const { t } = useTranslation();

  const handleSubmission = e => {
    e.preventDefault();
    setError("");

    const { error, test } = validateEmail(e.target.email.value);

    return test
      ? userResetPassword(e.target.email.value).then(success => {
          setEmailSent(success);
        })
      : setError(error);
  };

  return (
    <>
      <Title level="3" fontWeight={500} margin="0 0 15px">
        {t("reset_password.title")}
      </Title>
      {!sent ? (
        <>
          <Text lineHeight="140%" margin="0 0 40px" color="black300">
            {t("reset_password.description")}
          </Text>
          <form onSubmit={handleSubmission} noValidate>
            <Input
              label={t("reset_password.email_label")}
              placeholder="example@domain.com"
              name="email"
              type="email"
              autoComplete="email"
              errorMessage={validationError}
            />
            {message && (
              <Text color="secondary300" margin="-15px 0 0">
                {message}
              </Text>
            )}
            <Button type="submit" processing={processing} fontSize="14px" margin="15px 0" width="100%" height="48px">
              {t("reset_password.submit_button")}
            </Button>
            <Text lineHeight="140%" margin="0" color="black300">
              {t("reset_password.changed_main")}{" "}
              <Link className="link" to="/login">
                {t("reset_password.back_to_login")}
              </Link>
            </Text>
          </form>
        </>
      ) : (
        <>
          <Text lineHeight="140%" color="black300">
            {message}
          </Text>
          <Divider margin="16px 0" />
          <Link className="link" to="/login">
            {t("reset_password.back_to_login")}
          </Link>
        </>
      )}
    </>
  );
};

export default PasswordReset;
