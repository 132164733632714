import styled from "styled-components";

export const StyledHeader = styled.div`
  width: 100%;
  height: 56px;
  background: #ffffff;
  border-bottom: 1px solid #e1e1e1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledHeaderContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  padding: 0 16px;
`;

export const StyledBody = styled.div`
  overflow-x: hidden;
  overflow-y: scroll;
  flex-grow: 1;
`;

export const StyledBodyContent = styled.div`
  width: 100%;
  height: 100%;
`;
