import React from "react";
import { observer } from "mobx-react";
import parse from "html-react-parser";

import { useTranslation } from "b2c/hooks/useTranslation";
import InfoSection from "b2c/components/InfoSection";
import { Redirect } from "react-router-dom";
import Layout from "../../components/Layout";
import { Title, Text, Card } from "../../components/Core";
import { withTrackedRoute } from "../../hocs";
import { useDocumentTitle } from "../../../utils/hooks";
import { isBaronaBrand } from "../../contexts/branded";

const PrivacyPolicyScreen = () => {
  const isBarona = isBaronaBrand();
  const { t } = useTranslation();

  if (isBarona) {
    return <Redirect to="/" />;
  }

  const privacyPolicyData = [
    {
      title: t("privacy_policy.list.title_1"),
      text: t("privacy_policy.list.description_1")
    },
    {
      title: t("privacy_policy.list.title_2"),
      text: t("privacy_policy.list.description_2")
    },
    {
      title: t("privacy_policy.list.title_3"),
      text: t("privacy_policy.list.description_3")
    },
    {
      title: t("privacy_policy.list.title_4"),
      text: t("privacy_policy.list.description_4")
    },
    {
      title: t("privacy_policy.list.title_5"),
      text: t("privacy_policy.list.description_5")
    },
    {
      title: t("privacy_policy.list.title_6"),
      text: t("privacy_policy.list.description_6")
    },
    {
      title: t("privacy_policy.list.title_7"),
      text: t("privacy_policy.list.description_7")
    },
    {
      title: t("privacy_policy.list.title_8"),
      text: t("privacy_policy.list.description_8")
    },
    {
      title: t("privacy_policy.list.title_9"),
      text: t("privacy_policy.list.description_9")
    },
    {
      title: t("privacy_policy.list.title_10"),
      text: t("privacy_policy.list.description_10")
    },
    {
      title: t("privacy_policy.list.title_11"),
      text: t("privacy_policy.list.description_11")
    }
  ];

  useDocumentTitle(t("privacy_policy.meta_title"));

  return (
    <Layout.Content size="xl">
      <Title level="5" fontWeight={600} margin="30px 0 20px">
        {t("privacy_policy.title")}
      </Title>
      <Text margin="0 0 20px">{t("privacy_policy.effective_date")}</Text>

      <Card shadow style={{ marginBottom: 20 }}>
        <Card.Content>
          <Text fontSize={16} fontWeight={600} margin="0 0 4px" style={{ textTransform: "uppercase" }}>
            WE CARE ABOUT YOUR PRIVACY
            {t("privacy_policy.sub-title")}
          </Text>
          <Text lineHeight="140%">{parse(t("privacy_policy.description"))}</Text>
        </Card.Content>
      </Card>
      <InfoSection data={privacyPolicyData} />
    </Layout.Content>
  );
};

export default withTrackedRoute(observer(PrivacyPolicyScreen));
