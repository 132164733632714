import styled from "styled-components";
import { Dropdown } from "../Core";

export const Content = styled.div`
  padding: 16px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 4px 16px 0 rgba(10, 23, 49, 0.1);
`;

export const DropdownMenu = styled(Dropdown.Menu)`
  padding: 16px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 4px 16px 0 rgba(10, 23, 49, 0.1);
  right: 15px;
  top: 46px;
  width: 290px;

  .dropdown-list {
    max-height: none;
  }

  .dropdown-item {
    color: #333;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    padding: 8px;
    border-radius: 5px;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 8px;
    position: relative;

    &:hover {
      background: #f5f5f5;
      color: #333;
    }

    &_logout {
      margin-top: 16px;
      outline: none;

      svg {
        width: 16px;
        height: 16px;
        flex: none;
      }

      &:before {
        content: "";
        position: absolute;
        display: block;
        top: -8px;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #eee;
      }
    }
  }
`;

export const ImageContent = styled.div`
  width: 48px;
  height: 48px;
  flex: none;
  border-radius: 5px;
  border: 1px solid #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  img {
    max-width: 100%;
  }
`;

export const Heading = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding-bottom: 16px;
  border-bottom: 1px solid #eee;
  margin-bottom: 8px;
`;

export const Name = styled.p`
  color: #333;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  width: 210px;
`;

export const Email = styled.p`
  color: #666;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  width: 210px;
`;
