import React, { useState, useEffect, useContext, useMemo, Suspense } from "react";
import { Row, Col, Visible } from "react-grid-system";
import { Timer, Map } from "@styled-icons/boxicons-solid";
import { LeftArrowAlt, Buildings } from "@styled-icons/boxicons-regular";

import { useTranslation } from "b2c/hooks/useTranslation";
import logo_placeholder from "images/b2c/logo-placeholder.png";
import blurImage from "images/b2c/blur.png";
import { thousandSeparator, capitalize, calculateDaysLeft } from "utils/helpers";
import getIndustryStyles from "utils/industryImageMap";
import JobInfo from "b2c/pages/JobSearch/components/JobInfo";
import { parseDescription } from "b2c/utils/helpers";
import { applicationTypes } from "b2c/constants/jobSearch";
import { languageFormat } from "b2c/constants/main";
import FastApply from "b2c/components/FastApply";
import { Title, Text, Button, Image, Label, Suggestion } from "../Core";
import Accessibilities from "../Accessibilities";
import PositionTags from "../PositionTags";
import SaveButton from "../SaveButton";
import Tabs from "../Tabs";
import Company from "../Company";
import InstantFeedbackBanner from "../InstantFeedbackBanner";
import ShareButton from "../ShareButton";
import { GuestHidden, withGuestVersion } from "../WithAuth";
import BrandedContext, {
  isCustomJkuat,
  isWhitelabelled,
  isWhitelabelledOrCustomBranded,
  WhitelabelledOrCustomHidden
} from "../../contexts/branded";
import HardNavigationLink from "../../../components/HardNavigationLink";
import useStore from "../../contexts/store";
import { countryPath, jobPath, townPath } from "../../utils/routes";
import LinkWithFullUrl from "../../../components/LinkWithFullUrl";
import Articles from "../Articles";
import ActionRequired from "../ActionRequired";
import AuthorizedActions from "../../pages/EntityPage/components/AuthorizedActions";
import GuestActions from "../GuestActions";
import { useIsBarona, useLanguageAndCountryUrl } from "../../hooks";
import { Tags } from "./styled";

const JobDetailsItem = ({
  selected,
  selectedIndex,
  pathname,
  search,
  saveJob,
  setSelected,
  isMobile,
  single = false,
  promptRef,
  Actions = DefaultActions,
  isWhiteLabelled
}) => {
  const { brand_type } = useContext(BrandedContext);
  const [activeTab, setActiveTab] = useState("description");
  const { user } = useStore("User");
  const { ssr } = useStore("initialState");
  const isFastApply = selected?.application_type === applicationTypes.applicationTypeFastApply;
  const isAssigmentRequested =
    selected?.application?.fast_apply_status === applicationTypes.fastApplyAssignmentsRequested;
  const { t, language } = useTranslation();
  const languageFormatType = languageFormat[language];

  const { Icon } = getIndustryStyles(selected.industry);
  const languageAndLanguage = useLanguageAndCountryUrl();

  const formatDate = date =>
    date
      ? new Date(date * 1000).toLocaleString(languageFormatType, {
          day: "numeric",
          month: "short",
          year: "numeric"
        })
      : "";

  const left = calculateDaysLeft(selected.campaign_end_date);
  const datePublished = formatDate(selected.campaign_start_date);
  const closingDate = formatDate(selected.campaign_end_date);
  const daysLeft = Math.ceil((selected.campaign_end_date - Date.now() / 1000) / (3600 * 24));
  const relevant = daysLeft > 3;
  const expired = daysLeft < 0;

  const expiredDateText = !expired && daysLeft === 0 ? t("jobs.content.closing_today") : left;
  const customJkuat = isCustomJkuat();
  const whitelabelled = isWhitelabelled();

  const requirementMap = {
    education: t("jobs.content.jobs_requirements.education"),
    languages: t("jobs.content.jobs_requirements.languages"),
    work_experience: t("jobs.content.jobs_requirements.experience")
  };

  const fromUrl = whitelabelled ? `${pathname}/${selected.slug}` : selected.path;
  const returnToUrl = selected.path;

  const isAnonymous = useMemo(() => selected.access_type === "access_type_anonymous", [selected]);

  const isCompanyLinkable = useMemo(() => {
    return selected.company_accepted && (single || !isAnonymous) && !whitelabelled && !customJkuat;
  }, [selected, brand_type, isAnonymous, single]);

  const tabs = [
    {
      id: "description",
      title: t("jobs.content.tabs.description"),
      to: `/jobs/${selected.slug}`
    },
    {
      id: "company",
      title: t("jobs.content.tabs.company"),
      hidden: isAnonymous,
      to: selected.company_path
    }
  ];

  const actionProps = {
    selected,
    selectedIndex,
    fromUrl: returnToUrl,
    search,
    setSelected,
    expired
  };

  const isGuestUser = !user?.id && !isWhiteLabelled;
  const isBarona = useIsBarona();

  const blurImg = (
    <Image
      src={blurImage}
      width="110px"
      height="20px"
      margin="0 5px"
      objectFit="fill"
      style={{ display: "inline-block", verticalAlign: "bottom" }}
    />
  );

  const isAllowSaveJob = () => {
    if (selected.application && saveJob) return false;

    return !selected.expired;
  };

  const isWhitelabelledOrCustom = isWhitelabelledOrCustomBranded();
  const renderJobPath = () => {
    if (languageAndLanguage) {
      return `${languageAndLanguage}/jobs/${selected.slug}`;
    }

    return jobPath(selected);
  };

  useEffect(() => {
    setActiveTab("description");
  }, [selected]);

  return (
    <>
      {isMobile && selected.id && setSelected && (
        <Button
          fontSize={14}
          noBackground
          theme="black500"
          padding={0}
          onClick={() => setSelected({})}
          icon={<LeftArrowAlt width={16} />}
        >
          {t("jobs.content.return_back")}
        </Button>
      )}

      <div className="view-header">
        <div className="view-logo">
          {selected.company_logo && !isAnonymous ? (
            <>
              {isCompanyLinkable ? (
                <Button.Link to={selected?.company_path} height={110} theme="transparent">
                  <Image
                    src={selected.company_logo}
                    placeholder={logo_placeholder}
                    width={110}
                    height={110}
                    objectFit="contain"
                  />
                </Button.Link>
              ) : (
                <Image
                  src={selected.company_logo}
                  placeholder={logo_placeholder}
                  width={110}
                  height={110}
                  objectFit="contain"
                />
              )}
            </>
          ) : (
            <Icon width={110} height={110} />
          )}
        </div>

        <div className="content-main-info" data-cy="job-main-info">
          <ActionRequired isShow={isAssigmentRequested} />

          <Tags>
            {selected.application && !isAssigmentRequested && (
              <Label
                rect
                theme={selected.application.submitted ? "success200" : "primary100"}
                color={selected.application.submitted ? "white" : "black500"}
                height={24}
                margin="0"
              >
                {selected.application.submitted
                  ? t("jobs.content.submit_status.applied")
                  : t("jobs.content.submit_status.unfinished")}
              </Label>
            )}
            {selected.brand_tag && !isBarona && (
              <Label display="inline-block" rect noBackground bordered theme="black200" height={24} margin={0}>
                {`${t("jobs.content.only_on")} `}
                {selected.brand_tag}
              </Label>
            )}
          </Tags>

          {selected.expired && (
            <Label display="inline-block" rect noBackground bordered theme="accent300" height={24} margin="0 0 0 4px">
              {t("jobs.content.closed_for_applications")}
            </Label>
          )}
          <Title
            level="1"
            h1ForGuests={single}
            fontWeight="600"
            margin="6px 0"
            fontSize="20px"
            color={selected.expired ? "accent300" : "black"}
          >
            {selected.title}
          </Title>
          <div>
            <Label
              Icon={<Buildings width={16} />}
              fontWeight={400}
              noBackground
              padding={0}
              theme="black200"
              margin="4px 0"
            >
              <Text
                as={isCompanyLinkable ? LinkWithFullUrl : "div"}
                color={(single || !isAnonymous) && !whitelabelled && !customJkuat ? "secondary300" : "black300"}
                to={selected?.company_path}
                className={!isAnonymous && !whitelabelled && !customJkuat ? "link underlined" : ""}
                data-cy="company-name"
              >
                {selected.company_name}
              </Text>
            </Label>
          </div>
          <div>
            <Label
              Icon={<Map width={16} />}
              fontWeight={400}
              noBackground
              padding={0}
              theme="black200"
              margin="4px 0 8px"
            >
              {selected?.town && (
                <Text
                  as={!whitelabelled && !customJkuat ? HardNavigationLink : "div"}
                  color={!whitelabelled && !customJkuat ? "secondary300" : "black300"}
                  to={townPath(selected.town)}
                  className={!whitelabelled && !customJkuat ? "link underlined" : ""}
                  style={{
                    marginRight: "5px"
                  }}
                >
                  {selected.town.name},
                </Text>
              )}
              {selected?.country && (
                <Text
                  as={selected.country.slug && !whitelabelled && !customJkuat ? LinkWithFullUrl : "div"}
                  color={!whitelabelled && !customJkuat ? "secondary300" : "black300"}
                  to={countryPath(selected.country)}
                  className={!whitelabelled && !customJkuat ? "link underlined" : ""}
                >
                  {selected.country.name}
                </Text>
              )}
              {!selected?.country && !selected?.town && <div>{selected?.location}</div>}
            </Label>
          </div>

          {Actions && (
            <Visible xxl xl lg md>
              <Actions {...actionProps} isFastApply={isFastApply} isAssigmentRequested={isAssigmentRequested} />
            </Visible>
          )}
        </div>

        <div className="content-additional-info">
          <div className="action-buttons">
            <Row gutterWidth={8} align="stretch">
              <Col width="content">
                {isAllowSaveJob() && (
                  <SaveButton
                    id={selected.id}
                    saved={selected.saved}
                    handleSaving={saved => saveJob(selected.id, saved)}
                    title={t("jobs.content.save.title_1")}
                    titleSaved={t("jobs.content.save.title_2")}
                    isWhitelabelledOrCustom={isWhitelabelledOrCustom}
                  />
                )}
              </Col>
              {!expired && (
                <Col width="content">
                  <ShareButton prefix={t("global.share.position")} path={renderJobPath()} name={selected.title} />
                </Col>
              )}
            </Row>
          </div>

          <div className="additional">
            <Text className="published" color="black200">
              {t("jobs.content.closing")}: {closingDate}
            </Text>
            <Label
              noBackground
              padding="4px 0"
              margin={isMobile ? "0" : "0 0 0 auto"}
              theme={relevant && !expired ? "success200" : "accent300"}
              Icon={<Timer width={20} />}
              fontSize={14}
            >
              {expired ? t("jobs.content.expired") : expiredDateText}
            </Label>
            <Text className="published" color="black200">
              {`${t("jobs.content.published")}: ${datePublished}`}
            </Text>
          </div>
        </div>
      </div>
      <div className="view-main">
        <WhitelabelledOrCustomHidden>
          <Tabs theme="grey100" margin="0 0 20px">
            {tabs.map(
              ({ id, title, hidden, ...rest }) =>
                !hidden && (
                  <Tabs.Tab
                    key={`uid-${id}`}
                    selected={activeTab === id}
                    theme="grey100"
                    onClick={() => setActiveTab(id)}
                    {...rest}
                  >
                    {title}
                  </Tabs.Tab>
                )
            )}
          </Tabs>
        </WhitelabelledOrCustomHidden>
        <Tabs.TabPanel active={activeTab === "description"}>
          <JobInfo selected={selected} />

          {!customJkuat && selected.instant_feedback?.activated && (
            <GuestHidden>
              <InstantFeedbackBanner ref={promptRef} position_id={selected.id} {...selected.instant_feedback} />
            </GuestHidden>
          )}

          <div className="view-requirements">
            {!ssr && <FastApply t={t} isShow={isFastApply} />}

            <Title scales level={5} fontWeight={600} margin="8px 0">
              {t("jobs.content.jobs_requirements.title")}
            </Title>

            {selected.requirements
              ? Object.keys(selected.requirements).map(req => (
                  <div key={req}>
                    <Text inline fontWeight={500} color="black200" margin="0 6px 0 0">
                      {requirementMap[req]}:
                    </Text>

                    {isGuestUser && !isBarona ? (
                      <>{blurImg}</>
                    ) : (
                      <Text inline data-cy="job-requirements-item">
                        {Array.isArray(selected.requirements[req])
                          ? selected.requirements[req].join(", ")
                          : selected.requirements[req]}
                      </Text>
                    )}
                  </div>
                ))
              : null}
          </div>
          {selected.description ? (
            <div className="view-summary">
              <Title scales level={5} fontWeight={600} margin="8px 0">
                {t("jobs.content.job_summary")}
              </Title>
              {(selected.salary_max > 0 || selected.salary_min > 0) && (
                <div>
                  <Text inline fontWeight={500} color="black200" margin="0 6px 0 0">
                    {t("jobs.content.salary")}:
                  </Text>
                  {isGuestUser && !isBarona ? (
                    <>{blurImg}</>
                  ) : (
                    <Text inline>
                      {selected.salary_min > 0 && (
                        <>
                          {selected.salary_min_currency?.toUpperCase()} {thousandSeparator(selected.salary_min)}
                        </>
                      )}
                      {selected.salary_max > 0 && selected.salary_min > 0 && " – "}
                      {selected.salary_max > 0 && (
                        <>
                          {selected.salary_max_currency?.toUpperCase()} {thousandSeparator(selected.salary_max)}
                        </>
                      )}
                    </Text>
                  )}
                </div>
              )}
              {selected.salary_info && (
                <div>
                  <Text inline fontWeight={500} color="black200" margin="0 6px 0 0">
                    {t("jobs.content.salary_information")}:
                  </Text>

                  {isGuestUser && !isBarona ? <>{blurImg}</> : <Text inline>{selected.salary_info}</Text>}
                </div>
              )}
              {(selected.salary_max > 0 || selected.salary_min > 0) && (
                <div>
                  <Text inline fontWeight={500} color="black200" margin="0 6px 0 0">
                    {t("jobs.content.salary_period")}:
                  </Text>

                  {isGuestUser && !isBarona ? (
                    <>{blurImg}</>
                  ) : (
                    <Text inline>
                      {selected.salary_type ? capitalize(selected.salary_type) : t("jobs.content.not_specified")}
                    </Text>
                  )}
                </div>
              )}
              <div className="view-requirements">
                <Text inline fontWeight={500} color="black200" margin="0 6px 0 0">
                  {t("jobs.content.contract_type")}:
                </Text>

                {isGuestUser && !isBarona ? (
                  <>{blurImg}</>
                ) : (
                  <Text inline>
                    {selected.job_type ? capitalize(selected.job_type) : t("jobs.content.not_specified")}
                    {selected.permanent_position && selected.permanent_position !== "permanent"
                      ? `, ${t(`jobs.content.positions.${selected.permanent_position}`)}`
                      : ""}
                  </Text>
                )}
              </div>
              {!ssr && isGuestUser ? (
                <Suggestion.Prompt
                  type="signUp"
                  fromUrl={fromUrl}
                  style={{
                    maxWidth: "420px",
                    marginBottom: "30px",
                    paddingTop: "8px",
                    paddingBottom: "8px"
                  }}
                />
              ) : null}

              <Suspense fallback={null}>
                <div className="view-summary-content">{parseDescription(selected.description)}</div>
              </Suspense>
            </div>
          ) : (
            <>
              {isGuestUser && !ssr ? (
                <Suggestion.Prompt
                  type="signUp"
                  fromUrl={fromUrl}
                  style={{
                    maxWidth: "420px",
                    marginBottom: "30px",
                    paddingTop: "8px",
                    paddingBottom: "8px"
                  }}
                />
              ) : null}
            </>
          )}

          {!!selected.responsibility_text && (
            <div className="view-summary">
              <Title scales level={5} fontWeight={600} margin="8px 0">
                {t("jobs.content.responsibilities")}{" "}
              </Title>
              <Suspense fallback={null}>
                <div className="view-summary-content">{parseDescription(selected.responsibility_text)}</div>
              </Suspense>
            </div>
          )}
          {!!selected.accessibilities && Object.keys(selected?.accessibilities).length > 0 && (
            <div className="view-summary">
              <Title scales level={5} fontWeight={600} margin="8px 0">
                {t("jobs.content.accessibility")}
              </Title>
              <div className="view-summary-content">
                <Accessibilities accesibilities={selected.accessibilities} />
              </div>
            </div>
          )}
          <WhitelabelledOrCustomHidden>
            <PositionTags
              industry={selected.industry_record}
              town={selected.town}
              category={selected.category}
              seniority_level={selected.seniority_level}
              job_type={{
                name: capitalize(selected.job_type?.replace("_", "-")),
                slug: selected.job_type_slug
              }}
              country={selected.country}
            />
          </WhitelabelledOrCustomHidden>
        </Tabs.TabPanel>
        <Tabs.TabPanel active={activeTab === "company"}>
          <Company job_id={selected.id} showSimilar={false} wideOnMobile />
        </Tabs.TabPanel>
        {!customJkuat && !ssr ? <Suggestion.Prompt ref={promptRef} fromUrl={fromUrl} /> : null}
        <Articles />
      </div>

      {Actions && (
        <Visible sm xs>
          <div className="view-footer">
            <Actions
              saveJob={saveJob}
              {...actionProps}
              isFastApply={isFastApply}
              isAssigmentRequested={isAssigmentRequested}
            />
          </div>
        </Visible>
      )}
    </>
  );
};

const DefaultActions = withGuestVersion(AuthorizedActions, GuestActions);

export default JobDetailsItem;
