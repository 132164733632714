import React, { useContext } from "react";
import { Row, Col, ScreenClassContext, Visible, Hidden } from "react-grid-system";

import QuoteIcon from "images/b2c/service/quoteicon.svg";
import Layout from "../Layout";
import { Text, Image } from "../Core";
import {
  BlockquoteSectionWrapper,
  BlockquoteContainer,
  BlockquoteImage,
  BlockquoteContent,
  QuotationBG
} from "./styled";

const BlockquoteSection = ({ text, author, role, img }) => {
  const screen = useContext(ScreenClassContext);
  const isSm = ["xs", "sm"].includes(screen);

  return (
    <BlockquoteSectionWrapper>
      <Layout.Content size="xl" tag="div">
        <BlockquoteContainer>
          <BlockquoteImage>
            <Image src={img} />
          </BlockquoteImage>
          <BlockquoteContent>
            <Hidden xs sm>
              <QuotationBG />
            </Hidden>
            <Row>
              <Col md="content">
                <Hidden xs sm>
                  <QuoteIcon />
                </Hidden>
                <Visible xs sm>
                  <Row align="center">
                    <Col xs="content">
                      <QuoteIcon width="48" height="48" style={{ display: "flex" }} />
                    </Col>
                    <Col>
                      <Text fontWeight={600}>{author}</Text>
                      <Text color="black200">{role}</Text>
                    </Col>
                  </Row>
                </Visible>
              </Col>
              <Col>
                <Text fontSize={isSm ? 14 : 20} lineHeight="140%" margin={isSm ? "10px auto 15px" : "0 auto 15px"}>
                  {text}
                </Text>
                <Hidden xs sm>
                  {author && (
                    <Text color="black200" fontSize={16}>
                      {author}, {role}
                    </Text>
                  )}
                </Hidden>
              </Col>
            </Row>
          </BlockquoteContent>
        </BlockquoteContainer>
      </Layout.Content>
    </BlockquoteSectionWrapper>
  );
};

export default BlockquoteSection;
