import React, { useState } from "react";

import { formatError } from "utils/helpers";
import { Input, Dropdown, Hint } from "../../Core";

const Details = ({ codes = [], errors }) => {
  const [selectedCode, setSelectedCode] = useState(null);

  return (
    <>
      <Input
        type="text"
        name="firstName"
        placeholder="First name"
        label="First name"
        errorMessage={formatError(errors?.first_name)}
      />
      <Input
        type="text"
        name="lastName"
        placeholder="Last name"
        label="Last name"
        errorMessage={formatError(errors?.first_name)}
      />
      <Hint>
        Phone is the fastest way for employers to contact you. We also use phone for sharing recommendations with you.
      </Hint>
      <Dropdown
        name="codes"
        id={selectedCode}
        label="Phone number"
        placeholder="Select country/region"
        errorMessage={formatError(errors?.phone?.code)}
      >
        <Dropdown.Menu disableSorting>
          {codes.map(({ name, phone_code }) => (
            <Dropdown.Item
              key={name}
              value={`${name} (${phone_code})`}
              onClick={() => {
                setSelectedCode(phone_code);
              }}
            >
              {name} ({phone_code})
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      {selectedCode && (
        <Input
          type="tel"
          name="phone"
          prependedText={selectedCode}
          margin="12px 0 0"
          errorMessage={formatError(errors?.phone?.number)}
        />
      )}
    </>
  );
};

export default Details;
