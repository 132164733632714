import React from "react";

import { useTranslation } from "b2c/hooks/useTranslation";
import FastApplyIcon from "./assets/fast-apply.svg";
import ExpirationIcon from "./assets/expiration-icon.svg";

import {
  StyledRootLink,
  StyledRootExternalLink,
  StyledContent,
  StyledIcon,
  StyledImage,
  StyledTitle,
  StyledLocation,
  StyledStatus,
  StyledJobExpiration,
  StyledMetaContainer,
  StyledFastApplyStatus,
  StyledEarlyApplicantStatus
} from "./styled";

const getExpirationText = ({ campaign_end_date }) => {
  const nowTime = Date.now();
  const timeDiff = Number(new Date(campaign_end_date)) - nowTime;

  const daysLeft = Math.floor(timeDiff / (24 * 60 * 60 * 1000));
  const hoursLeft = Math.floor(timeDiff / (60 * 60 * 1000));
  const minutesLeft = Math.floor(timeDiff / (60 * 1000));
  const { t } = useTranslation();

  if (daysLeft) {
    return `${daysLeft}${t("notifications.days_left")}`;
  }

  if (hoursLeft) {
    return `${hoursLeft}${t("notifications.hours_left")}`;
  }

  if (minutesLeft) {
    return `${minutesLeft}${t("notifications.min_left")}`;
  }

  return null;
};

const getItemStatus = ({ application_type: applicationType, early_applicant: isEarlyApplicant }) => {
  const { t } = useTranslation();

  if (applicationType === "application_type_fast_apply") {
    return (
      <>
        <FastApplyIcon /> <StyledFastApplyStatus>{t("notifications.fast_apply")}</StyledFastApplyStatus>
      </>
    );
  }

  if (isEarlyApplicant) {
    return <StyledEarlyApplicantStatus>{t("notifications.be_an_early")}</StyledEarlyApplicantStatus>;
  }

  return null;
};

const JobItem = props => {
  const { title, location, logo, path, url } = props;
  const { t } = useTranslation();

  const status = getItemStatus(props);
  const expirationText = getExpirationText(props);

  const isExpired = !expirationText;

  const hasInternalLink = Boolean(path);
  const Wrapper = hasInternalLink ? StyledRootLink : StyledRootExternalLink;
  const wrapperParams = hasInternalLink ? { to: path } : { href: url, target: "_blank" };

  return (
    <Wrapper {...wrapperParams}>
      <StyledIcon expired={isExpired}>{Boolean(logo) && <StyledImage src={logo} />}</StyledIcon>
      <StyledContent>
        <StyledTitle expired={isExpired}>{title}</StyledTitle>
        <StyledLocation expired={isExpired}>{location}</StyledLocation>
        <StyledMetaContainer>
          <StyledStatus expired={isExpired}>{status}</StyledStatus>
          <StyledJobExpiration>
            {expirationText ? (
              <>
                <ExpirationIcon width={20} style={{ marginRight: "4px" }} /> {expirationText}
              </>
            ) : (
              t("notifications.closed")
            )}
          </StyledJobExpiration>
        </StyledMetaContainer>
      </StyledContent>
    </Wrapper>
  );
};

export default JobItem;
