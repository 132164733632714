import styled from "styled-components";

export const Container = styled.div`
  background-color: #fff;
  padding: 32px 16px;

  @media screen and (min-width: 1008px) {
    padding: 64px 0;
  }
`;

export const Content = styled.div`
  max-width: 1000px;
  margin: 0 auto;
`;

export const Title = styled.h3`
  color: #292929;
  text-align: center;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  margin-bottom: 16px;

  @media screen and (min-width: 1008px) {
    font-size: 32px;
    margin-bottom: 24px;
  }
`;

export const CompaniesItem = styled.div`
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100%;
  }

  @media screen and (min-width: 1008px) {
    display: flex;
    gap: 80px;
    align-items: center;
    justify-content: center;
  }
`;

export const Companies = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: 1008px) {
    display: flex;
    gap: 80px;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
`;
