import styled from "styled-components";
import ProfileAvatar from "components/ProfileAvatar";

export const TriggerWrapper = styled.div`
  cursor: pointer;
  display: inline-flex;
  align-items: center;

  > svg {
    width: 8px;
    margin: auto 4px auto 0;
  }
`;

export const StyledAvatar = styled(ProfileAvatar)`
  ${({ borderColor }) => `
        > img,
        div {
            height: 32px;
            width: 32px;
            margin: auto 0;
            border-radius: 5px;
            border: 2px solid ${borderColor};
            font-size: 18px;
        }
    `}
`;
