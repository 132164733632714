import React, { Suspense } from "react";

import { Image } from "../Core";
import { buildImageUrlS3 } from "../../contexts/branded";
import { nameImages } from "../../constants/images";

const LogoFooter = props => {
  const image = buildImageUrlS3(nameImages.logoPrimary);

  return (
    <Suspense fallback={null}>
      <Image src={image} width="95px" height="42px" {...props} />
    </Suspense>
  );
};

export default LogoFooter;
