import React, { useState, useLayoutEffect } from "react";
import { observer } from "mobx-react";

import { useTranslation } from "b2c/hooks/useTranslation";
import Payment from "images/b2c/icons/payment.svg";
import useStore from "../../../../contexts/store";
import { Card, Title } from "../../../../components/Core";
import TransactionStatus from "../TransactionStatus";
import PaymentSuccess from "../PaymentSuccess";
import PaymentMethod from "../PaymentMethod";
import BankInstructions from "../BankInstructions";
import PaymentProblems from "../PaymentProblems";
import { TitleIconHolder } from "./styled";
import ProductInfo from "../ProductInfo";

const ProductCheckout = ({ location: { state: locationState = {} }, match: { params } = {}, history: { push } }) => {
  const {
    state: { processing } = {},
    product_details,
    mobile_options,
    bank_options,
    instructions,
    provider,
    transaction,
    errors,
    initiatePaymentFlow,
    initiatePayment,
    getMobileOptions,
    getBankOptions,
    getTransactionStatus,
    cancelTransaction,
    disableStatusCheck
  } = useStore("Payment");
  const { getFeatureStatus } = useStore("Premium");
  const { t } = useTranslation();

  const { slug, position_id } = params;
  const [instructionPage, setInstructionPage] = useState(null);
  const from = {
    pathname: locationState?.from || "/home",
    state: { purchased: transaction?.status?.completed, slug, position_id }
  };

  useLayoutEffect(() => {
    initiatePaymentFlow(slug, position_id, locationState);

    return () => {
      setInstructionPage(null);
      disableStatusCheck();
      if (!transaction?.status?.completed && !transaction?.status?.pending) cancelTransaction();
    };
  }, []);

  useLayoutEffect(() => {
    if (transaction.payment_type === "bank_transfer") setInstructionPage("bank");
    else if (!transaction.payment_type) setInstructionPage(null);
    if (transaction?.status?.completed) getFeatureStatus();
  }, [transaction]);

  const handleCancellation = () => {
    cancelTransaction().then(() => {
      push(locationState?.from || "/home");
    });
  };

  const tryAgain = () => {
    cancelTransaction().then(() => {
      setInstructionPage(null);
      initiatePaymentFlow(slug, position_id, locationState.discounted);
    });
  };

  return transaction?.status?.completed ? (
    <PaymentSuccess
      name={product_details?.name}
      slug={product_details?.slug}
      message={transaction?.status?.success_message}
      from={from}
      position_id={position_id}
    />
  ) : (
    <>
      {instructionPage === "mobile" && (
        <PaymentProblems
          gateway={transaction?.payment_gateway}
          provider={provider}
          from={from}
          processing={processing}
          tryAgain={tryAgain}
        />
      )}
      {(!instructionPage || instructionPage === "bank") && (
        <Card shadow>
          <Card.Header>
            <TitleIconHolder>
              <Payment />
            </TitleIconHolder>

            <Title
              level={4}
              margin="0 0 0 15px"
              fontWeight={500}
              style={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center"
              }}
            >
              {t("premium_cv.secure_checkout")}
            </Title>
          </Card.Header>

          <Card.Content padding="16px">
            <ProductInfo
              {...product_details}
              description={transaction?.status?.success_message}
              amount={transaction.amount}
              points={transaction.fuzu_points_amount}
              currency={transaction.currency}
              discounted={locationState.discounted}
            />

            {instructionPage === "bank" ? (
              <BankInstructions
                processing={processing}
                provider={provider}
                from={from}
                cancelTransaction={handleCancellation}
              />
            ) : transaction?.status?.pending ? (
              <TransactionStatus
                from={from}
                processing={processing}
                getTransactionStatus={getTransactionStatus}
                provider={provider}
                onProblems={() => setInstructionPage("mobile")}
                cancelTransaction={handleCancellation}
                {...transaction.status}
              />
            ) : (
              <PaymentMethod
                loading={!transaction.id}
                processing={processing}
                errors={errors}
                instructions={instructions}
                mobile_options={mobile_options}
                bank_options={bank_options}
                getMobileOptions={getMobileOptions}
                getBankOptions={getBankOptions}
                initiatePayment={initiatePayment}
                cancelTransaction={handleCancellation}
              />
            )}
          </Card.Content>
        </Card>
      )}
    </>
  );
};

export default observer(ProductCheckout);
