import React, { useState, useEffect, useContext } from "react";
import { Visibility, VisibilityOff, KeyboardArrowDown, KeyboardArrowUp } from "@styled-icons/material";
import { useTranslation } from "b2c/hooks/useTranslation";

import { observer } from "mobx-react";
import {
  Content,
  Title,
  Description,
  Flex,
  VisibilityStatus,
  ToggleVisibility,
  Info,
  InfoHeading,
  InfoTitle,
  InfoArrow,
  InfoContent
} from "./styled";
import useStore from "../../../../contexts/store";
import { isWhitelabelledOrJkuat } from "../../../../contexts/branded";
import { useModal } from "../../../../../utils/hooks";
import HideProfileModal from "../HideProfileModal";
import ThemeContext from "../../../../contexts/theme";

const ProfileVisibility = ({ isWizard, FormActions, resolveAction }) => {
  const [activeDropdown, setActiveDropdown] = useState(false);
  const {
    Profile: { getPersonalInfo, profile: { personal_info: { talent_pool_visibility: isVisibilityProfile } = {} } = {} },
    Settings: { toggleVisibility }
  } = useStore("User");
  const Theme = useContext(ThemeContext);
  const { t } = useTranslation();

  const whitelabelledOrJkuat = isWhitelabelledOrJkuat();
  const toggleDropDown = () => {
    setActiveDropdown(prev => !prev);
  };

  const openLoginModal = useModal(
    <HideProfileModal onClick={toggleVisibility} />,
    t("wizard.profile_visibility.modal.title")
  );

  const handleProgress = e => {
    e.preventDefault();
    resolveAction();
  };

  useEffect(() => {
    if (isWizard && isVisibilityProfile === undefined) {
      getPersonalInfo();
    }
  }, []);

  return (
    <>
      {!whitelabelledOrJkuat && (
        <form onSubmit={handleProgress}>
          <Content isWizard={isWizard}>
            {!isWizard && <Title>{t("wizard.profile_visibility.sub-title")}</Title>}

            <Description>{t("wizard.profile_visibility.description_detail")}</Description>

            <Flex>
              <VisibilityStatus isVisibilityProfile={isVisibilityProfile}>
                {isVisibilityProfile ? (
                  <>
                    <Visibility />
                    {t("wizard.profile_visibility.visible")}
                  </>
                ) : (
                  <>
                    <VisibilityOff />
                    {t("wizard.profile_visibility.hidden")}
                  </>
                )}
              </VisibilityStatus>

              {isVisibilityProfile ? (
                <ToggleVisibility type="button" onClick={openLoginModal}>
                  {t("wizard.profile_visibility.hide_profile")}
                </ToggleVisibility>
              ) : (
                <ToggleVisibility
                  type="button"
                  onClick={toggleVisibility}
                  background={Theme.primaryButtonBackground}
                  color={Theme.primaryButtonLabelColor}
                >
                  {t("wizard.profile_visibility.make_visible")}
                </ToggleVisibility>
              )}
            </Flex>

            <Info>
              <InfoHeading onClick={toggleDropDown} tabIndex="-1" role="button">
                <InfoTitle>{t("wizard.profile_visibility.what_is_visible.title")}</InfoTitle>
                <InfoArrow>{activeDropdown ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</InfoArrow>
              </InfoHeading>
              {activeDropdown && (
                <InfoContent>
                  <li>{t("wizard.profile_visibility.what_is_visible.label_1")}</li>
                  <li>{t("wizard.profile_visibility.what_is_visible.label_2")}</li>
                  <li>{t("wizard.profile_visibility.what_is_visible.label_3")}</li>
                  <li>{t("wizard.profile_visibility.what_is_visible.label_4")}</li>
                  <li>{t("wizard.profile_visibility.what_is_visible.label_5")}</li>
                </InfoContent>
              )}
            </Info>
          </Content>
          {isWizard ? <FormActions /> : null}
        </form>
      )}
    </>
  );
};

export default observer(ProfileVisibility);
