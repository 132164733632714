import React, { useState, useMemo, useContext } from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { ScreenClassContext, useScreenClass } from "react-grid-system";
import { GridAlt, RightArrowAlt } from "@styled-icons/boxicons-regular";

import { usePrev } from "utils/hooks";
import CourseIco from "images/b2c/emptyStates/empty_saved_courses.svg";
import { useTranslation } from "b2c/hooks/useTranslation";
import Item from "./CoursesItem";
import CarouselSection from "../../Section/CarouselSection";
import useStore from "../../../contexts/store";
import { Title, Text, Button, Card, Icon } from "../../Core";
import { useLanguageAndCountryUrl } from "../../../hooks";

const CoursesSection = ({
  title,
  filter,
  course_type = "all",
  count_limit = 4,
  total_limit = 8,
  expandable = false,
  slug,
  noHeader = false,
  narrowCarousel,
  titleProps,
  pathList,
  ...rest
}) => {
  const isXs = useScreenClass() === "xs";
  const { courses, state, getCourses, getSimilarCourses } = useStore("Courses");
  const { handleSaving } = useStore("Saved");
  const loading = state[course_type][filter] ? state[course_type][filter]?.loading : true;
  const expanded = useState(false);
  const prevLoading = usePrev(loading);
  const loaded = useMemo(() => prevLoading, [loading, expanded]);
  const item_limit = expanded || (narrowCarousel && isXs) ? total_limit || count_limit : count_limit;
  const courseList = courses[course_type][filter]
    ? courses[course_type][filter].slice(0, item_limit)
    : [...new Array(item_limit).keys()];
  const screen = useContext(ScreenClassContext);
  const isSm = /sm|xs/.test(screen);
  const { t } = useTranslation();
  const languageAndLanguage = useLanguageAndCountryUrl();

  const onSectionMount = () => {
    if (courses.length) {
      return;
    }

    if (slug) {
      getSimilarCourses(slug);
    } else {
      getCourses(filter, course_type, expandable ? total_limit : count_limit);
    }
  };

  return (
    <CarouselSection
      expandable={expandable}
      expanded={expanded}
      onSectionMount={onSectionMount}
      columns={4}
      narrowCarousel={narrowCarousel && isXs}
      slidePadding="20px 15px 0"
      isFullHeight
      slideMobileWidth="230px"
      style={{
        marginTop: isSm ? 0 : "25px"
      }}
      ShowMoreButton={
        <Card style={{ height: "100%" }} as={Link} to="/learn/search">
          <Card.Content
            style={{
              justifyContent: "center",
              alignItems: "center",
              border: "1px solid #E1E1E1",
              boxShadow: "none",
              height: isSm ? "100%" : "290px",
              marginRight: "10px",
              borderRadius: "5px"
            }}
          >
            <Icon width="90px" as={CourseIco} additionalCss="height: 92px" />
            <Text width="100%" textAlign="center" margin="10px 0" fontWeight={600}>
              {t("learn.show_more")}
              <RightArrowAlt width={20} />
            </Text>
          </Card.Content>
        </Card>
      }
      Header={
        !noHeader && (
          <Title scales level={3} fontWeight={600} {...titleProps} className="title_sentence">
            {t(title)}
          </Title>
        )
      }
      ShowAll={
        <Button.Link
          to={pathList ? `${languageAndLanguage}/learn${pathList}` : `${languageAndLanguage}/learn`}
          theme="transparent"
          style={{ color: "#296ACC", fontSize: "14px", fontWeight: 600 }}
        >
          <GridAlt width={17} style={{ stroke: "#296ACC", marginRight: "10px" }} />
          {t("global.see_all")}
        </Button.Link>
      }
      {...rest}
    >
      {courseList.map((item, index) => (
        <Item
          key={`course_${filter}_${index}`}
          {...item}
          saveItem={saved => handleSaving(item.id, "course", saved)}
          loading={loading}
          loaded={loaded}
          photo={{ url: item.normal_image }}
        />
      ))}
    </CarouselSection>
  );
};

export default observer(CoursesSection);
