import React, { useEffect, useState, useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import { Close } from "@styled-icons/material/Close";
import { Col, Row } from "react-grid-system";

import { useTranslation } from "b2c/hooks/useTranslation";
import {
  Content,
  Title,
  Description,
  Divider,
  Input,
  TextArea,
  Label,
  Tile,
  CloseButton,
  Heading,
  NavWrapper,
  Error
} from "./styled";
import { Button, Checkbox, RadioButton } from "../../../../components/Core";
import Layout from "../../../../components/Layout";
import { questionsType } from "../../../../../constants/questions";

const Questions = ({
  getApplicationFlowQuestions,
  questions,
  id,
  processing,
  postApplicationFlowQuestions,
  cleanApplicationFlowQuestions,
  questionsErrors,
  getApplicationFlowAnswers,
  questionAnswers
}) => {
  const [questionsValue, setQuestionsValue] = useState({});
  const history = useHistory();
  const { t } = useTranslation();

  const handleChangeMultiOptions = (questionId, item) => {
    const selectedOptions = questionsValue[questionId].includes(item)
      ? questionsValue[questionId].filter(currentItem => currentItem !== item)
      : [...questionsValue[questionId], item];

    setQuestionsValue(prev => ({ ...prev, [questionId]: selectedOptions }));
  };

  const handleChangeOptionOptions = (questionId, item) => {
    setQuestionsValue(prev => ({ ...prev, [questionId]: item }));
  };

  const handleChangeShortText = (questionId, event) => {
    setQuestionsValue(prev => ({ ...prev, [questionId]: event.target.value }));
  };

  const handleChangeText = (questionId, event) => {
    setQuestionsValue(prev => ({ ...prev, [questionId]: event.target.value }));
  };

  const isDisabledButton = useMemo(() => {
    return !Object.values(questionsValue).every(item => (Array.isArray(item) ? item.length : Boolean(item)));
  }, [questions.length, Object.values(questionsValue)]);

  const handleSubmit = () => {
    const selectedQuestions = questions.map(item => {
      if (item.question_type === questionsType.optionChoice) {
        return {
          question_id: item.id,
          options: [questionsValue[item.id]]
        };
      }

      if (item.question_type === questionsType.multiChoice) {
        return {
          question_id: item.id,
          options: questionsValue[item.id]
        };
      }

      if (item.question_type === questionsType.shortText) {
        return {
          question_id: item.id,
          text: questionsValue[item.id]
        };
      }

      return {
        question_id: item.id,
        text: questionsValue[item.id]
      };
    });

    postApplicationFlowQuestions(id, { answers: selectedQuestions }).then(result => {
      if (!result.errors) {
        history.push(`/jobs/${id}/application`);
      }
    });
  };

  useEffect(() => {
    getApplicationFlowAnswers(id);
    getApplicationFlowQuestions(id);

    return () => {
      cleanApplicationFlowQuestions();
    };
  }, []);

  useEffect(() => {
    if (!Object.keys(questionsValue).length && questions.length) {
      const normalizeValues = questions.reduce((acc, current) => {
        if (current.question_type === questionsType.multiChoice) {
          acc[String(current.id)] = questionAnswers[current.id]
            ? questionAnswers[current.id].options.filter(option => option.selected).map(option => option.id)
            : [];
        }

        if (current.question_type === questionsType.optionChoice) {
          acc[current.id] = questionAnswers[current.id]
            ? questionAnswers[current.id].options.filter(option => option.selected).map(option => option.id)[0]
            : "";
        }

        if (current.question_type === questionsType.shortText) {
          acc[current.id] = questionAnswers[current.id] ? questionAnswers[current.id].response : "";
        }

        if (current.question_type === questionsType.text) {
          acc[current.id] = questionAnswers[current.id] ? questionAnswers[current.id].response : "";
        }

        return acc;
      }, {});

      setQuestionsValue(normalizeValues);
    }
  }, [questions.length]);

  const renderQuestions =
    Object.keys(questionsValue).length && questions.length
      ? questions.map(item => {
          if (item.question_type === questionsType.multiChoice) {
            return (
              <React.Fragment key={`multi-choice-${item.text}`}>
                <Title>{item.text}</Title>
                {item.description && <Description>{item.description}</Description>}

                <div>
                  {item.options.map(option => {
                    return (
                      <Tile key={`multi-choice-item-${option.text}`}>
                        <Checkbox
                          name={option.text}
                          id={option.text}
                          label={<Label>{option.text}</Label>}
                          checked={questionsValue[item.id].includes(option.id)}
                          onChange={() => handleChangeMultiOptions(item.id, option.id)}
                        />
                      </Tile>
                    );
                  })}
                </div>
                {questionsErrors[item.id] && <Error>{questionsErrors[item.id][0]}</Error>}
                <Divider />
              </React.Fragment>
            );
          }

          if (item.question_type === questionsType.optionChoice) {
            return (
              <React.Fragment key={`option-choice-${item.text}`}>
                <Title>{item.text}</Title>
                {item.description && <Description>{item.description}</Description>}

                <div>
                  {item.options.map(option => {
                    return (
                      <Tile key={`option-choice-item-${option.text}`}>
                        <RadioButton
                          key={option.id}
                          id={option.id}
                          Label={<Label>{option.text}</Label>}
                          name={option.text}
                          value={item.value}
                          checked={questionsValue[item.id] === option.id}
                          onChange={() => handleChangeOptionOptions(item.id, option.id)}
                        />
                      </Tile>
                    );
                  })}
                </div>
                {questionsErrors[item.id] && <Error>{questionsErrors[item.id][0]}</Error>}
                <Divider />
              </React.Fragment>
            );
          }

          if (item.question_type === questionsType.shortText) {
            return (
              <React.Fragment key={`short-text-${item.text}`}>
                <Title>{item.text}</Title>
                {item.description && <Description>{item.description}</Description>}

                <Input
                  onChange={event => handleChangeShortText(item.id, event)}
                  value={questionsValue[item.id]}
                  type="text"
                  placeholder={t("application.questions.input_placeholder")}
                />
                {questionsErrors[item.id] && <Error>{questionsErrors[item.id][0]}</Error>}
                <Divider />
              </React.Fragment>
            );
          }

          return (
            <React.Fragment key={`text-${item.text}`}>
              <Title>{item.text}</Title>
              {item.description && <Description>{item.description}</Description>}

              <TextArea
                onChange={event => handleChangeText(item.id, event)}
                value={questionsValue[item.id]}
                type="text"
                placeholder={t("application.questions.input_placeholder")}
              >
                {questionsValue[item.id]}
              </TextArea>
              {questionsErrors[item.id] && <Error>{questionsErrors[item.id][0]}</Error>}
              <Divider />
            </React.Fragment>
          );
        })
      : null;

  return (
    <>
      <CloseButton>
        <Link to={`/jobs/${id}/application`}>
          <Button.Iconed tabIndex="-1" theme="black300">
            <Close width={24} />
          </Button.Iconed>
        </Link>
      </CloseButton>
      <Heading>{t("application.questions.title")}</Heading>
      <Content>{renderQuestions}</Content>

      <NavWrapper>
        <Layout.Content size="xl" tag="div">
          <Row align="center">
            <Col>
              {/* {previous_question_response_id && ( */}
              <Button.Link to={`/jobs/${id}/application`} width="150px" theme="grey200">
                {t("global.back")}
              </Button.Link>
              {/* )} */}
            </Col>
            <Col xs="content">
              <Button
                width="150px"
                color="primaryButtonLabelColor"
                processing={processing}
                disabled={isDisabledButton}
                onClick={handleSubmit}
              >
                {t("global.save_button")}
              </Button>
            </Col>
          </Row>
        </Layout.Content>
      </NavWrapper>
    </>
  );
};

export default Questions;
