import styled from "styled-components";

export const JobInfoComponent = styled.div`
  background-color: #f3f3f3;
  border: 1px solid #dedede;
  border-radius: 5px;
  padding: 20px;
  margin: 16px 0;
`;

export const JobInfoHead = styled.div`
  @media screen and (min-width: 768px) {
    display: flex;
  }
`;

export const JobInfoHeadItem = styled.div`
  border-bottom: 1px solid #c4c4c4;
  margin-bottom: 16px;
  @media screen and (min-width: 768px) {
    width: 33.33%;
    flex: none;
    border-right: 1px solid #c4c4c4;
    border-bottom: none;
    margin-bottom: 0;
    padding-left: 16px;
    &:first-child {
      padding-left: 0;
    }
  }

  &:last-child {
    border-right: none;
    border-bottom: none;
  }
`;
