import React, { useState, useEffect, useMemo, useContext } from "react";
import { observer } from "mobx-react";
import { RightArrowAlt } from "@styled-icons/boxicons-regular";
import { Row, Col, ScreenClassContext } from "react-grid-system";

import { usePrev } from "utils/hooks";
import { useTranslation } from "b2c/hooks/useTranslation";
import { Button, Title, LazyLoader } from "../../../../components/Core";
import Post from "../../../../components/Post";
import useStore from "../../../../contexts/store";
import { useGetCurrentCountry, useIsBarona, useLanguageAndCountryUrl } from "../../../../hooks";
import { PostsWrapper } from "./styled";
import Mounted from "../Mounted";
import ServerSide from "../../../../contexts/ssr";

const PopularPosts = observer(({ loading: parentLoading, title, count_limit = 4, ...rest }) => {
  const { ssr } = useContext(ServerSide);
  const { state, articles, getArticles, h1Title } = useStore("Articles");
  const [page, setPage] = useState(1);
  const screen = useContext(ScreenClassContext);
  const isLg = ["lg", "xl", "xxl"].includes(screen);
  const loading = parentLoading || (state.hero ? state.hero.loading : false);
  const prevLoading = usePrev(loading);
  const loaded = useMemo(() => prevLoading, [loading]);
  const articleList = articles.hero ? articles.hero.slice(0, count_limit) : [...new Array(count_limit).keys()];
  const country = useGetCurrentCountry();
  const countryId = country?.id || "";
  const isBarona = useIsBarona();
  const { t } = useTranslation();
  const languageAndLanguage = useLanguageAndCountryUrl();

  useEffect(() => {
    if (articles.hero && articles.hero.length === 0 && page > 1) {
      setPage(1);
      getArticles("hero", count_limit, 1, undefined, countryId);
    }
  }, [articles.hero, articles.hero?.length]);

  const onSectionMount = () => {
    if (!articles.hero?.length) {
      getArticles("hero", count_limit, 1, undefined, countryId);
    }
  };

  const Content = ({ loading = false, ...rest }) => (
    <PostsWrapper>
      <Row {...rest}>
        {isLg ? (
          <>
            <Col lg={6}>
              {articleList.slice(0, 1).map((item, index) => (
                <Post
                  key={`article_${index}`}
                  type=""
                  verticalXL
                  loading={loading}
                  loaded={loaded}
                  {...item}
                  iArticleSectionList
                />
              ))}
            </Col>
            <Col
              lg={6}
              style={{
                display: "flex",
                flexDirection: "column"
              }}
            >
              {articleList.slice(1).map((item, index) => (
                <Post
                  key={`article_${index}`}
                  type=""
                  horizontal
                  loading={loading}
                  loaded={loaded}
                  {...item}
                  marginFooter="auto 0 0 0"
                  paddingContent="16px 8px"
                />
              ))}
            </Col>
          </>
        ) : (
          articleList.map((item, index) => (
            <Col key={`article_${index}`} xs={12} sm={6} md={6} style={{ marginBottom: "20px" }}>
              <Post type="" loading={loading} loaded={loaded} {...item} iArticleSectionList />
            </Col>
          ))
        )}

        <Col sm={12} style={{ textAlign: "center", marginTop: "20px" }}>
          <Button.Link
            to={`${languageAndLanguage}/forum/${isBarona ? "popular" : "hero"}`}
            theme="primary"
            fontSize="14px"
            color="primaryButtonLabelColor"
          >
            {t("forum.show_more_button")}
            <RightArrowAlt width={20} />
          </Button.Link>
        </Col>
      </Row>
    </PostsWrapper>
  );

  return (
    articleList.length > 0 && (
      <>
        <Title level={2} scales margin="0 0 25px 0" fontWeight={600}>
          {ssr ? <>{h1Title}</> : <>{title}</>}
        </Title>
        <LazyLoader offset={150} once placeholder={<Content loading />}>
          <Mounted onSectionMount={onSectionMount}>
            <Content loading={loading} {...rest} />
          </Mounted>
        </LazyLoader>
      </>
    )
  );
});

export default PopularPosts;
