import styled from "styled-components";

export const ItemList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  > div {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
`;

export const EmptyContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 60px 0;

  > img {
    margin: 0 auto;
  }
`;
