import React from "react";
import parse from "html-react-parser";
import { RightArrowAlt } from "@styled-icons/boxicons-regular";

import peoplesImage from "images/b2c/workforce-peoples.png";
import BallImage from "images/b2c/workforce-ball.svg";

import { Wrapper, Content, Title, LinkTo, PeoplesContent, BallContent } from "./styled";
import { useTranslation } from "../../hooks/useTranslation";

const GlobalWorkforceBanner = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Content>
        <div>
          <Title>{parse(t("employers.workforce_title"))}</Title>
          <LinkTo href="https://global.fuzu.com/">
            {t("employers.workforce_button")} <RightArrowAlt />
          </LinkTo>
        </div>
        <PeoplesContent>
          <img src={peoplesImage} alt="peoples" loading="lazy" />
        </PeoplesContent>
        <BallContent>
          <BallImage />
        </BallContent>
      </Content>
    </Wrapper>
  );
};

export default GlobalWorkforceBanner;
