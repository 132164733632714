import React from "react";
import format from "date-fns/format";
import SimpleReactCalendar from "simple-react-calendar";
import { LeftArrowAlt, RightArrowAlt, Globe } from "@styled-icons/boxicons-regular";

import { useTranslation } from "b2c/hooks/useTranslation";
import { Text, Dropdown } from "../Core";
import { StyledCalendarWrapper } from "./styled";
import { hours, hoursMapper } from "../../constants/contactUs";

const CalendarStep = ({
  selectedHour,
  setSelectedHour,
  selectedDate,
  currentCountry,
  setSelectaedDate,
  appointments
}) => {
  const { t } = useTranslation();

  const today = new Date();
  const selectedTimes = appointments
    .filter(({ due_date }) => due_date === selectedDate)
    .map(({ due_time }) => `${due_time} ${format(new Date(`Jan 1, 2000 ${due_time}`), "aa")}`);

  const country = currentCountry.name === "Africa" ? "Kenya" : currentCountry.name;

  hours.forEach(hour => {
    const d = new Date(`${format(new Date(), "EEEE, MMMM d")} ${hour}`);
    d.setFullYear(new Date().getFullYear());
    const current = new Date().getTime();
    if (current > d.getTime() && new Date(selectedDate).getDay() === new Date().getDay()) {
      selectedTimes.push(hour);
    }
  });

  const onDateSelectHandler = date => {
    const selected = new Date(date);
    const month = String(selected.getMonth() + 1);
    const day = String(selected.getDate());
    const dueDate = `${selected.getFullYear()}-${month.length === 1 ? `0${month}` : month}-${
      day.length === 1 ? `0${day}` : day
    }`;
    setSelectaedDate(dueDate);
  };

  return (
    <>
      <Text fontWeight={400} fontSize={14} color="black300" margin="24px 0 24px 0">
        {t("employers.contact_us_modal.calendar_description")}
      </Text>
      <Text
        fontWeight={600}
        fontSize={16}
        color="black300"
        margin="0 0 24px 0"
        style={{ borderTop: "1px solid #80808063", paddingTop: "12px", width: "100%" }}
      >
        {t("employers.contact_us_modal.select_date")}
      </Text>
      <div>
        <StyledCalendarWrapper>
          <SimpleReactCalendar
            headerPrevArrow={<LeftArrowAlt width={20} style={{ display: "inline-block" }} />}
            headerNextArrow={<RightArrowAlt width={20} style={{ display: "inline-block" }} />}
            daysOfWeek={["M", "T", "W", "T", "F", "S", "S"]}
            activeMonth={today}
            minDate={today}
            selected={new Date(selectedDate)}
            blockClassName="date_picker"
            onSelect={onDateSelectHandler}
          />
        </StyledCalendarWrapper>
        <div
          style={{
            display: "inline-block",
            width: "150px",
            height: "294px",
            verticalAlign: "top",
            marginTop: "4px"
          }}
        >
          <Text fontWeight={500} fontSize={12} color="black300" margin="0 0 24px 0">
            {selectedDate && format(new Date(selectedDate), "EEEE, MMMM d")}
          </Text>
          <div
            style={{
              height: "270px",
              overflow: "auto"
            }}
          >
            {selectedDate &&
              hours.map(hour => {
                return (
                  <div
                    role="button"
                    tabIndex={-1}
                    key={hour}
                    onClick={() => !selectedTimes.includes(hour) && setSelectedHour(hour)}
                    style={{
                      outline: "none",
                      padding: "10px 7px",
                      background: selectedTimes.includes(hour)
                        ? "#f3f3f3"
                        : selectedHour !== hour
                          ? "#dff1ff"
                          : "#4690ff",
                      margin: "3px",
                      borderRadius: "5px",
                      color: selectedTimes.includes(hour) ? "#808080" : selectedHour !== hour ? "#0e50b5" : "white",
                      cursor: selectedTimes.includes(hour) ? "initial" : "pointer"
                    }}
                  >
                    {hoursMapper[hour]}
                  </div>
                );
              })}
          </div>
        </div>
        <Dropdown
          controlled
          id="time"
          name="time"
          trigger={<Trigger country={country} />}
          closeOnSelect
          wrapperStyle={{ margin: 0 }}
        >
          {() => (
            <Dropdown.Menu>
              <Dropdown.Item fontWeight={500}>
                {country} {t("employers.contact_us_modal.time")}
              </Dropdown.Item>
            </Dropdown.Menu>
          )}
        </Dropdown>
      </div>
    </>
  );
};

const Trigger = ({ country }) => {
  const { t } = useTranslation();

  return (
    <div style={{ cursor: "pointer", fontSize: "12px", color: "grey" }}>
      <span>
        <Globe width={20} />
      </span>
      <span style={{ display: "inline-block", margin: "2px 0 0 5px" }}>
        {country} {t("employers.contact_us_modal.time")}
      </span>
    </div>
  );
};

export default CalendarStep;
