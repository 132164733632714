import React, { useContext } from "react";

import { useTranslation } from "b2c/hooks/useTranslation";
import ThemeContext from "../../../../contexts/theme";
import { Container, Content, LoginContent, Description, Login, Actions, Back, Next } from "./styled";

const Navigation = ({ isDisabled, handleSubmit, backUrl, isFinish }) => {
  const theme = useContext(ThemeContext);
  const { t } = useTranslation();

  return (
    <Container>
      <Content>
        <LoginContent>
          <Description>{t("onboarding.already_have_account")}</Description>
          <Login to="/login">{t("onboarding.log_in")}</Login>
        </LoginContent>
        <Actions>
          {backUrl && <Back to={backUrl}>{t("onboarding.back")}</Back>}

          <Next
            data-cy="submit-step"
            $background={theme.primaryButtonBackground}
            $color={theme.primaryButtonLabelColor}
            $disabled={isDisabled}
            onClick={handleSubmit}
          >
            {isFinish ? t("onboarding.finish") : t("onboarding.next")}
          </Next>
        </Actions>
      </Content>
    </Container>
  );
};

export default Navigation;
