import styled from "styled-components";

export const Container = styled.div`
  background-color: #fff;
  padding: 32px 16px;

  @media screen and (min-width: 1008px) {
    padding: 64px 16px;
  }

  @media screen and (min-width: 1440px) {
    padding: 64px 0;
  }
`;

export const Content = styled.div`
  max-width: 1000px;
  width: 100%;

  @media screen and (min-width: 768px) {
    margin: 0 auto;
  }
`;

export const Title = styled.h3`
  color: #212121
  font-family: Poppins;
  font-size: 28px;
  font-weight: 600;
  line-height: 120%;
  align-self: stretch;
  margin: 0 0 16px 0;
  
  @media screen and (min-width: 1008px) {
    font-size: 32px;
    line-height: 140%;
  }
`;

export const Text = styled.p`
  color: #212121
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  align-self: stretch;
  margin: 0 0 16px 0;
  
  &:last-child {
    margin: 0;
  }
`;
