import styled from "styled-components";
import QuoteIcon from "images/b2c/landing-page/quote_icon_branded.svg";

export const TestimonialWrapper = styled.section`
  padding: 60px 0;
  background-image: linear-gradient(0deg, rgba(246, 252, 255, 0.0001) 0%, #f7f7f7 100%);
  min-height: 654px;
  @media screen and (max-width: 768px) {
    min-height: 590px;
    padding: 30px 0;
    text-align: center;
  }
`;

export const TestimonialQuoteIcon = styled(QuoteIcon)`
  display: flex;
  width: 70px;
  height: auto;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const AuthorAvatar = styled.img`
  display: block;
  width: 320px;
  height: 320px;
  border-radius: 50%;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;
