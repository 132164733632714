import React, { useEffect } from "react";
import { observer } from "mobx-react";

import { useTranslation } from "b2c/hooks/useTranslation";
import { withTrackedRoute } from "../../hocs";
import useStore from "../../contexts/store";
import Layout from "../../components/Layout";
import { withAuthorizationRedirect } from "../../components/WithAuth";
import { Title, Info } from "./styled";
import UpdatePasswordSection from "./components/UpdatePasswordSection";
import UpdateEmailSection from "./components/UpdateEmailSection";
import DeleteAccountSection from "./components/DeleteAccountSection";
import LanguageSection from "./components/LanguageSection";

const SettingsScreen = () => {
  const {
    user,
    Profile: { initializeProfile },
    Settings: {
      processing: { email, password, deleting, language },
      updateEmail,
      updateLanguage,
      updatePassword,
      deleteAccount,
      validation_errors
    }
  } = useStore("User");
  const { global_brand: globalBrand } = useStore("initialState");
  const { t } = useTranslation();

  const isShowLanguageSelector = globalBrand.enforce_country_based_paths && globalBrand.enforce_locale_based_paths;

  useEffect(() => {
    initializeProfile();
  }, []);

  return (
    <Layout.Content size="xl" id="main" style={{ paddingTop: 32, paddingBottom: 32 }}>
      <Title> {t("settings.title")}</Title>
      {globalBrand.custom_authentication_b2c_url && (
        <Info>
          <h3>{t("settings.info.title")}</h3>
          <p>{t("settings.info.description")}</p>
        </Info>
      )}

      <UpdateEmailSection
        processing={email}
        updateEmail={updateEmail}
        userEmail={user?.email}
        validation_errors={validation_errors}
        customAuthenticationUrl={globalBrand.custom_authentication_b2c_url}
      />

      <UpdatePasswordSection
        passwordChangedAt={user.password_changed_at}
        isPasswordJustChanged={user.isPasswordJustChanged}
        processing={password}
        updatePassword={updatePassword}
        validation_errors={validation_errors}
        customAuthenticationUrl={globalBrand.custom_authentication_b2c_url}
      />

      {isShowLanguageSelector && (
        <LanguageSection processing={language} updateLanguage={updateLanguage} language={user.ui_locale} />
      )}

      <DeleteAccountSection processing={deleting} deleteAccount={deleteAccount} />
    </Layout.Content>
  );
};

export default withTrackedRoute(withAuthorizationRedirect(observer(SettingsScreen)));
