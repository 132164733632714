import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "b2c/hooks/useTranslation";
import parse from "html-react-parser";

import { buildImageUrlS3, getCompanyName } from "b2c/contexts/branded";
import LanguageSkills from "b2c/components/LanguageSkills";
import { Card, Image, Text } from "../../../../components/Core";
import ProfileItem from "../ProfileItem";
import useStore from "../../../../contexts/store";
import { EmptyContent, ItemList } from "./styled";
import { nameImages } from "../../../../constants/images";

const LanguageStep = ({ list = [], stepState: { id, editing }, setStepState, FormActions, resolveAction }) => {
  const {
    Profile: { removeLanguage }
  } = useStore("User");

  const handleProgress = e => {
    e.preventDefault();
    resolveAction();
  };

  const { t } = useTranslation();

  const handleEdit = listingId => {
    setStepState({ editing: true, id: listingId });
  };

  return !editing ? (
    <form onSubmit={handleProgress}>
      {list.length > 0 ? (
        <ItemList>
          {list.slice().map(({ id: ids, name, spoken, written }) => (
            <ProfileItem
              key={ids}
              {...{
                id: ids,
                title: name,
                info: `${t("wizard.languages.spoken")}: ${spoken}. ${t("wizard.languages.written")}: ${written}`,
                onEdit: () => handleEdit(ids),
                onDelete: () => removeLanguage(ids)
              }}
            />
          ))}
        </ItemList>
      ) : (
        <Card wideOnMobile>
          <EmptyContent>
            <Image src={buildImageUrlS3(nameImages.languageEmpty)} style={{ margin: "auto", display: "block" }} />

            <Text fontSize={16} textAlign="center" margin="24px auto" fontWeight={500}>
              {parse(t("wizard.languages.languages_empty", { name: getCompanyName() }))}
            </Text>
          </EmptyContent>
        </Card>
      )}

      <FormActions disableSubmit={!(list.length > 0)} />
    </form>
  ) : (
    <LanguageSkills hiddenTitle itemId={id} FormActions={FormActions} resolveAction={resolveAction} />
  );
};

export default observer(LanguageStep);
