import styled from "styled-components";

export const IconQuestion = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  rect {
    fill: #2496f5;
  }

  &.icon-chat {
    path {
      fill: #2496f5;
    }
  }
`;
