import React, { useContext } from "react";
import { ScreenClassContext } from "react-grid-system";
import { Button } from "../../../../components/Core";
import ModalConsumer from "../../../../../contexts/modal";
import GenerateCvModalContent from "../GenerateCvModalContent";

const StepActions = ({
  id,
  type = "create_cv_internal",
  stepStatus = "disabled",
  cta = "",
  setStep,
  substeps = []
}) => {
  const disabled = stepStatus === "disabled";
  const buttonTheme = stepStatus === "completed" ? "grey200" : "primary";

  const getColorText = () => {
    if (stepStatus === "completed" || stepStatus === "relevant") {
      return "primaryButtonLabelColor";
    }

    return "inherit";
  };

  const screen = useContext(ScreenClassContext);
  const isSm = ["xs", "sm"].includes(screen);
  let pendingTest;
  let testId;
  return (() => {
    switch (type) {
      default:
      case "create_cv_internal":
        return (
          <Button.Link
            to={
              disabled
                ? {}
                : {
                    pathname: "/profile",
                    state: {
                      from: `/jobs/${id}/application`,
                      noiseless: true
                    }
                  }
            }
            width={isSm ? "100%" : 160}
            disabled={disabled}
            theme={buttonTheme}
            color={getColorText()}
          >
            {cta}
          </Button.Link>
        );
      case "prescreening":
        return (
          <Button.Link
            to={
              disabled
                ? {}
                : {
                    pathname: `/jobs/${id}/application/questions`,
                    state: {
                      from: `/jobs/${id}/application`
                    }
                  }
            }
            width={isSm ? "100%" : 160}
            disabled={disabled}
            theme={buttonTheme}
            color={getColorText()}
          >
            {cta}
          </Button.Link>
        );
      case "tests":
        pendingTest = substeps.find(test => !test.completed);
        testId = pendingTest?.fuzu_test_id || substeps[0]?.fuzu_test_id;

        return (
          <Button.Link
            to={
              disabled
                ? {}
                : {
                    pathname: `/jobs/${id}/application/tests/${testId}`,
                    state: {
                      from: `/jobs/${id}/application`
                    }
                  }
            }
            width={isSm ? "100%" : 160}
            disabled={disabled}
            theme={buttonTheme}
            color={getColorText()}
          >
            {cta}
          </Button.Link>
        );
      case "attachments":
        return (
          <Button.Link
            to="application/attachments"
            disabled={disabled}
            width={isSm ? "100%" : 160}
            onClick={setStep}
            theme={buttonTheme}
            color={getColorText()}
          >
            {cta}
          </Button.Link>
        );
      case "cover_letter":
        return (
          <Button.Link
            to="application/cover_letter"
            disabled={disabled}
            width={isSm ? "100%" : 160}
            theme={buttonTheme}
            onClick={setStep}
            color={getColorText()}
          >
            {cta}
          </Button.Link>
        );
      case "download_cv":
        return (
          <ModalConsumer>
            {({ toggle }) => (
              <Button
                disabled={disabled}
                width={isSm ? "100%" : 160}
                theme={buttonTheme}
                onClick={() => toggle(<GenerateCvModalContent close={() => toggle()} />, "Download CV")}
                color={getColorText()}
              >
                {cta}
              </Button>
            )}
          </ModalConsumer>
        );
    }
  })();
};

export default StepActions;
