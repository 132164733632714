import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "b2c/hooks/useTranslation";

import FuzuLaptop from "images/fuzu-laptop.png";
import { ScreenClassContext } from "react-grid-system";
import { Dropdown, Image, Input, Text } from "../Core";
import { aboutFuzu } from "../../constants/contactUs";
import { StyledPrisingSection, StyledPrisingSectionWrapper } from "./styled";
import { theme } from "../../contexts/theme";

const codeErrorStyles = {
  display: "inline-block",
  position: "relative",
  bottom: "33px"
};

const FormStep = ({
  codes,
  currentCountry,
  countries = [],
  formState,
  setFormState,
  employerSizes,
  validationState,
  dividerPosition,
  title
}) => {
  const [phoneNumber, setPhoneNumber] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    if (codes.length) {
      const codeByCountry = codes.find(item => item?.name === currentCountry?.name);
      if (codeByCountry) {
        setFormState(prev => ({ ...prev, phoneCode: codeByCountry?.phone_code }));
      }
    }
  }, [codes]);
  const contactUsPricing = JSON.parse(localStorage.getItem("contactUsPricing"));
  const screen = useContext(ScreenClassContext);
  const isSm = ["xs", "sm", "md"].includes(screen);

  const wrapperStyle = !validationState?.phone?.error
    ? { display: "inline-block" }
    : !formState.phone || isNaN(formState.phone)
      ? codeErrorStyles
      : { display: "inline-block" };

  return (
    <div>
      <Text fontWeight="400" fontSize="14px" color="grey700" margin="24px 0 24px 0">
        {t(title)}
      </Text>
      <Text fontWeight="400" fontSize="14px" color="grey700" margin="0 0 24px 0">
        {t("companies.contact_us_modal.requires")}
      </Text>
      {contactUsPricing && contactUsPricing.title && (
        <StyledPrisingSectionWrapper>
          <Image src={FuzuLaptop} height="100px" width="100px" style={{ display: "inline-block", marginTop: "12px" }} />
          <StyledPrisingSection style={{ display: "inline-block", verticalAlign: "top", margin: "31px" }}>
            <Text margin="0 0 10px 0" fontWeight="600" fontSize="20px" color={theme.black300}>
              {t(contactUsPricing.title)}
            </Text>
            <Text
              style={{ display: "inline-block" }}
              fontWeight="600"
              fontSize="16px"
              color={theme.black300}
              margin="0"
            >
              {`${t(contactUsPricing.value)} ${contactUsPricing.per ? t(contactUsPricing.per) : ""}`}
            </Text>
          </StyledPrisingSection>
        </StyledPrisingSectionWrapper>
      )}

      <form noValidate>
        <Input
          data-cy="name"
          key="name"
          label={t("companies.contact_us_modal.input_1_label")}
          placeholder={t("companies.contact_us_modal.input_1_placeholder")}
          value={formState.name}
          errorMessage={validationState?.name?.error}
          onChange={({ target: { value } }) => setFormState(prev => ({ ...prev, name: value }))}
        />

        <div>
          <Text margin="0 0 10px 0" fontWeight={500} fontSize={14} color="$black500">
            {t("companies.contact_us_modal.input_2_label")}
          </Text>
          <div>
            <Dropdown
              placeholder={t("companies.contact_us_modal.input_2_placeholder_2")}
              id="code"
              name="code"
              menuStyle={{ display: "inline-block" }}
              wrapperStyle={{
                outline: "none",
                height: "46px",
                width: "140px",
                borderRadius: "5px",
                borderColor: "#e1e1e1",
                ...wrapperStyle
              }}
              defaultValue={formState.phoneCode}
            >
              <Dropdown.Menu disableSorting>
                {codes.map(({ phone_code, name }) => (
                  <Dropdown.Item
                    key={`${phone_code}-${name}`}
                    onClick={() => setFormState(prev => ({ ...prev, phoneCode: phone_code }))}
                  >
                    {`${phone_code} ${name}`}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>

            <Input
              data-cy="phone"
              key="phone"
              width={isSm ? "100%" : "350px"}
              display="inline-block"
              placeholder={t("companies.contact_us_modal.input_2_placeholder")}
              style={{ display: "inline-block" }}
              errorMessage={validationState?.phone?.error}
              value={formState.phone}
              defaultValue={phoneNumber}
              onChange={e => {
                setFormState(prev => ({ ...prev, phone: e.target.value }));
                setPhoneNumber(e.target.value);
              }}
            />
          </div>
        </div>

        <Input
          label={t("companies.contact_us_modal.input_3_label")}
          placeholder="example@domain.com"
          name="email"
          type="email"
          autoComplete="email"
          errorMessage={validationState?.email?.error}
          value={formState.email}
          onChange={({ target: { value } }) => setFormState(prev => ({ ...prev, email: value }))}
        />

        <Input
          data-cy="organization"
          key="organization"
          label={t("companies.contact_us_modal.input_4_label")}
          placeholder={t("companies.contact_us_modal.input_4_placeholder")}
          errorMessage={validationState?.organization?.error}
          value={formState.organization}
          onChange={({ target: { value } }) => setFormState(prev => ({ ...prev, organization: value }))}
        />

        <Dropdown
          id="size"
          data-cy="size"
          name="size"
          label={t("companies.contact_us_modal.input_5_label")}
          placeholder={t("companies.contact_us_modal.input_5_placeholder")}
          defaultValue={employerSizes.find(item => item?.id === formState?.size)?.name}
          errorMessage={validationState?.size?.error}
        >
          <Dropdown.Menu disableSorting isActiveSearch={isSm}>
            {employerSizes.map(({ id, name }, i) => (
              <Dropdown.Item key={i} value={name} onClick={() => setFormState(prev => ({ ...prev, size: id }))}>
                {name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown
          id="country-dropdown"
          data-cy="country-dropdown"
          name="country"
          label={t("companies.contact_us_modal.input_6_label")}
          placeholder={t("companies.contact_us_modal.input_6_placeholder")}
          defaultValue={currentCountry.name || countries.find(item => item?.id === formState?.country)?.name}
          errorMessage={validationState?.country?.error}
        >
          <Dropdown.Menu disableSorting isFixedSm={!!navigator.platform && /iPhone/.test(navigator.platform)}>
            {countries.map(({ id, name }, i) => (
              <Dropdown.Item
                style={{ borderBottom: i === dividerPosition ? "1px solid #e1e1e1" : "none" }}
                key={i}
                value={name}
                onClick={() => setFormState(prev => ({ ...prev, country: id }))}
              >
                {name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown
          id="about"
          data-cy="about"
          name="about"
          label={t("companies.contact_us_modal.input_7_label")}
          placeholder={t("companies.contact_us_modal.input_7_placeholder")}
          defaultValue={formState?.about}
          errorMessage={validationState?.about?.error}
        >
          <Dropdown.Menu disableSorting isActiveSearch={isSm}>
            {aboutFuzu.map(about => (
              <Dropdown.Item key={about} value={about} onClick={() => setFormState(prev => ({ ...prev, about }))}>
                {about}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </form>
    </div>
  );
};

export default FormStep;
