import React from "react";
import { Row, Col } from "react-grid-system";
import { useTranslation } from "b2c/hooks/useTranslation";

import Layout from "b2c/components/Layout";
import { Title, Text } from "b2c/components/Core";
import EmbedVideo from "b2c/components/EmbedVideo";
import { testimonials } from "b2c/constants/landing";
import useStore from "../../../../contexts/store";

const Testimonials = ({ style }) => {
  const { global_brand } = useStore("initialState");
  const globalBrandName = global_brand.name.toLowerCase();
  const testimonialsList = testimonials[globalBrandName];
  const { t } = useTranslation();

  return (
    <Layout.Section backgroundColor="guestTestimonialsBackground" padding="48px 0" style={style}>
      <Layout.Content tag="div" size="lg">
        <Title
          level="2"
          scales
          fontWeight={600}
          color="primaryButtonLabelColor"
          margin="0 auto 24px"
          textAlign="center"
        >
          {t("landing.testimonials.title")}
        </Title>
        <Row>
          {testimonialsList.map(({ id, text, videoID, author }) => (
            <Col key={id} md={6} sm={12}>
              <EmbedVideo videoID={videoID} alt={text} />
              <Text color="primaryButtonLabelColor" fontWeight={600} fontSize={16} lineHeight="24px" margin="8px 0">
                {t(text)}
              </Text>
              <Text color="primaryButtonLabelColor" margin="0 0 16px">
                {t(author)}
              </Text>
            </Col>
          ))}
        </Row>
      </Layout.Content>
    </Layout.Section>
  );
};

export default Testimonials;
