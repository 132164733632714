import React, { useMemo } from "react";

import { Hidden } from "react-grid-system";

import { KeyboardArrowDown } from "@styled-icons/material";
import { SortDown } from "@styled-icons/boxicons-regular/SortDown";
import { capitalize } from "utils/helpers";
import { useTranslation } from "b2c/hooks/useTranslation";
import { Dropdown, Button, Text, Title } from "../Core";

const SortingSelect = ({ currentValue = "", baseProperty = "value", options = [], onSelect, ...triggerProps }) => {
  const defaultValue = useMemo(
    () =>
      currentValue && options.length > 0 && options.some(option => option[baseProperty] == currentValue)
        ? options.find(option => option[baseProperty] == currentValue).name
        : options.some(option => option.default)
          ? options.find(option => option.default).name
          : null,
    [currentValue, options, baseProperty]
  );

  return (
    <Dropdown
      trigger={<SelectTrigger defaultValue={defaultValue} {...triggerProps} />}
      defaultValue={defaultValue}
      $triggerStyle="right: 0"
      menuStyle="right: 0"
      wrapperStyle="margin-bottom: 0;"
    >
      <Dropdown.Menu>
        {options.map(item => (
          <Dropdown.Item key={item.name} value={item[baseProperty]} onClick={onSelect}>
            {item.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

const SelectTrigger = ({ isOpen, onClick, currentValue, defaultValue, errorMessage, ...props }) => {
  const { t } = useTranslation();

  return (
    <Button.Iconed noBackground theme="black500" onClick={onClick} {...props}>
      <SortDown width={22} />
      <Text inline margin="auto 3px auto 6px" fontWeight={500}>
        {t("global.sort_by")}:
      </Text>
      <Hidden sm xs>
        <Title inline margin="auto 6px auto 3px" level="2" fontSize="14px" fontWeight="400">
          {capitalize(defaultValue || currentValue)}
        </Title>
      </Hidden>
      <KeyboardArrowDown width={20} />
    </Button.Iconed>
  );
};

export default SortingSelect;
