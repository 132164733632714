import React from "react";
import { Image } from "b2c/components/Core";
import HomeScreenImage from "images/app-home-screen.png";
import HomeScreenImageCycles from "images/app-home-screen-cycles.png";
import { HomeScreenContent, image } from "./styled";

const HomeScreen = () => {
  return (
    <HomeScreenContent background={HomeScreenImageCycles}>
      <Image src={HomeScreenImage} style={image} width="260px" height="460px" />
    </HomeScreenContent>
  );
};

export default HomeScreen;
