import React, { useState, useEffect } from "react";
import { Row, Col } from "react-grid-system";

import { useTranslation } from "b2c/hooks/useTranslation";
import { thousandSeparator } from "utils/helpers";
import { Text, Card, Button, Checkbox, RadioButton } from "b2c/components/Core";
import Product from "b2c/components/Product";
import withLoading from "b2c/components/withLoading";

const PriceCard = ({ name, description, products }) => {
  const [selected, setSelected] = useState(products[0]);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [error, setError] = useState();
  const oneSession = /1/.test(selected?.slug);
  const topics = getTopics(name);

  const { t } = useTranslation();

  useEffect(() => {
    setSelectedTopics(topics.slice(0, oneSession ? 1 : 3).map(({ name: names }) => names));
  }, [selected]);

  useEffect(() => {
    setError();
  }, [selectedTopics]);

  const modifyTopicState = names => {
    setSelectedTopics(prev => {
      if (prev.includes(names)) return prev.filter(topic => topic !== names);
      if (selectedTopics.length < 3) return prev.concat(names);

      setError("Please deselect option first");
      return prev;
    });
  };

  const buildSelectedOptions = options => {
    return {
      meta: { options }
    };
  };

  const validateOptions = () => {
    return new Promise((resolve, reject) => {
      if (selectedTopics.length === (oneSession ? 1 : 3)) resolve();
      else {
        setError(`Please select ${oneSession ? 1 : 3} option${oneSession ? "" : "s"}`);
        reject();
      }
    });
  };

  return (
    <Card shadow style={{ height: "100%" }}>
      <Card.Header style={{ flexDirection: "column" }}>
        <Text id={`${name}-label`} fontSize={28} fontWeight={700} margin="0 0 5px">
          {name}
        </Text>
        <Text fontSize={16} margin="0 0 10px">
          {description}
        </Text>
        <div role="radiogroup" aria-labelledby={`${name}-label`}>
          {products.map(product => (
            <RadioButton
              key={product.slug}
              id={product.slug}
              checked={selected.slug == product.slug}
              name="session"
              value={product.slug}
              checkedColor="secondary300"
              Label={
                <Text fontWeight={600} fontSize={18}>
                  {product.name} - {product.currency} {thousandSeparator(product.discounted_price)}
                </Text>
              }
              onChange={() => {
                setSelected(product);
              }}
            />
          ))}
        </div>
      </Card.Header>
      <Card.Separator />
      <Card.Content padding="8px 12px">
        <Row style={{ marginBottom: 8 }}>
          <Col lg={8}>
            <Text fontWeight={600} fontSize={18}>
              {oneSession ? t("career_coaching.packages.select_one") : t("career_coaching.packages.select_three")}
            </Text>
          </Col>
          <Col lg={4}>
            <Text margin="0 0 0 auto">
              {selectedTopics.length} / {oneSession ? 1 : 3} {t("career_coaching.packages.selected")}
            </Text>
          </Col>
        </Row>
        <div>
          {topics.map(({ name: nameProp, text, popular }) =>
            oneSession ? (
              <RadioButton
                key={nameProp}
                id={nameProp}
                checked={selectedTopics.includes(nameProp)}
                name="topic"
                value={nameProp}
                checkedColor="secondary300"
                margin="4px 0"
                Label={
                  <>
                    <Text fontWeight={600} fontSize={16} margin="4px 0 2px">
                      {t(nameProp)}
                      {popular ? (
                        <Text
                          as="b"
                          fontWeight={600}
                          margin="0 0 0 8px"
                          color="additional200"
                          style={{ textTransform: "uppercase" }}
                        >
                          {t("career_coaching.packages.popular")}
                        </Text>
                      ) : null}
                    </Text>
                    <Text margin="2px 0 4px">{t(text)}</Text>
                  </>
                }
                onChange={e => {
                  setSelectedTopics([e.target.value]);
                }}
              />
            ) : (
              <Checkbox
                key={nameProp}
                id={nameProp}
                checked={selectedTopics.includes(nameProp)}
                name="topic"
                value={nameProp}
                checkedColor="secondary300"
                margin="4px 0"
                label={
                  <>
                    <Text fontWeight={600} fontSize={16} margin="4px 0 2px">
                      {t(nameProp)}
                      {popular ? (
                        <Text
                          as="b"
                          fontWeight={600}
                          margin="0 0 0 8px"
                          color="additional200"
                          style={{ textTransform: "uppercase" }}
                        >
                          {t("career_coaching.popular")}
                        </Text>
                      ) : null}
                    </Text>
                    <Text margin="2px 0 4px">{t(text)}</Text>
                  </>
                }
                onChange={e => {
                  modifyTopicState(e.target.value);
                }}
              />
            )
          )}
        </div>
      </Card.Content>
      <Card.Footer padding="12px" style={{ marginTop: "auto" }}>
        <div style={{ width: "100%" }}>
          <Text height="18px" margin="0 auto 4px" color="accent300">
            {error}
          </Text>
          <Product.Button
            width="100%"
            theme="additional300"
            product={selected}
            locationState={buildSelectedOptions(selectedTopics)}
            validate={validateOptions}
          >
            {t("career_coaching.buy_now")} – {selected?.currency} {thousandSeparator(selected?.discounted_price)}
          </Product.Button>
        </div>
      </Card.Footer>
    </Card>
  );
};

PriceCard.Placeholder = () => {
  return (
    <Card shadow style={{ height: "100%" }}>
      <Card.Header style={{ flexDirection: "column" }}>
        <Text fontSize={28} fontWeight={700} margin="0 0 5px" loading />
        <Text fontSize={16} height={160} margin="0 0 100px" loading />
      </Card.Header>
      <Card.Separator height={1} margin="0" />
      <Card.Content padding="16px 12px" height={432} style={{ flex: "1 0 auto" }}>
        <Text fontSize={16} margin="auto 0" loading />
        <Text fontSize={16} margin="auto 0" loading />
        <Text fontSize={16} margin="auto 0" loading />
        <Text fontSize={16} margin="auto 0" loading />
        <Text fontSize={16} margin="auto 0" loading />
        <Text fontSize={16} margin="auto 0" loading />
      </Card.Content>
      <Card.Separator height={1} margin="0" />
      <Card.Footer padding="12px">
        <Button width="100%" theme="additional300" disabled>
          Buy Now
        </Button>
      </Card.Footer>
    </Card>
  );
};

const getTopics = product => {
  if (/Career/.test(product)) return careerTopics;
  return execTopics;
};

const careerTopics = [
  {
    name: "career_coaching.packages.career_topics.title_1",
    text: "career_coaching.packages.career_topics.description_1",
    popular: true
  },
  {
    name: "career_coaching.packages.career_topics.title_2",
    text: "career_coaching.packages.career_topics.description_2",
    popular: true
  },
  {
    name: "career_coaching.packages.career_topics.title_3",
    text: "career_coaching.packages.career_topics.description_3",
    popular: true
  },
  {
    name: "career_coaching.packages.career_topics.title_4",
    text: "career_coaching.packages.career_topics.description_4",
    popular: false
  },
  {
    name: "career_coaching.packages.career_topics.title_5",
    text: "career_coaching.packages.career_topics.description_5",
    popular: false
  }
];

const execTopics = [
  {
    name: "career_coaching.packages.exec_topics.title_1",
    text: "career_coaching.packages.exec_topics.description_1",
    popular: true
  },
  {
    name: "career_coaching.packages.exec_topics.title_2",
    text: "career_coaching.packages.exec_topics.description_2",
    popular: true
  },
  {
    name: "career_coaching.packages.exec_topics.title_3",
    text: "career_coaching.packages.exec_topics.description_3",
    popular: true
  },
  {
    name: "career_coaching.packages.exec_topics.title_4",
    text: "career_coaching.packages.exec_topics.description_4",
    popular: false
  },
  {
    name: "career_coaching.packages.exec_topics.title_5",
    text: "career_coaching.packages.exec_topics.description_5",
    popular: false
  }
];

export default withLoading(PriceCard, PriceCard.Placeholder);
