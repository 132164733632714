import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { theme } from "b2c/contexts/theme";

export const StyledHeader = styled.div`
  padding-top: 60px;

  &.styled-header_guest {
    padding-top: 85px;
  }
`;

export const HeroCardWrapper = styled.div`
  margin: auto;
`;

export const LoginFlow = styled.div`
  display: flex;
  align-items: center;
`;

const loginButtonStyles = css`
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  align-items: center;
  text-align: center;
  color: ${({ color }) => color || "#333333"};
  padding: 12px 20px;
  background: ${({ backgroundColor }) => backgroundColor || "#ffffff"};
  border: ${({ border }) => border || "1px solid #c5c5c5"};
  border-radius: 6px;
  display: block;
  margin-right: ${({ marginRight }) => marginRight || "16px"};
  cursor: pointer;
  outline: none;
  height: 40px;

  &:hover {
    border: ${({ border }) => border || "1px solid #333333"};
  }

  &:focus {
    border: ${({ border }) => border || "1px solid #333333"};
    background: ${({ backgroundColor }) => backgroundColor || "#f3f3f3"};
  }

  @media screen and (min-width: 768px) {
    padding: 12px 24px;
  }
`;

export const JoinNow = styled(Link)`
  ${loginButtonStyles};
`;

export const Login = styled(Link)`
  ${loginButtonStyles};
  display: none;

  @media screen and (min-width: 768px) {
    display: block;
  }
`;

export const LoginSidebar = styled(Link)`
  color: #333;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 7px 8px;
  display: block;
  margin-bottom: 16px;
  border-radius: 5px;
  border: 1px solid #eee;
  background: #fff;
`;

export const LoginEmployer = styled.a`
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  align-items: center;
  text-align: center;
  color: #333333;
  padding: 8px 24px;
  background: #ffffff;
  border: 1px solid #c5c5c5;
  border-radius: 6px;
  display: block;
  margin-right: 16px;
  cursor: pointer;
  outline: none;

  &:hover {
    border: 1px solid #333333;
  }

  &:focus {
    border: 1px solid #333333;
    background: #f3f3f3;
  }
`;

export const LanguageSwitcherContainer = styled.div`
  padding-top: 24px;
  border-top: 1px solid #c5c5c5;
  margin-top: 8px;
  margin-bottom: 16px;
`;

export const LanguageSwitcherDivider = styled.span`
  height: 16px;
  margin: 0 24px;
  width: 2px;
  background: #666;
`;

export const LanguageSwitcher = styled.button`
  border-radius: 5px;
  border: 1px solid #eee;
  background: #fff;
  color: #333;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  height: 40px;
  width: 100%;
  cursor: pointer;
  text-transform: capitalize;

  svg {
    width: 16px;
    height: 16px;
    fill: #333;
  }

  @media screen and (min-width: 1024px) {
    background: transparent;
    border: 1px solid #666;
    width: auto;
    padding: 8px 16px;
    color: #fff;

    svg {
      fill: #fff;
    }
  }
`;

export const Signup = styled(Link)`
  padding: 8px 24px;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  align-items: center;
  text-align: center;
  color: #333333;
  background: ${theme.primary};
  border-radius: 6px;
  display: block;
  outline: none;
  height: 32px;

  &:hover {
    background: #ffee96;
  }

  &:focus {
    background: #f1c80e;
  }
`;

export const ForEmployerOrSeekers = styled(Link)`
  font-weight: 600;
  font-size: ${({ isSmallFontSize }) => (isSmallFontSize ? "12px" : "14px")};
  line-height: 21px;
  color: ${({ color }) => color};
  display: block;
  position: relative;
  margin: ${({ margin }) => margin || "17px 0 0 0 "};

  &:after {
    content: "";
    width: 1px;
    height: 16px;
    position: absolute;
    right: 0;
    top: 4px;
    background: #c5c5c5;
    display: none;
  }

  @media screen and (min-width: 768px) {
    font-size: 14px;
  }

  @media screen and (min-width: 992px) {
    display: inline-block;
    margin-right: 16px;
    padding-right: 16px;
    margin-top: 0;
    font-size: 16px;
    line-height: 24px;

    &:after {
      display: block;
    }
  }
`;

export const StyledHeaderContainer = styled.header`
  ${({ background_color = "#fff", fontColor, fontColorActive, fillColor, borderBottom }) => css`
    background-color: ${background_color};
    border-bottom: ${borderBottom};

    &.main-header_guest {
      height: 85px;

      .content {
        max-height: 85px;
        align-items: flex-start;
        padding: 8px 20px;
      }
    }

    .nav-item {
      display: inline-flex;
      align-items: center;
      color: ${fontColor};
      font-size: 14px;
      font-weight: 600;
      padding: 0 15px;
      height: 100%;

      &.is-active {
        color: ${fontColorActive};
        .nav-item__icon {
          display: block;
        }
        &.prompt {
          padding-top: 3px;
        }
      }
    }

    .nav-item_border {
      position: relative;
      cursor: pointer;

      &:after {
        content: "";
        height: 3px;
        width: 50px;
        background-color: #c5c5c5;
        position: absolute;
        bottom: -21px;
        display: none;
        left: 50%;
        margin-left: -25px;
      }

      &:hover:after {
        display: block;
      }

      &.is-active {
        &:after {
          display: block;
          background-color: #333333;
        }
      }
    }

    .nav-item_barona {
      color: #fff;

      &:hover:after {
        display: none;
      }

      .nav-item__icon {
        display: none;
      }

      &:after {
        display: none;
      }

      &.is-active {
        color: #2496f5;

        .nav-item__icon {
          display: none;
        }

        &:after {
          display: none;
        }
      }
    }

    .nav-icon {
      outline: none;

      > svg {
        fill: ${fillColor};
      }

      &.nav-icon_barona {
        svg,
        path {
          fill: #f6f7f9;
        }
      }
    }

    .close-icon {
      position: absolute;
      right: 5px;
      top: 50%;
      margin-top: -17px;
    }

    .nav-item__icon {
      display: inline-block;
      margin-right: 5px;
      display: none;
    }
  `}

  &.styled-header_jkuat {
    max-height: 55px;
  }
`;

export const HamburgerContent = styled.div`
  position: absolute;
  display: ${({ $isActive }) => ($isActive ? "flex" : "none")};
  flex-direction: column;
  top: 55px;
  left: 0;
  height: calc(100vh - 55px);
  background: #f3f3f3;
  width: 100%;
  padding: 24px;

  a {
    margin: 0 0 16px 0;
  }

  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: row;
    position: inherit;
    background: transparent;
    height: auto;
    left: auto;
    top: auto;
    padding: 0;
    width: auto;

    a {
      margin: 0 16px 0;
    }
  }
`;

export const MobileSidebar = styled.div`
  position: absolute;
  display: ${({ $isActive }) => ($isActive ? "flex" : "none")};
  flex-direction: column;
  top: 0;
  left: 0;
  height: 100vh;
  background: #f3f3f3;
  width: 100%;
  padding: 16px;
  z-index: 1;

  a {
    margin: 0 0 16px 0;
  }

  @media screen and (min-width: 1024px) {
    display: none;
  }
`;

export const MobileSidebarHeading = styled.div`
  display: flex;
  align-self: center;
  justify-content: space-between;
  padding-bottom: 16px;
  margin-bottom: 24px;
  width: 100%;

  path {
    fill: #18181b;
  }
`;

export const MobileSidebarHeadingClose = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;

  svg {
    fill: #666666;
    width: 20px;
    height: 20px;
  }
`;

const mobileSidebarNavItemStyles = `
  color: #18181b;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    fill: #333333;
    width: 16px;
    height: 16px;
  }
`;

export const MobileSidebarNavItem = styled(Link)`
  ${mobileSidebarNavItemStyles};
`;

export const MobileSidebarNavItemLink = styled.a`
  ${mobileSidebarNavItemStyles};
`;

export const MobileSidebarFooter = styled.div`
  margin-top: auto;
`;

export const HamburgerButton = styled.button`
  display: block;
  width: 24px;
  height: 24px;
  background-color: transparent;

  svg {
    width: 100%;
  }

  @media screen and (min-width: 992px) {
    display: none;
  }
`;

export const HamburgerFooter = styled.div`
  margin-top: auto;

  @media screen and (min-width: 768px) {
    display: none;
  }
`;

export const FuzuLtd = styled.div`
  color: #a4a4a4;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  margin-top: 8px;
`;

export const Actions = styled.div`
  display: flex;
  gap: 12px;
  justify-content: center;
  color: #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  a {
    color: #666;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
`;

export const Flex = styled.div`
  display: flex;
  gap: 12px;
  margin-right: 12px;
  margin-left: auto;
`;

export const HamburgerMenu = styled.button`
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  padding: 0;
  margin-left: 12px;

  svg {
    width: 24px;
    height: 24px;
    fill: ${({ $color }) => $color};
  }

  @media screen and (min-width: 992px) {
    display: none;
  }
`;
