import styled from "styled-components";

export const ViewWrapper = styled.div`
  ${({
    $borderColor = "#C5C9D1",
    $backgroundColor = "#FFF",
    shadow = true,
    display = "flex",
    overflow = "auto",
    $guestMode = false
  }) => `
    ${shadow ? "box-shadow: 0px 5px 15px 3px rgba(33, 33, 33, 0.05);" : ""}
    border-radius: 5px 5px 0 0;
    display: ${display};
    overflow: ${overflow};
    width: 100%;
    flex: 1 0 0;
    background-color: ${$backgroundColor};

    @media screen and (max-width: 767px) {
        box-shadow: none;
        flex-direction: column;
        overflow: visible;
        background-color: transparent;
        margin: 0 -20px;
        width: auto;
    }

    section {
        overflow-y: auto;
        padding: 16px;
    }
    
    > div {
        padding: 16px;
    }

    > .job-list {
        flex: 1 0 35%;
        border-right: 1px solid ${$borderColor};

        @media screen and (max-width: 767px) {
            padding: 0 16px;
            overflow: visible;
            background-color: transparent;
            border: none;
        }

        > a {
            display: flex;
            @media screen and (max-width: 767px) {
                border-radius: 0;
            }
            
            :empty {
                margin-bottom: 0;
            }
        }
        
        .b2c-card {
          margin-bottom: 15px;
        }
    }

    > .job-content {
        flex: 1 1 100%;
        padding-bottom: 20px;

        &:focus {
            outline:none;
        }

        @media screen and (max-width: 767px) {
            display: flex;
            flex-direction: column;
            overflow-y: auto;
            background-color: ${$backgroundColor};
            padding: 16px 16px 0;
        }

        .view-header {
            width: 100%;
            display: inline-flex;
            position: relative;

            @media screen and (max-width: 767px) {
                flex-direction: column;
            }

            .content-main-info {
                @media screen and (min-width: 768px) {
                    flex: 1 0 320px;
                    padding: 0 16px;
                }
            }

            .view-logo {
                > img {
                    min-width: 110px;
                }

                @media screen and (max-width: 767px) { 
                    margin-bottom: 8px;
    
                    .b2c-button {
                      padding-left: 0;
                    }
                }

                > svg {
                    padding: 36px;
                    border-radius: 5px;
                    border: 1px solid ${$borderColor};

                    path {
                        fill: ${$borderColor};
                    }
                }
            }

            img {
                object-fit: contain;
                border-radius: 5px;
                border: 1px solid ${$borderColor};
                padding: 12px;
            }

            .content-additional-info {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                @media screen and (min-width: 768px) {
                    align-items: flex-end;
                }

                .action-buttons {
                    @media screen and (max-width: 768px) {
                        position: absolute;
                        top: 0px;
                        right: 0;
                    }
                }
                
                .additional {
                    @media screen and (min-width: 768px) {
                        text-align: right;

                        .published {
                            margin-left: auto;
                        }
                    }
                }
            }
        }

        .view-main {
            padding: 15px 0;

            @media screen and (max-width: 768px) {
                padding-bottom: 120px;
            }

            .view-requirements, .view-summary {
                margin-bottom: 20px;
            }

            .view-summary {
                min-height: 50px;
            }

            div.view-summary-content_tag {
              padding-left: 0;
              display: flex;
              align-items: center;
              gap: 8px;
              flex-wrap: wrap;
              
              a {
                margin: 0;
              }
            }

            .view-summary-content, .view-responsibilities-content {
                font-size: 14px;
                white-space: pre-wrap;
                
                ul, ol {
                  padding-left: 13px;
                }
                
                blockquote {
                    border-left: 4px solid #eeeeee;
                    margin: 16px 0 16px 8px;
                    padding-left: 16px;
                }
                
                * {
                    font-family: inherit !important;
                    font-size: inherit !important;
                    font-style: normal !important;
                }
            }
        }

        .mobile-actions {
            border-top: 1px solid ${$borderColor};
            padding: 15px 0;
            display: flex;
        }

        .view-footer {
            position: fixed;
            padding: 16px;
            width: 100%;
            bottom: ${$guestMode ? "0px" : "60px"};
            left: 0;
            border-top: 1px solid ${$borderColor};
            background: ${$backgroundColor};
            z-index: 101;

            > * {
                height: 36px;
            }

            > a {
                width: 60%;
            }
        }
    }
`}
`;
