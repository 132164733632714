import { observer } from "mobx-react";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Col, Row, ScreenClassContext } from "react-grid-system";

import { buildImageUrlS3, getCompanyName } from "b2c/contexts/branded";
import { useTranslation } from "b2c/hooks/useTranslation";
import AvatarProgress from "../AvatarProgress";
import { Label, Text, Button, Image } from "../Core";
import useStore from "../../contexts/store";
import StrengthLabel from "../StrengthLabel";
import { isCustomJkuat } from "../../contexts/branded";
import { useIsBarona } from "../../hooks";
import { nameImages } from "../../constants/images";

export const ProfileHighlights = observer(({ hideActivityCenter, isShowText }) => {
  const {
    profileStrength,
    user: { first_name: firstName, fuzu_points: fuzuPoints, sign_in_count: signInCount }
  } = useStore("User");
  const screen = useContext(ScreenClassContext);
  const isSm = /xs|sm/.test(screen);
  const isFirstLogin = signInCount === 1;
  const customJkuat = isCustomJkuat();
  const isBarona = useIsBarona();
  const { t } = useTranslation();

  const handleClick = () => {
    if (hideActivityCenter) {
      hideActivityCenter();
    }
  };

  return (
    <Row nowrap>
      <Col
        width="auto"
        style={{
          padding: isSm ? 0 : "auto"
        }}
      >
        <AvatarProgress width={isSm ? 56 : 80} isHideMobile />
      </Col>
      <Col width="auto">
        <Text
          as={Link}
          to="/profile"
          style={{ display: "block" }}
          fontSize={isSm ? 20 : 32}
          fontWeight={isSm ? 600 : 500}
        >
          {t("home.welcome")}
          {isFirstLogin ? "" : ` ${t("home.back")}`}, {firstName}!
        </Text>
        <StrengthLabel {...profileStrength} hideActivityCenter={hideActivityCenter} />
        {!customJkuat && !isBarona && (
          <Button.Link to="/points" theme="transparent" onClick={handleClick}>
            <Label theme="transparent" color="black500" display="inline-flex" padding="0 8px" rect fontSize={14}>
              <Image
                src={buildImageUrlS3(nameImages.points)}
                style={{ margin: "0 4px", display: "block", width: "18px" }}
              />
              {fuzuPoints}{" "}
              {isShowText && (
                <>
                  {" "}
                  {getCompanyName()} {t("global.points")}
                </>
              )}
            </Label>
          </Button.Link>
        )}
      </Col>
    </Row>
  );
});
