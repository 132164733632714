import React, { useLayoutEffect } from "react";
import { observer } from "mobx-react";
import { Row, Col, useScreenClass } from "react-grid-system";
import { useLocation } from "react-router-dom";

import { useModal } from "utils/hooks";
import { useTranslation } from "b2c/hooks/useTranslation";
import { StyledModalContent } from "./styled";
import { Title, Image, Button, Text } from "../Core";
import ApplicationFlowPromo from "../ModalContents/ApplicationSubmittedPromo";
import useStore from "../../contexts/store";
import { useShowModalAllowNotify } from "../../hooks";
/* hide for this ticket https://app.shortcut.com/fuzu/story/4129/remove-instant-feedback-remove-all-promo-elements-advertising-if-from-b2c-app */
// isWhitelabelledOrJkuat,
import { isBaronaBrand, isCustomJkuat, getCompanyName, buildImageUrlS3 } from "../../contexts/branded";
import { nameImages } from "../../constants/images";

const ExitFlowModalContent = ({ job_id, app_id, close, unblock, type: passedType }) => {
  const {
    state: { processing },
    // job_details,
    modalType: type,
    cancelApplication,
    clearApplication,
    submitApplication,
    switchModalType
  } = useStore("Application");
  const customJkuat = isCustomJkuat();
  const isBarona = isBaronaBrand();
  // const whitelabelledOrJkuat = isWhitelabelledOrJkuat();

  const location = useLocation();
  const { features } = useStore("Premium");
  const { user } = useStore("User");
  const screenClass = useScreenClass();
  const { t } = useTranslation();

  /*
    const openSubmitPromo = useModal(
      <ApplicationFlowPromo type="instant_feedback" submitted id={job_details.id} />,
      null,
      null,
      false
    );
  */
  const { isShowNotificationModalAllowNotify, openNotificationModalAllowNotify } = useShowModalAllowNotify();

  const isInviteToApply = location.search.includes("from_invite_message");
  const openEscapePromo = useModal(<ApplicationFlowPromo type="career_coaching" />, null, null, false);

  useLayoutEffect(() => {
    if (passedType) {
      switchModalType(passedType);
    }

    return () => {
      switchModalType("leave");
    };
  }, [passedType]);

  const resolveHanlder = () => {
    const pendindAction = (() => {
      switch (type) {
        default:
        case "leave":
          return clearApplication;
        case "submit":
          return submitApplication;
        case "cancel":
          return cancelApplication;
      }
    })();

    pendindAction(job_id, app_id, isInviteToApply).then(success => {
      if (success && unblock) {
        unblock();
      }

      if (isShowNotificationModalAllowNotify) {
        openNotificationModalAllowNotify();
      }
      /*
      if (
        success &&
        type === "submit" &&
        !job_details.instant_feedback?.activated &&
        !isBarona &&
        !whitelabelledOrJkuat
      ) {
        // openSubmitPromo();
      } else 
        */
      if (
        type === "leave" &&
        !features?.coaching?.activated &&
        ["KE", "UG"].includes(user?.country?.country_code) &&
        !customJkuat &&
        !isBarona
      ) {
        openEscapePromo();
      } else {
        setTimeout(() => {
          close();
        }, 50);
      }
    });
  };

  const { title, text, acceptText, buttonTheme } = (() => {
    switch (type) {
      default:
      case "leave":
        return {
          title: t("jobs.leave_modal.title"),
          text: t("jobs.leave_modal.title", { name: getCompanyName() }),
          acceptText: t("jobs.leave_modal.accept_text"),
          buttonTheme: "primary"
        };
      case "submit":
        return {
          title: t("jobs.submit_modal.title"),
          acceptText: t("jobs.submit_modal.accept_text"),
          buttonTheme: "success300"
        };
      case "cancel":
        return {
          title: t("jobs.cancel_modal.title"),
          text: t("jobs.cancel_modal.description"),
          acceptText: t("jobs.cancel_modal.accept_text"),
          buttonTheme: "accent300"
        };
    }
  })();

  return (
    <StyledModalContent textAlign="center" data-cy="exit-application-flow">
      {type !== "submit" && (
        <Image
          src={buildImageUrlS3(nameImages.alert)}
          alt="Alert"
          style={{ display: "block", width: "70px", margin: "auto" }}
        />
      )}
      <Title fontWeight={600} margin={type !== "submit" ? "24px auto" : "0 auto"} level={5}>
        {title}
      </Title>
      <Text margin="auto auto 24px" lineHeight="21px">
        {text}
      </Text>
      <Row>
        <Col lg={6} xs={12}>
          <Button theme="grey200" width="100%" onClick={close} margin="4px auto">
            {t("jobs.content.stay_in_application")}
          </Button>
        </Col>
        <Col lg={6} xs={12} style={{ order: screenClass === "xs" ? -1 : 1 }}>
          <Button
            color="#0B0A0A"
            processing={processing}
            width="100%"
            margin="4px auto"
            theme={buttonTheme}
            onClick={resolveHanlder}
          >
            {acceptText}
          </Button>
        </Col>
      </Row>
    </StyledModalContent>
  );
};

export default observer(ExitFlowModalContent);
