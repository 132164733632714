import React, { useContext } from "react";
import { Link, Route } from "react-router-dom";
import { Row, Col, Hidden, Visible, ScreenClassContext } from "react-grid-system";

import heroImage from "images/b2c/landing-page/new-landing-hero.png";
import heroGlobal from "images/b2c/landing-page/hero-global.png";
import JobsIcon from "images/b2c/landing-page/jobs-icon.svg";
import SkillsIcon from "images/b2c/landing-page/skills-icon.svg";
import CommunityIcon from "images/b2c/landing-page/community-icon.svg";
import { thousandSeparator } from "utils/helpers";
import HireTalentSkills from "images/b2c/landing-page/hire-talent-skills.svg";
import { useTranslation } from "b2c//hooks/useTranslation";
import Layout from "b2c/components/Layout";
import { Title, Text, Image } from "b2c/components/Core";
import { getCityScape, getMobileImage } from "b2c/utils/helpers";
import { HeroItemContainer } from "../HeroItemContainer";
import HeroItem from "../HeroItem";
import { ImageContainer, ImageContent, Wrapper } from "./styled";

const HeroSection = ({ country, jobsCount }) => {
  const screen = useContext(ScreenClassContext);
  const isSm = ["xs", "sm"].includes(screen);
  const { t } = useTranslation();

  const cityscape = getCityScape(country?.slug);
  const mobileImage = getMobileImage(country?.slug);
  const isNigeria = country?.slug === "nigeria";

  return (
    <Wrapper backgroundImage={isSm ? null : cityscape}>
      <Layout.Content tag="div" size="lg">
        <Row justify="between">
          <Col
            lg={7}
            sm={12}
            style={{
              minHeight: isSm ? 240 : 374,
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
              padding: 0
            }}
          >
            <Visible xs sm>
              <ImageContent>
                <ImageContainer
                  src={mobileImage}
                  alt="Jobs, career guidance and online courses for ambitious professionals from entry level to executives"
                  loadingType="eager"
                  srcSet={`${mobileImage} 300w`}
                />
              </ImageContent>
            </Visible>

            {isNigeria ? (
              <>
                <Title
                  level={1}
                  textAlign={isSm ? "center" : "left"}
                  fontSize={isSm ? 28 : 46}
                  margin={isSm ? "auto 0 4px" : "0 0 4px"}
                  lineHeight="100%"
                  fontWeight="600"
                  width="100%"
                >
                  {t("landing_fuzu.title_nigeria")}
                </Title>
                <Text
                  width="100%"
                  textAlign={isSm ? "center" : "left"}
                  margin={isSm ? "0 0 24px" : "0 0 6px"}
                  fontSize={16}
                >
                  {t("landing_fuzu.title_description")}
                </Text>
              </>
            ) : (
              <>
                <Title
                  level={1}
                  textAlign={isSm ? "center" : "left"}
                  fontSize={isSm ? 28 : 46}
                  margin={isSm ? "auto 0 4px" : "0 0 4px"}
                  lineHeight={1.2}
                  fontWeight={600}
                  width="100%"
                >
                  {`${t("landing_fuzu.title")} ${country?.slug ? `${country?.name}` : t("landing_fuzu.africa")}`}
                </Title>
                <Text
                  width="100%"
                  textAlign={isSm ? "center" : "left"}
                  margin={isSm ? "0 0 24px" : "0 0 6px"}
                  fontSize={16}
                >
                  {t("landing_fuzu.description")}
                </Text>
              </>
            )}

            <Hidden sm xs>
              <Route exact path="/">
                <Image
                  margin="auto 0 0"
                  width={563}
                  height={273}
                  loadingType="eager"
                  src={heroGlobal}
                  alt="Jobs, career guidance and online courses for ambitious professionals from entry level to executives"
                />
              </Route>
              <Route exact path="/:country">
                <Image
                  margin={isNigeria ? "auto 0 -10px 0" : "auto 0 0"}
                  width={537}
                  height={272}
                  loadingType="eager"
                  src={heroImage}
                  alt={
                    isNigeria
                      ? "Jobs, career guidance and online courses for ambitious professionals from entry level jobs to leadership positions in Nigeria"
                      : "Jobs, career guidance and online courses for ambitious professionals from entry level to executives"
                  }
                />
              </Route>
            </Hidden>
          </Col>
          <Col
            lg={4}
            sm={12}
            style={{
              display: "flex",
              justifyItems: "center",
              flexDirection: "column",
              maxHeight: 432
            }}
          >
            <HeroItem
              IconComponent={JobsIcon}
              title={t("landing_fuzu.hero.title_1")}
              label={t("landing_fuzu.hero.description_1")}
              count={thousandSeparator(jobsCount)}
              type="job"
              style={{ margin: "auto 0 16px" }}
              as={HeroItemContainer}
              to="/job"
            />

            <HeroItem
              IconComponent={SkillsIcon}
              title={isNigeria ? t("landing_fuzu.hero.title_2_nigeria") : t("landing_fuzu.hero.title_2")}
              type="learn"
              label={t("landing_fuzu.hero.description_2")}
              count="382"
            />

            {isNigeria ? (
              <HeroItem
                IconComponent={HireTalentSkills}
                title={t("landing_fuzu.hero.title_3_nigeria")}
                label={t("landing_fuzu.hero.description_3_nigeria")}
                count={t("landing_fuzu.hero.count_3")}
                type="join"
                style={{ margin: "0 0 auto" }}
                margin="0 0 auto"
                alt="Jobs, career guidance and online courses for ambitious professionals from entry level jobs to leadership positions in Nigeria"
                as={Link}
                to="/nigeria/employers"
              />
            ) : (
              <HeroItem
                IconComponent={CommunityIcon}
                title={t("landing_fuzu.hero.title_3")}
                label={t("landing_fuzu.hero.description_3")}
                count={t("landing_fuzu.hero.count_3")}
                type="join"
                style={{ margin: "0 0 auto" }}
                as={HeroItemContainer}
                to="/onboarding"
              />
            )}
          </Col>
        </Row>
      </Layout.Content>
    </Wrapper>
  );
};

export default HeroSection;
