import React, { useContext } from "react";
import { Row, Col, ScreenClassContext } from "react-grid-system";
import styled from "styled-components";

import FuzuPointsIco from "images/fuzuPointMainIco.svg";
import FuzuPointsInfoIcon from "images/FuzuPointsInfoIcon.svg";
import { Title, Text, Card, Button, Spinner } from "../../../../components/Core";

const InviteDetails = ({ loading }) => {
  const screen = useContext(ScreenClassContext);
  const isSm = ["xs", "sm"].includes(screen);

  return (
    <Card style={{ marginBottom: "25px", ...(isSm ? { borderRadius: 0 } : "") }}>
      <Card.Content padding="30px 16px 0">
        <Title width="100%" textAlign="center" level="5" fontWeight={600} margin="0 0 15px">
          You`ll get Fuzu Points when your friend joins Fuzu
        </Title>
        <Row
          style={{
            textAlign: "center"
          }}
        >
          <Col sm={6}>
            <Illustration>
              <FuzuPointsInfoIcon height="100px" />
            </Illustration>
            <Text
              fontSize={16}
              fontWeight={500}
              lineHeight="20px"
              margin="10px auto 30px"
              style={{ maxWidth: "630px" }}
            >
              You will get Fuzu Points for every successful registration. Successful registration means that a user you
              invited to Fuzu, who did not previously have an account, registers and completes the onboarding process.
            </Text>
          </Col>
          <Col sm={6}>
            <Illustration>
              {loading ? (
                <Spinner size="72px" margin="auto 15px auto 0" />
              ) : (
                <Text
                  textAlign="center"
                  fontSize={68}
                  fontWeight={600}
                  lineHeight="1"
                  color="success300"
                  margin="auto 15px auto 0"
                >
                  +20
                </Text>
              )}

              <FuzuPointsIco height="100px" />
            </Illustration>
            <Text
              fontSize={16}
              fontWeight={500}
              lineHeight="20px"
              margin="10px auto 30px"
              style={{ maxWidth: "470px" }}
            >
              You will get 20 Fuzu Points for every successful invitation and we will also reward your friend with 50
              extra Fuzu Points to get their Fuzu journey going!
            </Text>
          </Col>
        </Row>

        <Card theme="grey100" bordered style={{ marginBottom: "30px" }}>
          <Card.Content padding="20px 16px">
            <Text width="100%" textAlign="center" margin="0 auto 25px" lineHeight="20px" style={{ maxWidth: "1180px" }}>
              Fuzu Points is Fuzu’s own reward program. You get Fuzu Points for being active on the platform and for
              being committed to constantly growing and pushing yourself for success. You can use them to get discounts
              from Fuzu Premium products
            </Text>
            <Button.Link margin="0 auto" to="/points">
              Read more about Fuzu Points
            </Button.Link>
          </Card.Content>
        </Card>
      </Card.Content>
    </Card>
  );
};

const Illustration = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default InviteDetails;
