import React from "react";
import { Row, Col } from "react-grid-system";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

import Marker from "images/b2c/icons/marker.svg";
import LeftIcon from "images/b2c/landing-page/left-arrow.png";

import hero from "images/b2b/landing-page/hero.png";
import heroBig from "images/b2b/landing-page/hero_big.png";
import { useTranslation } from "b2c/hooks/useTranslation";
import { Button, Text, List, Label, Card, Title, Image } from "b2c/components/Core";
import Layout from "b2c/components/Layout";
import { icons } from "b2c/constants/pricing";
import { EliteSupport } from "../EliteSupport";
import { EliteFAQ } from "../EliteFAQ";
import { EliteDeliverables } from "../EliteDeliverables";
import { PricingWrapperElite, TitleWrapper } from "./styled";

const ElitePlan = ({ isSm, plan, backURL, openContactUsModal }) => {
  const { t } = useTranslation();

  const onModalOpenHandler = level => {
    openContactUsModal();
    const pricingData = {
      title: level.title,
      value: level.value,
      per: level.additional
    };
    localStorage.setItem("contactUsPricing", JSON.stringify(pricingData));
  };

  return (
    <Layout.Section style={{ backgroundColor: "white", overflow: isSm ? "hidden" : "auto" }}>
      <PricingWrapperElite>
        <Link
          to={{
            pathname: backURL,
            query: { scroll: isSm ? 2000 : 1400 }
          }}
          style={{
            position: "absolute",
            top: isSm ? "5px" : "50px",
            left: isSm ? "3px" : "0",
            zIndex: "1",
            cursor: "pointer"
          }}
        >
          <Image margin="15px" width={15} height={15} loadingType="eager" src={LeftIcon} alt="Left Icon" />
        </Link>
        <TitleWrapper>
          <Title
            loaded
            level="2"
            fontWeight={600}
            margin="0 auto 10px"
            textAlign="center"
            style={{ fontSize: isSm ? 20 : 36, display: "inline-block" }}
          >
            {t(plan.descr.title)}
          </Title>
        </TitleWrapper>
        <Text fontSize={18} lineHeight="24px" width="100%" textAlign="center" margin="0 0 18px">
          {`${t(plan.descr.tagline)}${plan.descr.disclaimer ? "." : ""}`} <b>{plan.descr.disclaimer}</b>
        </Text>
        <br />
        <Row style={{ marginTop: "40px" }}>
          {plan.descr.section.map((item, i) => {
            return (
              <Col key={i} md={4}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between"
                  }}
                >
                  <Image width={50} height={50} loadingType="eager" src={icons[i]} alt="Icon" />
                  <Title level={5} fontWeight={500} margin="0 0 0 15px">
                    {t(item.title)}
                  </Title>
                </div>
                <Row style={{ marginBottom: isSm ? " 40px" : "10px", marginTop: !isSm ? "40px" : "10px" }}>
                  <List>
                    {item.taglines.map(tagline => (
                      <List.Item key={tagline} margin="0 0 10px" lineHeight="20px" bulletColor="black500">
                        <Text fontSize="14px" color="black300">
                          {t(tagline)}
                        </Text>
                      </List.Item>
                    ))}
                  </List>
                </Row>
              </Col>
            );
          })}
        </Row>
        <Row style={{ marginTop: isSm ? "25px" : "50px" }}>
          <Col md={8}>
            <Card bordered style={{ padding: "0px" }}>
              <Card.Header style={{ flexDirection: "column" }} padding="10px 0 10px 20px">
                <Text fontWeight={600} fontSize={24}>
                  {t(plan.descr.eliteSection.title)}
                </Text>
                <Row>
                  <Col md={8}>
                    <Text fontSize={15} fontWeight={600} color="black300">
                      {t(plan.descr.eliteSection.tagline)}
                    </Text>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Separator height={1} margin="15px 0" />
              <Card.Content style={{ flex: "1 0 auto" }}>
                <List>
                  {plan.descr.eliteSection.descr.map(text => (
                    <List.Item
                      key={text}
                      margin="0 0 10px"
                      lineHeight="20px"
                      bulletSize={0}
                      style={{ display: "block" }}
                    >
                      <Label
                        Icon={
                          <Marker
                            style={{
                              marginRight: "15px"
                            }}
                          />
                        }
                        lineHeight={isSm ? "22px" : "26px"}
                        noBackground
                        color="black500"
                        fontSize={14}
                        fontWeight={400}
                        padding="0"
                        margin={isSm ? "0 0 5px" : "0 0 15px"}
                      >
                        {t(text)}
                      </Label>
                    </List.Item>
                  ))}
                </List>
              </Card.Content>
            </Card>
          </Col>
          <Col md={4}>
            <Card
              style={{
                height: "328px",
                backgroundColor: "#f3f3f3",
                padding: "35px",
                position: "relative",
                margin: isSm ? "20px 0" : "0"
              }}
              bordered
            >
              <Row>
                <Col md={8}>
                  <Row>
                    <Text fontSize="16px">{parse(t("pricing.elite_details.section.description"))}</Text>
                  </Row>
                </Col>
                <div
                  style={{
                    position: "absolute",
                    left: isSm ? "-15px" : "170px",
                    top: isSm ? "160px" : "36px"
                  }}
                >
                  <Image
                    margin={isSm ? "-40px 0 0 80px" : "41px 0 0 0"}
                    width={isSm ? 400 : 500}
                    height={isSm ? 185 : 230}
                    loadingType="eager"
                    src={isSm ? hero : heroBig}
                    alt="Elite hero"
                  />
                </div>
              </Row>
            </Card>
          </Col>
        </Row>
        <div style={{ position: "relative" }}>
          <Row style={{ flexWrap: "wrap" }}>
            {plan.descr.levelSection.map((level, index) => (
              <Col
                key={level.title}
                style={{
                  flex: "0 0 375px",
                  margin: isSm ? "0 auto" : "50px 0 40px",
                  padding: isSm ? "25px 0" : "0 10px",
                  borderBottom: isSm && plan.descr.levelSection.length !== index + 1 ? "1px solid #b9b9b9" : "none",
                  display: "flex",
                  flexDirection: "column"
                }}
              >
                <Title level={4} fontWeight={600} margin="0">
                  {t(level.title)}
                </Title>
                <Text
                  fontSize="14px"
                  color="black300"
                  style={{
                    margin: "0",
                    padding: "16px 0"
                  }}
                >
                  {t(level.tagline)}
                </Text>
                <div style={{ marginTop: "auto" }}>
                  <Title level={5} fontWeight={500} margin="0">
                    {t(level.value)}
                  </Title>
                  {level.additional && (
                    <Text fontSize="12px" color="black300" margin="3px 0 0 0">
                      {" "}
                      {t(level.additional)}
                    </Text>
                  )}
                </div>
                <Button
                  onClick={() => onModalOpenHandler(level)}
                  fontSize={18}
                  width="100%"
                  style={{
                    marginTop: "16px"
                  }}
                >
                  {t(level.buttonText)}
                </Button>
              </Col>
            ))}
          </Row>
        </div>
      </PricingWrapperElite>
      <EliteSupport isSm={isSm} />
      <EliteDeliverables />
      <EliteFAQ isSm={isSm} />
    </Layout.Section>
  );
};

export default ElitePlan;
