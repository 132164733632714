import React from "react";
import { Route, Switch } from "react-router-dom";

import { withTrackedRoute } from "../../hocs";
import CareerCoaching from "./components/CareerCoaching";
import { withAuthorizationRedirect } from "../../components/WithAuth";
import PremiumProductCore from "./components/PremiumProductCore";

const PremiumProductScreen = ({ match: { url } }) => {
  return (
    <Switch>
      <CareerCoaching countryCodes={["KE", "UG"]} exact path={`${url}/career-coaching`} />
      <Route exact path={`${url}/:slug`} component={PremiumProductCore} />
    </Switch>
  );
};

export default withTrackedRoute(withAuthorizationRedirect(PremiumProductScreen));
