import React, { useEffect } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import { useTranslation } from "b2c/hooks/useTranslation";

import { mainTabs, authorizedTabs, tabNames } from "b2c/constants/learning";
import { Suggestion } from "b2c/components/Core";
import Navigation from "b2c/components/TabNavigation";
import { withTrackedRoute } from "b2c/hocs";
import { GuestHidden } from "b2c/components/WithAuth";
import CourseScreen from "../CourseScreen";
import LearningTab from "./components/LearningTab";
import Home from "./components/Home";
import Search from "./components/Search";
import NotFound from "../NotFound";
import { useGetCurrentCountry } from "../../hooks";
import { isBaronaBrand } from "../../contexts/branded";

const Learning = ({ location: { pathname }, match: { url } }) => {
  const { t } = useTranslation();
  const currentCountry = useGetCurrentCountry();
  const isBarona = isBaronaBrand();
  const history = useHistory();

  const learnPaths = mainTabs
    .concat(authorizedTabs)
    .map(({ tab }) => `${url}/${tab}`)
    .concat("/learn/search/:id");

  const isSelectedTab = tab => {
    const isSearchTab = tab.includes(tabNames.search) && pathname.includes(tabNames.search);

    if (isSearchTab) {
      return true;
    }

    return pathname === `${url}${tab ? `/${tab}` : ""}`;
  };

  const renderTabs = tabs => {
    return tabs.map(({ name, tab }) => (
      <Navigation.Tab key={tab} selected={isSelectedTab(tab)} to={`${url}${tab ? `/${tab}` : ""}`}>
        {t(name)}
      </Navigation.Tab>
    ));
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    if (!isBarona && currentCountry.id !== "global") {
      history.push("/learn");
    }
  }, []);

  return (
    <>
      <Route exact path={learnPaths}>
        <Suggestion.Banner type="learn" margin="0 0 8px" />
        <Navigation innerLayoutSize="xl" margin="0">
          {renderTabs(mainTabs)}
          <GuestHidden>{renderTabs(authorizedTabs)}</GuestHidden>
        </Navigation>
      </Route>
      <Switch>
        <Home exact path={url} />
        <Search exact path={`${url}/search`} />
        <Search exact path={`${url}/search/:category`} />
        {authorizedTabs.map(({ tab }) => (
          <Route key={tab} exact path={`${url}/${tab}`}>
            <LearningTab slug={tab} />
          </Route>
        ))}
        <CourseScreen exact path={`${url}/:id`} />
        <NotFound />
      </Switch>
      <Route exact path={url}>
        <Suggestion.Email type="learn" />
      </Route>
    </>
  );
};

export default withTrackedRoute(Learning);
