import { Link } from "react-router-dom";
import React from "react";
import { Button, Card, Text } from "../../../../components/Core";

const HeroFirstStep = ({ setStep, isSm }) => {
  return (
    <Card style={{ height: "360px" }}>
      <div style={{ margin: `${isSm ? "10px" : "185px"} auto 0 auto`, padding: isSm ? "0 30px" : "0 80px" }}>
        <Text
          fontSize={isSm ? 28 : 48}
          fontWeight={700}
          lineHeight={isSm ? "32px" : "48px"}
          width="100%"
          margin="0 0 15px 0"
          textAlign="center"
          style={{
            fontFamily: "Poppins",
            fontStyle: "normal",
            textAlign: "center"
          }}
        >
          Celebrate your career hero
        </Text>
        <Text
          fontSize={isSm ? 18 : 20}
          fontWeight={500}
          lineHeight={isSm ? "24px" : "28px"}
          width="100%"
          margin="20px 0 0 0"
          textAlign="center"
          style={{
            fontFamily: "Poppins",
            fontStyle: "normal",
            lineHeight: "24px",
            textAlign: "center"
          }}
        >
          Say thank you to someone who believed in you, inspired you, or provided valuable support <br />
          at a critical stage of your career.
        </Text>
        <div style={{ textAlign: "center" }}>
          <Button
            onClick={() => setStep(2)}
            type="button"
            fontSize="14px"
            margin={isSm ? "15px 0 0 0" : "40px 0 0 0"}
            width="304px"
          >
            Start
          </Button>
        </div>
        <div style={{ textAlign: "center", marginTop: isSm ? "20px" : "91px" }}>
          <Link to="/nigeria" as="a">
            Go to Fuzu
          </Link>
        </div>
      </div>
    </Card>
  );
};

export default HeroFirstStep;
