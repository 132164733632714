import React from "react";
import { useLocation, useRouteMatch } from "react-router-dom";
import parse from "html-react-parser";

import { useTranslation } from "../../hooks/useTranslation";
import Layout from "../Layout";
import { Title, Text, Checkbox, Button } from "../Core";
import PremiumBanner from "../PremiumBanner";
import useStore from "../../contexts/store";
import { isBaronaBrand } from "../../contexts/branded";

const ApplicationFlowPromo = ({ id, type = "instant_feedback", submitted, close }) => {
  const isBarona = isBaronaBrand();

  const { track } = useStore("Tracking");
  const { pathname } = useLocation();
  const { path } = useRouteMatch();
  const { t } = useTranslation();

  const { title, points, buttonText, refusal, to } = (() => {
    switch (type) {
      default:
      case "instant_feedback":
        return {
          title: parse(t("promo.instant_feedback.title")),
          points: [
            t("promo.instant_feedback.description_1"),
            t("promo.instant_feedback.description_2"),
            t("promo.instant_feedback.description_3")
          ],
          buttonText: t("promo.instant_feedback.button_text"),
          refusal: t("promo.instant_feedback.label"),
          to: {
            pathname: `/jobs/${id}/product/position-specific-application-booster`,
            state: { discounted: true, from: pathname }
          }
        };
      case "career_coaching":
        return {
          title: parse(t("promo.career_coach.title")),
          points: [
            t("promo.career_coach.description_1"),
            t("promo.career_coach.description_2"),
            t("promo.career_coach.description_3")
          ],
          buttonText: t("promo.career_coach.button_text"),
          refusal: t("promo.career_coach.refusal"),
          to: {
            pathname: "/premium/career-coaching",
            state: { from: pathname }
          }
        };
    }
  })();

  const handleClick = () => {
    track(`${type}_promo_clicked`, { match_path: path });
    close();
  };

  return (
    <>
      {submitted && !isBarona && (
        <>
          <Title margin="-24px auto 0" level={5} fontWeight={600} textAlign="center">
            {t("application.success.modal.description_1")}
          </Title>
          <Text margin="24px auto" textAlign="center">
            {t("application.success.modal.description_2")}
            <br />
            {t("application.success.modal.description_3")}
          </Text>
        </>
      )}
      <PremiumBanner
        title=""
        height="auto"
        smHeight="auto"
        type={type}
        Button={null}
        additional={
          <>
            <Title color="white" margin="16px auto" level={5} fontWeight={600} textAlign="center">
              {title}
            </Title>
            <Layout.Section margin="0 auto">
              {points.map((item, i) => (
                <Checkbox
                  key={`point-${i}`}
                  checked
                  readOnly
                  checkedColor="additional200"
                  style={{ pointerEvents: "none" }}
                  label={item}
                />
              ))}
            </Layout.Section>
            <Button.Link
              onClick={handleClick}
              to={to}
              theme="additional200"
              fontWeight={600}
              width={270}
              margin="16px auto"
            >
              {buttonText}
            </Button.Link>
          </>
        }
      />
      <Text margin="16px auto 0" onClick={close}>
        {`${t("promo.no_thanks")} `} <span className="link underlined">{refusal}</span>
      </Text>
    </>
  );
};

export default ApplicationFlowPromo;
