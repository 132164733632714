import React, { useState, useContext, useCallback, useRef, useEffect } from "react";
import { useScreenClass } from "react-grid-system";

import { highlightField, debounce } from "utils/helpers";
import { usePreventSubmission } from "utils/hooks";
import { Input, Text } from "../Core";
import Styled from "./styled";
import ThemeContext from "../../contexts/theme";
import useStore from "../../contexts/store";

const JobInput = ({ inputProps }) => {
  const inputRef = useRef();
  const isSm = /xs|sm/.test(useScreenClass());
  const { matchingJobClusters, getMatchingJobClusters } = useStore("Onboarding");
  const theme = useContext(ThemeContext);
  const [focused, setFocused] = useState(false);
  const [applied, setApplied] = useState(false);
  const [field, setField] = useState("");
  const [jobId, setJobId] = useState(null);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    const onboardingForm = JSON.parse(localStorage.getItem("onboardingForm"));
    const jobCluster = onboardingForm?.job_cluster || {};
    if (jobCluster.title) {
      setField(jobCluster.title);
    } else if (jobCluster?.job_cluster_id) {
      const title = localStorage.getItem("onboardingJobTitle");

      if (title && title.length > 2) {
        getJobsDebounced(title);
      }
    }
  }, []);

  useEffect(() => {
    if (matchingJobClusters && matchingJobClusters.length) {
      const onboardingForm = JSON.parse(localStorage.getItem("onboardingForm"));
      const jobCluster = onboardingForm?.job_cluster || {};
      if (jobCluster?.job_cluster_id) {
        const cluster = matchingJobClusters.find(({ id }) => id == jobCluster?.job_cluster_id);
        const title = localStorage.getItem("onboardingJobTitle");

        if (title && title.length > 2) {
          setJobId(cluster?.id);
          setField(cluster?.title);
        }
      }
      const equalToTerm = matchingJobClusters.find(({ equal_to_term }) => Boolean(equal_to_term));
      if (equalToTerm) {
        setJobId(equalToTerm?.id);
        setField(equalToTerm?.title);
      }
    }
  }, [matchingJobClusters]);

  const getJobsDebounced = useCallback(
    debounce(term => {
      setProcessing(true);
      getMatchingJobClusters(term)
        .then(() => {
          setProcessing(false);
        })
        .catch(() => {
          setProcessing(false);
        });
    }, 750),
    []
  );

  usePreventSubmission(focused);

  const handleJobClusterSelect = ({ id, title }) => {
    localStorage.setItem("onboardingJobTitle", field);
    setField(title);
    setJobId(id);
    setApplied(true);
    if (isSm) setFocused(false);
    else inputRef.current?.blur();
  };

  const handleInput = value => {
    setField(value);
    localStorage.removeItem("onboardingJobTitle");
    setApplied(false);
    if (value.length > 2) getJobsDebounced(value);
    if (jobId) setJobId(null);
  };

  const handleAutoSelect = e => {
    if (String(e.keyCode) === "13") {
      setFocused(false);
    }
  };

  const isMenuVisible = focused && field?.length >= 3;

  return (
    <Styled.Wrapper>
      <div style={{ position: "relative" }}>
        <Input
          margin="0"
          ref={inputRef}
          id={jobId || "not-selected"}
          placeholder="eg. Store manager"
          name="jobInput"
          value={field}
          onFocus={({ target }) => {
            target.select();
            setFocused(true);
          }}
          onBlur={() => {
            if (!isSm) setFocused(false);
          }}
          onChange={({ target: { value } }) => handleInput(value)}
          onKeyDown={handleAutoSelect}
          autoComplete="off"
          borderColor={applied ? "secondary200" : undefined}
          backgroundColor={applied ? "secondary100" : undefined}
          {...inputProps}
        />
        {processing && <Styled.ProcessingState size="24px" />}
      </div>
      {isMenuVisible && (
        <Styled.InlineMenu theme={theme}>
          {matchingJobClusters.map(job_cluster => {
            return (
              <React.Fragment key={job_cluster?.id}>
                <Styled.Item
                  theme={theme}
                  onMouseDown={e => e.preventDefault()}
                  onClick={() => handleJobClusterSelect(job_cluster)}
                >
                  <Text fontWeight={isSm ? 400 : 500}>{highlightField(job_cluster.title, field)}</Text>
                </Styled.Item>
              </React.Fragment>
            );
          })}
        </Styled.InlineMenu>
      )}
    </Styled.Wrapper>
  );
};

export default JobInput;
