import React, { useState, useContext } from "react";
import { observer } from "mobx-react";
import { Link, useHistory } from "react-router-dom";

import { validateLogin } from "utils/validation";
import { onEnterPress } from "utils/helpers";
import signUpClose from "images/signup-close.png";
import aggressiveMessage from "images/aggressive-message.png";
import clockAggressive from "images/clock-aggressive.png";
import searchAggressive from "images/search-aggressive.png";
import frameAggressive from "images/frame-aggressive.png";
import MapIcon from "images/b2c/landing-page/bullets/map.svg";
import Clock from "images/b2c/landing-page/bullets/clock.svg";
import Glass from "images/b2c/landing-page/bullets/glass.svg";
import { ScreenClassContext } from "react-grid-system";
import useStore from "../../contexts/store";
import { Title, Text, Input, Button, Divider, Icon, Image } from "../Core";
import BrandSide from "./BrandSide";
import { AggressiveLoginModalWrapper, LoginModalWrapper } from "./styled";
import { withTracking } from "../../hocs";
import SocialAuthorization from "../SocialAuthorization";
import BrandedContext from "../../contexts/branded";
import OnboardingButton from "../OnboardingFlow/OnboardingButton";
import { useIsBarona } from "../../hooks";

const defaultValidation = {
  email: { error: false },
  password: { error: false }
};

const ModalContent = ({ close, from, type, onAuthorization, aggressive, text }) => {
  const {
    state,
    Authorization: { userLogin }
  } = useStore("User");
  const screen = useContext(ScreenClassContext);
  const isSm = ["xs", "sm"].includes(screen);
  const { location } = useHistory();

  const [login, switchToLogin] = useState(false);
  const isBarona = useIsBarona();

  return aggressive && !login ? (
    <AggressiveLoginModalWrapper>
      <Image
        src={signUpClose}
        onClick={close}
        alt="close"
        style={{
          position: "absolute",
          cursor: "pointer",
          top: "16px",
          right: "16px"
        }}
      />

      <Image src={frameAggressive} height={isSm ? 225 : 300} alt="Frame Aggressive" />

      <div style={{ padding: isSm ? "0px 20px 20px 20px" : "20px" }}>
        <div>
          <Text
            fontSize={isSm ? 20 : 32}
            fontWeight={600}
            width="100%"
            textAlign="center"
            style={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              textAlign: "start"
            }}
          >
            {text || "Join Fuzu to continue browsing for more jobs!"}
          </Text>
        </div>
        <div
          style={{
            margin: "10px 0"
          }}
        >
          <Image
            src={searchAggressive}
            alt="Search Aggressive"
            style={{
              position: "relative",
              top: "5px"
            }}
          />
          <Text
            fontSize={16}
            width="100%"
            margin="0 0 0 8px"
            textAlign="start"
            style={{
              display: "inline",
              fontFamily: "Poppins",
              fontStyle: "normal",
              textAlign: "start"
            }}
          >
            All opportunities in one place – 10,000+ new jobs every month
          </Text>
        </div>
        <div
          style={{
            margin: "10px 0"
          }}
        >
          <Image
            src={aggressiveMessage}
            alt="message Aggressive"
            style={{
              position: "relative",
              top: "5px"
            }}
          />
          <Text
            fontSize={16}
            width="100%"
            margin="0 0 0 8px"
            textAlign="start"
            style={{
              display: "inline",
              fontFamily: "Poppins",
              fontStyle: "normal",
              textAlign: "start"
            }}
          >
            Never miss an opportunity – Get access to personalised job alerts, tools and support
          </Text>
        </div>
        <div
          style={{
            margin: "10px 0"
          }}
        >
          <Image
            src={clockAggressive}
            alt="Clock Aggressive"
            style={{
              position: "relative",
              top: "5px"
            }}
          />
          <Text
            fontSize={16}
            width="100%"
            margin="0 0 0 8px"
            textAlign="start"
            style={{
              display: "inline",
              fontFamily: "Poppins",
              fontStyle: "normal",
              textAlign: "start"
            }}
          >
            Save time – Apply for jobs in minutes
          </Text>
        </div>
        <Button.Signup
          arrow
          state={{ from: from || location?.pathname + location?.search }}
          $trackParams={{ type }}
          width="100%"
          margin="18px 0"
          onClick={close}
        >
          Get Started
        </Button.Signup>
        <Button as={Link} to="/login" width="100%" onClick={close} margin="0 0 auto" style={{ background: "#f3f3f3" }}>
          I already have a Fuzu account
        </Button>
      </div>
    </AggressiveLoginModalWrapper>
  ) : (
    <LoginModalWrapper data-cy="authorization-modal">
      <BrandSide type={type} />
      {!login ? (
        <div className="menu-side">
          <Text fontSize={20} fontWeight={700} margin="auto auto 14px">
            New user?
          </Text>

          <Button.Signup
            state={{ from: from || location?.pathname + location?.search }}
            $trackParams={{ type }}
            width="100%"
            onClick={close}
          >
            Create a free account
          </Button.Signup>

          <Divider margin="28px 0 18px" />

          <Text fontSize={20} fontWeight={700} margin="0 auto 14px">
            Do you have an account?
          </Text>

          <Button width="100%" onClick={() => switchToLogin(true)} margin="0 0 auto">
            Log in with email
          </Button>

          {!isBarona && <SocialAuthorization modal close={close} />}
        </div>
      ) : (
        <LoginContent
          close={close}
          state={state}
          from={from || location?.pathname + location?.search}
          userLogin={userLogin}
          onAuthorization={onAuthorization}
        />
      )}
    </LoginModalWrapper>
  );
};

export const OnboardingContent = observer(({ close, from, type, onAuthorization }) => {
  const {
    state,
    Authorization: { userLogin }
  } = useStore("User");
  const { location } = useHistory();
  const [login, switchToLogin] = useState(false);

  return (
    <LoginModalWrapper data-cy="authorization-modal">
      <BrandSide type={type} isonboarding />
      {!login ? (
        <div className="menu-side">
          <Title level={3} fontWeight={600} margin="0 0 8px">
            Apply with a free Fuzu account
          </Title>
          <Text margin="4px 0" lineHeight={2} style={{ display: "flex" }}>
            <Icon as={Glass} width={20} margin="0 12px 0 0" />
            Get personalized job alerts
          </Text>
          <Text margin="4px 0" lineHeight={2} style={{ display: "flex" }}>
            <Icon as={Clock} width={20} margin="0 12px 0 0" />
            Apply for work in minutes
          </Text>
          <Text margin="4px 0 12px" lineHeight={2} style={{ display: "flex" }}>
            <Icon as={MapIcon} width={20} margin="0 12px 0 0" />
            Receive feedback on your applications
          </Text>
          <OnboardingButton flow="ic" from={from} width="100%" arrow margin="auto 0 12px" onClick={close}>
            Create a free account
          </OnboardingButton>
          <Button width="100%" onClick={() => switchToLogin(true)} theme="grey100" margin="0">
            I already have a Fuzu account
          </Button>
        </div>
      ) : (
        <LoginContent
          close={close}
          state={state}
          from={from || location?.pathname + location?.search}
          userLogin={userLogin}
          onAuthorization={onAuthorization}
        />
      )}
    </LoginModalWrapper>
  );
});

const LoginContent = withTracking(
  ({ close, state: { processing, message }, from, userLogin, onAuthorization }) => {
    const { name } = useContext(BrandedContext);
    const [formState, setFormState] = useState({
      email: "",
      password: "",
      remember_me: true
    });

    const [validationState, setValidation] = useState(defaultValidation);

    const handleSubmission = e => {
      e.preventDefault();
      setValidation(defaultValidation);

      const { valid, validation } = validateLogin(formState);

      return userLogin(valid, formState, { context: "login_prompt" }).then(success => {
        setValidation(validation);
        if (success && onAuthorization) {
          onAuthorization();
          close();
          // window.location.reload();
        }
      });
    };

    return (
      <div className="menu-side">
        <Text fontSize={18} fontWeight={600} margin="0 0 24px">
          Welcome back to {name}!
        </Text>
        <form onSubmit={handleSubmission} onKeyDown={e => onEnterPress(e, 1, () => handleSubmission(e))} noValidate>
          <Input
            label="Email address"
            placeholder="example@domain.com"
            type="email"
            autoComplete="email"
            value={formState?.email}
            onChange={({ target: { value } }) => setFormState(prev => ({ ...prev, email: value }))}
            errorMessage={validationState?.email?.error}
          />

          <Input
            label="Your password"
            placeholder="Enter your password"
            type="password"
            value={formState.password}
            onChange={({ target: { value } }) => setFormState(prev => ({ ...prev, password: value }))}
            errorMessage={validationState?.password?.error}
          />

          {message && (
            <Text color="accent300" margin="4px 0 0">
              {message}
            </Text>
          )}

          <Button type="submit" fontSize="14px" margin="10px 0" width="100%" processing={processing}>
            Login
          </Button>

          <Text lineHeight="140%" margin="8px 0 0" color="black300">
            Forgot your password?{" "}
            <Link className="link" to={{ pathname: "/reset", state: { from } }} onClick={close}>
              Reset password
            </Link>
          </Text>
        </form>
      </div>
    );
  },
  "login_flow_started",
  { context: "login_prompt" }
);

export default observer(ModalContent);
