import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Redirect } from "react-router-dom";
import { useTranslation } from "b2c/hooks/useTranslation";

import InfoSection from "b2c/components/InfoSection";
import { withTrackedRoute } from "../../hocs";
import Layout from "../../components/Layout";
import { Title, Text } from "../../components/Core";
import { useDocumentTitle } from "../../../utils/hooks";
import Chatline from "../../components/Chatline";
import { ContentQuestions } from "./styled";
import { useIsBarona } from "../../hooks";

const WorkingInTheNordics = () => {
  const isSm = /xs|sm|md/.test(screen);
  const isBarona = useIsBarona();
  const { t } = useTranslation();
  const faqWorkingInNordics = [
    {
      id: 1,
      title: t("working_in_the_nordics.sections.title_1"),
      sections: [
        {
          id: 1,
          title: t("working_in_the_nordics.sections.sub-title_1_1"),
          text: t("working_in_the_nordics.sections.description_1_1")
        },
        {
          id: 2,
          title: t("working_in_the_nordics.sections.sub-title_1_2"),
          text: t("working_in_the_nordics.sections.description_1_2")
        },
        {
          id: 3,
          title: t("working_in_the_nordics.sections.sub-title_1_3"),
          text: t("working_in_the_nordics.sections.description_1_3")
        },
        {
          id: 4,
          title: t("working_in_the_nordics.sections.sub-title_1_4"),
          text: t("working_in_the_nordics.sections.description_1_4")
        },
        {
          id: 5,
          title: t("working_in_the_nordics.sections.sub-title_1_5"),
          text: t("working_in_the_nordics.sections.description_1_5")
        }
      ]
    },
    {
      id: 2,
      title: t("working_in_the_nordics.sections.title_2"),
      sections: [
        {
          id: 5,
          title: t("working_in_the_nordics.sections.sub-title_2_1"),
          text: t("working_in_the_nordics.sections.description_2_1")
        },
        {
          id: 6,
          title: t("working_in_the_nordics.sections.sub-title_2_2"),
          text: t("working_in_the_nordics.sections.description_2_2")
        },
        {
          id: 7,
          title: t("working_in_the_nordics.sections.sub-title_2_3"),
          text: t("working_in_the_nordics.sections.description_2_3")
        },
        {
          id: 8,
          title: t("working_in_the_nordics.sections.sub-title_2_4"),
          text: t("working_in_the_nordics.sections.description_2_4")
        }
      ]
    },
    {
      id: 3,
      title: t("working_in_the_nordics.sections.title_3"),
      sections: [
        {
          id: 1,
          title: t("working_in_the_nordics.sections.sub-title_3_1"),
          text: t("working_in_the_nordics.sections.description_3_1")
        },
        {
          id: 2,
          title: t("working_in_the_nordics.sections.sub-title_3_2"),
          text: `
          ${t("working_in_the_nordics.sections.description_3_2_1")}:
         <br/><br/>
          <ul>
            <li>
              ${t("working_in_the_nordics.sections.description_3_2_2")}
            </li>
            <li>${t("working_in_the_nordics.sections.description_3_2_3")}
            </li>
            <li>${t("working_in_the_nordics.sections.description_3_2_4")}
            </li>
          </ul>
          `
        },
        {
          id: 3,
          title: t("working_in_the_nordics.sections.sub-title_3_3"),
          text: t("working_in_the_nordics.sections.description_3_3")
        },
        {
          id: 4,
          title: t("working_in_the_nordics.sections.sub-title_3_4"),
          text: t("working_in_the_nordics.sections.description_3_4")
        },
        {
          id: 5,
          title: t("working_in_the_nordics.sections.sub-title_3_5"),
          text: `
            ${t("working_in_the_nordics.sections.description_3_5_1")}           
            <br/><br/>
            ${t("working_in_the_nordics.sections.description_3_5_2")}                     `
        }
      ]
    }
  ];

  useDocumentTitle(t("working_in_the_nordics.meta_title"));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {isBarona ? (
        <Layout.Content size="xl" style={{ margin: isSm ? "24px auto 0" : "64px auto 0" }}>
          <Title level="2" fontWeight="600">
            {t("working_in_the_nordics.title")}
          </Title>
          <Text fontSize={16} margin="8px 0 20px 0">
            {t("working_in_the_nordics.description")}
          </Text>
          <ContentQuestions>
            <InfoSection data={faqWorkingInNordics} />
          </ContentQuestions>
          <Chatline />
        </Layout.Content>
      ) : (
        <Redirect to="/" />
      )}
    </>
  );
};

export default withTrackedRoute(observer(WorkingInTheNordics));
