import styled from "styled-components";
import { Link } from "react-router-dom";

export const DetailSection = styled.div`
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
  margin-bottom: 16px;

  @media screen and (min-width: 648px) {
    flex-direction: column;
    gap: 2px;
  }
`;

export const Label = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
`;

export const Value = styled(Link)`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #408bfc;
  display: inline-block;
  text-align: right;
  display: flex;
  gap: 2px;
`;

export const ValueAnchor = styled.a`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #408bfc;
  display: inline-block;
  text-align: right;
  display: flex;
  gap: 2px;
`;

export const ValueBlue = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #408bfc;
  display: inline-block;
  text-align: right;
  display: flex;
  gap: 2px;
`;

export const ValueText = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #666666;
`;

export const DescriptionText = styled.p`
  font-weight: 400;
  font-size: 14px;
  white-space: pre-wrap;
  line-height: 140%;
  display: inline;
`;
