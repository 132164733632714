import styled from "styled-components";

export const ShowMore = styled.div`
  ${() => `
      padding: 12px 0;
  `}
  .show-more__text {
    font-size: 10px;
    line-height: 15px;
    color: #656565;
    span {
      font-weight: 600;
    }
  }
`;

export const CountryContent = styled.div`
  height: 100%;
  transition: all 0.3s ease;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const ShowAllCountriesTitle = styled.h6`
  color: #666;
  font-size: 14px;
  font-weight: 600;
  line-height: 120%;
  margin: 24px 0 0 0;
  display: block;
  padding-top: 24px;
  border-top: 1px solid #c5c9d1;
`;

export const ShowAllCountriesContent = styled.div`
  max-height: 380px;
  transition: all 0.3s ease;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

export const SearchIcon = styled.span`
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);

  svg {
    fill: #666666;
    width: 16px;
    height: 16px;
  }
`;

export const CloseIcon = styled.button`
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  background-color: transparent;
  border: 0;
  outline: none;
  padding: 0;
  cursor: pointer;

  svg {
    fill: #666666;
    width: 16px;
    height: 16px;
  }
`;

export const SearchInputContainer = styled.div`
  position: relative;
`;

export const SearchInput = styled.input`
  border-radius: 5px;
  border: 1px solid #408bfc;
  background: #fff;
  padding: 8px 40px;
  margin: 16px 0;
  width: 100%;

  &:focus {
    border-color: #4690ff;
  }
`;

export const NotFound = styled.span`
  color: #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-bottom: 8px;
`;
