import styled from "styled-components";

export const SpeciesJobsContent = styled.div`
  border-top: 1px solid #c5c5c5;
  padding: 16px 16px 80px 16px;

  @media screen and (min-width: 768px) {
    padding: 25px 0 0 0;
  }
`;

export const SpeciesJobsFooter = styled.div`
  display: flex;
  padding: 0;
  margin: 8px 0 20px 0;
`;

export const FuzuYear = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #656565;
  text-align: center;
`;

export const SpeciesJobsCountries = styled.div`
  display: flex;
  padding: 0;
  margin: 8px 0 20px 0;

  a span {
    border-right: 1px solid #ccc;
  }

  a:first-child span {
    padding-left: 0;
  }

  a:last-child span {
    border-right: none;
  }
`;
