import React, { useContext } from "react";
import { Play } from "@styled-icons/boxicons-regular";
import { Row, Col, ScreenClassContext, Hidden, Visible } from "react-grid-system";

import heroImageB2B from "images/b2b/landing-page/heroImage.png";
import { useTranslation } from "b2c/hooks/useTranslation";
import { useModal } from "utils/hooks";
import Layout from "b2c/components/Layout";
import { Title, Text, Image, Icon, Button, Video } from "b2c/components/Core";
import { MobileAction } from "./styled";
import useStore from "../../../../contexts/store";

const HeroSection = ({ employers, actions }) => {
  const screen = useContext(ScreenClassContext);
  const isSm = ["xs", "sm"].includes(screen);
  const { t } = useTranslation();

  const openVideoModal = useModal(
    <Video
      embed
      src="https://www.youtube.com/embed/-UaOfm6ie3M?controls=0&autoplay=1"
      autoPlay
      style={{ minWidth: "50vw", minHeight: isSm ? 0 : 580 }}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullscreen
    />,
    t("employers.how_fuzu_works")
  );

  const { title, text, imageUrl } = {
    title: t("employers.hero_title"),
    text: t("employers.hero_description"),
    imageUrl: heroImageB2B
  };

  const {
    context: { h1_title: h1Title }
  } = useStore("initialState");

  const modifiedTitle = (() => {
    const arr = title?.split("/");
    arr?.splice(1, 0, <br key="br" />);
    return arr;
  })();

  const sectionStyles = {
    backgroundImage: `${employers ? "" : ""} ${rightGradient}, ${leftGradient}`,
    backgroundRepeat: "no-repeat",
    backgroundSize: `${isSm ? "contain" : "auto 100%"}, ${isSm ? "100%" : "50%"} auto, ${isSm ? "0%" : "50%"} auto`,
    backgroundPosition: `center ${isSm ? "bottom 0" : "center"}, right 0, left 0`,
    position: "relative",
    height: employers ? "auto" : isSm ? 332 : 378
  };

  return (
    <Layout.Section style={sectionStyles}>
      <Layout.Content tag="div" style={employers ? { padding: "0 20px 30px 20px" } : {}}>
        <Row align="center" nogutter>
          <Col md={employers ? 5 : 8}>
            <Title
              loaded
              level={1}
              fontWeight={800}
              margin="25px 0 0"
              width="100%"
              textAlign={isSm ? "center" : "left"}
              style={{ fontSize: isSm ? 26 : 48 }}
            >
              {h1Title || modifiedTitle}
            </Title>
            {employers ? (
              <>
                <Text fontSize={isSm ? 16 : 20} textAlign={isSm ? "center" : "left"} loaded margin="10px 0 15px">
                  {text}
                </Text>
                <Hidden xs sm>
                  {actions}
                </Hidden>
              </>
            ) : (
              <>
                <Hidden xs sm>
                  <Text fontSize={20} loaded margin="10px 0 15px" style={{ maxWidth: "410px" }}>
                    {text}
                  </Text>
                </Hidden>
                <MobileAction $styled={{ textAlign: isSm ? "center" : "left" }}>{actions}</MobileAction>
              </>
            )}
          </Col>
          <Col md={7} style={{ alignSelf: "flex-end" }}>
            {employers && (
              <>
                <Image
                  src={imageUrl}
                  display="flex"
                  loadingType="eager"
                  alt="Hero Image"
                  {...(employers
                    ? {
                        margin: "20px auto",
                        width: isSm ? 320 : 700,
                        height: isSm ? 190 : 415
                      }
                    : {
                        width: isSm ? 320 : 680,
                        height: isSm ? 177 : 377,
                        margin: "0 auto"
                      })}
                />
                <Button
                  noBackground
                  color="black500"
                  fontSize={18}
                  style={{
                    position: "absolute",
                    textDecoration: "underline",
                    bottom: 0,
                    right: "50%",
                    transform: "translateX(50%)"
                  }}
                  onClick={openVideoModal}
                >
                  <Icon
                    as={Play}
                    width={18}
                    padding="2px"
                    margin="auto 8px auto 0"
                    fill="primary300"
                    additionalCss={playIconStyles}
                  />
                  {t("employers.video_how_fuzu_works")}
                </Button>
              </>
            )}
          </Col>
          {employers && (
            <Visible xs sm>
              <Col md={7} style={{ alignSelf: "flex-end", marginTop: 24 }}>
                {actions}
              </Col>
            </Visible>
          )}
        </Row>
      </Layout.Content>
    </Layout.Section>
  );
};

const playIconStyles = `
        background: #EB3737;
        border-radius: 50%;
    `;

const rightGradient = "linear-gradient(#fcd02f 0%, #fcd02f 100%, #fff 80.1%, #fff 100%)";

const leftGradient = "linear-gradient(#f7bc16 0%, #f7bc16 85%, #fff 85%, #fff 100%)";

export default HeroSection;
