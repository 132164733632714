import React from "react";
import { Col, Row } from "react-grid-system";
import { LockAlt } from "@styled-icons/boxicons-solid/LockAlt";

import { Button, Text } from "../../../../components/Core";

const PaymentActions = ({ processing, disabled, onSubmit, cancelTransaction }) => {
  return (
    <Row className="method-actions" justify="end">
      <Col sm={3} xs={6}>
        <Button type="button" theme="grey200" width="100%" processing={processing} onClick={cancelTransaction}>
          Cancel
        </Button>
      </Col>
      <Col sm={4} xs={6}>
        <Button width="100%" disabled={disabled} processing={processing} onClick={onSubmit}>
          <LockAlt width={16} />
          <Text inline margin="0 0 0 6px" fontSize={16} fontWeight={500}>
            Pay Now
          </Text>
        </Button>
      </Col>
    </Row>
  );
};

export default PaymentActions;
