import React, { useContext } from "react";

import Label from "../Core/Label";
import { TabWrapper, TabContent, TabScrollContent, TabItem, TabPanel } from "./styled";

import ThemeContext from "../../contexts/theme";

const Tabs = ({
  children,
  style,
  theme = "white",
  borderRadius = "5px",
  margin = "0 0 10px",
  padding = "0 20px",
  ...props
}) => {
  const Theme = useContext(ThemeContext);

  return (
    <>
      <TabWrapper
        $styled={{
          borderRadius,
          backgroundColor: Theme[theme],
          margin,
          padding,
          ...style
        }}
        {...props}
      >
        <TabContent role="tablist">
          <TabScrollContent $theme={Theme[theme]}>{children}</TabScrollContent>
        </TabContent>
      </TabWrapper>
    </>
  );
};

Tabs.Tab = ({
  color = "black200",
  selectedColor = "secondary300",
  focusColor = "secondary100",
  theme = "white",
  children,
  selected,
  style,
  count = 0,
  to,
  ...props
}) => {
  const Theme = useContext(ThemeContext);

  return (
    <TabItem
      $color={Theme[color]}
      $altcolor={Theme[selectedColor]}
      $focusColor={Theme[focusColor]}
      $theme={Theme[theme]}
      selected={selected}
      $styled={style}
      role="tab"
      tabIndex="0"
      aria-selected={selected}
      {...props}
    >
      {children}
      {count > 0 && <Label theme="secondary300">{count}</Label>}
    </TabItem>
  );
};

Tabs.TabPanel = ({ style, active, children }) => {
  return active ? (
    <TabPanel className="fadeInFromBottom" $styled={style}>
      {children}
    </TabPanel>
  ) : null;
};

export default Tabs;
