import styled from "styled-components";

export const FuzuAndroidAppPromoStyled = styled.div`
  background: #333333;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  margin-bottom: ${({ $isJob }) => ($isJob ? "15px" : "0")};
`;

export const LogoContent = styled.div`
  margin: 0 0 0 auto;
  flex: none;

  svg {
    width: 100%;
    height: 100%;
  }

  @media screen and (min-width: 768px) {
    width: ${({ $isJob }) => ($isJob ? "56px" : "64px")};
    height: ${({ $isJob }) => ($isJob ? "56px" : "64px")};
    flex: none;
  }
`;
