import React, { useEffect, forwardRef, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react";

import { useInitialFocus, useQueryParams, useQueryUtils } from "utils/hooks";

import useStore from "b2c/contexts/store";

import NotificationsList from "./components/NotificationsList";
import EmptyNotificationsList from "./components/EmptyNotificationsList";
import NestedNotificationsList from "./components/NestedNotificationsList";

import { StyledRoot, StyledContent, StyledOverlay } from "./styled";
import { Spinner } from "../Core";

export const useNotificationsCenterNav = () => {
  const { replace } = useHistory();
  const { setParams, unsetParams, getPathWithParams } = useQueryUtils();
  const { notificationsOpened, notificationId } = useQueryParams();

  const isOpened = notificationsOpened !== undefined;

  const open = useCallback(
    withNotificationId => {
      let nextQueryParams = setParams({ notificationsOpened: true, notificationId: withNotificationId });

      if (withNotificationId === undefined) {
        nextQueryParams = unsetParams(["notificationId"], nextQueryParams);
      }

      const nextPath = getPathWithParams(nextQueryParams);
      replace(nextPath);
    },
    [setParams, unsetParams, getPathWithParams]
  );

  const close = useCallback(() => {
    const nextQueryParams = unsetParams(["notificationsOpened", "notificationId"]);
    const nextPath = getPathWithParams(nextQueryParams);
    replace(nextPath);
  }, [unsetParams, getPathWithParams]);

  return {
    notificationId,
    isOpened,
    open,
    close
  };
};

const useNotificationsCenter = ref => {
  const { Notifications, user } = useStore("User");
  const {
    notifications,
    getNotificationsList,
    state: { loading: isLoading }
  } = Notifications;
  const { notificationId, isOpened, open, close } = useNotificationsCenterNav();

  useInitialFocus(ref);

  const closeHandler = () => {
    close();
  };

  const backwardHandler = () => {
    open();
  };

  useEffect(() => {
    const onPressEscape = event => {
      if (event.keyCode === 27) {
        closeHandler();
      }
    };

    document.addEventListener("keydown", onPressEscape);

    return () => {
      document.removeEventListener("keydown", onPressEscape);
    };
  }, []);

  useEffect(() => {
    document.body.style.overflow = isOpened ? "hidden" : "unset";

    if (user) {
      getNotificationsList();
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpened]);

  return {
    isOpened,
    isLoading,
    notifications,
    notificationId,
    closeHandler,
    backwardHandler
  };
};

const NotificationsCenter = forwardRef((props, ref) => {
  const { isOpened, isLoading, notifications, notificationId, closeHandler, backwardHandler } =
    useNotificationsCenter(ref);

  if (!isOpened) {
    return null;
  }

  let LayoutComponent;

  if (!notifications.list.length) {
    LayoutComponent = EmptyNotificationsList;
  } else {
    LayoutComponent = notificationId === undefined ? NotificationsList : NestedNotificationsList;
  }

  return (
    <StyledRoot ref={ref} onClick={event => event.stopPropagation()} aria-modal="true" role="dialog">
      <StyledOverlay onClick={closeHandler} />

      <StyledContent>
        {isLoading ? (
          <Spinner size="50px" margin="auto" />
        ) : (
          <LayoutComponent
            isLoading={isLoading}
            notificationId={notificationId}
            notifications={notifications}
            onBackward={backwardHandler}
            onClose={closeHandler}
          />
        )}
      </StyledContent>
    </StyledRoot>
  );
});

export default observer(NotificationsCenter);
