import styled from "styled-components";

export const Line = styled.div`
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px;
  background-color: #b9b9b9;

  @media screen and (max-width: 1200px) {
    display: none;
  }
`;

export const PricingWrapperElite = styled.section`
  max-width: 1160px;
  padding: 50px 20px 30px 20px;
  width: 100%;
  margin: 0 auto;
  position: relative;
`;

export const TitleWrapper = styled.section`
  margin: 0 auto;
  text-align: center;
`;
