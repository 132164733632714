import React, { useLayoutEffect, useContext, useMemo, forwardRef } from "react";
import { observer } from "mobx-react";
import { MdStar } from "react-icons/md";
import { ScreenClassContext, Row, Col } from "react-grid-system";
import { useHistory, useRouteMatch } from "react-router-dom";

import { useModal } from "utils/hooks";
import { pickRandom } from "utils/helpers";
import useStore from "../../contexts/store";

import { Text, Button, Label, Icon } from "../Core";
import { LoadingContent } from "../Core/Spinner";
import { BuildYourProfileContent, GetInstanceFeedbackContent, InstantFeedbackContent } from "../ModalContents";
import { isBaronaBrand, MarketPromoHidden } from "../../contexts/branded";
import PremiumBox from "../PremiumBox";
import PhoneNumberModal from "../ModalContents/PhoneNumber";

const prompts = {
  student: ["Learn what it takes to get this job.", "Want to improve your chances on this role?"],
  junior: ["Are you ready for this job?", "Learn from the other candidates for this role."],
  expert: ["Are you a top candidate for this role?", "Curious to see who has already applied?"]
};

const InstantFeedbackBanner = forwardRef(({ position_id, activated, profile_filled_enough }, ref) => {
  const { IFScores, getIFScore } = useStore("Premium");
  const { track } = useStore("Tracking");
  const { user } = useStore("User");
  const { path } = useRouteMatch();
  const {
    replace,
    location: { pathname, search, state }
  } = useHistory();
  const isFreeProduct = false;
  const isNotActiveProduct = false;
  const isBarona = isBaronaBrand();

  if (isBarona) {
    return null;
  }

  const score = IFScores.find(scores => String(scores.id) === String(position_id));
  const autoOpen =
    /instant-feedback|application-booster/.test(pathname) ||
    (state?.purchased && /application-booster/.test(state?.slug) && String(state?.position_id) === String(position_id));
  const isSm = /xs|sm/.test(useContext(ScreenClassContext));

  const prompt = useMemo(() => {
    switch (user?.segment) {
      default:
      case "hustlers":
      case "junior_professionals":
      case "workers":
        return pickRandom(prompts.junior);
      case "students":
      case "graduates":
        return pickRandom(prompts.student);
      case "experts":
      case "managers":
        return pickRandom(prompts.expert);
    }
  }, [user?.segment]);

  const onModalClose = () => {
    if (autoOpen) replace(pathname.replace(/\/instant-feedback|\/application-booster/, "") + search);
  };

  const openModal = useModal(
    activated ? (
      <InstantFeedbackContent position_id={position_id} />
    ) : profile_filled_enough ? (
      <GetInstanceFeedbackContent position_id={position_id} />
    ) : (
      <BuildYourProfileContent Actions={InstantFeedbackEmptyActions} />
    ),
    "",
    onModalClose
  );

  useLayoutEffect(() => {
    if (activated) {
      getIFScore(position_id).then(() => {
        if (autoOpen) {
          openModal();
        }
      });
    }
  }, [position_id, activated]);

  const handlePromoClick = () => {
    openModal();
    if (!activated) track("promo_clicked", { prompt, match_path: path, type: "instant_feedback" });
  };

  const openModalPhoneNumber = useModal(
    <PhoneNumberModal
      isFreeProduct={isFreeProduct}
      isNotActiveProduct={isNotActiveProduct}
      handleCallBack={handlePromoClick}
      notAvailableText="Unfortunately, Career Coaching is not (yet) available in Nigeria."
    />,
    ""
  );

  const handleClickInstantFeedback = () => {
    if (!user?.product_country) {
      openModalPhoneNumber();
    } else {
      handlePromoClick();
    }
  };

  const getActionButtonText = () => {
    if (user.profile_completeness_percentage <= 19 && user.profile_completeness_percentage >= 0) {
      return "Build Fuzu profile";
    }
    if (user.profile_completeness_percentage <= 54 && user.profile_completeness_percentage >= 20) {
      return "Edit Fuzu profile";
    }
    if (activated) {
      return "View Instant Feedback";
    }
    return "Get Instant Feedback";
  };

  const buttonActionHandler = () => {
    if (user.profile_completeness_percentage <= 19 && user.profile_completeness_percentage >= 0) {
      replace("/wizard");
    } else if (user.profile_completeness_percentage <= 54 && user.profile_completeness_percentage >= 20) {
      replace("/profile");
    } else {
      handleClickInstantFeedback();
    }
  };

  return (
    <MarketPromoHidden bypass={activated}>
      <PremiumBox ref={!activated ? ref : undefined} height="auto" style={{ marginBottom: 20 }}>
        <Row align="center">
          {activated ? (
            <LoadingContent
              loading={!user.profile_completeness_percentage}
              size="30px"
              margin="0 auto 0 15px"
              primaryFill="secondary300"
              secondaryFill="secondary100"
            >
              <FeedbackMatch
                title="Your experience"
                profileCompleteness={user.profile_completeness_percentage}
                match={
                  (score?.match_scores?.experience + score?.match_scores?.education + score?.match_scores?.skills) / 3
                }
              />
            </LoadingContent>
          ) : (
            <>
              <Col xs={12} sm="content">
                <Label.PremiumAlternative title="Premium" margin="0 auto" style={{ display: "flex" }} />
              </Col>
              <Col>
                <Text
                  fontSize={16}
                  fontWeight={600}
                  lineHeight="140%"
                  color="white"
                  width="100%"
                  textAlign="center"
                  margin={isSm ? "10px 0 15px" : "0"}
                >
                  {prompt}
                </Text>
              </Col>
            </>
          )}
          <Col xs={12} sm="content">
            <Button
              theme="additional200"
              fontSize={12}
              fontWeight={600}
              margin="0 auto"
              height={32}
              onClick={buttonActionHandler}
              style={{ display: "flex" }}
            >
              {getActionButtonText()}
            </Button>
          </Col>
        </Row>
      </PremiumBox>
    </MarketPromoHidden>
  );
});

const FeedbackMatch = ({ match = 0, profileCompleteness }) => {
  const isSm = /xs|sm/.test(useContext(ScreenClassContext));
  const conditions = [match < 0.33 && match >= 0, match >= 0.33 && match <= 0.66, match > 0.66];

  let { text, starCount } = (() => {
    switch (conditions.indexOf(true)) {
      default:
      case 0:
        return {
          text: "This position is a POOR MATCH for you",
          starCount: 1
        };
      case 1:
        return {
          text: "This position is a GOOD MATCH for you",
          starCount: 2
        };
      case 2:
        return {
          text: "This position is a GREAT MATCH for you",
          starCount: 3
        };
    }
  })();

  if (profileCompleteness <= 54 && profileCompleteness >= 0) {
    text = "Want to see you compare with other candidates?";
    starCount = 0;
  }

  return (
    <>
      <Col xs={12} sm="content" style={{ display: "flex", justifyContent: "center" }}>
        {starCount
          ? [...new Array(3).keys()].map(i => (
              <Icon
                as={MdStar}
                key={i}
                fill={i + 1 <= starCount ? "additional300" : "black200"}
                width={23}
                additionalCss="height: 30px;width: 30px;"
              />
            ))
          : null}
      </Col>
      <Col>
        <Text
          width="100%"
          fontWeight={600}
          fontSize={16}
          color="white"
          textAlign="center"
          margin={isSm ? "10px 0 15px" : "0"}
        >
          {text}
        </Text>
      </Col>
    </>
  );
};

const InstantFeedbackEmptyActions = ({ position_id, close }) => (
  <Button.Link
    to={{
      pathname: "/profile",
      from: `/job?filters[job_id]=${position_id}`
    }}
    margin="4px auto"
    padding="0 23px"
    theme="primary"
    onClick={close}
  >
    Build my Fuzu Profile
  </Button.Link>
);

export default observer(InstantFeedbackBanner);
