export const countriesList = [
  "Poland",
  "Portugal",
  "Romania",
  "Slovakia",
  "Greece",
  "Hungary",
  "Ireland",
  "Italy",
  "Latvia",
  "Lithuania",
  "Luxembourg",
  "Malta",
  "Netherlands",
  "Austria",
  "Belgium",
  "Bulgaria",
  "Croatia",
  "Republic of Cyprus",
  "Czech Republic",
  "Denmark",
  "Estonia",
  "Finland",
  "France",
  "Slovenia",
  "Spain",
  "Sweden",
  "Iceland",
  "Iceland",
  "Liechtenstein",
  "Norway",
  "Switzerland",
  "England",
  "Scotland",
  "Scotland",
  "Wales",
  "Northern Ireland",
  "United States"
];
