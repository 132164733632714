import styled from "styled-components";

export const Wrapper = styled.div`
  background: #fff;
  min-height: 100vh;
`;

export const Overlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #fff;
  z-index: 11;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  svg {
    margin-top: 32px;
  }

  path {
    fill: #18181b;
  }

  div {
    margin: 0;
  }
`;

export const OverlayInfo = styled.span`
  color: #a4a4a4;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  display: block;
  margin: 24px 0 0;
`;
