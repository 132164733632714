import React, { useContext } from "react";
import parse from "html-react-parser";
import { Row, Col, ScreenClassContext } from "react-grid-system";
import { PlayCircle, Spreadsheet, Check } from "@styled-icons/boxicons-regular";

import { thousandShortcut } from "utils/helpers";
import Tooltip from "components/Tooltip";
import courses_placeholder from "images/b2c/courses-placeholder.jpg";
import { Comment, Like } from "@styled-icons/boxicons-solid";
import { useScrollHandler } from "utils/hooks";
import { getCategoryBackground } from "utils/industryImageMap";
import Layout from "b2c/components/Layout";
import { Title, Text, Card, Divider, Label, Suggestion, CollapsibleCard, Button } from "b2c/components/Core";
import { LoadingContent } from "b2c/components/Core/Spinner";
import StarRating from "b2c/components/StarRating";
import SaveButton from "b2c/components/SaveButton";
import ShareButton from "b2c/components/ShareButton";
import AuthorInfo from "b2c/components/AuthorInfo";
import PremiumBox from "b2c/components/PremiumBox";
import Comments from "b2c/components/Comments";
import CoursesSection from "b2c/components/FeedSections/CoursesSection";
import { objectiveParser, formatOptions } from "b2c/utils/helpers";
import { useTranslation } from "b2c/hooks/useTranslation";
import FeedbackSlider from "../FeedbackSlider";
import CourseProgress from "../CourseProgress";
import { DescriptionHeader, ContentWrapper, ContentSide, ContentBox, ContentGuide } from "./styled";
import { useImpressionTrack } from "../../../../hooks";
import { GuestHidden, GuestVisible } from "../../../../components/WithAuth";
import ThemeContext from "../../../../contexts/theme";
import { languageFormat } from "../../../../constants/main";

const CourseDescription = ({
  loading,
  processing,
  id,
  name,
  rating,
  likes,
  comments_count,
  description = "",
  author,
  participants_count,
  categories = [],
  skills = [],
  reviews,
  objectives,
  modules = [],
  duration_seconds,
  published_at,
  tests_count,
  saved,
  progress,
  review,
  completed_at,
  comments = [],
  handleSaving,
  getCourseModule,
  startCourse,
  retakeCourse,
  cancelCourse,
  addComment,
  updateComment,
  removeComment,
  likeCourse,
  removeCourseLike,
  premium,
  ncpwd,
  slug,
  photo,
  ...rest
}) => {
  const categoryBackground = getCategoryBackground(categories?.[0]?.slug);
  const screen = useContext(ScreenClassContext);
  const isSm = ["xs", "sm"].includes(screen);
  const { scrollHandler, targetRef } = useScrollHandler();
  const { targetRef: promptRef } = useImpressionTrack({ type: "guest_course" });
  const likeHandler = () => (!likes?.liked ? likeCourse(id) : removeCourseLike(id));
  const image = photo?.url || categoryBackground || courses_placeholder;
  const theme = useContext(ThemeContext);
  const showObjectives = loading || !!objectives || skills?.length > 0;
  const { t, language } = useTranslation();
  const languageFormatType = languageFormat[language];

  const isPremium = premium && !ncpwd;

  return (
    <>
      <DescriptionHeader premium={isPremium} backgroundImage={image}>
        <Layout.Content size="xl" tag="div">
          <ContentSide>
            {isPremium ? (
              <Label.PremiumAlternative title="premium course" />
            ) : (
              <Text loading={loading} loaded color="white" fontSize={18} fontWeight={500}>
                {t("learn.detail.label")}
              </Text>
            )}
            <Row nowrap={!isSm} align="center">
              <Col width="content">
                <Title
                  level={isSm ? 3 : 1}
                  h1ForGuests
                  loading={loading}
                  loaded
                  color="white"
                  fontWeight={600}
                  margin="8px 0"
                  lineHeight="140%"
                >
                  {name}
                </Title>
              </Col>
              {!loading && (
                <Col style={{ display: "flex", marginBottom: "10px" }}>
                  <GuestVisible>
                    <Button
                      theme="grey200"
                      color="black500"
                      icon={<Like width={18} />}
                      margin="0 8px 0 0"
                      padding="0 10px"
                      height={36}
                      disabled
                    >
                      {likes?.count}
                    </Button>
                  </GuestVisible>
                  <GuestHidden>
                    <Button
                      theme={likes?.liked ? "secondary100" : "grey200"}
                      color={likes?.liked ? "secondary200" : "black500"}
                      icon={<Like width={18} />}
                      margin="0 8px 0 0"
                      padding="0 10px"
                      height={36}
                      onClick={likeHandler}
                    >
                      {likes?.count}
                    </Button>
                  </GuestHidden>
                  <SaveButton
                    id={id}
                    type="learn"
                    saved={saved}
                    handleSaving={isSaved => handleSaving(id, "course", isSaved)}
                    margin="auto 8px"
                  />
                  <ShareButton prefix="course" name={name} margin="auto 8px" />
                </Col>
              )}
            </Row>
            <StarRating rating={rating?.value || 0} count={rating?.count} textColor="white" />
            <Col style={{ display: "flex" }} onClick={scrollHandler}>
              <Label
                theme="grey300"
                noBackground
                fontSize={14}
                margin="5px 15px 20px 0"
                padding="0"
                Icon={<Like width={20} />}
              >
                {`${thousandShortcut(likes?.count)} ${t("global.likes")}`}
              </Label>
              <Label
                theme="grey300"
                noBackground
                fontSize={14}
                margin="5px 15px 20px 0"
                padding="0"
                Icon={<Comment width={20} />}
              >
                {`${thousandShortcut(comments_count)} ${t("global.comments.label")}`}
              </Label>
            </Col>
          </ContentSide>
        </Layout.Content>
      </DescriptionHeader>
      <ContentBox premium={isPremium}>
        <Layout.Content size="xl">
          <ContentWrapper $reverseOnMobile>
            <ContentSide padding="32px 0">
              <Text as="div" loading={loading} loaded fontSize={16} lineHeight="160%">
                {parse(description.replace("&nbsp;", " "))}
              </Text>

              <Divider margin="25px 0 15px" />

              {(author || loading) && (
                <AuthorInfo
                  loaded
                  loading={loading}
                  author={author?.name}
                  avatarImg={author?.image}
                  views={thousandShortcut(participants_count)}
                  date={new Date(published_at * 1000).toLocaleString(languageFormatType, {
                    month: "long",
                    day: "numeric",
                    year: "numeric"
                  })}
                  countString="students have started this course"
                />
              )}

              {isPremium && (
                <Layout.Section margin="24px auto">
                  <PremiumBox height="auto">
                    <Row>
                      <Col lg={9}>
                        <Title color="white" level={3} fontWeight={600} textAlign={isSm ? "center" : "left"}>
                          What is a Fuzu Premium course?
                        </Title>
                      </Col>
                      <Col
                        lg={3}
                        style={{
                          display: "flex",
                          justifyContent: isSm ? "center" : "flex-end",
                          order: isSm ? -1 : "inherit"
                        }}
                      >
                        <Label.PremiumAlternative margin={isSm ? "0" : "0 0 8px"} />
                      </Col>
                    </Row>
                    <Text color="white" margin="16px 0 0" fontSize={16} lineHeight="160%">
                      Fuzu has partnered with the world’s leading online learning providers to offer you a curated
                      catalog of courses at the best possible price.
                    </Text>
                  </PremiumBox>
                </Layout.Section>
              )}

              {showObjectives && (
                <>
                  <Title level={3} fontWeight={600} margin="35px 0 20px">
                    {t("learn.detail.what_learn_this_course_title")}
                  </Title>
                  <Card bordered wideOnMobile>
                    <Card.Content padding="10px 16px">
                      <LoadingContent loading={loading} size="50px">
                        {objectives && (
                          <>
                            <Text margin="10px 0" fontSize={16} fontWeight={600}>
                              {t("learn.detail.what_learn_this_course_sub-title_1")}
                            </Text>

                            <ContentGuide fill={theme.primaryButtonBackground}>
                              {objectiveParser(objectives)}
                            </ContentGuide>
                          </>
                        )}

                        {skills.length > 0 && (
                          <>
                            <Text margin="10px 0" fontSize={16} fontWeight={600}>
                              {t("learn.detail.what_learn_this_course_sub-title_2")}
                            </Text>
                            <div className="labels-list">
                              {skills.map(({ id: ids, name: skillName }) => (
                                <Label
                                  key={`skill_uid_${ids}`}
                                  theme="secondary100"
                                  margin="0 10px 10px 0"
                                  padding="0 10px"
                                  rect
                                  height={40}
                                  fontSize={14}
                                >
                                  {skillName}
                                </Label>
                              ))}
                            </div>
                          </>
                        )}
                      </LoadingContent>
                    </Card.Content>
                  </Card>
                </>
              )}

              <Suggestion.Prompt ref={promptRef} type="course" margin="12px 0 0" />

              {(loading || modules?.length > 0) && (
                <>
                  <Title level={3} fontWeight={600} margin="35px 0 20px">
                    {t("learn.detail.modules_title")}
                  </Title>
                  <Card bordered wideOnMobile style={{ marginBottom: 35 }}>
                    <Card.Content padding="20px 16px">
                      <LoadingContent loading={loading} size="50px">
                        <Text fontSize={18} fontWeight={500} margin="0 0 15px">
                          <b>
                            {modules.length}{" "}
                            {modules.length > 1
                              ? t("learn.detail.modules_label_plural")
                              : t("learn.detail.modules_label")}
                          </b>
                          {duration_seconds > 0 &&
                            ` - ${Math.round(duration_seconds / 60)} ${t("learn.detail.min_total")}`}
                        </Text>
                        {modules.map((module, index) => (
                          <CollapsibleCard
                            key={`module_uid_${index}`}
                            theme="grey100"
                            style={{ marginBottom: 10 }}
                            headingContent={
                              <Row align="center" style={{ flex: "1 0 0" }}>
                                <Col sm={2}>
                                  <Text color="secondary200" fontSize={16} fontWeight={600} margin="3px 0">
                                    {t("learn.detail.modules_name_module")} {index + 1}
                                  </Text>
                                </Col>

                                <Col
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    lineHeight: 1
                                  }}
                                >
                                  <Text black="black400" margin="3px 0">
                                    {module?.name}
                                  </Text>
                                  {module?.videos_count > 0 && (
                                    <Tooltip tooltipContent={`${module?.videos_count} videos`}>
                                      <PlayCircle
                                        width={20}
                                        style={{
                                          margin: "0 0 0 5px"
                                        }}
                                      />
                                    </Tooltip>
                                  )}
                                  <Tooltip tooltipContent="Text content">
                                    <Spreadsheet width={20} style={{ margin: "0 0 0 5px" }} />
                                  </Tooltip>
                                </Col>

                                {module?.completed && (
                                  <Col sm="content" style={{ order: isSm ? 1 : 0 }}>
                                    <Label
                                      theme="success200"
                                      color="white"
                                      fill="white"
                                      Icon={<Check width={16} />}
                                      margin="3px 0"
                                    >
                                      {t("learn.detail.completed")}
                                    </Label>
                                  </Col>
                                )}
                                {module?.duration_seconds > 0 && (
                                  <Col sm="content">
                                    <Text color="black300" margin="3px 0">
                                      {Math.round(module.duration_seconds / 60)} {t("learn.detail.min")}
                                    </Text>
                                  </Col>
                                )}
                              </Row>
                            }
                          >
                            <Card.Separator margin="0 0 15px" />
                            {parse(module?.description?.replace("&nbsp;", " "), formatOptions)}
                          </CollapsibleCard>
                        ))}
                      </LoadingContent>
                    </Card.Content>
                  </Card>
                </>
              )}

              {reviews?.length > 0 && <FeedbackSlider reviews={reviews} loading={loading} />}

              <Comments
                ref={targetRef}
                id={id}
                loginType="learn"
                comments={comments}
                comments_count={comments.length}
                addComment={addComment}
                updateComment={updateComment}
                removeComment={removeComment}
                margin="16px 0 0"
              />
            </ContentSide>

            <CourseProgress
              loading={loading}
              processing={processing}
              progress={progress}
              image={image}
              modules={modules}
              duration_seconds={duration_seconds}
              tests_count={tests_count}
              getCourseModule={getCourseModule}
              startCourse={startCourse}
              retakeCourse={retakeCourse}
              cancelCourse={cancelCourse}
              review={review}
              completed_at={completed_at}
              premium={premium}
              ncpwd={ncpwd}
              {...rest}
            />
          </ContentWrapper>
        </Layout.Content>
      </ContentBox>

      {slug && (
        <Layout.Section backgroundColor="white">
          <Layout.Content size="xl" tag="div">
            <CoursesSection
              filter="similar"
              title="Similar courses"
              count_limit={4}
              slug={slug}
              titleProps={{ margin: "24px 0 0" }}
              noFooter
            />
          </Layout.Content>
        </Layout.Section>
      )}
    </>
  );
};

export default CourseDescription;
