import React, { useContext, useState } from "react";

import { Row, Col, ScreenClassContext } from "react-grid-system";

import manualWork from "images/b2c/onboarding/manual-work.png";
import officeWork from "images/b2c/onboarding/office-work.png";
import { useTranslation } from "b2c/hooks/useTranslation";
import { Card, Checkbox, Image, Text } from "../../../Core";

const jobTypes = [
  {
    id: "skilled",
    image: manualWork,
    title: "onboarding.interests.list.title_1",
    text: "onboarding.interests.list.description_1"
  },
  {
    id: "expert",
    image: officeWork,
    title: "onboarding.interests.list.title_2",
    text: "onboarding.interests.list.description_2"
  }
];

const JobType = () => {
  const screen = useContext(ScreenClassContext);
  const isSm = /xs|sm/.test(screen);

  const [selected, setSelected] = useState(() => {
    const onboardingForm = JSON.parse(localStorage.getItem("onboardingForm"));
    const selectedType = jobTypes.find(({ id }) => id === onboardingForm?.interest);
    return selectedType || {};
  });

  const { t } = useTranslation();

  const handleSelect = event => {
    const selectedType = jobTypes.find(({ id }) => id === event.target.value);
    setSelected(selectedType);
  };

  return (
    <Card
      theme="grey100"
      style={{
        maxWidth: "480px"
      }}
    >
      <Card.Content>
        <Row>
          {jobTypes.map(({ id, text, image, title }) => (
            <Col key={id} lg={6} sm={6} xs={6} style={{ display: "flex" }}>
              <Checkbox
                id={id}
                name="interest"
                value={id}
                borderedView
                width="100%"
                margin="6px 0"
                onChange={handleSelect}
                checked={selected.id === id}
                markColor="white"
                checkedColor="secondary200"
                checkedBgColor="secondary100"
                $markMargin={isSm ? "auto 0" : "0"}
                style={{
                  minHeight: "270px",
                  outline: "none"
                }}
                labelProps={{
                  style: {
                    padding: 8,
                    borderColor: selected.id === id ? "#4690FF" : "transparent",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.08)",
                    display: "block"
                  }
                }}
                checkboxMarkProps={{
                  style: {
                    boxShadow: selected.id === id ? "0px 4px 4px rgba(0, 0, 0, 0.25)" : "",
                    borderRadius: "50%"
                  }
                }}
                label={
                  <Row>
                    <Col
                      width="100%"
                      margin="0 auto"
                      style={{
                        textAlign: "center"
                      }}
                    >
                      <Image src={image} width="100px" height="100px" margin="24px auto 12px" />
                    </Col>
                    <Col width="100%">
                      <Text margin="0 auto" textAlign="center" fontWeight={600}>
                        {t(title)}
                      </Text>

                      <Text
                        margin="0 auto"
                        textAlign="center"
                        fontSize="12px"
                        lineHeight="18px"
                        color="#656565"
                        style={{
                          marginTop: "10px"
                        }}
                      >
                        {t(text)}
                      </Text>
                    </Col>
                  </Row>
                }
              />
            </Col>
          ))}
        </Row>
      </Card.Content>
    </Card>
  );
};

export default JobType;
