import React, { useContext } from "react";
import { Col, Row, ScreenClassContext } from "react-grid-system";
import { useHistory } from "react-router-dom";

import { useTranslation } from "b2c/hooks/useTranslation";
import { addQueryParams } from "utils/hooks";
import { Button } from "b2c/components/Core";
import { useModal } from "../../../utils/hooks";
import LoginModalContent from "../ModalContents/SignUpModalContent";
import { isWhitelabelledOrCustomBranded } from "../../contexts/branded";
import { useIsBarona } from "../../hooks";

const GuestActions = ({ selected, fromUrl }) => {
  const screen = useContext(ScreenClassContext);
  const isMobile = /sm|xs/.test(screen);
  const openLoginModal = useModal(
    <LoginModalContent type="auto" isShorterFlow appliedJobId={selected?.id} />,
    null,
    () => {},
    0
  );
  const history = useHistory();
  const isWhitelabelledOrCustom = isWhitelabelledOrCustomBranded();
  const isBarona = useIsBarona();
  const { t } = useTranslation();

  const openModal = () => {
    if (isWhitelabelledOrCustom) {
      history.push(fromUrl ? `/signup?return_to=${fromUrl}` : "/onboarding");
    } else if (isBarona) {
      const linkToOnboarding = fromUrl
        ? `/onboarding?shorterFlow=true&return_to=${fromUrl}&application_id=${selected.id}`
        : fromUrl;

      history.push(linkToOnboarding);
    } else {
      addQueryParams(
        { application_id: selected.id },
        history,
        `${history.location.pathname}${history.location.search}`
      );
      openLoginModal();
    }
  };

  return (
    <Row justify={isMobile ? "between" : "start"} gutterWidth={5}>
      <Col lg="content" xs={12}>
        <Button
          to={fromUrl}
          color="primaryButtonLabelColor"
          isonboarding
          height={isMobile ? 36 : 42}
          width={isMobile ? "100%" : "auto"}
          style={{ minWidth: 134 }}
          type="job"
          jobContextInfo={selected}
          from={fromUrl || `/jobs/${selected.id}/application`}
          onClick={openModal}
        >
          {selected?.expired ? t("jobs.content.sign_up_to_view") : t("jobs.content.sign_in_and_apply")}
        </Button>
      </Col>
    </Row>
  );
};

export default GuestActions;
